import {T_ParagraphMultiGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphMultiGallery";
import Card from "@/src/ui/components/generic/Card";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";

const BlockMultiGalleryMobile = (props: T_ParagraphMultiGallery) => {
  const modalState = useModalProvider(state => state);
  return (
    <section className="bg-gray-20">
      <ul className="py-2.5 px-5 flex space-x-2.5 overflow-x-auto">
        {props.field_multi_gallery_items.map((multiGalleryItem, index) => (
          <li
            className="h-50 w-50 flex-none  cursor-pointer"
            key={multiGalleryItem.id}
            onClick={() => {
              modalState.toggleModal(ModalKey.MULTI_GALLERY, {
                multiGallery: props,
                index,
              })
            }}
          >
            <Card
              mediaImage={multiGalleryItem.ui_cpt_field_media_mobile_teaser}
              className='bg-img-blue-shade-card'
            >
              <h3 className="title-4-mobile text-white font-calistoga">
                {multiGalleryItem.field_title}
              </h3>
              <Paragraph className="pt-0.5" size="medium">
                ({multiGalleryItem.ui_cpt_field_medias_gallery.length})
              </Paragraph>
            </Card>
          </li>
        ))}
      </ul>
    </section>
  );
}


export default BlockMultiGalleryMobile;
