import {T_ParagraphElbaFidelity} from "@/src/core/app/domain/models/paragraph/T_ParagraphElbaFidelity";
import TriangleSeparator from "src/ui/components/generic/TriangleSeparator";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Button from "@/src/ui/components/generic/Button";
import UiMediaUtils from "@/src/ui/utils/media";
import Anchor from "@/src/ui/components/generic/Anchor";
import {T_ElbaFidelity} from "@/src/core/app/domain/models/elba_fidelity/T_ElbaFidelity";

const renderFidelityItems = (items: T_ElbaFidelity[]): JSX.Element => (
  <ul className="flex justify-center space-x-8">
    {items.map((item, index) => (
      <li
        key={index}
        className="w-54 flex-1 flex flex-col items-center space-y-2.5"
      >
        <div className='flex-grow'>
          <img {...UiMediaUtils.getImgPropsFromMediaImage(item.field_fidelity_media)} width="80" height="auto"/>
        </div>
        <p className="text-center text-sm">
          {item.title}
        </p>
      </li>
    ))}
  </ul>
);

const BlockElbaFidelityDesktopCols = (props: T_ParagraphElbaFidelity): JSX.Element => (
  <div className="relative bg-dim-blue">
    <div className="relative">
      <TriangleSeparator/>
      <Section backgroundColor={props.field_background_color}>
        <MaxWidthWrapper>
          <div className="text-brand-3 pt-6 pb-5">
            <img
              className="mx-auto"
              src="/assets/images/generic/elba-fidelity-logo.svg"
              alt="Logo de Elba Fidelity"
            />
            <WrapperDiv size="medium" className="text-center pt-2.5" dangerouslySetInnerHTML={{__html: props.field_body.processed}} />
            <div className="py-10">
              {renderFidelityItems(props.fidelity_items)}
            </div>
            <div className="w-52 mx-auto px-1">
              {
                props.field_one_link && (
                  <Anchor href={props.field_one_link.url}>
                    <Button text={props.field_one_link.title} style="secondary" bold/>
                  </Anchor>
                )
              }
            </div>
          </div>
        </MaxWidthWrapper>
      </Section>
    </div>
  </div>
);

export default BlockElbaFidelityDesktopCols;
