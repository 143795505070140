import {T_NodeBlogTeaser} from "@/src/core/app/domain/models/node/T_NodeBlogTeaser";
import Anchor from "@/src/ui/components/generic/Anchor";
import UiMediaUtils from "@/src/ui/utils/media";
import BlogUtils from "@/src/core/app/utils/blog";
import cn from "classnames";
import UiTaxonomyTermUtils from "@/src/ui/utils/taxonomy-term";

export enum NodeBlogTeaser_Style {
  HOME = 'HOME',
  BOXED = 'BOXED',
}

type _Props = {
  blogPost: T_NodeBlogTeaser
  style?: NodeBlogTeaser_Style
}

const NodeBlogTeaserDesktop = (props : _Props): JSX.Element => {
  const {
    style = NodeBlogTeaser_Style.BOXED,
    blogPost,
  } = props;
  return (
    <article
      className={cn(
        style === NodeBlogTeaser_Style.BOXED && 'border border-gray-40 bg-gray-20'
      )}>
      <div>
        <Anchor path={blogPost.path}>
          <img
            className="w-full"
            {...UiMediaUtils.getImgPropsFromMediaImage(blogPost.field_blog_post_media_square)}
          />
        </Anchor>
      </div>
      <div
        className={cn(
          style === NodeBlogTeaser_Style.BOXED && 'px-3.75 pb-3.75'
        )}
      >
        <h3
          className={cn(
            'pt-5 pb-2.5 text-lg text-brand-2',
            style === NodeBlogTeaser_Style.HOME && 'font-bold leading-6',
          )}
        >
          <Anchor path={blogPost.path}>{blogPost.title}</Anchor>
        </h3>
        <p className="text-brand-2-60 text-xs">
          {BlogUtils.getDateCreated(blogPost.created)}
          {blogPost.taxonomy_term && (
            <>
              <span>{' - '}</span>
              <Anchor className='uppercase' href={UiTaxonomyTermUtils.getUrl(blogPost.taxonomy_term)}>
                {blogPost.taxonomy_term.title}
              </Anchor>
            </>
          )}
        </p>
      </div>
    </article>
  );
}

export default NodeBlogTeaserDesktop;
