import type {RouteProps} from "@/src/ui/@types/RouteProps";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import BlogCategoryListDesktop from "@/src/ui/components/blog/BlogCategoryList/desktop";
import NodeBlogLinkListDesktop from "@/src/ui/components/blog/NodeBlogLinkList/desktop";
import {T_TaxonomyTermBlogCategoryDetail} from "@/src/core/app/domain/models/taxonomy/T_TaxonomyTermBlogCategoryDetail";
import NodeBlogTeaserListDesktop from "@/src/ui/components/blog/NodeBlogTeaserList/desktop";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import PaginationWrapper from "@/src/ui/components/generic/PaginationWrapper";
import React from "react";
import {useTranslation} from "next-i18next";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_BlogCategoryDesktop = (props: RouteProps<T_TaxonomyTermBlogCategoryDetail>) : JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader header={props.routeData.data.page_header} />
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <div className="px-5 py-10">
        <MaxWidthWrapper>
          <div className="flex space-x-10">
            <div className="flex-1 space-y-7.5">
              <NodeBlogTeaserListDesktop
                titleWithTag={{
                  text: props.routeData.data.name,
                  tag: 'h2'
                }}
                items={props.routeData.data.posts}
              />
              <PaginationWrapper
                className="flex justify-center"
                pager={props.routeData.data.pager}
              />
            </div>
            <div className="flex-none w-95">
              <BlogCategoryListDesktop
                titleWithTag={{
                  text: t('generic.blog.categories_label'),
                  tag: 'p'
                }}
                categories={props.routeData.data.categories}
              />
              <NodeBlogLinkListDesktop
                titleWithTag={{
                  text: t('generic.blog.promoted_posts_label'),
                  tag: 'p',
                }}
                items={props.routeData.data.promotedPosts}
              />
            </div>
          </div>
        </MaxWidthWrapper>
      </div>
    </>
  );
}

export default Route_BlogCategoryDesktop;
