import {T_ParagraphInfoTextImage} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoTextImage";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import Section from "@/src/ui/components/generic/Section/Section";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import cn from "classnames";
import {CMS_ParagraphInfoTextImage_Align} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphInfoTextImage";
import CmsLinkButtonMultiple from "@/src/ui/components/generic/CmsLinkButtonMultiple";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import Carousel from "@/src/ui/components/generic/Carousel";

/*
const _Float = () => (
  <Section paddingY="pt-10" backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      <Carousel
        className={cn(
          'w-140 mb-10',
          props.field_align === CMS_ParagraphInfoTextImage_Align.LEFT && 'float-left mr-15',
          props.field_align === CMS_ParagraphInfoTextImage_Align.RIGHT && 'float-right ml-15',
        )}
        medias={props.ui_cpt_field_medias}
      />
      <TitleWithTag
        className="title-4"
        field={props.field_title_with_tag}
      />
      {props.field_body && (
        <WrapperDiv
          className="pt-2.5 pb-5 text-sm text-brand-2-90 Dingus-Formatted"
          dangerouslySetInnerHTML={{__html: props.field_body.processed}}
        />
      )}
      {props.field_one_link && (
        <ArrowLink href={props.field_one_link.url}>
          {props.field_one_link.title}
        </ArrowLink>
      )}
      {props.field_link && (props.field_link.length > 0) && (
        <CmsLinkButtonMultiple
          links={props.field_link}
          className='flex gap-2'
        />
      )}
      <div className='mb-10'/>
      <div className='clear-both'/>
    </MaxWidthWrapper>
  </Section>
);
*/

const BlockInfoTextImageDesktop = (props: T_ParagraphInfoTextImage) => (
  <Section paddingY="py-10" backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      <div className={cn(
        "flex space-x-15",
        props.field_align === CMS_ParagraphInfoTextImage_Align.LEFT && 'flex-row-reverse space-x-reverse',
      )}>
        <div className="flex-1 w-full">
          <TitleWithTag
            className="title-4 font-calistoga"
            field={props.field_title_with_tag}
          />
          {props.field_body && (
            <WrapperDiv
              className="pt-2.5 pb-5 text-sm text-brand-2-90 Dingus-Formatted"
              dangerouslySetInnerHTML={{__html: props.field_body.processed}}
            />
          )}
          {props.field_one_link && (
            <ArrowLink href={props.field_one_link.url}>
              {props.field_one_link.title}
            </ArrowLink>
          )}
          {props.field_link && (props.field_link.length > 0) && (
            <CmsLinkButtonMultiple
              links={props.field_link}
              className='flex gap-2'
            />
          )}
        </div>
        <Carousel
          className='w-140'
          medias={props.ui_cpt_field_medias}
        />
      </div>
    </MaxWidthWrapper>
  </Section>
);

export default BlockInfoTextImageDesktop;