import InputWrapper, {InputWrapperProps} from "../../form/InputWrapper";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import {MouseEventHandler} from "react";
import cn from "classnames";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import DestinationUtils from "@/src/ui/utils/destination";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {SearchParamsLocationType} from "@/src/core/app/domain/@types/SearchParamsLocationType";
import {useTranslation} from "next-i18next";

interface ButtonLocationSummaryProps {
  className?: string
  onClick?: MouseEventHandler
  color?: InputWrapperProps["color"]
  hasError?: InputWrapperProps["hasError"]
}

const ButtonLocationSummary = (props: ButtonLocationSummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useSearchFormProvider(state => state.location);
  const routeProps = useRouteProps();
  let label : string | null = null;

  if (location !== null) {
    label = location.type === SearchParamsLocationType.DESTINATION ?
      DestinationUtils.getDestination(location.id, routeProps.layoutData.destinations).destinationName :
      HotelTreeUtils.getHotel(location.id, routeProps.layoutData.hotelTree).title
    ;
  }

  return (
    <div className={props.className} onClick={props.onClick}>
      <InputWrapper
        className='space-x-1.5 overflow-hidden flex items-stretch'
        color={props.color}
        hasError={props.hasError}
      >
        <img src='/assets/icons/place.svg' alt="" />
        <span className={cn('bg-transparent flex-1 text-sm truncate', {
          'text-brand-2-40': !label
        })}>
          {label ?? t('ButtonLocationSummary._Index.fallback_label')}
        </span>
      </InputWrapper>
    </div>
  );
}

export default ButtonLocationSummary;
