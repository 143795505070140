import currency from 'currency.js'
import {CurrencyConfig} from "@/src/ui/@types/CurrencyConfig";
import {T_CurrencyList} from "@/src/core/app/domain/models/currency/T_CurrencyList";
import {T_Currency} from "@/src/core/app/domain/models/currency/T_Currency";

const UiCurrencyUtils = {
  getOutputOptionsAndValue: (value: number, config: CurrencyConfig, useEUR: boolean = false) => {
    const currencyToApply = useEUR ? config.apiCurrency : config.uiCurrency;
    const outputOptions = UiCurrencyUtils.getCurrencyLibOptions(currencyToApply);
    return {
      outputValue: useEUR ? value : value * currencyToApply.rate,
      outputOptions,
    }
  },
  format: (value: number, config: CurrencyConfig, useEUR: boolean = false): string => {
    const {
      outputValue,
      outputOptions
    } = UiCurrencyUtils.getOutputOptionsAndValue(value, config, useEUR);
    return currency(outputValue, outputOptions).format();
  },
  formatNumber: (value: number, config: CurrencyConfig, useEUR: boolean = false): string => {
    const {
      outputValue,
      outputOptions
    } = UiCurrencyUtils.getOutputOptionsAndValue(value, config, useEUR);
    outputOptions.symbol = '';
    return currency(outputValue, outputOptions).format();
  },
  getCurrencyLibOptions: (c: T_Currency) => {
    return {
      fromCents: true,
      pattern: c.symbol_position === 'left' ? '!#' : '#!',
      separator: c.decimal_separation === ',' ? '.' : ',',
      decimal: c.decimal_separation,
      symbol: c.symbol,
    };
  },
  getCurrency: (currencies: T_CurrencyList, code: string) : T_Currency => {
    return currencies[code];
  },
  getDropdownOptions: (currencies: T_CurrencyList) => {
    return Object.values(currencies).map(item => ({
      label: `${item.symbol} ${item.title}`,
      value: item.code,
    }));
  }
};

export default UiCurrencyUtils;
