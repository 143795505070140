import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutConversion} from "@/src/ui/@types/layout/UI_LayoutConversion";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalDesktopKey from "@/src/ui/@types/modal/ModalDesktopKey";
import CurrencyDropdown from "@/src/ui/components/generic/CurrencyDropdown";
import {useTranslation} from "next-i18next";
import ModalKey from "@/src/ui/@types/modal/ModalKey";

interface _Props {
  amount: number
}

const _Subtotal = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const toggleModal = useModalProvider(state => state.toggleModal);
  const currencyConfig = useCurrencyConfig();
  const routeProps = useRouteProps<T_CheckoutHotel, UI_LayoutConversion>();

  return (
    <div className="px-4 py-3.5 bg-gray-20 flex justify-between leading-tight">
      <div className="space-y-1.5">
        <h4 className="font-bold text-brand-2-60 uppercase">{t('CheckoutSummary._Subtotal.subtotal_label')}</h4>
        <div className="flex space-x-1.5 text-xs text-brand-2-90">
          <span>Ver en</span>
          <span className="text-brand-2">
          <CurrencyDropdown color="brand-2" isBold={false} />
        </span>
        </div>
      </div>
      <div className="space-y-0.5 text-right">
        <p className="title-4 font-calistoga">{UiCurrencyUtils.format(props.amount, currencyConfig)}</p>
        <p className="text-xs text-brand-2-90 text-right">
          {t('CheckoutSummary._Subtotal.taxes_included_label')} *
        </p>
        {routeProps.routeData.data.special_taxes && (
          <p
            className="text-xs underline cursor-pointer"
            onClick={() => toggleModal(ModalKey.CHECKOUT_SPECIAL_TAXES)}
          >
            {t('CheckoutSummary._Subtotal.special_taxes_link_label')}
          </p>
        )}
      </div>
    </div>
  );
}

export default _Subtotal;
