import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import LayoutConversionNavigationDesktop from "./LayoutConversionNavigationDesktop";
import Section from "@/src/ui/components/generic/Section/Section";
import Anchor from "@/src/ui/components/generic/Anchor";
import UrlUtils from "@/src/core/app/utils/url";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

const LayoutConversionHeaderDesktop = (): JSX.Element => {
  const appInfo = useAppInfo();
  return (
    <header>
      <Section className='bg-gray-20' backgroundColor='none'>
        <MaxWidthWrapper>
          <div className="flex justify-between items-end">
            <Anchor href={UrlUtils.getHomepageUrl(appInfo.langcode)}>
              <img
                className="h-19 w-auto"
                src="/assets/images/generic/elba-logo.svg"
              />
            </Anchor>
            <LayoutConversionNavigationDesktop />
          </div>
        </MaxWidthWrapper>
      </Section>
    </header>
  );
}

export default LayoutConversionHeaderDesktop;
