import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import HotelRoomTeaserDesktop from "@/src/ui/components/hotel-room/desktop/HotelRoomTeaser";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {T_HotelSectionDataRoom} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataRoom";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";
import _Rooms from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/rooms";
import _Calendar from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar";
import CheckoutUtils from "@/src/core/app/utils/checkout";

const BlockHotelRoomListDesktop = (props: T_ParagraphHotelRoomList) => {
  const routeData = useRouteData<T_HotelSection<T_HotelSectionDataRoom>>();
  const state = useCheckoutProvider(state => state);
  let rooms = routeData.data.ui_hotel_section.data.rooms;

  if (CheckoutUtils.isCheckoutReadyToShowRooms(state)) {
    return (
      <_Rooms {...props} />
    );
  }

  if (CheckoutUtils.isCheckoutReadyToShowCalendar(state)) {
    return (
      <_Calendar {...props} />
    );
  }

  rooms = rooms.filter(room => !room.list_hidden);

  return (
    <Section>
      <MaxWidthWrapper>
        <div className="py-2.5 space-y-10">
          <TitleWithTag
            field={props.field_title_with_tag}
            className="title-4 font-calistoga"
          />
          <ul className="space-y-5">
            {rooms.map((room, index) => (
              <li key={index}>
                <HotelRoomTeaserDesktop hotelRoom={room} />
              </li>
            ))}
          </ul>
        </div>
      </MaxWidthWrapper>
    </Section>
  );
};

export default BlockHotelRoomListDesktop;
