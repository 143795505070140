import {RouteProps} from "@/src/ui/@types/RouteProps";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import BookingConfirmationDesktop from "@/src/ui/components/routes/Route_BookingConfirmation/desktop";
import BookingConfirmationMobile from "@/src/ui/components/routes/Route_BookingConfirmation/mobile";

const Route_BookingConfirmation = (props: RouteProps<BookingConfirmationRouteProps>) => {
  const _Component = props.device.isMobile ? BookingConfirmationMobile : BookingConfirmationDesktop;
  return (
    <_Component {...props} />
  );
};

export default Route_BookingConfirmation;
