import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";
import {UserPasswordChangeError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserPasswordChangeError";
import {FormErrorHandleFnProps} from "@/src/core/app/domain/forms/@types/FormErrorHandleFnProps";

const UserPasswordChangeErrors : FormErrorMap = {
  [UserPasswordChangeError.INVALID_CREDENTIALS]: {
    fields: {
      'currentPassword': 'user_password_change.invalid_credentials.error_message',
    },
    action: (props: FormErrorHandleFnProps) => {
      props.modalState.closeModal();
    }
  },
}

export default UserPasswordChangeErrors;
