import {LayoutProps} from "@/src/ui/@types/layout/LayoutProps";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import LayoutStatic from "@/src/ui/components/layout/static";

const LayoutError = (props: LayoutProps) => {
  const appInfo = useAppInfo();
  return (
    <LayoutStatic langcode={appInfo.langcode}>
      {props.children}
    </LayoutStatic>
  );
}

export default LayoutError;
