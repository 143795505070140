import {GetServerSidePropsContext} from "next/types";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {NextRequest} from "next/server";
import {IncomingMessage} from "http";

const ServerSideUtils = {
  getAppInfo: (context: GetServerSidePropsContext) : AppInfo => {
    return {
      langcode: ServerSideUtils.getLangcode(context),
      absoluteUrl: ServerSideUtils.getUrl(context).toString(),
    };
  },

  getLangcode: (context: GetServerSidePropsContext) => {
    return context.locale as string;
  },

  getPathFromContext: (context: GetServerSidePropsContext) => {
    if (context.params?.path === undefined) {
      return '/';
    }
    if (typeof context.params?.path === 'string') {
      return '/' + context.params?.path;
    }
    return '/' + context.params?.path?.join('/') || "";
  },

  getPathFromContextWithLangPrefix: (context: GetServerSidePropsContext) => {
    const path = ServerSideUtils.getPathFromContext(context);
    const langcode = ServerSideUtils.getLangcode(context);
    return `/${langcode}${path}`;
  },

  getAbsoluteUrl: (url: string, req: IncomingMessage) => {
    const protocol = (req.headers['x-forwarded-proto'] as string) || 'http';
    const host = req.headers.host || 'localhost';
    return new URL(url, `${protocol}://${host}`);
  },

  getUrl: (context: GetServerSidePropsContext) => {
    const url = ServerSideUtils.getRelativeUrl(context);
    return ServerSideUtils.getAbsoluteUrl(url, context.req);
  },

  getRelativeUrl: (context: GetServerSidePropsContext) => {
    const path = ServerSideUtils.getPathFromContextWithLangPrefix(context);
    const queryParams = context.query;
    delete queryParams['path'];

    const urlSearchParams = new URLSearchParams(queryParams as Record<string, string>).toString();
    let url = path;
    if (urlSearchParams.length > 0) {
      url += '?' + urlSearchParams;
    }

    return url;
  },
};

export default ServerSideUtils;
