import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodeJobOfferDetail} from "@/src/core/app/domain/models/node/T_NodeJobOfferDetail";
import Section from "@/src/ui/components/generic/Section/Section";
import _Header from "@/src/ui/components/routes/Route_JobOfferDetail/generic/_Header";
import _Details from "@/src/ui/components/routes/Route_JobOfferDetail/generic/_Details";
import JobOfferForm from "src/ui/components/forms/JobOfferForm";

const Route_JobOfferDetailMobile = (props: RouteProps<T_NodeJobOfferDetail>) : JSX.Element => {
  const jobOffer = props.routeData.data;
  return (
    <Section paddingY="py-7.5 space-y-5">
      <h2 className="title-4 font-calistoga">
        {jobOffer.title}
      </h2>
      <_Header {...jobOffer} />
      <_Details {...jobOffer} />
      <JobOfferForm jobOffer={jobOffer} />
    </Section>
  );
}

export default Route_JobOfferDetailMobile;
