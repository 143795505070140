import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import {CMS_OfferEntity_PriceType} from "@/src/core/app/domain/cms/@types/offer/CMS_OfferEntityWithPrice";
import _PriceTagFixed from "@/src/ui/components/offer/OfferDetail/price-tag/by-type/fixed";
import _PriceTagDiscount from "@/src/ui/components/offer/OfferDetail/price-tag/by-type/discount";
import _PriceTagRange from "@/src/ui/components/offer/OfferDetail/price-tag/by-type/range";
import _PriceTagNoPrice from "@/src/ui/components/offer/OfferDetail/price-tag/by-type/no-price";

const _PriceTag = (props: T_OfferDetail) => {
  if (props.price_type === CMS_OfferEntity_PriceType.FIXED) {
    return (
      <_PriceTagFixed
        price={props.price_from}
        description={props.field_price_conditions}
      />
    )
  }
  if (props.price_type === CMS_OfferEntity_PriceType.DISCOUNT) {
    return (
      <_PriceTagDiscount
        discount={props.price_discount as number}
        description={props.field_price_conditions}
      />
    )
  }
  if (props.price_type === CMS_OfferEntity_PriceType.RANGE) {
    return (
      <_PriceTagRange
        priceFrom={props.price_from}
        priceTo={props.price_to}
        description={props.field_price_conditions}
      />
    )
  }
  if (props.price_type === CMS_OfferEntity_PriceType.NO_PRICE) {
    return (
      <_PriceTagNoPrice
        description={props.field_price_conditions}
      />
    )
  }
  return null;
}

export default _PriceTag;
