import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_FieldTextTextarea} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextTextarea";
import {CMS_FieldLink} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldLink";
import {T_NodeBlogTeaser} from "@/src/core/app/domain/models/node/T_NodeBlogTeaser";
import {T_Pager} from "@/src/core/app/domain/models/pager/T_Pager";
import {T_TaxonomyTermLink} from "@/src/core/app/domain/models/taxonomy/T_TaxonomyTermLink";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

export enum T_ParagraphBlogList__Display {
  LAST = 'last',
  ALL = 'all',
}

export interface T_ParagraphBlogList extends CMS_ParagraphEntity {
  field_title_with_tag?: CMS_FieldTextWithTag
  field_body?: CMS_FieldTextTextarea
  field_blog_display: T_ParagraphBlogList__Display
  field_one_link?: CMS_FieldLink
  field_background_color?: string
  blogPosts: T_NodeBlogTeaser[]
  cpt_node_blog_count: number

  categories: T_TaxonomyTermLink[]

  pager: T_Pager
}