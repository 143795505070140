import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_OfferGroupDetail} from "@/src/core/app/domain/models/offer-group/T_OfferGroupDetail";
import OfferSearch from "@/src/ui/components/offer/OfferSearch";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_OfferGroupDetail = (props: RouteProps<T_OfferGroupDetail>) : JSX.Element => {
  const item = props.routeData.data;
  return (
    <>
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <OfferSearch
        titleWithTag={{
          text: item.title,
          tag: 'p',
        }}
        count={item.cpt_offer_count}
        offerGroupLinks={item.cpt_ui_offer_group_links}
        experienceLinks={item.cpt_ui_experience_links}
        offerTeasers={item.offerTeasers}
        pager={item.pager}
      />
    </>
  )
}

export default Route_OfferGroupDetail;
