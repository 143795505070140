import Paragraph from "@/src/ui/components/generic/Paragraph";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

interface _Props {
  type?: 'plain' | 'html'
  message: string
}

const SimpleMessage = (props: _Props) => {
  const type = props.type ?? 'plain';

  if (type === 'html') {
    return (
      <article className="p-5 bg-gray-20 space-y-5">
        <WrapperDiv dangerouslySetInnerHTML={{__html: props.message}} />
      </article>
    );
  }

  return (
    <article className="p-5 bg-gray-20 space-y-5">
      <Paragraph>
        {props.message}
      </Paragraph>
    </article>
  );
}

export default SimpleMessage;
