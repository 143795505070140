import {T_ParagraphOffersCategoryPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphOffersCategoryPromoted";
import Section from "@/src/ui/components/generic/Section/Section";
import OfferTeaserListMobile from "@/src/ui/components/offer-group/OfferTeaserList/mobile";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const BlockOffersCategoryPromotedMobile = (props: T_ParagraphOffersCategoryPromoted): JSX.Element => {
  return (
    <Section paddingY={"py-7.5"} backgroundColor={props.field_background_color}>
      <div className="text-brand-2">
        <div className="space-y-2.5">
          <TitleWithTag
            className="text-xl font-calistoga"
            field={props.field_title_with_tag}
          />
          <WrapperDiv size="medium" dangerouslySetInnerHTML={{__html: props.field_body.processed}} />
        </div>
      </div>
      <div className="py-5">
        <OfferTeaserListMobile max={4} offers={props.field_offers_category} />
      </div>
      <ArrowLink href={props.field_one_link.url}>{props.field_one_link.title}</ArrowLink>
    </Section>
  );
};

export default BlockOffersCategoryPromotedMobile;
