import {ChangeEvent, useEffect, useRef} from "react";
import FormInputWrapper from "src/ui/components/form/FormInputWrapper";
import {useTranslation} from "next-i18next";
import {useFormField} from "@/src/ui/hooks/useFormField";
import {FormFieldProps} from "@/src/ui/@types/form/FormFieldProps";

interface _Props extends FormFieldProps {
  label?: string
  className?: string
  description: string
  accept?: string
}

const FormInputFile = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    label,
    className,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null)
  const [field, meta, handlers] = useFormField(props, t);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    handlers.setValue(event.target.files[0]);
    handlers.setError(undefined);
  };

  useEffect(() => {
    if (!field.value) {
      // @ts-ignore
      inputRef.current.value = null;
    }
  }, [field.value]);

  return (
    <>
      <FormInputWrapper
        label={label}
        error={meta.error}
        className={className}
        footer={(
          <div className="text-xs text-brand-2-90" dangerouslySetInnerHTML={{__html: props.description}} />
        )}
      >
        <input
          ref={inputRef}
          type='file'
          onChange={onChange}
          accept={props.accept}
        />
      </FormInputWrapper>
    </>
  )
};

export default FormInputFile;
