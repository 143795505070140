import Paragraph from "../../../generic/Paragraph";

interface _Props {
  content: string
}

const _Label = (props: _Props): JSX.Element => (
  <div className="py-2 px-2.5 text-white bg-brand-2 rounded-sm">
    <Paragraph size="medium">{props.content}</Paragraph>
  </div>
);

export default _Label;
