import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";

const _Features = (props: T_HotelTeaser): JSX.Element | null => {
  if (props.field_characteristics.length === 0) {
    return null;
  }
  return (
    <ul className="flex flex-wrap gap-5 text-sm text-brand-2-90">
      {props.field_characteristics.map((item, index) => (
        <li className="flex items-center space-x-2.5" key={index}>
          <img
            className="h-4.5 w-auto"
            src="/assets/icons/check-circle-gray.svg"
            alt=""
          />
          <span className="block">{item.title}</span>
        </li>
      ))}
    </ul>
  );
}

export default _Features;
