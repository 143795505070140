import {ReactNode} from "react";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";

interface _Props {
  content: ReactNode
  sidebar: ReactNode
}

const Section2ColsDesktop = (props: _Props): JSX.Element => (
  <Section paddingY="pt-10 pb-20">
    <MaxWidthWrapper>
      <div className="flex space-x-10">
        <div className="flex-1 max-w-190">{props.content}</div>
        <div className="flex-none w-95">{props.sidebar}</div>
      </div>
    </MaxWidthWrapper>
  </Section>
);

export default Section2ColsDesktop;
