import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {SsrService} from "@/src/core/app/data/services/ssr_service";
import {T_ReservationManage} from "@/src/core/app/domain/models/reservation/T_ReservationManage";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

const SsrUtils = {
  bookingManage: async (appInfo: AppInfo, reservation: string, hotel: string) : Promise<NetworkResponse<T_ReservationManage>> => {
    const api = locator.get<SsrService>(TYPES.SsrService);
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    return await api.bookingManage({
      langcode: appInfo.langcode,
      reservation,
      hotel
    }, authHeaders);
  },
};

export default SsrUtils;
