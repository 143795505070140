import HeaderMobile from "./HeaderMobile";
import FooterMobile from "./FooterMobile";

import React from "react";
import LayoutConversionHeaderMobile from "./LayoutConversionHeaderMobile";
import type {LayoutProps} from "@/src/ui/@types/layout/LayoutProps";
import {LayoutType} from "@/src/ui/@types/layout/Layout";
import PortalsMobile from "@/src/ui/components/portals/mobile/PortalsMobile";

const LayoutMobile = (props: LayoutProps) => (
  <PortalsMobile
    routeProps={props.routeProps}
    routePortals={props.routePortals}
  >
    {props.routeProps.layout.type === LayoutType.CONVERSION && <LayoutConversionHeaderMobile />}
    {(props.routeProps.layout.type === LayoutType.HOME || props.routeProps.layout.type === LayoutType.GENERIC) && <HeaderMobile />}
    <main>{props.children}</main>
    <FooterMobile />
  </PortalsMobile>
);

export default LayoutMobile;
