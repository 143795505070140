import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import Button from "@/src/ui/components/generic/Button";
import {useOfferFormProvider} from "@/src/ui/providers/offer-form.provider";
import {useEffectStrictMode} from "@front_web_mrmilu/hooks";
import _DateRange from "@/src/ui/components/offer/OfferDetail/form/date-range";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import _Occupation from "@/src/ui/components/offer/OfferDetail/form/occupation";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {CMS_OfferEntity_NightsType} from "@/src/core/app/domain/cms/@types/offer/CMS_OfferEntityWithNights";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {CMS_OfferEntity_OccupationType} from "@/src/core/app/domain/cms/@types/offer/CMS_OfferEntityWithOccupation";
import {useTranslation} from "next-i18next";

const _Form = (props: T_OfferDetail) => {
  const { t } = useTranslation();
  const device = useDevice();
  const modalState = useModalProvider(state => state);
  const checkout = useCheckoutProvider(state => state);
  const formState = useOfferFormProvider(state => state);

  useEffectStrictMode(() => {
    if (formState.isInitiated) {
      return;
    }

    formState.init({
      dates: {
        datetime_from: props.datetime_from,
        datetime_to: props.datetime_to
      },
      occupation: {
        occupation_type: props.occupation_type,
        occupation_adults: props.occupation_adults,
        occupation_children: props.occupation_children,
      },
      nights: {
        nights_type: props.nights_type,
        nights_count: props.nights_count,
        nights_min: props.nights_min,
      },
      kidsMaxAge: props.cpt_ui_hotel_kids_max_age,
    })
  })

  if (!formState.isInitiated) {
    return null;
  }

  const onClick = async () => {
    modalState.toggleModal(ModalKey.LOADER, {
      message: 'OfferDetail._Form.loading_message'
    });

    await checkout.initForOfferDetail(formState.dateRange, formState.occupation);

    modalState.closeModal();
  };

  return (
    <div className={cn(
      'p-5 border border-gray-40 bg-gray-20',
      device.isDesktop && 'flex items-end space-x-10',
      device.isMobile && 'space-y-4',
    )}>
      <_DateRange
        dateRange={formState.dateRange as SearchParamsDateRange}
        isEditable={props.nights_type !== CMS_OfferEntity_NightsType.CLOSE}
      />
      <_Occupation
        occupation={formState.occupation as SearchParamsOccupationRoom}
        isEditable={props.occupation_type === CMS_OfferEntity_OccupationType.OPEN}
      />
      <div className="flex-1"></div>
      <div>
        <Button
          className='block'
          text={t('OfferDetail._Form.button_label')}
          onClick={onClick}
          bold
        />
      </div>
    </div>
  );
}

export default _Form;
