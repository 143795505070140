import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import _Header from "@/src/ui/components/offer/OfferDetail/header";
import _Img from "@/src/ui/components/offer/OfferDetail/img";
import _Skirt from "@/src/ui/components/offer/OfferDetail/skirt";
import _Form from "@/src/ui/components/offer/OfferDetail/form";
import _Body from "@/src/ui/components/offer/OfferDetail/body";
import _Checkout from "@/src/ui/components/offer/OfferDetail/checkout";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStepRoomsStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsStatus";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";

interface _Props {
  item: T_OfferDetail
}

const OfferDetail = (props: _Props) => {
  const device = useDevice();
  const item = props.item;
  const checkout = useCheckoutProvider(state => state);
  const className = device.isDesktop ? 'space-y-5' : 'space-y-2';

  if ((checkout.currentStep === CheckoutStep.ROOMS) && (checkout.currentStepData as CheckoutStepRoomsData).status !== CheckoutStepRoomsStatus.LOADING) {
    return (
      <div className={className}>
        <_Header {...item} />
        <_Form {...item} />
        <_Checkout {...item} />
      </div>
    )
  }

  return (
    <div className={className}>
      <_Header {...item} />
      <_Img {...item} />
      <_Skirt {...item} />
      <_Form {...item} />
      <_Body {...item} />
    </div>
  );
}

export default OfferDetail;
