import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import OfferTeaserList from "@/src/ui/components/offer/OfferTeaserList";
import Section from "@/src/ui/components/generic/Section/Section";
import {OfferSearchProps} from "@/src/ui/components/offer/OfferSearch/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import Button from "@/src/ui/components/generic/Button";
import ModalMobileKey from "@/src/ui/@types/modal/ModalMobileKey";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import PaginationWrapper from "@/src/ui/components/generic/PaginationWrapper";
import React from "react";
import OfferSearchDateFilter from "@/src/ui/components/offer/OfferSearch/common/date-filter";
import {useTranslation} from "next-i18next";

const OfferSearchMobile = (props: OfferSearchProps) => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);

  return (
    <Section paddingY="py-7.5">
      <MaxWidthWrapper>
        {props.titleWithTag && (
          <TitleWithTag
            className="text-brand-2 text-2xl font-calistoga"
            field={props.titleWithTag}
            suffix={`(${props.count})`}
          />
        )}
        <div className="py-5 flex space-x-2.5">
          <div>
            <Button
              text={t('ModalOfferSearchMenu._Index.title')}
              style="ghost"
              customPaddingClass='px-3 py-2'
              className='block'
              onClick={() => modalState.toggleModal(ModalMobileKey.OFFER_SEARCH_MENU, {
                offerGroupLinks: props.offerGroupLinks,
                experienceLinks: props.experienceLinks,
              })}
            />
          </div>
          <OfferSearchDateFilter />
        </div>
        <div className="pb-10">
          <OfferTeaserList items={props.offerTeasers} />
          <PaginationWrapper
            className="flex justify-center pt-8"
            pager={props.pager}
          />
        </div>
      </MaxWidthWrapper>
    </Section>
  );
};

export default OfferSearchMobile;