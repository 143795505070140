import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";

const BookingManageErrors : FormErrorMap = {
  'input-validation-failed': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'global.generic_notification.error_title',
      message: 'booking_cancel.input_validation_failed.error_message',
    }
  },
}

export default BookingManageErrors;
