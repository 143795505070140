import BlockBannerSliderDesktop from "@/src/ui/components/blocks/BlockBannerSlider/desktop";
import {T_ParagraphBannerSlider} from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSlider";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockBannerSliderMobile from "@/src/ui/components/blocks/BlockBannerSlider/mobile";


const BlockBannerSlider = (props: T_ParagraphBannerSlider) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockBannerSliderMobile : BlockBannerSliderDesktop;
  return (
    <_Component {...props} />
  );
}

export default BlockBannerSlider;
