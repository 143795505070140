import {
  T_ParagraphResidentsList,
  T_ParagraphResidentsList__Display
} from "@/src/core/app/domain/models/paragraph/T_ParagraphResidentsList";
import _Column from "@/src/ui/components/blocks/BlockResidentsList/desktop/by-display/column";
import _Row from "@/src/ui/components/blocks/BlockResidentsList/desktop/by-display/row";

const BlockResidentsListDesktop = (props: T_ParagraphResidentsList) => {
  const _Component = props.field_residents_display === T_ParagraphResidentsList__Display.COLUMN_IMAGE_TITLE ? _Column : _Row;
  return (
    <_Component {...props} />
  );
};

export default BlockResidentsListDesktop;
