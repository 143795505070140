import BrowserUtils from "@/src/ui/utils/browser";

import {MyBookingListState} from "@/src/ui/view_models/my-booking-list.state";
import {createProvider} from "@/src/common/utils/zustand";
import BookingManageUtils from "@/src/core/app/utils/booking-manage";
import {CMS_ReservationStatus} from "@/src/core/app/domain/cms/@types/reservation/CMS_ReservationStatus";
import {AppInitiatedEvent} from "@/src/ui/@types/event/AppInitiatedEvent";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

export const useMyBookingListProvider = createProvider<MyBookingListState>((set, get) => {
  BrowserUtils.onAppInitiated<AppInitiatedEvent>((event) => {
    get().init(
      event.detail.appInfo,
      event.detail.userData
    );
  });

  return {
    isLoading: true,
    items: [],
    init: async (appInfo: AppInfo, userData: UserData | null) => {
      if (userData === null) {
        set(state => {
          state.isLoading = false;
        });
        return;
      }

      const items = await BookingManageUtils.myBookings(appInfo);
      set(state => {
        state.isLoading = false;
        state.items = items;
      });
    },
    onCancel: (reservationId: string) => set(state => {
      for (const item of state.items) {
        if (item.id === reservationId) {
          item.status = CMS_ReservationStatus.CANCELLED;
          item.is_cancellable = false;
        }
      }
    }),
  };
});
