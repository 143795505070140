import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {BookingApiService} from "@/src/core/app/data/services/booking_api_service";
import {GetAvailAndRatesParams} from "@/src/core/app/domain/booking/@types/Params/GetAvailAndRatesParams";
import {
  CheckoutStepCalendarDataDateRanges
} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarDataDateRanges";
import {DateRange} from "@/src/core/app/domain/@types/DateRange";
import CalendarUtils from "@/src/ui/utils/calendar";
import {
  HotelCalendarAvailAndRatesOutput,
  HotelCalendarAvailAndRatesOutputRates,
} from "@/src/core/app/domain/booking/@types/Output/HotelCalendarAvailAndRatesOutput";
import {ModalState} from "@/src/ui/view_models/modal.state";
import {CheckoutState} from "@/src/ui/view_models/checkout.state";
import {CheckoutStepCalendarData} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarData";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import {SearchFormState} from "@/src/ui/view_models/search-form.state";

const CheckoutCalendarUtils = {
  getAvailAndRates: async (
    langcode: string,
    searchParams: SearchParams,
    dateRange: DateRange,
  ) => {
    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    const room = searchParams.rooms[0];
    const params : GetAvailAndRatesParams = {
      hotel: searchParams.location?.id as string,
      date_from: dateRange.start as string,
      date_to: dateRange.end as string,
      adults_count: room.adults,
      kids_count: room.kids,
    }

    if (room.kids > 0) {
      params['kids_age'] = room.kid_ages.join(',');
    }

    return await api.getHotelCalendarAvailAndRates(langcode, params);
  },

  getRanges: (dateRange: DateRange, selectionDateRange?: DateRange) : CheckoutStepCalendarDataDateRanges => {
    const viewMinDate = dateRange.start;

    const ranges : CheckoutStepCalendarDataDateRanges = {
      viewDateRange: {
        start: viewMinDate,
        end: CalendarUtils.addDays(viewMinDate, 6),
      },
      dataDateRange: {
        start: CalendarUtils.subDaysOrToday(viewMinDate, 14),
        end: CalendarUtils.addMonths(viewMinDate, 1),
      },
    };

    if (selectionDateRange) {
      const dateRangeToCheck = {
        start: selectionDateRange.start,
        end: CalendarUtils.subDays(selectionDateRange.end, 1),
      };
      if (CalendarUtils.isRangeInRange(dateRangeToCheck, ranges.dataDateRange)) {
        ranges.selectionDateRange = selectionDateRange;
      }
    }

    return ranges;
  },

  onDayClicked: (
    dt: string,
    selectedDateRange?: DateRange
  ) : DateRange | null => {
    if (!selectedDateRange) {
      return {
        start: dt,
        end: CalendarUtils.addDays(dt, 1),
      };
    }
    if (CalendarUtils.isDateInRange(dt, selectedDateRange)) {
      return {
        start: dt,
        end: CalendarUtils.addDays(dt, 1),
      }
    }

    const previousDay = CalendarUtils.subDays(selectedDateRange.start, 1);
    if (previousDay === dt) {
      return {
        start: previousDay,
        end: selectedDateRange.end,
      }
    }

    if (selectedDateRange.end === dt) {
      return {
        start: selectedDateRange.start,
        end: CalendarUtils.addDays(selectedDateRange.end, 1),
      }
    }

    return {
      start: dt,
      end: CalendarUtils.addDays(dt, 1),
    };
  },
  areAllRoomsUnavailable: (rates: HotelCalendarAvailAndRatesOutputRates, dt: string) : boolean => {
    const roomCodes = Object.keys(rates);
    for (let i = 0; i < roomCodes.length; i++) {
      const roomCode = roomCodes[i];
      if (rates[roomCode][dt].isAvailable) {
        return false;
      }
    }
    return true;
  },

  onSubDays: async (checkoutState: CheckoutState, days: number) => {
    const currentStateData = checkoutState.currentStepData as CheckoutStepCalendarData;
    const dateRanges = currentStateData.dateRanges as CheckoutStepCalendarDataDateRanges;
    const viewDateRange = dateRanges.viewDateRange as DateRange;

    const newFirstDay = CalendarUtils.subDaysOrToday(viewDateRange.start, days);
    const newLastDay = CalendarUtils.addDays(newFirstDay, 6);
    const newRange = {
      start: newFirstDay,
      end: newLastDay,
    }

    // if data is already loaded
    if (CalendarUtils.isRangeInRange(newRange, dateRanges.dataDateRange)) {
      await checkoutState.setCalendarViewDatesWithoutLoading(newRange);
      return;
    }

    // otherwhise
    const ranges = CheckoutCalendarUtils.getRanges(newRange, dateRanges.selectionDateRange);
    await checkoutState.setCalendarRangesAndReload(ranges);
  },
  onAddDays: async  (checkoutState: CheckoutState, days: number) => {
    const currentStateData = checkoutState.currentStepData as CheckoutStepCalendarData;
    const dateRanges = currentStateData.dateRanges as CheckoutStepCalendarDataDateRanges;
    const viewDateRange = dateRanges.viewDateRange as DateRange;

    const today = CalendarUtils.getToday();
    const limitDay = CalendarUtils.addDays(today, 100);
    const newLastDay = CalendarUtils.addDaysWithLimit(viewDateRange.end, days, limitDay);
    const newFirstDay = CalendarUtils.subDaysOrToday(newLastDay, 6);

    const newRange = {
      start: newFirstDay,
      end: newLastDay,
    }

    // if data is already loaded
    if (CalendarUtils.isRangeInRange(newRange, dateRanges.dataDateRange)) {
      await checkoutState.setCalendarViewDatesWithoutLoading(newRange);
      return;
    }

    // otherwhise
    const ranges = CheckoutCalendarUtils.getRanges(newRange, dateRanges.selectionDateRange);
    await checkoutState.setCalendarRangesAndReload(ranges);
  },

  getTotalPrice: (
    roomCode: string,
    checkoutState: CheckoutState
  ) : number | null => {
    const currentStateData = checkoutState.currentStepData as CheckoutStepCalendarData;
    const dateRanges = currentStateData.dateRanges as CheckoutStepCalendarDataDateRanges;
    const rates = currentStateData.rates as HotelCalendarAvailAndRatesOutputRates;

    if (!dateRanges.selectionDateRange) {
      return null;
    }

    let total = 0;
    const dateRangeIterator = CalendarUtils.getDateRangeIterator(dateRanges.selectionDateRange, true);
    for (let i = 0;  i < dateRangeIterator.length; i++) {
      const dt = dateRangeIterator[i];
      const cell = rates[roomCode][dt];

      if (!cell.isAvailable) {
        return null;
      }
      total += cell.price as number;
    }
    return total;
  },

  onTotalPriceClicked: (
    roomCode: string,
    checkoutState: CheckoutState,
    searchFormState: SearchFormState,
    modalState: ModalState
  ) => {
    const currentStateData = checkoutState.currentStepData as CheckoutStepCalendarData;
    const dateRanges = currentStateData.dateRanges as CheckoutStepCalendarDataDateRanges;

    if (!dateRanges.selectionDateRange || !checkoutState.searchParams) {
      return;
    }

    searchFormState.setDateRange(dateRanges.selectionDateRange);
    searchFormState.setRooms([checkoutState.searchParams.rooms[0]])
    SearchFormUtils.submit(searchFormState, modalState, roomCode);
  },
};

export default CheckoutCalendarUtils;
