import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import SentryUtils from "@/src/common/utils/sentry";
import {Route} from "@sentry/react/types/reactrouterv3";

const ElbaMediaUtils = {
  getData: async (appInfo: AppInfo, page?: string) : Promise<NetworkResponse> => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    if (authHeaders === null) {
      throw new Error('You cannot get elba media if you are not logged in');
    }

    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.getElbaMedia(appInfo, authHeaders, page);
  },
  getGolfData: async (appInfo: AppInfo, page?: string, path?: string) : Promise<NetworkResponse> => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    if (authHeaders === null) {
      throw new Error('You cannot get elba media golf if you are not logged in');
    }

    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.getElbaMediaGolf(appInfo, authHeaders, page, path);
  },
};

export default ElbaMediaUtils;
