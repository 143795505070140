import FormInputBasicSelect from "@/src/ui/components/form/FormInputBasicSelect";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {
  SearchFormRoomOccupationWidgetProps
} from "@/src/ui/components/search-form/SearchFormRoomOccupationWidget/types";
import {useDevice} from "@/src/ui/hooks/useDevice";
import _Desktop from "@/src/ui/components/search-form/SearchFormRoomOccupationWidget/desktop";
import _Mobile from "@/src/ui/components/search-form/SearchFormRoomOccupationWidget/mobile";
import {useTranslation} from "next-i18next";

const SearchFormRoomOccupationWidget = (props: SearchFormRoomOccupationWidgetProps) : JSX.Element => {
  const { t } = useTranslation();
  const device = useDevice();
  const _Component = device.isDesktop ? _Desktop : _Mobile;
  return (
    <div className="space-y-4">
      {props.roomIndex !== undefined && (
        <p className="text-lg font-bold">
          {t('SearchFormRoomOccupationWidget._Index.room_label', {
            index: props.roomIndex + 1
          })}
        </p>
      )}
      <_Component {...props} />
      {props.occupation.kids > 0 && (
        <ul className="space-y-3.5">
          {props.occupation.kid_ages.map((kidAge, kidIndex) => (
            <li className="flex items-center" key={kidIndex}>
              <div className="w-36 mr-2">{t('SearchFormRoomOccupationWidget._Index.kid_age_label', {
                index: kidIndex + 1
              })}</div>
              <div className="w-52">
                <FormInputBasicSelect
                  value={kidAge.toString()}
                  options={SearchParamsUtils.getKidAgeOptions(t, props.kidsMaxAge)}
                  onChange={newKidAge => props.onKidAgeChange(kidIndex, parseInt(newKidAge))}
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchFormRoomOccupationWidget;
