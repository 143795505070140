import {T_ParagraphUserEditForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserEditForm";
import _UserData from "@/src/ui/components/blocks/BlockUserEditForm/desktop/user-data";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import _Loading from "@/src/ui/components/blocks/BlockUserEditForm/desktop/loading";
import {PropsWithChildren} from "react";
import _UserTripStyle from "@/src/ui/components/blocks/BlockUserEditForm/generic/user-trip-style";
import {CMS_Language} from "@/src/core/app/domain/cms/@types/language/CMS_Language";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

interface _Props extends T_ParagraphUserEditForm {
  languages: CMS_Language[]
}

const _Wrapper = (props: PropsWithChildren<_Props>) => (
  <div className="space-y-7.5">
    <TitleWithTag
      className="title-4 font-calistoga"
      field={props.field_title_with_tag}
    />
    {props.children}
  </div>
);

const BlockUserEditFormDesktop = (props: _Props) => {
  const { t } = useTranslation();
  const userState = useUserProvider(state => state);

  if (!userState.isInitiated) {
    return (
      <_Wrapper {...props}>
        <_Loading />
      </_Wrapper>
    );
  }

  if (userState.userData === null) {
    return (
      <_Wrapper {...props}>
        <p>{t('BlockUserEditForm._Index.unauthenticated_message')}</p>
      </_Wrapper>
    );
  }

  return (
    <_Wrapper {...props}>
      <_UserData
        userData={userState.userData}
        countries={props.countries}
        languages={props.languages}
      />
      <_UserTripStyle
        userData={userState.userData}
        countries={props.countries}
      />
    </_Wrapper>
  );
};

export default BlockUserEditFormDesktop;
