import type {RouteProps} from "@/src/ui/@types/RouteProps";
import _Wrapper from "@/src/ui/components/routes/Route_ElbaMediaGolf/wrapper";
import {useTranslation} from "next-i18next";
import _Loading from "@/src/ui/components/blocks/BlockUserEditForm/desktop/loading";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import NotificationMessage from "src/ui/components/generic/NotificationMessage";
import {T_Notification} from "@/src/ui/@types/T_Notification";
import {PropsWithChildren} from "react";
import Pagination from "@/src/ui/components/generic/Pagination";
import PagerUtils from "@/src/core/app/utils/pager";
import { useElbaMediaGolfProvider } from "@/src/ui/providers/elba-media-golf.provider";
import ElbaMediaGolfErrors from "@/src/core/app/domain/elba-media/@types/FormErrorMap/ElbaMediaGolfErrors";
import {ElbaMediaGolfSuccessOutput} from "@/src/core/app/domain/elba-media/@types/Output/ElbaMediaGolfSuccessOutput";
import _RowFile from "@/src/ui/components/routes/Route_ElbaMediaGolf/row-file";
import {ElbaMediaGolfItemFile} from "@/src/core/app/domain/elba-media/@types/ElbaMediaGolfItemFile";
import _RowFolder from "@/src/ui/components/routes/Route_ElbaMediaGolf/row-folder";
import {ElbaMediaGolfItemFolder} from "@/src/core/app/domain/elba-media/@types/ElbaMediaGolfItemFolder";
import UiElbaMediaUtils from "@/src/ui/utils/elba-media";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_ElbaMediaGolf = (props: RouteProps) : JSX.Element => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const state = useElbaMediaGolfProvider(state => state);

  if (state.isLoading) {
    return (
      <_Wrapper>
        <_Loading />
      </_Wrapper>
    );
  }

  if (!state.isUserAllowed) {
    return (
      <_Wrapper>
        <p>{t('Route_ElbaMedia._Index.unauthorized_message')}</p>
      </_Wrapper>
    );
  }

  if (state.error) {
    const error = FormErrorUtils.getError({
      networkError: state.error,
      formErrorMap: ElbaMediaGolfErrors
    });
    return (
      <_Wrapper>
        <NotificationMessage {...(error.notification as T_Notification)} />
      </_Wrapper>
    );
  }

  const data = state.data as ElbaMediaGolfSuccessOutput
  const pager = PagerUtils.getPager(props.route, 20, data.count);
  const breadcrumb = UiElbaMediaUtils.getGolfBreadcrumb(t, props.route, appInfo, data.hierarchy);
  const parentFolderUrl = UiElbaMediaUtils.getGolfParentFolder(breadcrumb);

  return (
    <_Wrapper paddingClass=''>
      <BreadcrumbWrapper breadcrumb={breadcrumb} />
      <table className="ElbaMedia_Table">
        <thead>
          <tr>
            <th>{t('Route_ElbaMedia._Index.title_label')}</th>
            <th>{t('Route_ElbaMedia._Index.description_label')}</th>
            <th>{t('Route_ElbaMedia._Index.file_label')}</th>
            <th>{t('Route_ElbaMedia._Index.created_label')}</th>
          </tr>
        </thead>
        <tbody>
        {parentFolderUrl && (
          <tr>
            <td colSpan={4}>
              <a href={parentFolderUrl} className="flex space-x-0.5 items-center">
                <img src="/assets/icons/file-navigation-go-back.svg" alt=""/>
                <span>Parent directory</span>
              </a>
            </td>
          </tr>
        )}
        {data.items.map(item => {
          if (item.type === 'file') {
            return (
              <_RowFile key={item.id} item={item as ElbaMediaGolfItemFile}/>
            );
          }
          if (item.type === 'folder') {
            return (
              <_RowFolder
                key={item.id}
                item={item as ElbaMediaGolfItemFolder}
                route={props.route}
              />
            );
          }
        })}
        </tbody>
      </table>
      {pager.pagesCount > 1 && (
        <Pagination {...pager} />
      )}
    </_Wrapper>
  );
}

Route_ElbaMediaGolf.getAppWrapper = (
  props: PropsWithChildren<RouteProps>
) => (
  <useElbaMediaGolfProvider.State>
    {props.children}
  </useElbaMediaGolfProvider.State>
)

export default Route_ElbaMediaGolf;
