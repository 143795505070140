import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useDevice} from "@/src/ui/hooks/useDevice";
import Route_JobOfferDetailDesktop from "@/src/ui/components/routes/Route_JobOfferDetail/desktop";
import Route_JobOfferDetailMobile from "@/src/ui/components/routes/Route_JobOfferDetail/mobile";
import {T_NodeJobOfferDetail} from "@/src/core/app/domain/models/node/T_NodeJobOfferDetail";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_JobOfferDetail = (props: RouteProps<T_NodeJobOfferDetail>) : JSX.Element => {
  const device = useDevice();
  const _Component = device.isDesktop ? Route_JobOfferDetailDesktop : Route_JobOfferDetailMobile;
  return (
    <>
      <PageHeader header={props.routeData.data.page_header} />
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <_Component {...props} />
    </>
  );
}

export default Route_JobOfferDetail;
