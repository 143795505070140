import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import _Summary from "@/src/ui/components/portals/desktop/ModalHotelRoom/_Summary";
import _Gallery from "@/src/ui/components/portals/desktop/ModalHotelRoom/_Gallery";

const ModalHotelRoomDesktop = () => {
  const modalState = useModalProvider(state => state);
  const room = modalState.modalData.room as T_HotelRoomBasic;

  return (
    <ModalLayoutFullscreen onCloseModal={() => modalState.closeModal()}>
      <div className="pt-15 flex-1 overflow-y-auto">
        <_Summary {...room} />
        <_Gallery images={room.ui_cpt_field_room_media_popup} />
      </div>
    </ModalLayoutFullscreen>
  )
};

export default ModalHotelRoomDesktop;
