import {useDevice} from "@/src/ui/hooks/useDevice";
import {T_ParagraphUserTmsInfo} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserTmsInfo";
import BlockUserTmsInfoDesktop from "@/src/ui/components/blocks/BlockUserTmsInfo/desktop";
import BlockUserTmsInfoMobile from "@/src/ui/components/blocks/BlockUserTmsInfo/mobile";

const BlockUserTmsInfo = (props: T_ParagraphUserTmsInfo) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockUserTmsInfoMobile : BlockUserTmsInfoDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockUserTmsInfo;
