import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStepRoomsStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsStatus";
import _CheckoutStatusOk from "@/src/ui/components/blocks/BlockHotelRoomList/mobile/checkout-status-ok";
import {CheckoutRates} from "@/src/core/app/domain/models/checkout/CheckoutRates";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {T_HotelSectionDataRoom} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataRoom";
import _CheckoutStatusLoading from "@/src/ui/components/blocks/BlockHotelRoomList/mobile/checkout-status-loading";
import HotelRoomTeaserMobile from "@/src/ui/components/hotel-room/mobile/HotelRoomTeaser";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import CheckoutStepRoomsErrors from "@/src/core/app/domain/checkout/@types/FormErrorMap/checkout-step-rooms";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import NotificationMessage from "src/ui/components/generic/NotificationMessage";
import {T_Notification} from "@/src/ui/@types/T_Notification";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";
import CheckoutUtils from "@/src/core/app/utils/checkout";

const BlockHotelRoomListMobile = (props: T_ParagraphHotelRoomList) => {
  const routeData = useRouteData<T_HotelSection<T_HotelSectionDataRoom>>();
  const state = useCheckoutProvider(state => state);
  let rooms = routeData.data.ui_hotel_section.data.rooms;

  if (CheckoutUtils.isCheckoutReadyToShowRooms(state)) {
    const stepData = state.currentStepData as CheckoutStepRoomsData;

    if (stepData.status === CheckoutStepRoomsStatus.LOADING) {
      return (
        <_CheckoutStatusLoading {...props} />
      );
    }

    if (stepData.status === CheckoutStepRoomsStatus.ERROR) {
      const error = FormErrorUtils.getError({
        networkError: stepData.error as NetworkResponseError,
        formErrorMap: CheckoutStepRoomsErrors,
      });
      return (
        <Section>
          <NotificationMessage {...(error.notification as T_Notification)} />
        </Section>
      );
    }

    if (stepData.status === CheckoutStepRoomsStatus.OK) {
      return (
        <_CheckoutStatusOk
          {...props}
          rooms={rooms}
          rates={stepData.rates as CheckoutRates}
        />
      )
    }
  }

  rooms = rooms.filter(room => !room.list_hidden);

  return (
    <Section>
      <MaxWidthWrapper>
        <TitleWithTag
          field={props.field_title_with_tag}
          className="title-2-mobile pt-2.5 pb-7.5 font-calistoga"
        />
        <ul className="space-y-5 pb-2.5">
          {rooms.map((room, index) => (
            <li key={index}>
              <HotelRoomTeaserMobile hotelRoom={room} />
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  )
};

export default BlockHotelRoomListMobile;
