import type { ReactNode } from "react";
import Anchor from "@/src/ui/components/generic/Anchor";
import {CMS_Path} from "@/src/core/app/domain/cms/@types/common/CMS_Path";

interface ArrowLinkProps {
  children: ReactNode
  href?: string
  path?: CMS_Path
}

const ArrowLink = ({ children, ...props }: ArrowLinkProps): JSX.Element => (
  <Anchor
    className="items-center text-sm text-brand-2-90 hover:underline flex"
    href={props.href}
    path={props.path}
  >
    <span>{children}</span>
    <img className="inline ml-2" src="/assets/icons/arrow.svg" alt="" />
  </Anchor>
);

export default ArrowLink;
