import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";
import {FormErrorHandleFnProps} from "@/src/core/app/domain/forms/@types/FormErrorHandleFnProps";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {UserTmsAssignErrorOutput} from "@/src/core/app/domain/user/@types/Output/UserTmsAssignErrorOutput";

const UserTmsAssignErrors : FormErrorMap = {
  'tms-integration-disabled': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'global.generic_notification.error_title',
      message: 'user_tms_assign.tms-integration-disabled.error_message',
    }
  },
  'user-should-be-logged-in': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'global.generic_notification.error_title',
      message: 'user_tms_assign.user-should-be-logged-in.error_message',
    }
  },
  'tms-client-id-already-assigned': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'global.generic_notification.error_title',
      message: 'user_tms_assign.tms-client-id-already-assigned.error_message',
    }
  },
  'client-id-validation-assigned-to-other-user': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'global.generic_notification.error_title',
      message: 'user_tms_assign.client-id-validation-assigned-to-other-user.error_message',
    }
  },
  'client-id-card-disabled': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'global.generic_notification.error_title',
      message: 'user_tms_assign.client-id-card-disabled.error_message',
    }
  },
  'client-id-card-not-signed': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'global.generic_notification.error_title',
      message: 'user_tms_assign.client-id-card-not-signed.error_message',
    }
  },
  'client-id-validation-unknown': {
    action: (props: FormErrorHandleFnProps) => {
      const error = props.networkError.data as UserTmsAssignErrorOutput;
      props.modalState.toggleModal(ModalKey.NOTIFICATION, {
        type: T_NotificationType.WARNING,
        title: 'global.generic_notification.error_title',
        message: error.errorMessage,
      })
    }
  },
}

export default UserTmsAssignErrors;
