import {
  SearchFormRoomOccupationWidgetProps
} from "@/src/ui/components/search-form/SearchFormRoomOccupationWidget/types";
import {useTranslation} from "next-i18next";

const _Desktop = (props: SearchFormRoomOccupationWidgetProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex items-center">
        <img className="w-3.5 h-auto" src="/assets/icons/person-brand-2.svg" alt="" />
        <div className="text-lg w-32 pl-2">{t('SearchFormRoomOccupationWidget._Index.adults_label')}</div>
        <button onClick={props.onSubAdult}>
          <img src="/assets/icons/minus-circle.svg" />
        </button>
        <div className="w-14 mx-0.5 text-sm text-center">
          {props.occupation.adults}
        </div>
        <button onClick={props.onAddAdult}>
          <img src="/assets/icons/plus-circle.svg" />
        </button>
      </div>
      {props.kidsMaxAge !== 0 && (
        <div className="flex items-center">
          <img className="w-3.5 h-auto" src="/assets/icons/person-brand-2.svg" alt=""/>
          <div className="text-lg w-32 pl-2">{t('SearchFormRoomOccupationWidget._Index.kids_label')}</div>
          <button onClick={props.onSubKid}>
            <img src="/assets/icons/minus-circle.svg"/>
          </button>
          <div className="w-14 mx-0.5 text-sm text-center">
            {props.occupation.kids}
          </div>
          <button onClick={props.onAddKid}>
            <img src="/assets/icons/plus-circle.svg"/>
          </button>
          <div className="pl-5 text-xs text-brand-2-60">
            {t('SearchFormRoomOccupationWidget._Index.kids_max_age_label', {
              amount: props.kidsMaxAge
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default _Desktop;
