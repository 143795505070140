import React from "react";
import FooterDesktop from "./FooterDesktop";
import HeaderDesktopHome from "./HeaderDesktopHome";
import HeaderDesktopGeneric from "./HeaderDesktopGeneric";
import PortalsDesktop from "../../portals/desktop/PortalsDesktop";
import LayoutConversionHeaderDesktop from "./LayoutConversionHeaderDesktop";
import type {LayoutProps} from "@/src/ui/@types/layout/LayoutProps";
import {LayoutType} from "@/src/ui/@types/layout/Layout";

const LayoutDesktop = (props: LayoutProps) => (
  <PortalsDesktop {...props}>
    {props.routeProps.layout.type === LayoutType.HOME && <HeaderDesktopHome {...props.routeProps}/>}
    {props.routeProps.layout.type === LayoutType.GENERIC && <HeaderDesktopGeneric {...props.routeProps}/>}
    {props.routeProps.layout.type === LayoutType.CONVERSION && <LayoutConversionHeaderDesktop />}
    <main>{props.children}</main>
    <FooterDesktop />
  </PortalsDesktop>
);

export default LayoutDesktop;
