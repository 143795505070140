import {TFunction} from "react-i18next";

const _passwordValidation = (t: TFunction, values: any) => {
  const errors : Record<string, string> = {};

  if (values.newPassword1 && values.newPassword2) {
    if (values.newPassword1 !== values.newPassword2) {
      errors.password1 = t('global.validation.password_validation_error_message');
      errors.password2 = t('global.validation.password_validation_error_message');
    }
  }
  return errors;
};

export default _passwordValidation;
