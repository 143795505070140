import Section from "../../generic/Section/Section";
import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";
import {CheckoutStepCalendarData} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarData";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";

interface _Props {
  searchParams: SearchParams
}

const HotelSearchParamsSummary = ({ searchParams }: _Props): JSX.Element => {
  const checkoutState = useCheckoutProvider(state => state);
  let rooms = searchParams.rooms;
  let dateRange: SearchParamsDateRange | null = searchParams.dateRange;

  if (checkoutState.isInitiated && checkoutState.currentStep === CheckoutStep.CALENDAR) {
    // Calendar show prices for just one room
    rooms = [rooms[0]];

    // We show current date range selection
    const currentStateData = checkoutState.currentStepData as CheckoutStepCalendarData;
    if (currentStateData.dateRanges?.selectionDateRange) {
      dateRange = currentStateData.dateRanges?.selectionDateRange;
    }
    else {
      dateRange = null;
    }
  }

  const { t } = useTranslation();
  return (
    <Section paddingY="pt-7.5">
      <MaxWidthWrapper>
        <div className="p-5 flex items-center space-x-10 text-sm text-brand-2 bg-gray-10 border border-gray-20">
          <h2 className="flex-none text-medium-bold uppercase w-60">
            {t('HotelSearchParamsSummary._Index.title')}
          </h2>
          <div className="flex-1 space-y-2">
            <h3 className="font-bold text-brand-2-60">{t('HotelSearchParamsSummary._Index.dates_label')}</h3>
            {dateRange && (
              <p>
                <time>{SearchParamsFormatter.formatRange(dateRange)}</time>
                <span>{" "}</span>
                <span>({SearchParamsFormatter.getDateRangeNightsSummary(dateRange, t)})</span>
              </p>
            )}
            {!dateRange && (
              <p>-</p>
            )}
          </div>
          <div className="flex-1 space-y-2">
            <h3 className="font-bold text-brand-2-60">{t('HotelSearchParamsSummary._Index.rooms_label')}</h3>
            <p>
              <span>{SearchParamsFormatter.getRoomsSummary(rooms, t)}</span>
              <span>{", "}</span>
              <span>{SearchParamsFormatter.getOccupationSummary(rooms, t)}</span>
            </p>
          </div>
          {/*<button className="flex-none font-bold underline">Cambiar</button>*/}
        </div>
      </MaxWidthWrapper>
    </Section>
  );
}

export default HotelSearchParamsSummary;
