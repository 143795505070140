import React, {useEffect, useRef} from "react";
import {CheckoutRateRoomAndRoomTeaser} from "@/src/core/app/domain/models/checkout/CheckoutRateRoomAndRoomTeaser";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import HotelRoomTeaserCommonMobile from "@/src/ui/components/hotel-room/mobile/HotelRoomTeaserCommon";
import LowestPriceTagMobile from "@/src/ui/components/mobile/LowestPriceTag";
import CheckoutRateGroupMobile from "@/src/ui/components/checkout/mobile/CheckoutRateGroup";

interface _Props {
  room: CheckoutRateRoomAndRoomTeaser
  onClick: (
    room: CheckoutRateRoomAndRoomTeaser,
    rateGroup: CheckoutRatesHotelRoomRateGroup,
    board: CheckoutRatesHotelRoomRateGroupBoard
  ) => void
}

const HotelRoomTeaserWithRatesMobile = (props: _Props) => {
  const ref = useRef(null);
  const lowestPrice = CheckoutUtils.getRoomLowestPrice(props.room.rateRoom);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!CheckoutUtils.isRoomCodeInUrl(props.room.roomTeaser.code)) {
      return;
    }
      // @ts-ignore
    const height = document.querySelector('#he-layout-header-mobile').scrollHeight;
    // @ts-ignore
    document.querySelector('html').scrollTo({
      left: 0,
      // @ts-ignore
      top: ref.current.offsetTop - height - 10,
      behavior: "smooth",
    });
  }, []);

  return (
    <div ref={ref}>
      <HotelRoomTeaserCommonMobile
        hotelRoom={props.room.roomTeaser}
        rightTopComponent={(
          <LowestPriceTagMobile lowestPrice={lowestPrice} />
        )}
        footerComponent={(
          <>
            {props.room.rateRoom.rate_groups.map(rateGroup => (
              <CheckoutRateGroupMobile
                key={rateGroup.code}
                rateGroup={rateGroup}
                onClick={(
                  rateGroup: CheckoutRatesHotelRoomRateGroup,
                  board: CheckoutRatesHotelRoomRateGroupBoard
                ) => props.onClick(props.room, rateGroup, board)}
              />
            ))}
           </>
         )}
      />
    </div>
  )
}

export default HotelRoomTeaserWithRatesMobile;
