import {T_ParagraphHotelsMap} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelsMap";
import _Map from "@/src/ui/components/blocks/BlockHotelsMap/generic/map";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";

interface _Props {
  hotelList: T_HotelTreeHotel[]
  paragraph: T_ParagraphHotelsMap
}

const BlockHotelsMapDesktop = (props: _Props) => {
  return (
    <_Map
      hotelList={props.hotelList}
      className='h-109.5'
    />
  )
}

export default BlockHotelsMapDesktop;