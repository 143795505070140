import {
  T_HotelSectionHotelCommonInfo
} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";
import {CMS_FieldLink} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldLink";
import Anchor from "@/src/ui/components/generic/Anchor";

interface _Props {
  hotel: T_HotelSectionHotelCommonInfo
}

const socialNetworks = [
  {
    key: 'instagram',
    field: 'field_social_network_ig',
    white: "/assets/icons/instagram.svg",
    circle: "/assets/icons/instagram-circle-white.svg",
  },
  {
    key: 'facebook',
    field: 'field_social_network_fb',
    white: "/assets/icons/facebook.svg",
    circle: "/assets/icons/facebook-circle-white.svg",
  },
  {
    key: 'twitter',
    field: 'field_social_network_tw',
    white: "/assets/icons/twitter.svg",
    circle: "/assets/icons/twitter-circle-white.svg",
  },
  {
    key: 'youtube',
    field: 'field_social_network_youtube',
    white: "/assets/icons/youtube.svg",
    circle: "/assets/icons/youtube-circle-white.svg",
  },
];


const getLink = (hotel: T_HotelSectionHotelCommonInfo, field: string) : CMS_FieldLink | null => {
  // @ts-ignore
  return hotel[field];
};

const _SocialNetworks = (props: _Props): JSX.Element => (
  <ul className="flex space-x-2.5 overflow-x-auto">
    {socialNetworks.map((sn) => {
      const link = getLink(props.hotel, sn.field);
      if (link === null) {
        return;
      }
      return (
        <li key={sn.key}>
          <Anchor href={link.uri}>
            <img
              className="h-7 w-auto bg-white hover:bg-gray-20 rounded-full"
              src={sn.circle}
              alt={link.title || sn.key}
            />
          </Anchor>
        </li>
      );
    })}
  </ul>
);

export default _SocialNetworks;
