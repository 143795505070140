import InputWrapper, { InputWrapperProps } from "../InputWrapper";
import {ChangeEvent, MouseEventHandler} from "react";

interface InputText {
  className?: string
  onClick?: MouseEventHandler
  onChange?: (value: string) => void
  icon?: string
  placeholder: string
  color?: InputWrapperProps["color"]
  writable?: boolean
  value?: string
}

const InputText = ({
  icon,
  color,
  onChange,
  writable = false,
  ...props
}: InputText): JSX.Element => {
  let inputProps : Record<string, any> = {};

  if (writable) {
    inputProps = {
      placeholder: props.placeholder,
      value: props.value,
    };

    if (onChange) {
      (inputProps as Record<string, any>)['onChange'] = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }
    }
  }

  return (
    <div className={props.className} onClick={props.onClick}>
      <InputWrapper
        color={color}
        className='space-x-1.5 overflow-hidden flex items-stretch'
      >
        {icon && <img src={icon} alt="" />}
        {writable ? (
          <input
            type="text"
            className="bg-transparent flex-1 text-sm placeholder-brand-2-40 leading-none"
            {...inputProps}
          />
        ) : (
          <span className="bg-transparent flex-1 flex items-center text-sm text-brand-2-40 leading-none">
          {props.placeholder}
        </span>
        )}
      </InputWrapper>
    </div>
  );
}

export default InputText;
