import HeaderCovidInfo from "../generic/HeaderCovidInfo";
import HeaderDesktop from "./HeaderDesktop";
import type {RouteProps} from "@/src/ui/@types/RouteProps";

const HeaderDesktopHome = (props: RouteProps): JSX.Element => (
  <>
    {/*<HeaderCovidInfo />*/}
    <HeaderDesktop {...props}/>
  </>
);

export default HeaderDesktopHome;
