export function arrayFromOneToN(params: {
  count: number,
  start?: number,
  max?: number
}): number[] {
  const {
    count,
    start = 1,
    max,
  } = params || {};

  const array = [];
  for (let i = start; i < (count + start); i++) {
    array.push(i);
    if (max && array.length === max) {
      return array;
    }
  }
  return array;
}
