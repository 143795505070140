import FormInputText from "@/src/ui/components/formik/FormInputText";
import Button from "@/src/ui/components/generic/Button";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import NarrowFormBox from "@/src/ui/components/generic/NarrowFormBox";
import FormUtils from "@/src/ui/utils/form";
import {UserRegisterStepProps} from "@/src/ui/components/routes/Route_UserRegister/steps/UserRegisterStep";
import FormTermsAndConditions from "@/src/ui/components/formik/FormTermsAndConditions";
import _AlreadyRegistered from "@/src/ui/components/routes/Route_UserRegister/steps/_AlreadyRegistered";
import {useTranslation} from "next-i18next";

const UserRegisterStep1 = (props: UserRegisterStepProps) => {
  const { t } = useTranslation();
  return (
    <NarrowFormBox title={t('Route_UserRegister.UserRegisterStep1.title')}>
      <div className="space-y-3.75">
        <FormInputText
          name='email'
          label={t('Route_UserRegister.UserRegisterStep1.email_label') + " *"}
          validations={[FormUtils.validateIsRequired, FormUtils.validateIsEmail]}
        />
        <div>
          <FormInputText
            name='password1'
            type='password'
            label={t('Route_UserRegister.UserRegisterStep1.password1_label') + " *"}
            validate={FormUtils.validateIsRequired}
          />
        </div>
        <FormInputText
          name='password2'
          type='password'
          label={t('Route_UserRegister.UserRegisterStep1.password2_label') + " *"}
          validate={FormUtils.validateIsRequired}
        />
        {/*<FormInputCheckbox*/}
        {/*  name='newsletter'*/}
        {/*  color="brand-2"*/}
        {/*  >*/}
        {/*  ¿Quieres recibir ofertas en tu mail?*/}
        {/*</FormInputCheckbox>*/}
        <FormTermsAndConditions
          name='termsAndConditions'
          label2={t('Route_UserRegister.UserRegisterStep1.terms_and_conditions_label') + " *"}
        />
      </div>
      <div>
        <Button
          text={t('Route_UserRegister.common.next_step_button_label')}
          bold
          onClick={props.formik.handleSubmit}
          isActive={!props.formik.isSubmitting}
        />
      </div>
      <VerisignImg />
      <_AlreadyRegistered />
    </NarrowFormBox>
  );
}

export default UserRegisterStep1;
