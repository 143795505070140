import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import {useTranslation} from "next-i18next";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";

const StickyCheckoutStepRoomsRoomSummary = (): JSX.Element | null => {
  const { t } = useTranslation();
  const device = useDevice();
  const currencyConfig = useCurrencyConfig();
  const state = useCheckoutProvider(state => state);

  if (!CheckoutUtils.isCheckoutReadyToShowRooms(state)) {
    return null;
  }

  const stepData = state.currentStepData as CheckoutStepRoomsData;
  const roomIndex = stepData.currentRoomIndex;
  const searchParams = state.searchParams as SearchParams;
  const room = searchParams.rooms[roomIndex];

  if (searchParams.rooms.length === 1) {
    return null;
  }

  const totalAmount = CheckoutUtils.getTotalAmountRooms(state.rooms);

  return (
    <div className='fixed w-full bottom-0 left-0 z-20 bg-gray-10 border-t-2 border-gray-40 py-3.75'>
      <div className={cn(
        device.isDesktop && "max-w-295 mx-auto",
        device.isMobile && "px-5",
      )}>
        <p className='font-bold text-brand-2 text-sm'>
          {t('StickyCheckoutStepRoomsRoomSummary._Index.summary_label',{
            roomIndex: roomIndex + 1,
            summary: SearchParamsFormatter.getRoomOccupationDetailedSummary(room, t)
          })}
        </p>
        <div>
          <p className='text-brand-2 text-sm'>
            <span className='text-lg'>{UiCurrencyUtils.format(totalAmount, currencyConfig)}</span> total
          </p>
        </div>
      </div>
    </div>
  );
}

export default StickyCheckoutStepRoomsRoomSummary;
