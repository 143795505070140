import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import Section from "../../generic/Section/Section";
import cn from "classnames";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useDevice} from "@/src/ui/hooks/useDevice";
import { Breadcrumb } from "@/src/core/app/domain/@types/Breadcrumb";
import _Item from "@/src/ui/components/generic/BreadcrumbWrapper/item";

interface _Props {
  breadcrumb?: Breadcrumb
}

const BreadcrumbWrapper = (props: _Props): JSX.Element | null => {
  const device = useDevice();

  if (!props.breadcrumb) {
    return null;
  }

  return (
    <Section paddingY={device.isMobile ? "pt-5" : "pt-7.5"}>
      <MaxWidthWrapper>
        <ul className="flex flex-wrap text-brand-3-60 text-xs gap-x-4" itemType="https://schema.org/BreadcrumbList">
          {props.breadcrumb.map((item, index) => (
            <li
              className="pt-2.5 flex items-baseline space-x-4"
              key={index}
            >
              {!!index && (
                <img
                  className="h-2 w-auto"
                  src="/assets/icons/chevron-right-brand-3-60.svg"
                  alt=""
                />
              )}
              <span
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <meta itemProp="position" content={`${index}`}/>
                <_Item
                  item={item}
                  className={cn(
                    props.breadcrumb && index === props.breadcrumb.length - 1 && "text-brand-3",
                    item.url && 'hover:underline',
                    "whitespace-nowrap"
                  )}
                />
              </span>
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  );
}

export default BreadcrumbWrapper;
