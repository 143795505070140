import { ReactNode } from "react";
import Anchor from "@/src/ui/components/generic/Anchor";

interface ReturnLinkProps {
  children: ReactNode
  href: string | null
}

const ReturnLink = ({ children, href }: ReturnLinkProps): JSX.Element => (
  <Anchor
    className="flex items-center space-x-2.5 text-sm text-brand-2 hover:underline"
    href={href || undefined}
  >
    <img
      className="transform rotate-180 h-4 w-auto"
      src="/assets/icons/chevron-right-brand-2.svg"
      alt=""
    />
    <span>{children}</span>
  </Anchor>
);

export default ReturnLink;
