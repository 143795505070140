import { ReactNode } from "react";
import Paragraph from "../Paragraph";

interface LocationTagProps {
  children: ReactNode
  itemProp?: string
}

const LocationTag = ({ children, itemProp }: LocationTagProps): JSX.Element => (
  <div className="flex space-x-2 text-brand-2">
    <img src="/assets/icons/place.svg" alt="" />
    <Paragraph
      size="medium"
      itemProp={itemProp}
    >{children}</Paragraph>
  </div>
);

export default LocationTag;
