import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";
import Anchor from "@/src/ui/components/generic/Anchor";
import {ReactNode} from "react";

interface _RowLinkProps {
  item: CMS_MenuItem
}

interface _SitemapMenuProps {
  title: string
  items: CMS_MenuItem[]
  extra?: ReactNode
  showParents?: boolean
}

const _CmsRowLink = (props: _RowLinkProps) => (
  <li>
    <Anchor href={props.item.url}>
      {props.item.title}
    </Anchor>
  </li>
);

interface _CmsRowSubmenuProps extends _RowLinkProps {
  showParents?: boolean
}

const _CmsRowSubmenu = (props: _CmsRowSubmenuProps) => {
  const {
    showParents = false,
  } = props;

  if (!showParents) {
    return (
      <>
        {props.item.below.map(item => (
          <_CmsRowLink key={item.id} item={item} />
        ))}
      </>
    );
  }

  return (
    <li className='pb-2'>
      <p>{props.item.title}</p>
      <ul>
        {props.item.below.map(item => (
          <_CmsRowLink key={item.id} item={item} />
        ))}
      </ul>
    </li>
  );
}

const _SitemapMenu = (props: _SitemapMenuProps) => (
  <div>
    <p>{props.title}</p>
    <ul>
      {props.items.map(item => {
        if (item.below.length > 0) {
          return (
            <_CmsRowSubmenu
              key={item.id}
              item={item}
              showParents={props.showParents}
            />
          );
        }
        return (
          <_CmsRowLink
            key={item.id}
            item={item}
          />
        );
      })}
      {props.extra}
    </ul>
  </div>
);

export default _SitemapMenu;
