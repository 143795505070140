import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import MetaUtils from "@/src/core/app/utils/meta";
import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import UiOfferUtils from "@/src/ui/utils/offer";
import CalendarUtils, {DATE_ISO_FORMAT} from "@/src/ui/utils/calendar";
import UrlUtils from "@/src/core/app/utils/url";

interface _Props {
  offerDetail: T_OfferDetail
}

const _JsonLd = (props: _Props) => {
  const meta = MetaUtils.getMeta(props.offerDetail.metatag);
  const url = UrlUtils.buildPublicUrl(UiOfferUtils.getUrl(props.offerDetail));
  const data = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": props.offerDetail.cpt_ui_hotel_locality,
          "addressRegion": "ES",
          "postalCode": props.offerDetail.cpt_ui_hotel_postal_code,
          "streetAddress": props.offerDetail.cpt_ui_hotel_address
        },
        "name": props.offerDetail.title
      },
      {
        "@type": "Offer",
        "url": url,
      }
    ]
  };
  const data2 = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    "author": "Hoteles Elba",
    "contentLocation": "Spain, Spain",
    "contentUrl": url,
    "datePublished": CalendarUtils.formatDateTime(props.offerDetail.created, DATE_ISO_FORMAT),
    "description": meta?.description ?? '',
    "name": props.offerDetail.title
  };
  return (
    <>
      <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify(data)
      }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify(data2)
      }} />
    </>
  );
} ;

export default _JsonLd;
