import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import SentryUtils from "@/src/common/utils/sentry";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {UserTmsInfoSuccessOutput} from "@/src/core/app/domain/user/@types/Output/UserTmsInfoSuccessOutput";

const UserTmsInfoUtils = {
  getTmsInfo: async () => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    if (authHeaders === null) {
      throw new Error('You cannot retrieve tms info if you are not logged in');
    }

    const api = locator.get<UserApiService>(TYPES.UserApiService);
    const response = await api.userTmsInfo(authHeaders);

    if (!response.isOk) {
      SentryUtils.logNetworkError('TMS - Info - Failed - CMS request', response.error as NetworkResponseError);
      return null;
    }

    return response.data as UserTmsInfoSuccessOutput;
  }
};

export default UserTmsInfoUtils;
