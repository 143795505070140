import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStepCalendarData} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarData";
import {DateRange} from "@/src/core/app/domain/@types/DateRange";
import CalendarUtils from "@/src/ui/utils/calendar";
import CheckoutCalendarUtils from "@/src/core/app/utils/checkout-calendar";
import {useTranslation} from "next-i18next";

const _Header = () => {
  const { t } = useTranslation();
  const checkoutState = useCheckoutProvider(state => state);
  const currentStepData = checkoutState.currentStepData as CheckoutStepCalendarData;
  const viewRanges = currentStepData.dateRanges?.viewDateRange as DateRange;

  const iconWrapperStyle = 'cursor-pointer w-5 h-5 flex items-center justify-center select-none';
  const iconStyle = 'h-4 w-auto';
  return (
    <div className="w-full mb-10">
      <div className="flex justify-center space-x-5 items-center">
        <div className={iconWrapperStyle} onClick={() => CheckoutCalendarUtils.onSubDays(checkoutState, 7)}>
          <img className={iconStyle} src="/assets/icons/calendar-header-left-plus.svg" alt=""/>
        </div>
        <div className={iconWrapperStyle} onClick={() => CheckoutCalendarUtils.onSubDays(checkoutState, 1)}>
          <img className={iconStyle} src="/assets/icons/calendar-header-left.svg" alt=""/>
        </div>
        <div className='w-100 text-center font-bold text-xs'>
          {t('BlockHotelRoomListDesktop._CalendarStatusOk.header_range_description', {
            from: CalendarUtils.formatDate(viewRanges.start),
            to: CalendarUtils.formatDate(viewRanges.end)
          })}
        </div>
        <div className={iconWrapperStyle} onClick={() => CheckoutCalendarUtils.onAddDays(checkoutState, 1)}>
          <img className={iconStyle} src="/assets/icons/calendar-header-right.svg" alt=""/>
        </div>
        <div className={iconWrapperStyle} onClick={() => CheckoutCalendarUtils.onAddDays(checkoutState, 7)}>
          <img className={iconStyle} src="/assets/icons/calendar-header-right-plus.svg" alt=""/>
        </div>
      </div>
    </div>
  );
};

export default _Header;
