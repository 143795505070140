import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import Button from "@/src/ui/components/generic/Button";
import {DeviceType} from "@/src/ui/@types/Device";
import cn from "classnames";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";

export interface FooterProps {
  device: DeviceType
  range : SearchParamsDateRange
  canBeSubmitted : boolean
  onSubmit: VoidFunction
  clearDates: VoidFunction
}

const _FooterDesktop = (props: FooterProps) => {
  const { t } = useTranslation();
  return (
    <div className="px-5 py-2.5 flex justify-between items-center border-t border-gray-30">
      <button
        onClick={props.clearDates}
        className="font-bold hover:underline"
      >
        {t('SearchFormCalendar._Footer.button_clear_label')}
      </button>
      <div className="flex items-center space-x-5">
        <Paragraph size="medium" className="text-brand-2-60">
          <time>{SearchParamsFormatter.formatRange(props.range)}</time>
        </Paragraph>
        <Button
          text={t('SearchFormCalendar._Footer.button_submit_label')}
          className="w-28"
          bold
          onClick={props.onSubmit}
          style={props.canBeSubmitted ? 'primary' : 'ghost'}
          isActive={props.canBeSubmitted}
        />
      </div>
    </div>
  );
}

const _FooterMobile = (props: FooterProps) => {
  const { t } = useTranslation();
  const isRangeDefined = props.range.end != null;
  return (
    <div>
      {isRangeDefined && (
        <div className="px-5 py-2.5 flex justify-between items-center bg-gray-10 border-t border-gray-30">
          <span className='text-sm'>
            {SearchParamsFormatter.formatRange(props.range)}
          </span>
          <div className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">
            <img className="h-full w-auto" src="/assets/icons/moon.svg" alt="" />
            <span className='text-sm text-brand-2-60'>
            {SearchParamsFormatter.getDateRangeNightsSummary(props.range, t)}
          </span>
          </div>
        </div>
      )}
      <div className="px-5 py-7 flex justify-between items-center border-t border-gray-30">
        <span
          className={cn(
          'text-sm font-bold underline',
            !isRangeDefined && 'text-brand-2-40',
          )}
          onClick={props.clearDates}
        >
          {t('SearchFormCalendar._Footer.button_clear_label')}
        </span>
        <div className="w-28" onClick={props.onSubmit}>
          <Button
            text={t('SearchFormCalendar._Footer.button_submit_label')}
            style={props.canBeSubmitted ? 'secondary' : 'ghost'}
            isActive={props.canBeSubmitted}
            bold
          />
        </div>
      </div>
    </div>
  );
}

export const _Footer = (props: FooterProps) => {
  const _Component = props.device.isMobile ? _FooterMobile : _FooterDesktop;
  return (
    <_Component {...props} />
  );
}