import React from 'react';
import type {GetServerSideProps} from "next";
import type {GetServerSidePropsContext} from "next/types";
import RouterController from "@/src/ui/controllers/RouterController";
import getRouteComponent from "@/src/ui/components/routes/index";
import type {RouteProps, RouteComponent} from "@/src/ui/@types/RouteProps";
import DeviceController, {DeviceContext} from "@/src/ui/controllers/DeviceController";
import Layout from '@/src/ui/components/layout';
import Head from "@/src/ui/components/generic/Head";
import DataLoaderContext from "@/src/core/app/data/data-loader/context/DataLoaderContext";
import RouteManager from '@/src/core/app/domain/@types/RouteManager';
import {Route} from "@/src/core/app/domain/@types/Route";
import DataLoaderCacheFactory from "@/src/core/app/data/data-loader/cache/DataLoaderCacheFactory";
import AppController from "@/src/ui/controllers/AppController";
import {AppData} from "@/src/core/app/domain/@types/AppData";
import LayoutController from '@/src/ui/controllers/LayoutController';
import App from '@/src/ui/components/App';
import {NotFoundException} from "@/src/core/app/domain/route/exception/NotFoundException";
import {RedirectionException} from "@/src/core/app/domain/route/exception/RedirectionException";
import {RouteErrorException} from "@/src/core/app/domain/route/exception/RouteErrorException";
import RouteUtils from "@/src/core/app/utils/route";
import {DeviceType} from "@/src/ui/@types/Device";
import SentryUtils from "@/src/common/utils/sentry";
import MetaUtils from "@/src/core/app/utils/meta";
import ServerSideUtils from "@/src/core/app/utils/server-side";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";

const Page = (props: RouteProps): JSX.Element | null => {
  const _RouteComponent : RouteComponent | null = getRouteComponent(props);

  if (_RouteComponent === null) {
    SentryUtils.captureMessage('_RouteComponent is null');
    // @TODO - Sentry + Response
    return (
      <div>FALLO!</div>
    );
  }

  const routeAppWrapper = _RouteComponent.getAppWrapper || undefined;
  const routePortals = _RouteComponent.getPortals || undefined;

  return (
    <App routeProps={props} routeAppWrapper={routeAppWrapper}>
      <Layout routeProps={props} routePortals={routePortals}>
        <Head meta={props.routeData.meta} />
        <_RouteComponent {...props} />
      </Layout>
    </App>
  );
};

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext & DeviceContext) => {
  let device = null;
  let route = null;

  const appInfo = ServerSideUtils.getAppInfo(context);
  try {
    console.debug('getServerSideProps');

    console.debug('loading device');
    device = DeviceController.getDevice(context);
    console.debug('creating dataLoader');
    const dataLoader = {
      context: new DataLoaderContext(device, appInfo),
      cache: DataLoaderCacheFactory(appInfo),
    };

    console.debug('getRouteManager');
    const routeManager: RouteManager | null = await RouterController.getRouteManager(context, dataLoader);

    route = routeManager.route;
    dataLoader.context.set<Route>('Route', route);

    console.debug('loading AppData');
    const appData = await AppController.getData(dataLoader);
    dataLoader.context.set<AppData>('AppData', appData);

    console.debug('loading RouteData');
    const routeData = await routeManager.getRouteData(dataLoader);
    console.debug('retrieving Layout');
    const layout = await LayoutController.getLayout(route);
    console.debug('loading LayoutData');
    const layoutData = await LayoutController.getLayoutData(dataLoader, route, layout);

    routeData.meta = MetaUtils.getMetaForRoute(routeData.meta, route, layoutData);

    context.res.setHeader('cache-control', 'public, s-maxage=31536000');
    context.res.setHeader('x-cache-control', 'public, s-maxage=31536000');
    context.res.setHeader('cache-tags', dataLoader.context.getCacheTagsHeader());

    return {
      props: {
        appInfo,
        device,
        route,
        routeData,
        layout,
        layoutData,
        ...await serverSideTranslations(appInfo.langcode),
      }
    };
  }
  catch (e) {
    if (e instanceof NotFoundException) {
      return {
        notFound: true
      }
    }
    if (e instanceof RedirectionException) {
      return {
        redirect: {
          destination: e.destination,
          permanent: e.permanent,
        }
      }
    }
    if (e instanceof RouteErrorException) {
      return {
        props: {
          ...RouteUtils.getRouteError(appInfo, e, device as DeviceType, route as Route),
          ...await serverSideTranslations(appInfo.langcode),
        },
      }
    }
    throw e;
  }
}

export default Page;
