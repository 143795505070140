import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import React from "react";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelRoom: T_HotelRoomBasic
}

const _Features = (props: _Props) => {
  const { t } = useTranslation();
  const hotelRoom = props.hotelRoom;

  if (!hotelRoom.field_room_details_rooms && !hotelRoom.field_room_details_bathroom && !hotelRoom.field_room_details_equipment) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-5">
      {hotelRoom.field_room_details_rooms && (
        <div className="space-y-1">
          <h3 className="text-medium-bold">{t('ModalHotelRoom._Features.room_label')}</h3>
          <WrapperDiv
            size="small"
            className="pt-1 text-brand-2-90 max-w-screen-md Cms-Formatted"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_rooms.value}}
          />
        </div>
      )}
      {hotelRoom.field_room_details_bathroom && (
        <div className="space-y-1">
          <h3 className="text-medium-bold">{t('ModalHotelRoom._Features.bathroom_label')}</h3>
          <WrapperDiv
            size="small"
            className="pt-1 text-brand-2-90 max-w-screen-md Cms-Formatted"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_bathroom.value}}
          />
        </div>
      )}
      {hotelRoom.field_room_details_equipment && (
        <div className="space-y-1">
          <h3 className="text-medium-bold">{t('ModalHotelRoom._Features.equipment_label')}</h3>
          <WrapperDiv
            size='small'
            className="pt-1 text-brand-2-90 max-w-screen-md Cms-Formatted"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_equipment.value}}
          />
        </div>
      )}
    </div>
  );
}

export default _Features;
