import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import Button from "@/src/ui/components/generic/Button";
import SearchFormRoomOccupationWidget from "@/src/ui/components/search-form/SearchFormRoomOccupationWidget";
import {useOfferFormProvider} from "@/src/ui/providers/offer-form.provider";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";
import {useSearchFormKidsMaxAge} from "@/src/ui/hooks/useSearchFormKidsMaxAge";

const ModalOfferFormOccupationGeneric = () : JSX.Element | null => {
  const { t } = useTranslation();
  const device = useDevice();
  const offerDetailState = useOfferFormProvider(state => state);
  const modalState = useModalProvider(state => state);
  const kidsMaxAge = useSearchFormKidsMaxAge();

  const closeModal = () => modalState.closeModal();

  const onSubmit = () => {
    const total = offerDetailState.occupation.adults + offerDetailState.occupation.kids;
    if (total === 0) {
      offerDetailState.setOccupation({
        adults: 2,
        kids: 0,
        kid_ages: []
      });
    }
    closeModal();
  };

  const _Component = device.isDesktop ? ModalLayout : ModalLayoutFullscreen;
  const componentProps = device.isDesktop ? {
    title: t("ModalOfferFormOccupation._Index.title"),
    onCloseModal: () => closeModal(),
    className: "w-115"
  } : {
    title: t("ModalOfferFormOccupation._Index.title"),
    onCloseModal: () => closeModal(),
    wrapperClassName: 'h-full overflow-hidden',
    contentClassName: 'flex flex-col'
  };

  return (
    <_Component {...componentProps}>
      <div className={cn(
        "pt-5 px-5 space-y-5 text-brand-2 overflow-y-auto",
        device.isDesktop && 'h-80',
        device.isMobile && 'flex-1',
      )}>
        <SearchFormRoomOccupationWidget
          occupation={offerDetailState.occupation}
          onAddAdult={() => offerDetailState.formAddAdult()}
          onSubAdult={() => offerDetailState.formSubAdult()}
          onAddKid={() => offerDetailState.formAddKid()}
          onSubKid={() => offerDetailState.formSubKid()}
          onKidAgeChange={(kidIndex: number, kidAge: number) => offerDetailState.formKidAgeChange(kidIndex, kidAge)}
          kidsMaxAge={kidsMaxAge}
        />
      </div>
      <div className="px-5 py-2 flex justify-end items-center border-t border-gray-20">
        <div className="flex items-center space-x-5">
          <div className="w-28" onClick={onSubmit}>
            <Button text={t('ModalOfferFormOccupation._Index.submit_button_label')} bold className='block' />
          </div>
        </div>
      </div>
    </_Component>
  );
}

export default ModalOfferFormOccupationGeneric;
