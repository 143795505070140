export enum HeaderMobileMenuSection {
  HOTELS = 'HOTELS',
  DESTINATIONS = 'DESTINATIONS',
  SERVICES = 'SERVICES',
}

export interface HeaderMobileMenuState {
  isOpen: boolean
  open: VoidFunction
  close: VoidFunction
  currentSection: HeaderMobileMenuSection | null
  setCurrentSection: (newSection: HeaderMobileMenuSection) => void
  clearSection: VoidFunction
}