import UserPasswordChangeForm from "@/src/ui/components/forms/UserPasswordChangeForm";
import UserPasswordChangeFormDesktopComponent from "@/src/ui/components/forms/UserPasswordChangeForm/desktop";
import UserRestrictedContentSeoFriendly from "@/src/ui/components/generic/UserRestrictedContentSeoFriendly";
import {T_ParagraphUserPasswordEditForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserPasswordEditForm";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

const BlockUserPasswordChangeFormDesktop = (props: T_ParagraphUserPasswordEditForm) => {
  const { t } = useTranslation();
  return (
    <div className="space-y.5">
      <TitleWithTag
        className="title-4 pt-2.5 mb-5 font-calistoga"
        field={props.field_title_with_tag}
      />
      <UserRestrictedContentSeoFriendly
        anonymousContent={(
          <p>{t('BlockUserPasswordChangeForm._Index.unauthenticated_message')}</p>
        )}
        content={(
          <UserPasswordChangeForm Component={UserPasswordChangeFormDesktopComponent} />
        )}
      />
    </div>
  );
}

export default BlockUserPasswordChangeFormDesktop;
