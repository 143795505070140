import type {RouteComponent, RouteProps} from "@/src/ui/@types/RouteProps";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {T_HotelSectionType} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionType";
import HotelSectionRooms from "@/src/ui/components/routes/Route_HotelSection/by-type/rooms";
import HotelSectionDefault from "@/src/ui/components/routes/Route_HotelSection/by-type/default";

const Route_HotelSection = (props: RouteProps<T_HotelSection>): RouteComponent => {
  if (props.routeData.data.ui_hotel_section.type === T_HotelSectionType.SECTION_ROOMS) {
    return HotelSectionRooms;
  }
  return HotelSectionDefault;
}

export default Route_HotelSection
