import { ReactNode } from "react";

interface LegendBoxProps {
  title: string;
  children: ReactNode;
}

const LegendBox = ({ title, children }: LegendBoxProps): JSX.Element => (
  <div className="relative pt-3">
    <h3 className="absolute top-0 w-46 inset-x-0 mx-auto text-center text-lg font-bold text-brand-2-90 bg-white">
      {title}
    </h3>
    <div className="p-5 pt-9 border border-gray-40 text-sm text-brand-2-90">
      {children}
    </div>
  </div>
);

export default LegendBox;
