import _Label from "@/src/ui/components/offer/OfferTeaser/header/label";
import {useTranslation} from "next-i18next";

interface _Props {
  discount: number
}

const _HeaderDiscount = (props: _Props) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="absolute inset-0 bg-img-blue-shade" />
      <div className="absolute top-4 right-5">
        <_Label content={t('OfferTeaser._HeaderDiscount.label', {
          percent: props.discount
        })} />
      </div>
    </>
  );
}

export default _HeaderDiscount;