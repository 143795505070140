import InputText from "../../form/InputText";
import Button from "../../generic/Button";
import Paragraph from "../../generic/Paragraph";
import ButtonOccupationSummary from "../../search-form/ButtonOccupationSummary";
import ButtonDateRangeSummary from "src/ui/components/search-form/ButtonDateRangeSummary";
import ButtonLocationSummary from "src/ui/components/search-form/ButtonLocationSummary";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParamsError} from "@/src/core/app/domain/@types/SearchParamsError";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalDesktopKey from "@/src/ui/@types/modal/ModalDesktopKey";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {useTranslation} from "next-i18next";
import Anchor from "@/src/ui/components/generic/Anchor";
import SearchFormUtils from "@/src/core/app/utils/search-form";

const HeroHomeSearchForm = (): JSX.Element => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const searchForm = useSearchFormProvider(state => state);
  const promocode = useSearchFormProvider(state => state.promocode);
  const layoutData = useLayoutData<UI_LayoutGeneric>();

  return (
    <article className="absolute bottom-5 inset-x-0 mx-auto w-167.5 space-y-2.5 bg-white-70 rounded p-2.5 text-brand-2 pointer-events-auto">
      <div className="flex space-x-2.5">
        <ButtonLocationSummary
          className="w-80 cursor-pointer"
          hasError={SearchParamsUtils.hasError(searchForm.validation, SearchParamsError.LOCATION_EMPTY)}
          onClick={() => modalState.toggleModal(ModalDesktopKey.SEARCH_FORM_STEP_LOCATION)}
        />
        <ButtonDateRangeSummary
          className="w-80 cursor-pointer"
          hasError={SearchParamsUtils.hasError(searchForm.validation, SearchParamsError.DATE_RANGE_EMPTY)}
          onClick={() => modalState.toggleModal(ModalDesktopKey.SEARCH_FORM_STEP_DATES)}
        />
      </div>
      <div className="flex space-x-2.5">
        <ButtonOccupationSummary
          className="w-80 cursor-pointer"
          onClick={() => modalState.toggleModal(ModalDesktopKey.SEARCH_FORM_STEP_OCCUPATION)}
        />
        <div className="w-80 flex space-x-2.5">
          <InputText
            writable
            className="flex-1"
            icon="/assets/icons/ticket.svg"
            placeholder={t('SearchForm._Index.promocode_placeholder')}
            value={promocode}
            onChange={(value) => searchForm.setPromocode(value)}
          />
          <Button
            text={t('SearchForm._Index.submit_button_label')}
            className="w-25"
            customPaddingClass='py-2'
            onClick={() => SearchFormUtils.submit(searchForm, modalState)}
          />
        </div>
      </div>
      <div className="flex space-x-2.5">
        {layoutData.menuSearchForm.items.map(item => (
          <Paragraph
            key={item.id}
            size="medium"
            className="flex-1 cursor-pointer hover:underline font-bold"
          >
            <Anchor className="text-left" href={item.url}>+ {item.title}</Anchor>
          </Paragraph>
        ))}
      </div>
    </article>
  );
}

export default HeroHomeSearchForm;
