import ModalSearchFormStepFormMobile from "../ModalSearchFormStepForm";
import ModalSearchFormStepLocationMobile from "../ModalSearchFormStepLocation";
import ModalSearchFormStepDatesMobile from "../ModalSearchFormStepDates";
import ModalSearchFormStepOccupationMobile from "../ModalSearchFormStepOccupation";
import {useSearchFormMobileProvider} from "@/src/ui/providers/search-form-mobile-provider";
import {SearchFormMobileStep} from "@/src/ui/view_models/search-form-mobile.state";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useTranslation} from "next-i18next";

const stepTitles : Record<SearchFormMobileStep, string> = {
  [SearchFormMobileStep.FORM]: "SearchFormMobile._Index.step_form_title",
  [SearchFormMobileStep.LOCATION]: "SearchFormMobile._Index.step_location_title",
  [SearchFormMobileStep.DATES]: "SearchFormMobile._Index.step_dates_title",
  [SearchFormMobileStep.OCCUPATION]: "SearchFormMobile._Index.step_occupation_title",
};

const _Component = (): JSX.Element | null => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const state = useSearchFormMobileProvider(state => state);

  return (
    <div className="fixed z-30 inset-0 bg-white flex flex-col h-full overflow-hidden">
      <div className="flex-none top-0 bg-white px-7.5 pt-10.5 border-b border-gray-40">
        <div className="flex justify-between items-center">
          <div>
            {state.currentStep !== SearchFormMobileStep.FORM && (
              <button
                className="font-bold text-sm text-brand-2 flex items-center space-x-2.5"
                onClick={() => state.setCurrentStep(SearchFormMobileStep.FORM)}
              >
                <img
                  src="/assets/icons/chevron-right-brand-2.svg"
                  className="transform rotate-180"
                  alt=""
                />
                <span>{t('SearchFormMobile._Index.back_link_label')}</span>
              </button>
            )}
          </div>
          {state.currentStep === SearchFormMobileStep.FORM && (
            <div
              onClick={() => modalState.closeModal()}
            >
              <img
                className="h-6 w-6"
                src="/assets/icons/cross-brand-2.svg"
                alt="close"
              />
            </div>
          )}
        </div>
        <h2 className="py-5 text-center text-2xl text-brand-2 font-calistoga">
          {t(stepTitles[state.currentStep] as any)}
        </h2>
      </div>
      <ModalSearchFormStepFormMobile />
      <ModalSearchFormStepLocationMobile />
      <ModalSearchFormStepDatesMobile />
      <ModalSearchFormStepOccupationMobile />
    </div>
  );
}

const ModalSearchFormMobile = () => (
  <useSearchFormMobileProvider.State>
    <_Component />
  </useSearchFormMobileProvider.State>
);

export default ModalSearchFormMobile;
