import {T_ParagraphInfoTextImage} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoTextImage";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import Section from "@/src/ui/components/generic/Section/Section";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import CmsLinkButtonMultiple from "@/src/ui/components/generic/CmsLinkButtonMultiple";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import Carousel from "@/src/ui/components/generic/Carousel";

const BlockInfoTextImageMobile = (props: T_ParagraphInfoTextImage) => (
  <Section paddingY="my-5 space-y-2.5">
    <div>
      <TitleWithTag
        className="title-4-mobile pb-2.5 font-calistoga"
        field={props.field_title_with_tag}
      />
      {props.field_body && (
        <WrapperDiv
          className="text-sm text-brand-2-90 Dingus-Formatted"
          dangerouslySetInnerHTML={{__html: props.field_body.processed}}
        />
      )}
      {props.field_one_link && (
        <div className="pt-5">
          <ArrowLink href={props.field_one_link.url}>
            {props.field_one_link.title}
          </ArrowLink>
        </div>
      )}
      {props.field_link && (
        <CmsLinkButtonMultiple
          links={props.field_link}
          className='py-5 space-y-2.5'
        />
      )}
    </div>
    <Carousel
      className='w-full'
      medias={props.ui_cpt_field_medias}
    />
  </Section>
);


export default BlockInfoTextImageMobile;