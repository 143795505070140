import UiMediaUtils from "@/src/ui/utils/media";
import React from "react";
import _TextFields from "@/src/ui/components/blocks/BlockLoungesList/generic/_TextFields";
import {T_ParagraphLoungesList} from "@/src/core/app/domain/models/paragraph/T_ParagraphLoungesList";

const _Display4Cols = (props: T_ParagraphLoungesList) => (
  <div className="grid grid-cols-4 gap-5">
    {props.field_lounge_items.map((item, index) => (
      <article
        key={index}
        className="border border-gray-40 p-3.75 flex flex-col space-y-5"
      >
        <div className='w-full'>
          <img
            {...UiMediaUtils.getImgPropsFromMediaImage(item.ui_cpt_field_media_teaser)}
          />
        </div>
        <_TextFields className="space-y-2.5" item={item} />
      </article>
    ))}
  </div>
);

export default _Display4Cols;
