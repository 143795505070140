import HotelLinkWithRating, {OnHotelSelected} from "../../hotel/HotelLinkWithRating";
import {T_HotelTreeRegion} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeRegion";

interface HeaderMobileHotelListProps {
  onHotelSelected?: OnHotelSelected
  region: T_HotelTreeRegion
}

const HeaderMobileHotelList = ({
  region,
  onHotelSelected,
}: HeaderMobileHotelListProps): JSX.Element => (
  <>
    <h3 className="pt-7.5 text-lg font-bold text-brand-2 mb-4">
      {region.title}
    </h3>
    {region.destinations.map(destination => (
      <div key={destination.id} className='mb-5'>
        <p className="text-sm font-bold text-brand-2 flex items-center mb-1">{destination.destinationName}</p>
        <ul className="space-y-4">
          {destination.hotels.map((hotel) => (
            <li key={hotel.id}>
              <HotelLinkWithRating hotel={hotel} onHotelSelected={onHotelSelected}/>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </>
);

export default HeaderMobileHotelList;
