import FormInputWrapper from "../../form/FormInputWrapper";
import React from "react";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import cn from "classnames";
import {useFormField} from "@/src/ui/hooks/useFormField";
import {useTranslation} from "next-i18next";
import {FormFieldProps} from "@/src/ui/@types/form/FormFieldProps";

interface _Props extends FormFieldProps {
  label?: string
  emptyOption?: string
  options: FormInputSelectOption[]
  className?: string
}

const FormInputSelect = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    emptyOption,
    options,
    className,
  } = props;

  const [field, meta] = useFormField(props, t);
  const value = options.find(option => option.value == field.value);

  return (
    <div className={cn(
      "relative",
      className ?? 'flex-1'
    )}>
      <div className="text-left block w-full">
        <FormInputWrapper
          {...props}
          error={meta.error}
          relativeChildren={(
            <select
              className='w-full h-full absolute opacity-0 z-50 cursor-pointer'
              {...field}
            >
              {emptyOption && (
                <option value=''>{emptyOption}</option>
              )}
              {props.options.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          )}
        >
          <div
            className="flex justify-between items-center w-full font-sans text-brand-2-90 text-lg cursor-pointer"
          >
            <span className='flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis'>
              {value?.label  || emptyOption}
            </span>
            <div className='flex w-4 ml-2'>
              <img
                className="h-3 w-auto"
                src="/assets/icons/chevron-down.svg"
                alt=""
              />
            </div>
          </div>
        </FormInputWrapper>
      </div>
    </div>
  )
};

export default FormInputSelect;
