import TitledBox from "@/src/ui/components/generic/TitledBox";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormUtils from "@/src/ui/utils/form";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import {_FormProps} from "@/src/ui/components/forms/EventForm/types";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import FormInputTextarea from "@/src/ui/components/formik/FormInputTextarea";
import EventUtils from "@/src/core/app/utils/event";
import CalendarUtils from "@/src/ui/utils/calendar";
import cn from "classnames";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";

const _Event = (props: _FormProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const hotels = HotelTreeUtils.getHotels(layoutData.hotelTree);

  return (
    <TitledBox title={t('EventForm._Event.title')}>
      <div className="px-5 py-3.75 space-y-7.5">
        <div className="space-y-3.75">
          <div className={cn(
            device.isDesktop && "flex space-x-5",
            device.isMobile && "space-y-2.5",
          )}>
            <FormInputSelect
              name='eventType'
              label={t('EventForm._Event.event_type_label') + " *"}
              emptyOption={'- ' + t('EventForm._Event.event_type_empty_option') + ' -'}
              options={I18nUtils.translateFormInputSelectOptions(
                EventUtils.getEventTypeSelectOptions(), t
              )}
              validate={FormUtils.validateIsRequired}
            />
            <FormInputText
              name='eventName'
              label={t('EventForm._Event.event_name_label')}
            />
          </div>
          <div className={cn(
            device.isDesktop && "flex space-x-5",
            device.isMobile && "space-y-2.5",
          )}>
            <div className="flex-1 grid grid-cols-3 space-x-5">
              <FormInputSelect
                name='eventDay'
                label={t('EventForm._Event.day_label')}
                emptyOption={'- ' + t('EventForm._Event.day_empty_option') + ' -'}
                options={CalendarUtils.getDayOptions()}
              />
              <FormInputSelect
                name='eventMonth'
                label={t('EventForm._Event.month_label')}
                emptyOption={'- ' + t('EventForm._Event.month_empty_option') + ' -'}
                options={CalendarUtils.getMonthOptions()}
              />
              <FormInputSelect
                name='eventYear'
                label={t('EventForm._Event.year_label')}
                emptyOption={'- ' + t('EventForm._Event.year_empty_option') + ' -'}
                options={CalendarUtils.getYearOptions()}
              />
            </div>
            <div className="flex-1">
              <FormInputText
                name='eventPeople'
                label={t('EventForm._Event.people_label')}
              />
            </div>
          </div>
          <FormInputSelect
            name='eventHotelCode'
            label={t('EventForm._Event.hotel_label') + " *"}
            emptyOption={'- ' + t('EventForm._Event.hotel_empty_option') + ' -'}
            options={HotelTreeUtils.getSelectOptions(hotels)}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputTextarea
            name='eventDescription'
            label={t('EventForm._Event.description_label')}
          />
        </div>
      </div>
    </TitledBox>
  );
}

export default _Event;
