import {
  HotelCalendarAvailAndRatesOutputRates
} from "@/src/core/app/domain/booking/@types/Output/HotelCalendarAvailAndRatesOutput";
import {
  CheckoutStepCalendarDataDateRanges
} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarDataDateRanges";
import CalendarUtils from "@/src/ui/utils/calendar";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import _Row from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/row";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStepCalendarData} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarData";
import _Th, {ThStyle} from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/th";
import CheckoutCalendarUtils from "@/src/core/app/utils/checkout-calendar";
import {useTranslation} from "next-i18next";

const _Table = () => {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const checkoutState = useCheckoutProvider(state => state);
  const currentStepData = checkoutState.currentStepData as CheckoutStepCalendarData;

  const rates = currentStepData.rates as HotelCalendarAvailAndRatesOutputRates;
  const roomOrder = currentStepData.roomsOrder as string[];
  const dateRanges = currentStepData.dateRanges as CheckoutStepCalendarDataDateRanges;
  const dateRangeIterator = CalendarUtils.getDateRangeIterator(dateRanges.viewDateRange);

  const onClick = async (d: string) => {
    await checkoutState.onCalendarDayClicked(d);
  };

  return (
    <table className='table w-full'>
      <thead>
        <tr>
          <_Th style={ThStyle.TH_ROOM_DESCRIPTION}>
            {t('BlockHotelRoomListDesktop._CalendarStatusOk.table_header_room_label')}
          </_Th>
          {dateRangeIterator.map(dt => {
            const notClickable = CheckoutCalendarUtils.areAllRoomsUnavailable(rates, dt);
            let style = ThStyle.DAY_NORMAL;
            if (CalendarUtils.isDateInRange(dt, dateRanges.selectionDateRange)) {
              style = ThStyle.DAY_SELECTED;
            }
            if (notClickable) {
              style = ThStyle.DAY_UNAVAILABLE;
            }

            return (
              <_Th
                key={dt}
                style={style}
                onClick={() => !notClickable && onClick(dt)}
              >
                {CalendarUtils.formatCalendarHeaderDay(dt, appInfo.langcode)}
              </_Th>
            );
          })}
          <_Th style={ThStyle.TH_TOTAL_PRICE}>
            {t('BlockHotelRoomListDesktop._CalendarStatusOk.table_header_total_price_label')}
          </_Th>
        </tr>
      </thead>
      <tbody>
        {roomOrder.map(roomCode => (
          <_Row
            key={roomCode}
            roomRates={rates[roomCode]}
            dateRanges={dateRanges}
            roomCode={roomCode}
            onClick={onClick}
          />
        ))}
      </tbody>
    </table>
  );
};

export default _Table;
