import {ReactNode} from "react";
import cn from "classnames";
import PositionFixedWrapper from "@/src/ui/components/desktop/PositionFixedWrapper";

interface ModalLayoutProps {
  children: ReactNode
  title: string
  className?: string
  onCloseModal?: () => void
}

const ModalLayout = ({
  children,
  title,
  className,
  onCloseModal,
}: ModalLayoutProps): JSX.Element | null => {
  return (
    <PositionFixedWrapper
      className={cn('rounded bg-white', className)}
    >
      <div
        className="bg-gray-20 flex-none flex justify-between items-center px-5 py-2.5 text-small font-bold text-brand-2">
        <h2>{title}</h2>
        {onCloseModal && (
          <button onClick={onCloseModal}>
            <img
              src="/assets/icons/cross-brand-2-90.svg"
            />
          </button>
        )}
      </div>
      {children}
    </PositionFixedWrapper>
  );
}

export default ModalLayout;
