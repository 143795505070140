import {CMS_HotelSection} from "@/src/core/app/domain/cms/@types/hotel/CMS_HotelSection";
import {Route} from "@/src/core/app/domain/@types/Route";
import {CMS_ParagraphHotelList_LinkSection} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";
import {CMS_HotelSectionLinks} from "@/src/core/app/domain/cms/@types/hotel/CMS_HotelSectionLinks";
import { Breadcrumb } from "@/src/core/app/domain/@types/Breadcrumb";

const HotelUtils = {
  getLinkToSection: (hotel: CMS_HotelSectionLinks, linkSection: CMS_ParagraphHotelList_LinkSection) => {
    switch (linkSection) {
      case CMS_ParagraphHotelList_LinkSection.MAIN:
        return HotelUtils.getSectionUrl(hotel.cpt_hotel_section_main);
      case CMS_ParagraphHotelList_LinkSection.ROOM:
        return HotelUtils.getSectionUrl(hotel.cpt_hotel_section_rooms)
      case CMS_ParagraphHotelList_LinkSection.OFFER:
        return HotelUtils.getSectionUrl(hotel.cpt_hotel_section_offers);
      case CMS_ParagraphHotelList_LinkSection.EVENT:
        return HotelUtils.getSectionUrl(hotel.cpt_hotel_section_events);
    }
    return null;
  },
  getSectionUrl: (section: CMS_HotelSection | null) : string | null => {
    if (!section) {
      return null;
    }
    // @TODO - Add sentry
    if ('data' in section as any) {
      return null;
    }
    const url = section?.meta?.path?.alias || '/node/' + section.meta.drupal_internal__target_id;
    return `/${section.meta.path.langcode}${url}`;
  },
  getSectionTitle: (section: CMS_HotelSection) : string => {
    // @TODO - Add sentry
    return section?.meta?.title || "";
  },
  isCurrentSection: (section: CMS_HotelSection, route: Route, breadcrumb: Breadcrumb) : boolean => {
    const sectionUrl = HotelUtils.getSectionUrl(section);

    if (sectionUrl === route.path) {
      return true;
    }

    return breadcrumb.slice(3).find(item => {
      return item.url === sectionUrl;
    }) !== undefined;
  },
  getSectionsAndCurrentSection: (sections: CMS_HotelSection[], route: Route, breadcrumb: Breadcrumb) => {
    let currentSection = null;
    let ret: CMS_HotelSection[] = [];
    sections.forEach(section => {
      ret.push(section);
      if (HotelUtils.isCurrentSection(section, route, breadcrumb)) {
        currentSection = section;
      }
    });
    return {
      currentSection: currentSection as unknown as CMS_HotelSection,
      sections: ret,
    };
  },
}

export default HotelUtils;
