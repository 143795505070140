import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalMobileKey from "@/src/ui/@types/modal/ModalMobileKey";
import Section from "@/src/ui/components/generic/Section/Section";
import ButtonLocationSummary from "@/src/ui/components/search-form/ButtonLocationSummary";
import ButtonDateRangeSummary from "@/src/ui/components/search-form/ButtonDateRangeSummary";

const HeaderMobileSearchForm = (): JSX.Element => {
  const state = useModalProvider(state => state);

  return (
    <div
      className="bg-white pt-2 pb-2 border-t border-b border-gray-30"
    >
      <Section paddingY={""}>
        <div className="grid grid-cols-2 space-x-2.5">
          <ButtonLocationSummary
            color="gray"
            onClick={() => state.toggleModal(ModalMobileKey.SEARCH_FORM)}
          />
          <ButtonDateRangeSummary
            color="gray"
            size='tiny'
            onClick={() => state.toggleModal(ModalMobileKey.SEARCH_FORM)}
          />
        </div>
      </Section>
    </div>
  );
}

export default HeaderMobileSearchForm;
