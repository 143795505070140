
export enum SearchFormMobileStep {
  FORM,
  LOCATION,
  DATES,
  OCCUPATION,
}

export interface SearchFormMobileState {
  currentStep: SearchFormMobileStep
  setCurrentStep: (step: SearchFormMobileStep) => void
}
