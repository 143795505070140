import styles from './styles.json'

const LeafletMapService = {
  init: (nodes) => {
    const map = window.L.map('mapId', {maxZoom: 11}).setView([51.505, -0.09], 1);
    map.invalidateSize();

    map.addLayer(L.gridLayer.googleMutant({
      type: 'roadmap',
      styles,
    }));

    const bounds = [];
    nodes.forEach(node => {
      const latlng = [node.latitude, node.longitude];
      const marker = window.L.marker(latlng).addTo(map);
      marker.bindPopup(node.link);
      bounds.push(latlng);
    })
    map.fitBounds(bounds);
  }
};

export default LeafletMapService;
