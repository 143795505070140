import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Formik} from "formik";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import {UserPasswordChangeFormProps} from "@/src/ui/components/forms/UserPasswordChangeForm/types";
import {UserPasswordChangeFormValues} from "@/src/core/app/domain/forms/UserPasswordChangeFormValues";
import FormUtils from "@/src/ui/utils/form";
import _passwordValidation from "@/src/ui/components/forms/UserPasswordChangeForm/validation/password";
import UserPasswordChangeUtils from "@/src/core/app/utils/user-password-change";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import UserPasswordChangeErrors from "@/src/core/app/domain/user/@types/FormErrorMap/user-password-change";
import {useTranslation} from "next-i18next";

const UserPasswordChangeForm = (props: UserPasswordChangeFormProps) => {
  const { t } = useTranslation();
  const userState = useUserProvider(state => state);
  const modalState = useModalProvider(state => state);

  let initialValues : UserPasswordChangeFormValues = {
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
  };

  return (
    <Formik<UserPasswordChangeFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => FormUtils.runValidations(t, values, [_passwordValidation])}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await UserPasswordChangeUtils.save(values, userState);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: UserPasswordChangeErrors,
            formErrorMapKeyGetter: NetworkUtils.NetworkErrorKeyGetter__DataIsErrorMapKey,
            logFn: (reason) => FormErrorUtils.logNetworkError('User password change form error', reason, networkError),
          });
          return;
        }

        formikHelpers.setValues(initialValues);
        modalState.toggleModal(ModalKey.NOTIFICATION, {
          type: T_NotificationType.SUCCESS,
          title: 'UserPasswordChangeForm._Index.success_notification_title',
          message: 'UserPasswordChangeForm._Index.success_notification_message',
        });
      }}
    >
      {(formik: FormikProps<UserPasswordChangeFormValues>) => (
        <props.Component
          formik={formik}
          {...props}
        />
      )}
    </Formik>
  );
}

export default UserPasswordChangeForm;
