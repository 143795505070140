import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useDevice} from "@/src/ui/hooks/useDevice";
import Route_OfferDetailDesktop from "@/src/ui/components/routes/Route_OfferDetail/desktop";
import Route_OfferDetailMobile from "@/src/ui/components/routes/Route_OfferDetail/mobile";
import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import {PropsWithChildren} from "react";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";
import Route_CheckoutStepCustomerInfo from "@/src/ui/components/routes/Route_CheckoutStepCustomerInfo";

const Route_OfferDetail = (props: RouteProps<T_OfferDetail>) : JSX.Element => {
  const device = useDevice();
  const _Component = device.isDesktop ? Route_OfferDetailDesktop : Route_OfferDetailMobile;
  return (
    <_Component {...props} />
  );
}

Route_OfferDetail.getAppWrapper = (
  props: PropsWithChildren<RouteProps<T_OfferDetail>>
) => (
  <useCheckoutProvider.State
    langcode={props.appInfo.langcode}
    autoInit={false}
    offerDingusCode={props.routeData.data.dingus_code}
    step={CheckoutStep.ROOMS}
    hotelId={props.routeData.data.cpt_ui_hotel_id}
    hotelCode={props.routeData.data.cpt_ui_hotel_code}
    stepExtrasEnabled={props.routeData.data.cpt_ui_hotel_form_extras_enabled}
  >
    {props.children}
  </useCheckoutProvider.State>
)

export default Route_OfferDetail;
