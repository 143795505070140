import {T_ParagraphHotelListByDestination} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelListByDestination";
import HotelTeaserListMobile from "@/src/ui/components/hotel/HotelTeaserList/mobile";
import Section from "@/src/ui/components/generic/Section/Section";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const BlockHotelListByDestinationMobile = (props: T_ParagraphHotelListByDestination) => (
  <Section paddingY="pt-5 pb-7.5">
    {props.field_title_with_tag && (
      <TitleWithTag
        className="title-4-mobile pb-5 font-calistoga"
        field={props.field_title_with_tag}
        suffix={`(${props.hotelTeasers.length})`}
      />
    )}
    <HotelTeaserListMobile hotelTeasers={props.hotelTeasers} />
  </Section>
);


export default BlockHotelListByDestinationMobile;