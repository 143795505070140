import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelLinkList from "@/src/ui/components/hotel/HotelLinkList";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {useTranslation} from "next-i18next";

const ModalOfferFormByHotel = (): JSX.Element => {
  const { t } = useTranslation();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const modalState = useModalProvider(state => state);
  const hotelTree = layoutData.hotelTree;
  const onCloseModal = () => {
    modalState.closeModal();
  };

  const onHotelSelected = (hotel: T_HotelTreeHotel) => {
    if (!hotel.hotel_section_offers_url) {
      modalState.toggleModal(ModalKey.NOTIFICATION, {
        type: T_NotificationType.ERROR,
        title: '¡ErrorEnum!',
        message: 'El hotel no tiene configurada la pestaña de ofertas',
      });
      return;
    }
    modalState.toggleModal(ModalKey.LOADER, {
      message: 'global.generic_link_loader.message'
    });
    window.location.href = hotel.hotel_section_offers_url;
  }

  return (
    <ModalLayout
      title={t('ModalOfferFormByHotel._Index.title')}
      className="w-115 max-w-full h-127.5"
      onCloseModal={onCloseModal}
    >
      <div className="flex-1 overflow-auto">
        <ul className="p-5">
          {hotelTree.regions.map((region) => (
            <li key={region.id} className="w-75 flex-none">
              <HotelLinkList region={region} onHotelSelected={onHotelSelected} />
            </li>
          ))}
        </ul>
      </div>
    </ModalLayout>
  );
}

export default ModalOfferFormByHotel;
