import {PropsWithChildren, useState} from "react";
import cn from "classnames";

type _Props =  PropsWithChildren<{
  title: string
}>

const DropdownParagraph = (props: _Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  const onClick = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="text-sm text-brand-2-90">
      <p
        className="flex items-center cursor-pointer space-x-2.5 font-bold"
        onClick={onClick}
      >
        <span>{props.title}</span>
        <img
          className={cn(
            "h-2 w-auto transition transform",
            isOpen && 'rotate-180'
          )}
          src="/assets/icons/chevron-brand-2-90.svg"
          alt=""
        />
      </p>
      {isOpen && (
        <div className='py-2.5'>
          {props.children}
        </div>
      )}
    </div>
  );
}

export default DropdownParagraph;
