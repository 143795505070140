import {T_ParagraphAlertBlock} from "@/src/core/app/domain/models/paragraph/T_ParagraphAlertBlock";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {CMS_ParagraphAlertBlock_AlertType} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphAlertBlock";
import cn from "classnames";


const icons : Record<CMS_ParagraphAlertBlock_AlertType, string> = {
  [CMS_ParagraphAlertBlock_AlertType.SUCCESS]: '/assets/icons/check-circle.svg',
  [CMS_ParagraphAlertBlock_AlertType.INFO]: '/assets/icons/info.svg',
  [CMS_ParagraphAlertBlock_AlertType.WARNING]: '/assets/icons/error.svg',
  [CMS_ParagraphAlertBlock_AlertType.ERROR]: '/assets/icons/error.svg',
};

const BlockAlertBlock = (props: T_ParagraphAlertBlock) => (
  <Section paddingY="pt-10">
    <MaxWidthWrapper>
      <article className={cn(
        "flex space-x-2.5 py-2.5 px-5",
        {
          'bg-yellow-notification': props.field_alert_type === CMS_ParagraphAlertBlock_AlertType.INFO,
          'bg-green-notification': props.field_alert_type === CMS_ParagraphAlertBlock_AlertType.SUCCESS,
          'bg-red-notification': [CMS_ParagraphAlertBlock_AlertType.ERROR, CMS_ParagraphAlertBlock_AlertType.WARNING].indexOf(props.field_alert_type) !== -1,
        }
      )}>
        <img className="w-5 flex-none h-auto" src={icons[props.field_alert_type]} alt="" />
        <WrapperDiv
          className="flex-1 text-sm text-brand-2 leading-snug"
          dangerouslySetInnerHTML={{__html: props.field_body.processed}}
        />
      </article>
    </MaxWidthWrapper>
  </Section>
)
export default BlockAlertBlock;
