import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import {T_Lounge} from "@/src/core/app/domain/models/lounge/T_Lounge";
import LegendBox from "@/src/ui/components/generic/LegendBox";
import React from "react";
import AssetCmsIcon from "@/src/ui/components/generic/AssetCmsIcon";
import {useTranslation} from "next-i18next";

interface _Props {
  lounge: T_Lounge
}

const _Features = (props: _Props) => {
  const { t } = useTranslation();
  const lounge = props.lounge;

  if (!lounge.field_disposition_items.length && !lounge.field_information_items.length) {
    return null;
  }

  return (
    <div>
      <LegendBox title={t('ModalLounge._Features.info_title')}>
        <div className="space-y-2.5">
          {lounge.field_information_items.map((item, index) => (
            <div className="flex" key={index}>
              <span className="font-bold">{item.text_1}:&nbsp;</span>
              <span>{item.text_2}</span>
            </div>
          ))}
        </div>
      </LegendBox>
      <LegendBox title={t('ModalLounge._Features.disposition_title')}>
        <div className="space-y-2.5">
          {lounge.field_disposition_items.map((item, index) => (
            <div className="flex items-center" key={index}>
              <AssetCmsIcon
                className="mr-4 h-4.5"
                iconKey={item.icon_class}
              />
              <span className="font-bold">{item.text_1}:&nbsp;</span>
              <span>{item.text_2}</span>
            </div>
          ))}
        </div>
      </LegendBox>
    </div>
  );
}

export default _Features;
