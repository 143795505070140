import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useTranslation} from "next-i18next";

const _AlreadyRegistered = () => {
  const { t } = useTranslation();
  const layoutData = useLayoutData<UI_LayoutBase>();
  return (
    <p className="text-sm text-brand-2">
      {t('Route_UserRegister._AlreadyRegistered.already_registered_label')}{" "}
      <Anchor
        className="text-link"
        href={layoutData.sitePageUrls.elba_fidelity_page_url}
      >
        {t('Route_UserRegister._AlreadyRegistered.login_button_label')}
      </Anchor>
    </p>
  );
};

export default _AlreadyRegistered;
