import {T_ParagraphHotelsMap} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelsMap";
import _Map from "@/src/ui/components/blocks/BlockHotelsMap/generic/map";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import Anchor from "@/src/ui/components/generic/Anchor";
import cn from "classnames";
import GoogleMapsUtils from "@/src/core/app/utils/google-maps";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelList: T_HotelTreeHotel[]
  paragraph: T_ParagraphHotelsMap
  infoPosition?: 'overlay-top' | 'overlay-bottom' | 'bottom'
}

const BlockHotelsMapMobile = (props: _Props) => {
  const { t } = useTranslation();
  const infoPosition = props.infoPosition || 'bottom';
  const hotel = props.hotelList[0];
  return (
    <div className={cn([infoPosition !== 'bottom' && 'relative'])}>
      <_Map
        hotelList={props.hotelList}
        className='h-60'
      />
      {props.hotelList.length === 1 && (
        <figcaption className={cn([
          infoPosition === 'overlay-top' && 'absolute top-0 inset-x-0 bg-white-80',
          infoPosition === 'overlay-bottom' && 'absolute bottom-0 inset-x-0 bg-white-80',
          infoPosition === 'bottom' && 'border-b-2',
          "py-2.5 px-3.5"
        ])}>
          <h2 className="text-big-bold">
            {hotel.title}
          </h2>
          <Anchor
            className="text-brand-2-90 underline text-xs"
            target='_blank'
            href={GoogleMapsUtils.buildLink(hotel.latitude, hotel.longitude)}
          >
            {t('BlockHotelsMap._Index.google_maps_link_label')}
          </Anchor>
        </figcaption>
      )}
    </div>
  )
}

export default BlockHotelsMapMobile;