import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormInputSelect from "src/ui/components/formik/FormInputSelect";
import FormUtils from "@/src/ui/utils/form";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import CalendarUtils from "@/src/ui/utils/calendar";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";

const _PaymentDataDesktop = () => {
  const { t } = useTranslation();
  return (
    <TitledBox title={t('CheckoutCustomerInfoForm._PaymentData.title')}>
      <div className="px-5 py-4 space-y-3.5">
        <div className="flex space-x-5">
          <FormInputText
            label={t('CheckoutCustomerInfoForm._PaymentData.first_name_label') + ' *'}
            name="ccFirstName"
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            label={t('CheckoutCustomerInfoForm._PaymentData.last_name_label') + ' *'}
            name="ccLastName"
            validate={FormUtils.validateIsRequired}
          />
          <FormInputSelect
            label={t('CheckoutCustomerInfoForm._PaymentData.cc_type_label') + ' *'}
            name="ccType"
            validate={FormUtils.validateIsRequired}
            options={[
              { value: 'visa', label: 'Visa' },
              { value: 'mastercard', label: 'Mastercard' },
              { value: 'amex', label: 'American Express' },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 gap-x-5 pb-6">
          <div className="col-span-2 grid grid-cols-4 flex space-x-5">
            <div className="col-span-3 flex">
              <FormInputText
                label={t('CheckoutCustomerInfoForm._PaymentData.cc_number_label') + ' *'}
                name="ccNumber"
                validate={FormUtils.validateIsRequired}
              />
            </div>
            <div className="flex">
              <FormInputText
                label={t('CheckoutCustomerInfoForm._PaymentData.cvv_label') + ' *'}
                name="ccCVV"
                validate={FormUtils.validateIsRequired}
              />
            </div>
          </div>
          <div className="flex space-x-5">
            <FormInputSelect
              label={t('CheckoutCustomerInfoForm._PaymentData.month_label') + ' *'}
              name="ccMonth"
              emptyOption={'- ' + t('CheckoutCustomerInfoForm._PaymentData.month_label')}
              validate={FormUtils.validateIsRequired}
              options={CalendarUtils.getMonthOptions()}
            />
            <FormInputSelect
              label={t('CheckoutCustomerInfoForm._PaymentData.year_label') + ' *'}
              name="ccYear"
              emptyOption={'- ' + t('CheckoutCustomerInfoForm._PaymentData.year_label')}
              validate={FormUtils.validateIsRequired}
              options={CalendarUtils.getYearOptions()}
            />
          </div>
        </div>
        <div className="flex items-center space-x-5 text-brand-2 text-xs">
          <VerisignImg />
          <p>
            {t('CheckoutCustomerInfoForm._PaymentData.warning')}
          </p>
        </div>
      </div>
    </TitledBox>
  );
}

export default _PaymentDataDesktop;
