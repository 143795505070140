import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import SearchFormRoomOccupationWidget from "@/src/ui/components/search-form/SearchFormRoomOccupationWidget";
import {useSearchFormKidsMaxAge} from "@/src/ui/hooks/useSearchFormKidsMaxAge";

interface _RoomProps {
  roomIndex: number
  room: SearchParamsOccupationRoom
}

export const _Room = ({
 roomIndex,
 room,
} : _RoomProps) : JSX.Element => {
  const roomSubAdult = useSearchFormOccupationProvider(state => state.roomSubAdult);
  const roomAddAdult = useSearchFormOccupationProvider(state => state.roomAddAdult);
  const roomSubKid = useSearchFormOccupationProvider(state => state.roomSubKid);
  const roomAddKid = useSearchFormOccupationProvider(state => state.roomAddKid);
  const roomChangeKidAge = useSearchFormOccupationProvider(state => state.roomChangeKidAge);
  const kidsMaxAge = useSearchFormKidsMaxAge();

  return (
    <SearchFormRoomOccupationWidget
      roomIndex={roomIndex}
      occupation={room}
      onAddAdult={() => roomAddAdult(roomIndex)}
      onSubAdult={() => roomSubAdult(roomIndex)}
      onAddKid={() => roomAddKid(roomIndex, kidsMaxAge)}
      onSubKid={() => roomSubKid(roomIndex)}
      onKidAgeChange={(kidIndex, kidAge) => roomChangeKidAge(roomIndex, kidIndex, kidAge)}
      kidsMaxAge={kidsMaxAge}
    />
  )
}