import {useDevice} from "@/src/ui/hooks/useDevice";
import {T_ParagraphInfoTextImage} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoTextImage";
import BlockInfoTextImageDesktop from "@/src/ui/components/blocks/BlockInfoTextImage/desktop";
import BlockInfoTextImageMobile from "@/src/ui/components/blocks/BlockInfoTextImage/mobile";


const BlockInfoTextImage = (props: T_ParagraphInfoTextImage) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockInfoTextImageMobile : BlockInfoTextImageDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockInfoTextImage;
