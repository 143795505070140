import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodeBlogDetail} from "@/src/core/app/domain/models/node/T_NodeBlogDetail";
import _Body from "@/src/ui/components/routes/Route_BlogDetail/mobile/body";
import _Related from "@/src/ui/components/routes/Route_BlogDetail/mobile/related";
import BlogCategoryListMobile from "@/src/ui/components/blog/BlogCategoryList/mobile";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_BlogDetailMobile = (props: RouteProps<T_NodeBlogDetail>) : JSX.Element => (
  <>
    <PageHeader header={props.routeData.data.page_header} />
    <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
    <BlogCategoryListMobile
      categories={props.routeData.data.categories}
      currentTaxonomy={props.routeData.data.taxonomy_term}
    />
    <div className="px-5 py-7.5 divide-y divide-gray-40">
      <_Body {...props.routeData.data} />
      <_Related {...props.routeData.data} />
    </div>
  </>
)

export default Route_BlogDetailMobile;
