import HotelTeaserDesktop from "@/src/ui/components/hotel/HotelTeaser/desktop";
import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import {CMS_ParagraphHotelList_LinkSection} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";

interface _Props {
  hotelTeasers: T_HotelTeaser[]
  linkSection?: CMS_ParagraphHotelList_LinkSection
}

const HotelTeaserListDesktop = (props: _Props): JSX.Element => (
  <ul className="space-y-5">
    {props.hotelTeasers.map((item, index) => (
      <li key={item.id}>
        <HotelTeaserDesktop
          hotelTeaser={item}
          linkSection={props.linkSection}
        />
      </li>
    ))}
  </ul>
);

export default HotelTeaserListDesktop;
