import React, {useEffect, useRef} from "react";
import LowestPriceTagDesktop from "@/src/ui/components/desktop/LowestPriceTag";
import {CheckoutRateRoomAndRoomTeaser} from "@/src/core/app/domain/models/checkout/CheckoutRateRoomAndRoomTeaser";
import HotelRoomTeaserCommonDesktop from "@/src/ui/components/hotel-room/desktop/HotelRoomTeaserCommon";
import CheckoutRateGroupDesktop from "@/src/ui/components/checkout/desktop/CheckoutRateGroup";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";

interface _Props {
  room: CheckoutRateRoomAndRoomTeaser
  onClick: (
    room: CheckoutRateRoomAndRoomTeaser,
    rateGroup: CheckoutRatesHotelRoomRateGroup,
    board: CheckoutRatesHotelRoomRateGroupBoard
  ) => void
}

const HotelRoomTeaserWithRatesDesktop = (props: _Props) => {
  const ref = useRef(null);
  const lowestPrice = CheckoutUtils.getRoomLowestPrice(props.room.rateRoom);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!CheckoutUtils.isRoomCodeInUrl(props.room.roomTeaser.code)) {
      return;
    }

    // @ts-ignore
    const height = document.querySelector('#he-layout-header-desktop').scrollHeight;
    // @ts-ignore
    document.querySelector('html').scrollTo({
      left: 0,
      // @ts-ignore
      top: ref.current.offsetTop - height  - 10,
      behavior: "smooth",
    });
  }, []);

  return (
    <div ref={ref}>
      <HotelRoomTeaserCommonDesktop
        hotelRoom={props.room.roomTeaser}
        rightTopComponent={(
          <LowestPriceTagDesktop lowestPrice={lowestPrice} />
        )}
        bottomComponent={(
          <>
            {props.room.rateRoom.rate_groups.map(rateGroup => (
              <CheckoutRateGroupDesktop
                key={rateGroup.code}
                rateGroup={rateGroup}
                onClick={(
                  rateGroup: CheckoutRatesHotelRoomRateGroup,
                  board: CheckoutRatesHotelRoomRateGroupBoard
                ) => props.onClick(props.room, rateGroup, board)}
              />
            ))}
           </>
         )}
      />
    </div>
  )
}

export default HotelRoomTeaserWithRatesDesktop;
