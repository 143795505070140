import Button from "@/src/ui/components/generic/Button";
import React, {ReactNode} from "react";
import ModalDesktopKey from "@/src/ui/@types/modal/ModalDesktopKey";
import {useTranslation} from "next-i18next";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import OfferSearchUtils from "@/src/core/app/utils/offer-search";
import CalendarUtils from "@/src/ui/utils/calendar";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {useRoute} from "@/src/ui/hooks/useRoute";
import {useDevice} from "@/src/ui/hooks/useDevice";
import ModalMobileKey from "@/src/ui/@types/modal/ModalMobileKey";

const OfferSearchDateFilter = () => {
  const device = useDevice();
  const route = useRoute();
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const dateRange = OfferSearchUtils.getRangeFromRoute(route);

  const onClick = () => {
    modalState.toggleModal(
      device.isMobile ?
        ModalMobileKey.OFFER_SEARCH_DATES_FILTER :
        ModalDesktopKey.OFFER_SEARCH_DATES_FILTER
    );
  };

  let text : string | ReactNode = t('OfferSearch._Index.show_date_filter_popup_label');

  if (!SearchParamsUtils.isDateRangeEmpty(dateRange)) {
    const start = CalendarUtils.formatDate(dateRange.start as string);
    const end = CalendarUtils.formatDate(dateRange.end as string);

    text = (
      <span className='flex space-x-2.5'>
        <span className='text-brand-2 font-bold'>
          {start} - {end}
        </span>
        <img className='w-3' src="/assets/icons/chevron-down.svg" alt=""/>
      </span>
    );
  }

  return (
    <Button
      style="ghost"
      text={text}
      customPaddingClass='px-3 py-2'
      onClick={onClick}
    />
  );
};

export default OfferSearchDateFilter;
