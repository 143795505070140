import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import HotelRoomTeaserLoadingDesktop from "@/src/ui/components/hotel-room/desktop/HotelRoomTeaserLoading";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const _CheckoutStatusLoading = (props: T_ParagraphHotelRoomList) => {
  const rooms = Array(3).fill(0);
  return (
    <Section paddingY="py-15">
      <MaxWidthWrapper>
        <div className='pb-10'>
          <TitleWithTag
            field={props.field_title_with_tag}
            className="title-4 font-calistoga"
          />
        </div>
        <ul className="space-y-5">
          {rooms.map((room, index) => (
            <li key={index}>
              <HotelRoomTeaserLoadingDesktop />
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  )
}

export default _CheckoutStatusLoading;
