export enum CheckoutRatesHotelRoomRateGroupConfiguration_Style {
  BLACK_FRIDAY = "BLACK-FRIDAY",
  CYBER_MONDAY = "CYBER-MONDAY",
  BLUE_MONDAY = "BLUE-MONDAY",
  LOVE = "LOVE",
  CHRISTMAS = "CHRISTMAS",
  NEW_YEARS_EVE = "NEW-YEARS-EVE",
  RELAX = "RELAX",
  HALLOWEEN = "HALLOWEEN",
  GOLF = "GOLF",
  GETAWAY = "GETAWAY",
  SUMMER = "SUMMER",
}