import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useDevice} from "@/src/ui/hooks/useDevice";
import Route_BlogCategoryDesktop from "@/src/ui/components/routes/Route_BlogCategory/desktop";
import Route_BlogCategoryMobile from "@/src/ui/components/routes/Route_BlogCategory/mobile";
import {T_TaxonomyTermBlogCategoryDetail} from "@/src/core/app/domain/models/taxonomy/T_TaxonomyTermBlogCategoryDetail";

const Route_BlogCategory = (props: RouteProps<T_TaxonomyTermBlogCategoryDetail>) : JSX.Element => {
  const device = useDevice();
  const _Component = device.isDesktop ? Route_BlogCategoryDesktop : Route_BlogCategoryMobile;
  return (
    <_Component {...props} />
  );
}

export default Route_BlogCategory;
