import {useDevice} from "@/src/ui/hooks/useDevice";
import _PaymentDataDesktop from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/desktop/payment-data";
import _PaymentDataMobile from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/mobile/payment-data";

const _PaymentData = () => {
  const device = useDevice();
  const _Component = device.isMobile ? _PaymentDataMobile : _PaymentDataDesktop;
  return (
    <_Component />
  );
}

export default _PaymentData;
