import {T_ParagraphMultiGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphMultiGallery";
import {T_ParagraphMultiGalleryItem} from "@/src/core/app/domain/models/paragraph/T_ParagraphMultiGalleryItem";
import {T_ParagraphSimpleGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphSimpleGallery";

const UiGalleryUtils = {
  getMultiGalleryDesktopRows: (paragraph: T_ParagraphMultiGallery) : number => {
    return paragraph.field_multi_gallery_items.reduce((previousValue, currentValue) => {
      if (previousValue === null) {
        return currentValue.field_mgi_row;
      }
      if (currentValue.field_mgi_row > previousValue) {
        return currentValue.field_mgi_row;
      }
      return previousValue;
    }, 0);
  },
  getMultiGalleryItemDesktopWidth: (multiGalleryItem: T_ParagraphMultiGalleryItem, rowSize: number, gap: number) => {
    const size = multiGalleryItem.field_teaser_display.split('x');
    const width = parseInt(size[0]);
    return width * rowSize + ((width - 1) * gap);
  },
  getSingleGalleryLegend: (simpleGallery: T_ParagraphSimpleGallery, selectedIndex: number, totalItemsCount: number) : string => {
    if (simpleGallery.field_title) {
      return `${simpleGallery.field_title} (${selectedIndex + 1} de ${totalItemsCount})`;
    }
    return `${selectedIndex + 1} de ${totalItemsCount}`;
  },
}

export default UiGalleryUtils;
