import {T_ParagraphHotelsMap} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelsMap";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockHotelsMapDesktop from "@/src/ui/components/blocks/BlockHotelsMap/desktop";
import BlockHotelsMapMobile from "@/src/ui/components/blocks/BlockHotelsMap/mobile";

const BlockHotelsMap = (props: T_ParagraphHotelsMap) => {
  const device = useDevice();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const ids = props.field_hotels.map(item => item.id);
  const hotelList = HotelTreeUtils.getHotels(layoutData.hotelTree).filter(item => ids.indexOf(item.id) !== -1);

  const _Component = device.isDesktop ? BlockHotelsMapDesktop : BlockHotelsMapMobile;
  return (
    <_Component
      hotelList={hotelList}
      paragraph={props}
    />
  );
};

export default BlockHotelsMap;
