import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_ParagraphResidentsList} from "@/src/core/app/domain/models/paragraph/T_ParagraphResidentsList";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import UiMediaUtils from "@/src/ui/utils/media";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Anchor from "@/src/ui/components/generic/Anchor";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

const _Row = (props: T_ParagraphResidentsList) => {
  const { t } = useTranslation();
  return (
    <Section backgroundColor={props.field_background_color}>
      <MaxWidthWrapper>
        <div className="pb-7 5">
          <TitleWithTag
            className='text-2xl'
            field={props.field_title_with_tag}
          />
        </div>
        <ul className='space-y-5'>
          {props.residents.map((item, index) => (
            <li
              key={item.id}
              className="relative cursor-pointer flex items-center justify-center border border-gray-40 bg-white"
            >
              <Anchor path={item.path}>
                <article className='p-2.5'>
                  <img
                    className="w-full"
                    {...UiMediaUtils.getImgPropsFromMediaImage(item.ui_cpt_field_resident_teaser_mobile)}
                  />
                  <div>
                    <p className="pt-3 pb-2.5 text-brand-2-60 text-xs strong uppercase">{item.ui_cpi_field_region_title}</p>
                    <h2 className='text-2xl'>{item.title}</h2>
                    {item.field_teaser && (
                      <WrapperDiv
                        className="py-3 text-sm text-brand-2-90 Cms-Formatted"
                        dangerouslySetInnerHTML={{__html: item.field_teaser.processed}} />
                    )}
                    <ArrowLink href={undefined}>
                      <p>{t('BlockResidentsList._Row.link_label')}</p>
                    </ArrowLink>
                  </div>
                </article>
              </Anchor>
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  );
}

export default _Row;
