import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {T_ParagraphHotelListFiltrable} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelListFiltrable";
import _Filter from "@/src/ui/components/blocks/BlockHotelListFiltrable/mobile/filter";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import HotelTeaserListMobile from "@/src/ui/components/hotel/HotelTeaserList/mobile";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";
import {
  BlockListHotelFiltrableMode
} from "@/src/ui/components/blocks/BlockHotelListFiltrable/@types/BlockListHotelFiltrableMode";
import _ModeButton from "@/src/ui/components/blocks/BlockHotelListFiltrable/generic/mode-button";
import _Map from "@/src/ui/components/blocks/BlockHotelListFiltrable/generic/map";

interface _Props {
  mode: BlockListHotelFiltrableMode
  toggleMode: VoidFunction
  paragraph: T_ParagraphHotelListFiltrable
  options: FormInputSelectOption[]
  currentOption: FormInputSelectOption | null
  onChange: (option: FormInputSelectOption | null) => void
  hotelTeasers: T_HotelTeaser[]
}

const BlockHotelListFiltrableMobile = (props: _Props) => {
  const { t } = useTranslation();
  return (
    <Section paddingY="pt-7.5 pb-15" backgroundColor={props.paragraph.field_background_color}>
      <MaxWidthWrapper>
        {props.paragraph.field_title_with_tag && (
          <TitleWithTag
            className="title-4 font-calistoga"
            field={props.paragraph.field_title_with_tag}
            suffix={`(${props.hotelTeasers.length})`}
          />
        )}
        <div className="flex justify-between items-center text-brand-2 text-sm pb-7.5 pt-2.5">
          <_Filter
            placeholder={t('BlockHotelListFiltrable._Filter.placeholder')}
            options={props.options}
            currentOption={props.currentOption}
            onChange={props.onChange}
          />
          <_ModeButton mode={props.mode} toggleMode={props.toggleMode} />
        </div>
        {props.mode === BlockListHotelFiltrableMode.LIST && (
          <HotelTeaserListMobile hotelTeasers={props.hotelTeasers}/>
        )}
        {props.mode === BlockListHotelFiltrableMode.MAP && (
          <_Map hotelTeasers={props.hotelTeasers} />
        )}
      </MaxWidthWrapper>
    </Section>
);
}

export default BlockHotelListFiltrableMobile;
