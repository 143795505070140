import styled from "styled-components";

export const ModalLoaderCircleAnimated = styled.div`
  width: 60px;
  height: 60px;
  border: solid 5px #12A9E2;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: modal-loader-rotate;
  -webkit-animation-duration: 1.0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: modal-loader-rotate;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes modal-loader-rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  @-webkit-keyframes modal-loader-rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`