import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {locator} from "@/src/core/app/ioc";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {TYPES} from "@/src/core/app/ioc/types";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import {UserState} from "@/src/ui/view_models/user.state";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {UserPasswordChangeFormValues} from "@/src/core/app/domain/forms/UserPasswordChangeFormValues";
import {UserPasswordChangeInput} from "@/src/core/app/domain/user/@types/Input/UserPasswordChangeInput";

const UserPasswordChangeUtils = {
  buildInput: (data: UserPasswordChangeFormValues) => {
    return {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword1,
    } as UserPasswordChangeInput;
  },
  save: async (
    data: UserPasswordChangeFormValues,
    userState: UserState,
  ) : Promise<NetworkResponse> => {
    const userData = userState.userData as UserData;
    const input = UserPasswordChangeUtils.buildInput(data);
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.userPasswordChange(userData.id, input, CMS_AuthUtils.getAuthHeaders());
  },
};

export default UserPasswordChangeUtils;
