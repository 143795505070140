import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import HotelRoomTeaserCommonMobile from "@/src/ui/components/hotel-room/mobile/HotelRoomTeaserCommon";
import Button from "@/src/ui/components/generic/Button";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalMobileKey from "@/src/ui/@types/modal/ModalMobileKey";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelRoom: T_HotelRoomTeaser
}

const HotelRoomTeaserMobile = (props: _Props) => {
  const { t } = useTranslation();
  const searchForm = useSearchFormProvider(state => state);
  const modalState = useModalProvider(state => state);

  const onClick = () => {
    if (!SearchParamsUtils.isEmpty(searchForm)) {
      SearchFormUtils.submit(searchForm, modalState, props.hotelRoom.code);
      return;
    }

    modalState.toggleModal(ModalMobileKey.SEARCH_FORM, {
      roomCode: props.hotelRoom.code
    });
  };

  return (
    <HotelRoomTeaserCommonMobile
      hotelRoom={props.hotelRoom}
      bottomComponent={(
        <Button
          className='inline-block select-none'
          text={t('HotelRoomTeaserCommon._Index.check_availability_label')}
          style="secondary"
          bold
          onClick={onClick}
        />
      )}
    />
  );
}

export default HotelRoomTeaserMobile;
