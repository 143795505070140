import {LanguageDropdownMode} from "@/src/ui/utils/language";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useHeaderMobileMenuProvider} from "@/src/ui/providers/header-mobile-menu";
import LanguageDropdown from "@/src/ui/components/generic/LanguageDropdown";
import {useTranslation} from "next-i18next";
import UrlUtils from "@/src/core/app/utils/url";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

const HeaderMobileTop = (): JSX.Element => {
  const { t } = useTranslation();
  const headerMenuState = useHeaderMobileMenuProvider(state => state);
  const appInfo = useAppInfo();

  return (
    <div className="bg-gray-20 px-5 py-2 flex items-center text-brand-2">
      <p className="sr-only">{t('global.generic.site_name')}</p>
      <div className="flex-1">
        <button
          onClick={() => headerMenuState.open()}
          className="h-6 w-7 flex flex-col justify-between items-stretch"
        >
          <div className="h-3px bg-brand-2" />
          <div className="h-3px bg-brand-2" />
          <div className="h-3px bg-brand-2" />
        </button>
      </div>
      <Anchor href={UrlUtils.getHomepageUrl(appInfo.langcode)}>
        <img
          className="flex-none h-15 w-auto"
          src="/assets/images/generic/elba-logo.svg"
        />
      </Anchor>
      <div className="flex-1 flex justify-end items-center space-x-1.5">
        <img className="h-6" src="/assets/icons/person-brand-2.svg" alt="" />
        <LanguageDropdown
          color="brand-2"
          spaceBetween={true}
          mode={LanguageDropdownMode.SHORT}
        />
      </div>
    </div>
  );
}

export default HeaderMobileTop;
