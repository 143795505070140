import type {RouteProps} from "@/src/ui/@types/RouteProps";
import BlockListComponent from "@/src/ui/components/generic/BlockListComponent";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import {T_NodePage} from "@/src/core/app/domain/models/node/T_NodePage";
import BreadcrumbWrapper from "src/ui/components/generic/BreadcrumbWrapper";

const Route_Page = (props: RouteProps<T_NodePage>) : JSX.Element => {
 return (
    <>
      <PageHeader header={props.routeData.data.page_header} />
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <BlockListComponent blocks={props.routeData.data.field_blocks} />
    </>
  );
}

export default Route_Page;
