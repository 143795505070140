import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useTranslation} from "next-i18next";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";

const BookingConfirmationActions = (): JSX.Element => {
  const layoutData = useLayoutData<UI_LayoutBase>();
  const { t } = useTranslation();
  const routeData = useRouteData<BookingConfirmationRouteProps>();

  return (
    <aside className="px-4 py-3.5 bg-gray-20 border border-gray-40 text-sm text-brand-2">
      <h4 className="font-bold text-brand-2-60 uppercase">{t('BookingConfirmationActions._Index.title')}</h4>
      <WrapperDiv
        size="medium"
        className="pt-1.5 pb-2.5 space-y-5 Cms-Formatted"
        dangerouslySetInnerHTML={{__html: routeData.data.hotel.field_contact_info?.processed}}
      />
      <nav className="font-bold space-y-3.5">
        <div className="flex hover:underline items-center space-x-2.5">
          <p>
            <Anchor href={layoutData.sitePageUrls.my_booking_manage_page_url}>
              {t('BookingConfirmationActions._Index.manage_booking_link_label')}
            </Anchor>
          </p>
          <img
            className="h-4 w-auto"
            src="/assets/icons/chevron-right-brand-2.svg"
            alt=""
          />
        </div>
      </nav>
    </aside>
  );
}

export default BookingConfirmationActions;
