import {T_ParagraphSimpleGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphSimpleGallery";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import React from "react";
import {useCarouselBasic} from "@/src/ui/hooks/useCarouselBasic";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import UiGalleryUtils from "@/src/ui/utils/gallery";

const BlockSimpleGalleryDesktop = (props: T_ParagraphSimpleGallery) => {
  const {
    emblaRef,
    selectedIndex,
    scrollPrev,
    scrollNext,
  } = useCarouselBasic(props.ui_cpt_field_medias_gallery);

  return (
    <Section paddingY="pt-7.5 pb-5" backgroundColor={props.field_background_color}>
      <MaxWidthWrapper>
        <div className="relative">
          <div className="pt-2.5 pb-5">
            <div className="embla h-170 overflow-hidden" ref={emblaRef}>
              <div className="embla__container flex h-full">
                {props.ui_cpt_field_medias_gallery.map((img, index) => (
                  <div
                    key={index}
                    className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
                    style={{ flexBasis: "100%" }}
                  >
                    <MediaImageBgImg mediaImage={img} />
                  </div>
                ))}
              </div>
              <div className="absolute inset-0 max-w-screen-xl mx-auto p-5 flex items-center justify-between">
                <button
                  className="embla__prev relative z-10 transition-all transform hover:scale-110"
                  onClick={scrollPrev}
                >
                  <img src="/assets/icons/arrow-left-circle-transparent.svg" alt="" />
                </button>
                <button
                  className="embla__next relative z-10 transition-all transform hover:scale-110"
                  onClick={scrollNext}
                >
                  <img src="/assets/icons/arrow-right-circle-transparent.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-sm text-brand-2-90">
          {UiGalleryUtils.getSingleGalleryLegend(props, selectedIndex, props.ui_cpt_field_medias_gallery.length)}
        </p>
      </MaxWidthWrapper>
    </Section>
  );
}

export default BlockSimpleGalleryDesktop;