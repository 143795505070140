import {T_ParagraphMapInfoSlider} from "@/src/core/app/domain/models/paragraph/T_ParagraphMapInfoSlider";
import useEmblaCarousel from "embla-carousel-react";
import {useCallback, useEffect, useState} from "react";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import TitleGroupDesktop from "src/ui/components/desktop/TitleGroup";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import {T_ParagraphMapInfoSliderItem} from "@/src/core/app/domain/models/paragraph/T_ParagraphMapInfoSliderItem";
import UiMediaUtils from "@/src/ui/utils/media";
import _Controller from "@/src/ui/components/blocks/BlockMapInfoSlider/controller";
import _Map from "@/src/ui/components/blocks/BlockMapInfoSlider/map";

const renderItem = (item: T_ParagraphMapInfoSliderItem, index: number) => (
  <article
    key={index}
    className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
    style={{flexBasis: "100%"}}
  >
    <Section>
      <MaxWidthWrapper>
        <div className="flex justify-between">
          <div className="max-w-xl">
            <div className="pb-10">
              <TitleGroupDesktop
                titleWithTag={item.field_title_with_tag}
                subtitle={item.field_subtitle}
                description={item.field_body.processed}
              />
            </div>
            {item.field_one_link && (
              <div className="pt-0 5">
                <ArrowLink href={item.field_one_link.url}>{item.field_one_link.title}</ArrowLink>
              </div>
            )}
          </div>
          <div className="max-w-lg pl-2">
            <img
              className="w-full h-auto"
              {...UiMediaUtils.getImgPropsFromMediaImage(item.field_media)}
            />
          </div>
        </div>
      </MaxWidthWrapper>
    </Section>
  </article>
);

const sideEffectCheckAndSetMapInitiated = (
  mapInitiated: boolean,
  setMapInitiated: (mapInitiated: boolean) => void,
  nextIndex: number
) => {
  if (mapInitiated || nextIndex !== 0) {
    return;
  }
  setMapInitiated(true);
};

const BlockMapInfoSlider = (props: T_ParagraphMapInfoSlider) => {
  const hasElements = props.field_slider_items.length > 0;
  const startIndex = hasElements ? 1 : 0;
  // const startIndex = 0;
  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex,
  });
  const [mapInitiated, setMapInitiated] = useState(startIndex === 0);
  const [selectedIndex, setSelectedIndex] = useState(startIndex);
  const [scrollSnaps, setScrollSnaps] = useState([] as number[]);

  const scrollPrev = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
    sideEffectCheckAndSetMapInitiated(mapInitiated, setMapInitiated, emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    sideEffectCheckAndSetMapInitiated(mapInitiated, setMapInitiated, emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap);
    sideEffectCheckAndSetMapInitiated(mapInitiated, setMapInitiated, emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  const scrollTo = useCallback((index: number) => {
    if (!emblaApi) return;
    emblaApi.scrollTo(index);
    sideEffectCheckAndSetMapInitiated(mapInitiated, setMapInitiated, index);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList);
    emblaApi.on("select", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <section>
      <div className="embla h-109.5 overflow-hidden" ref={emblaRef}>
        <div className="embla__container flex h-full">
          <article
            className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
            style={{flexBasis: "100%"}}
          >
            <_Map initiated={mapInitiated} />
          </article>
          {hasElements && props.field_slider_items.map(renderItem)}
        </div>
      </div>
      <div className="p-5">
        <_Controller
          scrollNext={scrollNext}
          scrollPrev={scrollPrev}
          scrollSnaps={scrollSnaps}
          selectedIndex={selectedIndex}
          scrollTo={scrollTo}
        />
      </div>
    </section>
  )
};

export default BlockMapInfoSlider;
