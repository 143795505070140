import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {ReactElement} from "react";
import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import {_Form} from "@/src/ui/components/portals/desktop/ModalSearchFormStepOccupation/form";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import Button from "@/src/ui/components/generic/Button";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";

interface _PopupProps {
  onSubmit: (rooms: SearchParamsOccupationRoom[]) => void
}

export const _Popup = (props: _PopupProps) : ReactElement | null => {
  const { t } = useTranslation();
  const searchFormOccupation = useSearchFormOccupationProvider(state => state);

  const onSubmit = () => {
    props.onSubmit(searchFormOccupation.rooms);
  };

  return (
    <>
      <_Form />
      <div className="px-5 py-2 flex justify-between items-center border-t border-gray-20">
        <button
          onClick={() => searchFormOccupation.clearRooms()}
          className="font-bold hover:underline"
        >
          {t('ModalSearchFormStepOccupation._Popup.clear_button_label')}
        </button>
        <div className="flex items-center space-x-5 py-2">
          <Paragraph size="medium" className="text-brand-2-60">
            {SearchParamsFormatter.getOccupationDetailedSummary(searchFormOccupation.rooms, t)}
          </Paragraph>
          <div className="w-28" onClick={onSubmit}>
            <Button text={t('ModalSearchFormStepOccupation._Popup.submit_button_label')} bold />
          </div>
        </div>
      </div>
    </>
  );
}