import {T_ParagraphElbaFidelity} from "@/src/core/app/domain/models/paragraph/T_ParagraphElbaFidelity";
import SentryUtils from "@/src/common/utils/sentry";
import BlockElbaFidelityDesktopCols from "@/src/ui/components/blocks/BlockElbaFidelity/desktop/columns";
import BlockElbaFidelityDesktopRows from "@/src/ui/components/blocks/BlockElbaFidelity/desktop/rows";

const BlockElbaFidelityDesktop = (props: T_ParagraphElbaFidelity): JSX.Element | null => {
  if (props.field_elba_fidelity_display === "columns") {
    return (
      <BlockElbaFidelityDesktopCols {...props} />
    );
  }

  if (props.field_elba_fidelity_display === "rows") {
    return (
      <BlockElbaFidelityDesktopRows {...props} />
    );
  }

  SentryUtils.captureMessage('ParagraphElbaFidelity: invalid field_elba_fidelity_display value');
  return null;
}

export default BlockElbaFidelityDesktop;
