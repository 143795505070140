import {CMS_UserTmsInfo} from "@/src/core/app/domain/cms/@types/user/CMS_UserTmsInfo";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {CMS_FieldTextTextarea} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextTextarea";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {useTranslation} from "next-i18next";

interface _Props {
  footer?: CMS_FieldTextTextarea
  userTmsInfo: CMS_UserTmsInfo
}

const _Overview = (props: _Props) => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();

  return (
    <>
      <article className="p-3.75 bg-gray-20 space-y-5">
        <ul className="space-y-3.75">
          <li className="w-38 space-y-2.5">
            <h3 className="title-5">{t('BlockUserTmsInfoDesktop._Overview.your_points')}</h3>
            <em className="not-italic title-2-mobile">{UiCurrencyUtils.formatNumber(props.userTmsInfo.currentPoints, currencyConfig, true)}</em>
          </li>
          <li className="space-y-2.5">
            <h3 className="title-5">{t('BlockUserTmsInfoDesktop._Overview.your_card_number')}</h3>
            <em className="not-italic title-2-mobile">{props.userTmsInfo.cardNumber}</em>
          </li>
        </ul>
        {props.footer && (
          <WrapperDiv
            className="text-xs text-brand-2-90 Cms-Formatted"
            dangerouslySetInnerHTML={{__html: props.footer.processed}}
          />
        )}
      </article>
    </>
  );
}

export default _Overview;