import {T_NodeDestinationTeaser} from "@/src/core/app/domain/models/node/T_NodeDestinationTeaser";
import LocationTag from "@/src/ui/components/generic/LocationTag";
import UiMediaUtils from "@/src/ui/utils/media";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Anchor from "@/src/ui/components/generic/Anchor";

const DestinationTeaserDesktop = (props: T_NodeDestinationTeaser) => (
  <article className="flex items-stretch border border-gray-40 divide-x divide-gray-40 bg-white">
    <Anchor path={props.path}>
      <img
        className="flex-none min-h-73 object-cover w-95"
        {...UiMediaUtils.getImgPropsFromMediaImage(props.ui_cpi_field_destination_teaser)}
      />
    </Anchor>
    <div className="flex-1 p-7.5 pt-5">
      <div className="space-y-1">
        <Anchor path={props.path}>
          <h3 className="text-big-bold">{props.title}</h3>
        </Anchor>
        <LocationTag>{props.field_destination_name}</LocationTag>
      </div>
      {props.field_teaser && (
        <WrapperDiv
          className="pt-3.75 text-sm text-brand-2-90 Cms-Formatted"
          dangerouslySetInnerHTML={{__html:props.field_teaser?.processed}}
        />
      )}
    </div>
    <Anchor
      path={props.path}
      className="flex flex-none p-2 items-center bg-gray-10"
    >
      <img className="w-6 h-auto" src="/assets/icons/chevron-right-circle.svg" alt="" />
    </Anchor>
  </article>
);

export default DestinationTeaserDesktop;
