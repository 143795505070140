import SectionGray from "@/src/ui/components/generic/SectionGray";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import {useCarouselBasic} from "@/src/ui/hooks/useCarouselBasic";
import {useTranslation} from "next-i18next";

interface _Props {
  items: T_MediaImage[]
  legendBuilder?: (selectedIndex: number) => string
}

const FullWidthGalleryMobile = (props: _Props) => {
  const { t } = useTranslation();
  const {
    emblaRef,
    selectedIndex,
    scrollPrev,
    scrollNext,
  } = useCarouselBasic(props.items);

  return (
    <div>
      <div className="h-70 overflow-hidden embla" ref={emblaRef}>
        <div className="embla__container flex h-full">
          {props.items.map((img) => (
            <div
              key={img.id}
              className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
              style={{ flexBasis: "100%" }}
            >
              <MediaImageBgImg  mediaImage={img} />
            </div>
          ))}
        </div>
      </div>
      <SectionGray className="flex-1">
        <div className="flex justify-between items-center text-sm text-brand-2-90">
          <button
            className="transition-all transform hover:scale-110"
            onClick={scrollPrev}
          >
            <img src="/assets/icons/arrow-left-circle-transparent.svg" alt="" />
          </button>
          {}
          <p>{props.legendBuilder ?
            props.legendBuilder(selectedIndex) :
            t('FullwidthGalleryMobile._Gallery.footer', {
              count: selectedIndex + 1,
              total: props.items.length,
            })
          }
          </p>
          <button
            className="transition-all transform hover:scale-110"
            onClick={scrollNext}
          >
            <img src="/assets/icons/arrow-right-circle-transparent.svg" alt="" />
          </button>
        </div>
      </SectionGray>
    </div>
  );
}

export default FullWidthGalleryMobile;
