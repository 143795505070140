import {useHeaderMobileMenuProvider} from "@/src/ui/providers/header-mobile-menu";
import {HeaderMobileMenuSection} from "@/src/ui/view_models/header-mobile-menu.state";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import Anchor from "@/src/ui/components/generic/Anchor";
import Title3Menu from "@/src/ui/components/generic/Title3Menu";
import {useTranslation} from "next-i18next";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";

const HeaderMobileMenuTabDestinations = (): JSX.Element | null => {
  const {t} = useTranslation();
  const layoutData = useLayoutData<UI_LayoutBase>();
  const currentSection = useHeaderMobileMenuProvider(state => state.currentSection);
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();

  if (currentSection !== HeaderMobileMenuSection.DESTINATIONS) {
    return null;
  }

  return (
    <div className="pt-7.5">
      <Anchor href={layoutData.sitePageUrls.destination_list_page_url}>
        <Title3Menu hasArrow>
          {t('HeaderMobileMenu.HeaderMobileMenuTabDestinations.destination_link_label')}
        </Title3Menu>
      </Anchor>
      <ul className="ul pt-5 space-y-5 leading-4 text-sm">
        {routeProps.layoutData.destinations.map((item, index) => (
          <li className="hover:underline" key={item.id}>
            <Anchor href={item.url}>{item.destinationName} ({item.hotelCount})</Anchor>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HeaderMobileMenuTabDestinations;
