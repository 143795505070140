import ShimmerEffect from "@/src/ui/components/generic/ShimmerEffect";

const _Room = () => (
  <div className="border border-gray-40 rounded-sm flex p-5 space-x-4 border-b-0 last:border-b">
    <ShimmerEffect className="h-20 w-20" />
    <div className='space-y-2'>
      <ShimmerEffect className="h-5 w-10" />
      <ShimmerEffect className="h-5 w-30" />
      <ShimmerEffect className="h-5 w-30" />
    </div>
  </div>
);

const BookingManageLoadingMobile = () => (
  <article className="border border-gray-40 rounded-sm">
    <ShimmerEffect className='h-15 w-full' />
    <div className="px-5 py-2.5 space-y-2.5">
      <ShimmerEffect className="h-5 w-full" />
      <ShimmerEffect className="h-5 w-40" />
      <ShimmerEffect className="h-5 w-70" />
    </div>
    <div className='px-5'>
      <_Room />
      <_Room />
    </div>
    <div className="px-5 py-2.5 flex items-center justify-between">
      <ShimmerEffect className="h-5 w-20" />
      <ShimmerEffect className="h-10 w-30 rounded-full" />
    </div>
  </article>
);


export default BookingManageLoadingMobile;