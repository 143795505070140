import {FieldValidator} from "formik/dist/types";
import {useField} from "formik";
import {FormFieldProps} from "@/src/ui/@types/form/FormFieldProps";
import FormUtils from "@/src/ui/utils/form";
import {TFunction} from "react-i18next";

interface _FieldProps {
  name: string
  validate?: FieldValidator
}

export const useFormField = (props: FormFieldProps, t: TFunction) => {
  const fieldProps : _FieldProps = {
    name: props.name,
    validate: undefined,
  };

  const {
    validate,
    validations,
  } = props;

  if (validate) {
    fieldProps.validate = (value: any) => validate(t, value);
  }

  if (validations) {
    fieldProps.validate = (value: any) => FormUtils.multipleValidator(t, value, validations);
  }

  return useField(fieldProps);
}
