import {useContextHeaderDesktopContext} from "@/src/ui/context/HeaderDesktopContext";
import cn from "classnames";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import _MenuHeaderUser from "@/src/ui/components/layout/desktop/HeaderDesktopNavigation/_MenuHeaderUser";
import _MenuHeaderMain from "@/src/ui/components/layout/desktop/HeaderDesktopNavigation/_MenuHeaderMain";
import _MenuHeaderSecondary from "@/src/ui/components/layout/desktop/HeaderDesktopNavigation/_MenuHeaderSecondary";


const HeaderDesktopNavigation = (): JSX.Element => {
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();

  const context = useContextHeaderDesktopContext();
  const classes = cn("pl-14 flex-1 flex flex-col justify-end items-stretch text-sm", {
    'text-white': !context?.isVisible,
    'text-brand-2': context?.isVisible
  });
  return (
    <nav
      className={classes}
    >
      <div className="flex justify-end space-x-7.5 text-xs pb-1.5">
        <_MenuHeaderSecondary
          menu={routeProps.layoutData.menuHeaderSecondary}
          currencies={routeProps.layoutData.currencies}
          languages={routeProps.layoutData.languages}
        />
      </div>
      <div className="flex justify-between items-end pl-1">
        <_MenuHeaderMain menu={routeProps.layoutData.menuHeaderMain} />
        <_MenuHeaderUser menu={routeProps.layoutData.menuHeaderUser} />
      </div>
    </nav>
  )
};

export default HeaderDesktopNavigation;
