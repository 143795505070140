import Button from "../../generic/Button";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import {useTranslation} from "next-i18next";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";

export interface FarePriceProps {
  name: string;
  price: string;
  priceNight: string;
  fidelityPrice: string;
  fidelityPriceNight: string;
}

interface _Props {
  board: CheckoutRatesHotelRoomRateGroupBoard
  onClick: (board: CheckoutRatesHotelRoomRateGroupBoard) => void
}

const CheckoutRateGroupBoardDesktop = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const state = useCheckoutProvider(state => state);
  const currencyConfig = useCurrencyConfig();
  const searchParams = state.searchParams as SearchParams;
  const stepData = state.currentStepData as CheckoutStepRoomsData;
  const pricePerNight = props.board.price / SearchParamsUtils.getDateRangeNights(searchParams.dateRange);
  const isLastRoom = stepData.currentRoomIndex === (searchParams.rooms.length - 1);

  return (
    <article className="flex flex-col items-center py-2.5">
      <h4 className="text-medium-bold">{props.board.name}</h4>
      <div className="text-center text-xs text-brand-1 pb-2.5 pt-2">
        <p>
          <em className="text-big not-italic leading-none">{UiCurrencyUtils.format(props.board.price, currencyConfig)}</em>
        </p>
        <p className="text-brand-2-90 pb-1">
          ({t('CheckoutRateGroupBoard._Index.per_night_label', {
            amount: UiCurrencyUtils.format(pricePerNight, currencyConfig)
          })})
        </p>
        {/*<p>*/}
        {/*  <em className="text-lg not-italic">Elba Fidelity {fidelityPrice}</em>*/}
        {/*</p>*/}
        {/*<p>({fidelityPriceNight} / noche)</p>*/}
      </div>
      <Button
        text={isLastRoom ? t('CheckoutRateGroupBoard._Index.book_label') : t('CheckoutRateGroupBoard._Index.add_label')} bold
        onClick={() => props.onClick(props.board)}
      />
    </article>
  );
}

export default CheckoutRateGroupBoardDesktop;
