import useEmblaCarousel from "embla-carousel-react";
import React, {useState} from "react";

import Section from "../../generic/Section/Section";
import { T_ParagraphBannerSliderSlideMobile } from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSliderSlideMobile";
import Button from "@/src/ui/components/generic/Button";
import {T_ParagraphBannerSlider} from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSlider";
import UiMediaUtils from "@/src/ui/utils/media";
import Anchor from "@/src/ui/components/generic/Anchor";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {
  T_ParagraphBannerSliderSlideDesktop
} from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSliderSlideDesktop";
import {
  T_ParagraphBannerSliderSlide_FooterFontSize
} from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSliderSlide";
import cn from "classnames";
import {useTranslation} from "next-i18next";

const _Footer = (item: T_ParagraphBannerSliderSlideMobile) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);

  if (!item.field_footer || !item.field_footer_font_size) {
    return null;
  }

  const sizes = {
    [T_ParagraphBannerSliderSlide_FooterFontSize.SMALL]: 'text-xs',
    [T_ParagraphBannerSliderSlide_FooterFontSize.MEDIUM]: 'text-sm',
    [T_ParagraphBannerSliderSlide_FooterFontSize.BIG]: 'text-base',
  };

  return (
    <div className='mt-5 pt-5 border-t-1px border-white'>
      <p className='mb-0.5 font-bold text-xs cursor-pointer flex space-x-1'
         onClick={() => setExpanded(!isExpanded)}
      >
        <span>{t('BlockBannerSlider._Index.conditions_label')}</span>
        <img
          className={cn({'rotate-180': isExpanded})}
          src='/assets/icons/banner-slider-footer-arrow-down.svg'
        />
      </p>
      {isExpanded && (
        <p className={sizes[item.field_footer_font_size]}>
          {item.field_footer}
        </p>
      )}
    </div>
  );
};

const renderItem = (item: T_ParagraphBannerSliderSlideMobile, index: number) : JSX.Element => (
  <div key={index} className="embla__slide relative w-full flex-grow-0 flex-shrink-0">
    <img className="h-70 w-full object-cover"
         {...UiMediaUtils.getImgPropsFromMediaImage(item.field_media_mobile)}
    />
    <div className="text-white">
      <Section backgroundColor='none'>
        <TitleWithTag
          field={item.field_title_with_tag}
          className="text-title-2-mobile pb-2.5 leading-tight"
        />
        <WrapperDiv size="medium" dangerouslySetInnerHTML={{__html: item.field_body.processed}} />
        {item.field_one_link && (
          <div className="pt-5 pb-5 mb-1">
            <Anchor href={item.field_one_link.url}>
              <Button
                text={item.field_one_link.title}
                style="secondary-white"
                bold
              />
            </Anchor>
          </div>
        )}
        <_Footer {...item} />
      </Section>
    </div>
  </div>
);

const BlockBannerSliderMobile = (props: T_ParagraphBannerSlider): JSX.Element => {
  const [emblaRef,] = useEmblaCarousel();

  return (
    <div className="relative bg-brand-1 text-white">
      <div className="embla overflow-hidden" ref={emblaRef}>
        <div className="embla__container flex">
          {(props.field_slides as T_ParagraphBannerSliderSlideMobile[]).map((item, index) => renderItem(item, index))}
        </div>
      </div>
    </div>
  )
};

export default BlockBannerSliderMobile;
