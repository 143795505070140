import type {RouteProps} from "@/src/ui/@types/RouteProps";
import _Wrapper from "@/src/ui/components/routes/Route_ElbaMedia/wrapper";
import {useTranslation} from "next-i18next";
import _Loading from "@/src/ui/components/blocks/BlockUserEditForm/desktop/loading";
import {useElbaMediaProvider} from "@/src/ui/providers/elba-media.provider";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import NotificationMessage from "src/ui/components/generic/NotificationMessage";
import {T_Notification} from "@/src/ui/@types/T_Notification";
import ElbaMediaErrors from "@/src/core/app/domain/elba-media/@types/FormErrorMap/ElbaMediaErrors";
import {PropsWithChildren} from "react";
import {ElbaMediaSuccessOutput} from "@/src/core/app/domain/elba-media/@types/Output/ElbaMediaSuccessOutput";
import Pagination from "@/src/ui/components/generic/Pagination";
import PagerUtils from "@/src/core/app/utils/pager";
import _Row from "@/src/ui/components/routes/Route_ElbaMedia/row";

const Route_ElbaMedia = (props: RouteProps) : JSX.Element => {
  const { t } = useTranslation();
  const state = useElbaMediaProvider(state => state);

  if (state.isLoading) {
    return (
      <_Wrapper>
        <_Loading />
      </_Wrapper>
    );
  }

  if (!state.isUserAllowed) {
    return (
      <_Wrapper>
        <p>{t('Route_ElbaMedia._Index.unauthorized_message')}</p>
      </_Wrapper>
    );
  }

  if (state.error) {
    const error = FormErrorUtils.getError({
      networkError: state.error,
      formErrorMap: ElbaMediaErrors
    });
    return (
      <_Wrapper>
        <NotificationMessage {...(error.notification as T_Notification)} />
      </_Wrapper>
    );
  }

  const data = state.data as ElbaMediaSuccessOutput;
  const pager = PagerUtils.getPager(props.route, 20, data.count);

  return (
    <_Wrapper>
      <table className="ElbaMedia_Table">
        <thead>
          <tr>
            <th>{t('Route_ElbaMedia._Index.title_label')}</th>
            <th>{t('Route_ElbaMedia._Index.description_label')}</th>
            <th>{t('Route_ElbaMedia._Index.file_label')}</th>
            <th>{t('Route_ElbaMedia._Index.created_label')}</th>
          </tr>
        </thead>
        <tbody>
        {data.items.map(item => (
          <_Row key={item.id} item={item} />
        ))}
        </tbody>
      </table>
      <Pagination {...pager} />
    </_Wrapper>
  );
}

Route_ElbaMedia.getAppWrapper = (
  props: PropsWithChildren<RouteProps>
) => (
  <useElbaMediaProvider.State>
    {props.children}
  </useElbaMediaProvider.State>
)

export default Route_ElbaMedia;
