import {useTranslation} from "next-i18next";

interface StarRatingProps {
  rating: number | string
  size?: "small" | "big"
}

const StarRating = ({
  rating,
  size = "small",
}: StarRatingProps): JSX.Element => {
  const { t } = useTranslation();
  const value = typeof rating === 'string' ? parseInt(rating) : rating;
  const items = Array(value).fill(0);
  return(
    <figure className="flex space-x-1">
      {items.map((_item, index) => (
        <img
          key={index}
          className={size === "small" ? "h-3 w-auto" : "h-4 w-auto"}
          src="/assets/icons/star.svg"
          alt=""
        />
      ))}
      <figcaption className="sr-only">{t('StarRating._Index.label')}: {rating}</figcaption>
    </figure>
  );
}

export default StarRating;
