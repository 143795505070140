import MaxWidthWrapper from "../../../generic/MaxWidthWrapper";
import cn from "classnames";
import {
  T_HotelSectionHotelCommonInfo
} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";
import HotelUtils from "@/src/core/app/utils/hotel";
import {useRoute} from "@/src/ui/hooks/useRoute";
import Anchor from "@/src/ui/components/generic/Anchor";
import { Breadcrumb } from "@/src/core/app/domain/@types/Breadcrumb";

interface _Props {
  hotel: T_HotelSectionHotelCommonInfo
  breadcrumb: Breadcrumb
}

const _Tabs = (props: _Props): JSX.Element => {
  const route = useRoute();
  const items = props.hotel.field_hotel_sections;

  return (
    <div className="border-b border-gray-20">
      <MaxWidthWrapper>
        <ul className="flex text-xs overflow-x-auto">
          {items.map((section, index) => {
            const isCurrentTab = HotelUtils.isCurrentSection(section, route, props.breadcrumb);
            const sectionTitle = HotelUtils.getSectionTitle(section);
            return (
              <li
                key={section.id}
                className={cn(
                  "HotelTab flex flex-col whitespace-nowrap items-center cursor-pointer",
                  isCurrentTab ? "HotelTab--CurrentTab text-brand-1" : "text-brand-2",
                  (index === 0) && 'HotelTab--FirstTab',
                )}
              >
                <div className='flex items-center'>
                  <Anchor
                    className='flex'
                    href={HotelUtils.getSectionUrl(section) || undefined}
                  >
                    <div
                      className={cn(
                        'HotelTab__Title pr-3',
                        index && "pl-3",
                      )}
                      data-section-title={sectionTitle}
                    >
                      <div className="py-3">
                        <span className='HotelTab__Title__Text leading-7.5'>
                          {sectionTitle}
                        </span>
                      </div>
                      <div className='HotelTab__TitleUnderline block h-0.5 bg-brand-1 w-0' />
                    </div>
                  </Anchor>
                  {index !== (items.length - 1) && (
                    <div className='h-7.5 w-0.5 bg-gray-20'></div>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </MaxWidthWrapper>
    </div>
  );
}

export default _Tabs;
