import OfferGroupTeaserMobile from "../OfferGroupTeaser/mobile";
import { T_OfferGroupTeaser } from "@/src/core/app/domain/models/offer-group/T_OfferGroupTeaser";
import { T_OfferGroupTeaserMobile } from "@/src/core/app/domain/models/offer-group/T_OfferGroupTeaserMobile";

interface Props {
  offers: T_OfferGroupTeaser[]
  max: number
}

const OfferTeaserListMobile = ({ offers, max}: Props): JSX.Element => (
  <ul className="space-y-5">
    {offers.slice(0, max || 4).map((item, index) => (
      <li key={index}>
        <OfferGroupTeaserMobile {...(item as unknown as T_OfferGroupTeaserMobile)} />
      </li>
    ))}
  </ul>
);

export default OfferTeaserListMobile;
