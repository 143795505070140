import {T_ReservationManage} from "@/src/core/app/domain/models/reservation/T_ReservationManage";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {T_ReservationManageRoom} from "@/src/core/app/domain/models/reservation/T_ReservationManageRoom";
import {ModalState} from "@/src/ui/view_models/modal.state";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {locator} from "@/src/core/app/ioc";
import {BookingApiService} from "@/src/core/app/data/services/booking_api_service";
import {TYPES} from "@/src/core/app/ioc/types";
import NetworkNotificationUtils from "@/src/core/app/utils/network-notification";
import BookingCancelErrors from "@/src/core/app/domain/booking/@types/FormErrorMap/booking-cancel";
import SentryUtils from "@/src/common/utils/sentry";
import {BookingMyListOutput} from "@/src/core/app/domain/booking/@types/Output/BookingMyListOutput";
import {SsrService} from "@/src/core/app/data/services/ssr_service";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

const BookingManageUtils = {
  isRefundable: (bookingManage: T_ReservationManage) : boolean => {
    return bookingManage.total_pay_now === 0;
  },
  getDateRange: (reservationManageData: T_ReservationManage) : SearchParamsDateRange => {
    return {
      start: reservationManageData.start,
      end: reservationManageData.end,
    }
  },
  getRateAndBoardInfo: (room: T_ReservationManageRoom) : string => {
    const parts = [
      room.rate_group_description,
      room.board_description
    ];
    return parts.join(' - ');
  },
  bookingCancel: async (
    langcode: string,
    reservation: string,
    hotel: string,
    modalState: ModalState
  ) : Promise<boolean> => {
    modalState.toggleModal(ModalKey.LOADER);

    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    const response = await api.bookingCancel(langcode,
      {
        reservation,
        hotel
      },
      CMS_AuthUtils.getAuthHeaders()
    );

    if (!response.isOk) {
      const networkError = response.error as NetworkResponseError;
      const notification = NetworkNotificationUtils.getNetworkErrorNotification({
        networkError,
        map: BookingCancelErrors,
        logFn: (reason) => NetworkNotificationUtils.logNetworkError('Booking cancel error', reason, networkError),
      });
      modalState.toggleModal(ModalKey.NOTIFICATION, notification);
      return false;
    }

    modalState.closeModal();
    return true;
  },
  myBookings: async (appInfo: AppInfo): Promise<T_ReservationManage[]> => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    if (authHeaders === null) {
      throw new Error('You cannot retrieve your bookings if you are not logged in');
    }

    const ssrApi = locator.get<SsrService>(TYPES.SsrService);
    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    const response = await api.bookingMyList(appInfo.langcode, authHeaders);

    if (!response.isOk) {
      SentryUtils.logNetworkError('Booking - My list - Failed - CMS request', response.error as NetworkResponseError);
      return [];
    }

    const items = [];

    for (const ref of response.data as BookingMyListOutput) {
      const ssrResponse = await ssrApi.bookingManage({
        langcode: appInfo.langcode,
        reservation: ref.id,
        hotel: ref.hotel,
      }, authHeaders)
      if (!ssrResponse.isOk) {
        SentryUtils.logNetworkError('Booking - My list - Failed - SSR request', ssrResponse.error as NetworkResponseError);
        return [];
      }
      items.push(ssrResponse.data as T_ReservationManage);
    }

    return items;
  },
};

export default BookingManageUtils;
