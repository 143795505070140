import {CMS_FieldLink} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldLink";
import {CMS_FieldTextTextarea} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextTextarea";
import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

export enum T_ParagraphBannerSliderSlide_FooterFontSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export interface T_ParagraphBannerSliderSlide extends CMS_ParagraphEntity {
  field_title_with_tag?: CMS_FieldTextWithTag
  field_body: CMS_FieldTextTextarea
  field_one_link?: CMS_FieldLink
  field_footer?: string
  field_footer_font_size?: T_ParagraphBannerSliderSlide_FooterFontSize
}