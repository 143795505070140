import {T_HotelSectionType} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionType";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {Route, RouteType} from "@/src/core/app/domain/@types/Route";
import {T_ParagraphInfoBlock} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoBlock";
import {T_HotelSectionDataBase} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataBase";
import {CMS_ParagraphType} from "@/src/core/app/domain/cms/@types/entities/CMS_ParagraphType";

const UiHotelSectionUtils = {
  isMainSection: (hotelSection: T_HotelSection) : boolean => {
    return hotelSection.ui_hotel_section.type === T_HotelSectionType.SECTION_MAIN;
  },
  getBodyItemPropForRouteAndParagraph: (
    route: Route,
    routeData: any,
    paragraph: T_ParagraphInfoBlock
  ) : string | undefined => {
    if (route.type !== RouteType.HOTEL_SECTION) {
      return undefined;
    }

    console.log(routeData);

    const hotelSection = routeData.data as T_HotelSection;
    if (!UiHotelSectionUtils.isMainSection(hotelSection)) {
      return undefined;
    }

    const hotelSectionData = hotelSection.ui_hotel_section.data as T_HotelSectionDataBase;
    const blocks = hotelSectionData.field_blocks.filter(block => block.type === CMS_ParagraphType.INFO_BLOCK);

    if (blocks.length === 0) {
      return undefined;
    }
    if (blocks[0].id === paragraph.id) {
      return 'description';
    }
    return undefined;
  },
};

export default UiHotelSectionUtils;
