export enum CMS_OfferEntity_NightsType {
  OPEN = 'open',
  FIXED = 'fixed',
  CLOSE = 'close',
}

export interface CMS_OfferEntityWithNights {
  nights_type: CMS_OfferEntity_NightsType
  nights_count: number
  nights_min?: number
}
