import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_LoungeReference} from "@/src/core/app/domain/cms/@types/lounge/CMS_LoungeReference";

export enum CMS_ParagraphLoungesList_Display {
  "1col" = "1col",
  "2cols" = "2cols",
  "3cols" = "3cols",
  "4cols" = "4cols",
}

export interface CMS_ParagraphLoungesList extends CMS_ParagraphEntity {
  field_background_color?: string
  field_lounges_display: CMS_ParagraphLoungesList_Display
  field_lounge_items: CMS_LoungeReference[]
}