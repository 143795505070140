import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockMultiGalleryDesktop from "@/src/ui/components/blocks/BlockMultiGallery/desktop";
import BlockMultiGalleryMobile from "@/src/ui/components/blocks/BlockMultiGallery/mobile";
import {T_ParagraphMultiGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphMultiGallery";


const BlockMultiGallery = (props: T_ParagraphMultiGallery) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockMultiGalleryMobile : BlockMultiGalleryDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockMultiGallery;
