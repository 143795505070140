import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import cn from "classnames";
import {useTranslation} from "next-i18next";

interface _Props {
  className?: string
  itemClassName?: string
  iconColor?: 'white' | 'gray' | 'brand-2'
  surfaceIcon?: 'width' | 'surface-circle'
  room: T_HotelRoomBasic
}

const HotelRoomFeaturesSummary = (props: _Props) => {
  const { t } = useTranslation();
  const room = props.room;
  const iconColor = props.iconColor || 'gray';
  const surfaceIcon = props.surfaceIcon || 'width';
  const surface = HotelRoomUtils.getSurface(room);
  return (
    <ul className={cn("flex", props.className)}>
      <li className={cn("flex", props.itemClassName)}>
        <img
          className="h-4.5 w-4.5 object-contain"
          src={`/assets/icons/person-${iconColor}.svg`}
          alt=""
        />
        <span className="sr-only">{t('HotelRoomFeaturesSummary._Index.capacity_label')}:</span>
        <span>{HotelRoomUtils.getCapacity(room)}</span>
      </li>
      {room.field_room_beds && (
        <li className="flex space-x-3">
          <img
            className="h-4.5 w-4.5 object-contain"
            src={`/assets/icons/bed-${iconColor}.svg`}
            alt=""
          />
          <span className="sr-only">{t('HotelRoomFeaturesSummary._Index.rooms_label')}</span>
          <span>{room.field_room_beds}</span>
        </li>
      )}
      {surface && (
        <li className="flex space-x-3">
          <img
            className="h-4.5 w-4.5 object-contain"
            src={`/assets/icons/${surfaceIcon}-${iconColor}.svg`}
            alt=""
          />
          <span className="sr-only">
            {t('HotelRoomFeaturesSummary._Index.surface_label')}:
          </span>
          <span className="whitespace-nowrap" dangerouslySetInnerHTML={{__html: surface}} />
        </li>
      )}
    </ul>
  );
}

export default HotelRoomFeaturesSummary;