import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import _Dots from "@/src/ui/components/blocks/BlockMapInfoSlider/dots";

interface _Props {
  scrollPrev: VoidFunction
  scrollNext: VoidFunction
  scrollTo: (arg0 : number) => void
  scrollSnaps: number[]
  selectedIndex: number
}

const _Controller = (props: _Props): JSX.Element => (
  <MaxWidthWrapper>
    <div className="flex items-center justify-between">
      <button
        className="embla__prev"
        aria-label="previous image"
        onClick={props.scrollPrev}
      >
        <img src="/assets/icons/arrow-circle.svg" alt="" />
      </button>
      <_Dots
        scrollSnaps={props.scrollSnaps}
        selectedIndex={props.selectedIndex}
        scrollTo={props.scrollTo}
      />
      <button
        className="embla__next"
        aria-label="embla__button-left next image"
        onClick={props.scrollNext}
      >
        <img
          className="transform rotate-180"
          src="/assets/icons/arrow-circle.svg"
          alt=""
        />
      </button>
    </div>
  </MaxWidthWrapper>
);

export default _Controller;
