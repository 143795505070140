import {T_ParagraphElbaFidelity} from "@/src/core/app/domain/models/paragraph/T_ParagraphElbaFidelity";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import UiMediaUtils from "@/src/ui/utils/media";
import {T_ElbaFidelity} from "@/src/core/app/domain/models/elba_fidelity/T_ElbaFidelity";

interface _ItemProps {
  item: T_ElbaFidelity
}

const _Item = (props: _ItemProps) => {
  const imgProps = UiMediaUtils.getImgPropsFromMediaImage(props.item.field_fidelity_media);
  return (
    <div className="flex space-x-5">
      <div className='w-15 h-auto'>
        <img {...imgProps} />
      </div>
      <div className="flex-1 py-2.5">
        <h3 className="font-bold text-sm">{props.item.title}</h3>
        {props.item.field_fidelity_subtitle && (
          <WrapperDiv size="small" dangerouslySetInnerHTML={{__html: props.item.field_fidelity_subtitle.processed}} />
        )}
      </div>
    </div>
  );
}

const BlockElbaFidelityDesktopRows = (props: T_ParagraphElbaFidelity): JSX.Element => {
  const articleStyle: any = {};

  if (props.field_background_color) {
    articleStyle['backgroundColor'] = `#${props.field_background_color}`;
  }

  return (
    <>
      <img
        src="/assets/images/generic/elba-fidelity-logo.svg"
        alt="Logotipo de Elba Fidelity"
        className="w-65 h-auto mx-auto"
      />
      <WrapperDiv
        className="pt-1 pb-7.5 text-center text-sm text-brand-2-90"
        dangerouslySetInnerHTML={{__html: props.field_body.processed}}
      />
      <article
        className="bg-gray-20 p-5 space-y-5 text-center text-brand-2-90"
        style={articleStyle}
      >
        <div className="space-y-1 text-xs">
          <h2 className="title-4 text-brand-2 font-calistoga">
            {props.field_title_items}
          </h2>
          <WrapperDiv dangerouslySetInnerHTML={{__html: props.field_body_items?.processed}} />
        </div>
        <ul className="space-y-5 text-left">
          {props.fidelity_items.map((item, index) => (
            <li key={index}>
              <_Item item={item} />
            </li>
          ))}
        </ul>
      </article>
    </>
  );
}

export default BlockElbaFidelityDesktopRows;
