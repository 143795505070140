import {T_HotelSectionHotelCommonInfo} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";
import {useCarouselBasic} from "@/src/ui/hooks/useCarouselBasic";
import {T_HotelSectionDataMain} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataMain";
import _HeroCarouselMedia from "@/src/ui/components/routes/Route_HotelSection/desktop/_HeroCarouselMedia";

interface _Props {
  hotel: T_HotelSectionHotelCommonInfo
  sectionMainData: T_HotelSectionDataMain
}

const _Hero = (props: _Props): JSX.Element => {
  const {
    emblaRef,
    scrollPrev,
    scrollNext,
  } = useCarouselBasic(props.hotel);

  return (
    <div className="relative">
      <div className="absolute inset-0 max-w-screen-xl mx-auto p-5 flex items-center justify-between">
        <button
          className="embla__prev relative z-10 transition-all transform hover:scale-110"
          onClick={scrollPrev}
        >
          <img src="/assets/icons/arrow-left-circle-transparent.svg" alt="" />
        </button>
        <button
          className="embla__next relative z-10 transition-all transform hover:scale-110"
          onClick={scrollNext}
        >
          <img src="/assets/icons/arrow-right-circle-transparent.svg" alt="" />
        </button>
      </div>
      <div className="embla h-140 overflow-hidden" ref={emblaRef}>
        <div className="embla__container flex h-full">
          {props.sectionMainData.field_hotel_slider.map((media) => (
            <_HeroCarouselMedia key={media.id} media={media} />
          ))}
        </div>
      </div>
      {/*<button className="absolute bottom-8 left-1/2 transform -translate-x-1/2 mx-auto bg-brand-2-60 flex items-center rounded-full font-bold text-white text-sm px-7.5 py-2 space-x-2">*/}
      {/*  <img className="h-4 w-auto flex-none" src="/assets/icons/picture.svg" alt="" />*/}
      {/*  <span>{'text'}</span>*/}
      {/*</button>*/}
    </div>
  );
}

export default _Hero;
