import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {SearchFormCalendarWidget} from "@/src/ui/components/search-form/SearchFormCalendarWidget";


interface SearchFormCalendarProps {
  onSubmit: (range: SearchParamsDateRange) => void
}

const SearchFormCalendar = (props: SearchFormCalendarProps): JSX.Element => {
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();
  const searchForm = useSearchFormProvider(state => state);

  const onSubmit = (range: SearchParamsDateRange) => {
    searchForm.setDateRange(range);
    props.onSubmit(range);
  };

  return(
    <SearchFormCalendarWidget
      {...props}
      device={routeProps.device}
      onSubmit={onSubmit}
      startDate={searchForm.dateRange.start}
      endDate={searchForm.dateRange.end}
    />
  );
}

export default SearchFormCalendar;
