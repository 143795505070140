import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {NetworkErrorNotificationMap} from "@/src/core/app/domain/network/NetworkErrorNotificationMap";

const BookingCancelErrors : NetworkErrorNotificationMap = {
  'input-validation-failed': {
    type: T_NotificationType.WARNING,
    title: 'global.generic_notification.error_title',
    message: 'booking_cancel.input_validation_failed.error_message',
  },
}

export default BookingCancelErrors;
