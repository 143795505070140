import _Label from "@/src/ui/components/offer/OfferTeaser/header/label";

interface _Props {
  value?: string
}

const _HeaderNoPrice = (props: _Props) => {
  if (!props.value) {
    return null;
  }
  return (
    <>
      <div className="absolute inset-0 bg-img-blue-shade" />
      <div className="absolute top-4 right-5">
        <_Label content={props.value} />
      </div>
    </>
  );
}

export default _HeaderNoPrice;