// import Radio from "../../generic/Radio";

import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

interface _Props {
  rateGroup: CheckoutRatesHotelRoomRateGroup
}

const CheckoutRateGroupFooterDesktop = (props: _Props): JSX.Element => (
  <article className="bg-gray-10 border border-gray-40 divide-y divide-gray-40">
    {/*<form className="py-3 px-5 space-y-3.5">*/}
    {/*  <h4 className="text-small-bold">Elige tu cama (según disponibilidad)</h4>*/}
    {/*  <div className="flex items-center space-x-10">*/}
    {/*    <Radio name="bed-sizes" id="1-bed-king-size" checked={true}>*/}
    {/*      1 cama King size (2x2m)*/}
    {/*    </Radio>*/}
    {/*    <Radio name="bed-sizes" id="2-beds">*/}
    {/*      2 camas*/}
    {/*    </Radio>*/}
    {/*  </div>*/}
    {/*</form>*/}
    <aside className="bg-yellow-15 py-3.5 px-5 space-y-1 text-xs text-brand-2">
      <h4 className="text-small-bold">{props.rateGroup.title}</h4>
      <WrapperDiv
        size='small'
        className='Dingus-Formatted'
        dangerouslySetInnerHTML={{__html: props.rateGroup.configuration?.description || null}}
      />
    </aside>
  </article>
);

export default CheckoutRateGroupFooterDesktop;
