import {T_ParagraphHotelOffersSearchForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelOffersSearchForm";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import Anchor from "@/src/ui/components/generic/Anchor";
import Button from "@/src/ui/components/generic/Button";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import cn from "classnames";
import {useDevice} from "@/src/ui/hooks/useDevice";
import _Link from "@/src/ui/components/blocks/BlockHotelOffersSearchForm/link";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";



const BlockHotelOffersSearchForm = (props: T_ParagraphHotelOffersSearchForm) => {
  const { t } = useTranslation();
  const device = useDevice();
  const modalState = useModalProvider(state => state);

  return (
    <Section paddingY={"py-7.5"} backgroundColor={props.field_background_color}>
      <MaxWidthWrapper>
        <div className="space-y-5 text-brand-2 text-center">
          <TitleWithTag
            className="title-3"
            field={props.field_title_with_tag}
          />
          {props.field_subtitle && (
            <p className="text-sm">{props.field_subtitle}</p>
          )}
          <div>
            <div
              className={cn(
                device.isDesktop && "flex justify-center space-x-5",
                device.isMobile && 'space-y-5'
              )}
            >
              <_Link
                title={t("BlockHotelOffersSearchForm._Index.by_hotel_label")}
                onClick={() => modalState.toggleModal(ModalKey.OFFER_FORM_BY_HOTEL)}
              />
              <_Link
                title={t("BlockHotelOffersSearchForm._Index.by_destination_label")}
                onClick={() => modalState.toggleModal(ModalKey.OFFER_FORM_BY_DESTINATION)}
              />
              <_Link
                title={t("BlockHotelOffersSearchForm._Index.by_offer_group_label")}
                onClick={() => modalState.toggleModal(ModalKey.OFFER_FORM_BY_OFFER_GROUP, {
                  links: props.cpt_ui_offer_group_links,
                })}
              />
              <_Link
                title={t("BlockHotelOffersSearchForm._Index.by_experience_label")}
                onClick={() => modalState.toggleModal(ModalKey.OFFER_FORM_BY_EXPERIENCE, {
                  links: props.cpt_ui_experience_links,
                })}
              />
            </div>
          </div>
          <div className="relative">
            <div className="absolute h-1px bg-brand-3-60 top-0 bottom-0 inset-x-0 my-auto" />
            <Paragraph
              size="medium"
              className="relative w-20 mx-auto text-center bg-white"
            >
              ó
            </Paragraph>
          </div>
          {props.field_link.length > 0 && (
            <div className="flex justify-center pb-7.5 space-x-5">
              {props.field_link.map((item, index) => (
                <Anchor href={item.url} key={index}>
                  <Button text={item.title} style="secondary" bold />
                </Anchor>
              ))}
            </div>
          )}
        </div>
      </MaxWidthWrapper>
    </Section>
  );
}

export default BlockHotelOffersSearchForm;
