import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormUtils from "@/src/ui/utils/form";
import FormInputSelect from "src/ui/components/formik/FormInputSelect";
import CountryUtils from "@/src/core/app/utils/country";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";

const _CustomerInfoMobile = () => {
  const { t } = useTranslation();
  const routeData = useRouteData<T_CheckoutHotel>();
  return (
    <div className='px-5'>
      <TitledBox title={t('CheckoutCustomerInfoForm._CustomerInfo.title')}>
        <div className="px-5 py-4 space-y-3.5">
          <FormInputText
            name='firstName'
            label={t('CheckoutCustomerInfoForm._CustomerInfo.first_name_label') + ' *'}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            name='lastName'
            label={t('CheckoutCustomerInfoForm._CustomerInfo.last_name_label') + ' *'}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            name='email'
            label={t('CheckoutCustomerInfoForm._CustomerInfo.email_label') + ' *'}
            type='email'
            validations={[FormUtils.validateIsRequired, FormUtils.validateIsEmail]}
          />
          <FormInputText
            name='phoneNumber'
            label={t('CheckoutCustomerInfoForm._CustomerInfo.phone_number_label') + ' *'}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputSelect
            name='country'
            label={t('CheckoutCustomerInfoForm._CustomerInfo.country_label') + ' *'}
            emptyOption={'- ' + t('CheckoutCustomerInfoForm._CustomerInfo.country_empty_option')}
            options={CountryUtils.getSelectOptions(routeData.data.countries)}
            validate={FormUtils.validateIsRequired}
          />
        </div>
      </TitledBox>
    </div>
  );
}

export default _CustomerInfoMobile;