
const ColorUtils = {
  convertHexToRGB: (hex: string) => {
    // @ts-ignore
    const rgb='0x' + hex.slice(1).replace(hex.length > 4 && /./g,'$&$&');
    // @ts-ignore
    return [rgb>>16, rgb >>8 & 255, rgb & 255]
  },
  isLight: (hex: string, level: number = 128) : boolean => {
    const [r, g, b] = ColorUtils.convertHexToRGB(hex);
    return (((r * 299) + (g * 587) + (b * 114)) / 1000) >= level;
  },
}

export default ColorUtils;
