import {T_ParagraphMultiGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphMultiGallery";
import SectionGray from "@/src/ui/components/generic/SectionGray";
import Card from "@/src/ui/components/generic/Card";
import Title4 from "@/src/ui/components/generic/Title4";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import UiGalleryUtils from "@/src/ui/utils/gallery";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";

const BlockMultiGalleryDesktop = (props: T_ParagraphMultiGallery) => {
  const modalState = useModalProvider(state => state);
  const rows = UiGalleryUtils.getMultiGalleryDesktopRows(props);
  return (
    <SectionGray>
      <div
        className='mx-auto'
        style={{
          width: "88rem",
          height: "58.25rem",
        }}
      >
        <div
          className='h-113'
          style={{
            display: "grid",
            position: "relative",
            gridTemplateColumns: "28.5rem 28.5rem 28.5rem",
            gridTemplateRows: Array.from({length: rows}, (v, k) => '28.5rem').join(' '),
            gap: '1.25rem',
          }}>
          {props.field_multi_gallery_items.map((multiGalleryItem, index) => (
            <div
              key={multiGalleryItem.id}
              className='cursor-pointer'
              onClick={() => {
                modalState.toggleModal(ModalKey.MULTI_GALLERY, {
                  multiGallery: props,
                  index,
                })
              }}
              style={{
                position: "absolute",
                height: `28.5rem`,
                width: `${UiGalleryUtils.getMultiGalleryItemDesktopWidth(multiGalleryItem, 28.5, 1.25)}rem`,
                gridColumn: `${multiGalleryItem.field_mgi_column} / auto`,
                gridRow: `${multiGalleryItem.field_mgi_row} / auto`,
              }}
            >
              <Card mediaImage={multiGalleryItem.ui_cpt_field_media_teaser}>
                <img
                  className="hover-show-child w-4 h-auto"
                  src='/assets/icons/picture.svg'
                  alt=""
                />
                <div className="hover-show-child py-2.5">
                  <Title4 className='font-calistoga'>
                    {multiGalleryItem.field_title}
                  </Title4>
                </div>
                <Paragraph className="hover-show-child" size="medium">
                  ({multiGalleryItem.ui_cpt_field_medias_gallery.length} fotos)
                </Paragraph>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </SectionGray>
  );
}

export default BlockMultiGalleryDesktop;
