import {T_ParagraphBlogList} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogList";
import NodeBlogTeaserListMobile from "@/src/ui/components/blog/NodeBlogTeaserList/mobile";
import BlogCategoryListMobile from "@/src/ui/components/blog/BlogCategoryList/mobile";
import React from "react";
import PaginationWrapper from "@/src/ui/components/generic/PaginationWrapper";

const BlockBlogListMobile = (props: T_ParagraphBlogList) => (
  <div>
    <BlogCategoryListMobile
      categories={props.categories}
      currentTaxonomy={null}
      paddingX='px-0'
      paddingY='py-5'
    />
    <NodeBlogTeaserListMobile
      titleWithTag={props.field_title_with_tag}
      items={props.blogPosts}
    />
    <PaginationWrapper
      className="flex justify-center"
      pager={props.pager}
    />
  </div>
);

export default BlockBlogListMobile;
