import {OfferSearchProps} from "@/src/ui/components/offer/OfferSearch/types";
import {useDevice} from "@/src/ui/hooks/useDevice";
import OfferSearchMobile from "@/src/ui/components/offer/OfferSearch/mobile";
import OfferSearchDesktop from "@/src/ui/components/offer/OfferSearch/desktop";

const OfferSearch = (props: OfferSearchProps) => {
  const device = useDevice();
  const _Component = device.isMobile ? OfferSearchMobile : OfferSearchDesktop;
  return (
    <_Component {...props} />
  );
};

export default OfferSearch;