import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {_Popup} from "@/src/ui/components/portals/desktop/ModalSearchFormStepOccupation/popup";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import {useTranslation} from "next-i18next";
import {ModalSearchFormStepOccupationDesktopData} from "@/src/ui/@types/modal/ModalSearchFormStepOccupationDesktopData";
import SearchFormUtils from "@/src/core/app/utils/search-form";

const ModalSearchFormStepOccupationDesktop = () : JSX.Element | null => {
  const { t } = useTranslation();
  const searchForm = useSearchFormProvider(state => state);
  const modalState = useModalProvider(state => state);

  const modalStateData = modalState.modalData as ModalSearchFormStepOccupationDesktopData | null;

  const closeModal = () => modalState.closeModal();

  const onSubmit = (rooms: SearchParamsOccupationRoom[]) => {
    searchForm.setRooms(rooms);
    closeModal();

    if (modalStateData) {
      SearchFormUtils.submit(searchForm, modalState, modalStateData.roomCode);
    }
  };

  return (
    <ModalLayout
      title={t('ModalSearchFormStepOccupation._Index.title')}
      className="w-115"
      onCloseModal={closeModal}
    >
      <useSearchFormOccupationProvider.State>
        <_Popup
          onSubmit={onSubmit}
        />
      </useSearchFormOccupationProvider.State>
    </ModalLayout>
  );
}

export default ModalSearchFormStepOccupationDesktop;
