import {useSearchFormMobileProvider} from "@/src/ui/providers/search-form-mobile-provider";
import {SearchFormMobileStep} from "@/src/ui/view_models/search-form-mobile.state";
import SearchFormCalendar from "src/ui/components/search-form/SearchFormCalendar";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import {useModalProvider} from "@/src/ui/providers/modal.provider";

const ModalSearchFormStepDatesMobile = (): JSX.Element | null => {
  const modalState = useModalProvider(state => state);
  const state = useSearchFormMobileProvider(state => state);

  if (state.currentStep !== SearchFormMobileStep.DATES) {
    return null;
  }

  const onSubmit = (range: SearchParamsDateRange) => {
    if (SearchParamsUtils.isDateRangeEmpty(range)) {
      SearchFormUtils.onClear(modalState);
      return;
    }
    state.setCurrentStep(SearchFormMobileStep.FORM);
  };

  return (
    <SearchFormCalendar
      onSubmit={onSubmit}
    />
  );
}

export default ModalSearchFormStepDatesMobile;
