import {T_ParagraphBlogSection} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogSection";
import BlogCategoryListDesktop from "@/src/ui/components/blog/BlogCategoryList/desktop";

const BlockBlogSectionDesktop = (props: T_ParagraphBlogSection) => (
  <BlogCategoryListDesktop
    titleWithTag={props.field_title_with_tag}
    categories={props.categories}
  />
);

export default BlockBlogSectionDesktop;