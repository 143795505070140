import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import {CheckoutRates} from "@/src/core/app/domain/models/checkout/CheckoutRates";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import CheckoutRoomListDesktop from "@/src/ui/components/checkout/desktop/CheckoutRoomList";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {useTranslation} from "next-i18next";

interface _Props extends T_ParagraphHotelRoomList {
  rooms: T_HotelRoomTeaser[]
  rates: CheckoutRates
}

const _RoomsStatusOk = (props: _Props) => {
  const { t } = useTranslation();
  const checkout = useCheckoutProvider(state => state);

  return (
    <Section paddingY="py-15">
      <MaxWidthWrapper>
        <div className="flex justify-between items-end pb-10">
          <TitleWithTag
            field={props.field_title_with_tag}
            className="title-4 font-calistoga"
          />
          <div className="flex space-x-7.5 text-sm text-brand-2">
            {/*<div className="flex items-center space-x-2.5">*/}
            {/*    <Toggle />*/}
            {/*    <p>Soy residente {regionName}</p>*/}
            {/*    <img*/}
            {/*      className="h-3.5 w-auto hover:opacity-90"*/}
            {/*      src="/assets/icons/help-brand-2.svg"*/}
            {/*      alt=""*/}
            {/*    />*/}
            {/*  </div>*/}
            <span className="underline cursor-pointer" onClick={() => checkout.setStepCalendar()}>
              {t('BlockHotelRoomListDesktop._RoomsStatusOk.see_calendar_prices_label')}
            </span>
          </div>
        </div>
        <CheckoutRoomListDesktop
          rooms={props.rooms}
          rates={props.rates}
        />
      </MaxWidthWrapper>
    </Section>
  );
}

export default _RoomsStatusOk;
