import cn from "classnames";
import LocationTag from "@/src/ui/components/generic/LocationTag";
import Button from "@/src/ui/components/generic/Button";
import Anchor from "@/src/ui/components/generic/Anchor";
import {T_OfferTeaser} from "@/src/core/app/domain/models/offer/T_OfferTeaser";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import _Body from "@/src/ui/components/offer/OfferTeaser/body";
import _Header from "@/src/ui/components/offer/OfferTeaser/header";
import {useTranslation} from "next-i18next";
import {OfferTeaserStyle} from "@/src/ui/@types/offer/OfferTeaserStyle";
import UiOfferUtils from "@/src/ui/utils/offer";

interface _Props {
  item: T_OfferTeaser
  style?: OfferTeaserStyle
}

const OfferTeaser = (props: _Props) => {
  const { t } = useTranslation();
  const {
    item,
    style = OfferTeaserStyle.GRID,
  } = props;
  const size = 'small';
  return (
    <article
      className="flex flex-col h-full bg-gray-10 border border-brand-2-60"
      itemScope
      itemType="https://schema.org/Event"
    >
      <Anchor href={UiOfferUtils.getUrl(item)} itemProp='url'>
        <div
          className={cn(
            "relative w-full flex-none",
            // size === "regular" && "h-55",
            size === "small" && "h-45"
          )}
        >
          <MediaImageBgImg
            mediaImage={item.cpt_ui_field_offer_teaser}
            alt={item.title}
            title={item.title}
            itemProp="image"
          />
          <_Header item={item}/>
        </div>
        <div className="flex-1 pt-2.5 pb-5 px-3.75">
          <div className="text-brand-2">
            <p
              className={cn(
              "text-big pb-1",
              style === OfferTeaserStyle.CARD && 'font-calistoga',
              )}
              itemProp='name'
            >
              {item.title}
            </p>
            <div itemProp="location" itemScope itemType="https://schema.org/Place">
              <meta itemProp="name" content={item.cpt_ui_hotel_name}/>
              <meta itemProp="url" content={UiOfferUtils.getUrl(item)}/>
              <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                <p
                  className="text-medium-bold pb-2.5"
                  itemProp="addressLocality"
                >{item.cpt_ui_hotel_name}</p>
                <LocationTag itemProp='addressRegion'>{item.cpt_ui_hotel_location}</LocationTag>
              </div>
            </div>
            <meta itemProp="description" content={item.field_subtitle}/>
          </div>
          <_Body item={item}/>
          {style === OfferTeaserStyle.GRID && (
            <div className="pt-5 pr-2">
              <Button text={t('OfferTeaser._Index.button_label')} style="secondary" bold/>
            </div>
          )}
        </div>
      </Anchor>
    </article>
  );
}

export default OfferTeaser;
