import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodeJobOfferDetail} from "@/src/core/app/domain/models/node/T_NodeJobOfferDetail";
import _Details from "@/src/ui/components/routes/Route_JobOfferDetail/generic/_Details";
import _Header from "@/src/ui/components/routes/Route_JobOfferDetail/generic/_Header";
import Section2Cols from "@/src/ui/components/generic/Section2Cols";
import JobOfferForm from "src/ui/components/forms/JobOfferForm";

const Route_JobOfferDetailDesktop = (props: RouteProps<T_NodeJobOfferDetail>) : JSX.Element => {
  const jobOffer = props.routeData.data;
  return (
    <Section2Cols
      content={(
        <div className="space-y-7.5">
          <h2 className="title-4 font-calistoga">
            {jobOffer.title}
          </h2>
          <_Header {...jobOffer} />
          <_Details {...jobOffer} />
        </div>
      )}
      sidebar={(
        <JobOfferForm jobOffer={jobOffer}/>
      )}
    />
  );
}

export default Route_JobOfferDetailDesktop;
