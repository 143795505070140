import {T_ParagraphBlogList} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogList";
import BlockBlogListDesktop from "@/src/ui/components/blocks/BlockBlogList/desktop";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockBlogListMobile from "@/src/ui/components/blocks/BlockBlogList/mobile";

const BlockBlogList = (props: T_ParagraphBlogList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockBlogListMobile : BlockBlogListDesktop;
  return (
    <_Component {...props} />
  );
};

export default BlockBlogList;
