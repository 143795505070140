import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {T_ParagraphMultiGalleryItem} from "@/src/core/app/domain/models/paragraph/T_ParagraphMultiGalleryItem";
import SectionGray from "@/src/ui/components/generic/SectionGray";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import {useCarouselBasic} from "@/src/ui/hooks/useCarouselBasic";
import {useTranslation} from "next-i18next";

interface _Props {
  multiGalleryItem: T_ParagraphMultiGalleryItem
}

const _Gallery = (props: _Props) => {
  const { t } = useTranslation();
  const {
    emblaRef,
    selectedIndex,
    scrollPrev,
    scrollNext,
  } = useCarouselBasic(props.multiGalleryItem);

  return (
    <div>
      <SectionGray className="flex-1 overflow-y-auto">
        <MaxWidthWrapper>
          <div className="relative">
            <div className="absolute inset-0 max-w-screen-xl mx-auto p-5 flex items-center justify-between">
              <button
                className="relative z-10 transition-all transform hover:scale-110"
                onClick={scrollPrev}
              >
                <img src="/assets/icons/arrow-left-circle-transparent.svg" alt="" />
              </button>
              <button
                className="relative z-10 transition-all transform hover:scale-110"
                onClick={scrollNext}
              >
                <img src="/assets/icons/arrow-right-circle-transparent.svg" alt="" />
              </button>
            </div>
            <div className="pt-2.5 pb-5">
              <div className="h-170 hotel-pictures-carousel overflow-hidden embla" ref={emblaRef}>
                <div className="embla__container flex h-full">
                  {props.multiGalleryItem.ui_cpt_field_medias_gallery.map((img) => (
                    <div
                      key={img.id}
                      className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
                      style={{ flexBasis: "100%" }}
                    >
                      <MediaImageBgImg  mediaImage={img} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <p className="text-center text-sm text-brand-2-90">
            {t('ModalMultiGallery._Gallery.footer',{
              count: selectedIndex + 1,
              total: props.multiGalleryItem.ui_cpt_field_medias_gallery.length,
            })}
          </p>
        </MaxWidthWrapper>
      </SectionGray>
    </div>
  );
}

export default _Gallery;
