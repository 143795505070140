import getBlockComponent from "@/src/ui/components/blocks";
import {T_Paragraph} from "@/src/core/app/domain/models/paragraph/T_Paragraph";
import SentryUtils from "@/src/common/utils/sentry";

interface BlockListComponentProps {
  blocks: T_Paragraph[]
}

export default function BlockListComponent(props: BlockListComponentProps) : JSX.Element {
  return (
    <>
      {props.blocks.map((block: T_Paragraph, index: number) => {
        if (block === null) {
          SentryUtils.captureMessage('BlockListComponent: block is null');
          return null;
        }
        const _Component = getBlockComponent(block.type);
        if (_Component === null) {
          SentryUtils.captureMessage(`BlockListComponent: getBlockComponent returned null: ${block.type}`);
          return null;
        }

        return (
          <_Component key={index} {...block} />
        );
      })}
    </>
  )
}
