import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {T_NodeResidentHome} from "@/src/core/app/domain/models/node/T_NodeResidentHome";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

export enum T_ParagraphResidentsList__Display {
  COLUMN_IMAGE_TITLE = "column_image_title",
  ROWS_DESCRIPTION = "rows_description",
}

export interface T_ParagraphResidentsList extends CMS_ParagraphEntity {
  field_title_with_tag?: CMS_FieldTextWithTag
  field_background_color: string
  field_residents_display: T_ParagraphResidentsList__Display
  residents: T_NodeResidentHome[]
}