import {T_NodeJobOfferTeaser} from "@/src/core/app/domain/models/node/T_NodeJobOfferTeaser";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import MediaImage from "@/src/ui/components/generic/MediaImage";
import Anchor from "@/src/ui/components/generic/Anchor";
import JobOfferUtils from "@/src/core/app/utils/job-offer";
import {useTranslation} from "next-i18next";

interface _Props {
  item: T_NodeJobOfferTeaser
}

const _JobOfferTeaser = (props: _Props) => {
  const { t } = useTranslation();
  const item = props.item;
  return (
    <div className="flex items-stretch p-2.5 pr-5 space-x-2.5 sm:space-x-5 border border-gray-40">
      <div className="flex-none pt-1">
        <MediaImage
          className="w-20 h-auto my-auto"
          mediaImage={item.field_job_offer_teaser}
        />
      </div>
      <div className="flex-1 sm:flex space-y-2.5 sm:space-y-0 sm:space-x-5 items-center">
        <div className="flex-1 text-xs text-brand-2-90">
          <h3 className="text-medium-bold">{item.title}</h3>
          <p className="pb-1 font-bold">{item.cpt_hotel_title}</p>
          {item.field_teaser && (
              <WrapperDiv
                  className="mb-2 Cms-Formatted"
                  dangerouslySetInnerHTML={{__html: item.field_teaser.processed}}
              />
          )}
          <p>
            {item.field_job_offer_salary || t('BlockJobOfferInfo._Teaser.no_salary_label')}
            {` | `}
            {t('BlockJobOfferInfo._Teaser.experience_label')}: {item.field_job_offer_experience}
            {` | `}
            {t('BlockJobOfferInfo._Teaser.schedule_label')}: {t(JobOfferUtils.getScheduleLabel(item.field_job_offer_schedule) as any)}
          </p>
        </div>
        <Anchor
          path={item.path}
          className="flex-none flex items-center space-x-2.5 text-xs sm:text-sm font-bold text-brand-2 hover:underline"
        >
          <span>{t('BlockJobOfferInfo._Teaser.link_label')}</span>
          <img
            className="h-4 w-auto"
            src="/assets/icons/chevron-right-brand-2.svg"
            alt=""
          />
        </Anchor>
      </div>
    </div>
  );
}
export default _JobOfferTeaser;
