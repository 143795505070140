import {T_ParagraphDestinationByResident} from "@/src/core/app/domain/models/paragraph/T_ParagraphDestinationByResident";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import DestinationTeaserDesktop from "@/src/ui/components/destination/DestinationTeaser/desktop";
import {useDevice} from "@/src/ui/hooks/useDevice";
import DestinationTeaserMobile from "@/src/ui/components/destination/DestinationTeaser/mobile";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";


const BlockDestinationListByResident = (props: T_ParagraphDestinationByResident) => {
  const device = useDevice();
  const _Component = device.isMobile ? DestinationTeaserMobile : DestinationTeaserDesktop;

  if (props.destinationTeasers.length === 0) {
    return null;
  }

  return (
    <Section paddingY="pt-7.5 pb-15" backgroundColor={props.field_background_color} >
      <MaxWidthWrapper>
        {props.field_title_with_tag && (
          <TitleWithTag
            className="title-4 pb-7.5 font-calistoga"
            field={props.field_title_with_tag}
            suffix={`(${props.destinationTeasers.length})`}
          />
        )}
        <ul className="space-y-5">
          {props.destinationTeasers.map((item) => (
            <li key={item.id}>
              <_Component {...item} />
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  )
}
export default BlockDestinationListByResident;
