import {T_NodeJobOfferDetail} from "@/src/core/app/domain/models/node/T_NodeJobOfferDetail";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {useTranslation} from "next-i18next";

const _Details = (props: T_NodeJobOfferDetail) => {
  const { t } = useTranslation();
  return (
    <TitledBox title={t('Route_JobOfferDetail._Details.title')}>
      <div className="p-3.75 sm:px-5 space-y-3.75 text-xs text-brand-2-90">
        {props.field_body && (
          <div className="space-y-1.5">
            <h4 className="text-medium-bold">{t('Route_JobOfferDetail._Details.body_label')}:</h4>
            <WrapperDiv
              size="medium"
              className="py-2.5 text-brand-2 Cms-Formatted"
              dangerouslySetInnerHTML={{__html: props.field_body.processed}}
            />
          </div>
        )}
        {props.field_job_offer_level && (
          <div className="space-y-1.5">
            <h4 className="text-medium-bold">{t('Route_JobOfferDetail._Details.offer_level_label')}:</h4>
            <p>{props.field_job_offer_level}</p>
          </div>
        )}
        {props.field_job_offer_personnel && (
          <div className="space-y-1.5">
            <h4 className="text-medium-bold">{t('Route_JobOfferDetail._Details.offer_personnel_label')}:</h4>
            <p>{props.field_job_offer_personnel}</p>
          </div>
        )}
        <div className="space-y-1.5">
          <h4 className="text-medium-bold">{t('Route_JobOfferDetail._Details.offer_count_label')}:</h4>
          <p>{props.field_job_offer_count}</p>
        </div>

        {props.field_job_offer_requirements && (
          <div className="space-y-1.5">
            <h4 className="text-medium-bold">{t('Route_JobOfferDetail._Details.offer_requirements_label')}:</h4>
            <WrapperDiv
              size="medium"
              className="py-2.5 text-brand-2 Cms-Formatted"
              dangerouslySetInnerHTML={{__html: props.field_job_offer_requirements.processed}}
            />
          </div>
        )}
      </div>
    </TitledBox>
  );
}

export default _Details;