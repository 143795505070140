import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

interface _Props {
  titleWithTag?: CMS_FieldTextWithTag
  subtitle?: string
  description?: string
}

const TitleGroupDesktop = (props: _Props): JSX.Element | null => {
  if (!props.titleWithTag && !props.subtitle && !props.description) {
    return null;
  }

  return (
    <hgroup className="text-brand-2">
      {props.subtitle && (
        <p className="title-5">{props.subtitle}</p>
      )}
      <TitleWithTag
        className="pb-2.5 title-4 font-calistoga"
        field={props.titleWithTag}
      />
      {props.description && (
        <div className="text-sm text-brand-2-90" dangerouslySetInnerHTML={{__html: props.description}} />
      )}
    </hgroup>
  );
}

export default TitleGroupDesktop;
