import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import SearchFormCalendar from "src/ui/components/search-form/SearchFormCalendar";
import {useTranslation} from "next-i18next";
import {ModalSearchFormStepDatesDesktopData} from "@/src/ui/@types/modal/ModalSearchFormStepDatesDesktopData";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import ModalDesktopKey from "@/src/ui/@types/modal/ModalDesktopKey";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import searchForm from "@/src/core/app/utils/search-form";

const ModalSearchFormStepDatesDesktop = (): JSX.Element => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const modalStateData = modalState.modalData as ModalSearchFormStepDatesDesktopData | null;

  const onSubmit = (range: SearchParamsDateRange) => {
    modalState.closeModal();

    if (SearchParamsUtils.isDateRangeEmpty(range)) {
      SearchFormUtils.onClear(modalState);
      return;
    }

    if (modalStateData) {
      modalState.toggleModal(ModalDesktopKey.SEARCH_FORM_STEP_OCCUPATION, {
        roomCode: modalStateData.roomCode
      });
    }
  };

  return (
    <ModalLayout
      title={t('ModalSearchFormStepDates._Index.title')}
      onCloseModal={() => modalState.closeModal()}
    >
      {/*{warning && <WarningBanner paddingX="px-5" text={warning} />}*/}
      <SearchFormCalendar
        onSubmit={onSubmit}
      />
    </ModalLayout>
  );
}

export default ModalSearchFormStepDatesDesktop;
