import {BreadcrumbItem} from "@/src/core/app/domain/@types/BreadcrumbItem";
import UrlUtils from "@/src/core/app/utils/url";

interface _Props {
  className?: string
  item: BreadcrumbItem
}

const _Item = (props: _Props) => {
  const {
    item,
    className
  } = props;

  if (item.url) {
    return (
      <a
        href={UrlUtils.getCmsLinkUrl(item.url)}
        className={className}
        itemProp="item"
      >
        <span itemProp="name">{item.title}</span>
      </a>
    )
  }
  return (
    <span
      className={className}
      itemProp="item"
    >
      <span itemProp="name">{item.title}</span>
    </span>
  );
};

export default _Item;
