import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";

const rateNotFound = {
  notification: {
    type: T_NotificationType.WARNING,
    title: 'booking_step_checkout.generic_notification.error_title',
    message: 'booking_step_checkout.rate_not_found.error_message',
  }
  // @TODO - Add go to search again
};

const CheckoutStepCustomerInfoErrors : FormErrorMap = {
  'prices-have-changed': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'booking_step_checkout.generic_notification.error_title',
      message: 'booking_step_checkout.prices_have_changed.error_message',
    }
    // @TODO - Add go to search again
  },
  'cancellation-policies-have-changed': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'booking_step_checkout.generic_notification.error_title',
      message: 'booking_step_checkout.prices_have_changed.error_message',
    }
    // @TODO - Add go to search again
  },
  'customer_registration:password:error:password-invalid-for-existing-account': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'booking_step_checkout.generic_notification.error_title',
      message: 'booking_step_checkout.customer_registration.password.error.password-invalid-for-existing-account.error_message',
    },
    fields: {
      'userPassword1': 'booking_step_checkout.customer_registration.password.error.password-invalid-for-existing-account.error_message',
    },
  },
  'password:error:password-too-weak': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'booking_step_checkout.generic_notification.error_title',
      message: 'booking_step_checkout.password.error.password-too-weak.error_message',
    },
    fields: {
      'userPassword1': 'booking_step_checkout.password.error.password-too-weak.error_message',
    },
  },
  'requested-hotel-not-found-in-hotel-search-response': rateNotFound,
  'requested-room-not-found-in-hotel-search-response': rateNotFound,
  'requested-rate-not-found-in-hotel-search-response': rateNotFound,
  'requested-board-not-found-in-hotel-search-response': rateNotFound,
}

export default CheckoutStepCustomerInfoErrors;
