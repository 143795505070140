import {LayoutProps} from "@/src/ui/@types/layout/LayoutProps";
import ModalLoader from "src/ui/components/portals/generic/ModalLoader";

const LayoutIframe = (props: LayoutProps) => {
  return (
    <>
      {props.children}
      <ModalLoader />
    </>
  )
}

export default LayoutIframe;
