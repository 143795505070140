import cn from "classnames";
import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import React, {LegacyRef, useState} from "react";
import HotelRoomMediaCarousel from "@/src/ui/components/hotel-room/generic/HotelRoomMediaCarousel";
import HotelRoomHeaderHeaderInfoDesktop from "@/src/ui/components/hotel-room/desktop/HotelRoomHeaderHeaderInfo";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelRoom: T_HotelRoomTeaser
  rightTopComponent?: JSX.Element
  bottomComponent?: JSX.Element
}

const _Features = (props: _Props) => {
  const { t } = useTranslation();
  const hotelRoom = props.hotelRoom;

  if (!hotelRoom.field_room_details_rooms && !hotelRoom.field_room_details_bathroom && !hotelRoom.field_room_details_equipment) {
    return null;
  }

  return (
    <div className="flex text-xs text-brand-2-90 space-x-5">
      {hotelRoom.field_room_details_rooms && (
        <div className="flex-1 space-y-1">
          <h3 className="text-medium-bold">{t('HotelRoomTeaserCommon._Index.room_label')}</h3>
          <WrapperDiv
            size='small'
            className="space-y-1 text-xs text-brand-2-90 Cms-Formatted"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_rooms.value}}
          />
        </div>
      )}
      {hotelRoom.field_room_details_bathroom && (
        <div className="flex-1 space-y-1">
          <h3 className="text-medium-bold">{t('HotelRoomTeaserCommon._Index.bathroom_label')}</h3>
          <WrapperDiv
            size='small'
            className="space-y-1 text-xs text-brand-2-90 Cms-Formatted"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_bathroom.value}}
          />
        </div>
      )}
      {hotelRoom.field_room_details_equipment && (
        <div className="flex-1 space-y-1">
          <h3 className="text-medium-bold">{t('HotelRoomTeaserCommon._Index.equipment_label')}</h3>
          <WrapperDiv
            size='small'
            className="space-y-1 text-xs text-brand-2-90 Cms-Formatted"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_equipment.value}}
          />
        </div>
      )}
    </div>
  );
}

const HotelRoomTeaserCommonDesktop = (props: _Props) => {
  const { t } = useTranslation();
  // const [isExpanded, setExpanded] = useState(false);
  const isExpanded = false;
  const setExpanded = (value: boolean) => {};
  const modalState = useModalProvider(state => state);
  const hotelRoom = props.hotelRoom;
  const toggleRoomPopup = () => {
    modalState.toggleModal(ModalKey.HOTEL_ROOM, {
      room: hotelRoom
    })
  };

  return (
    <article className="space-y-2.5">
      <div className="flex space-x-5">
        <div className="w-95 flex-none">
          <HotelRoomMediaCarousel mediaImages={hotelRoom.field_room_media}/>
        </div>
        <div className={cn(
          "relative flex-1 overflow-hidden space-y-3.5 transition-all duration-500",
          isExpanded ? 'max-h-screen' : 'max-h-57.5'
        )}>
          <div className="flex justify-between items-center">
            <h3 className="text-big-bold">{HotelRoomUtils.getTeaserTitle(hotelRoom)}</h3>
            {props.rightTopComponent}
          </div>
          <HotelRoomHeaderHeaderInfoDesktop hotelRoom={hotelRoom}/>
          <WrapperDiv
            size='small'
            className="text-brand-2-90"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_body?.processed}}
          />
          <_Features {...props} />
          {isExpanded && (
            <button
              onClick={() => setExpanded(false)}
              className="cursor-pointer text-brand-2 text-xs underline"
            >
              {t('HotelRoomTeaserCommon._Index.hide_details_button_label')}
            </button>
          )}
          <div
            className="absolute inset-x-0 bottom-0 h-12 bg-gradient-to-b from-transparent via-white to-white flex items-end text-xs text-brand-2 underline">
            <button
              className="cursor-pointer"
              //onClick={() => setExpanded(true)}
              onClick={() => toggleRoomPopup()}
            >
              {t('HotelRoomTeaserCommon._Index.show_details_button_label')}
            </button>
          </div>
        </div>
      </div>
      {props.bottomComponent}
    </article>
  )
}

export default HotelRoomTeaserCommonDesktop;
