import {T_ParagraphHotelList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelList";
import Section from "@/src/ui/components/generic/Section/Section";
import HotelTeaserListMobile from "@/src/ui/components/hotel/HotelTeaserList/mobile";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const _List = (props: T_ParagraphHotelList) => (
  <Section paddingY="pt-5 pb-7.5">
    <TitleWithTag
      className="title-4-mobile pb-5 font-calistoga"
      field={props.field_title_with_tag}
      suffix={`(${props.hotelTeasers.length})`}
    />
    <HotelTeaserListMobile
      hotelTeasers={props.hotelTeasers}
      linkSection={props.field_hotel_list_link_section}
    />
  </Section>
);

export default _List;
