import {T_ParagraphOffersCategoryPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphOffersCategoryPromoted";
import BlockOffersCategoryPromotedDesktop from "@/src/ui/components/blocks/BlockOffersCategoryPromoted/desktop";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockOffersCategoryPromotedMobile from "@/src/ui/components/blocks/BlockOffersCategoryPromoted/mobile";

const BlockOffersCategoryPromoted = (props: T_ParagraphOffersCategoryPromoted) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockOffersCategoryPromotedMobile : BlockOffersCategoryPromotedDesktop;
  return (
    <_Component {...props} />
  );
}

export default BlockOffersCategoryPromoted;
