import {T_ParagraphBlogList} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogList";
import NodeBlogTeaserListDesktop from "@/src/ui/components/blog/NodeBlogTeaserList/desktop";
import React from "react";
import PaginationWrapper from "@/src/ui/components/generic/PaginationWrapper";

const _All = (props: T_ParagraphBlogList) => (
  <div className="flex-1 space-y-7.5">
    <NodeBlogTeaserListDesktop
      titleWithTag={props.field_title_with_tag}
      items={props.blogPosts}
    />
    <PaginationWrapper
      className="flex justify-center"
      pager={props.pager}
    />
  </div>
);

export default _All;