import {T_ParagraphIconList} from "@/src/core/app/domain/models/paragraph/T_ParagraphIconList";
import Section from "@/src/ui/components/generic/Section/Section";
import AssetCmsIcon from "@/src/ui/components/generic/AssetCmsIcon";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";

const _BlockIconListMobileInline = (props: T_ParagraphIconList) => (
  <Section>
    <MaxWidthWrapper>
      <div className="sm:py-2.5">
        {props.field_section_name && (
          <h2 className="title-5 pb-5">{props.field_section_name}</h2>
        )}
        <ul className="flex flex-wrap justify-between gap-5 text-sm text-brand-2-90">
          {props.field_icon_texts_items.map((item, index) => (
            <li key={index} className="flex space-x-3 items-center">
              <AssetCmsIcon className="w-6 h-6 object-contain" iconKey={item.icon_class} />
              <div className="w-30 sm:w-48">{item.text_1}</div>
            </li>
          ))}
        </ul>
      </div>
    </MaxWidthWrapper>
  </Section>
);


export default _BlockIconListMobileInline;
