import {PropsWithChildren} from "react";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {useTranslation} from "next-i18next";
import Anchor from "@/src/ui/components/generic/Anchor";

const _Wrapper = (props: PropsWithChildren) => {
  const { t } = useTranslation();
  return (
    <Section className='mx-auto pt-10'>
      <MaxWidthWrapper>
        <div className='flex justify-between items-center pb-7.5'>
          <h1 className='text-2xl font-calistoga'>
            {t('Route_ElbaMedia._Index.page_title')}
          </h1>
          <div>
            <Anchor href='/user/logout' className='flex space-x-0.5 items-center'>
              <img className='w-5' src="/assets/icons/system-shut.svg" alt=""/>
              <span>
                {t('global.generic.logout_label')}
              </span>
            </Anchor>
          </div>
        </div>
        <div className="space-y-7.5">
          {props.children}
        </div>
      </MaxWidthWrapper>
    </Section>
);
}

export default _Wrapper;
