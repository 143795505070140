import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodeExperienceDetail} from "@/src/core/app/domain/models/node/T_NodeExperienceDetail";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import BlockListComponent from "@/src/ui/components/generic/BlockListComponent";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_ExperienceDetail = (props: RouteProps<T_NodeExperienceDetail>) : JSX.Element => (
  <>
    <PageHeader header={props.routeData.data.page_header} />
    <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
    <BlockListComponent blocks={props.routeData.data.field_blocks} />
  </>
);

export default Route_ExperienceDetail;
