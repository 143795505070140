import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useTranslation} from "next-i18next";

const ModalOfferFormByDestination = (): JSX.Element => {
  const { t } = useTranslation();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const modalState = useModalProvider(state => state);
  const hotelTree = layoutData.hotelTree;
  const destinations = HotelTreeUtils.getDestinations(hotelTree).sort((a, b) => {
    return a.destinationName.localeCompare(b.destinationName);
  });
  const onCloseModal = () => {
    modalState.closeModal();
  };

  return (
    <ModalLayout
      title={t('ModalOfferFormByDestination._Index.title')}
      className="w-80 h-95"
      onCloseModal={onCloseModal}
    >
      <div className="flex-1 overflow-auto">
        <ul>
          {destinations.map((destination) => (
            <li key={destination.id} className="w-full flex-none">
              <Anchor
                className="block text-brand-2 text-sm px-5 py-3 hover:bg-gray-10 cursor-pointer"
                href={`${layoutData.sitePageUrls.offer_search_page_url}?destination=${destination.id}`}
              >
                {destination.destinationName}
              </Anchor>
            </li>
          ))}
        </ul>
      </div>
    </ModalLayout>
  );
}

export default ModalOfferFormByDestination;
