import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import useEmblaCarousel from "embla-carousel-react";
import {useCallback} from "react";

interface _Props {
  mediaImages: T_MediaImage[]
}

const HotelRoomMediaCarousel = ({
  mediaImages,
}: _Props): JSX.Element => {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi]);
  return (
    <div className="relative">
      <div className="absolute inset-0 max-w-screen-xl mx-auto p-2.5 sm:p-5 flex items-center justify-between">
        <button
          className="relative z-10 transition-all transform hover:scale-110"
          onClick={scrollPrev}
        >
          <img src="/assets/icons/arrow-left-circle-transparent.svg" alt="" />
        </button>
        <button
          className="relative z-10 transition-all transform hover:scale-110"
          onClick={scrollNext}
        >
          <img src="/assets/icons/arrow-right-circle-transparent.svg" alt="" />
        </button>
      </div>
      <div
        ref={emblaRef}
        className="h-57.5 overflow-hidden"
      >
        <div className="embla__container flex h-full">
          { mediaImages && mediaImages.map((mediaImage) => (
            <div
              key={mediaImage.id}
              className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
              style={{ flexBasis: "100%" }}
            >
              <MediaImageBgImg mediaImage={mediaImage} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HotelRoomMediaCarousel;
