import {ReactNode} from "react";
import SectionGray from "@/src/ui/components/generic/SectionGray";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import _SocialNetworks from "@/src/ui/components/routes/Route_HotelSection/generic/_SocialNetworks";
import UrlUtils from "@/src/core/app/utils/url";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import UiHotelSectionUtils from "@/src/ui/utils/hotel-section";

interface _Props {
  hotelSection: T_HotelSection
  hero: ReactNode
}

const _Header = (props: _Props): JSX.Element => {
  const hotel = props.hotelSection.ui_hotel_common_info;

  const experienceLinks = hotel.field_experiences.map(node => ({
    label: node.title,
    href: UrlUtils.getCmsLinkUrlFromPath(node.path),
  }));

  const isMainSection = UiHotelSectionUtils.isMainSection(props.hotelSection);

  return(
    <>
      {props.hero}
      <SectionGray>
        <div className="space-y-2.5 text-brand-2">
          <h1 className="title-1-mobile" itemProp={isMainSection ? 'name' : undefined}>
            {hotel.title}
          </h1>
          <div className="flex items-center space-x-2.5 flex-shrink-0">
            <img className="h-4 w-auto" src="/assets/icons/place.svg" />
            <Paragraph size="big">{hotel.locality}, {hotel.region} ({hotel.country})</Paragraph>
          </div>
          {/*<ul className="flex text-sm py-1.5 space-x-1.5 overflow-x-auto">*/}
          {/*  {experienceLinks.map((item, index) => (*/}
          {/*    <Anchor href={item.href} key={index}>*/}
          {/*      <li*/}
          {/*        className="px-2 h-6 flex flex-wrap items-center justify-center rounded-full bg-white whitespace-nowrap"*/}
          {/*      >*/}
          {/*        {item.label}*/}
          {/*      </li>*/}
          {/*    </Anchor>*/}
          {/*  ))}*/}
          {/*</ul>*/}
          <_SocialNetworks hotel={hotel} />
        </div>
      </SectionGray>
    </>
  );
}

export default _Header;
