import {createProvider} from "@/src/common/utils/zustand";
import {BookingManageState} from "@/src/ui/view_models/booking-manage.state";
import {T_ReservationManage} from "@/src/core/app/domain/models/reservation/T_ReservationManage";
import {CMS_ReservationStatus} from "@/src/core/app/domain/cms/@types/reservation/CMS_ReservationStatus";


export const useBookingManageProvider = createProvider<BookingManageState>((set, get) => ({
  reservationManageData: null,
  setData: (data: T_ReservationManage) => set(state => {
    state.reservationManageData = data;
  }),
  clear: () => set(state => {
    state.reservationManageData = null;
  }),
  onCancel: () => set(state => {
    if (state.reservationManageData) {
      state.reservationManageData.status = CMS_ReservationStatus.CANCELLED;
      state.reservationManageData.is_cancellable = false;
    }
  })
}));
