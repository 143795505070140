import useEffectStrictMode from "@/src/ui/hooks/useEffectStrictMode";
import {userProviderStore} from "@/src/ui/providers/user.provider";
import BrowserUtils from "@/src/ui/utils/browser";
import {AppInitiatedEventData} from "@/src/ui/@types/event/AppInitiatedEvent";
import {T_HotelTree} from "@/src/core/app/domain/models/hotel-tree/T_HotelTree";
import { Route } from "@/src/core/app/domain/@types/Route";
import RouteData from "@/src/core/app/domain/@types/RouteData";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

export const useAppInitiatedEvent = (
  route: Route,
  routeData: RouteData,
  appInfo: AppInfo,
  hotelTree: T_HotelTree | null
) => {
  return useEffectStrictMode(() => {
    const unsubscribeFn = userProviderStore.subscribe(state => {
      if (state.isInitiated) {
        const detail : AppInitiatedEventData = {
          route,
          routeData,
          appInfo,
          userData: state.userData,
          hotelTree,
        };
        const event = new CustomEvent('app-initiated', {detail});
        BrowserUtils.dispatchEvent(event);
        unsubscribeFn();
      }
    });
    userProviderStore.getState().init(appInfo, route);
  });
}
