import {ModalSearchFormStepLocationTab} from "@/src/ui/components/portals/desktop/ModalSearchFormStepLocation";
import cn from "classnames";
import {useTranslation} from "next-i18next";

interface ModalPlaceSearchTabsProps {
  tab: ModalSearchFormStepLocationTab
  setTab: (tab: ModalSearchFormStepLocationTab) => void
}

const ModalPlaceSearchTabsDesktop = (props: ModalPlaceSearchTabsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ul className="px-2 py-3 text-xs uppercase divide-x divide-x-gray-20 border-b border-gray-20 flex">
      <li
        onClick={() => props.setTab(ModalSearchFormStepLocationTab.HOTELS)}
        className={cn(
          "py-2 px-3 flex flex-col items-center cursor-pointer",
          props.tab === ModalSearchFormStepLocationTab.HOTELS ?
            'text-brand-1 font-bold' :
            'text-brand-2'
        )}
      >
        <div>{t('ModalPlaceSearchTabs._Index.hotel_label')}</div>
        <div
          className={cn(
            "h-0.5 bg-brand-1 w-full transition-all transform translate-y-5",
            props.tab === ModalSearchFormStepLocationTab.HOTELS ?
              'max-w-lg' :
              'max-w-0'
          )}
        />
      </li>
      <li
        onClick={() => props.setTab(ModalSearchFormStepLocationTab.DESTINATIONS)}
        className={cn(
          "py-2 px-3 flex flex-col items-center cursor-pointer",
          props.tab === ModalSearchFormStepLocationTab.DESTINATIONS ?
            'text-brand-1 font-bold' :
            'text-brand-2'
        )}
      >
        <div>{t('ModalPlaceSearchTabs._Index.destination_label')}</div>
        <div
          className={cn(
            "h-0.5 bg-brand-1 w-full transition-all transform translate-y-5",
            props.tab === ModalSearchFormStepLocationTab.DESTINATIONS ?
              'max-w-lg' :
              'max-w-0'
          )}
        />
      </li>
    </ul>
  );
}

export default ModalPlaceSearchTabsDesktop;
