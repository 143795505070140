import styled from "styled-components";

export const ShimmerEffectDiv = styled.div`
  background: linear-gradient(to right, #D5E2EE 8%, #C1D0DE 18%, #D5E2EE 33%);
  background-size: 1000px 100%;
  animation: shimmer 2.2s linear infinite forwards;

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`