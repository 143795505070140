import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import React, {useState} from "react";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import CheckoutRateGroupHeader from "@/src/ui/components/checkout/generic/CheckoutRateGroupHeader";
import CheckoutRateGroupBoardMobile from "@/src/ui/components/checkout/mobile/CheckoutRateGroupBoard";
import CheckoutRateGroupFooterMobile from "@/src/ui/components/checkout/mobile/CheckoutRateGroupFooter";

interface _Props {
  rateGroup: CheckoutRatesHotelRoomRateGroup
  onClick: (
    rateGroup: CheckoutRatesHotelRoomRateGroup,
    board: CheckoutRatesHotelRoomRateGroupBoard
  ) => void
}

const CheckoutRateGroupMobile = (props: _Props): JSX.Element => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => setExpanded(!isExpanded)}
      >
        <CheckoutRateGroupHeader rateGroup={props.rateGroup} />
      </div>
      {isExpanded && (
        <ul className="divide-y divide-gray-40">
          {props.rateGroup.boards.map(board => (
            <li key={board.code}>
              <CheckoutRateGroupBoardMobile
                board={board}
                onClick={(board: CheckoutRatesHotelRoomRateGroupBoard) => props.onClick(props.rateGroup, board)}
              />
            </li>
          ))}
        </ul>
      )}
      {isExpanded && (
        <CheckoutRateGroupFooterMobile rateGroup={props.rateGroup}/>
      )}
    </div>
  )
};

export default CheckoutRateGroupMobile;
