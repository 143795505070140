import {T_ParagraphUserTmsInfo} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserTmsInfo";
import {useUserTmsInfoProvider} from "@/src/ui/providers/user-tms-info.provider";
import _Wrapper from "@/src/ui/components/blocks/BlockUserTmsInfo/generic/wrapper";
import _Loading from "@/src/ui/components/blocks/BlockUserTmsInfo/mobile/loading";
import {TmsInfoStatus} from "@/src/core/app/domain/user/@types/Output/UserTmsInfoSuccessOutput";
import SimpleMessage from "@/src/ui/components/generic/SimpleMessage";
import _Overview from "@/src/ui/components/blocks/BlockUserTmsInfo/mobile/overview";
import _Table from "@/src/ui/components/blocks/BlockUserTmsInfo/generic/table";
import {CMS_UserTmsInfo} from "@/src/core/app/domain/cms/@types/user/CMS_UserTmsInfo";
import {useTranslation} from "next-i18next";

const BlockUserTmsInfoMobile = (props: T_ParagraphUserTmsInfo) => {
  const { t } = useTranslation();
  const state = useUserTmsInfoProvider(state => state);

  if (state.isLoading) {
    return (
      <_Wrapper paragraph={props}>
        <_Loading />
      </_Wrapper>
    );
  }

  if (state.data === null) {
    return (
      <_Wrapper paragraph={props}>
        <SimpleMessage message={t('BlockUserTmsInfoDesktop._Index.section_temporarily_unavailable')} />
      </_Wrapper>
    );
  }

  if (state.data.status === TmsInfoStatus.DISABLED) {
    return (
      <_Wrapper paragraph={props}>
        <SimpleMessage type={'html'} message={props.field_uti_tms_disabled_content.processed} />
      </_Wrapper>
    );
  }

  if (state.data.status === TmsInfoStatus.USER_ANONYMOUS) {
    return (
      <_Wrapper paragraph={props}>
        <SimpleMessage message={t('BlockUserTmsInfoDesktop._Index.section_not_avaiable_for_anonymous_users')} />
      </_Wrapper>
    );
  }

  if (state.data.status === TmsInfoStatus.USER_WITHOUT_CLIENT_ID) {
    return (
      <_Wrapper paragraph={props}>
        <SimpleMessage type={'html'} message={props.field_uti_no_client_id_content.processed} />
      </_Wrapper>
    );
  }

  if (state.data.status === TmsInfoStatus.KO) {
    return (
      <_Wrapper paragraph={props}>
        <SimpleMessage message={state.data.error as string} />
      </_Wrapper>
    );
  }

  return (
    <_Wrapper paragraph={props}>
      <_Overview
        userTmsInfo={state.data.data as CMS_UserTmsInfo}
        footer={props.field_uti_overview}
      />
      <_Table userTmsInfo={state.data.data as CMS_UserTmsInfo} />
    </_Wrapper>
  );
}

export default BlockUserTmsInfoMobile;
