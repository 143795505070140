import {T_ParagraphHotelList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelList";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import HotelTeaserSquaredList from "@/src/ui/components/hotel/HotelTeaserSquaredList";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const _Grid = (props: T_ParagraphHotelList) => (
  <Section paddingY="pt-7.5 pb-15" backgroundColor={props.field_background_color} >
    <MaxWidthWrapper>
      <TitleWithTag
        className="title-4 font-calistoga"
        field={props.field_title_with_tag}
        suffix={`(${props.hotelTeasers.length})`}
      />
      <HotelTeaserSquaredList
        hotelTeasers={props.hotelTeasers}
        linkSection={props.field_hotel_list_link_section}
      />
    </MaxWidthWrapper>
  </Section>
);

export default _Grid;
