import FormInputText from "@/src/ui/components/formik/FormInputText";
import Button from "@/src/ui/components/generic/Button";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Formik} from "formik";
import {UserLoginFormValues} from "@/src/core/app/domain/forms/UserLoginFormValues";
import FormUtils from "@/src/ui/utils/form";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import UserUtils from "@/src/core/app/utils/user";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import UserLoginErrors from "@/src/core/app/domain/user/@types/FormErrorMap/user-login";
import Anchor from "@/src/ui/components/generic/Anchor";
import UserLoginUtils from "@/src/core/app/utils/user-login";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import AppController from "@/src/ui/controllers/AppController";
import {useTranslation} from "next-i18next";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import {UI_LayoutCommon} from "@/src/ui/@types/layout/UI_LayoutCommon";
import UrlUtils from "@/src/core/app/utils/url";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import UiElbaMediaUtils from "@/src/ui/utils/elba-media";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import BrowserUtils from "@/src/ui/utils/browser";

export interface _FormProps {
  formik: FormikProps<UserLoginFormValues>
}

const _Form = (props: _FormProps) => {
  const { t } = useTranslation();
  const routeProps = useRouteProps();
  const layoutData = useLayoutData<UI_LayoutBase>();
  return (
    <TitledBox title={t('UserLoginForm._Index.title')}>
      <div className="p-5 space-y-5 sm:space-y-7.5">
        <div className="space-y-3.5">
          <FormInputText
            label={t('UserLoginForm._Index.email_label') + " *"}
            name='email'
            type='email'
            validations={[
              FormUtils.validateIsRequired,
              FormUtils.validateIsEmail,
            ]}
          />
          <FormInputText
            name='password'
            label={t('UserLoginForm._Index.password_label') + " *"}
            type='password'
            validate={FormUtils.validateIsRequired}
          />
          <p className="text-xs text-brand-2-90 hover:text-brand-2 underline">
            <Anchor
              href={UrlUtils.buildAppUrlWithLangPrefix(routeProps.appInfo.langcode, '/password/recovery')}
            >
              {t('UserLoginForm._Index.password_recovery_link_label')}
            </Anchor>
          </p>
        </div>
        <div>
          <Button
            text={t('UserLoginForm._Index.login_button_label')}
            bold onClick={props.formik.handleSubmit} isActive={!props.formik.isSubmitting} />
        </div>
        <p className="text-sm text-brand-2">
          {t('UserLoginForm._Index.register_message')}{" "}
          <Anchor
            className="underline"
            href={UrlUtils.buildAppUrlWithLangPrefix(routeProps.appInfo.langcode, '/user/register')}
          >
            {t('UserLoginForm._Index.register_button_label')}
          </Anchor>
        </p>
        <VerisignImg />
      </div>
    </TitledBox>
  );
}

const UserLoginForm = () => {
  const appInfo = useAppInfo();
  const {t} = useTranslation();
  const layoutData = useLayoutData<UI_LayoutCommon>();
  const modalState = useModalProvider(state => state);
  let initialValues : UserLoginFormValues = {
    email: '',
    password: '',
  };

  return (
    <Formik<UserLoginFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await UserLoginUtils.login(values.email, values.password);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: UserLoginErrors,
            formErrorMapKeyGetter: NetworkUtils.NetworkErrorKeyGetter__DataIsErrorMapKey,
            logFn: (reason) => FormErrorUtils.logNetworkError('User login form error', reason, networkError),
          });
          return;
        }

        const userData = await UserUtils.loadUser();
        if (userData) {
          const url = UiElbaMediaUtils.getLoginRedirectionUrl(appInfo, userData);
          if (url) {
            BrowserUtils.goTo(url);
            return;
          }
        }

        UserUtils.goToUserHome(layoutData.sitePageUrls);
      }}
    >
      {(formik: FormikProps<UserLoginFormValues>) => (
        <_Form formik={formik} />
      )}
    </Formik>
  );
}

export default UserLoginForm;
