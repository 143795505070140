import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Anchor from "@/src/ui/components/generic/Anchor";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_ParagraphResidentsList} from "@/src/core/app/domain/models/paragraph/T_ParagraphResidentsList";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const _Column = (props: T_ParagraphResidentsList) => (
  <Section backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      <div className="flex space-x-5 items-stretch">
        <div className="w-70 flex items-center">
          <TitleWithTag
            className="text-brand-2 font-bold text-lg"
            field={props.field_title_with_tag}
          />
        </div>
        {props.residents.map((item, index) => (
          <Anchor key={item.id} path={item.path}>
            <article className="relative w-70 h-25 cursor-pointer">
              <MediaImageBgImg mediaImage={item.field_resident_home}/>
              <div className="inset-0 flex justify-center items-center absolute hover:bg-brand-2-80 transition">
                <h3 className="relative text-white text-lg font-bold">
                  {item.title}
                </h3>
              </div>
            </article>
          </Anchor>
        ))}
      </div>
    </MaxWidthWrapper>
  </Section>
);

export default _Column;
