import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";

const CountryUtils = {
  getSelectOptions: (countries: T_Country[]) : FormInputSelectOption[] => {
    return countries.map(country => ({
      value: country.iso2,
      label: country.title,
    }));
  },
  findByIso2: (countries: T_Country[], iso2: string) : T_Country => countries.find(item => item.iso2 === iso2) as T_Country,
  findByUuid: (countries: T_Country[], uuid: string) : T_Country => countries.find(item => item.id === uuid) as T_Country,
};

export default CountryUtils;
