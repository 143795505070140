import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import FormInputBasicSelect from "src/ui/components/form/FormInputBasicSelect";
import {useTranslation} from "next-i18next";
import {useSearchFormKidsMaxAge} from "@/src/ui/hooks/useSearchFormKidsMaxAge";

interface _RoomProps {
  roomIndex: number
  room: SearchParamsOccupationRoom
}

export const _Room = ({
  roomIndex,
  room,
} : _RoomProps) => {
  const { t } = useTranslation();
  const roomSubAdult = useSearchFormOccupationProvider(state => state.roomSubAdult);
  const roomAddAdult = useSearchFormOccupationProvider(state => state.roomAddAdult);
  const roomSubKid = useSearchFormOccupationProvider(state => state.roomSubKid);
  const roomAddKid = useSearchFormOccupationProvider(state => state.roomAddKid);
  const roomChangeKidAge = useSearchFormOccupationProvider(state => state.roomChangeKidAge);
  const kidsMaxAge = useSearchFormKidsMaxAge();

  return (
    <li
      className="space-y-4 py-7.5">
      <h3 className="text-lg font-bold">
        {t('SearchFormRoomOccupationWidget._Index.room_label', {
          index: roomIndex + 1
        })}
      </h3>
      <div className="flex justify-between items-center">
        <div className="flex">
          <img className="w-5 h-auto" src="/assets/icons/person-brand-2.svg" />
          <div className="text-sm w-32 pl-2">{t('SearchFormRoomOccupationWidget._Index.adults_label')}</div>
        </div>
        <div className="w-36 pl-1 flex justify-between items-center">
          <button onClick={() => roomSubAdult(roomIndex)}>
            <img src="/assets/icons/minus-circle.svg" />
          </button>
          <div className="w-14 mx-0.5 text-sm text-center">
            {room.adults}
          </div>
          <button onClick={() => roomAddAdult(roomIndex)}>
            <img src="/assets/icons/plus-circle.svg" />
          </button>
        </div>
      </div>
      {kidsMaxAge !== 0 && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center">
            <img className="w-5 h-auto" src="/assets/icons/person-brand-2.svg"/>
            <div className="text-sm w-32 pl-2 flex flex-col">
              <span>{t('SearchFormRoomOccupationWidget._Index.kids_label')}</span>
              <span className="text-xs text-brand-2-60">
              {t('SearchFormRoomOccupationWidget._Index.kids_max_age_label', {
                amount: kidsMaxAge
              })}
            </span>
            </div>
          </div>
          <div className="w-36 pl-1 flex justify-between items-center">
            <button onClick={() => roomSubKid(roomIndex)}>
              <img src="/assets/icons/minus-circle.svg"/>
            </button>
            <div className="text-sm">{room.kids}</div>
            <button onClick={() => roomAddKid(roomIndex, kidsMaxAge)}>
              <img src="/assets/icons/plus-circle.svg"/>
            </button>
          </div>
        </div>
      )}
      {room.kids > 0 && (kidsMaxAge !== 0) && (
        <ul className="space-y-3.5">
          {room.kid_ages.map((kidAge, kidIndex) => (
            <li className="flex justify-between items-center" key={kidIndex}>
              <div className="flex-1 mr-2">
                {t('SearchFormRoomOccupationWidget._Index.kid_age_label', {
                  index: kidIndex + 1,
                })}
              </div>
              <div className="flex-none w-36 pl-1">
                <FormInputBasicSelect
                  value={kidAge.toString()}
                  options={SearchParamsUtils.getKidAgeOptions(t, kidsMaxAge)}
                  onChange={newKidAge => roomChangeKidAge(roomIndex, kidIndex, parseInt(newKidAge))}
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}