import OfferSearch from "@/src/ui/components/offer/OfferSearch";
import {T_ParagraphHotelOffers} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelOffers";


const BlockHotelOffers = (props: T_ParagraphHotelOffers) => (
  <OfferSearch
    titleWithTag={props.field_title_with_tag}
    count={props.cpt_offer_count}
    offerGroupLinks={props.cpt_ui_offer_group_links}
    experienceLinks={props.cpt_ui_experience_links}
    offerTeasers={props.offerTeasers}
    pager={props.pager}
  />
);

export default BlockHotelOffers;
