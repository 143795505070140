import {T_HotelRoomPromoted} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomPromoted";
import Title4 from "@/src/ui/components/generic/Title4";
import Card from "@/src/ui/components/generic/Card";
import HotelRoomFeaturesSummary from "@/src/ui/components/hotel-room/generic/HotelRoomFeaturesSummary";
import {useTranslation} from "next-i18next";

const _Room = (props: T_HotelRoomPromoted): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Card mediaImage={props.field_room_media_square}>
      <div className="opacity-100 hover-show-child-opacity-0 transition-all absolute inset-0 bg-img-blue-shade-card" />
      <div className="pb-5 space-y-5 hover-show-child absolute">
        <Title4 className='font-calistoga'>
          {props.title}
        </Title4>
        <HotelRoomFeaturesSummary
          room={props}
          iconColor='white'
          className='text-sm space-x-5 justify-center'
          itemClassName='space-x-3'
        />
      </div>
      <span className="hover-show-child absolute inset-x-0 bottom-6 text-center underline text-sm">
      {t('BlockHotelRoomsPromoted._Room.view_more')}
    </span>
    </Card>
  );
}

export default _Room;
