import cn from "classnames";
import {useRoute} from "@/src/ui/hooks/useRoute";
import RouteUtils from "@/src/core/app/utils/route";
import {CMS_MenuAccount} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuAccount";
import {
  CMS_MenuAccountItem,
  CMS_MenuAccountItemStyle,
  CMS_MenuAccountItemUserTmsClientId
} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuAccountItem";
import Anchor from "@/src/ui/components/generic/Anchor";
import MenuUtils from "@/src/ui/utils/menu";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import {useTranslation} from "next-i18next";

interface _Props {
  menu: CMS_MenuAccount
}

interface _MenuProps {
  userData: UserData | null
  menu: CMS_MenuAccount
}

interface _PropsMenuLink {
  userData: UserData | null
  menuLink: CMS_MenuAccountItem
}

const isVisible = (menuLink: CMS_MenuAccountItem, userData: UserData | null) => {
  if (userData === null) {
    return true;
  }

  if (menuLink.field_ma_user_tms_client_id === CMS_MenuAccountItemUserTmsClientId.SKIP) {
    return true;
  }

  if (userData.tmsClientId === null) {
    return (menuLink.field_ma_user_tms_client_id === CMS_MenuAccountItemUserTmsClientId.MANDATORY_NULL);
  }

  return (menuLink.field_ma_user_tms_client_id === CMS_MenuAccountItemUserTmsClientId.MANDATORY_ASSIGNED);
};

const _Link = (props: _PropsMenuLink) => {
  const route = useRoute();
  const url = MenuUtils.getMenuItemHref(props.menuLink);

  if (!isVisible(props.menuLink, props.userData)) {
    return null;
  }

  return (
    <li
      className={cn(
        "hover:underline",
        RouteUtils.isRoute(route, url) && "font-bold text-brand-1",
        props.menuLink.field_menu_account_style === CMS_MenuAccountItemStyle.SECONDARY && 'normal-case text-brand-2-90'
      )}
    >
      <Anchor href={url}>{props.menuLink.title}</Anchor>
    </li>
  )
}

const _Menu = (props: _MenuProps) => {
  const { t } = useTranslation();
  return (
    <nav className="space-y-7.5">
      <p className="title-4 font-calistoga">{props.menu.label}</p>
      <ul className="text-brand-2 text-xs uppercase space-y-2.5">
        {props.menu.items.map((menuLink, index) => (
          <_Link key={menuLink.id} menuLink={menuLink} userData={props.userData} />
        ))}
      </ul>
      <div className="text-brand-2-90">
        <p className="text-medium-bold">{t('BlockMenuAccount._Index.link_title')}</p>
        <p>
          <Anchor className="text-link-small" href="/user/contact">
            {t('BlockMenuAccount._Index.link_label')}
          </Anchor>
        </p>
      </div>
    </nav>
  );
}

const BlockMenuAccountDesktop = (props: _Props) => {
  const userState = useUserProvider(state => state);

  if ((!userState.isInitiated) || (userState.userData !== null)) {
    return (
      <_Menu {...props} userData={userState.userData} />
    );
  }
  return null;
}

export default BlockMenuAccountDesktop;
