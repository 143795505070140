import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import CalendarUtils, {DATE_ISO_FORMAT} from "@/src/ui/utils/calendar";

interface _Props {
  hotelTeaser: T_HotelTeaser
}

const HotelTeaserJsonLd = (props: _Props) => {
  const data = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    "author": "Hoteles Elba",
    "contentLocation": "Spain, Spain",
    "contentUrl": props.hotelTeaser.ui_cpt_field_hotel_teaser.field_media_image.uri.url,
    "datePublished": CalendarUtils.formatDateTime(props.hotelTeaser.created, DATE_ISO_FORMAT),
    "description": props.hotelTeaser.meta?.description ?? '',
    "name": props.hotelTeaser.title,
  };
  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: JSON.stringify(data)
    }} />
  );
} ;

export default HotelTeaserJsonLd;
