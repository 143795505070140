import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";
import {UserLoginError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserLoginError";

const UserLoginErrors : FormErrorMap = {
  [UserLoginError.INVALID_CREDENTIALS]: {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'user_login.invalid_credentials.error_title',
      message: 'user_login.invalid_credentials.error_message',
    }
  },
}

export default UserLoginErrors;
