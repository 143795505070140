import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockHotelListDesktop from "@/src/ui/components/blocks/BlockHotelList/desktop";
import BlockHotelListMobile from "@/src/ui/components/blocks/BlockHotelList/mobile";
import {T_ParagraphHotelList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelList";


const BlockHotelList = (props: T_ParagraphHotelList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockHotelListMobile : BlockHotelListDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockHotelList;
