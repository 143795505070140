import {useSearchFormMobileProvider} from "@/src/ui/providers/search-form-mobile-provider";
import {SearchFormMobileStep} from "@/src/ui/view_models/search-form-mobile.state";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParamsError} from "@/src/core/app/domain/@types/SearchParamsError";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import Anchor from "@/src/ui/components/generic/Anchor";
import ButtonLocationSummary from "@/src/ui/components/search-form/ButtonLocationSummary";
import ButtonDateRangeSummary from "@/src/ui/components/search-form/ButtonDateRangeSummary";
import ButtonOccupationSummary from "@/src/ui/components/search-form/ButtonOccupationSummary";
import InputText from "@/src/ui/components/form/InputText";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import Button from "@/src/ui/components/generic/Button";
import {useTranslation} from "next-i18next";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {ModalSearchFormMobileData} from "@/src/ui/@types/modal/ModalSearchFormMobileData";

const ModalSearchFormStepFormMobile = (): JSX.Element | null => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const searchForm = useSearchFormProvider(state => state);
  const state = useSearchFormMobileProvider(state => state);
  const layoutData = useLayoutData<UI_LayoutGeneric>();

  if (state.currentStep !== SearchFormMobileStep.FORM) {
    return null;
  }

  const modalStateData = modalState.modalData as ModalSearchFormMobileData | null;
  const roomCode = modalStateData ? modalStateData.roomCode : undefined;

  return (
    <div className="space-y-5 text-brand-2 p-7.5">
      <ButtonLocationSummary
        hasError={SearchParamsUtils.hasError(searchForm.validation, SearchParamsError.LOCATION_EMPTY)}
        onClick={() => state.setCurrentStep(SearchFormMobileStep.LOCATION)}
      />
      <ButtonDateRangeSummary
        hasError={SearchParamsUtils.hasError(searchForm.validation, SearchParamsError.DATE_RANGE_EMPTY)}
        onClick={() => state.setCurrentStep(SearchFormMobileStep.DATES)}
      />
      <ButtonOccupationSummary
        onClick={() => state.setCurrentStep(SearchFormMobileStep.OCCUPATION)}
      />
      <InputText
        writable
        icon="/assets/icons/ticket.svg"
        placeholder={t('SearchForm._Index.promocode_placeholder')}
        value={searchForm.promocode}
        onChange={(value) => searchForm.setPromocode(value)}
      />
      {layoutData.menuSearchForm.items.map(item => (
        <Paragraph size="medium" key={item.id}>
          <Anchor className="hover:underline" href={item.url}>
            + {item.title}
          </Anchor>
        </Paragraph>
      ))}
      <div>
        <Button
          text={t('SearchForm._Index.submit_button_label')}
          className="flex leading-10 h-10 align-middle"
          customPaddingClass='px-7.5'
          bold
          onClick={() => SearchFormUtils.submit(searchForm, modalState, roomCode)}
        />
      </div>
    </div>
  );
}

export default ModalSearchFormStepFormMobile;
