import _PriceTagCommon from "@/src/ui/components/offer/OfferDetail/price-tag/by-type/common";
import {useTranslation} from "next-i18next";

interface _Props {
  discount: number
  description?: string
}

const _PriceTagDiscount = (props: _Props) => {
  const { t } = useTranslation();
  return (
    <_PriceTagCommon>
      <p className='uppercase font-bold text-brand-2-90 text-sm'>{t('OfferDetail._PriceTagDiscount.title')}</p>
      <p className='text-brand-2 font-text-8'>-{props.discount}%</p>
      {props.description && (
        <p className='text-sm text-brand-2-90'>{props.description}</p>
      )}
    </_PriceTagCommon>
  );
}

export default _PriceTagDiscount;
