import TriangleSeparator from "../../../generic/TriangleSeparator";
import cn from "classnames";
import {T_HotelSectionHotelCommonInfo} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";
import UiMediaUtils from "@/src/ui/utils/media";

interface _Props {
  hotel: T_HotelSectionHotelCommonInfo
}

const _Hero = (props: _Props): JSX.Element => (
  <div className="bg-gray-20">
    <img
      className={cn("h-30 w-full object-cover")}
      {...UiMediaUtils.getImgPropsFromMediaImage(props.hotel.field_hotel_header_mobile)}
    />
    <TriangleSeparator />
  </div>
)

export default _Hero;
