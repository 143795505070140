import {T_OfferTeaser} from "@/src/core/app/domain/models/offer/T_OfferTeaser";
import OfferTeaser from "@/src/ui/components/offer/OfferTeaser";
import WarningBanner from "@/src/ui/components/desktop/WarningBanner";
import {useTranslation} from "next-i18next";
import {OfferTeaserStyle} from "@/src/ui/@types/offer/OfferTeaserStyle";

interface _Props {
  items: T_OfferTeaser[]
  style?: OfferTeaserStyle
}

const OfferTeaserList = (props: _Props) => {
  const { t } = useTranslation();

  if (props.items.length === 0) {
    return (
      <WarningBanner>
        <div>{t('OfferTeaserList._Index.empty_text')}</div>
      </WarningBanner>
  )
    ;
  }
  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-content-center">
      {props.items.map(item => (
        <OfferTeaser
          key={item.id}
          item={item}
          style={props.style}
        />
      ))}
    </ul>
  );
}

export default OfferTeaserList;