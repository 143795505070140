import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockHotelRoomListDesktop from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/default";
import BlockHotelRoomListMobile from "@/src/ui/components/blocks/BlockHotelRoomList/mobile/default";

const BlockHotelRoomList = (props: T_ParagraphHotelRoomList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockHotelRoomListMobile : BlockHotelRoomListDesktop;
  return (
    <_Component {...props}/>
  )
};

export default BlockHotelRoomList;
