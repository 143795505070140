import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import CalendarUtils from "@/src/ui/utils/calendar";
import {ElbaMediaItem} from "@/src/core/app/domain/elba-media/@types/ElbaMediaItem";
import UiElbaMediaUtils from "@/src/ui/utils/elba-media";

interface _Props {
  item: ElbaMediaItem
}

const _Row = (props: _Props) => {
  const item = props.item;
  return (
    <tr>
      <td>{item.title}</td>
      <td>
        {!item.description && ''}
        {item.description && (
          <WrapperDiv
            dangerouslySetInnerHTML={{__html: item.description.value}}
          />
        )}
      </td>
      <td>
        <a className="flex space-x-0.5" href={item.file.url} target='_blank'>
          <img src={UiElbaMediaUtils.getIcon(item.file.type)} alt=""/>
          <span>{item.file.name}</span>
        </a>
      </td>
      <td>
        {CalendarUtils.formatDateTime(item.created)}
      </td>
    </tr>
  )
};

export default _Row;
