import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";

export enum CMS_MenuHeaderUserItemStyle {
  NORMAL = 'normal',
  ICON_PEOPLE = 'icon-people',
}
export enum CMS_MenuHeaderUserItemVisibility {
  ALL = 'all',
  ONLY_ANONYMOUS = 'only-anonymous',
  ONLY_AUTHENTICATED = 'only-authenticated',
}

export interface CMS_MenuHeaderUserItem extends CMS_MenuItem {
  field_menu_hu_style: CMS_MenuHeaderUserItemStyle
  field_menu_hu_visibility: CMS_MenuHeaderUserItemVisibility
}
