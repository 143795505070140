import {useContextHeaderDesktopContext} from "@/src/ui/context/HeaderDesktopContext";
import Anchor from "@/src/ui/components/generic/Anchor";
import {CMS_Menu} from "@/src/core/app/domain/cms/@types/menu/CMS_Menu";
import {CMS_Language} from "@/src/core/app/domain/cms/@types/language/CMS_Language";
import LanguageDropdown from "@/src/ui/components/generic/LanguageDropdown";
import CurrencyDropdown from "@/src/ui/components/generic/CurrencyDropdown";
import {T_CurrencyList} from "@/src/core/app/domain/models/currency/T_CurrencyList";

interface _Props {
  menu: CMS_Menu
  currencies: T_CurrencyList
  languages: CMS_Language[]
}

const _MenuHeaderSecondary = (props: _Props): JSX.Element => {
  const context = useContextHeaderDesktopContext();
  return (
    <>
      {props.menu.items.map((item, index) => (
        <Anchor href={item.url} key={item.id}>{item.title}</Anchor>
      ))}
      <span>
        <CurrencyDropdown
          color={context?.isVisible ? "brand-2" : 'white'}
          spaceBetween
        />
      </span>
      <span>
        <LanguageDropdown
          spaceBetween
          color={context?.isVisible ? "brand-2" : 'white'}
        />
      </span>
    </>
  );
}

export default _MenuHeaderSecondary;
