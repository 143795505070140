const MapboxMapService = {
  init: (nodes) => {
    window.mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_API_KEY;

    const options = {
      container: 'mapId',
      style: 'mapbox://styles/mapbox/streets-v11',
      scrollZoom: false,
      maxZoom: 16,
      zoom: 4,
    };

    const bounds = new mapboxgl.LngLatBounds();
    nodes.forEach((node) => {
      bounds.extend([node.longitude, node.latitude]);
    });

    options['center'] = bounds.getCenter();

    if (nodes.length === 1) {
      options['zoom'] = 10;
    }

    const map = new window.mapboxgl.Map(options);

    nodes.forEach((node) => {
      const popup = new window.mapboxgl.Popup()
        .setHTML(node.link);

      const el = document.createElement('img');
      el.src = '/assets/images/generic/marker-icon.png';
      el.alt = node.title;
      el.style.width = '25px';
      el.style.height = '41px';
      el.style.transform = 'translate3d(550px, 219px, 0px)';
      el.style.outline = 'none';
      el.className = 'leaflet-marker-icon leaflet-zoom-animated leaflet-interactive';

      const marker = new window.mapboxgl.Marker({
        element: el,
        color: '#3E7DC9'
      })
        .setLngLat({
          lat: node.latitude,
          lon: node.longitude,
        })
        .addTo(map);
      marker.setPopup(popup);
    });

    if (nodes.length > 1) {
      map.fitBounds(bounds, {
        padding: 20
      });
    }
  }
};

export default MapboxMapService;
