import {useState} from "react";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import cn from "classnames";
import {useTranslation} from "next-i18next";

interface _Props {
  currentOption: FormInputSelectOption | null
  options: FormInputSelectOption[]
  onChange: (option: FormInputSelectOption | null) => void
  placeholder: string
}

const _Filter = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const onClick = (option: FormInputSelectOption | null) => {
    setIsOpened(false);
    props.onChange(option);
  };

  return (
    <div className="flex items-baseline">
      <strong>{t('BlockHotelListFiltrable._Filter.title')}&nbsp;</strong>
      <div className="relative flex space-x-1 items-center hover:underline">
        <div className="cursor-pointer flex items-center space-x-1" onClick={() => setIsOpened(!isOpened)}>
          <button>{props.currentOption ? props.currentOption.label : props.placeholder}</button>
          <img src="/assets/icons/triangle-down-brand-2.svg" alt="" />
        </div>
        <ul
          className={cn(
            'absolute w-62 p-5 top-8 -left-1 space-y-2.5 rounded bg-white shadow-custom text-sm text-brand-2 z-10',
            !isOpened && 'hidden'
          )}
        >
          <li
            className="cursor-pointer hover:underline"
            key={'-empty-'}
            onClick={()=> onClick(null)}
          >
            {props.placeholder}
          </li>
          {props.options.map((option) => (
            <li
              className="cursor-pointer hover:underline"
              key={option.value}
              onClick={()=> onClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default _Filter;
