import {SmallDropdownStyleProps} from "@/src/ui/components/generic/SmallDropdown/types";
import SmallDropdown from "@/src/ui/components/generic/SmallDropdown";
import LanguageUtils, {LanguageDropdownMode} from "@/src/ui/utils/language";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import {T_Meta} from "@/src/core/app/domain/models/meta/T_Meta";
import UrlUtils from "@/src/core/app/utils/url";

interface _Props extends SmallDropdownStyleProps {
  mode?: LanguageDropdownMode
}

const getUrl = (langcode: string, meta: T_Meta | undefined | null) => {
  const def = `/${langcode}`;
  if (!meta) {
    return def;
  }
  if (!meta.hreflang[langcode]) {
    return def;
  }
  return UrlUtils.getCmsLinkUrl(meta.hreflang[langcode]);
};

const LanguageDropdown = (props: _Props): JSX.Element => {
  const {
    mode = LanguageDropdownMode.DEFAULT,
    ...styleProps
  } = props;
  const routeProps = useRouteProps<any, UI_LayoutBase>();

  const onChange = (value: string) => {
    window.location.href = getUrl(value, routeProps.routeData.meta);
  };

  return (
    <SmallDropdown
      currentOption={routeProps.appInfo.langcode}
      options={LanguageUtils.getDropdownOptions(routeProps.layoutData.languages, props.mode)}
      onChange={onChange}
      {...styleProps}
    />
  );
}

export default LanguageDropdown;
