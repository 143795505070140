import {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import { useCheckoutProvider } from "@/src/ui/providers/checkout.provider";
import HotelSectionMobile from "@/src/ui/components/routes/Route_HotelSection/mobile";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";
import StickyCheckoutStepRoomsRoomSummary
  from "@/src/ui/components/checkout/generic/StickyCheckoutStepRoomsRoomSummary";
import {PropsWithChildren} from "react";
import {T_HotelSectionDataRoom} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataRoom";
import HotelSectionRoomsDesktop from "@/src/ui/components/routes/Route_HotelSection/by-type/rooms/desktop";

export default function HotelSectionRooms(props: RouteProps<T_HotelSection>): JSX.Element {
  const device = props.device;
  const _Component = device.isDesktop ? HotelSectionRoomsDesktop : HotelSectionMobile;

  return (
    <_Component {...props} />
  )
}

HotelSectionRooms.getPortals = () => (
  <StickyCheckoutStepRoomsRoomSummary />
)

HotelSectionRooms.getAppWrapper = (
  props: PropsWithChildren<RouteProps<T_HotelSection<T_HotelSectionDataRoom>>>
) => (
  <useCheckoutProvider.State
    langcode={props.appInfo.langcode}
    step={CheckoutStep.ROOMS}
    hotelId={props.routeData.data.ui_hotel_common_info.id}
    hotelCode={props.routeData.data.ui_hotel_common_info.code}
    stepExtrasEnabled={props.routeData.data.ui_hotel_section.data.checkoutStepExtrasEnabled}
  >
    {props.children}
  </useCheckoutProvider.State>
)
