import UrlUtils from "@/src/core/app/utils/url";
import PageMenuMobile from "@/src/ui/components/mobile/PageMenuMobile";
import {T_TaxonomyTermLink} from "@/src/core/app/domain/models/taxonomy/T_TaxonomyTermLink";
import {useTranslation} from "next-i18next";
import UiTaxonomyTermUtils from "@/src/ui/utils/taxonomy-term";

interface _Props {
  currentTaxonomy: T_TaxonomyTermLink | null
  categories: T_TaxonomyTermLink[]
  paddingY?: string
  paddingX?: string
}

const BlogCategoryListMobile = (props: _Props) => {
  const {t} = useTranslation();
  const options = props.categories.map(item => ({
    id: item.id,
    label: item.title,
    value: UiTaxonomyTermUtils.getUrl(item),
  }));

  const currentOption = options.find(item => {
    return item.id === props.currentTaxonomy?.id;
  }) || null;

  return (
    <PageMenuMobile
      title={t('generic.blog.categories_label')}
      currentOption={null}
      options={options}
      placeholder={currentOption?.label || t('BlogCategoryList._Index.placeholder')}
      placeholderOptionLabel={'- ' + t('BlogCategoryList._Index.placeholder_option_label') + ' -'}
      onChange={(option) => option && (window.location.href = option.value)}
      showPlaceholderOption={true}
      paddingY={props.paddingY}
      paddingX={props.paddingX}
    />
  )
}

export default BlogCategoryListMobile;
