import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import CalendarUtils, {DATE_DEFAULT_FORMAT} from "@/src/ui/utils/calendar";
import {TFunction} from 'react-i18next';

const SearchParamsFormatter = {
  getOccupationSummary: (rooms: SearchParamsOccupationRoom[], t: TFunction) : string => {
    const adults = SearchParamsUtils.getTotalAdults(rooms);
    const kids = SearchParamsUtils.getTotalKids(rooms);
    if ((adults > 0) && (kids > 0)) {
      const total = adults + kids;
      return total === 1 ?
        t('search_params_formatter.occupation_summary.label_singular') :
        t('search_params_formatter.occupation_summary.label_plural', {
          count: total,
        });
    }
    if (adults > 0) {
      return SearchParamsFormatter.getOccupationAdults(adults, t);
    }
    return SearchParamsFormatter.getOccupationKids(kids, t);
  },
  getOccupationAdults: (adults: number, t: TFunction) : string => {
    return adults === 1 ?
      t('search_params_formatter.occupation_adults.label_singular') :
      t('search_params_formatter.occupation_adults.label_plural', {
        count: adults,
      })
    ;
  },
  getOccupationKids: (kids: number, t: TFunction) : string => {
    return kids === 1 ?
      t('search_params_formatter.occupation_kids.label_singular')  :
      t('search_params_formatter.occupation_kids.label_plural', {
        count: kids,
      })
    ;
  },
  getRoomsSummary: (rooms: SearchParamsOccupationRoom[], t: TFunction) : string => {
    const count = SearchParamsUtils.getRoomCount(rooms);
    return count === 1 ?
      t('search_params_formatter.rooms_summary.label_singular') :
      t('search_params_formatter.rooms_summary.label_plural', {
        count,
      })
    ;
  },
  formatRoomOccupation: (adults: number, kids: number, t: TFunction) : string => {
    const parts = [];
    let t_adults = null, t_kids = null;
    if (adults > 0) {
      t_adults = SearchParamsFormatter.getOccupationAdults(adults, t);
      parts.push(t_adults);
    }
    if (kids > 0) {
      t_kids = SearchParamsFormatter.getOccupationKids(kids, t);
      parts.push(t_kids);
    }
    if (parts.length === 1) {
      return parts[0];
    }
    return t('search_params_formatter.room_occupation.join', {
      adults: t_adults,
      kids: t_kids,
    });
  },
  getRoomOccupationDetailedSummary: (room: SearchParamsOccupationRoom, t: TFunction) : string => {
    return SearchParamsFormatter.formatRoomOccupation(room.adults, room.kids, t);
  },
  getOccupationDetailedSummary: (rooms: SearchParamsOccupationRoom[], t: TFunction) : string => {
    const roomsSummary = SearchParamsFormatter.getRoomsSummary(rooms, t);
    const adults = SearchParamsUtils.getTotalAdults(rooms);
    const kids = SearchParamsUtils.getTotalKids(rooms);
    return `${roomsSummary}: ${SearchParamsFormatter.formatRoomOccupation(adults, kids, t)}`;
  },
  formatRange: (range: SearchParamsDateRange, format : string = DATE_DEFAULT_FORMAT) : string  => {
    let datesDisplay = '';
    if (range.start) {
      datesDisplay += CalendarUtils.formatDate(range.start, format);
    }
    if (range.end) {
      datesDisplay += " - " + CalendarUtils.formatDate(range.end, format);
    }
    return datesDisplay;
  },
  getDateRangeNightsSummary: (dateRange: SearchParamsDateRange, t: TFunction) : string => {
    const count = SearchParamsUtils.getDateRangeNights(dateRange);
    return count === 1 ?
      t('search_params_formatter.nights_summary.label_singular') :
      t('search_params_formatter.nights_summary.label_plural', {
        count,
      })
    ;
  },
}

export default SearchParamsFormatter;
