import {PropsWithChildren} from "react";
import cn from "classnames";

export enum TdStyle {
  PRICING_NORMAL,
  PRICING_SELECTED,
  PRICING_UNAVAILABLE,
}

interface _Props {
  style?: TdStyle
  onClick?: VoidFunction
  className?: string
}

const _Td = (props: PropsWithChildren<_Props>) => (
  <td
    className={cn(
      'h-15 border border-gray-40 text-center',
      props.style === TdStyle.PRICING_NORMAL && 'bg-gray-10 text-xs select-none',
      props.style === TdStyle.PRICING_SELECTED && 'bg-gray-20 font-bold text-xs select-none',
      props.style === TdStyle.PRICING_UNAVAILABLE && 'bg-f8 text-brand-3-40 text-xs',
      props.style === TdStyle.PRICING_NORMAL && 'cursor-pointer hover:bg-brand-1-60 hover:font-bold',
      props.style === TdStyle.PRICING_SELECTED && 'cursor-pointer hover:bg-brand-1-60',
      props.className,
    )}
    onClick={props.onClick}
  >
    {props.children}
  </td>
);

export default _Td;
