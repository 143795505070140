import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import SectionGray from "@/src/ui/components/generic/SectionGray";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import {useCarouselBasic} from "@/src/ui/hooks/useCarouselBasic";
import {useTranslation} from "next-i18next";

interface _Props {
  images: T_MediaImage[]
}

const _Gallery = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    emblaRef,
    selectedIndex,
    scrollPrev,
    scrollNext,
  } = useCarouselBasic(props.images);

  return (
    <SectionGray>
      <MaxWidthWrapper>
        <div className="relative">
          <div className="absolute inset-0 max-w-screen-xl mx-auto p-5 flex items-center justify-between">
            <button
              className="relative z-10 transition-all transform hover:scale-110"
              onClick={scrollPrev}
            >
              <img src="/assets/icons/arrow-left-circle-transparent.svg" alt="" />
            </button>
            <button
              className="relative z-10 transition-all transform hover:scale-110"
              onClick={scrollNext}
            >
              <img src="/assets/icons/arrow-right-circle-transparent.svg" alt="" />
            </button>
          </div>
          <div className="pt-2.5 pb-5">
            <div
              ref={emblaRef}
              className="h-170 room-carousel overflow-hidden"
            >
              <div className="embla__container flex h-full">
                {props.images.map((img, index) => (
                  <div
                    key={img.id}
                    className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
                    style={{ flexBasis: "100%" }}
                  >
                    <MediaImageBgImg mediaImage={img} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-sm text-brand-2-90">
          {t('ModalHotelRoom._Gallery.footer', {
            count: selectedIndex + 1,
            total: props.images.length,
          })}
        </p>
      </MaxWidthWrapper>
    </SectionGray>
  );
}

export default _Gallery;
