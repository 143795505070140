import {T_ParagraphIconList} from "@/src/core/app/domain/models/paragraph/T_ParagraphIconList";
import Section from "@/src/ui/components/generic/Section/Section";
import AssetCmsIcon from "@/src/ui/components/generic/AssetCmsIcon";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";

const _BlockIconListMobileUp = (props: T_ParagraphIconList) => (
  <Section>
    <MaxWidthWrapper>
      <div className="sm:py-2.5">
        {props.field_section_name && (
          <h2 className="title-5 pb-7.5">{props.field_section_name}</h2>
        )}
        <ul className="flex flex-wrap gap-2 justify-between">
          {props.field_icon_texts_items.map((item, index) => (
            <li key={index} className="w-24 text-sm text-brand-2-90 ">
              <AssetCmsIcon className="h-10 w-10 object-contain pb-1" iconKey={item.icon_class} />
              <p>
                <strong>{item.text_1}</strong>
              </p>
              {item.text_2 && (
                <p>{item.text_2}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </MaxWidthWrapper>
  </Section>
);


export default _BlockIconListMobileUp;
