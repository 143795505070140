import {T_ParagraphIconList} from "@/src/core/app/domain/models/paragraph/T_ParagraphIconList";
import Section from "@/src/ui/components/generic/Section/Section";
import AssetCmsIcon from "@/src/ui/components/generic/AssetCmsIcon";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";

const _BlockIconListDesktopInline = (props: T_ParagraphIconList) => (
  <Section paddingY="py-7.5 bg-gray-10" backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      {props.field_section_name && (
        <h2 className="title-5 pb-7.5">{props.field_section_name}</h2>
      )}
      <ul className={`grid md:grid-cols-${props.field_columns} grid-cols-${props.field_columns} gap-5 text-sm text-brand-2-90`}>
        {props.field_icon_texts_items.map((item, index) => (
          <li key={index} className="flex items-center space-x-5">
            <AssetCmsIcon className="h-5 w-auto" iconKey={item.icon_class} />
            <p>{item.text_1}</p>
            {item.text_2 && (
              <p>{item.text_2}</p>
            )}
          </li>
        ))}
      </ul>
    </MaxWidthWrapper>
  </Section>
);


export default _BlockIconListDesktopInline;
