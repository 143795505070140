import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import {_Room} from "@/src/ui/components/portals/mobile/ModalSearchFormStepOccupation/room";
import {useTranslation} from "next-i18next";

export const _Form = () => {
  const { t } = useTranslation();
  const rooms = useSearchFormOccupationProvider(state => state.rooms);
  const roomAdd = useSearchFormOccupationProvider(state => state.roomAdd);

  return (
    <>
      <div className="flex-1 overflow-y-auto">
        <div className="px-7.5 text-brand-2">
          <ul className="divide-y divide-gray-40">
            {rooms.map((room, roomIndex) => (
              <_Room key={roomIndex} roomIndex={roomIndex} room={room} />
            ))}
          </ul>
          <button
            onClick={roomAdd}
            className="text-sm hover:text-brand-2-60 hover:underline pb-5"
          >
            + {t('ModalSearchFormStepOccupation._Form.button_add_room_label')}
          </button>
        </div>
      </div>
    </>
  );
}