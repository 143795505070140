import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useCheckoutSpecialTaxes} from "@/src/ui/hooks/useCheckoutSpecialTaxes";
import {useTranslation} from "next-i18next";
import ModalKey from "@/src/ui/@types/modal/ModalKey";

interface _Props {
  title: string
  price: string
}

const CheckoutClientFormSummaryPrice = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const toggleModal = useModalProvider(state => state.toggleModal);
  const checkoutSpecialTaxes = useCheckoutSpecialTaxes();

  return (
    <div className="flex-1 space-y-2.5 leading-normal">
      <h3 className="text-brand-2-90 font-bold uppercase">{props.title}</h3>
      <div className="space-y-0.5 text-xs">
        <p className="title-2 font-calistoga">{props.price}</p>
        <p className="text-brand-2-90">
          {t('CheckoutClientFormSummaryPrice._Index.taxes_included_label')} *
        </p>
        {checkoutSpecialTaxes.special_taxes && (
          <p
            className="underline cursor-pointer"
            onClick={() => toggleModal(ModalKey.CHECKOUT_SPECIAL_TAXES)}
          >
            {t('CheckoutClientFormSummaryPrice._Index.special_taxes_link_label')}
          </p>
        )}
      </div>
    </div>
  );
}

export default CheckoutClientFormSummaryPrice;
