export enum LayoutType {
  ERROR = 'LayoutError',
  GENERIC = 'LayoutGeneric',
  HOME = 'LayoutHome',
  CONVERSION = 'LayoutConversion',
  IFRAME = 'LayoutIframe',
}

export interface Layout {
  type: LayoutType,
}
