import {
  HotelCalendarAvailAndRatesOutputRoomRates
} from "@/src/core/app/domain/booking/@types/Output/HotelCalendarAvailAndRatesOutput";
import {
  CheckoutStepCalendarDataDateRanges
} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarDataDateRanges";
import CalendarUtils from "@/src/ui/utils/calendar";
import _Cell from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/cell";
import {CheckoutHotelCalendarDayOnClick} from "@/src/ui/@types/checkout/CheckoutHotelCalendarDayOnClick";
import _TotalPriceCell from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/total-price-cell";
import _RoomInfoCell from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/room-info-cell";

interface _Props {
  roomCode: string
  onClick: CheckoutHotelCalendarDayOnClick
  roomRates: HotelCalendarAvailAndRatesOutputRoomRates
  dateRanges: CheckoutStepCalendarDataDateRanges
}

const _Row = (props: _Props) => {
  const dateRangeIterator = CalendarUtils.getDateRangeIterator(props.dateRanges.viewDateRange);

  return (
    <tr>
      <_RoomInfoCell roomCode={props.roomCode} />
      {dateRangeIterator.map(dt => (
        <_Cell
          key={dt}
          dt={dt}
          roomRate={props.roomRates[dt]}
          isSelected={CalendarUtils.isDateInRange(dt, props.dateRanges.selectionDateRange)}
          onClick={props.onClick}
        />
      ))}
      <_TotalPriceCell
        roomCode={props.roomCode}
      />
    </tr>
  );
};

export default _Row;
