import BgImg from "@/src/ui/components/generic/BgImg";
import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";

interface MediaReferenceBgImgProps {
  mediaImage: T_MediaImage | null
  top?: boolean
  fallbackUrl?: string
  itemProp?: string
  alt?: string
  title?: string
}

const MediaImageBgImg = (props : MediaReferenceBgImgProps) : JSX.Element | null => {
  if (!props.mediaImage) {
    if (props.fallbackUrl) {
      return (
        <BgImg
          top={props.top}
          itemProp={props.itemProp}
          alt={props.alt}
          title={props.title}
          imgRaw={{
            "src": props.fallbackUrl,
          }}
        />
      )
    }
    return null;
  }
  return (
    <BgImg
      top={props.top}
      itemProp={props.itemProp}
      alt={props.alt}
      title={props.title}
      imgRaw={{
        "src": props.mediaImage.field_media_image.uri.url,
        "alt": props.mediaImage.meta.alt || undefined,
        "title": props.mediaImage.meta.title || undefined,
      }}
    />
  );
}

export default MediaImageBgImg;
