import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import CheckoutClientFormSummaryPrice from "@/src/ui/components/desktop/CheckoutClientFormSummaryPrice";
import FormTermsAndConditions from "@/src/ui/components/formik/FormTermsAndConditions";
import {useTranslation} from "next-i18next";

const _SummaryMobile = (): JSX.Element | null => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();
  const checkout = useCheckoutProvider(state => state);

  if (checkout.isInitiated === false) {
    return null;
  }

  const [payNow, payLater] = CheckoutUtils.getTotalAmountSplitted(checkout);

  return (
    <div className="px-5">
      <article className="p-5 bg-gray-20 space-y-5 text-sm text-brand-2">
        <FormTermsAndConditions
          name='termsAndConditions'
          label2={t('CheckoutCustomerInfoForm._Summary.terms_and_conditions_label') + ' *'}
        />
        <CheckoutClientFormSummaryPrice
          title={t('CheckoutCustomerInfoForm._Summary.hotel_payment_label')}
          price={UiCurrencyUtils.format(payLater, currencyConfig)}
        />
        <CheckoutClientFormSummaryPrice
          title={t('CheckoutCustomerInfoForm._Summary.now_payment_label')}
          price={UiCurrencyUtils.format(payNow, currencyConfig)}
        />
      </article>
    </div>
  );
}

export default _SummaryMobile;
