import {T_NodeBlogTeaser} from "@/src/core/app/domain/models/node/T_NodeBlogTeaser";
import Anchor from "@/src/ui/components/generic/Anchor";
import BlogUtils from "@/src/core/app/utils/blog";
import MediaImage from "@/src/ui/components/generic/MediaImage";

type Props = {
  blogPost: T_NodeBlogTeaser
}

const NodeBlogTeaserMobile = ({ blogPost } : Props): JSX.Element => (
  <Anchor path={blogPost.path}>
    <article className="border border-gray-40">
      {blogPost.ui_cpt_field_blog_post_media_mobile.length > 0 && (
        <MediaImage
          className="w-full"
          mediaImage={blogPost.ui_cpt_field_blog_post_media_mobile[0]}
        />
      )}
      {blogPost.ui_cpt_field_blog_post_media_mobile.length == 0 && (
        <MediaImage
          className="w-full"
          mediaImage={blogPost.field_blog_post_media_square}
        />
      )}
      <div className="bg-gray-10 p-5">
        <h3 className="title-4-mobile pb-1">
          {blogPost.title}
        </h3>
        <p className="text-brand-2-60 text-xs">
          {BlogUtils.getDateCreated(blogPost.created)}
          {blogPost.taxonomy_term && (
            <>
              <span>{' - '}</span>
              <span className='uppercase'>
                {blogPost.taxonomy_term.title}
              </span>
            </>
          )}
        </p>
      </div>
    </article>
  </Anchor>
);

export default NodeBlogTeaserMobile;
