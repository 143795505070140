import {SearchFormState} from "@/src/ui/view_models/search-form.state";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {ModalState} from "@/src/ui/view_models/modal.state";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import BrowserUtils from "@/src/ui/utils/browser";


const SearchFormUtils = {
  submit: (
    searchForm: SearchFormState,
    modalState: ModalState,
    roomCode?: string
  ) => {
    SearchFormUtils.showLoader(modalState);
    const ok = searchForm.onSubmit(roomCode);
    if (!ok) {
      modalState.closeModal();
    }
  },
  showLoader: (modalState: ModalState) => {
    modalState.toggleModal(ModalKey.LOADER, {
      message: 'global.generic_loader.message'
    });
  },
  onClear: (modalState: ModalState) => {
    SearchFormUtils.showLoader(modalState);
    SearchParamsUtils.removeCookie();
    BrowserUtils.reload();
  },
}

export default SearchFormUtils;
