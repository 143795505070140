import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";

export enum CMS_MenuAccountItemStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum CMS_MenuAccountItemUserTmsClientId {
  SKIP = 'skip',
  MANDATORY_ASSIGNED = 'mandatory_assigned',
  MANDATORY_NULL = 'mandatory_null',
}

export interface CMS_MenuAccountItem extends CMS_MenuItem {
  field_menu_account_style: CMS_MenuAccountItemStyle
  field_ma_user_tms_client_id: CMS_MenuAccountItemUserTmsClientId
}