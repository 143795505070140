import {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import HotelSectionDesktop from "@/src/ui/components/routes/Route_HotelSection/desktop";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import HotelSearchParamsSummary from "src/ui/components/hotel/HotelSearchParamsSummary";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";

const _Header = () => {
  const state = useCheckoutProvider(state => state);

  if (SearchParamsUtils.isEmpty(state.searchParams)) {
    return null;
  }

  return (
    <HotelSearchParamsSummary searchParams={state.searchParams as SearchParams} />
  );
}

const HotelSectionRoomsDesktop = (props: RouteProps<T_HotelSection>) => (
  <HotelSectionDesktop
    {...props}
    header={(
      <_Header />
    )}
  />
)

export default HotelSectionRoomsDesktop;
