import {ReactNode} from "react";
import {useUserProvider} from "@/src/ui/providers/user.provider";

interface _Props {
  anonymousContent: ReactNode
  content: ReactNode
}

const UserRestrictedContentSeoFriendly = (props: _Props) => {
  const userState = useUserProvider(state => state);

  if (userState.isAnonymousGuaranteed()) {
    return (
      <>
        {props.anonymousContent}
      </>
    );
  }

  return (
    <>{props.content}</>
  );
};


export default UserRestrictedContentSeoFriendly;
