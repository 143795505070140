import FormInputText from "@/src/ui/components/formik/FormInputText";
import Button from "@/src/ui/components/generic/Button";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import NarrowFormBox from "@/src/ui/components/generic/NarrowFormBox";
import FormUtils from "@/src/ui/utils/form";
import CountryUtils from "@/src/core/app/utils/country";
import FormInputSelect from "src/ui/components/formik/FormInputSelect";
import LanguageUtils from "@/src/ui/utils/language";
import FormInputRadioGroup from "src/ui/components/formik/FormInputRadioGroup";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {UserRegisterRouteProps} from "@/src/core/app/domain/route/@types/UserRegisterRouteProps";
import {UI_LayoutConversion} from "@/src/ui/@types/layout/UI_LayoutConversion";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UserRegisterStepProps} from "@/src/ui/components/routes/Route_UserRegister/steps/UserRegisterStep";
import UserUtils from "@/src/core/app/utils/user";
import _AlreadyRegistered from "@/src/ui/components/routes/Route_UserRegister/steps/_AlreadyRegistered";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";

const UserRegisterStep2 = (props: UserRegisterStepProps) => {
  const { t } = useTranslation();
  const routeData = useRouteData<UserRegisterRouteProps>();
  const layoutData = useLayoutData<UI_LayoutConversion>();
  return (
    <NarrowFormBox title={t('Route_UserRegister.UserRegisterStep2.title')}>
      <div className="space-y-3.75">
        <FormInputText
          name='firstName'
          label={t('Route_UserRegister.UserRegisterStep2.first_name_label') + " *"}
          validate={FormUtils.validateIsRequired}
        />
        <FormInputText
          name='lastName'
          label={t('Route_UserRegister.UserRegisterStep2.last_name_label') + " *"}
          validate={FormUtils.validateIsRequired}
        />
        <FormInputRadioGroup
          name="gender"
          label={t('Route_UserRegister.UserRegisterStep2.gender_label') + " *"}
          validate={FormUtils.validateIsRequired}
          options={I18nUtils.translateFormInputSelectOptions(
            UserUtils.getGenderOptions(), t
          )}
        />
        <div>
          <FormInputText
            name='phoneNumber'
            label={t('Route_UserRegister.UserRegisterStep2.phone_number_label') + " *"}
            validate={FormUtils.validateIsRequired}
          />
          <p className="text-brand-3 text-xs pt-1.5">
            {t('Route_UserRegister.UserRegisterStep2.phone_number_info')}
          </p>
        </div>
        <FormInputSelect
          name='country'
          label={t('Route_UserRegister.UserRegisterStep2.country_label') + " *"}
          emptyOption={'- ' + t('Route_UserRegister.UserRegisterStep2.country_empty_option')}
          options={CountryUtils.getSelectOptions(routeData.data.countries)}
          validate={FormUtils.validateIsRequired}
        />
        <FormInputSelect
          name='language'
          label={t('Route_UserRegister.UserRegisterStep2.language_label') + " *"}
          emptyOption={'- ' + t('Route_UserRegister.UserRegisterStep2.language_empty_option')}
          options={LanguageUtils.getSelectOptions(layoutData.languages)}
          validate={FormUtils.validateIsRequired}
        />
      </div>
      <div>
        <Button
          text={t('Route_UserRegister.common.next_step_button_label')}
          bold
          onClick={props.formik.handleSubmit}
          isActive={!props.formik.isSubmitting}
        />
      </div>
      <VerisignImg/>
      <_AlreadyRegistered />
    </NarrowFormBox>
  );
}

export default UserRegisterStep2;
