import MaxWidthWrapper from "../MaxWidthWrapper";
import Section from "../Section/Section";
import {T_PageHeader} from "@/src/core/app/domain/models/page/T_PageHeader";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

interface _Props {
  header: T_PageHeader
}

const PageHeaderDesktop = (props: _Props): JSX.Element => {
  const header = props.header;
  return (
    <div className="block relative">
      <MediaImageBgImg mediaImage={header.image} />
      <Section paddingY="">
        <MaxWidthWrapper>
          <hgroup className="relative h-83.5 space-y-5 flex flex-col justify-center">
            <p className="title-5-white">
              {header.title}
            </p>
            <p className="title-1-display font-cognito">
              {header.subtitle}
            </p>
            {header.body && (
              <WrapperDiv
                className='text-big-white max-w-3xl'
                dangerouslySetInnerHTML={{__html: header.body.processed}}
              />
            )}
          </hgroup>
        </MaxWidthWrapper>
      </Section>
    </div>
  );
}

export default PageHeaderDesktop;
