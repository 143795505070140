export enum CMS_ParagraphType {
  BANNER_SLIDER = 'banner_slider',
  BANNER_SLIDER_SLIDE = 'banner_slider_slide',
  ELBA_FIDELITY = 'elba_fidelity',
  OFFERS_CATEGORY_PROMOTED = 'offers_category_promoted',
  RESIDENTS_LIST = 'residents_list',
  BLOG_LIST = 'blog_list',
  EXPERIENCES_LIST = 'experiences_list',
  MAP_INFO_SLIDER = 'map_info_slider',
  MAP_INFO_SLIDER_ITEM = 'map_info_slider_item',
  HOTEL_ROOM_LIST = 'hotel_room_list',
  LOGIN_FORM = 'login_form',
  INFO_SQUARED = 'info_squared',
  MANAGE_BOOKING_FORM = 'manage_booking_form',
  MY_BOOKING_LIST = 'my_booking_list',
  MENU_ACCOUNT = 'menu_account',
  USER_EDIT_FORM = 'user_edit_form',
  USER_PASSWORD_CHANGE_FORM = 'user_password_change_form',
  USER_TMS_INFO = 'user_tms_info',
  USER_TMS_ASSIGN_FORM = 'user_tms_assign_form',
  INFO_BLOCK = 'info_block',
  ICON_LIST = 'icon_list',
  HOTEL_CONTACT = 'hotel_contact',
  HOTEL_LIST_FILTRABLE = 'hotel_list_filtrable',
  HOTELS_MAP = 'hotels_map',
  HOTEL_ROOMS_PROMOTED = 'hotel_rooms_promoted',
  HOTEL_LIST = 'hotel_list',
  HOTEL_LIST_BY_DESTINATION = 'hotel_list_by_destination',
  HOTEL_LIST_BY_EXPERIENCE = 'hotel_list_by_experience',
  HOTEL_LIST_BY_TAG = 'hotel_list_by_tag',
  MULTI_GALLERY = 'multi_gallery',
  SIMPLE_GALLERY = 'simple_gallery',
  INFO_CARDS = 'info_cards',
  ALERT_BLOCK = 'alert_block',
  LINKS_IMAGE_LIST = 'links_image_list',
  INFO_TEXT_IMAGE = 'info_text_image',
  HTML_BLOCK = 'html_block',
  LINKS_BLOCK = 'links_block',
  LOUNGES_LIST = 'lounges_list',
  DESTINATION_LIST = 'destination_list',
  HOTELS_BY_RESIDENT = 'hotels_by_resident',
  DESTINATION_BY_RESIDENT = 'destination_by_resident',
  BLOG_SECTION = 'blog_section',
  BLOG_PROMOTED = 'blog_promoted',
  JOB_OFFER_INFO = 'job_offer_info',
  JOB_OFFER_FORM = 'job_offer_form',
  EVENT_FORM = 'event_form',
  CONTACT_GENERAL_INFO = 'contact_general_info',
  CONTACT_FORM = 'contact_form',
  HOTELS_OFFERS_PROMOTED = 'hotels_offers_promoted',
  HOTEL_OFFERS_SEARCH_FORM = 'hotel_offers_search_form',
  HOTEL_OFFERS_RESULT = 'hotel_offers_result',
  HOTEL_OFFERS = 'hotel_offers',
}