import UiBlockUtils from "@/src/ui/utils/block";
import {CMS_ParagraphType} from "@/src/core/app/domain/cms/@types/entities/CMS_ParagraphType";
import {T_NodePage2Cols} from "@/src/core/app/domain/models/node/T_NodePage2Cols";

const UiPage2colsUtils = {
  isManageBookingFormPresent: (routeData: T_NodePage2Cols) => {
    return UiBlockUtils.isBlockInList(
      routeData.field_blocks_margin,
      CMS_ParagraphType.MANAGE_BOOKING_FORM
    );
  },
};

export default UiPage2colsUtils;
