import type {RouteProps} from "@/src/ui/@types/RouteProps";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_NodeBasicPage} from "@/src/core/app/domain/models/node/T_NodeBasicPage";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import cn from "classnames";
import UiMediaUtils from "@/src/ui/utils/media";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_BasicPage = (props: RouteProps<T_NodeBasicPage>) : JSX.Element => {
  const device = useDevice();
  const node = props.routeData.data;
  const image = device.isMobile ? node.ui_cpt_field_basic_page_image_mobile : node.ui_cpt_field_basic_page_image;
  return (
    <>
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <Section className={cn(
        device.isDesktop && 'max-w-190 mx-auto pt-10',
        device.isMobile && 'pt-7.5'
      )}>
        <MaxWidthWrapper>
          <h1
            className={cn(
              'text-2xl font-calistoga',
              device.isDesktop && 'pb-7.5',
              device.isMobile && 'pb-5',
            )}>
            {node.title}
          </h1>
          {image && (
            <div className={cn(
              'text-2xl',
              device.isDesktop && 'pb-7.5',
              device.isMobile && 'pb-5',
            )}>
              <img
                className={cn("w-full")}
                {...UiMediaUtils.getImgPropsFromMediaImage(image)}
              />
            </div>
          )}
          <WrapperDiv
            size="medium"
            className="py-2.5 text-brand-2 Cms-Formatted"
            dangerouslySetInnerHTML={{__html: node.field_body.processed}}
          />
        </MaxWidthWrapper>
      </Section>
    </>
  )
}


export default Route_BasicPage;
