import {T_HotelTree} from "@/src/core/app/domain/models/hotel-tree/T_HotelTree";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import {T_HotelTreeDestination} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeDestination";

const HotelTreeUtils = {
  getHotel: (code: string, tree: T_HotelTree) : T_HotelTreeHotel => {
    const hotels = HotelTreeUtils.getHotels(tree);
    const hotel = hotels.find(item => item.code === code);
    if (!hotel) {
      throw new Error('Hotel not found in tree');
    }
    return hotel;
  },
  getHotelById: (id: string, tree: T_HotelTree) : T_HotelTreeHotel => {
    const hotels = HotelTreeUtils.getHotels(tree);
    const hotel = hotels.find(item => item.id === id);
    if (!hotel) {
      throw new Error('Hotel not found in tree');
    }
    return hotel;
  },
  getHotelByDestination: (id: string, tree: T_HotelTree) : T_HotelTreeHotel => {
    const hotels = HotelTreeUtils.getHotels(tree);
    const hotel = hotels.find(item => item.destination === id);
    if (!hotel) {
      throw new Error('Hotel not found in tree');
    }
    return hotel;
  },
  getHotelByTmsCode: (tmsCode: string, tree: T_HotelTree) : T_HotelTreeHotel => {
    const hotels = HotelTreeUtils.getHotels(tree);
    const hotel = hotels.find(item => item.tms_code === tmsCode);
    if (!hotel) {
      throw new Error('Hotel not found in tree');
    }
    return hotel;
  },
  getHotels: (tree: T_HotelTree) : T_HotelTreeHotel[] => {
    return tree.regions.reduce<T_HotelTreeHotel[]>((items, item) => {
      return item.destinations.reduce<T_HotelTreeHotel[]>((items, item) => {
        item.hotels.forEach(h => items.push(h));
        return items;
      }, items);
    }, []);
  },
  getHotelsByIds: (tree: T_HotelTree, ids: string[]) : T_HotelTreeHotel[] => {
    return HotelTreeUtils.getHotels(tree).filter(item => ids.indexOf(item.id) !== -1);
  },
  getSelectOptions: (hotels: T_HotelTreeHotel[]) : FormInputSelectOption[] => {
    return hotels.map(hotel => ({
      value: hotel.code,
      label: hotel.title,
    }));
  },
  getDestinations: (tree: T_HotelTree) : T_HotelTreeDestination[] => {
    return tree.regions.reduce<T_HotelTreeDestination[]>((items, item) => {
      item.destinations.forEach(d => items.push(d));
      return items;
    }, []);
  },
  getDestination: (id: string, tree: T_HotelTree) => {
    const destinations = HotelTreeUtils.getDestinations(tree);
    const destination = destinations.find(item => item.id === id);
    if (!destination) {
      throw new Error('Destination not found in tree');
    }
    return destination;
  }
}

export default HotelTreeUtils;
