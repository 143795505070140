export enum MakeBookingOutputReservationStatus {
  OK = 'OK',
}

interface MakeBookingOutputReservation {
  id: string
  uuid: string
  dingus_id: string
  status: MakeBookingOutputReservationStatus
}

export enum MakeBookingOutputPaymentMethodType {
  TAZZY = 'TAZZY',
  BAP = 'BAP',
}

export enum MakeBookingOutputPaymentMethodBapMethod {
  TOKEN_AND_PAYMENT = 'TOKEN_AND_PAYMENT',
  TOKEN = 'TOKEN',
}

interface MakeBookingOutputPaymentMethodBapData {
  url: string
}

interface MakeBookingOutputPaymentMethod {
  type: MakeBookingOutputPaymentMethodType
  bapMethod?: MakeBookingOutputPaymentMethodBapMethod
  bapData?: MakeBookingOutputPaymentMethodBapData
}

export interface MakeBookingOutput {
  reservation: MakeBookingOutputReservation
  payment_method: MakeBookingOutputPaymentMethod
  user_logged_in: boolean
}