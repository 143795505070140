import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import {_Room} from "@/src/ui/components/portals/desktop/ModalSearchFormStepOccupation/room";
import {useTranslation} from "next-i18next";

export const _Form = (): JSX.Element | null => {
  const {t} = useTranslation();
  const rooms = useSearchFormOccupationProvider(state => state.rooms);
  const roomAdd = useSearchFormOccupationProvider(state => state.roomAdd);

  return (
    <div className="pt-5 px-5 space-y-5 text-brand-2 h-80 overflow-y-auto">
      {rooms.map((room, roomIndex) => (
        <_Room key={roomIndex} roomIndex={roomIndex} room={room} />
      ))}
      <button
        onClick={roomAdd}
        className="text-sm hover:text-brand-2-60 hover:underline pb-5"
      >
        + {t('ModalSearchFormStepOccupation._Form.button_add_room_label')}
      </button>
    </div>
  );
}
