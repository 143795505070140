import {
  SearchFormRoomOccupationWidgetProps
} from "@/src/ui/components/search-form/SearchFormRoomOccupationWidget/types";
import {useTranslation} from "next-i18next";

const _Mobile = (props: SearchFormRoomOccupationWidgetProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex">
          <img className="w-5 h-auto" src="/assets/icons/person-brand-2.svg" />
          <div className="text-sm w-32 pl-2">{t('SearchFormRoomOccupationWidget._Index.adults_label')}</div>
        </div>
        <div className="w-36 pl-1 flex justify-between items-center">
          <button onClick={props.onSubAdult}>
            <img src="/assets/icons/minus-circle.svg" />
          </button>
          <div className="w-14 mx-0.5 text-sm text-center">
            {props.occupation.adults}
          </div>
          <button onClick={props.onAddAdult}>
            <img src="/assets/icons/plus-circle.svg" />
          </button>
        </div>
      </div>
      {props.kidsMaxAge !== 0 && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center">
            <img className="w-5 h-auto" src="/assets/icons/person-brand-2.svg" />
            <div className="text-sm w-32 pl-2 flex flex-col">
              <span>{t('SearchFormRoomOccupationWidget._Index.kids_label')}</span>
              <span className="text-xs text-brand-2-60">{
                t('SearchFormRoomOccupationWidget._Index.kids_max_age_label', {
                  amount: props.kidsMaxAge
                })}
              </span>
            </div>
          </div>
          <div className="w-36 pl-1 flex justify-between items-center">
            <button onClick={props.onSubKid}>
              <img src="/assets/icons/minus-circle.svg" />
            </button>
            <div className="text-sm">
              {props.occupation.kids}
            </div>
            <button onClick={props.onAddKid}>
              <img src="/assets/icons/plus-circle.svg" />
            </button>
          </div>
        </div>
      )};
    </>
  )
}

export default _Mobile;
