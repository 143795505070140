import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import HotelTeaserMobile from "@/src/ui/components/hotel/HotelTeaser/mobile";
import {CMS_ParagraphHotelList_LinkSection} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";

interface _Props {
  hotelTeasers: T_HotelTeaser[]
  linkSection?: CMS_ParagraphHotelList_LinkSection
}

const HotelTeaserListMobile = (props: _Props): JSX.Element => (
  <ul className="space-y-5">
    {props.hotelTeasers.map((item) => (
      <li key={item.id}>
        <HotelTeaserMobile
          hotelTeaser={item}
          linkSection={props.linkSection}
        />
      </li>
    ))}
  </ul>
);

export default HotelTeaserListMobile;
