import {T_HotelRoomPromoted} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomPromoted";
import Card from "@/src/ui/components/generic/Card";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import _Features from "@/src/ui/components/blocks/BlockHotelRoomsPromoted/mobile/_Features";

const _Room = (props: T_HotelRoomPromoted): JSX.Element => {
  const modalState = useModalProvider(state => state);

  const toggleRoomPopup = (room: T_HotelRoomBasic) => {
    modalState.toggleModal(ModalKey.HOTEL_ROOM, {
      room,
    })
  };

  return(
    <Card mediaImage={props.field_room_media_square}>
      <div className="opacity-100 hover-show-child-opacity-0 transition-all absolute inset-0 bg-img-blue-shade-card" />
      <div className="relative" onClick={() => toggleRoomPopup(props)}>
        <h3 className="title-4-mobile-white pb-2 font-calistoga">
          {props.title}
        </h3>
        <_Features {...props} />
      </div>
    </Card>
  );
}

export default _Room;
