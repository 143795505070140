import Anchor from "@/src/ui/components/generic/Anchor";
import Button from "@/src/ui/components/generic/Button";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import BookingManageUtils from "@/src/core/app/utils/booking-manage";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import BookingManageRoom from "@/src/ui/components/booking-manage/BookingManageRoom";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {ModalReservationCancelData} from "@/src/ui/@types/modal/ModalReservationCancelData";
import {CMS_ReservationStatus} from "@/src/core/app/domain/cms/@types/reservation/CMS_ReservationStatus";
import {useTranslation} from "next-i18next";

interface _Props extends ModalReservationCancelData {
}

const BookingManage = (props: _Props) => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const currencyConfig = useCurrencyConfig();
  const layoutData = useLayoutData<UI_LayoutGeneric>();

  const reservationManageData = props.reservationManageData;
  const hotel = HotelTreeUtils.getHotel(reservationManageData.hotel.code, layoutData.hotelTree);
  const dateRange = BookingManageUtils.getDateRange(reservationManageData);

  const onCancelClick = () => {
    modalState.toggleModal(ModalKey.RESERVATION_CANCEL, props);
  };

  const isCancelled = reservationManageData.status === CMS_ReservationStatus.CANCELLED;

  return (
    <TitledBox title={hotel.title}>
      <div className="py-2.5 sm:px-5 space-y-3.75">
        <div className="px-5 sm:px-0 pt-1 sm:pt-0">
          <p className="text-xs text-brand-2">
            <time>{SearchParamsFormatter.formatRange(dateRange)}</time>
            <span>{" "}</span>
            <span>({SearchParamsFormatter.getDateRangeNightsSummary(dateRange, t)})</span>
          </p>
        </div>
        <div className="px-5 sm:px-0 sm:flex space-y-3.5 sm:space-y-0 justify-between items-baseline">
          <p>
          <span className="text-xs font-bold text-brand-2-90">
            {t('BookingManage._Index.reference_label')}:&nbsp;
          </span>
            <em className="text-big not-italic">#{reservationManageData.id}</em>
          </p>
          <p>
          <span className="text-xs font-bold text-brand-2-90 uppercase">
            {t('BookingManage._Index.total_label')}:&nbsp;
          </span>
            <em className="text-big not-italic">
              {UiCurrencyUtils.format(reservationManageData.total, currencyConfig)}
            </em>
            <span className="text-sm text-brand-2"> {t('BookingManage._Index.taxes_included_label')}</span>
          </p>
        </div>
        <ul className="border-t border-b sm:border border-gray-40 divide-y divide-gray-40">
          {reservationManageData.rooms.map((room, index) => (
            <BookingManageRoom key={index} room={room} />
          ))}
        </ul>
        <div className="px-5 sm:px-0 sm:flex space-y-2.5 sm:space-y-0 justify-between items-center">
          <p className="text-xs text-brand-2-90">
            <Anchor className="underline" href={layoutData.sitePageUrls.contact_page_url}>
              {t('BookingManage._Index.contact_us_link_label')}
            </Anchor>
          </p>
          {reservationManageData.is_cancellable && (
            <div>
              <Button text={t('BookingManage._Index.cancel_button_label')} bold onClick={onCancelClick} />
            </div>
          )}
          {!isCancelled && !BookingManageUtils.isRefundable(reservationManageData) && (
            <p className="text-xs text-orange">{t('BookingManage._Index.not_refundable_label')}</p>
          )}
          {isCancelled && (
            <p className="text-xs text-red">{t('BookingManage._Index.cancelled_label')}</p>
          )}
        </div>
      </div>
    </TitledBox>
  )
};

export default BookingManage;
