import {T_ParagraphExperiencesList} from "@/src/core/app/domain/models/paragraph/T_ParagraphExperiencesList";
import NodeExperienceTeaserMobile from "@/src/ui/components/experience/NodeExperienceTeaser/mobile";
import Section from "@/src/ui/components/generic/Section/Section";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";


const BlockExperiencesListMobile = (props: T_ParagraphExperiencesList): JSX.Element => (
  <Section paddingY={"pt-5 pb-12"} backgroundColor={props.field_background_color}>
    <div className="space-y-2.5 text-brand-2">
      <TitleWithTag
        className="text-xl font-calistoga"
        field={props.field_title_with_tag}
      />
      <Paragraph size="medium" className="text-brand-2-90">
        {props.field_subtitle}
      </Paragraph>
    </div>
    <div className="py-5">
      <ul className="space-y-5">
        {props.field_experiences.map((item, index) => (
          <li key={index}>
            <NodeExperienceTeaserMobile experience={item} />
          </li>
        ))}
      </ul>
    </div>
    {props.field_one_link && (
      <ArrowLink href={props.field_one_link.url}>
        {props.field_one_link.title}
      </ArrowLink>
    )}
  </Section>
);

export default BlockExperiencesListMobile;
