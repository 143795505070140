const _userRegisterValidation = (values: any) => {
  const errors : Record<string, string> = {};

  if (values.userPassword1 && values.userPassword2) {
    if (values.userPassword1 !== values.userPassword2) {
      errors.userPassword1 = 'La contraseña no coincide.';
      errors.userPassword2 = 'La contraseña no coincide.';
    }
  }
  return errors;
};

export default _userRegisterValidation;
