import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import Section from "@/src/ui/components/generic/Section/Section";
import OfferDetail from "@/src/ui/components/offer/OfferDetail";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";
import _JsonLd from "@/src/ui/components/routes/Route_OfferDetail/generic/_JsonLd";

const Route_OfferDetailMobile = (props: RouteProps<T_OfferDetail>) : JSX.Element => {
  const item = props.routeData.data;
  return (
    <>
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <Section paddingY="py-7.5">
        <h1 className="title-3-mobile mb-2.5">
          {item.title}
        </h1>
        <div className="flex-1">
          <OfferDetail item={item} />
        </div>
        <_JsonLd offerDetail={props.routeData.data} />
      </Section>
    </>
  );
}

export default Route_OfferDetailMobile;
