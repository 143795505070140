import {
  T_ParagraphIconList,
  T_ParagraphIconList_ListDisplay
} from "@/src/core/app/domain/models/paragraph/T_ParagraphIconList";
import _BlockIconListDesktopUp from "@/src/ui/components/blocks/BlockIconList/desktop/by-list-display/up";
import _BlockIconListDesktopInline from "@/src/ui/components/blocks/BlockIconList/desktop/by-list-display/inline";

const BlockIconListDesktop = (props: T_ParagraphIconList) => {
  if (props.field_list_display === T_ParagraphIconList_ListDisplay.UP) {
    return (
      <_BlockIconListDesktopUp {...props} />
    );
  }
  return (
    <_BlockIconListDesktopInline {...props} />
  );
};

export default BlockIconListDesktop;