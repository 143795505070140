import {UserRegisterFormValues} from "@/src/core/app/domain/forms/UserRegisterFormValues";
import {UserRegisterState} from "@/src/ui/view_models/user-register.state";
import _termsValidation from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/validation/common";
import _passwordValidation
  from "@/src/ui/components/forms/UserRegisterForm/steps/step1/validation/password";
import {FormikHelpers} from "formik/dist/types";
import {ModalState} from "@/src/ui/view_models/modal.state";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import {UserRegisterFormStep} from "@/src/ui/components/forms/UserRegisterForm/steps/step";
import {UserRegisterStep} from "@/src/core/app/domain/models/user-register/user-register-step";
import {TFunction} from "react-i18next";
import {T_SitePageUrls} from "@/src/core/app/domain/models/site/T_SitePageUrls";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

const UserRegisterStep1Form : UserRegisterFormStep = {
  validations: [_termsValidation, _passwordValidation],
  onSubmit: async (
    appInfo: AppInfo,
    values: UserRegisterFormValues,
    formikHelpers: FormikHelpers<UserRegisterFormValues>,
    t: TFunction,
    modal: ModalState,
    state: UserRegisterState,
    countries: T_Country[],
    sitePageUrls: T_SitePageUrls,
  ) => {
    state.setData({
      email: values.email,
      password: values.password1,
    });
    state.setStep(UserRegisterStep.STEP_2);
  }
}

export default UserRegisterStep1Form;
