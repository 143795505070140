import LanguageDropdown from "@/src/ui/components/generic/LanguageDropdown";
import CurrencyDropdown from "@/src/ui/components/generic/CurrencyDropdown";

const LayoutConversionNavigationDesktop = (): JSX.Element => (
  <nav className="flex items-center space-x-7.5">
    <CurrencyDropdown color="brand-2" spaceBetween />
    <div className="flex items-center space-x-2.5">
      <img className="h-4 w-auto" src="/assets/icons/globe.png" alt="" />
      <LanguageDropdown color="brand-2" />
    </div>
  </nav>
);

export default LayoutConversionNavigationDesktop;
