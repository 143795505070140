import Cookies from "js-cookie";
import BrowserUtils from "@/src/ui/utils/browser";

export const CURRENCY_COOKIE_NAME = 'he_currency';
export const CURRENCY_COOKIE_DURATION = 365;

const CurrencyUtils = {
  getCurrencyCodeFromCookie: () : string | undefined => {
    if (!BrowserUtils.isBrowser()) {
      return undefined;
    }
    return Cookies.get(CURRENCY_COOKIE_NAME);
  },

  setCurrencyCodeCookie: (code: string) => {
    Cookies.set(CURRENCY_COOKIE_NAME, code, { expires: CURRENCY_COOKIE_DURATION });
  },
};

export default CurrencyUtils;
