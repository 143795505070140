import HotelLinkWithRating, {OnHotelSelected} from "../HotelLinkWithRating";
import {T_HotelTreeRegion} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeRegion";

interface HotelListProps {
  onHotelSelected?: OnHotelSelected
  region: T_HotelTreeRegion
}

const HotelLinkList = ({
  region,
  onHotelSelected,
}: HotelListProps): JSX.Element => (
  <div>
    <p className="text-lg font-bold text-brand-2 flex items-center mb-5">{region.title}</p>
    {region.destinations.map(destination => (
      <div key={destination.id} className='mb-5'>
        <p className="text-sm font-bold text-brand-2 flex items-center mb-1">{destination.destinationName}</p>
        <ul className="space-y-4">
        {destination.hotels.map((hotel) => (
          <li key={hotel.id}>
            <HotelLinkWithRating hotel={hotel} onHotelSelected={onHotelSelected}/>
          </li>
        ))}
        </ul>
      </div>
    ))}
  </div>
);

export default HotelLinkList;
