import BlockUserTmsInfoHeader from "@/src/ui/components/blocks/BlockUserTmsInfo/generic/header";
import {PropsWithChildren} from "react";
import {T_ParagraphUserTmsInfo} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserTmsInfo";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

type _Props = PropsWithChildren<{
  paragraph: T_ParagraphUserTmsInfo
}>

const _Wrapper = (props: _Props) => {
  const device = useDevice();

  return (
    <div className={cn([
      device.isDesktop && "space-y-7.5",
      device.isMobile && "space-y-5"
    ])}>
      <TitleWithTag
        className={cn([
          device.isDesktop && "title-4 font-calistoga",
          device.isMobile && "pt-2.5 title-4 font-calistoga"
        ])}
        field={props.paragraph.field_title_with_tag}
      />
      {props.paragraph.field_uti_header && (
        <BlockUserTmsInfoHeader content={props.paragraph.field_uti_header} />
      )}
      {props.children}
    </div>
  );
}

export default _Wrapper;
