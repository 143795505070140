import {T_ParagraphHotelList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelList";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import HotelTeaserListDesktop from "@/src/ui/components/hotel/HotelTeaserList/desktop";
import Section from "@/src/ui/components/generic/Section/Section";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const _List = (props: T_ParagraphHotelList) => (
  <Section paddingY="pt-7.5 pb-15" backgroundColor={props.field_background_color} >
    <MaxWidthWrapper>
      <TitleWithTag
        className="title-4 pb-7.5 font-calistoga"
        field={props.field_title_with_tag}
        suffix={`(${props.hotelTeasers.length})`}
      />
      <HotelTeaserListDesktop
        hotelTeasers={props.hotelTeasers}
        linkSection={props.field_hotel_list_link_section}
      />
    </MaxWidthWrapper>
  </Section>
);

export default _List;
