import InputWrapper, { InputWrapperProps } from "../../form/InputWrapper";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {MouseEventHandler} from "react";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";

interface ButtonDateRangeSummaryProps {
  className?: string
  onClick?: MouseEventHandler
  color?: InputWrapperProps["color"]
  hasError?: InputWrapperProps["hasError"]
  size?: 'normal' | 'small' | 'tiny'
}

const _Empty = (props: ButtonDateRangeSummaryProps) : JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={props.className} onClick={props.onClick}>
      <InputWrapper
        className='space-x-1.5 overflow-hidden flex items-stretch'
        hasError={props.hasError}
        color={props.color}
      >
        <img src='/assets/icons/calendar.svg' alt="" />
        <span className="bg-transparent flex-1 flex items-center text-sm text-brand-2-40 leading-none">
        {t('ButtonDateRangeSummary._Empty.text')}
      </span>
      </InputWrapper>
    </div>
  )
}

const ButtonDateRangeSummary = ({
  size = 'normal',
  ...props
} : ButtonDateRangeSummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const dateRange = useSearchFormProvider(state => state.dateRange);

  if (SearchParamsUtils.isDateRangeEmpty(dateRange)) {
    return (
      <_Empty {...props} />
    );
  }

  if (size === 'tiny') {
    return (
      <div className={props.className} onClick={props.onClick}>
        <InputWrapper
          color={props.color}
          hasError={props.hasError}
          className="text-brand-2 cursor-pointer flex items-center"
        >
          <div className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">
            <img className="h-full w-auto" src="/assets/icons/calendar.svg" alt="" />
            <div className="text-sm">
              {SearchParamsFormatter.formatRange(dateRange, 'dd/MM')}
            </div>
          </div>
          {/*<div className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">*/}
          {/*  <img className="h-full w-auto" src="/assets/icons/moon.svg" alt="" />*/}
          {/*  <span>{SearchFormUtils.getDateRangeNightsSummary(dateRange)}</span>*/}
          {/*</div>*/}
        </InputWrapper>
      </div>
    );
  }

  return (
    <div className={props.className} onClick={props.onClick}>
       <InputWrapper
         color={props.color}
         hasError={props.hasError}
         className="text-brand-2 cursor-pointer flex items-center"
       >
         <div className="flex-1 flex space-x-4 overflow-hidden">
           <div className="flex flex-grow items-center space-x-2 leading-none h-3.5">
             <img className="h-full w-auto" src="/assets/icons/calendar.svg" alt="" />
             <span className="text-sm">{SearchParamsFormatter.formatRange(dateRange)}</span>
           </div>
           <div className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">
             <img className="h-full w-auto" src="/assets/icons/moon.svg" alt="" />
             <span className="text-sm text-brand-2-40">
               {
                size === 'normal' ?
                  SearchParamsFormatter.getDateRangeNightsSummary(dateRange, t) :
                  SearchParamsUtils.getDateRangeNights(dateRange)
              }
             </span>
           </div>
         </div>
       </InputWrapper>
    </div>
  );
}

export default ButtonDateRangeSummary;
