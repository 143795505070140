import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import {CheckoutRates} from "@/src/core/app/domain/models/checkout/CheckoutRates";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutRateRoomAndRoomTeaser} from "@/src/core/app/domain/models/checkout/CheckoutRateRoomAndRoomTeaser";
import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import HotelRoomTeaserWithRatesMobile from "@/src/ui/components/hotel-room/mobile/HotelRoomTeaserWithRates";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

interface _Props extends T_ParagraphHotelRoomList {
  rooms: T_HotelRoomTeaser[]
  rates: CheckoutRates
}

const _CheckoutStatusOk = (props: _Props) => {
  const modal = useModalProvider(state => state);
  const state = useCheckoutProvider(state => state);
  const rooms = CheckoutUtils.getCheckoutStepRoomsRateRoom(props.rates, props.rooms);

  const onClick = (
    room: CheckoutRateRoomAndRoomTeaser,
    rateGroup: CheckoutRatesHotelRoomRateGroup,
    board: CheckoutRatesHotelRoomRateGroupBoard
  ) => {
    modal.toggleModal(ModalKey.LOADER);
    state.addRoom(room, rateGroup, board);
  };

  return (
    <Section paddingY="pt-10 pb-7.5">
      <MaxWidthWrapper>
        <div className="pb-7.5">
          <TitleWithTag
            field={props.field_title_with_tag}
            className="title-4-mobile"
          />
          {/*<div className="flex items-center space-x-2.5 pb-2.5 text-sm text-brand-2">*/}
          {/*  <Toggle />*/}
          {/*  <p>Soy residente {regionName}</p>*/}
          {/*  <img*/}
          {/*    className="h-3.5 w-auto hover:opacity-90"*/}
          {/*    src="/assets/icons/help-brand-2.svg"*/}
          {/*    alt=""*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<button className="text-link">Ver calendario de precios</button>*/}
        </div>
        <ul className="space-y-5">
          {rooms.map((room, index) => (
            <li key={room.rateRoom.uuid}>
              <HotelRoomTeaserWithRatesMobile
                room={room}
                onClick={onClick}
              />
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  )
}

export default _CheckoutStatusOk;
