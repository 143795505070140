import React from "react";

interface _Props {
  scrollSnaps: number[]
  selectedIndex: number
  scrollTo: (arg0 : number) => void
}

const _Dots = (props : _Props): JSX.Element => (
  <div className="flex items-center space-x-2.5">
    {props.scrollSnaps.map((_, index) =>  (
      <button
        key={index}
        className={`embla__dot rounded-full w-1.5 h-1.5 ${index === props.selectedIndex ? "is-selected" : ""}`}
        type="button"
        onClick={() =>{props.scrollTo(index)}}
      />
    ))}

  </div>
);

export default _Dots;
