import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";
import FooterSocialNetworks from "@/src/ui/components/layout/generic/FooterSocialNetworks";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import Anchor from "@/src/ui/components/generic/Anchor";
import LanguageDropdown from "@/src/ui/components/generic/LanguageDropdown";
import CurrencyDropdown from "@/src/ui/components/generic/CurrencyDropdown";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {useTranslation} from "next-i18next";
import cn from "classnames";
import UrlUtils from "@/src/core/app/utils/url";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";


interface _SumenuProps {
  menuItem: CMS_MenuItem
}

const _SubMenu = ({ menuItem } : _SumenuProps): JSX.Element => (
  <nav>
    <h3 className="text-sm uppercase font-bold pb-5">{menuItem.title}</h3>
    {menuItem.below.length > 0 && (
      <ul>
        {menuItem.below.map((submenuItem, index) => (
          <li
            className={cn(
              "hover:underline",
              (index + 1) != menuItem.below.length && 'pb-2.5',
            )} key={submenuItem.id}>
            <Anchor href={submenuItem.url}>
              {submenuItem.title}
            </Anchor>
          </li>
        ))}
      </ul>
    )}
  </nav>
);

const FooterDesktop = (): JSX.Element => {
  const { t } = useTranslation();
  const routeProps = useRouteProps<any, UI_LayoutBase>();
  const appInfo = useAppInfo();
  return (
    <footer className="bg-brand-2 pt-10 px-5 text-white text-xs">
      <MaxWidthWrapper>
        <div className="flex mb-10">
          <div className="flex-1 flex">
            {routeProps.layoutData.menuFooterMain.items.map((item, index) => (
              <div className="flex-1" key={index}>
                <_SubMenu menuItem={item} />
              </div>
            ))}
          </div>
          <div className="w-100">
            <a href={UrlUtils.getHomepageUrl(appInfo.langcode)}>
              <img
                className='w-25'
                src='/assets/logo/105x70-alpha@2x.png'
              />
            </a>
            {/*<FooterNewsletterForm />*/}
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-1 flex divide-x divide-white">
            {routeProps.layoutData.menuFooterSecondary.items.map((item, index) => (
              <Anchor href={item.url} className="underline px-2.5" key={index}>
                {item.title}
              </Anchor>
            ))}
          </div>
          <div className="w-100 flex justify-between items-center">
            <CurrencyDropdown spaceBetween />
            <LanguageDropdown />
            <FooterSocialNetworks socialNetworks={routeProps.layoutData.socialNetworks} />
          </div>
        </div>
        <aside className="py-10 text-center">
          {t('global.copyright.message')}
        </aside>
      </MaxWidthWrapper>
    </footer>
  );
}

export default FooterDesktop;
