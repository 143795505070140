import UiCurrencyUtils from "@/src/ui/utils/currency";
import _Td from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/td";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import CheckoutCalendarUtils from "@/src/core/app/utils/checkout-calendar";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";

interface _Props {
  roomCode: string
}

const _TotalPriceCell = (props: _Props) => {
  const currencyConfig = useCurrencyConfig();
  const modalState = useModalProvider(state => state);
  const searchFormState = useSearchFormProvider(state => state);
  const checkoutState = useCheckoutProvider(state => state);
  const totalPrice = CheckoutCalendarUtils.getTotalPrice(props.roomCode, checkoutState);

  const onClick = () => {
    CheckoutCalendarUtils.onTotalPriceClicked(
      props.roomCode,
      checkoutState,
      searchFormState,
      modalState
    );
  };

  return (
    <_Td className='w-38'>
      {totalPrice && (
        <div
          className="h-full flex items-center px-5 justify-between cursor-pointer hover:bg-brand-1-60 select-none"
          onClick={onClick}
        >
            <span className="text-lg font-bold">
              {UiCurrencyUtils.format(totalPrice, currencyConfig)}
            </span>
          <img src="/assets/icons/calendar-arrow.svg" alt=""/>
        </div>
      )}
    </_Td>
  );
};

export default _TotalPriceCell;
