import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockHotelListByDestinationDesktop from "@/src/ui/components/blocks/BlockHotelListByDestination/desktop";
import BlockHotelListByDestinationMobile from "@/src/ui/components/blocks/BlockHotelListByDestination/mobile";
import {T_ParagraphHotelListByDestination} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelListByDestination";


const BlockHotelListByDestination = (props: T_ParagraphHotelListByDestination) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockHotelListByDestinationMobile : BlockHotelListByDestinationDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockHotelListByDestination;
