import {T_ParagraphContactGeneralInfo} from "@/src/core/app/domain/models/paragraph/T_ParagraphContactGeneralInfo";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import _Item from "@/src/ui/components/blocks/BlockContactGeneralInfo/item";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";


const BlockContactGeneralInfo = (props: T_ParagraphContactGeneralInfo) => (
  <div className='space-y-10'>
    <div className='space-y-2.5'>
      <TitleWithTag
        className="title-4 font-calistoga"
        field={props.field_title_with_tag}
      />
      <WrapperDiv
        className="text-xs text-brand-2-90"
        dangerouslySetInnerHTML={{__html: props.field_body.processed}}
      />
    </div>
    <ul className="border border-gray-40">
      {props.cpt_hotel_info_items.map((item, index) => (
        <li key={index}>
          <_Item item={item} />
        </li>
      ))}
    </ul>
  </div>
);
export default BlockContactGeneralInfo;
