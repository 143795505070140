import {immer} from "zustand/middleware/immer";
import {createStore, useStore} from "zustand";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchFormState} from "@/src/ui/view_models/search-form.state";
import {SearchParamsLocation} from "@/src/core/app/domain/@types/SearchParamsLocation";
import {SearchParamsValidation} from "@/src/core/app/domain/@types/SearchParamsValidation";
import BrowserUtils from "@/src/ui/utils/browser";
import {AppInitiatedEvent} from "@/src/ui/@types/event/AppInitiatedEvent";
import {SearchParamsContext} from "@/src/core/app/domain/@types/SearchParamsContext";
import {SearchParamsLocationType} from "@/src/core/app/domain/@types/SearchParamsLocationType";
import SearchForm from "@/src/core/app/utils/search-form";

export const searchFormStore = createStore<SearchFormState>()(immer((set, get) => {
  BrowserUtils.onAppInitiated<AppInitiatedEvent>((event) => {
    const context = SearchParamsUtils.getContext(event);
    get().init(context);
  });

  return {
    isInitiated: false,
    ...SearchParamsUtils.getDefaultValue(),
    context: null,
    validation: {
      hasErrors: false,
      errors: [],
    },
    init: (context: SearchParamsContext) => set(state => {
      state.isInitiated = true;
      state.context = context;
      const data = SearchParamsUtils.load(context);

      if (data) {
        state.location = data.location;
        state.rooms = data.rooms;
        state.dateRange = data.dateRange;
        state.promocode = data.promocode;
      }
    }),
    setRooms: (rooms) => set(state => {
      state.rooms = rooms;
    }),
    setDateRange: (dateRange) => set(state => {
      state.dateRange = dateRange;
      if (get().validation.hasErrors) {
        state.validation = SearchParamsUtils.validate(state);
      }
    }),
    setLocation: (location: SearchParamsLocation) => set(state => {
      state.location = location;
      if (location.type === SearchParamsLocationType.HOTEL) {
        state.rooms = SearchParamsUtils.adaptRoomsByHotelAndHotelTree(
          get().rooms,
          location.id,
          (get().context as SearchParamsContext).hotelTree,
        )
      }
      if (get().validation.hasErrors) {
        state.validation = SearchParamsUtils.validate(state);
      }
    }),
    setPromocode: (value: string) => set(state => {
      state.promocode = value;
    }),
    onSubmit: (roomCode?: string) => {
      let ok = false;
      set(state => {
        const searchForm = get();

        const validation : SearchParamsValidation = SearchParamsUtils.validate(searchForm);
        state.validation = validation;

        if (validation.hasErrors) {
          ok = false;
          return;
        }

        ok = SearchParamsUtils.submit(
          searchForm,
          searchForm.context as SearchParamsContext,
          roomCode
        );
      });
      return ok;
    },
  };
}));

export function useSearchFormProvider(): SearchFormState;
export function useSearchFormProvider<T>(selector: (state: SearchFormState) => T, equals?: (a: T, b: T) => boolean): T;
export function useSearchFormProvider(selector?: any, equals?: any) {
  return useStore(searchFormStore, selector, equals);
}
