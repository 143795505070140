import UiMediaUtils from "@/src/ui/utils/media";
import React from "react";
import _TextFields from "@/src/ui/components/blocks/BlockLoungesList/generic/_TextFields";
import {T_ParagraphLoungesList} from "@/src/core/app/domain/models/paragraph/T_ParagraphLoungesList";

const _Display1Col = (props: T_ParagraphLoungesList) => (
  <>
    <ul className="space-y-5">
      {props.field_lounge_items.map((item, index) => (
        <li key={index}>
          <article className="p-3.75 flex space-x-5 border border-gray-40">
            <div className='flex-none w-66.25'>
              <img
                {...UiMediaUtils.getImgPropsFromMediaImage(item.ui_cpt_field_media_teaser)}
                className="border border-gray-40 w-full"
              />
            </div>
            <_TextFields className="space-y-2.5" item={item} />
          </article>
        </li>
      ))}
    </ul>
  </>
);

export default _Display1Col;
