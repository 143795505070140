import {useDevice} from "@/src/ui/hooks/useDevice";
import _SummaryMobile from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/mobile/summary";
import _SummaryDesktop from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/desktop/summary";

const _Summary = (): JSX.Element | null => {
  const device = useDevice();
  const _Component = device.isMobile ? _SummaryMobile : _SummaryDesktop;
  return (
    <_Component />
  );
}

export default _Summary;
