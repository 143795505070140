import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

interface _Props {
  rateGroup: CheckoutRatesHotelRoomRateGroup
}

const CheckoutRateGroupFooterMobile = (props: _Props): JSX.Element => (
  <article className="bg-gray-10 border-t border-b border-gray-40 divide-y divide-gray-40">
    {/*<form className="py-2.5 px-3.75 space-y-3.5">*/}
    {/*  <h4 className="text-small-bold">Elige tu cama (según disponibilidad)</h4>*/}
    {/*  <div className="space-y-2.5">*/}
    {/*    <Radio name="bed-sizes" id="1-bed-king-size" checked={true}>*/}
    {/*      1 cama King size (2x2m)*/}
    {/*    </Radio>*/}
    {/*    <Radio name="bed-sizes" id="2-beds">*/}
    {/*      2 camas*/}
    {/*    </Radio>*/}
    {/*  </div>*/}
    {/*</form>*/}
    <aside className="bg-yellow-15 py-2.5 px-3.75 text-xs text-brand-2">
      <h4 className="text-small-bold pb-1">
        {props.rateGroup.title}
      </h4>
      <WrapperDiv
        size='small'
        className='pb-2.5 Dingus-Formatted'
        dangerouslySetInnerHTML={{__html: props.rateGroup.configuration?.description || null}}
      />
      {/*<Checkbox color="brand-2" checkedSymbol="check">*/}
      {/*  <span className="text-xs">*/}
      {/*    Tengo cuenta Elba Fidelity o deseo registrarme como usuario nuevo*/}
      {/*    durante la reserva*/}
      {/*  </span>*/}
      {/*</Checkbox>*/}
    </aside>
  </article>
);

export default CheckoutRateGroupFooterMobile;
