import ModalLayout from "src/ui/components/portals/generic/ModalLayout";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useCheckoutSpecialTaxes} from "@/src/ui/hooks/useCheckoutSpecialTaxes";
import {useTranslation} from "next-i18next";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";

const MODAL_KEY = ModalKey.CHECKOUT_SPECIAL_TAXES;

const ModalCheckoutSpecialTaxesGeneric = (): JSX.Element | null => {
  const checkout = useCheckoutProvider(state => state);
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const checkoutSpecialTaxes = useCheckoutSpecialTaxes();

  if (
    !checkout.isInitiated ||
    modalState.modalKey !== MODAL_KEY
  ) {
    return null;
  }

  if (
    !checkoutSpecialTaxes.special_taxes ||
    checkoutSpecialTaxes.special_taxes_body === null
  ) {
    return null;
  }

  return (
    <ModalLayout
      title={t('ModalCheckoutSpecialTaxes._Index.title')}
      className="w-171.5"
      onCloseModal={() => modalState.closeModal()}
    >
      <div className="p-3.75 sm:p-5 space-y-3.75 sm:space-y-5">
        <WrapperDiv
          className='Cms-Formatted'
          dangerouslySetInnerHTML={{__html: checkoutSpecialTaxes.special_taxes_body.processed}}
          size='medium'
        />
      </div>
    </ModalLayout>
  );
}

export default ModalCheckoutSpecialTaxesGeneric;
