import _PriceTagCommon from "@/src/ui/components/offer/OfferDetail/price-tag/by-type/common";
import {useTranslation} from "next-i18next";

interface _Props {
  priceFrom: number
  priceTo?: number
  description?: string
}

const _PriceTagRange = (props: _Props) => {
  const { t } = useTranslation();
  return (
    <_PriceTagCommon>
      <div className='flex space-x-10'>
        <div>
          <p className='uppercase font-bold text-brand-2-90 text-sm'>
            {t('OfferDetail._PriceTagRange.from_label')}
          </p>
          <p className='text-brand-2 font-text-8'>{props.priceFrom}€</p>
        </div>
        {props.priceTo && (
          <div>
            <p className='uppercase font-bold text-brand-2-90 text-sm'>
              {t('OfferDetail._PriceTagRange.to_label')}
            </p>
            <p className='text-brand-2 font-text-8'>{props.priceTo}€</p>
          </div>
        )}
      </div>
      {props.description && (
        <p className='text-sm text-brand-2-90'>{props.description}</p>
      )}
    </_PriceTagCommon>
  );
}

export default _PriceTagRange;
