import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_RouteError} from "@/src/core/app/domain/route/@types/T_RouteError";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import HeroError from "@/src/ui/components/generic/HeroError";
import {T_RouteErrorType} from "@/src/core/app/domain/route/@types/T_RouteErrorType";
import _Message from "@/src/ui/components/routes/Route_Error/by-type/message";
import {Route_Error_Component} from "@/src/ui/components/routes/Route_Error/Route_Error_Component";


const map : Record<T_RouteErrorType.MESSAGE, Route_Error_Component> = {
  [T_RouteErrorType.MESSAGE]: _Message,
};

const Route_Error = (props: RouteProps<T_RouteError>): JSX.Element => {
  const _Component = map[props.routeData.data.type];
  return (
    <>
      <HeroError />
      <Section paddingY="pt-5 pb-20 min-h-73">
        <MaxWidthWrapper>
          <_Component routeError={props.routeData.data}/>
        </MaxWidthWrapper>
      </Section>
    </>
  );
}

export default Route_Error;

