import _CustomerInfo from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/customer-info";
import _PaymentData from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/payment-data";
import _SpecialRequests from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/special-requests";
import _UserRegistration from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/user-registration";
import {Form} from "formik";
import _Formik from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/form";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import _Summary from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/summary";
import _Actions from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/actions";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useUserProvider} from "@/src/ui/providers/user.provider";

const CheckoutCustomerInfoForm = (): JSX.Element | null => {
  const device = useDevice();
  const routeData = useRouteData<T_CheckoutHotel>();
  const checkout = useCheckoutProvider(state => state);
  const userProvider = useUserProvider(state => state);

  if (!checkout.isInitiated || !userProvider.isInitiated) {
    return null;
  }

  const isPaymentDataRequired = !routeData.data.bap_enabled;
  const isRegistrationRequired = CheckoutUtils.isRegistrationRequired(userProvider.userData, checkout);

  return (
    <_Formik
      isPaymentDataRequired={isPaymentDataRequired}
      isRegistrationRequired={isRegistrationRequired}
      countries={routeData.data.countries}
    >
      <Form className={cn({
        'space-y-10': device.isDesktop,
        'space-y-5': device.isMobile,
      })}>
        <_CustomerInfo />
        {isPaymentDataRequired && (
          <_PaymentData />
        )}
        <_SpecialRequests />
        {isRegistrationRequired && (
          <_UserRegistration />
        )}
        <_Summary />
        <_Actions />
      </Form>
    </_Formik>
  );
};

export default CheckoutCustomerInfoForm;
