import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {EventRequestFormSuccessOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormSuccessOutput";
import {EventRequestFormErrorOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormErrorOutput";
import {FormsApiService} from "@/src/core/app/data/services/forms_api_service";

const JobRequestUtils = {
  getJobTypes: () : Record<string, string> => ({
    'director': 'job_offer_utils.job_type.director',
    'recepcion': 'job_offer_utils.job_type.recepcion',
    'maitre': 'job_offer_utils.job_type.maitre',
    'gobernata': 'job_offer_utils.job_type.gobernata',
    'jefe_de_cocina': 'job_offer_utils.job_type.jefe_de_cocina',
    'servicio_tecnico': 'job_offer_utils.job_type.servicio_tecnico',
    'camareras_pisos': 'job_offer_utils.job_type.camareras_pisos',
    'camareros_restaurante': 'job_offer_utils.job_type.camareros_restaurante',
    'cocineros': 'job_offer_utils.job_type.cocineros',
    'recepcionistas': 'job_offer_utils.job_type.recepcionistas',
    'sstt': 'job_offer_utils.job_type.sstt',
    'animadores': 'job_offer_utils.job_type.animadores',
    'otro': 'job_offer_utils.job_type.otro',
  }),

  getJobTypeSelectOptions: () => {
    const jobTypes = JobRequestUtils.getJobTypes();
    return Object.keys(jobTypes).map(key => ({
      label: jobTypes[key],
      value: key,
    }));
  },

  sendJobRequestForm: async (
    appInfo: AppInfo,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    hotelCode: string,
    jobType: string,
    cvFileName: string,
    cvFileData: string
  ) : Promise<NetworkResponse<EventRequestFormSuccessOutput, EventRequestFormErrorOutput>> => {
    const api = locator.get<FormsApiService>(TYPES.FormsApiService);
    return await api.jobRequestForm(
      appInfo,
      firstName,
      lastName,
      phoneNumber,
      hotelCode,
      jobType,
      cvFileName,
      cvFileData
    );
  },
};

export default JobRequestUtils;
