import {useDevice} from "@/src/ui/hooks/useDevice";

const TriangleSeparator = (): JSX.Element => {
  const device = useDevice();

  if (device.isMobile) {
    return (
      <svg
        className="mx-auto w-full"
        preserveAspectRatio="none"
        height="20"
        viewBox="0 0 375 20"
        fill="none"
      >
        <path d="M0 20L375 0H0V20Z" fill="#182341"/>
      </svg>
    );
  }


  return (
    <svg
      className="mx-auto w-full"
      preserveAspectRatio="none"
      height="30"
      viewBox="0 0 1440 30"
      fill="none"
    >
      <path d="M0 0H1440L0 30V0Z" fill="#182341"/>
    </svg>
  );
}

export default TriangleSeparator;
