import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import MediaImage from "@/src/ui/components/generic/MediaImage";
import _PriceTag from "@/src/ui/components/offer/OfferDetail/price-tag";
import {useDevice} from "@/src/ui/hooks/useDevice";

const _Img = (props: T_OfferDetail) => {
  const device = useDevice();
  return (
    <div className='relative'>
      <_PriceTag {...props} />
      <MediaImage mediaImage={
        device.isDesktop ?
          props.cpt_ui_field_offer_full :
          props.cpt_ui_field_offer_teaser_mobile
      } />
    </div>
  );
}

export default _Img;
