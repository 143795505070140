import ModalSearchFormStepLocationDesktop from "./ModalSearchFormStepLocation";
import ModalSearchFormStepDatesDesktop from "src/ui/components/portals/desktop/ModalSearchFormStepDates";
import ModalSearchFormStepOccupationDesktop from "src/ui/components/portals/desktop/ModalSearchFormStepOccupation";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLoader from "src/ui/components/portals/generic/ModalLoader";
import ModalNotification from "src/ui/components/portals/generic/ModalNotification";
import {LayoutProps} from "@/src/ui/@types/layout/LayoutProps";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import ModalDesktopKey from "@/src/ui/@types/modal/ModalDesktopKey";
import ModalReservationCancel from "@/src/ui/components/portals/generic/ModalReservationCancel";
import ModalHotelRoomDesktop from "src/ui/components/portals/desktop/ModalHotelRoom";
import ModalMultiGalleryDesktop from "@/src/ui/components/portals/desktop/ModalMultiGallery";
import ModalLoungeDesktop from "@/src/ui/components/portals/desktop/ModalLounge";
import ModalOfferFormByHotel from "@/src/ui/components/portals/generic/ModalOfferFormByHotel";
import ModalOfferFormByDestination from "@/src/ui/components/portals/generic/ModalOfferFormByDestination";
import ModalOfferFormByOfferGroup from "@/src/ui/components/portals/generic/ModalOfferFormByOfferGroup";
import ModalOfferFormByExperience from "@/src/ui/components/portals/generic/ModalOfferFormByExperience";
import ModalOfferFormDateRangeGeneric from "src/ui/components/portals/generic/ModalOfferFormDateRange";
import ModalOfferFormOccupationGeneric from "src/ui/components/portals/generic/ModalOfferFormOccupation";
import ModalOfferSearchDatesFilterDesktop from "@/src/ui/components/portals/desktop/ModalOfferSearchDatesFilter";

type ModalComponent = () => JSX.Element | null;

const Map : Record<string, ModalComponent> = {
  [ModalKey.LOADER]: ModalLoader,
  [ModalKey.NOTIFICATION]: ModalNotification,
  [ModalKey.RESERVATION_CANCEL]: ModalReservationCancel,
  [ModalKey.HOTEL_ROOM]: ModalHotelRoomDesktop,
  [ModalKey.MULTI_GALLERY]: ModalMultiGalleryDesktop,
  [ModalKey.LOUNGE]: ModalLoungeDesktop,
  [ModalKey.OFFER_FORM_BY_HOTEL]: ModalOfferFormByHotel,
  [ModalKey.OFFER_FORM_BY_DESTINATION]: ModalOfferFormByDestination,
  [ModalKey.OFFER_FORM_BY_OFFER_GROUP]: ModalOfferFormByOfferGroup,
  [ModalKey.OFFER_FORM_BY_EXPERIENCE]: ModalOfferFormByExperience,
  [ModalKey.OFFER_FORM_DATE_RANGE]: ModalOfferFormDateRangeGeneric,
  [ModalKey.OFFER_FORM_OCCUPATION]: ModalOfferFormOccupationGeneric,
  [ModalDesktopKey.SEARCH_FORM_STEP_LOCATION]: ModalSearchFormStepLocationDesktop,
  [ModalDesktopKey.SEARCH_FORM_STEP_DATES]: ModalSearchFormStepDatesDesktop,
  [ModalDesktopKey.SEARCH_FORM_STEP_OCCUPATION]: ModalSearchFormStepOccupationDesktop,
  [ModalDesktopKey.OFFER_SEARCH_DATES_FILTER]: ModalOfferSearchDatesFilterDesktop,
};

const _Portal = () => {
  const modalKey = useModalProvider(state => state.modalKey);

  if (modalKey === null) {
    return null;
  }

  const _Component = Map[modalKey];

  if (!_Component) {
    return null;
  }

  return (
    <_Component />
  )
};

const PortalsDesktop = (props: LayoutProps) : JSX.Element => {
  const _RoutePortalsComponent = props.routePortals;
  return (
    <>
      {props.children}
      <_Portal />
      {_RoutePortalsComponent && (
        <_RoutePortalsComponent {...props.routeProps} />
      )}
    </>
  );
}

export default PortalsDesktop;
