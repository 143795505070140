import {
  T_ParagraphResidentsList,
  T_ParagraphResidentsList__Display
} from "@/src/core/app/domain/models/paragraph/T_ParagraphResidentsList";
import _Column from "@/src/ui/components/blocks/BlockResidentsList/mobile/by-display/column";
import _Row from "@/src/ui/components/blocks/BlockResidentsList/mobile/by-display/row";


const BlockResidentsListMobile = (props: T_ParagraphResidentsList): JSX.Element => {
  const _Component = props.field_residents_display === T_ParagraphResidentsList__Display.COLUMN_IMAGE_TITLE ? _Column : _Row;
  return (
    <_Component {...props} />
  );
}
export default BlockResidentsListMobile;
