import {LanguageDropdownMode} from "@/src/ui/utils/language";
import Anchor from "@/src/ui/components/generic/Anchor";
import LanguageDropdown from "@/src/ui/components/generic/LanguageDropdown";
import NavigationUtils from "@/src/core/app/utils/navigation";
import {useTranslation} from "next-i18next";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

const LayoutConversionHeaderMobile = (): JSX.Element => {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  return (
    <>
      <header className="relative px-5 py-2.5 flex justify-center border-b border-gray-30 bg-gray-20">
        <p className="sr-only">{t('global.generic.site_name')}</p>
        <Anchor href={NavigationUtils.getHomePath(appInfo.langcode)}>
          <img
            className="flex-none h-15 w-auto"
            src="/assets/images/generic/elba-logo.svg"
          />
        </Anchor>
        <div className="absolute right-5 top-0 bottom-0 flex justify-end items-center space-x-1.5">
          <LanguageDropdown color="brand-2" mode={LanguageDropdownMode.SHORT} />
        </div>
      </header>
    </>
  );
}

export default LayoutConversionHeaderMobile;
