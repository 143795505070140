import {RefObject, useEffect, useState} from "react";
import useHTMLElementOnScreen from "@/src/ui/hooks/useHTMLElementOnScreen";

export default function useHTMLElementHasBeenDisplayed(ref: RefObject<HTMLElement>) {
  const onScreen = useHTMLElementOnScreen(ref);
  const [scrollPassed, setScrollPassed] = useState(onScreen);

  useEffect(() => {
    if (scrollPassed) {
      return;
    }
    if (onScreen) {
      setScrollPassed(true);
    }
  }, [onScreen]);

  return scrollPassed;
}