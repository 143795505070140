import {ShimmerEffectDiv} from "@/src/ui/components/generic/ShimmerEffect/index.styled";

interface _Props {
  className?: string | undefined
}

const ShimmerEffect = (props: _Props) => (
  <ShimmerEffectDiv className={props.className}>
    &nbsp;
  </ShimmerEffectDiv>
);

export default ShimmerEffect;
