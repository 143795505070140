import {T_NodeBlogTeaser} from "@/src/core/app/domain/models/node/T_NodeBlogTeaser";
import NodeBlogTeaserMobile from "@/src/ui/components/blog/NodeBlogTeaser/mobile";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

interface _Props {
  titleWithTag?: CMS_FieldTextWithTag
  items: T_NodeBlogTeaser[]
}

const NodeBlogTeaserListMobile = (props: _Props) => (
  <>
    <TitleWithTag
      className="title-4-mobile font-calistoga"
      field={props.titleWithTag}
    />
    <ul className="space-y-5 pt-7.5 pb-5">
      {props.items.map((item) => (
        <li key={item.id}>
          <NodeBlogTeaserMobile
            blogPost={item}
          />
        </li>
      ))}
    </ul>
  </>
);

export default NodeBlogTeaserListMobile;
