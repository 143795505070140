import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {useTranslation} from "next-i18next";

interface _Props {
  lowestPrice: number
}

const LowestPriceTagMobile = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();
  return (
    <div className="w-auto px-2.5 py-0.5 rounded-full bg-brand-2 text-white font-bold text-xs mt-1 whitespace-nowrap">
      <em className="block not-italic">
        {t('LowestPriceTag._Index.from_label')} <span className="text-sm">{UiCurrencyUtils.format(props.lowestPrice, currencyConfig)}</span>
      </em>
    </div>
  );
}

export default LowestPriceTagMobile;
