import {FormInputRadioOption} from "@/src/ui/@types/form/FormInputRadioOption";
import {useField} from "formik";
import {FieldValidator} from "formik/dist/types";
import FormInputRadio from "src/ui/components/formik/FormInputRadio";
import {FormFieldProps} from "@/src/ui/@types/form/FormFieldProps";
import {useFormField} from "@/src/ui/hooks/useFormField";
import {useTranslation} from "next-i18next";

interface _Props extends FormFieldProps {
  label?: string
  options: FormInputRadioOption[]
  isVertical?: boolean
}

const FormInputRadioGroup = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    label,
    options,
    isVertical,
  } = props;

  const [field, meta] = useFormField(props, t);
  return (
    <label className="flex-1 block w-full">
      {label && <p className="text-medium-bold pb-3.75">{label}</p>}
      <div className={isVertical ? "space-y-1.5" : "flex flex-wrap space-x-5"}>
        {options.map((option) => (
          <FormInputRadio
            key={option.value}
            id={option.value}
            name={field.name}
            label={option.label}
            value={option.value}
          />
        ))}
      </div>
      {meta.error && <p className="text-xs text-red pt-1.5">{meta.error}</p>}
    </label>
  );
}

export default FormInputRadioGroup;
