import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_FieldTwoTextsAndIcon} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTwoTextsAndIcon";

export enum T_ParagraphIconList_ListDisplay {
  INLINE = 'inline',
  UP = 'up',
}

export interface T_ParagraphIconList extends CMS_ParagraphEntity {
  field_background_color: string
  field_columns: number
  field_list_display: T_ParagraphIconList_ListDisplay
  field_icon_texts_items: CMS_FieldTwoTextsAndIcon[]
  field_section_name: string
}