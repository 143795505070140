import CalendarUtils from "@/src/ui/utils/calendar";
import {useTranslation} from "next-i18next";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import React from "react";

interface _CalendarHeaderProps {
  monthDate: Date
  customHeaderCount: number
  decreaseMonth: VoidFunction
  increaseMonth: VoidFunction
}

interface _DayHeaderProps {
  label: string
}

const _DayHeader = ({
  label
}: _DayHeaderProps) => {
  const {t} = useTranslation();
  return (
    <span className='w-11 h-11 text-sm flex justify-center items-center bg-gray-20 text-brand-3-20 border-b border-r border-gray-30'>
    {t(label as any)}
  </span>
  );
}

interface _H4Props {
  currentMonth: string
}

const _H4 = (props: _H4Props) => {
  const appInfo = useAppInfo();
  return (
    <h4 className="text-lg leading-9 font-bold text-center">
      {CalendarUtils.formatMonthAndYear(props.currentMonth, appInfo.langcode)}
    </h4>
  );
};

export const _CalendarHeader = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
}: _CalendarHeaderProps) => {
  const currentMonth = CalendarUtils.dayToString(monthDate);
  return (
    <div className='w-77'>
      <div className='relative pb-3.5'>
        {customHeaderCount !== 1 && !CalendarUtils.isCurrentMonth(currentMonth) && (
          <button
            onClick={decreaseMonth}
            className="absolute top-0 left-0 h-9 w-9 flex items-center"
          >
            <img
              className="transform rotate-180 h-4"
              src="/assets/icons/chevron-right-brand-2.svg"
            />
          </button>
        )}
        <_H4 currentMonth={currentMonth} />
        {customHeaderCount !== 0 && (
          <button
            className="absolute top-0 right-0 h-9 w-9 flex justify-end items-center"
            onClick={increaseMonth}
          >
            <img
              className="h-4"
              src="/assets/icons/chevron-right-brand-2.svg"
            />
          </button>
        )}
      </div>
      <ul className="border-t border-l border-gray-30 grid grid-cols-7">
        <li><_DayHeader label={'SearchFormCalendar._Header.monday_short_label'} /></li>
        <li><_DayHeader label={'SearchFormCalendar._Header.tuesday_short_label'} /></li>
        <li><_DayHeader label={'SearchFormCalendar._Header.wednesday_short_label'} /></li>
        <li><_DayHeader label={'SearchFormCalendar._Header.thursday_short_label'} /></li>
        <li><_DayHeader label={'SearchFormCalendar._Header.friday_short_label'} /></li>
        <li><_DayHeader label={'SearchFormCalendar._Header.saturday_short_label'} /></li>
        <li><_DayHeader label={'SearchFormCalendar._Header.sunday_short_label'} /></li>
      </ul>
    </div>
  );
}