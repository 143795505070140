import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import {TFunction} from "react-i18next";

const I18nUtils = {
  translateFormInputSelectOptions: (options: FormInputSelectOption[], t: TFunction) : FormInputSelectOption[] => {
    return options.map(item => ({
      id: item.id,
      value: item.value,
      label: t(item.label as any),
    }));
  },
  translateLabels: (labels: string[], t: TFunction) : string[] => {
    return labels.map(label => t(label as any));
  },
};

export default I18nUtils;
