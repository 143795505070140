import {T_ParagraphHotelsByResident} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelsByResident";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import HotelTeaserSquared from "src/ui/components/hotel/HotelTeaserSquared";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";


const BlockHotelListByResident = (props: T_ParagraphHotelsByResident) => {
  if (props.hotelTeasers.length === 0) {
    return null;
  }
  return (
    <Section paddingY="pt-7.5 pb-15 bg-gray-10" backgroundColor={props.field_background_color} >
      <MaxWidthWrapper>
        <TitleWithTag
          className="title-4 font-calistoga"
          field={props.field_title_with_tag}
        />
        <ul className="pt-7.5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {props.hotelTeasers.map((item) => (
            <li className="flex items-stretch" key={item.id}>
              <HotelTeaserSquared hotelTeaser={item} />
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  );
}
export default BlockHotelListByResident;
