import React from "react";
import {Formik} from "formik";
import FormUtils from "@/src/ui/utils/form";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useTranslation} from "next-i18next";
import Button from "@/src/ui/components/generic/Button";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import NarrowFormBox from "@/src/ui/components/generic/NarrowFormBox";
import {FormikProps} from "formik/dist/types";
import Anchor from "@/src/ui/components/generic/Anchor";
import UrlUtils from "@/src/core/app/utils/url";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import UserUtils from "@/src/core/app/utils/user";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {
  UserLostPasswordConfirmationFormValues
} from "@/src/core/app/domain/forms/UserLostPasswordConfirmationFormValues";
import UserLostPasswordResetErrors from "@/src/core/app/domain/user/@types/FormErrorMap/user-lost-password-reset";
import _passwordValidation from "@/src/ui/components/forms/UserLostPasswordConfirmationForm/validation/password";

interface _FormProps {
  formik: FormikProps<UserLostPasswordConfirmationFormValues>
}

const _Form = (props: _FormProps) => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  return (
    <NarrowFormBox title={t('UserLostPasswordConfirmationForm._Index.title')}>
      <div className="text-brand-3 space-y-3.75">
        <FormInputText
          name='newPassword1'
          type='password'
          label={t('UserPasswordChangeForm._Index.new_password1_label') + " *"}
          validate={FormUtils.validateIsRequired}
        />
        <FormInputText
          name='newPassword2'
          type='password'
          label={t('UserPasswordChangeForm._Index.new_password2_label') + " *"}
          validate={FormUtils.validateIsRequired}
        />
      </div>
      <div>
      <Button
        bold
        text={t('UserLostPasswordConfirmationForm._Index.submit_label')}
        onClick={props.formik.handleSubmit}
        isActive={!props.formik.isSubmitting}
      />
      </div>
      <p>
        <Anchor
          className="text-link"
          href={UrlUtils.getHomepageUrl(appInfo.langcode)}
        >
          {t('UserLostPasswordConfirmationForm._Index.cancel_label')}
        </Anchor>
      </p>
    </NarrowFormBox>
  );
}

interface _Props {
  username: string
  tempPassword: string
}

const UserLostPasswordConfirmationForm = (props: _Props) => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const layoutData = useLayoutData();
  const modalState = useModalProvider(state => state);
  let initialValues : UserLostPasswordConfirmationFormValues = {
    username: props.username,
    tempPassword: props.tempPassword,
    newPassword1: '',
    newPassword2: '',
  };

  return (
    <Formik<UserLostPasswordConfirmationFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => FormUtils.runValidations(t, values, [_passwordValidation])}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await UserUtils.lostPasswordReset(
          appInfo,
          values.username,
          values.tempPassword,
          values.newPassword1
        );
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: UserLostPasswordResetErrors,
            formErrorMapKeyGetter: UserUtils.getLostPasswordResetNetworkErrorKey,
            logFn: (reason) => FormErrorUtils.logNetworkError('Password recovery confirmation form error', reason, networkError),
          });
          return;
        }

        modalState.toggleModal(ModalKey.NOTIFICATION, {
          type: T_NotificationType.SUCCESS,
          title: 'UserLostPasswordConfirmationForm._Index.success_notification_title',
          message: 'UserLostPasswordConfirmationForm._Index.success_notification_message',
          onClose: () => {
            UserUtils.goToLoginPage(layoutData.sitePageUrls);
          }
        });
      }}
    >
      {(formik: FormikProps<UserLostPasswordConfirmationFormValues>) => (
        <_Form formik={formik} />
      )}
    </Formik>
  )
}

export default UserLostPasswordConfirmationForm;
