import {useContextHeaderDesktopContext} from "@/src/ui/context/HeaderDesktopContext";
import Anchor from "@/src/ui/components/generic/Anchor";
import {CMS_MenuHeaderUser} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuHeaderUser";
import {
  CMS_MenuHeaderUserItem,
  CMS_MenuHeaderUserItemStyle, CMS_MenuHeaderUserItemVisibility
} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuHeaderUserItem";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import MenuUtils from "@/src/ui/utils/menu";
import MenuHeaderUserUtils from "@/src/core/app/utils/menu-header-user";

interface _Props {
  menu: CMS_MenuHeaderUser
}

interface _ItemProps {
  menuItem: CMS_MenuHeaderUserItem
}

const _MenuItemWithIcon = (props: _ItemProps) => {
  const context = useContextHeaderDesktopContext();
  const icon = 'person';
  return (
    <li className="flex-1 flex space-x-2.5">
      <img src={context?.isVisible ? `/assets/icons/${icon}-brand-2.svg` : `/assets/icons/${icon}-white.svg`} alt="" />
      <Anchor
        className="whitespace-nowrap"
        href={MenuUtils.getMenuItemHref(props.menuItem)}
      >
        {props.menuItem.title}
      </Anchor>
    </li>
  );
};

const _MenuItemNormal = (props: _ItemProps) => (
  <li>
    <Anchor href={MenuUtils.getMenuItemHref(props.menuItem)}>
      {props.menuItem.title}
    </Anchor>
  </li>
);

const _MenuItem = (props: _ItemProps) => {
  const menuItem = props.menuItem;
  const userState = useUserProvider(state => state);

  if (!MenuHeaderUserUtils.isMenuItemVisible(menuItem, userState)) {
    return null;
  }

  if (menuItem.field_menu_hu_style === CMS_MenuHeaderUserItemStyle.ICON_PEOPLE) {
    return (
      <_MenuItemWithIcon menuItem={menuItem} />
    );
  }

  return (
    <_MenuItemNormal menuItem={menuItem} />
  );
};

const _MenuHeaderUser = (props: _Props): JSX.Element => (
  <ul className="text-sm font-bold flex space-x-7.5">
    {props.menu.items.map(menuItem => (
      <_MenuItem key={menuItem.id} menuItem={menuItem} />
    ))}
  </ul>
);

export default _MenuHeaderUser;
