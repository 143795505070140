import cn from "classnames";
import {T_Pager} from "@/src/core/app/domain/models/pager/T_Pager";
import UiPagerUtils from "@/src/ui/utils/pager";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useRoute} from "@/src/ui/hooks/useRoute";

const Pagination = (props: T_Pager): JSX.Element => {
  const route = useRoute();
  const isFirst = UiPagerUtils.isFirstPage(props);
  const isLast = UiPagerUtils.isLastPage(props);
  const pages = UiPagerUtils.getPageLinks(props).map(pageNumber => ({
    pageNumber,
    href: UiPagerUtils.getPageLink(props, route, pageNumber)
  }));
  const prevLink = UiPagerUtils.getPageLink(props, route, props.currentPage - 1);
  const nextLink = UiPagerUtils.getPageLink(props, route, props.currentPage + 1);

  return (
    <div className="flex rounded-full w-auto mx-auto overflow-hidden space-x-2">
      <Anchor
        className={cn(
          "h-7.5 w-7.5 flex justify-center items-center rounded-full",
          prevLink && 'hover:bg-gray-10',
        )}
        href={prevLink}
      >
        <img
          className={cn("transform rotate-90", isFirst && "opacity-40")}
          src="/assets/icons/chevron-small-brand-2.svg"
        />
      </Anchor>
      <ul className="text-brand-2 text-sm flex space-x-2">
        {pages.map(({pageNumber, href}) => (
          <li key={pageNumber}>
            <Anchor
              href={href}
              className={cn(
                (props.currentPage == pageNumber) && "font-bold",
                "h-7.5 w-7.5 flex justify-center items-center rounded-full",
                href && 'hover:bg-gray-10',
              )}
            >
              {pageNumber + 1}
            </Anchor>
          </li>
        ))}
      </ul>
      <Anchor
        className={cn(
          "h-7.5 w-7.5 flex items-center justify-center rounded-full",
          nextLink && 'hover:bg-gray-10',
        )}
        href={nextLink}
      >
        <img
          className={cn(
            "transform -rotate-90",
            isLast && "opacity-40"
          )}
          src="/assets/icons/chevron-small-brand-2.svg"
        />
      </Anchor>
    </div>
  );
}

export default Pagination;
