import BrowserUtils from "@/src/ui/utils/browser";
import {CheckoutState} from "@/src/ui/view_models/checkout.state";
import {createProviderFn} from "@/src/common/utils/zustand-fn";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import Checkout from "@/src/core/app/utils/checkout";
import {CheckoutStepRoomsStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsStatus";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {SearchParamsLocationType} from "@/src/core/app/domain/@types/SearchParamsLocationType";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParamsContext} from "@/src/core/app/domain/@types/SearchParamsContext";
import {AppInitiatedEvent} from "@/src/ui/@types/event/AppInitiatedEvent";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";
import {CheckoutStepCalendarData} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarData";
import {CheckoutStepCalendarStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarStatus";
import TimerUtils from "@/src/core/app/utils/timer";
import {DateRange} from "@/src/core/app/domain/@types/DateRange";
import {
  CheckoutStepCalendarDataDateRanges
} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarDataDateRanges";
import CheckoutCalendarUtils from "@/src/core/app/utils/checkout-calendar";

interface CheckoutProviderProps {
  langcode: string
  autoInit?: boolean
  hotelId: string
  hotelCode: string
  offerDingusCode?: string
  stepExtrasEnabled: boolean
  step: CheckoutStep
}

export const useCheckoutProvider = createProviderFn<CheckoutState, CheckoutProviderProps>(props => {
  return (set, get) => {
    const autoInit = props.autoInit === undefined ? true : props.autoInit;
    autoInit && BrowserUtils.onAppInitiated<AppInitiatedEvent>((event) => {
      const loadParams = SearchParamsUtils.getContext(event);
      get().init(loadParams);
    });

    return {
      isInitiated: false,
      langcode: props.langcode,
      currentStep: null,
      currentStepData: null,
      hotelId: props.hotelId,
      hotelCode: props.hotelCode,
      stepExtrasEnabled: props.stepExtrasEnabled,
      ...CheckoutUtils.getDefaultValue(),
      init: async (context: SearchParamsContext) => {
        // Init state
        set(state => {
          state.searchParams = CheckoutUtils.loadSearchParams(context);

          const cookie = CheckoutUtils.getCookieData(props.hotelId);

          if (props.step === CheckoutStep.ROOMS) {
            const data = CheckoutUtils.loadStepRoomsData();
            state.currentStep = CheckoutStep.ROOMS;
            (state.currentStepData as CheckoutStepRoomsData) = data;
            state.rooms = cookie.data.rooms.slice(0, data.currentRoomIndex);
            return;
          }

          if (props.step === CheckoutStep.CALENDAR) {
            state.currentStep = CheckoutStep.CALENDAR;
            (state.currentStepData as CheckoutStepCalendarData) = {
              status: CheckoutStepCalendarStatus.LOADING,
            };
            state.rooms = cookie.data.rooms.slice(0, 1);
            return;
          }

          state.rooms = cookie.data.rooms;
        });

        // Check state
        let isValid = true;
        const currentState = get();
        if (props.step === CheckoutStep.ROOMS) {
          isValid = Checkout.checkStepRoomsState(currentState);
        }
        if (props.step === CheckoutStep.CUSTOMER_INFORMATION) {
          isValid = Checkout.checkStepCustomerInformationState(currentState);
        }

        if (!isValid) {
          return;
        }
        set(state => {
          state.isInitiated = true;
        });

        // Run step specific process
        if (props.step === CheckoutStep.ROOMS) {
          const searchParams = currentState.searchParams;

          if (SearchParamsUtils.isEmpty(searchParams)) {
            return;
          }

          await currentState.getAvailAndRates();
        }

        if (props.step === CheckoutStep.CALENDAR) {
          const searchParams = currentState.searchParams;

          if (SearchParamsUtils.isEmpty(searchParams)) {
            return;
          }
          await currentState.setStepCalendar();
        }
      },

      initForOfferDetail: async (
        dateRange: SearchParamsDateRange,
        occupation: SearchParamsOccupationRoom
      ) => {
        set(state => {
          state.searchParams = {
            location: {
              type: SearchParamsLocationType.HOTEL,
              id: state.hotelCode,
            },
            rooms: [occupation],
            dateRange,
            promocode: '',
          };

          state.currentStep = CheckoutStep.ROOMS;
          state.currentStepData = {
            status: CheckoutStepRoomsStatus.LOADING,
            currentRoomIndex: 0,
          };

          state.isInitiated = true;
        });

        await get().getAvailAndRates();
      },

      getAvailAndRates: async () => {
        const currentRoomIndex = (get().currentStepData as CheckoutStepRoomsData).currentRoomIndex;
        const ratesResponse = await CheckoutUtils.getAvailAndRates(
          props.langcode,
          get().searchParams as SearchParams,
          currentRoomIndex,
        );

        set(state => {
          if (ratesResponse.isOk) {
            let rates = ratesResponse.data;
            if (props.offerDingusCode) {
              rates = CheckoutUtils.filterRatesByDingusCode(rates, props.offerDingusCode);
            }
            state.currentStep = CheckoutStep.ROOMS;
            state.currentStepData = {
              status: CheckoutStepRoomsStatus.OK,
              currentRoomIndex,
              rates,
            };
            return;
          }

          state.currentStep = CheckoutStep.ROOMS;
          state.currentStepData = {
            status: CheckoutStepRoomsStatus.ERROR,
            currentRoomIndex,
            error: ratesResponse.error,
          };
        });
      },
      addRoom: (room, rateGroup, board) => {
        set(state => {
          const st = get();
          const currentRoomIndex = (st.currentStepData as CheckoutStepRoomsData).currentRoomIndex as number;
          const searchParamsRoom = st.searchParams?.rooms[currentRoomIndex] as SearchParamsOccupationRoom;
          const checkoutRoom = CheckoutUtils.getCheckoutRoom(searchParamsRoom, room, rateGroup, board);
          const rooms = [...st.rooms];
          rooms.push(checkoutRoom);
          state.rooms = rooms;
        });
        const state = get();
        SearchParamsUtils.save(state.searchParams as SearchParams);
        CheckoutUtils.saveCookie(state);
        CheckoutUtils.stepRoomsRedirect(state);
      },
      setStepCalendar: async () => {
        const currentState = get();

        set(state => {
          const selectionDateRange = (currentState.searchParams as SearchParams).dateRange as DateRange;
          const dateRanges = CheckoutCalendarUtils.getRanges(selectionDateRange, selectionDateRange);

          state.currentStep = CheckoutStep.CALENDAR;
          (state.currentStepData as CheckoutStepCalendarData) = {
            status: CheckoutStepCalendarStatus.LOADING,
            dateRanges,
          };
        });

        await get().loadCalendarData();
      },
      loadCalendarData: async () => {
        const currentState = get();

        set(state => {
          state.currentStep = CheckoutStep.CALENDAR;
          (state.currentStepData as CheckoutStepCalendarData).status = CheckoutStepCalendarStatus.LOADING;
        });

        const response = await CheckoutCalendarUtils.getAvailAndRates(
          props.langcode,
          currentState.searchParams as SearchParams,
          ((currentState.currentStepData as CheckoutStepCalendarData).dateRanges as CheckoutStepCalendarDataDateRanges).dataDateRange,
        );

        set(state => {
          if (response.isOk) {
            (state.currentStepData as CheckoutStepCalendarData).status = CheckoutStepCalendarStatus.OK;
            (state.currentStepData as CheckoutStepCalendarData).rates = response.data?.rates;
            (state.currentStepData as CheckoutStepCalendarData).roomsOrder = response.data?.roomsOrder;
            return;
          }

          (state.currentStepData as CheckoutStepCalendarData) = {
            status: CheckoutStepCalendarStatus.ERROR,
            error: response.error,
          };
        });
      },
      onCalendarDayClicked: async (dt: string) => {
        const currentStateData = get().currentStepData as CheckoutStepCalendarData;
        const selectedDateRange = CheckoutCalendarUtils.onDayClicked(
          dt, currentStateData.dateRanges?.selectionDateRange
        );

        if (!selectedDateRange) {
          return;
        }
        set(state => {
          ((state.currentStepData as CheckoutStepCalendarData).dateRanges as CheckoutStepCalendarDataDateRanges).selectionDateRange = selectedDateRange;
        });
      },
      setCalendarViewDatesWithoutLoading: async (dateRange: DateRange) => {
        set(state => {
          ((state.currentStepData as CheckoutStepCalendarData).dateRanges as CheckoutStepCalendarDataDateRanges).viewDateRange = dateRange;
        });
      },
      setCalendarRangesAndReload: async (dateRanges: CheckoutStepCalendarDataDateRanges) => {
        set(state => {
          (state.currentStepData as CheckoutStepCalendarData) = {
            status: CheckoutStepCalendarStatus.LOADING,
            dateRanges,
          };
        });
        await get().loadCalendarData();
      },
    };
}});
