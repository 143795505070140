import {useSearchFormMobileProvider} from "@/src/ui/providers/search-form-mobile-provider";
import {SearchFormMobileStep} from "@/src/ui/view_models/search-form-mobile.state";
import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import {_Footer} from "@/src/ui/components/portals/mobile/ModalSearchFormStepOccupation/footer";
import {_Form} from "@/src/ui/components/portals/mobile/ModalSearchFormStepOccupation/form";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import {useModalProvider} from "@/src/ui/providers/modal.provider";


const ModalSearchFormStepOccupationMobile = (): JSX.Element | null => {
  const searchForm = useSearchFormProvider(state => state);
  const state = useSearchFormMobileProvider(state => state);

  if (state.currentStep !== SearchFormMobileStep.OCCUPATION) {
    return null;
  }

  const onSubmit = (rooms: SearchParamsOccupationRoom[]) => {
    searchForm.setRooms(rooms);
    state.setCurrentStep(SearchFormMobileStep.FORM);
  };

  return (
    <useSearchFormOccupationProvider.State>
      <_Form />
      <_Footer
        onSubmit={onSubmit}
      />
    </useSearchFormOccupationProvider.State>
  );
}

export default ModalSearchFormStepOccupationMobile;
