import { ReactNode } from "react";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import cn from "classnames";

interface Props {
  children: ReactNode
  mediaImage: T_MediaImage | null
  className?: string
}

const HorizontalCard = ({ mediaImage, children, className }: Props): JSX.Element => (
  <article className="relative h-35">
    <MediaImageBgImg mediaImage={mediaImage} fallbackUrl={'/assets/images/desktop/horizontal-card-fallback.png'}/>
    <div className={cn(
      "absolute inset-0 flex flex-col items-center justify-center text-white",
      className
    )}>
      {children}
    </div>
  </article>
);

export default HorizontalCard;
