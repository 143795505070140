import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import HotelTeaserSquared from "@/src/ui/components/hotel/HotelTeaserSquared";
import {CMS_ParagraphHotelList_LinkSection} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";

interface _Props {
  hotelTeasers: T_HotelTeaser[]
  linkSection?: CMS_ParagraphHotelList_LinkSection
}

const HotelTeaserSquaredList = (props: _Props) => (
  <ul className="pt-7.5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
    {props.hotelTeasers.map((item) => (
      <li className="flex items-stretch" key={item.id}>
        <HotelTeaserSquared
          hotelTeaser={item}
          linkSection={props.linkSection}
        />
      </li>
    ))}
  </ul>
);

export default HotelTeaserSquaredList;