import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import type {Layout} from '@/src/ui/@types/layout/Layout'
import {LayoutType} from "@/src/ui/@types/layout/Layout";

export type HeaderDesktopContextType = {
  isVisible: boolean,
  isMenuVisible: boolean,
  toggleMenu: () => void,
} | null


const HeaderDesktopContext = React.createContext<HeaderDesktopContextType>(null);

const OFFSET = 575;

export interface HeaderDesktopContextProviderProps extends PropsWithChildren {
  layout: Layout,
}

export const HeaderDesktopContextProvider = ({children, layout} : HeaderDesktopContextProviderProps) => {
  const [state, setState] = useState({
    isVisible: layout.type === LayoutType.GENERIC,
    isMenuVisible: false,
  });

  const toggleMenu = () => {
    setState({
      ...state,
      isMenuVisible: !state.isMenuVisible,
    });
  };

  const showOrHideForm = () => {
    if (!state.isVisible && window.scrollY >= OFFSET) {
      setState({
        ...state,
        isVisible: true,
      })
    }
    if (state.isVisible && window.scrollY < OFFSET) {
      setState({
        ...state,
        isVisible: false,
      })
    }
  };

  useEffect(() => {
    if (layout.type !== LayoutType.HOME) {
      return;
    }
    showOrHideForm();
    window.addEventListener("scroll", showOrHideForm);
    return () => {
      window.removeEventListener("scroll", showOrHideForm);
    };
  });

  return (
    <HeaderDesktopContext.Provider value={{
      ...state,
      toggleMenu,
    }}>
      {children}
    </HeaderDesktopContext.Provider>
  );
}

export const useContextHeaderDesktopContext = () => {
  return useContext(HeaderDesktopContext);
};