import NextHead from 'next/head'
import {T_WithMeta} from "@/src/core/app/domain/models/meta/T_WithMeta";
import UrlUtils from "@/src/core/app/utils/url";

export default function Head(props: T_WithMeta) : JSX.Element | null {
  if (!props.meta) {
    return null;
  }

  const meta = props.meta;
  return (
    <NextHead>
      <title>{meta.title}</title>
      {meta.description && (
        <meta name="description" content={meta.description} />
      )}
      {meta.keywords && (
        <meta name="keywords" content={meta.keywords} />
      )}
      {Object.keys(meta.hreflang).map(key => (
        <link key={key} href={UrlUtils.getCmsLinkUrl(meta.hreflang[key])} rel="alternate" hrefLang={key} />
      ))}
    </NextHead>
  )
}
