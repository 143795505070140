import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import _HeaderDesktop from "@/src/ui/components/offer/OfferDetail/header/desktop";
import {useDevice} from "@/src/ui/hooks/useDevice";
import _HeaderMobile from "@/src/ui/components/offer/OfferDetail/header/mobile";

const _Header = (props: T_OfferDetail) => {
  const device = useDevice();
  const _Component = device.isDesktop ? _HeaderDesktop : _HeaderMobile;
  return (
    <_Component {...props} />
  );
}

export default _Header;
