import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {CmsApiService} from "@/src/core/app/data/services/cms_api_service";
import {CMS_OfferGroupEntity} from "@/src/core/app/domain/cms/@types/offer-group/CMS_OfferGroupEntity";
import {CMS_OfferGroupBase} from "@/src/core/app/domain/cms/@types/offer-group/CMS_OfferGroupBase";
import UrlUtils from "@/src/core/app/utils/url";
import DataLoaderContext from "@/src/core/app/data/data-loader/context/DataLoaderContext";

const OfferGroupUtils = {
  getLinkUrl: (offerGroup: CMS_OfferGroupBase) : string => {
    if (offerGroup.path?.alias) {
      return UrlUtils.getCmsLinkUrlFromPath(offerGroup.path);
    }
    return `/${offerGroup.langcode}/offer-group/${offerGroup.drupal_internal__id}`;
  },

  getList: async (context: DataLoaderContext, pageSize: number, langcode: string) : Promise<CMS_OfferGroupEntity[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const data = await cms.getOfferGroupList(context, pageSize, langcode);
    return data.data as CMS_OfferGroupEntity[]
  },
};

export default OfferGroupUtils;
