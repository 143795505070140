
const TimerUtils = {
  sleep: (milliseconds: number) : Promise<void> => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, milliseconds);
    })
  },
}

export default TimerUtils;
