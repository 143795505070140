import Button from "../../generic/Button";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import {useTranslation} from "next-i18next";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";

interface _Props {
  board: CheckoutRatesHotelRoomRateGroupBoard
  onClick: (board: CheckoutRatesHotelRoomRateGroupBoard) => void
}

const CheckoutRateGroupBoardMobile = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const state = useCheckoutProvider(state => state);
  const currencyConfig = useCurrencyConfig();
  const searchParams = state.searchParams as SearchParams;
  const stepData = state.currentStepData as CheckoutStepRoomsData;
  const pricePerNight = props.board.price / SearchParamsUtils.getDateRangeNights(searchParams.dateRange);
  const isLastRoom = stepData.currentRoomIndex === (searchParams.rooms.length - 1);

  return (
    <article className="py-1.5 px-3.75 text-brand-2">
      <div className="flex justify-between items-baseline">
        <h4 className="text-small-bold">{props.board.name}</h4>
        <p>
          <em className="text-sm not-italic leading-none">
            {UiCurrencyUtils.format(props.board.price, currencyConfig)}&nbsp;
          </em>
          <span className="text-xs text-brand-2-90">
            ({t('CheckoutRateGroupBoard._Index.per_night_label', {
              amount: UiCurrencyUtils.format(pricePerNight, currencyConfig)
            })})
          </span>
        </p>
      </div>
      {/*<div className="flex justify-between items-baseline text-brand-1">*/}
      {/*  <p className="text-xs text-brand-1">Elba Fidelity</p>*/}
      {/*  <p>*/}
      {/*    <em className="text-sm not-italic leading-none">*/}
      {/*      {fidelityPrice}&nbsp;*/}
      {/*    </em>*/}
      {/*    <span className="text-xs">({fidelityPriceNight} / noche)</span>*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div className="flex justify-end pt-1">
        <Button
          bold
          customPaddingClass='pt-0 pb-1px px-3'
          text={isLastRoom ? t('CheckoutRateGroupBoard._Index.book_label') : t('CheckoutRateGroupBoard._Index.add_label')}
          onClick={() => props.onClick(props.board)}
        />
      </div>
    </article>
  );
}

export default CheckoutRateGroupBoardMobile;
