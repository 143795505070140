import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {useTranslation} from "next-i18next";

const _Body = (props: T_OfferDetail) => {
  const { t } = useTranslation();
  return (
    <div className='space-y-5'>
      {props.field_body && (
        <div className='text-brand-2'>
          <div className="h-6 mb-0.5 relative">
            <div className="absolute inset-x-0 flex justify-center pt-3">
              <p className="text-center bg-white text-lg font-bold px-10">
                {t('OfferDetail._Body.body_label')}
              </p>
            </div>
          </div>
          <div className='p-5 pt-9 mb-2.5 border border-gray-40 space-y-2.5'>
            <WrapperDiv
              className='Cms-Formatted'
              dangerouslySetInnerHTML={{__html: props.field_body.processed}}
            />
          </div>
        </div>
      )}
      {props.field_conditions && (
        <div className='bg-gray-20 p-5'>
          <p className='font-bold mb-2'>{t('OfferDetail._Body.conditions_label')}</p>
          <WrapperDiv
            className='Cms-Formatted'
            dangerouslySetInnerHTML={{__html: props.field_conditions.processed}}
          />
        </div>
      )}
    </div>
  );
}

export default _Body;