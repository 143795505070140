import {CMS_OfferEntityBase} from "@/src/core/app/domain/cms/@types/offer/CMS_OfferBase";
import UrlUtils from "@/src/core/app/utils/url";

const UiOfferUtils = {
  getUrl: (entity: CMS_OfferEntityBase) => {
    if (entity.path.alias) {
      return UrlUtils.buildAppUrlWithLangPrefix(entity.path.langcode, entity.path.alias);
    }
    return UrlUtils.buildAppUrlWithLangPrefix(entity.langcode, `/offer/${entity.drupal_internal__id}`);
  },
}

export default UiOfferUtils;
