import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import TitleGroupDesktop from "src/ui/components/desktop/TitleGroup";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import Section from "@/src/ui/components/generic/Section/Section";
import {
  T_ParagraphExperiencesList,
  T_ParagraphExperiencesList__ViewMode
} from "@/src/core/app/domain/models/paragraph/T_ParagraphExperiencesList";
import NodeExperienceTeaserDesktop from "@/src/ui/components/experience/NodeExperienceTeaser/desktop";
import Slider from "react-slick";
import React, {PropsWithChildren} from "react";
import {useLayout} from "@/src/ui/hooks/useLayout";
import {LayoutType} from "@/src/ui/@types/layout/Layout";


const SliderNextArrow = (props: any) => (
  <div {...props}>
    <img src="/assets/icons/arrow-right-circle-transparent.svg" alt=""/>
  </div>
);

const SliderPrevArrow = (props: any) => (
  <div {...props}>
    <img src="/assets/icons/arrow-left-circle-transparent.svg" alt=""/>
  </div>
);

const _Wrapper = (props: PropsWithChildren<T_ParagraphExperiencesList>) => {
  if (props.field_pel_view_mode === T_ParagraphExperiencesList__ViewMode.GRID) {
    return (
      <div className="flex flex-wrap gap-5 place-content-center">
        {props.children}
      </div>
    );
  }

  const settings = {
    arrows: props.field_experiences.length > 4,
    draggable: false,
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />
  };
  return (
    <Slider {...settings}>
      {props.children}
    </Slider>
  );
}

const BlockExperiencesListDesktop = (props: T_ParagraphExperiencesList) => {
  const layout = useLayout();
  return (
    <div className='relative'>
      {layout.type === LayoutType.HOME && (
        <img
          className="absolute top-0 right-0"
          src="/assets/images/desktop/ribbon-experiences.png"
        />
      )}
      <Section
        paddingY={""}
        backgroundColor={props.field_background_color}
      >
        <MaxWidthWrapper>
          <div className="py-7.5">
            <TitleGroupDesktop
              titleWithTag={props.field_title_with_tag}
              description={props.field_subtitle}
            />
          </div>
          <_Wrapper {...props}>
            {props.field_experiences.map((experience, index) => (
              <div key={index}>
                <div className="h-100 w-70 mx-auto">
                  <NodeExperienceTeaserDesktop experience={experience}/>
                </div>
              </div>
            ))}
          </_Wrapper>
          <div className="py-7.5">
            {props.field_one_link && (
              <ArrowLink href={props.field_one_link.url}>{props.field_one_link.title}</ArrowLink>
            )}
          </div>
        </MaxWidthWrapper>
      </Section>
    </div>
  );
}

export default BlockExperiencesListDesktop;
