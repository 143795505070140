import {useDevice} from "@/src/ui/hooks/useDevice";
import _CustomerInfoMobile from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/mobile/customer-info";
import _CustomerInfoDesktop from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/desktop/customer-info";

const _CustomerInfo = () => {
  const device = useDevice();
  const _Component = device.isMobile ? _CustomerInfoMobile : _CustomerInfoDesktop;
  return (
    <_Component />
  );
}

export default _CustomerInfo;
