import {useDevice} from "@/src/ui/hooks/useDevice";
import {useMyBookingListProvider} from "@/src/ui/providers/my-booking-list.provider";
import BlockMyBookingListDesktop from "@/src/ui/components/blocks/BlockMyBookingList/desktop";
import BlockMyBookingListMobile from "@/src/ui/components/blocks/BlockMyBookingList/mobile";
import {T_ParagraphMyBookingList} from "@/src/core/app/domain/models/paragraph/T_ParagraphMyBookingList";

const BlockMyBookingList = (props: T_ParagraphMyBookingList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockMyBookingListMobile : BlockMyBookingListDesktop;
  return (
    <useMyBookingListProvider.State>
      <_Component {...props} />
    </useMyBookingListProvider.State>
  );
}
export default BlockMyBookingList;
