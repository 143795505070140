import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockMenuAccountDesktop from "@/src/ui/components/blocks/BlockMenuAccount/desktop";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";

const BlockMenuAccount = () => {
  const device = useDevice();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const _Component = device.isMobile ? BlockMenuAccountDesktop : BlockMenuAccountDesktop;
  return (
    <_Component menu={layoutData.menuAccount} />
  );
}
export default BlockMenuAccount;
