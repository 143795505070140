import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useDevice} from "@/src/ui/hooks/useDevice";
import Route_BlogDetailDesktop from "@/src/ui/components/routes/Route_BlogDetail/desktop";
import Route_BlogDetailMobile from "@/src/ui/components/routes/Route_BlogDetail/mobile";
import {T_NodeBlogDetail} from "@/src/core/app/domain/models/node/T_NodeBlogDetail";

const Route_BlogDetail = (props: RouteProps<T_NodeBlogDetail>) : JSX.Element => {
  const device = useDevice();
  const _Component = device.isDesktop ? Route_BlogDetailDesktop : Route_BlogDetailMobile;
  return (
    <_Component {...props} />
  );
}

export default Route_BlogDetail;
