import cn from "classnames";
import React, {ReactNode} from "react";

export type ButtonStyle = "primary" | "secondary" | "secondary-white" | "light" | "ghost";

interface ButtonProps {
  style?: ButtonStyle
  bold?: boolean
  className?: string

  text?: string | ReactNode
  textClassName?: string

  customPaddingClass?: string
  isFullwidth?: boolean

  icon?: string
  isActive?: boolean
  onClick?: VoidFunction
  href?: string
}

const Button = ({
  text,
  textClassName,
  style = "primary",
  bold = false,
  icon,
  isFullwidth,
  className,
  isActive = true,
  onClick,
  href = undefined,
  customPaddingClass = "px-7.5 py-2",
}: ButtonProps): JSX.Element => {
  const _Element = href !== undefined ? 'a' : 'span';
  return (
    <_Element
      className={cn(
        "text-sm text-center rounded-full justify-center",
        style === 'primary' && "bg-brand-1 text-white",
        style === 'primary' && isActive && "hover:bg-brand-1-hover",
        style === 'secondary' && "border border-brand-2 text-brand-2",
        style === 'secondary' && isActive && "hover:text-white hover:bg-brand-1 hover:border-brand-1",
        style === 'light' && "text-brand-2 bg-gray-10",
        style === 'light' && isActive && "hover:bg-gray-20",
        style === 'ghost' && "text-brand-2-90 border border-gray-40",
        style === 'ghost' && isActive && "hover:text-brand-2-40 hover:border-gray-20",
        style === 'secondary-white' && "bg-transparent border border-white text-white",
        style === 'secondary-white' && isActive && "hover:bg-white hover:text-brand-2",
        isActive && "cursor-pointer",
        !isActive && "cursor-not-allowed",
        customPaddingClass,
        bold && "font-bold",
        isFullwidth && "w-full",
        className
      )}
      onClick={onClick && isActive ? onClick : undefined}
      href={href}
    >
      {text && React.isValidElement(text) && (text)}
      {text && !React.isValidElement(text) && (
        <span className={textClassName}>{text}</span>
      )}
      {icon && <img src={icon} className="ml-2.5" alt=""/>}
    </_Element>
  );
}

export default Button;
