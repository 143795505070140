import {immer} from "zustand/middleware/immer";
import {createStore, useStore} from "zustand";
import {OfferData, OfferFormState} from "@/src/ui/view_models/offer-form.state";
import CalendarUtils from "@/src/ui/utils/calendar";
import {arrayFromOneToN} from "@/src/common/utils/arrayFromOneToN";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {CMS_OfferEntity_NightsType} from "@/src/core/app/domain/cms/@types/offer/CMS_OfferEntityWithNights";

const ADULTS_MAX = 5;
const ADULTS_MIN = 0;
const KIDS_MAX = 5;
const KIDS_MIN = 0;

export const offerFormStore = createStore<OfferFormState>()(immer((set, get) => ({
  isInitiated: false,
  offerData: null,

  minDate: CalendarUtils.getToday(),
  maxDate: null,

  dateRange: {
    start: CalendarUtils.getToday(),
    end: CalendarUtils.addDays(CalendarUtils.getToday(), 2),
  },
  occupation: {
    adults: 2,
    kids: 0,
    kid_ages: [],
  },

  init: (offerData: OfferData) => {
    set(state => {
      const kidsMaxAge = offerData.kidsMaxAge;
      const minDate = CalendarUtils.isBeforeToday(offerData.dates.datetime_from) ?
        CalendarUtils.getToday() :
        offerData.dates.datetime_from
      ;

      let nightsCount : number = 2;

      if (offerData.nights.nights_type === CMS_OfferEntity_NightsType.CLOSE) {
        nightsCount = CalendarUtils.getDiffDays(minDate, offerData.dates.datetime_to as string);
      }
      else if (offerData.nights.nights_type === CMS_OfferEntity_NightsType.FIXED) {
        nightsCount = offerData.nights.nights_count;
      }
      else if (offerData.nights.nights_min) {
        nightsCount = offerData.nights.nights_min;
      }

      state.offerData = offerData

      state.minDate = minDate;
      state.maxDate = offerData.dates.datetime_to || null;

      state.dateRange = {
        start: minDate,
        end: CalendarUtils.addDays(minDate, nightsCount),
      };

      state.occupation = {
        adults: offerData.occupation.occupation_adults,
        kids: offerData.occupation.occupation_children,
        kid_ages: arrayFromOneToN({
          count: offerData.occupation.occupation_children
        }).map(item => kidsMaxAge),
      }

      state.isInitiated = true
    });
  },

  setDateRange: (dateRange: SearchParamsDateRange) => {
    set(state => {
      state.dateRange = dateRange;
    });
  },
  setOccupation: (occupation: SearchParamsOccupationRoom) => {
    set(state => {
      state.occupation = occupation;
    })
  },

  formAddAdult: () => {
    if (get().occupation.adults === ADULTS_MAX) {
      return;
    }
    set(state => {
      state.occupation.adults++
    })
  },
  formSubAdult: () => {
    if (get().occupation.adults === ADULTS_MIN) {
      return;
    }
    set(state => {
      state.occupation.adults--
    })
  },
  formAddKid: () => {
    if (get().occupation.kids === KIDS_MAX) {
      return;
    }

    const kidsMaxAge = (get().offerData as OfferData).kidsMaxAge;
    set(state => {
      state.occupation.kids++;
      state.occupation.kid_ages.push(kidsMaxAge);
    })
  },
  formSubKid: () => {
    if (get().occupation.kids === KIDS_MIN) {
      return;
    }
    set(state => {
      state.occupation.kids--;
      state.occupation.kid_ages = state.occupation.kid_ages.slice(0, state.occupation.kids);
    })
  },
  formKidAgeChange: (kidIndex, kidAge) => {
    set(state => {
      state.occupation.kid_ages[kidIndex] = kidAge
    })
  },
})));

export function useOfferFormProvider(): OfferFormState;
export function useOfferFormProvider<T>(selector: (state: OfferFormState) => T, equals?: (a: T, b: T) => boolean): T;
export function useOfferFormProvider(selector?: any, equals?: any) {
  return useStore(offerFormStore, selector, equals);
}
