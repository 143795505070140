import UserLoginForm from "@/src/ui/components/forms/UserLoginForm";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import BlockMenuAccount from "@/src/ui/components/blocks/BlockMenuAccount";

const BlockLoginForm = () => {
  const userState = useUserProvider(state => state);

  if (userState.isLoggedIn()) {
    return (
      <BlockMenuAccount />
    );
  }

  return (
    <UserLoginForm />
  );
}
export default BlockLoginForm;
