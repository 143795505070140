import {PropsWithChildren} from "react";


const ModalFooter = (props: PropsWithChildren): JSX.Element => (
  <div className="py-2.5 px-5 flex justify-between sm:justify-end items-center space-x-5 border-t border-gray-40">
    {props.children}
  </div>
);

export default ModalFooter;
