import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import React from "react";
import {T_ParagraphInfoCardsItem} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoCardsItem";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import AppController from "@/src/ui/controllers/AppController";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import UrlUtils from "@/src/core/app/utils/url";

interface _Props {
  item: T_ParagraphInfoCardsItem
  className?: string
}

const _TextFields = (props: _Props) => {
  const routeProps = useRouteProps();
  return (
    <div className={props.className}>
      <TitleWithTag
        className="text-big-bold"
        field={props.item.field_title_with_tag}
      />
      {props.item.field_body && (
        <WrapperDiv
          className='text-sm text-brand-2-90 space-y-4 has-styled-li Dingus-Formatted'
          dangerouslySetInnerHTML={{__html: props.item.field_body.processed}}
        />
      )}
      {props.item.field_one_link?.url && (
        <div className="pt-2.5">
          <ArrowLink href={props.item.field_one_link.url}>
            {props.item.field_one_link.title}
          </ArrowLink>
        </div>
      )}
      {!props.item.field_one_link?.url && props.item.field_one_link && (
        <div className="pt-2.5">
          <ArrowLink
            href={UrlUtils.buildAppUrlWithLangPrefix(routeProps.appInfo.langcode, `/${props.item.field_one_link.uri}`)}
          >
            {props.item.field_one_link.title}
          </ArrowLink>
        </div>
      )}
    </div>
  );
}

export default _TextFields;
