import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";

const HotelRoomUtils = {
  getCapacity: (hotelRoom: T_HotelRoomBasic) : number => {
    return hotelRoom.capacity;
  },
  getSurface(hotelRoom: T_HotelRoomBasic) : string | null {
    if (!hotelRoom.field_room_size) {
      return null;
    }
    return hotelRoom.field_room_size.replace('m2', 'm<sup>2</sup>');
  },
  getTeaserTitle(hotelRoom: T_HotelRoomBasic) : string {
    return hotelRoom.title_long || hotelRoom.title;
  },
}

export default HotelRoomUtils;
