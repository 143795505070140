import {T_ParagraphHotelContact} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelContact";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Anchor from "@/src/ui/components/generic/Anchor";
import cn from "classnames";


const BlockHotelContact = (props: T_ParagraphHotelContact) => (
  <div className={cn([props.field_background_color && "bg-" + props.field_background_color])}>
    <Section>
      <MaxWidthWrapper>
        <address className="sm:py-2.5 not-italic">
          {props.field_section_name && (
            <h2 className="title-5 pb-5 sm:pb-7.5">{props.field_section_name}</h2>
          )}
          <ul className="space-y-3 text-sm">
            <li className="flex space-x-5 text-brand-2-90">
              <img className="h-6 w-auto" src="/assets/icons/place.svg" alt="" />
              <span>{props.hotel.field_hotel_pci_address}</span>
            </li>
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-12">
              <li className="flex items-center space-x-5 text-brand-2-90">
                <img className="h-6 w-auto" src="/assets/icons/phone.svg" alt="" />
                <Anchor className="underline" href={'tel:' + props.hotel.field_hotel_pci_phone_number}>
                  {props.hotel.field_hotel_pci_phone_number}
                </Anchor>
              </li>
              <li className="flex items-center space-x-5 text-brand-2-90">
                <img className="h-6 w-auto" src="/assets/icons/email.svg" alt="" />
                <Anchor className="underline" href={"mailto:" + props.hotel.field_hotel_pci_email}>
                  {props.hotel.field_hotel_pci_email}
                </Anchor>
              </li>
            </div>
          </ul>
        </address>
      </MaxWidthWrapper>
    </Section>
  </div>
);
export default BlockHotelContact;
