import {useMyBookingListProvider} from "@/src/ui/providers/my-booking-list.provider";
import BookingManageLoadingDesktop from "src/ui/components/private-area/desktop/BookingManageLoading";
import BookingManage from "src/ui/components/booking-manage/BookingManage";
import {T_ParagraphMyBookingList} from "@/src/core/app/domain/models/paragraph/T_ParagraphMyBookingList";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";


const BlockMyBookingListDesktop = (props: T_ParagraphMyBookingList) => {
  const myBookingListState = useMyBookingListProvider(state => state);

  const onBookingCancel = (reservationId: string) => {
    myBookingListState.onCancel(reservationId);
  };

  if (myBookingListState.isLoading) {
    return (
      <div className="space-y-7.5">
        <TitleWithTag
          className="title-4 font-calistoga"
          field={props.field_title_with_tag}
        />
        <BookingManageLoadingDesktop />
      </div>
    )
  }

  if (myBookingListState.items.length === 0) {
    return (
      <div className="space-y-7.5">
        <TitleWithTag
          className="title-4 font-calistoga"
          field={props.field_title_with_tag}
        />
        <WrapperDiv size='medium' dangerouslySetInnerHTML={{__html: props.field_mbl_empty_listing.processed}}/>
      </div>
    )
  }

  return (
    <div className="space-y-7.5">
      <TitleWithTag
        className="title-4 font-calistoga"
        field={props.field_title_with_tag}
      />
      {myBookingListState.items.map((item, index) => (
        <BookingManage
          key={index}
          reservationManageData={item}
          onCancel={onBookingCancel}
        />
      ))}
    </div>
  );
}

export default BlockMyBookingListDesktop;
