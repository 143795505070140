import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import SocialNetworkUtils from "@/src/core/app/utils/social-network";
import UrlUtils from "@/src/core/app/utils/url";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";
import {useRoute} from "@/src/ui/hooks/useRoute";

const _Skirt = (props: T_OfferDetail) => {
  const route = useRoute();
  const { t } = useTranslation();
  const device = useDevice();

  return (
    <div className={cn(
      device.isDesktop && 'flex space-x-5',
      device.isMobile && 'space-y-2',
    )}>
      <div className={
        cn(
          device.isDesktop && 'flex-1',
          device.isMobile && 'text-sm',
        )}>
        <p>{t('OfferDetail._Skirt.warning')}</p>
      </div>
      <div>
        <a
          href={SocialNetworkUtils.getTwitterShareLink(
            props.title,
            UrlUtils.buildPublicUrlWithFallback(props.path.alias, route.path),
          )}
          className='flex w-7 h-7 bg-gray-20 rounded-full'
          target='_blank'
        >
          <img src="/assets/icons/twitter-circle-white.svg" alt=""/>
        </a>
      </div>
    </div>
  );
}

export default _Skirt;
