import cn from "classnames";
import {CMS_SocialNetwork} from "@/src/core/app/domain/cms/@types/social_network/CMS_SocialNetwork";
import Anchor from "@/src/ui/components/generic/Anchor";

interface FooterSocialNetworksProps {
  socialNetworks: CMS_SocialNetwork[]
  isMobile?: boolean;
}

const FooterSocialNetworks = ({
  socialNetworks,
  isMobile = false,
}: FooterSocialNetworksProps): JSX.Element | null => {
  if (socialNetworks.length === 0) {
    return null;
  }

  return (
    <ul
      className={cn("flex items-center", isMobile ? "space-x-10" : "space-x-8")}
    >
      {socialNetworks.map((item, index) => (
        <li className="pl-0.5" key={index}>
          <Anchor href={item.url}>
            <img
              className="h-4 w-4 object-contain"
              src={`/assets/icons/${item.icon}.svg`}
              alt={item.title}
            />
          </Anchor>
        </li>
      ))}
    </ul>
  );
}

export default FooterSocialNetworks;
