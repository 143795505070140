import React, {ChangeEvent} from "react";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";

type FormInputBasicSelectValue = string;
interface FormInputBasicSelectProps {
  name?: string
  placeholder?: string
  options: FormInputSelectOption[]
  onChange?: (newValue: FormInputBasicSelectValue) => void
  value: FormInputBasicSelectValue
}

const getOption = (options: FormInputSelectOption[], value: FormInputBasicSelectValue) : FormInputSelectOption => {
  const option = options.find(item => item.value === value);
  if (option === undefined) {
    throw new Error('Value not found in options');
  }
  return option;
}

const FormInputBasicSelect = (props: FormInputBasicSelectProps) : JSX.Element => {
  let placeholder = props.placeholder || '';

  if (props.value !== undefined) {
    placeholder = getOption(props.options, props.value).label;
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) {
      props.onChange(e.target.value as FormInputBasicSelectValue);
    }
  };

  return (
    <div className="cursor-pointer w-full relative">
      <select
        name={props.name}
        value={props.value}
        className='w-full h-full absolute opacity-0 z-50 cursor-pointer'
        onChange={onChange}
      >
        {props.options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      <button
        className="block w-full p-2.5 flex justify-between items-center text-sm text-brand-2-60 rounded border border-brand-2-40"
      >
        <span>{placeholder}</span>
        <img
          className="transform rotate-90"
          src="/assets/icons/chevron-right-brand-2.svg"
        />
      </button>
   </div>
  );
}

export default FormInputBasicSelect;
