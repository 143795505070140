import {T_ParagraphHtmlBlock} from "@/src/core/app/domain/models/paragraph/T_ParagraphHtmlBlock";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";


const BlockHtmlBlock = (props: T_ParagraphHtmlBlock) => {
  return (
    <div className="space-y-2.5">
      <TitleWithTag
        className="title-4 font-calistoga"
        field={props.field_title_with_tag}
      />
      <WrapperDiv
        className="pt-2.5 pb-5 text-sm text-brand-2 Dingus-Formatted"
        dangerouslySetInnerHTML={{__html: props.field_body.processed}}
      />
    </div>
  );
}
export default BlockHtmlBlock;
