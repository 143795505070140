interface _Props {
  title: string
  content: string
}

const TitledBoxColumn = (props: _Props) => (
  <div className="space-y-1.5">
    <h4 className="text-medium-bold">{props.title}</h4>
    <p className="text-sm text-brand-2-90">{props.content}</p>
  </div>
);

export default TitledBoxColumn;