import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Formik} from "formik";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {UserEditFormValues} from "@/src/core/app/domain/forms/UserEditFormValues";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import CountryUtils from "@/src/core/app/utils/country";
import UserEditUtils from "@/src/core/app/utils/user-edit";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import SentryUtils from "@/src/common/utils/sentry";
import NetworkNotificationUtils from "@/src/core/app/utils/network-notification";
import {UserEditFormProps} from "@/src/ui/components/forms/UserEditForm/types";

const UserEditForm = (props: UserEditFormProps) => {
  const userState = useUserProvider(state => state);
  const modalState = useModalProvider(state => state);

  const userData = userState.userData as UserData;
  const country = CountryUtils.findByUuid(props.countries, userData.country);
  let initialValues : UserEditFormValues = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    gender: userData.gender,
    phoneNumber: userData.phoneNumber,
    country: country.iso2,
    langcode: userData.langcode,
  };

  return (
    <Formik<UserEditFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await UserEditUtils.save(values, userState, props.countries);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          SentryUtils.logNetworkError('User edit form error', networkError)
          modalState.toggleModal(ModalKey.NOTIFICATION, NetworkNotificationUtils.getErrorServerUnexpectedNotification());
          return;
        }

        modalState.closeModal();
        props.closeFn();
      }}
    >
      {(formik: FormikProps<UserEditFormValues>) => (
        <props.Component
          formik={formik}
          {...props}
        />
      )}
    </Formik>
  );
}

export default UserEditForm;
