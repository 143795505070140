import {RouteProps} from "@/src/ui/@types/RouteProps";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import HeaderHotelCheckoutMobile from "@/src/ui/components/checkout/mobile/HeaderHotelCheckout";
import Section from "@/src/ui/components/generic/Section/Section";
import BookingConfirmationSummary from "@/src/ui/components/checkout/mobile/BookingConfirmationSummary";
import BookingConfirmationActions from "@/src/ui/components/checkout/generic/BookingConfirmationActions";

const BookingConfirmationMobile = (props: RouteProps<BookingConfirmationRouteProps>) => {
  return (
    <>
      <HeaderHotelCheckoutMobile
        checkoutHotel={props.routeData.data.hotel}
      />
      <BookingConfirmationSummary />

      <Section paddingY="py-10">
        <BookingConfirmationActions />
      </Section>
    </>
  );
};

export default BookingConfirmationMobile;
