import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import {CheckoutRates} from "@/src/core/app/domain/models/checkout/CheckoutRates";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {CheckoutRateRoomAndRoomTeaser} from "@/src/core/app/domain/models/checkout/CheckoutRateRoomAndRoomTeaser";
import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import HotelRoomTeaserWithRatesDesktop from "@/src/ui/components/hotel-room/desktop/HotelRoomTeaserWithRates";
import HotelRoomTeaserWithRatesMobile from "@/src/ui/components/hotel-room/mobile/HotelRoomTeaserWithRates";

interface _Props {
  rooms: T_HotelRoomTeaser[]
  rates: CheckoutRates
}

const CheckoutRoomListMobile = (props: _Props) => {
  const modal = useModalProvider(state => state);
  const state = useCheckoutProvider(state => state);
  const rooms = CheckoutUtils.getCheckoutStepRoomsRateRoom(props.rates, props.rooms);

  const onClick = (
    room: CheckoutRateRoomAndRoomTeaser,
    rateGroup: CheckoutRatesHotelRoomRateGroup,
    board: CheckoutRatesHotelRoomRateGroupBoard
  ) => {
    modal.toggleModal(ModalKey.LOADER);
    state.addRoom(room, rateGroup, board);
  };

  return (
    <ul className="space-y-5">
      {rooms.map((room) => (
        <li key={room.rateRoom.uuid}>
          <HotelRoomTeaserWithRatesMobile
            room={room}
            onClick={onClick}
          />
        </li>
      ))}
    </ul>
  )
};

export default CheckoutRoomListMobile;