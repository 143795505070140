import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {RouteProps} from "@/src/ui/@types/RouteProps";
import BlockListComponent from "@/src/ui/components/generic/BlockListComponent";
import {T_HotelSectionDataBase} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataBase";
import _Tabs from "@/src/ui/components/routes/Route_HotelSection/mobile/_Tabs";
import _Hero from "@/src/ui/components/routes/Route_HotelSection/mobile/_Hero";
import _Header from "@/src/ui/components/routes/Route_HotelSection/mobile/_Header";
import {T_HotelSectionType} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionType";
import _HeroCarousel from "@/src/ui/components/routes/Route_HotelSection/mobile/_HeroCarousel";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";
import UiHotelSectionUtils from "@/src/ui/utils/hotel-section";
import HotelMetaAddress from "@/src/ui/components/hotel/HotelMetaAddress";
import _JsonLd from "@/src/ui/components/routes/Route_HotelSection/generic/_JsonLd";

export default function HotelSectionMobile(props: RouteProps<T_HotelSection>): JSX.Element {
  const hotelSection = props.routeData.data;
  const hotel = hotelSection.ui_hotel_common_info;
  const hotelSectionData = hotelSection.ui_hotel_section.data as T_HotelSectionDataBase;

  const isMainSection = UiHotelSectionUtils.isMainSection(hotelSection);

  const parentProps : Record<string, any> = {};

  if (isMainSection) {
    parentProps['itemScope'] = true;
    parentProps['itemType'] = 'https://schema.org/LocalBusiness';
  }

  return (
    <div {...parentProps}>
      <_Header hotelSection={hotelSection}  hero={isMainSection ?
        (
          <_HeroCarousel hotel={hotel} sectionMainData={hotelSection.ui_hotel_section.data} />
        ) :
        (
          <_Hero hotel={hotel} />
        )
      }/>
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <_Tabs hotel={hotel} breadcrumb={props.routeData.data.breadcrumb} />
      <BlockListComponent blocks={hotelSectionData.field_blocks} />
      {isMainSection && (
        <>
          <HotelMetaAddress data={hotelSection.ui_hotel_common_info} />
          <_JsonLd hotelSection={hotelSection} />
        </>
      )}
    </div>
  );
}
