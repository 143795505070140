import UserPasswordChangeForm from "@/src/ui/components/forms/UserPasswordChangeForm";
import UserPasswordChangeFormMobileComponent from "@/src/ui/components/forms/UserPasswordChangeForm/mobile";
import UserRestrictedContentSeoFriendly from "@/src/ui/components/generic/UserRestrictedContentSeoFriendly";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {T_ParagraphUserPasswordEditForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserPasswordEditForm";
import {useTranslation} from "next-i18next";

const BlockUserPasswordChangeFormMobile = (props: T_ParagraphUserPasswordEditForm) => {
  const { t } = useTranslation();
  return (
    <div className="space-y-7.5">
      <TitleWithTag
        className="title-4 font-calistoga"
        field={props.field_title_with_tag}
      />
      <UserRestrictedContentSeoFriendly
        anonymousContent={(
          <p>{t('BlockUserPasswordChangeForm._Index.unauthenticated_message')}</p>
        )}
        content={(
          <UserPasswordChangeForm Component={UserPasswordChangeFormMobileComponent} />
        )}
      />
    </div>
  );
}

export default BlockUserPasswordChangeFormMobile;
