import HeaderDesktop from "./HeaderDesktop";
import type {RouteProps} from "@/src/ui/@types/RouteProps";

const HeaderDesktopGeneric = (props: RouteProps): JSX.Element => (
  <>
    <HeaderDesktop {...props}/>
    <div className="h-43"></div>
  </>
);

export default HeaderDesktopGeneric;
