import {T_Media} from "@/src/core/app/domain/models/media/T_Media";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import CmsMediaUtils from "@/src/core/app/domain/cms/utils/media";
import {T_MediaType} from "@/src/core/app/domain/models/media/T_MediaType";
import {T_MediaVideo} from "@/src/core/app/domain/models/media/T_MediaVideo";

interface _Props {
  media: T_Media
}

const _HeroCarouselMedia = (props: _Props) => {
  let media = props.media;

  const mediaType = CmsMediaUtils.getMediaType(media);
  if (mediaType === T_MediaType.IMAGE) {
    media = media as T_MediaImage;
    return (
      <div
        className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
        style={{flexBasis: "100%"}}
      >
        <MediaImageBgImg mediaImage={media} itemProp='image' />
      </div>
    );
  }

  if (mediaType === T_MediaType.VIDEO) {
    media = media as T_MediaVideo;
    return (
      <div
        className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
        style={{flexBasis: "100%"}}
      >
        <video
          controls
          style={{height: '100%', objectFit: 'cover'}}
          width="100%"
          height="100%"
          poster={media.field_media_video_poster.uri.url}
        >
          <source src={media.field_media_video_file.uri.url} type="video/mp4" />
        </video>
      </div>
    );
  }


  return null;
};

export default _HeroCarouselMedia;
