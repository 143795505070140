import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";
import {useState} from "react";
import cn from "classnames";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import Anchor from "@/src/ui/components/generic/Anchor";
import LanguageDropdown from "@/src/ui/components/generic/LanguageDropdown";
import CurrencyDropdown from "@/src/ui/components/generic/CurrencyDropdown";
import Section from "@/src/ui/components/generic/Section/Section";
import FooterSocialNetworks from "@/src/ui/components/layout/generic/FooterSocialNetworks";
import {useTranslation} from "next-i18next";

interface _SumenuProps {
  menuItem: CMS_MenuItem
}

const _SubMenu = ({ menuItem }: _SumenuProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full justify-between items-center pb-4 pt-1.5 border-b border-brand-3-60"
      >
        <h3 className="text-sm uppercase font-bold">{menuItem.title}</h3>
        <img
          className={cn("transition", {
            'transform -rotate-90': isOpen
          })}
          src="/assets/icons/triangle-left-white.svg"
          alt=""
        />
      </button>
      {isOpen && menuItem.below.length > 0 && (
        <ul className="overflow-x-hidden pt-2.5 transition-all">
          {menuItem.below.map((submenuItem) => (
            <li className="pb-2.5 text-sm hover:underline" key={submenuItem.id}>
              <Anchor href={submenuItem.url}>
                {submenuItem.title}
              </Anchor>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

const FooterMobile = (): JSX.Element => {
  const { t } = useTranslation();
  const routeProps = useRouteProps<any, UI_LayoutBase>();
  return (
    <footer className="bg-brand-2 text-white">
      <Section backgroundColor='none'>
        <ul className="pt-2">
          {routeProps.layoutData.menuFooterMain.items.map((item, index) => (
            <div className="flex-1" key={index}>
              <_SubMenu menuItem={item} />
            </div>
          ))}
        </ul>
      </Section>
      <Section backgroundColor='none'>
        <div className="flex space-x-10">
          <CurrencyDropdown spaceBetween />
          <LanguageDropdown />
        </div>
      </Section>
      <Section backgroundColor='none'>
        {/*<FooterNewsletterForm isMobile />*/}
      </Section>
      <div className="h-3" />
      <Section backgroundColor='none'>
        <FooterSocialNetworks socialNetworks={routeProps.layoutData.socialNetworks} isMobile />
      </Section>
      <Section backgroundColor='none'>
        <div className="pt-2.5 pb-10 text-xs text-center underline">
          {routeProps.layoutData.menuFooterSecondary.items.map((item, index) => (
            <div key={item.id}>
              <Anchor href={item.url}>
                {item.title}
              </Anchor>
            </div>
          ))}
        </div>
        <p className="pt-2.5 pb-3 text-xs text-center">
          {t('global.copyright.message')}
        </p>
      </Section>
    </footer>
  );
}

export default FooterMobile;
