import {SmallDropdownStyleProps} from "@/src/ui/components/generic/SmallDropdown/types";
import SmallDropdown from "@/src/ui/components/generic/SmallDropdown";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import CurrencyUtils from "@/src/core/app/utils/currency";
import {useCurrencyProvider} from "@/src/ui/providers/currency.provider";

const CurrencyDropdown = (props: SmallDropdownStyleProps): JSX.Element => {
  const currencyState = useCurrencyProvider(state => state);
  const routeProps = useRouteProps<any, UI_LayoutBase>();

  const onChange = (value: string) => {
    currencyState.setCurrencyCode(value);
    CurrencyUtils.setCurrencyCodeCookie(value);
  };

  return (
    <SmallDropdown
      currentOption={currencyState.currencyCode || ''}
      options={UiCurrencyUtils.getDropdownOptions(routeProps.layoutData.currencies)}
      onChange={onChange}
      {...props}
    />
  );
}

export default CurrencyDropdown;
