import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormInputSelect from "src/ui/components/formik/FormInputSelect";
import FormUtils from "@/src/ui/utils/form";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import CalendarUtils from "@/src/ui/utils/calendar";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";

const _PaymentDataMobile = () => {
  const { t } = useTranslation();
  return (
    <div className='px-5'>
      <TitledBox title={t('CheckoutCustomerInfoForm._PaymentData.title')}>
        <div className="px-5 py-4 space-y-3.5">
          <FormInputText
            label={t('CheckoutCustomerInfoForm._PaymentData.first_name_label') + ' *'}
            name="ccFirstName"
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            label={t('CheckoutCustomerInfoForm._PaymentData.last_name_label') + ' *'}
            name="ccLastName"
            validate={FormUtils.validateIsRequired}
          />
          <FormInputSelect
            label={t('CheckoutCustomerInfoForm._PaymentData.cc_type_label') + ' *'}
            name="ccType"
            validate={FormUtils.validateIsRequired}
            options={[
              { value: 'visa', label: 'Visa' },
              { value: 'mastercard', label: 'Mastercard' },
              { value: 'amex', label: 'American Express' },
            ]}
          />
          <FormInputText
            label={t('CheckoutCustomerInfoForm._PaymentData.cc_number_label') + ' *'}
            name="ccNumber"
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            label={t('CheckoutCustomerInfoForm._PaymentData.cvv_label') + ' *'}
            name="ccCVV"
            validate={FormUtils.validateIsRequired}
          />
          <div className='flex space-x-3.5'>
            <FormInputSelect
              label={t('CheckoutCustomerInfoForm._PaymentData.month_label') + ' *'}
              name="ccMonth"
              emptyOption={'- ' + t('CheckoutCustomerInfoForm._PaymentData.month_label')}
              validate={FormUtils.validateIsRequired}
              options={CalendarUtils.getMonthOptions()}
            />
            <FormInputSelect
              label={t('CheckoutCustomerInfoForm._PaymentData.year_label') + ' *'}
              name="ccYear"
              emptyOption={'- ' + t('CheckoutCustomerInfoForm._PaymentData.year_label')}
              validate={FormUtils.validateIsRequired}
              options={CalendarUtils.getYearOptions()}
            />
          </div>
          <div>
            <div className='my-5'>
              <VerisignImg />
            </div>
            <p className='text-sm'>
              {t('CheckoutCustomerInfoForm._PaymentData.warning')}
            </p>
          </div>
        </div>
      </TitledBox>
    </div>
  );
}

export default _PaymentDataMobile;
