import type {RouteProps} from "@/src/ui/@types/RouteProps";
import BlockListComponent from "@/src/ui/components/generic/BlockListComponent";
import {T_NodePage2Cols} from "@/src/core/app/domain/models/node/T_NodePage2Cols";
import Section2Cols from "@/src/ui/components/generic/Section2Cols";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import {PropsWithChildren} from "react";
import {useBookingManageProvider} from "@/src/ui/providers/booking-manage.provider";
import UiPage2colsUtils from "@/src/ui/utils/page-2cols";
import _Content from "@/src/ui/components/routes/Route_Page2Cols/content";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_Page2Cols = (props: RouteProps<T_NodePage2Cols>) : JSX.Element => {
  const content = UiPage2colsUtils.isManageBookingFormPresent(props.routeData.data) ?
    (<_Content {...props} />) :
    (<BlockListComponent blocks={props.routeData.data.field_blocks} />)
  ;

  return (
    <>
      <PageHeader header={props.routeData.data.page_header} />
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <Section2Cols
        content={content}
        sidebar={(
          <BlockListComponent blocks={props.routeData.data.field_blocks_margin} />
        )}
      />
    </>
  );
}

Route_Page2Cols.getAppWrapper = (
  props: PropsWithChildren<RouteProps<T_NodePage2Cols>>
) => {
  if (UiPage2colsUtils.isManageBookingFormPresent(props.routeData.data)) {
    return (
      <useBookingManageProvider.State>
        {props.children}
      </useBookingManageProvider.State>
    )
  }

  return (
    <>
      {props.children}
    </>
  )
}

export default Route_Page2Cols;
