import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";
import {UserLostPasswordResetError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserLostPasswordResetError";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";

const UserLostPasswordResetErrors : FormErrorMap = {
  [UserLostPasswordResetError.ACCOUNT_BLOCKED]: {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'user_lost_password_reset.account_blocked.error_title',
      message: 'user_lost_password_reset.account_blocked.error_message',
    },
  },
  [UserLostPasswordResetError.TOKEN_INVALID]: {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'user_lost_password_reset.token_invalid.error_title',
      message: 'user_lost_password_reset.token_invalid.error_message',
    },
  },
  [UserLostPasswordResetError.TOKEN_EXPIRED]: {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'user_lost_password_reset.token_expired.error_title',
      message: 'user_lost_password_reset.token_expired.error_message',
    },
  },
}

export default UserLostPasswordResetErrors;
