import {T_Pager} from "@/src/core/app/domain/models/pager/T_Pager";
import {arrayFromOneToN} from "@/src/common/utils/arrayFromOneToN";
import { Route } from "@/src/core/app/domain/@types/Route";

const UiPagerUtils = {
  isFirstPage: (pager: T_Pager) => {
    return pager.currentPage === 0;
  },
  isLastPage: (pager: T_Pager) => {
    return (pager.currentPage + 1) === pager.pagesCount;
  },
  getPageLink: (pager: T_Pager, route: Route, page: number) => {
    if (UiPagerUtils.isFirstPage(pager) && page === 0) {
      return undefined;
    }
    if (UiPagerUtils.isLastPage(pager) && (page === pager.currentPage)) {
      return undefined;
    }
    if (page < 0) {
      return undefined;
    }
    if (page >= pager.pagesCount) {
      return undefined;
    }

    const urlQueryParams = new URLSearchParams();
    Object.keys(route.queryParams).forEach(key => {
      urlQueryParams.set(key, route.queryParams[key] as string);
    });
    urlQueryParams.set('page', page.toString());

    if (page === 0) {
      urlQueryParams.delete('page');
    }

    const sUrlQueryParams = urlQueryParams.toString();
    if (sUrlQueryParams === '') {
      return route.path;
    }
    return `${route.path}?${sUrlQueryParams}`;
  },
  getPageLinks(pager: T_Pager) {
    const padLeft = (pager.currentPage - 2) < 0 ? 0 : pager.currentPage - 2;
    const padRight = (2 - (pager.pagesCount - pager.currentPage - 1)) < 0 ? 0 : (2 - (pager.pagesCount - pager.currentPage - 1));
    const start = (padLeft - padRight) >= 0 ? padLeft - padRight : 0;
    return arrayFromOneToN({
      count: pager.pagesCount,
      start,
      max: 5,
    });
  }
}

export default UiPagerUtils;
