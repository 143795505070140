import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockInfoCardsDesktop from "@/src/ui/components/blocks/BlockInfoCards/desktop";
import BlockInfoCardsMobile from "@/src/ui/components/blocks/BlockInfoCards/mobile";
import {T_ParagraphInfoCards} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoCards";


const BlockInfoCards = (props: T_ParagraphInfoCards) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockInfoCardsMobile : BlockInfoCardsDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockInfoCards;
