import Anchor from "@/src/ui/components/generic/Anchor";
import {T_HotelTree} from "@/src/core/app/domain/models/hotel-tree/T_HotelTree";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import {SitemapRouteHotelSections} from "@/src/core/app/domain/route/@types/SitemapRouteProps";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {CMS_HotelSection} from "@/src/core/app/domain/cms/@types/hotel/CMS_HotelSection";
import HotelUtils from "@/src/core/app/utils/hotel";

interface _RowHotelProps {
  item: T_HotelTreeHotel
  sections: CMS_HotelSection[]
}

interface _SitemapHotelMenuProps {
  title: string
  tree: T_HotelTree
  sections: SitemapRouteHotelSections
}

const _RowHotel = (props: _RowHotelProps) => (
  <li>
    <Anchor href={props.item.href || undefined}>
      {props.item.title}
    </Anchor>
    <ul>
      {props.sections.map(item => (
        <li key={item.id}>
          <Anchor href={HotelUtils.getSectionUrl(item) || undefined}>
            {item.meta.title}
          </Anchor>
        </li>
      ))}
    </ul>
  </li>
);

const _SitemapHotelMenu = (props: _SitemapHotelMenuProps) => (
  <div>
    <p>{props.title}</p>
    <ul>
      {HotelTreeUtils.getHotels(props.tree).map(item => (
        <_RowHotel
          key={item.id}
          item={item}
          sections={props.sections[item.id]}
        />
      ))}
    </ul>
  </div>
);

export default _SitemapHotelMenu;
