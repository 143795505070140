import WarningBanner from "@/src/ui/components/desktop/WarningBanner";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormUtils from "@/src/ui/utils/form";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

const _UserRegistrationMobile = () => {
  const { t } = useTranslation();
  return (
    <div className="px-5">
      <TitledBox bgColor="bg-yellow" title={t('CheckoutCustomerInfoForm._UserRegistration.title')}>
        <WarningBanner>
          <WrapperDiv
            dangerouslySetInnerHTML={{__html: t('CheckoutCustomerInfoForm._UserRegistration.warning')}}
          />
        </WarningBanner>
        <div className="px-5 py-4 space-y-3.5">
          <FormInputText
            label={t('CheckoutCustomerInfoForm._UserRegistration.password1_label') + " *"}
            name='userPassword1'
            type='password'
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            label={t('CheckoutCustomerInfoForm._UserRegistration.password2_label') + " *"}
            name='userPassword2'
            type='password'
            validate={FormUtils.validateIsRequired}
          />
          <p className="text-brand-2 text-xs leading-relaxed">
            {t('CheckoutCustomerInfoForm._UserRegistration.footer')}
          </p>
        </div>
      </TitledBox>
    </div>
  );
}

export default _UserRegistrationMobile;
