import {T_Paragraph} from "@/src/core/app/domain/models/paragraph/T_Paragraph";
import {CMS_ParagraphType} from "@/src/core/app/domain/cms/@types/entities/CMS_ParagraphType";

const UiBlockUtils = {
  isBlockInList: (list: T_Paragraph[], type: CMS_ParagraphType) : boolean => {
    const p = list.find(item => item.type === type);
    return p !== undefined;
  }
}

export default UiBlockUtils;
