import MapboxMapService from "@/src/ui/components/hotel/HotelMapSection/services/mapbox";
import LeafletMapService from "@/src/ui/components/hotel/HotelMapSection/services/leaflet";

const MapServiceFactory = {
  create: (key: string) => {
    if (key === 'mapbox') {
      return MapboxMapService;
    }
    return LeafletMapService;
  }
};

export default MapServiceFactory;
