import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {T_NodeQrPage} from "@/src/core/app/domain/models/node/T_NodeQrPage";
import Section from "@/src/ui/components/generic/Section/Section";
import cn from "classnames";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import UiMediaUtils from "@/src/ui/utils/media";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Carousel from "@/src/ui/components/generic/Carousel";

const Route_QrPage = (props: RouteProps<T_NodeQrPage>) : JSX.Element => {
  const device = useDevice();
  const node = props.routeData.data;
  return (
    <Section className={cn(
      device.isDesktop && 'max-w-190 mx-auto pt-10',
      device.isMobile && 'pt-7.5'
    )}>
      <MaxWidthWrapper>
        <h1
          className={cn(
            'text-2xl font-calistoga',
            device.isDesktop && 'pb-7.5',
            device.isMobile && 'pb-5',
          )}>
          {node.title}
        </h1>
        <Carousel
          className='w-full'
          medias={node.ui_cpt_field_qr_page_media}
        />
        <WrapperDiv
          size="medium"
          className="py-2.5 text-brand-2 Cms-Formatted"
          dangerouslySetInnerHTML={{__html: node.field_body.processed}}
        />
      </MaxWidthWrapper>
    </Section>
  );
}

export default Route_QrPage;
