import {CMS_UserTmsInfo} from "@/src/core/app/domain/cms/@types/user/CMS_UserTmsInfo";

export enum TmsInfoStatus {
  DISABLED = 'DISABLED',
  OK = 'OK',
  KO = 'KO',
  USER_ANONYMOUS = 'USER_ANONYMOUS',
  USER_WITHOUT_CLIENT_ID = 'USER_WITHOUT_CLIENT_ID',
}

export interface UserTmsInfoSuccessOutput {
  status: TmsInfoStatus
  data?: CMS_UserTmsInfo
  error?: string
}