import {T_ReservationManageRoom} from "@/src/core/app/domain/models/reservation/T_ReservationManageRoom";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiMediaUtils from "@/src/ui/utils/media";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import BookingManageUtils from "@/src/core/app/utils/booking-manage";
import {useTranslation} from "next-i18next";

interface _Props {
  room: T_ReservationManageRoom
}

const BookingManageRoom = (props: _Props) => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();
  const room = props.room;

  return (
    <article className="p-2.5 flex space-x-2.5 sm:space-x-5">
      {room.image && (
        <img
          className="h-20 w-20 object-cover flex-none"
          {...UiMediaUtils.getImgPropsFromMediaImage(room.image)}
        />
      )}
      <div className="flex-1 sm:flex pb-1 sm:pb-0 space-y-1">
        <div className="flex-1 space-y-1">
          <h4 className="text-medium-bold">{room.title}</h4>
          <ul className="text-xs text-brand-2 space-y-1 sm:space-y-0">
            <li className="sm:flex sm:space-x-2.5">
              <div className="w-21 flex-none text-brand-2-90">{t('BookingManageRoom._Index.room_occupation_label')}:</div>
              <div className="flex-1">
                {SearchParamsFormatter.formatRoomOccupation(room.user_adults_count, room.user_kids_count, t)}
              </div>
            </li>
            <li className="sm:flex sm:space-x-2.5">
              <div className="w-21 flex-none text-brand-2-90">
                {t('BookingManageRoom._Index.rate_and_board_label')}:
              </div>
              <div className="flex-1">
                {BookingManageUtils.getRateAndBoardInfo(room)}
              </div>
            </li>
            {/*<li className="sm:flex sm:space-x-2.5">*/}
            {/*  <div className="w-21 flex-none text-brand-2-90">Extras:</div>*/}
            {/*  <div className="flex-1">*/}
            {/*    {extras.map((extra) => (*/}
            {/*      <div key={extra}>{extra}</div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</li>*/}
          </ul>
        </div>
        <div className="w-38 flex-none flex items-end">
          <p className="text-xs text-brand-2">
            <span className="text-brand-2-90">{t('BookingManageRoom._Index.subtotal_label')}: </span>
            <em className="text-sm not-italic">
              {UiCurrencyUtils.format(room.total, currencyConfig)}
            </em>
            <span> {t('BookingManage._Index.taxes_included_label')}</span>
          </p>
        </div>
      </div>
    </article>
  )
};

export default BookingManageRoom;
