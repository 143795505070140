import {T_ParagraphJobOfferInfo} from "@/src/core/app/domain/models/paragraph/T_ParagraphJobOfferInfo";
import _JobOfferTeaser from "@/src/ui/components/blocks/BlockJobOfferInfo/job-offer-teaser";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

const _JobOffers = (props: T_ParagraphJobOfferInfo) => (
  <article className="p-3.75 sm:px-5 space-y-5 text-xs text-brand-2-90">
    <WrapperDiv
      dangerouslySetInnerHTML={{__html: props.field_joi_tab_header.processed}}
    />
    {props.jobOffers.length && (
      <ul className="space-y-2.5">
        {props.jobOffers.map(item => (
          <li key={item.id}>
            <_JobOfferTeaser item={item} />
          </li>
        ))}
      </ul>
    )}
    {!props.jobOffers.length && (
      <WrapperDiv
        dangerouslySetInnerHTML={{__html: props.field_joi_empty_text.processed}}
      />
    )}
  </article>
);

export default _JobOffers;
