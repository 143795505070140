import FormUtils from "@/src/ui/utils/form";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import {_FormProps} from "@/src/ui/components/forms/EventForm/types";
import CollapsedFormBox from "@/src/ui/components/desktop/CollapsedFormBox";
import FormInputTextarea from "@/src/ui/components/formik/FormInputTextarea";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import {useFormikContext} from "formik";
import {useEffect} from "react";
import {EventFormValues} from "@/src/core/app/domain/forms/EventFormValues";
import EventUtils from "@/src/core/app/utils/event";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";

const _MeetingRooms = (props: _FormProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  const context = useFormikContext<EventFormValues>();

  useEffect(() => {
    const iMeetingRooms = parseInt(context.values.meetingRooms);
    let meetingRoomsData = [];
    const diff = iMeetingRooms - context.values.meetingRoomsData.length;
    if (diff <= 0) {
      meetingRoomsData = [
        ...context.values.meetingRoomsData.splice(0, iMeetingRooms),
      ];
    }
    else {
      meetingRoomsData = [
        ...context.values.meetingRoomsData,
      ];
      for (let i = 0; i < diff; i++) {
        meetingRoomsData.push({
          people: '',
          setup: Object.keys(EventUtils.getMeetingRoomSetup())[0],
        })
      }
    }

    context.setFieldValue('meetingRoomsData', meetingRoomsData);
  }, [context.values.meetingRooms])

  return (
    <CollapsedFormBox title={t('EventForm._MeetingRooms.tittle')}>
      <div className="px-5 py-3.75 space-y-7.5">
        <div className="space-y-3.75">
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">
              {t('EventForm._MeetingRooms.meeting_rooms_label')}:
            </p>
            <div className="flex flex-1 space-x-5">
              <FormInputSelect
                name='meetingRooms'
                options={FormUtils.getNumberSelectOptions(5, 0)}
              />
            </div>
          </div>
          {props.formik.values.meetingRoomsData.map((item, index) => (
            <div
              key={index}
              className={cn(
                device.isDesktop && "flex",
                device.isMobile && "space-y-2.5",
              )}
            >
              <p className="flex-1 text-medium-bold text">Sala {index + 1}:</p>
              <div className="flex flex-1 space-x-5">
                <FormInputText
                  label={t('EventForm._MeetingRooms.people_label') + " *"}
                  name={`meetingRoomsData.${index}.people`}
                  validate={FormUtils.validateIsRequired}
                />
                <FormInputSelect
                  label={t('EventForm._MeetingRooms.setup_label') + " *"}
                  name={`meetingRoomsData.${index}.setup`}
                  options={I18nUtils.translateFormInputSelectOptions(
                    EventUtils.getMeetingRoomSetupSelectOptions(), t
                  )}
                  validate={FormUtils.validateIsRequired}
                />
              </div>
            </div>
          ))}
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">
              {t('EventForm._MeetingRooms.additional_info_label')}:
            </p>
            <div className="flex-1">
              <FormInputTextarea name='meetingRoomsAdditionalInfo' />
            </div>
          </div>
        </div>
      </div>
    </CollapsedFormBox>
  );
}

export default _MeetingRooms;
