import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockBlogPromotedDesktop from "@/src/ui/components/blocks/BlockBlogPromoted/desktop";
import BlockBlogPromotedMobile from "@/src/ui/components/blocks/BlockBlogPromoted/mobile";
import {T_ParagraphBlogPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogPromoted";


const BlockBlogPromoted = (props: T_ParagraphBlogPromoted) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockBlogPromotedMobile : BlockBlogPromotedDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockBlogPromoted;
