import HeaderCovidInfo from "../generic/HeaderCovidInfo";
import HeaderMobileTop from "./HeaderMobileTop";
import HeaderMobileSearchForm from "./HeaderMobileSearchForm";
import HeaderMobileMenu from "./HeaderMobileMenu";

const HeaderMobile = (): JSX.Element => (
  <>
    {/*<HeaderCovidInfo isMobile />*/}
    <header className="sticky top-0 inset-x-0 z-30">
      <div className="absolute inset-x-0" id='he-layout-header-mobile'>
        <HeaderMobileTop />
        <HeaderMobileSearchForm />
      </div>
    </header>
    <HeaderMobileMenu />
    <div className="h-32" />
  </>
);

export default HeaderMobile;
