import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Section from "@/src/ui/components/generic/Section/Section";
import {ReactNode, useState} from "react";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

interface _Props {
  backgroundColor?: string
  subtitle?: string
  title?: string
  titleWithTag?: CMS_FieldTextWithTag
  bodyHtml?: string
  bodyExpandedHtml?: string
  bodyItemProp?: string
  infoBox?: ReactNode
}

const InfoBlockDesktop = (props: _Props) => {
  const { t } = useTranslation();
  const isExpandable = props.bodyExpandedHtml !== undefined;
  const [expanded, setExpanded] = useState(false);

  return (
    <Section paddingY={'pb-10'} backgroundColor={props.backgroundColor}>
      <MaxWidthWrapper>
        <div className="pt-10 flex text-brand-2">
          <article className="flex-1">
            <hgroup className="space-y-2.5 pb-2.5">
              {props.subtitle && (<p className="title-5">{props.subtitle}</p>)}
              {props.title && (
                <h2 className='text-2xl font-calistoga'>
                  {props.title}
                </h2>
              )}
              <TitleWithTag
                field={props.titleWithTag}
                className='text-2xl font-calistoga'
              />
            </hgroup>
            {props.bodyHtml && (
              <WrapperDiv
                className="text-sm text-brand-2-90 Cms-Formatted"
                itemProp={props.bodyItemProp}
                dangerouslySetInnerHTML={{__html: expanded ? [props.bodyHtml, props.bodyExpandedHtml].join('') : props.bodyHtml}}
              />
            )}
            {isExpandable && (
              <span className="py-5 text-sm text-brand-2-90 underline cursor-pointer" onClick={() => setExpanded(!expanded)}>
                {expanded ? t('global.generic_link.read_less') : t('global.generic_link.read_more')}
              </span>
            )}
          </article>
          {props.infoBox && (
            <div className="flex-none w-96 pl-10">
              {props.infoBox}
            </div>
          )}
        </div>
      </MaxWidthWrapper>
    </Section>
  );
}

export default InfoBlockDesktop;
