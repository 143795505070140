import {useDevice} from "@/src/ui/hooks/useDevice";
import _UserRegistrationMobile from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/mobile/user-registration";
import _UserRegistrationDesktop from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/desktop/user-registration";

const _UserRegistration = () => {
  const device = useDevice();
  const _Component = device.isMobile ? _UserRegistrationMobile : _UserRegistrationDesktop;
  return (
    <_Component />
  );
}

export default _UserRegistration;
