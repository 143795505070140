import {useState} from "react";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {T_ParagraphHotelListFiltrable} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelListFiltrable";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import {T_HotelTreeDestination} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeDestination";
import BlockHotelListFiltrableMobile from "@/src/ui/components/blocks/BlockHotelListFiltrable/mobile";
import BlockHotelListFiltrableDesktop from "@/src/ui/components/blocks/BlockHotelListFiltrable/desktop";
import {
  BlockListHotelFiltrableMode
} from "@/src/ui/components/blocks/BlockHotelListFiltrable/@types/BlockListHotelFiltrableMode";

const getHotelsByDestination = (hotelTeasers: T_HotelTeaser[], destination: string | null) => {
  if (destination === null) {
    return hotelTeasers;
  }
  return hotelTeasers.filter(item => item.field_destination.id === destination);
}

const getOptions = (destinations: T_HotelTreeDestination[]) => {
  return destinations.sort((a, b) => {
    const diff = b.hotels.length - a.hotels.length;
    if (diff === 0) {
      return a.destinationName.localeCompare(b.destinationName);
    }
    return diff;
  }).map((destination) => ({
    value: destination.id,
    label: `${destination.destinationName} (${destination.hotels.length})`,
  }))
};

const getCurrentOption = (destination: T_HotelTreeDestination | null) => {
  if (!destination) {
    return null;
  }
  return {
    value: destination.id,
    label: `${destination.destinationName} (${destination.hotels.length})`,
  };
}

const BlockHotelListFiltrable = (props: T_ParagraphHotelListFiltrable) => {
  const [mode, setMode] = useState(BlockListHotelFiltrableMode.LIST);
  const [currentDestination, setCurrentDestination] = useState<T_HotelTreeDestination | null>(null);
  const device = useDevice();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const destinations = HotelTreeUtils.getDestinations(layoutData.hotelTree);

  const onChange = (option: FormInputSelectOption | null) => {
    if (option === null) {
      setCurrentDestination(null);
      return;
    }

    const destination = destinations.find(item => item.id === option.value) as T_HotelTreeDestination;
    setCurrentDestination(destination);
  }

  const toggleMode = () => {
    if (mode === BlockListHotelFiltrableMode.LIST) {
      setMode(BlockListHotelFiltrableMode.MAP);
      return;
    }

    setMode(BlockListHotelFiltrableMode.LIST);
  };

  const _Component = device.isMobile ? BlockHotelListFiltrableMobile : BlockHotelListFiltrableDesktop;
  return (
    <_Component
      paragraph={props}
      options={getOptions(destinations)}
      currentOption={getCurrentOption(currentDestination)}
      onChange={onChange}
      hotelTeasers={getHotelsByDestination(props.hotelTeasers, currentDestination ? currentDestination.id : null)}
      mode={mode}
      toggleMode={toggleMode}
    />
  );
}
export default BlockHotelListFiltrable;
