import Paragraph from "../../generic/Paragraph";
import {T_NodeExperienceTeaser} from "@/src/core/app/domain/models/node/T_NodeExperienceTeaser";
import HorizontalCard from "@/src/ui/components/mobile/HorizontalCard";
import Anchor from "@/src/ui/components/generic/Anchor";
import ExperienceUtils from "@/src/core/app/utils/experience";
import {useTranslation} from "next-i18next";

type _Props = {
  experience: T_NodeExperienceTeaser
}
const NodeExperienceTeaserMobile = ({experience}: _Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Anchor href={ExperienceUtils.getLinkUrl(experience)}>
      <HorizontalCard
        mediaImage={experience.field_experience_teaser_mobile}
        className='bg-img-blue-shade-card'
      >
        <h3 className="text-lg font-calistoga">{experience.title}</h3>
        <Paragraph size="medium" className="text-center pt-2.5 pb-3.5">
          {t('NodeExperienceTeaser._Index.subtitle', {
            count: experience.hotelCount,
          })}
        </Paragraph>
      </HorizontalCard>
    </Anchor>
  );
}

export default NodeExperienceTeaserMobile;
