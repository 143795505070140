import Anchor from "@/src/ui/components/generic/Anchor";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_ParagraphResidentsList} from "@/src/core/app/domain/models/paragraph/T_ParagraphResidentsList";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const _Column = (props: T_ParagraphResidentsList) => (
  <Section>
    <TitleWithTag
      className="text-brand-2 font-bold text-lg text-center pb-5"
      field={props.field_title_with_tag}
    />
    <ul className="grid grid-cols-3 gap-2.5 px-1.5">
      {props.residents.map((item, index) => (
        <li key={item.id}>
          <Anchor path={item.path} className='relative h-25 flex items-center justify-center'>
            <h3 className="text-center text-white text-lg font-bold z-10">
              {item.title}
            </h3>
            <MediaImageBgImg mediaImage={item.field_resident_home_mobile} />
          </Anchor>
        </li>
      ))}
    </ul>
  </Section>
);

export default _Column;