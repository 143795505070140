import { ReactNode } from "react";
import cn from "classnames";

interface WrapperDivProps {
  children?: ReactNode
  size?: "big" | "medium" | "small"
  className?: string
  itemProp?: string
  dangerouslySetInnerHTML?: any
}

const WrapperDiv = ({
  children,
  size,
  className,
  itemProp,
  dangerouslySetInnerHTML,
}: WrapperDivProps): JSX.Element => (
  <div
    className={cn(
      size === "big" && "text-lg",
      size === "medium" && "text-sm",
      size === "small" && "text-xs",
      className
    )}
    itemProp={itemProp}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </div>
);

export default WrapperDiv;
