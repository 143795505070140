import Anchor from "@/src/ui/components/generic/Anchor";
import FormInputCheckbox from "@/src/ui/components/formik/FormInputCheckbox";
import FormUtils from "@/src/ui/utils/form";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import {useTranslation} from "next-i18next";

interface _Props {
  name: string
  label1?: string
  label2?: string
  color?: "white" | "brand-2"
  href?: string
  isRequired?: boolean
  requiredErrorMessage?: string
}

const FormTermsAndConditions = (props: _Props) => {
  const { t } = useTranslation();
  const layoutData = useLayoutData<UI_LayoutBase>();

  const {
    name,
    label1 = t("FormTermsAndConditions._Index.default_label1"),
    label2 = t("FormTermsAndConditions._Index.default_label2"),
    color = 'brand-2',
    isRequired = false,
    requiredErrorMessage = t('FormTermsAndConditions._Index.default_required_error_message')
  } = props;

  const requiredValidation = (value: any) => {
    if (!value) {
      return requiredErrorMessage;
    }
  };

  return (
    <FormInputCheckbox
      name={name}
      color={color}
      validate={isRequired ? requiredValidation : undefined}
    >
      {label1}
      <Anchor className="text-link" href={layoutData.sitePageUrls.terms_and_conditions_page_url}>
        {label2}
      </Anchor>
    </FormInputCheckbox>
  )
}

export default FormTermsAndConditions;
