import {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import HeaderHotelCheckoutDesktop from "@/src/ui/components/checkout/desktop/HeaderHotelCheckout";
import ProcessSteps from "src/ui/components/generic/ProcessSteps";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import CheckoutLayout from "@/src/ui/components/checkout/desktop/CheckoutLayout";
import CheckoutSummary from "src/ui/components/checkout/generic/CheckoutSummary";
import CheckoutCustomerInfoForm from "src/ui/components/checkout/generic/CheckoutCustomerInfoForm";

const CheckoutStepCustomerInfoDesktop = (props: RouteProps<T_CheckoutHotel>) => {
  const formExtrasEnabled = props.routeData.data.form_extras_enabled;
  return (
    <>
      <HeaderHotelCheckoutDesktop
        checkoutHotel={props.routeData.data}
      />
      <ProcessSteps
        step={formExtrasEnabled ? 3 : 2}
        steps={CheckoutUtils.getSteps(formExtrasEnabled)}
      />
      <CheckoutLayout
        rightChild={
          <CheckoutSummary />
        }
        leftChild={
          <CheckoutCustomerInfoForm />
        }
      />
    </>
  );
}

export default CheckoutStepCustomerInfoDesktop;
