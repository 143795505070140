import {T_HotelSectionHotelCommonInfo} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";
import {useCarouselBasic} from "@/src/ui/hooks/useCarouselBasic";
import {T_HotelSectionDataMain} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataMain";
import TriangleSeparator from "src/ui/components/generic/TriangleSeparator";
import _HeroCarouselMedia from "@/src/ui/components/routes/Route_HotelSection/mobile/_HeroCarouselMedia";

interface _Props {
  hotel: T_HotelSectionHotelCommonInfo
  sectionMainData: T_HotelSectionDataMain
}

const _HeroCarousel = (props: _Props): JSX.Element => {
  const {
    emblaRef
  } = useCarouselBasic(props.hotel);

  return (
    <div className="bg-gray-20">
      <div className="h-60 overflow-hidden embla" ref={emblaRef}>
        <div className="embla__container flex h-full">
          {props.sectionMainData.field_hotel_slider_mobile.map((media) => (
            <_HeroCarouselMedia key={media.id} media={media} />
          ))}
        </div>
      </div>
      <TriangleSeparator />
    </div>
  );
}

export default _HeroCarousel;
