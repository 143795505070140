import {T_NodeDestinationTeaser} from "@/src/core/app/domain/models/node/T_NodeDestinationTeaser";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import UiMediaUtils from "@/src/ui/utils/media";
import {useDevice} from "@/src/ui/hooks/useDevice";
import Anchor from "@/src/ui/components/generic/Anchor";

const DestinationTeaserSquared = (props: T_NodeDestinationTeaser) => {
  const device = useDevice();

  const image = device.isMobile ?
    props.ui_cpi_field_destination_teaser_mobile :
    props.ui_cpi_field_destination_teaser
  ;

  return (
    <Anchor path={props.path}>
      <article className="border border-gray-40 bg-white">
        <img
          className="w-full h-55 object-cover"
          {...UiMediaUtils.getImgPropsFromMediaImage(image)}
        />
        <div className="p-3.75 text-sm text-brand-2">
          <h3 className="text-big-bold">{props.title}</h3>
          <div className="flex items-center space-x-3 pt-1 pb-2.5">
            <img className="h-4 w-auto" src="/assets/icons/place.svg" alt="" />
            <p>{props.field_destination_name}</p>
          </div>
          {props.field_teaser && (
            <WrapperDiv
              className="text-brand-2-90"
              dangerouslySetInnerHTML={{__html: props.field_teaser.processed}}
            />
          )}
        </div>
      </article>
    </Anchor>
  )
}

export default DestinationTeaserSquared;
