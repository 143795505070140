import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {SitemapRouteProps} from "@/src/core/app/domain/route/@types/SitemapRouteProps";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import cn from "classnames";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useTranslation} from "next-i18next";
import {CMS_MenuHeaderUserItemVisibility} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuHeaderUserItem";
import _SitemapMenu from "@/src/ui/components/routes/Route_Sitemap/menu";
import _SitemapDestinationMenu from "@/src/ui/components/routes/Route_Sitemap/destination-menu";
import _SitemapHotelMenu from "@/src/ui/components/routes/Route_Sitemap/hotel-menu";
import _SitemapOfferMenu from "@/src/ui/components/routes/Route_Sitemap/offer-menu";
import AppController from "@/src/ui/controllers/AppController";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import UrlUtils from "@/src/core/app/utils/url";


const Route_Sitemap = (props: RouteProps<SitemapRouteProps>): JSX.Element => {
  const { t } = useTranslation();
  const routeProps = useRouteProps();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const device = useDevice();

  return (
    <Section className={cn(
      device.isDesktop && 'mx-auto pt-10',
      device.isMobile && 'pt-7.5'
    )}>
      <MaxWidthWrapper>
        <h1
          className={cn(
            'text-2xl font-calistoga',
            device.isDesktop && 'pb-7.5',
            device.isMobile && 'pb-5',
          )}>
          {t('Route_Sitemap._Index.page_title')}
        </h1>
        <div className="py-2.5 text-brand-2 Cms-Formatted">
          <ul>
            <li>
              <Anchor
                href={UrlUtils.buildAppUrlWithLangPrefix(routeProps.appInfo.langcode, '/')}
              >
                {t('global.generic.site_name')}
              </Anchor>
            </li>
          </ul>
          <div className="grid grid-cols-4 space-x-3">
            <_SitemapMenu
              title={t('Route_Sitemap._Index.menu_header_main_title')}
              items={layoutData.menuHeaderMain.items}
              extra={(
                <li>
                  <Anchor href={layoutData.sitePageUrls.destination_list_page_url}>
                    {t('HeaderDesktopMenu._DestinationMenu.destination_label')}
                  </Anchor>
                </li>
              )}
            />
            <_SitemapMenu
              title={t('Route_Sitemap._Index.menu_header_secondary_title')}
              items={layoutData.menuHeaderSecondary.items}
            />
            <_SitemapMenu
              title={t('Route_Sitemap._Index.menu_header_user_title')}
              items={layoutData.menuHeaderUser.items.filter(
                item=>
                  item.field_menu_hu_visibility === CMS_MenuHeaderUserItemVisibility.ONLY_ANONYMOUS
              )}
            />
            <_SitemapMenu
              title={t('Route_Sitemap._Index.menu_footer_main_title')}
              items={layoutData.menuFooterMain.items}
              showParents={true}
            />
            <_SitemapMenu
              title={t('Route_Sitemap._Index.menu_popup_title')}
              items={layoutData.menuPopup.items}
            />
            <_SitemapDestinationMenu
              title={t('Route_Sitemap._Index.menu_destination_label')}
              items={layoutData.destinations}
            />
            <_SitemapHotelMenu
              title={t('Route_Sitemap._Index.menu_hotel_sections_label')}
              tree={layoutData.hotelTree}
              sections={props.routeData.data.hotelSections}
            />
            <_SitemapOfferMenu
              title={t('Route_Sitemap._Index.menu_offers_title')}
              items={props.routeData.data.offerTeasers}
            />
            <_SitemapMenu
              title={t('Route_Sitemap._Index.menu_footer_secondary')}
              items={layoutData.menuFooterSecondary.items}
            />
          </div>
        </div>
      </MaxWidthWrapper>
    </Section>
  );
}

export default Route_Sitemap;
