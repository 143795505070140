import {
  HotelCalendarAvailAndRatesOutputRoomRateForSpecificDate
} from "@/src/core/app/domain/booking/@types/Output/HotelCalendarAvailAndRatesOutput";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import _Td, {TdStyle} from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/td";
import {CheckoutHotelCalendarDayOnClick} from "@/src/ui/@types/checkout/CheckoutHotelCalendarDayOnClick";
import {useTranslation} from "next-i18next";

interface _Props {
  onClick: CheckoutHotelCalendarDayOnClick
  roomRate: HotelCalendarAvailAndRatesOutputRoomRateForSpecificDate
  isSelected: boolean
  dt: string
}

const _Cell = (props: _Props) => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();

  if (!props.roomRate.isAvailable) {
    return (
      <_Td style={TdStyle.PRICING_UNAVAILABLE}>
        {t('BlockHotelRoomListDesktop._CalendarStatusOk.room_not_available')}
      </_Td>
    );
  }

  return (
    <_Td
      style={props.isSelected ? TdStyle.PRICING_SELECTED : TdStyle.PRICING_NORMAL}
      onClick={() => props.onClick(props.dt)}
    >
      {UiCurrencyUtils.format(props.roomRate.price as number, currencyConfig)}
    </_Td>
  );
};

export default _Cell;
