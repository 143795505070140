import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import CheckoutClientFormSummaryPrice from "@/src/ui/components/desktop/CheckoutClientFormSummaryPrice";
import FormTermsAndConditions from "@/src/ui/components/formik/FormTermsAndConditions";
import {useTranslation} from "next-i18next";

const _SummaryDesktop = (): JSX.Element | null => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();
  const checkout = useCheckoutProvider(state => state);

  if (checkout.isInitiated === false) {
    return null;
  }

  const [payNow, payLater] = CheckoutUtils.getTotalAmountSplitted(checkout);

  return (
    <article className="p-5 bg-gray-20 space-y-10 text-sm text-brand-2">
      <FormTermsAndConditions
        name='termsAndConditions'
        label2={t('CheckoutCustomerInfoForm._Summary.terms_and_conditions_label') + ' *'}
      />
      <div className="flex space-y-7.5 sm:space-y-0 flex-col sm:flex-row">
        <CheckoutClientFormSummaryPrice
          title={t('CheckoutCustomerInfoForm._Summary.hotel_payment_label')}
          price={UiCurrencyUtils.format(payLater, currencyConfig)}
        />
        <CheckoutClientFormSummaryPrice
          title={t('CheckoutCustomerInfoForm._Summary.now_payment_label')}
          price={UiCurrencyUtils.format(payNow, currencyConfig)}
        />
      </div>
    </article>
  );
}

export default _SummaryDesktop;
