import React, {ReactNode} from "react";
import {Field, useField} from "formik";
import cn from "classnames";
import {FieldValidator} from "formik/dist/types";

interface _Props {
  name: string
  children: ReactNode
  color?: "white" | "brand-2"
  value?: string
  showError?: boolean
  validate?: FieldValidator
}

const FormInputCheckbox = (props: _Props): JSX.Element => {
  const {
    children,
    color,
    value,
    showError = true,
    ...fieldProps
  } = props;

  const [field, meta, helpers] = useField(fieldProps);
  return (
    <label className="flex cursor-pointer">
      <div
        className={cn(
          color === "white" ? "w-3.5 h-3.5" : "w-4 h-4",
          `flex-none flex items-center justify-center border border-${color} mr-2.5`
        )}
      >
        <Field type='checkbox' {...field} value={props.value} className='checkbox checkbox2' />
        <div className="h-2.5 w-2.5 bg-brand-1 hover:bg-brand-1-hover checkbox__check" />
      </div>
      <div className="space-y-1.5 leading-tight text-sm">
        {children}
        {showError && meta.error && <p className="text-xs text-red">{meta.error}</p>}
      </div>
    </label>
  );
};

export default FormInputCheckbox;
