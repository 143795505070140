import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";

const BookingConfirmationSummaryReservation = () => {
  const { t } = useTranslation();
  const routeData = useRouteData<BookingConfirmationRouteProps>();

  const dateRange = {
    start: routeData.data.reservation.start,
    end: routeData.data.reservation.end,
  };
  const sDates = `${SearchParamsFormatter.formatRange(dateRange)} (${SearchParamsFormatter.getDateRangeNightsSummary(dateRange, t)})`;
  return (
    <article className="p-5 sm:flex space-y-3.75 sm:space-y-0 sm:space-x-7.5 border border-gray-20 text-sm text-brand-2">
      <div className="flex-1 space-y-1.5">
        <h3 className="text-brand-2-60 font-bold">{t('BookingConfirmationSummaryReservation._Index.hotel_label')}</h3>
        <p>{routeData.data.hotel.title}</p>
      </div>
      <div className="flex-1 space-y-1.5">
        <h3 className="text-brand-2-60 font-bold">{t('BookingConfirmationSummaryReservation._Index.dates_label')}</h3>
        <p>{sDates}</p>
      </div>
    </article>
  )
};

export default BookingConfirmationSummaryReservation;
