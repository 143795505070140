import {
  T_ParagraphBlogList,
  T_ParagraphBlogList__Display
} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogList";
import _Last from "@/src/ui/components/blocks/BlockBlogList/desktop/by-display/last";
import _All from "@/src/ui/components/blocks/BlockBlogList/desktop/by-display/all";


const BlockBlogListDesktop = (props: T_ParagraphBlogList) => {
  const _Component = props.field_blog_display === T_ParagraphBlogList__Display.LAST ? _Last : _All;
  return (
    <_Component {...props} />
  );
};

export default BlockBlogListDesktop;
