import {T_OfferTeaser} from "@/src/core/app/domain/models/offer/T_OfferTeaser";
import CalendarUtils from "@/src/ui/utils/calendar";
import _Label from "@/src/ui/components/offer/OfferTeaser/header/label";
import {useTranslation} from "next-i18next";
import UiOfferUtils from "@/src/ui/utils/offer";
import UrlUtils from "@/src/core/app/utils/url";

interface _Props {
  item: T_OfferTeaser
}

const _HeaderRange = (props: _Props) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="absolute inset-0 bg-img-blue-shade" />
      <div className="absolute top-4 right-5">
        <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
          <_Label content={t('OfferTeaser._HeaderRange.from_label', {
            amount: props.item.price_from
          })}/>
          {props.item.price_to && (
            <_Label content={t('OfferTeaser._HeaderRange.to_label', {
              amount: props.item.price_to
            })}/>
          )}
          <link itemProp="itemCondition" href="https://schema.org/NewCondition"/>
          <meta itemProp="priceCurrency" content="EUR"/>
          <meta itemProp="priceValidUntil" content={`${CalendarUtils.getCurrentYear()}-12-31`}/>
          <meta itemProp="price" content={props.item.price_from.toString()}/>
          <link itemProp="availability" href="https://schema.org/InStock"/>
          <meta itemProp="url" content={UrlUtils.buildPublicUrl(UiOfferUtils.getUrl(props.item))}/>
          <meta itemProp="validFrom" content={`${CalendarUtils.getCurrentYear()}-12-31`}/>
        </div>
        <span itemProp="attendee organizer performer" itemScope itemType="http://schema.org/Organization"
              style={{'display': 'none'}}
        >
	        <span itemProp="name">© Elba Hoteles</span>
        </span>
        <span itemProp="attendee organizer performer" itemScope itemType="http://schema.org/Person"
              style={{'display': 'none'}}
        >
	        <span itemProp="name">© Elba Hoteles</span>
        </span>
      </div>
      <span itemProp="attendee organizer performer" itemScope itemType="https://schema.org/Organization"
            style={{'display': 'none'}}>
      <span itemProp="name">© Elba Hoteles</span>
    </span>
      <span itemProp="attendee organizer performer" itemScope itemType="https://schema.org/Person" style={{'display': 'none'}}>
      <span itemProp="name">© Elba Hoteles</span>
    </span>
    </>
  );
}

export default _HeaderRange;
