import {T_PageHeader} from "@/src/core/app/domain/models/page/T_PageHeader";
import TriangleSeparator from "src/ui/components/generic/TriangleSeparator";
import UiMediaUtils from "@/src/ui/utils/media";

interface _Props {
  header: T_PageHeader
}

const PageHeaderMobile = (props: _Props): JSX.Element => {
  const header = props.header;

  const imgProps = UiMediaUtils.getImgPropsFromMediaImage(header.image_mobile);
  return (
    <div>
      <img className="w-full h-30 object-cover" {...imgProps} />
      <section className="bg-gray-20">
        <TriangleSeparator />
        <hgroup className="p-5 space-y-1">
          <h1 className="title-5">{header.title}</h1>
          <h2 className="title-1-mobile">{header.subtitle}</h2>
        </hgroup>
      </section>
    </div>
  );
}

export default PageHeaderMobile;
