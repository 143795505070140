import {ReactNode} from "react";
import {useDevice} from "@/src/ui/hooks/useDevice";
import Section2ColsMobile from "@/src/ui/components/generic/Section2Cols/mobile";
import Section2ColsDesktop from "@/src/ui/components/generic/Section2Cols/desktop";

interface _Props {
  content: ReactNode
  sidebar: ReactNode
}

const Section2Cols = (props: _Props): JSX.Element => {
  const device = useDevice();
  const _Component = device.isMobile ? Section2ColsMobile : Section2ColsDesktop;
  return (
    <_Component {...props} />
  );
}

export default Section2Cols;
