import React from "react";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import _Features from "@/src/ui/components/portals/desktop/ModalLounge/_Features";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {T_Lounge} from "@/src/core/app/domain/models/lounge/T_Lounge";

const _Summary = (props: T_Lounge): JSX.Element => (
  <div className="px-5 flex-none">
    <MaxWidthWrapper>
      <div className="pb-6 space-y-5">
        <h2 className="title-4">{props.field_title}</h2>
        {props.field_body && (
          <WrapperDiv
            size="small"
            className="pt-1 text-brand-2-90 max-w-screen-md"
            dangerouslySetInnerHTML={{__html: props.field_body.processed}}
          />
        )}
        <_Features lounge={props} />
      </div>
    </MaxWidthWrapper>
  </div>
);

export default _Summary;
