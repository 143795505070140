import Button from "@/src/ui/components/generic/Button";
import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Form, Formik} from "formik";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {T_NodeJobOfferDetail} from "@/src/core/app/domain/models/node/T_NodeJobOfferDetail";
import {JobOfferFormValues} from "@/src/core/app/domain/forms/JobOfferFormValues";
import FormTermsAndConditions from "@/src/ui/components/formik/FormTermsAndConditions";
import FormInputFile from "@/src/ui/components/formik/FormInputFile";
import FormUtils from "@/src/ui/utils/form";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {useTranslation} from "next-i18next";
import FormFileUtils from "@/src/ui/utils/form-file";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import JobOfferUtils from "@/src/core/app/utils/job-offer";
import JobOfferFormErrors from "@/src/core/app/domain/job-offer/@types/FormErrorMap/JobOfferFormErrors";
import FormRecaptcha from "@/src/ui/components/formik/FormRecaptcha";

interface _FormProps {
  jobOffer: T_NodeJobOfferDetail
  formik: FormikProps<JobOfferFormValues>
}

interface _Props {
  jobOffer: T_NodeJobOfferDetail
}

const _Form = (props: _FormProps) => {
  const { t } = useTranslation();
  return (
    <TitledBox title={t('JobOfferForm._Index.title')}>
      <Form className="px-5 py-3.75 space-y-7.5">
        <FormInputFile
          label={t('JobOfferForm._Index.file_label') + ' *'}
          description={t('FormInputFile._Index.description')}
          name='cv'
          validations={[FormUtils.validateIsRequired, FormFileUtils.validateDocExtensions, FormFileUtils.validateDocFileSize]}
          accept={FormFileUtils.getDocAccept()}
        />
        <FormTermsAndConditions name='termsAndConditions' isRequired={true} />
        <FormRecaptcha name='recaptcha' />
        <div>
          <Button
            text={t('JobOfferForm._Index.submit_button_label')}
            bold
            onClick={props.formik.handleSubmit}
            isActive={!props.formik.isSubmitting}
          />
        </div>
      </Form>
    </TitledBox>
  );
}

const JobOfferForm = (props: _Props) => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  let initialValues : JobOfferFormValues = {
    jobOffer: props.jobOffer.id,
    cv: null,
    recaptcha: '',
    termsAndConditions: false,
  };

  return (
    <Formik<JobOfferFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        const base64 = await FormFileUtils.getBase64(values.cv);
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await JobOfferUtils.sendJobOfferForm(appInfo, values.jobOffer, values.cv.name, base64);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: JobOfferFormErrors,
            logFn: (reason) => FormErrorUtils.logNetworkError('Job offer request form error', reason, networkError),
          });
          return;
        }
        formikHelpers.setValues(initialValues);
        modalState.toggleModal(ModalKey.NOTIFICATION, {
          type: T_NotificationType.SUCCESS,
          title: 'JobOfferForm._Index.success_notification_title',
          message: 'JobOfferForm._Index.success_notification_message',
        });
      }}
    >
      {(formik: FormikProps<JobOfferFormValues>) => (
        <_Form
          formik={formik}
          jobOffer={props.jobOffer}
        />
      )}
    </Formik>
  );
}

export default JobOfferForm;
