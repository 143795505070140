import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import MetaUtils from "@/src/core/app/utils/meta";

interface _Props {
  hotelSection: T_HotelSection
}

const _JsonLd = (props: _Props) => {
  const meta = MetaUtils.getMeta(props.hotelSection.metatag);
  const data = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    "name": props.hotelSection.title,
    "description": meta?.description ?? '',
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "ES",
      "addressLocality": props.hotelSection.ui_hotel_common_info.locality,
      "addressRegion": props.hotelSection.ui_hotel_common_info.region,
      "postalCode": props.hotelSection.ui_hotel_common_info.postal_code,
      "streetAddress": props.hotelSection.ui_hotel_common_info.address,
    },
    "telephone": props.hotelSection.ui_hotel_common_info.phone,
    "image": props.hotelSection.ui_hotel_common_info.ui_cpt_field_hotel_teaser.field_media_image.uri.url,
  };
  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: JSON.stringify(data)
    }} />
  );
} ;

export default _JsonLd;
