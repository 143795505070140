import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import HotelUtils from "@/src/core/app/utils/hotel";
import UiMediaUtils from "@/src/ui/utils/media";
import StarRating from "@/src/ui/components/generic/StarRating";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import Anchor from "@/src/ui/components/generic/Anchor";
import {CMS_ParagraphHotelList_LinkSection} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelTeaser: T_HotelTeaser
  linkSection?: CMS_ParagraphHotelList_LinkSection
}

const HotelTeaserSquared = (props: _Props) => {
  const { t } = useTranslation();
  const {
    hotelTeaser,
    linkSection = CMS_ParagraphHotelList_LinkSection.MAIN,
  } = props;
  const href = HotelUtils.getLinkToSection(hotelTeaser, linkSection) || undefined;
  return (
    <article className="flex-1 flex flex-col border border-gray-40 bg-white">
      <Anchor href={href}>
        <img
          className="flex-none h-55 w-full object-cover"
          {...UiMediaUtils.getImgPropsFromMediaImage(hotelTeaser.ui_cpt_field_hotel_teaser)}
        />
      </Anchor>
      <div className="flex-1 p-3.75 space-y-2.5 flex flex-col">
        <div className="flex-1 space-y-1">
          <StarRating rating={hotelTeaser.field_stars} />
          <Anchor href={href}>
            <h3 className="text-big-bold pt-px">{hotelTeaser.title}</h3>
          </Anchor>
          <p className="text-sm text-brand-2-40">{hotelTeaser.locality}</p>
        </div>
        {href && (
          <div className="flex-none">
            <ArrowLink href={href}>
              {t('HotelTeaserSquared._Index.link_label')}
            </ArrowLink>
          </div>
        )}
      </div>
    </article>
  );
}

export default HotelTeaserSquared;