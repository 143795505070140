import {CMS_FieldTextTextarea} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextTextarea";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

interface _Props {
  content: CMS_FieldTextTextarea
}

const BlockUserTmsInfoHeader = (props: _Props) => {
  return (
    <article className="p-3.75 sm:px-5 bg-yellow-15 space-y-3.75 sm:space-y-5">
      <WrapperDiv
        className="space-y-2.5 Cms-Formatted"
        size="medium"
        dangerouslySetInnerHTML={{__html: props.content.processed}}
      />
    </article>
  );
}

export default BlockUserTmsInfoHeader;