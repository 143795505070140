import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import OfferSearchMenu from "@/src/ui/components/offer/OfferSearchMenu";
import OfferTeaserList from "@/src/ui/components/offer/OfferTeaserList";
import Section from "@/src/ui/components/generic/Section/Section";
import {OfferSearchProps} from "@/src/ui/components/offer/OfferSearch/types";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import PaginationWrapper from "@/src/ui/components/generic/PaginationWrapper";
import React from "react";
import OfferSearchDateFilter from "@/src/ui/components/offer/OfferSearch/common/date-filter";

const OfferSearchDesktop = (props: OfferSearchProps) => (
  <Section paddingY="py-7.5">
    <MaxWidthWrapper>
      <div className="pb-7.5 flex justify-between items-center">
        {props.titleWithTag && (
          <TitleWithTag
            className="title-3 font-calistoga"
            field={props.titleWithTag}
            suffix={`(${props.count})`}
          />
        )}
      <OfferSearchDateFilter />
      </div>
      <div className="flex pb-5">
        <div className="flex-none w-75 pr-20">
          <OfferSearchMenu
            offerGroupLinks={props.offerGroupLinks}
            experienceLinks={props.experienceLinks}
          />
        </div>
        <div className="flex-1">
          <OfferTeaserList items={props.offerTeasers} />
          <PaginationWrapper
            className="flex justify-center pt-8"
            pager={props.pager}
          />
        </div>
      </div>
    </MaxWidthWrapper>
  </Section>
);

export default OfferSearchDesktop;