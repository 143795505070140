import {T_ParagraphOffersCategoryPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphOffersCategoryPromoted";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import cn from "classnames";
import TitleGroupDesktop from "src/ui/components/desktop/TitleGroup";
import OfferTeaserListDesktop from "@/src/ui/components/offer-group/OfferTeaserList/desktop";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";

const BlockOffersCategoryPromotedDesktop = (props: T_ParagraphOffersCategoryPromoted) => (
  <Section backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      <div className={cn("py-7.5")}>
        <TitleGroupDesktop
          titleWithTag={props.field_title_with_tag}
          description={props.field_body.processed}
        />
      </div>
      <OfferTeaserListDesktop offers={props.field_offers_category} />
      <div className="py-7.5">
        <ArrowLink href={props.field_one_link.url}>{props.field_one_link.title}</ArrowLink>
      </div>
    </MaxWidthWrapper>
  </Section>
);

export default BlockOffersCategoryPromotedDesktop;
