import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodeDestinationDetail} from "@/src/core/app/domain/models/node/T_NodeDestinationDetail";
import {useDevice} from "@/src/ui/hooks/useDevice";
import Route_DestinationDetailDesktop from "@/src/ui/components/routes/Route_DestinationDetail/desktop";
import Route_DestinationDetailMobile from "@/src/ui/components/routes/Route_DestinationDetail/mobile";

const Route_DestinationDetail = (props: RouteProps<T_NodeDestinationDetail>) : JSX.Element => {
  const device = useDevice();
  const _Component = device.isDesktop ? Route_DestinationDetailDesktop : Route_DestinationDetailMobile;
  return (
    <_Component {...props} />
  );
}

export default Route_DestinationDetail;
