import StarRating from "../../generic/StarRating";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import {PropsWithChildren} from "react";
import Anchor from "@/src/ui/components/generic/Anchor";

export type OnHotelSelected = (hotel: T_HotelTreeHotel) => void;

interface HotelLinkWithRatingProps {
  onHotelSelected?: OnHotelSelected
  hotel: T_HotelTreeHotel
}

const _Wrapper = ({
  hotel,
  onHotelSelected,
  children,
}: PropsWithChildren<HotelLinkWithRatingProps>) => {
  if (onHotelSelected) {
    return (
      <div
        className="cursor-pointer"
        onClick={() => onHotelSelected(hotel)}
      >
        {children}
      </div>
    )
  }
  return (
    <Anchor href={hotel.href || undefined}>
      {children}
    </Anchor>
  )
}

const HotelLinkWithRating = (props: HotelLinkWithRatingProps): JSX.Element => (
  <_Wrapper {...props}>
    <p className="text-brand-2 pb-1 text-sm hover:underline">{props.hotel.title}</p>
    <div className="flex space-x-4 text-xs text-brand-3-40">
      <p>{props.hotel.destination}</p>
      <StarRating rating={props.hotel.rating} />
    </div>
  </_Wrapper>
);

export default HotelLinkWithRating;
