import {Route} from "@/src/core/app/domain/@types/Route";
import {T_Pager} from "@/src/core/app/domain/models/pager/T_Pager";

const PagerUtils = {
  getCurrentPage: (
    route: Route
  ) : number => {
    if (!route.queryParams['page']) {
      return 0;
    }
    if (Array.isArray(route.queryParams['page'])) {
      return 0;
    }
    return parseInt(route.queryParams['page']);
  },

  getPager: (
    route: Route,
    pageSize: number,
    totalCount: number,
  ) : T_Pager => {
    const currentPage =  PagerUtils.getCurrentPage(route);
    const pagesCount = Math.ceil(totalCount / pageSize);
    return {
      currentPage,
      pagesCount,
      pageSize,
      totalCount,
    }
  },
}

export default PagerUtils;
