import Button from "@/src/ui/components/generic/Button";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import UserUtils from "@/src/core/app/utils/user";
import TitledBoxColumn from "@/src/ui/components/generic/TitledBoxColumn";
import {useState} from "react";
import UserEditTripStyleForm from "@/src/ui/components/forms/UserEditTripStyleForm";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";

interface _Props {
  userData: UserData
  countries: T_Country[]
}

interface _InfoProps extends _Props {
  onOpen: VoidFunction
}

const _UserTripStyleInfo = (props: _InfoProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  const userData = props.userData;
  const labels = userData.tripStyle.map(item => t(UserUtils.getTripStyleLabel(item) as any));
  const label = labels.length === 0 ? t('BlockUserEditForm._UserTripStyleInfo.undefined_label') : labels.join(', ');

  return (
    <TitledBox title={t('BlockUserEditForm._UserTripStyleInfo.header_label')}>
      <div className={cn([
        'space-y-5',
        device.isDesktop && "py-3.75 px-5",
        device.isMobile && "p-3.75",
      ])}>
        <TitledBoxColumn
          title={t('BlockUserEditForm._UserTripStyleInfo.title_label')}
          content={label}
        />
        <div>
          <Button text={t('BlockUserEditForm._UserTripStyleInfo.edit_button_label')} bold style="secondary" onClick={props.onOpen}/>
        </div>
      </div>
    </TitledBox>
  );
};

const _UserTripStyle = (props: _Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  if (isOpen) {
    return (
      <UserEditTripStyleForm
        countries={props.countries}
        closeFn={toggle}
      />
    );
  }

  return (
    <_UserTripStyleInfo {...props} onOpen={toggle}/>
  )
};

export default _UserTripStyle;
