import {T_Media} from "@/src/core/app/domain/models/media/T_Media";
import {T_MediaType} from "@/src/core/app/domain/models/media/T_MediaType";

const CmsMediaUtils = {
  getMediaType(media: T_Media) : T_MediaType {
    if (media.type === 'media--video') {
      return T_MediaType.VIDEO;
    }
    return T_MediaType.IMAGE;
  }
};

export default CmsMediaUtils;
