import InputWrapper from "@/src/ui/components/form/InputWrapper";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import cn from "classnames";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {useTranslation} from "next-i18next";

interface _Props {
  occupation: SearchParamsOccupationRoom
  isEditable: boolean
}

const _Occupation = (props: _Props) => {
  const { t } = useTranslation();
  const device = useDevice();
  const modalState = useModalProvider(state => state);

  const onClick = () => {
    modalState.toggleModal(ModalKey.OFFER_FORM_OCCUPATION);
  };

  return (
    <div
      className={cn(
        'space-y-1.5',
        device.isDesktop && "w-60",
        device.isMobile && "w-full",
      )}
      onClick={props.isEditable ? onClick : undefined}
    >
      <p className="text-medium-bold">
        {props.isEditable ? t('OfferDetail._FormOccupation.occupation_label') : t('OfferDetail._FormOccupation.occupation_fixed_label')}:
      </p>
      <InputWrapper
        className={cn(
          "text-brand-2 flex items-center",
          props.isEditable && 'cursor-pointer',
        )}
        color={props.isEditable ? 'white' : 'disabled'}
      >
        <ul className="flex-1 flex space-x-4 overflow-hidden">
          <li className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">
            <img className="h-full w-auto" src="/assets/icons/person-brand-2.svg" alt="" />
            <span className="text-sm">
              {SearchParamsFormatter.formatRoomOccupation(props.occupation.adults, props.occupation.kids, t)}
            </span>
          </li>
        </ul>
      </InputWrapper>
    </div>
  );
}

export default _Occupation;
