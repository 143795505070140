import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

interface _Props {
  field?: CMS_FieldTextWithTag
  className?: string
  suffix?: string
  suffixSeparator?: string
}

const TitleWithTag = (props: _Props) => {
  if (!props.field) {
    return null;
  }

  const suffixSeparator = props.suffixSeparator ?? ' ';
  const Tag = props.field.tag as keyof JSX.IntrinsicElements;
  const text = props.suffix ? `${props.field.text}${suffixSeparator}${props.suffix}` : props.field.text;
  return (
    <Tag className={props.className}>
      {text}
    </Tag>
  );
}

export default TitleWithTag;
