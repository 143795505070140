import {T_ParagraphHotelListByTag} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelListByTag";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockHotelListByTagMobile from "@/src/ui/components/blocks/BlockHotelListByTag/mobile";
import BlockHotelListByTagDesktop from "@/src/ui/components/blocks/BlockHotelListByTag/desktop";


const BlockHotelListByTag = (props: T_ParagraphHotelListByTag) => {
  if (props.hotelTeasers.length === 0) {
    return null;
  }

  const device = useDevice();
  const _Component = device.isMobile ? BlockHotelListByTagMobile : BlockHotelListByTagDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockHotelListByTag;
