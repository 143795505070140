import { ReactNode } from "react";

interface Title3MenuProps {
  children: ReactNode;
  hasArrow?: boolean;
}

const Title3Menu = ({
  children,
  hasArrow = false,
}: Title3MenuProps): JSX.Element => (
  <span className="text-sm font-bold text-brand-2 flex items-center space-x-2.5">
    <span>{children}</span>
    {hasArrow && (
      <img
        className="h-3.5 w-auto"
        src="/assets/icons/chevron-right-brand-2.svg"
        alt=""
      />
    )}
  </span>
);

export default Title3Menu;
