import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import _Summary from "@/src/ui/components/portals/desktop/ModalLounge/_Summary";
import _Gallery from "@/src/ui/components/portals/desktop/ModalLounge/_Gallery";
import {T_Lounge} from "@/src/core/app/domain/models/lounge/T_Lounge";

const ModalLoungeDesktop = () => {
  const modalState = useModalProvider(state => state);
  const lounge = modalState.modalData.lounge as T_Lounge;

  return (
    <ModalLayoutFullscreen onCloseModal={() => modalState.closeModal()}>
      <div className="pt-15 flex-1 overflow-y-auto">
        <_Summary {...lounge} />
        <_Gallery images={lounge.ui_cpt_field_medias} />
      </div>
    </ModalLayoutFullscreen>
  )
};

export default ModalLoungeDesktop;
