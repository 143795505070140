import {useContext} from "react";
import {RouteContext} from "@/src/ui/context/RouteContext";
import {CheckoutSpecialTaxes} from "@/src/ui/@types/checkout/CheckoutSpecialTaxes";
import {RouteType} from "@/src/core/app/domain/@types/Route";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";

export const useCheckoutSpecialTaxes = () : CheckoutSpecialTaxes => {
  const routeProps = useContext(RouteContext);

  const checkoutHotel = (routeProps.route.type === RouteType.BOOKING_CONFIRMATION) ?
    (routeProps.routeData.data as BookingConfirmationRouteProps).hotel :
    (routeProps.routeData.data as T_CheckoutHotel)
  ;

  return {
    special_taxes: checkoutHotel.special_taxes,
    special_taxes_body: checkoutHotel.special_taxes_body,
  }
}