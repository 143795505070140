import UiCurrencyUtils from "@/src/ui/utils/currency";
import {CurrencyConfig} from "@/src/ui/@types/CurrencyConfig";
import {useCurrencies} from "@/src/ui/hooks/useCurrencies";
import {useCurrencyProvider} from "@/src/ui/providers/currency.provider";

export const useCurrencyConfig = () : CurrencyConfig => {
  const currencies = useCurrencies();
  const state = useCurrencyProvider(state => state);

  const apiCurrency = UiCurrencyUtils.getCurrency(currencies, 'EUR');
  const uiCurrency = state.currencyCode ? UiCurrencyUtils.getCurrency(currencies, state.currencyCode) : apiCurrency;

  return {
    match: uiCurrency.code === apiCurrency.code,
    uiCurrency,
    apiCurrency,
  }
}
