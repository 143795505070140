import ShimmerEffect from "@/src/ui/components/generic/ShimmerEffect";

const _LabelAndValue = () => (
  <div className='space-y-2.5 w-40'>
    <ShimmerEffect className="h-5 w-1/2" />
    <ShimmerEffect className="h-5 w-full" />
  </div>
);

const _Loading = () => (
  <article className="border border-gray-40 rounded-sm">
    <ShimmerEffect className='h-15 w-full' />
    <div className="px-5 py-2.5 space-y-2.5">
      <_LabelAndValue />
      <_LabelAndValue />
      <_LabelAndValue />
      <_LabelAndValue />
    </div>
    <div className="px-5 py-2.5 flex items-center">
      <ShimmerEffect className="h-10 w-30 rounded-full" />
    </div>
  </article>
)

export default _Loading;
