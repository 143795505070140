import {T_ParagraphHotelRoomsPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomsPromoted";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import _Room from "@/src/ui/components/blocks/BlockHotelRoomsPromoted/desktop/_Room";
import HotelUtils from "@/src/core/app/utils/hotel";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import RouteData from "@/src/core/app/domain/@types/RouteData";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

const BlockHotelRoomsPromotedDesktop = (props: T_ParagraphHotelRoomsPromoted) => {
  const { t } = useTranslation();
  const routeData = useRouteData() as RouteData<T_HotelSection>;
  const modalState = useModalProvider(state => state);
  const href = HotelUtils.getSectionUrl(routeData.data.ui_hotel_common_info.cpt_hotel_section_rooms);

  const toggleRoomPopup = (room: T_HotelRoomBasic) => {
    modalState.toggleModal(ModalKey.HOTEL_ROOM, {
      room,
    })
  };

  return (
    <Section backgroundColor={props.field_background_color}>
      <MaxWidthWrapper>
        <div className="py-2.5">
          <hgroup className="text-brand-2 space-y-2.5">
            <p className="title-5">{props.field_section_name}</p>
            <TitleWithTag
              field={props.field_title_with_tag}
              className="title-4"
            />
          </hgroup>
          <ul className="py-7.5 flex flex-wrap gap-5">
            {props.field_rooms.map((room, index) => (
              <li
                  key={room.id}
                  onClick={() => toggleRoomPopup(room)}
                  className="flex-grow flex-shrink-0 h-95 w-60 cursor-pointer"
              >
                <_Room {...room} />
              </li>
            ))}
          </ul>
          {href && (
            <ArrowLink href={href}>
              {t('BlockHotelRoomsPromoted._Room.view_more')}
            </ArrowLink>
          )}
        </div>
      </MaxWidthWrapper>
    </Section>
  );
}

export default BlockHotelRoomsPromotedDesktop;
