import HeaderMobileHotelList from "./HeaderMobileHotelList";
import {useHeaderMobileMenuProvider} from "@/src/ui/providers/header-mobile-menu";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {HeaderMobileMenuSection} from "@/src/ui/view_models/header-mobile-menu.state";

const HeaderMobileMenuTabHotels = (): JSX.Element | null => {
  const currentSection = useHeaderMobileMenuProvider(state => state.currentSection);
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();

  if (currentSection !== HeaderMobileMenuSection.HOTELS) {
    return null;
  }

  return (
    <div className="divide-y divide-y-gray-40">
      {routeProps.layoutData.hotelTree.regions.map((item) => (
        <div key={item.id} className="w-75 flex-none">
          <HeaderMobileHotelList region={item} />
        </div>
      ))}
    </div>
  );
}

export default HeaderMobileMenuTabHotels;
