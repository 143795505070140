import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import {ModalMultiGalleryData} from "@/src/ui/@types/modal/ModalMultiGalleryData";
import {useState} from "react";
import _Tabs from "@/src/ui/components/portals/mobile/ModalMultiGallery/_Tabs";
import FullWidthGalleryMobile from "src/ui/components/mobile/FullwidthGalleryMobile";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const ModalMultiGalleryMobile = () => {
  const modalState = useModalProvider(state => state);
  const data = modalState.modalData as ModalMultiGalleryData;
  const [currentTabIndex, setCurrentTabIndex] = useState(data.index);

  return (
    <ModalLayoutFullscreen onCloseModal={() => modalState.closeModal()}>
      <div className='p-5 pt-15 border-b border-gray-20'>
        <TitleWithTag
          field={data.multiGallery.field_title_with_tag}
          className="title-3-mobile text-center"
        />
      </div>
      <div className="flex-1 flex flex-col bg-gray-20">
        <_Tabs
          multiGallery={data.multiGallery}
          currentTabIndex={currentTabIndex}
          onTabClicked={(index: number) => setCurrentTabIndex(index)}
        />
      </div>
      <FullWidthGalleryMobile items={data.multiGallery.field_multi_gallery_items[currentTabIndex].ui_cpt_field_medias_gallery_mobile} />
    </ModalLayoutFullscreen>
  )
};

export default ModalMultiGalleryMobile;
