import cn from "classnames";

interface VerisignImgProps {
  /**
   * Tailwind class of the width of the verisign info img
   */
  width?: "w-24" | "w-17.25";
}

const VerisignImg = ({ width = "w-24" }: VerisignImgProps): JSX.Element => (
  <img
    className={cn(width, "h-auto")}
    src="/assets/images/desktop/verisign.png"
  />
);

export default VerisignImg;
