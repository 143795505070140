import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {CmsApiService} from "@/src/core/app/data/services/cms_api_service";
import {CMS_NodeBlog} from "@/src/core/app/domain/cms/@types/node/CMS_NodeBlog";
import {parseISO} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";
import {CMS_NodeReference} from "@/src/core/app/domain/cms/@types/node/CMS_NodeReference";
import DataLoaderContext from "@/src/core/app/data/data-loader/context/DataLoaderContext";

const BlogUtils = {
  getLastBlogPosts: async (
    context: DataLoaderContext,
    pageSize: number,
    offset: number,
    langcode: string
  ) : Promise<CMS_NodeBlog[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const data = await cms.getLastBlogPosts(context, pageSize, offset, langcode);
    return data.data as CMS_NodeBlog[]
  },

  getBlogPostsByCategory: async (
    context: DataLoaderContext,
    pageSize: number,
    offset: number,
    langcode: string,
    blogCategory: string
  ) : Promise<CMS_NodeBlog[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const data = await cms.getBlogPostsByCategory(context, pageSize, offset, langcode, blogCategory);
    return data.data as CMS_NodeBlog[]
  },

  getBlogPostsPromoted: async (
    context: DataLoaderContext,
    pageSize: number,
    langcode: string
  ) : Promise<CMS_NodeBlog[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const data = await cms.getBlogPostsPromoted(context, pageSize, langcode);
    return data.data as CMS_NodeBlog[]
  },

  getBlogPostsPromotedByCategory: async (
    context: DataLoaderContext,
    pageSize: number,
    langcode: string,
    blogCategory: string
  ) : Promise<CMS_NodeBlog[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const data = await cms.getBlogPostsPromotedByCategory(context, pageSize, langcode, blogCategory);
    return data.data as CMS_NodeBlog[]
  },

  getBlogPostsPromotedByEntityQueue: async (
    context: DataLoaderContext,
    langcode: string
  ) : Promise<CMS_NodeBlog[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const response = await cms.getBlogPostsPromotedByEntityQueue(context, langcode);
    const items = response.data[0].items as CMS_NodeReference[];
    const nodes = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const response = await cms.getNode(context, 'blog', item.id);
      nodes.push(response.data as CMS_NodeBlog);
    }
    return nodes;
  },

  getDateCreated: (created: string) => {
    const tz = 'Europe/Madrid'
    const iso = parseISO(created);
    return formatInTimeZone(iso, tz,'dd / MM / yyyy');
  },
};

export default BlogUtils;
