import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Formik} from "formik";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import UserEditUtils from "@/src/core/app/utils/user-edit";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import SentryUtils from "@/src/common/utils/sentry";
import NetworkNotificationUtils from "@/src/core/app/utils/network-notification";
import {UserEditTripStyleFormValues} from "@/src/core/app/domain/forms/UserEditTripStyleFormValues";
import {UserEditTripStyleFormProps} from "@/src/ui/components/forms/UserEditTripStyleForm/types";
import UserEditTripStyleFormComponent from "@/src/ui/components/forms/UserEditTripStyleForm/form";


const UserEditTripStyleForm = (props: UserEditTripStyleFormProps) => {
  const userState = useUserProvider(state => state);
  const modalState = useModalProvider(state => state);

  const userData = userState.userData as UserData;
  let initialValues : UserEditTripStyleFormValues = {
    tripStyle: userData.tripStyle,
  };

  return (
    <Formik<UserEditTripStyleFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await UserEditUtils.saveTripStyleForm(values, userState, props.countries);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          SentryUtils.logNetworkError('User edit trip style form error', networkError)
          modalState.toggleModal(ModalKey.NOTIFICATION, NetworkNotificationUtils.getErrorServerUnexpectedNotification());
          return;
        }

        modalState.closeModal();
        props.closeFn();
      }}
    >
      {(formik: FormikProps<UserEditTripStyleFormValues>) => (
        <UserEditTripStyleFormComponent
          formik={formik}
          {...props}
        />
      )}
    </Formik>
  );
}

export default UserEditTripStyleForm;
