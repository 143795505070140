import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import _Features from "@/src/ui/components/portals/mobile/ModalLounge/_Features";
import {T_Lounge} from "@/src/core/app/domain/models/lounge/T_Lounge";

const _Summary = (props: T_Lounge): JSX.Element => (
  <div className="py-8 px-5 space-y-5 text-brand-2">
    {props.field_body && (
      <WrapperDiv
        size="small"
        className="text-xs text-brand-2-90"
        dangerouslySetInnerHTML={{__html: props.field_body.processed}}
      />
    )}
    <_Features lounge={props} />
  </div>
);

export default _Summary;
