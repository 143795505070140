import {T_ParagraphInfoBlock} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoBlock";
import _Box from "@/src/ui/components/blocks/BlockInfoBlock/generic/box";
import InfoBlockDesktop from "src/ui/components/desktop/InfoBlockDesktop";
import {useRoute} from "@/src/ui/hooks/useRoute";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import UiHotelSectionUtils from "@/src/ui/utils/hotel-section";

const BlockInfoBlockDesktop = (props: T_ParagraphInfoBlock) => {
  const route = useRoute();
  const routeData = useRouteData();

  const bodyItemProp = UiHotelSectionUtils.getBodyItemPropForRouteAndParagraph(route, routeData, props);

  return (
    <InfoBlockDesktop
      backgroundColor={props.field_background_color}
      titleWithTag={props.field_title_with_tag}
      subtitle={props.field_section_name}
      bodyHtml={props.field_body?.processed}
      bodyExpandedHtml={props.field_read_more_body?.processed}
      bodyItemProp={bodyItemProp}
      infoBox={(
        <_Box paragraph={props} />
      )}
    />
  );
}

export default BlockInfoBlockDesktop;
