import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {UI_LayoutConversion} from "@/src/ui/@types/layout/UI_LayoutConversion";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useTranslation} from "next-i18next";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import ModalKey from "@/src/ui/@types/modal/ModalKey";

const _Footer = (): JSX.Element => {
  const config = useCurrencyConfig();
  const { t } = useTranslation();
  const toggleModal = useModalProvider(state => state.toggleModal);
  const routeProps = useRouteProps<T_CheckoutHotel, UI_LayoutConversion>();

  const imgs = [
    "/assets/images/generic/credit-card-1.png",
    "/assets/images/generic/credit-card-2.png",
    "/assets/images/generic/credit-card-3.png",
  ];
  return (
    <article className="pt-10 space-y-5 text">
      {!config.match && (
        <div className="space-y-1.5 text-xs">
          <h3 className="text-medium-bold">{t('CheckoutSummary._Footer.currency_conversion_title')}</h3>
          <p className="text-brand-2-90"
             dangerouslySetInnerHTML={{__html: t('CheckoutSummary._Footer.currency_conversion_body')}}
          />
        </div>
      )}
      <div className="space-y-1.5 text-xs">
        <h3 className="text-medium-bold">{t('CheckoutSummary._Footer.title')}</h3>
        <p className="text-brand-2-90">
          {t('CheckoutSummary._Footer.body')}
        </p>
        {routeProps.routeData.data.special_taxes && (
          <p
            className="text-brand-2 underline cursor-pointer"
            onClick={() => toggleModal(ModalKey.CHECKOUT_SPECIAL_TAXES)}
          >
            {t('CheckoutSummary._Footer.special_taxes_link_label')}
          </p>
        )}
      </div>
      <div className="space-y-1.5 text-xs">
        <h3 className="text-medium-bold">
          {t('CheckoutSummary._Footer.contact_info')}
        </h3>
        {routeProps.routeData.data.field_contact_info && (
          <address className="text-brand-2-90 not-italic">
            <WrapperDiv
              className='Cms-Formatted'
              dangerouslySetInnerHTML={{__html: routeProps.routeData.data.field_contact_info?.processed}}
              size='medium'
            />
          </address>
        )}
      </div>
      <div className="space-y-1.5">
        <h3 className="text-medium-bold">{t('CheckoutSummary._Footer.payment_methods_info')}:</h3>
        <ul className="flex space-x-3.75">
          {imgs.map((img, index) => (
            <li key={index}>
              <img className="flex-1 h-12.5 w-auto object-contain" src={img}/>
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
}

export default _Footer;
