export enum CMS_FieldMetatagRecordType {
  UNKNOWN = 'UNKNOWN',
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  KEYWORDS = 'KEYWORDS',
  CANONICAL = 'CANONICAL',
  HREFLANG = 'HREFLANG',
}

export interface CMS_FieldMetatagRecordAttributesMeta {
  name: string
  content: string
}

export interface CMS_FieldMetatagRecordAttributesLink {
  rel: string
  href: string
  hreflang?: string
}

export type CMS_FieldMetatagRecordAttributes = CMS_FieldMetatagRecordAttributesMeta |
  CMS_FieldMetatagRecordAttributesLink;

export interface CMS_FieldMetatagRecord {
  tag: string
  attributes: CMS_FieldMetatagRecordAttributes
}

export type CMS_Metatag = CMS_FieldMetatagRecord[];
