import {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {PropsWithChildren} from "react";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";
import CheckoutStepCustomerInfoDesktop from "@/src/ui/components/routes/Route_CheckoutStepCustomerInfo/desktop";
import CheckoutStepCustomerInfoMobile from "@/src/ui/components/routes/Route_CheckoutStepCustomerInfo/mobile";
import ModalCheckoutRatePopupGeneric from "@/src/ui/components/portals/generic/ModalCheckoutRatePopup";
import ModalCheckoutSpecialTaxesGeneric from "@/src/ui/components/portals/generic/ModalCheckoutSpecialTaxes";

const Route_CheckoutStepCustomerInfo = (props: RouteProps<T_CheckoutHotel>) => {
  const _Component = props.device.isMobile ? CheckoutStepCustomerInfoMobile : CheckoutStepCustomerInfoDesktop;
  return (
    <_Component {...props} />
  );
};

Route_CheckoutStepCustomerInfo.getAppWrapper = (
  props: PropsWithChildren<RouteProps<T_CheckoutHotel>>
) => (
  <useCheckoutProvider.State
    langcode={props.appInfo.langcode}
    step={CheckoutStep.CUSTOMER_INFORMATION}
    hotelId={props.routeData.data.id}
    hotelCode={props.routeData.data.code}
    stepExtrasEnabled={props.routeData.data.form_extras_enabled}
  >
    {props.children}
  </useCheckoutProvider.State>
)

Route_CheckoutStepCustomerInfo.getPortals = () => (
  <>
    <ModalCheckoutRatePopupGeneric />
    <ModalCheckoutSpecialTaxesGeneric />
  </>
)

export default Route_CheckoutStepCustomerInfo;
