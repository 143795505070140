import cn from "classnames";
import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import ColorUtils from "@/src/ui/utils/color";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {useTranslation} from "next-i18next";
import {CheckoutRateStyleConfigMap} from "@/src/ui/components/checkout/generic/CheckoutRateStyleConfigMap";

interface _Props {
  rateGroup: CheckoutRatesHotelRoomRateGroup
}

interface RateUiStyle {
  icon: string | null
  textColor: string | null
  backgroundColor: string | null
}

const getRateUiStyle = (rateGroup: CheckoutRatesHotelRoomRateGroup) : RateUiStyle => {
  if (rateGroup.configuration?.style) {
    const styleConfig = CheckoutRateStyleConfigMap[rateGroup.configuration.style];

    return {
      icon: styleConfig.icon,
      backgroundColor: styleConfig.backgroundColor,
      textColor: styleConfig.textColor,
    };
  }

  if (rateGroup.configuration?.color) {
    const isLight = ColorUtils.isLight(rateGroup.configuration?.color);
    return {
      icon: null,
      backgroundColor: rateGroup.configuration?.color,
      textColor: isLight ? '#ffffff' : '#000000'
    };
  }

  return {
    icon: null,
    backgroundColor: null,
    textColor: null,
  }
};

interface _SubtitleProps {
  subtitle: string
  textColor: string | null
}

const _Subtitle = (props: _SubtitleProps) => (
  <div
    className="space-x-1.5 flex items-center text-xs sm:text-sm"
    style={{
      // @ts-ignore
      color: props.textColor
    }}
  >
    <span>{props.subtitle}</span>
    {/*  <img*/}
    {/*    className="h-3.5 w-auto"*/}
    {/*    src={`icons/help-${questionIconColor}.svg`}*/}
    {/*    alt=""*/}
    {/*  />*/}
  </div>
);

const CheckoutRateGroupHeader = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();
  const {
    icon,
    backgroundColor,
    textColor,
  } = getRateUiStyle(props.rateGroup);

  const allBoardsAreCancellable = CheckoutUtils.areAllBoardsCancellable(props.rateGroup);
  const allBoardsAreNotCancellable = CheckoutUtils.areAllBoardsNotCancellable(props.rateGroup);

  return (
    <div
      className={cn(
        "py-1 px-3.75 sm:px-5 flex justify-between items-center border border-gray-40 cursor-pointer text-sm leading-none",
        //backgroundColor,
        "bg-gray-20",
        //textColor,
        "text-brand-2"
      )}
      style={{
        // @ts-ignore
        backgroundColor,
      }}
    >
      {icon && (
        <img src={`/assets/icons/rates/${icon}`} alt="" className="flex-none h-full w-auto mr-3.5" />
      )}
      <div className="flex-1 space-y-0.5 sm:space-y-0 sm:space-x-3.5 sm:flex items-center">
        <h4
          className={cn(
            "text-sm sm:text-lg font-bold sm:font-normal",
            // accentTextColor,
            "text-brand-1"
          )}
          style={{
            // @ts-ignore
            color: textColor
          }}
        >
          {props.rateGroup.title}
        </h4>
        {allBoardsAreCancellable && (
          <_Subtitle subtitle={t('CheckoutRateGroupHeader._Index.hotel_payment_label')} textColor={textColor} />
        )}
        {allBoardsAreNotCancellable && (
          <_Subtitle subtitle={t('CheckoutRateGroupHeader._Index.now_payment_label')} textColor={textColor} />
        )}
      </div>
      <div className="sm:space-x-5 space-y-0.5 sm:space-y-0 sm:flex items-baseline text-right">
        <p
          className="space-x-1"
          style={{
            // @ts-ignore
            color: textColor
          }}
        >
          <span className="text-xs sm:text-sm">{t('CheckoutRateGroupHeader._Index.from_label')}</span>
          <em className="sm:text-lg not-italic">{UiCurrencyUtils.format(props.rateGroup.min_price, currencyConfig)}</em>
        </p>
        {/*<p className={cn("space-x-1 font-bold sm:font-normal", accentTextColor)}>*/}
        {/*  <span className="text-xs sm:text-sm">Elba Fidelity</span>*/}
        {/*  <em className="sm:text-lg not-italic">{elbaFidelityPrice}</em>*/}
        {/*</p>*/}
      </div>
    </div>
  );
}

export default CheckoutRateGroupHeader;
