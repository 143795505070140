import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";
import {UserRegisterError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserRegisterError";
import {UserRegisterState} from "@/src/ui/view_models/user-register.state";
import {UserRegisterStep} from "@/src/core/app/domain/models/user-register/user-register-step";
import {FormErrorHandleFnProps} from "@/src/core/app/domain/forms/@types/FormErrorHandleFnProps";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";

const UserRegisterErrors : FormErrorMap = {
  [UserRegisterError.EMAIL_ALREADY_REGISTERED]: {
    fields: {
      'email': 'user_register.email_already_registered.error_message',
    },
    action: (props: FormErrorHandleFnProps<UserRegisterState>) => {
      props.modalState.closeModal();
      props.extra?.setStep(UserRegisterStep.STEP_1);
    }
  },
  [UserRegisterError.PASSWORD_WEAK]: {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'user_register.password_weak.error_title',
      message: 'user_register.password_weak.error_message',
    },
    fields: {
      'password1': 'user_register.password_weak.error_message',
    },
    action: (props: FormErrorHandleFnProps<UserRegisterState>) => {
      // props.modalState.closeModal();
      props.extra?.setStep(UserRegisterStep.STEP_1);
    }
  },
}

export default UserRegisterErrors;
