import { immer } from "zustand/middleware/immer";
import {createStore, useStore} from "zustand";
import {HeaderMobileMenuState} from "@/src/ui/view_models/header-mobile-menu.state";
import BrowserUtils from "@/src/ui/utils/browser";

const HTML_MODAL_CLASS = 'has-modal';

export const headerMobileMenuStore = createStore<HeaderMobileMenuState>()(immer((set, get) => ({
  isOpen: false,
  currentSection: null,
  open: () => set(state => {
    state.isOpen = true;
    BrowserUtils.addClass('html', HTML_MODAL_CLASS);
  }),
  close: () => set(state => {
    state.isOpen = false;
    BrowserUtils.removeClass('html', HTML_MODAL_CLASS);
  }),
  setCurrentSection: (newSection) => set(state => {
    state.currentSection = newSection;
  }),
  clearSection: () => set(state => {
    state.currentSection = null;
  })
})));

export function useHeaderMobileMenuProvider(): HeaderMobileMenuState;
export function useHeaderMobileMenuProvider<T>(selector: (state: HeaderMobileMenuState) => T, equals?: (a: T, b: T) => boolean): T;
export function useHeaderMobileMenuProvider(selector?: any, equals?: any) {
  return useStore(headerMobileMenuStore, selector, equals);
}
