import {T_ParagraphLinksBlock} from "@/src/core/app/domain/models/paragraph/T_ParagraphLinksBlock";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import CmsLinkButton from "@/src/ui/components/generic/CmsLinkButton";


const BlockLinksBlock = (props: T_ParagraphLinksBlock) => {
  const device = useDevice();
  return (
    <Section>
      <MaxWidthWrapper>
        <div className={cn(
          device.isDesktop && 'flex gap-2',
          device.isMobile && 'space-y-2.5',
        )}>
          {props.field_link && props.field_link.map((item, index) => (
            <CmsLinkButton key={index} link={item} />
          ))}
        </div>
      </MaxWidthWrapper>
    </Section>
  )
}

export default BlockLinksBlock;
