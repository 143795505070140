import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockInfoBlockDesktop from "@/src/ui/components/blocks/BlockInfoBlock/desktop";
import BlockInfoBlockMobile from "@/src/ui/components/blocks/BlockInfoBlock/mobile";
import {T_ParagraphInfoBlock} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoBlock";


const BlockInfoBlock = (props: T_ParagraphInfoBlock) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockInfoBlockMobile : BlockInfoBlockDesktop;
  return (
    <_Component {...props} />
  );
}
export default BlockInfoBlock;
