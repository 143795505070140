import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import StarRating from "@/src/ui/components/generic/StarRating";
import LocationTag from "@/src/ui/components/generic/LocationTag";
import UiMediaUtils from "@/src/ui/utils/media";
import Anchor from "@/src/ui/components/generic/Anchor";
import HotelUtils from "@/src/core/app/utils/hotel";
import {CMS_ParagraphHotelList_LinkSection} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";
import HotelMetaAddress from "@/src/ui/components/hotel/HotelMetaAddress";
import HotelTeaserJsonLd from "@/src/ui/components/hotel/HotelTeaser/generic/HotelTeaserJsonLd";

interface _Props {
  hotelTeaser: T_HotelTeaser
  linkSection?: CMS_ParagraphHotelList_LinkSection
}

const HotelTeaserMobile = (props: _Props) => {
  const {
    hotelTeaser,
    linkSection = CMS_ParagraphHotelList_LinkSection.MAIN,
  } = props;
  const href = HotelUtils.getLinkToSection(hotelTeaser, linkSection) || undefined;
  return (
    <article
      className="border border-gray-40 divide-y divide-gray-40 bg-white"
      itemScope itemType="https://schema.org/Hotel"
    >
      <Anchor href={href}>
        <img
          className="h-55 w-full object-cover"
          {...UiMediaUtils.getImgPropsFromMediaImage(hotelTeaser.ui_cpt_field_hotel_teaser_mobile)}
        />
      </Anchor>
      <div className="p-5">
        <div className="space-y-1">
          <StarRating rating={hotelTeaser.field_stars}/>
          <Anchor href={href}>
            <h3 className="text-big-bold" itemProp="name">
              {hotelTeaser.title}
            </h3>
          </Anchor>
          <LocationTag>{hotelTeaser.locality}</LocationTag>
        </div>
        {/*{(hotelTeaser.field_experiences.length > 0) && (*/}
        {/*  <ul className="flex text-sm text-brand-2 font-bold flex-wrap gap-2.5 pt-3.75">*/}
        {/*    {hotelTeaser.field_experiences.map((item, index) => (*/}
        {/*      <li*/}
        {/*        key={index}*/}
        {/*        className="rounded-full whitespace-nowrap bg-gray-20"*/}
        {/*      >*/}
        {/*        <Anchor*/}
        {/*          className='block px-3 py-1.5'*/}
        {/*          path={item.path}*/}
        {/*        >*/}
        {/*          {item.title}*/}
        {/*        </Anchor>*/}
        {/*      </li>*/}
        {/*    ))}*/}
        {/*  </ul>*/}
        {/*)}*/}
      </div>
      <HotelMetaAddress data={props.hotelTeaser} />
      <HotelTeaserJsonLd hotelTeaser={props.hotelTeaser} />
    </article>
  );
}

export default HotelTeaserMobile;
