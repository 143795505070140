import {T_OfferTeaser} from "@/src/core/app/domain/models/offer/T_OfferTeaser";
import {CMS_OfferEntity_PriceType} from "@/src/core/app/domain/cms/@types/offer/CMS_OfferEntityWithPrice";
import _HeaderDiscount from "@/src/ui/components/offer/OfferTeaser/header/by-type/discount";
import _HeaderRange from "@/src/ui/components/offer/OfferTeaser/header/by-type/range";
import _HeaderFixed from "@/src/ui/components/offer/OfferTeaser/header/by-type/fixed";
import _HeaderNoPrice from "@/src/ui/components/offer/OfferTeaser/header/by-type/no-price";

interface _Props {
  item: T_OfferTeaser
}

const _Header = (props: _Props) => {
  if (props.item.price_type === CMS_OfferEntity_PriceType.DISCOUNT) {
    return <_HeaderDiscount discount={props.item.price_discount as number} />
  }
  if (props.item.price_type === CMS_OfferEntity_PriceType.RANGE) {
    return <_HeaderRange item={props.item} />
  }
  if (props.item.price_type === CMS_OfferEntity_PriceType.FIXED) {
    return <_HeaderFixed price={props.item.price_from as number} />
  }
  if (props.item.price_type === CMS_OfferEntity_PriceType.NO_PRICE) {
    return <_HeaderNoPrice value={props.item.field_price_conditions} />
  }

  return null;
}

export default _Header;