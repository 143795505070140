import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockBlogSectionDesktop from "@/src/ui/components/blocks/BlockBlogSection/desktop";
import BlockBlogSectionMobile from "@/src/ui/components/blocks/BlockBlogSection/mobile";
import {T_ParagraphBlogSection} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogSection";


const BlockBlogSection = (props: T_ParagraphBlogSection) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockBlogSectionMobile : BlockBlogSectionDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockBlogSection;
