import {CMS_FieldLink} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldLink";
import Button, {ButtonStyle} from "@/src/ui/components/generic/Button";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useDevice} from "@/src/ui/hooks/useDevice";

interface _Props {
  link: CMS_FieldLink
}

const getButtonStyle = (link: CMS_FieldLink) : ButtonStyle => {
  if (link.options?.attributes.class) {
    if (["primary", "secondary", "secondary-white", "light", "ghost"].indexOf(link.options.attributes.class) !== -1) {
      return link.options.attributes.class;
    }
  }
  return 'primary';
}

const CmsLinkButton = (props: _Props) => {
  const device = useDevice();
  const link = props.link;

  return (
    <Anchor
      href={link.url}
      className='flex'
    >
      <Button
        text={link.title}
        style={getButtonStyle(link)}
        isFullwidth={device.isMobile}
        bold
      />
    </Anchor>
  );
}

export default CmsLinkButton;