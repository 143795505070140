import cn from "classnames";
import { ReactNode } from "react";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

interface _Props {
  title?: string | ReactNode
  titleWithTag?: CMS_FieldTextWithTag
  children: ReactNode
  bgColor?: string
}

const TitledBox = (props: _Props): JSX.Element => {
  const {
    children,
    title,
    titleWithTag,
    bgColor = "bg-gray-20",
  } = props;
  return (
    <article className="border border-gray-40 rounded-sm divide-y divide-gray-40">
      {title && (
        <div className={cn("px-4 py-3.5 text-big-bold", bgColor)}>{title}</div>
      )}
      {titleWithTag && (
        <TitleWithTag
          className={cn("px-4 py-3.5 text-big-bold", bgColor)}
          field={titleWithTag}
        />
      )}
      {children}
    </article>
  );
}

export default TitledBox;
