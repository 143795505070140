import {Field} from "formik";

interface _Props {
  id: string
  name: string
  value: string
  label: string
}

const FormInputRadio = (props: _Props): JSX.Element => {
  const {
    label,
    ...fieldProps
  } = props;
  return (
    <label className="flex items-center text-sm text-brand-2 cursor-pointer">
      <Field
        type="radio"
        className="radio absolute h-px w-px opacity-0"
        {...fieldProps}
      />
      <div className="radio__container h-4 w-4 mr-2.5 flex justify-center items-center bg-white border border-brand-2 rounded-full">
        <div className="h-2.5 w-2.5 radio__ball bg-brand-1 rounded-full opacity-0" />
      </div>
      <span>{label}</span>
    </label>
  );
}

export default FormInputRadio;
