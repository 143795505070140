import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {EventFormValues} from "@/src/core/app/domain/forms/EventFormValues";
import {EventRequestFormErrorOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormErrorOutput";
import {EventRequestFormSuccessOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormSuccessOutput";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {FormsApiService} from "@/src/core/app/data/services/forms_api_service";
import { EventRequestFormInput } from "@/src/core/app/domain/event/@types/Input/EventRequestFormErrors";

const EventUtils = {
  getEventTypes: () : Record<string, string> => ({
    'company': 'event_utils.event_type.company',
    'wedding': 'event_utils.event_type.wedding',
    'baptism': 'event_utils.event_type.baptism',
    'communion': 'event_utils.event_type.communion',
    'sport': 'event_utils.event_type.sport',
  }),

  getEventTypeSelectOptions: () => {
    const items = EventUtils.getEventTypes();
    return Object.keys(items).map(key => ({
      label: items[key],
      value: key,
    }));
  },

  getMeetingRoomSetup: () : Record<string, string> => ({
    'cocktail': 'event_utils.meeting_room_setup.cocktail',
    'classroom': 'event_utils.meeting_room_setup.classroom',
    'theatre': 'event_utils.meeting_room_setup.theatre',
    'banquet': 'event_utils.meeting_room_setup.banquet',
    'u-shaped': 'event_utils.meeting_room_setup.u-shaped',
    'imperial': 'event_utils.meeting_room_setup.imperial',
  }),

  getMeetingRoomSetupSelectOptions: () => {
    const items = EventUtils.getMeetingRoomSetup();
    return Object.keys(items).map(key => ({
      label: items[key],
      value: key,
    }));
  },
  
  getCateringGroups: () : Record<string, string> => ({
    'breakfast': 'event_utils.catering_group.breakfast',
    'mid-morning-coffee': 'event_utils.catering_group.mid-morning-coffee',
    'lunch': 'event_utils.catering_group.lunch',
    'quick-lunch': 'event_utils.catering_group.quick-lunch',
    'buffet-lunch': 'event_utils.catering_group.buffet-lunch',
    'gala-lunch': 'event_utils.catering_group.gala-lunch',
    'mid-afternoon-coffee': 'event_utils.catering_group.mid-afternoon-coffee',
    'cocktail': 'event_utils.catering_group.cocktail',
    'gala-dinner': 'event_utils.catering_group.gala-dinner',
  }),
  
  getMultimediaServices: () : Record<string, string> => ({
    'audivisual' : 'event_utils.multimedia_service.audivisual',
    'screens' : 'event_utils.multimedia_service.screens',
    'computers' : 'event_utils.multimedia_service.computers',
    'projectors' : 'event_utils.multimedia_service.projectors',
    'sound-team' : 'event_utils.multimedia_service.sound-team',
    'flipchart' : 'event_utils.multimedia_service.flipchart',
    'hostess' : 'event_utils.multimedia_service.hostess',
  }),

  getMultimediaServicesSelectOptions: () => {
    const items = EventUtils.getMultimediaServices();
    return Object.keys(items).map(key => ({
      label: items[key],
      value: key,
    }));
  },

  getOtherServices: () : Record<string, string> => ({
    'show': 'event_utils.other_service.show',
    'live-music': 'event_utils.other_service.live-music',
    'theatre': 'event_utils.other_service.theatre',
    'fireworks': 'event_utils.other_service.fireworks',
    'adornments': 'event_utils.other_service.adornments',
    'decoration': 'event_utils.other_service.decoration',
    'pool': 'event_utils.other_service.pool',
    'free-bar': 'event_utils.other_service.free-bar',
    'other-services': 'event_utils.other_service.other-services',
  }),

  getOtherServicesSelectOptions: () => {
    const items = EventUtils.getOtherServices();
    return Object.keys(items).map(key => ({
      label: items[key],
      value: key,
    }));
  },

  sendEventRequestForm: async (
    appInfo: AppInfo,
    values: EventFormValues
  ) : Promise<NetworkResponse<EventRequestFormSuccessOutput, EventRequestFormErrorOutput>> => {
    const api = locator.get<FormsApiService>(TYPES.FormsApiService);
    const input = EventUtils.getEventRequestFormInput(values);
    return await api.eventRequestForm(appInfo, input);
  },

  getEventRequestFormInput: (values: EventFormValues) : EventRequestFormInput => {
    const data : EventRequestFormInput = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phoneNumber,
      country: values.country,

      event_type: values.eventType,
      event_hotel_code: values.eventHotelCode,

      rooms_individual: parseInt(values.individualRooms),
      rooms_double: parseInt(values.doubleRooms),
      rooms_suite: parseInt(values.suiteRooms),

      meetings: [],

      catering: {},

      services_multimedia: values.servicesMultimedia,
      services_other: values.servicesOther,
    };

    // optional fields
    if (values.iat) data.iat = values.iat;
    if (values.eventName) data.event_name = values.eventName;
    if (values.eventDay && values.eventMonth && values.eventYear) {
      data.event_date = `${values.eventDay}/${values.eventMonth}/${values.eventYear}`;
    }
    if (values.eventPeople) data.event_people = values.eventPeople;
    if (values.eventDescription) data.event_description = values.eventDescription;
    if (values.roomsAdditionalInfo) data.rooms_additional = values.roomsAdditionalInfo;
    if (values.meetingRoomsAdditionalInfo) data.meetings_additional = values.meetingRoomsAdditionalInfo;
    if (values.cateringAdditionalInfo) data.catering_additional = values.cateringAdditionalInfo;
    if (values.servicesAdditionalInfo) data.services_additional = values.servicesAdditionalInfo;

    // meetings
    values.meetingRoomsData.forEach(item => {
      data.meetings.push({
        people: parseInt(item.people),
        setup: item.setup,
      })
    })

    // catering
    const keys = Object.keys(EventUtils.getCateringGroups());
    keys.forEach(key => {
      const item = values.cateringData[key];
      data.catering[key] = {};
      if (item.people) data.catering[key].people = item.people;
      if (item.days) data.catering[key].days = item.days;
      if (item.from) data.catering[key].from = item.from;
      if (item.to) data.catering[key].to = item.to;
    });

    return data;
  },
};

export default EventUtils;
