import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import BrowserUtils from "@/src/ui/utils/browser";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import { Route } from "@/src/core/app/domain/@types/Route";

const OfferSearchUtils = {
  onSubmitDatesFilter: (range: SearchParamsDateRange) => {
    const url = BrowserUtils.buildUrl(window.location.pathname);
    if (SearchParamsUtils.isDateRangeEmpty(range)) {
      BrowserUtils.goTo(url.toString());
      return;
    }

    url.searchParams.set('from', range.start as string);
    url.searchParams.set('to', range.end as string);
    BrowserUtils.goTo(url.toString());
  },

  getRangeFromRoute: (route: Route) : SearchParamsDateRange => {
    const start = route.queryParams['from'] as string | undefined;
    const end = route.queryParams['to'] as string | undefined;

    if (start && end) {
      return {
        start,
        end
      }
    }
    return {
      start: undefined,
      end: undefined
    }
  },
};

export default OfferSearchUtils;