import _Group, {_GroupLink} from "@/src/ui/components/offer/OfferSearchMenu/group";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {T_OfferGroupLink} from "@/src/core/app/domain/models/offer-group/T_OfferGroupLink";
import {T_NodeExperienceLink} from "@/src/core/app/domain/models/node/T_NodeExperienceLink";
import {useRoute} from "@/src/ui/hooks/useRoute";
import { Route } from "@/src/core/app/domain/@types/Route";
import {useTranslation} from "next-i18next";

interface _Props {
  offerGroupLinks: T_OfferGroupLink[]
  experienceLinks: T_NodeExperienceLink[]
}

const buildLink = (route: Route, label: string, href: string | null) : _GroupLink => ({
  label,
  href,
  isActive: href === route.url,
});

const OfferSearchMenu = (props: _Props) => {
  const { t } = useTranslation();
  const route = useRoute();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const hotelLinks = HotelTreeUtils.getHotels(layoutData.hotelTree).map(item => buildLink(
    route,
    item.title,
    item.hotel_section_offers_url,
  ));

  const destinationLinks = HotelTreeUtils.getDestinations(layoutData.hotelTree).sort((a, b) => {
    return a.destinationName.localeCompare(b.destinationName);
  }).map(item =>  buildLink(
    route,
    item.destinationName,
    `${layoutData.sitePageUrls.offer_search_page_url}?destination=${item.id}`,
  ));

  const offerGroupLinks = props.offerGroupLinks.map(item => buildLink(
    route,
    item.title,
    item.url,
  ));

  const experienceLinks = props.experienceLinks.map(item => buildLink(
    route,
    item.title,
    `${layoutData.sitePageUrls.offer_search_page_url}?experience=${item.id}`,
  ));

  return (
    <div>
      <_Group title={t('OfferSearchMenu._Index.by_hotel')} links={hotelLinks} />
      <_Group title={t('OfferSearchMenu._Index.by_destination')} links={destinationLinks} />
      <_Group title={t('OfferSearchMenu._Index.by_offer_group')} links={offerGroupLinks} />
      <_Group title={t('OfferSearchMenu._Index.by_experience')} links={experienceLinks} />
    </div>
  );
};

export default OfferSearchMenu;
