import {RefObject, useEffect, useMemo, useState} from "react";
import BrowserUtils from "@/src/ui/utils/browser";

export default function useHTMLElementOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false)

  if (!BrowserUtils.isBrowser()) {
    return false;
  }

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref])

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [])

  return isIntersecting
}