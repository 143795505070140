import {useState} from "react";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {T_Destination} from "@/src/core/app/domain/models/destination/T_Destination";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import {SearchParamsLocationType} from "@/src/core/app/domain/@types/SearchParamsLocationType";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import ModalPlaceSearchTabsDesktop from "src/ui/components/portals/desktop/ModalPlaceSearchTabs";
import HotelLinkList from "src/ui/components/hotel/HotelLinkList";
import {useTranslation} from "next-i18next";

export enum ModalSearchFormStepLocationTab {
  HOTELS,
  DESTINATIONS,
}

interface ModalSearchFormStepLocationState {
  tab: ModalSearchFormStepLocationTab,
}

const ModalSearchFormStepLocationDesktop = () : JSX.Element | null => {
  const { t } = useTranslation();
  const searchForm = useSearchFormProvider(state => state);
  const modalState = useModalProvider(state => state);
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();
  const [state, setState] = useState<ModalSearchFormStepLocationState>({
    tab: ModalSearchFormStepLocationTab.HOTELS,
  });

  const closeModal = () => modalState.closeModal();

  const onDestinationSelected = (item: T_Destination) => {
    searchForm.setLocation({
      type: SearchParamsLocationType.DESTINATION,
      id: item.id,
    });
    closeModal();
  };
  const onHotelSelected = (item: T_HotelTreeHotel) => {
    searchForm.setLocation({
      type: SearchParamsLocationType.HOTEL,
      id: item.code,
    });
    closeModal();
  };

  const setTab = (tab: ModalSearchFormStepLocationTab) => {
    setState({
      ...state,
      tab,
    })
  };

  return(
    <ModalLayout
      className="w-115 h-125"
      title={t('ModalSearchFormStepLocation._Index.title')}
      onCloseModal={closeModal}
    >
      <ModalPlaceSearchTabsDesktop
        tab={state.tab}
        setTab={setTab}
      />
      <div className="p-5 h-100 overflow-y-auto">
        {state.tab === ModalSearchFormStepLocationTab.HOTELS && (
          <div className="pb-5">
            {routeProps.layoutData.hotelTree.regions.map((item) => (
              <div key={item.id}>
                <HotelLinkList region={item} onHotelSelected={onHotelSelected} />
              </div>
            ))}
          </div>
        )}

        {state.tab === ModalSearchFormStepLocationTab.DESTINATIONS && (
          <ul className="ul pb-5 space-y-5 leading-4">
            {routeProps.layoutData.destinations.map(item => (
              <li
                key={item.id}
                className="hover:underline cursor-pointer text-brand-2 text-sm"
                onClick={() => onDestinationSelected(item)}
              >
                {item.destinationName} ({item.hotelCount})
              </li>
            ))}
          </ul>
        )}
      </div>
    </ModalLayout>
  );
}

export default ModalSearchFormStepLocationDesktop;
