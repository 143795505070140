import {useContextHeaderDesktopContext} from "@/src/ui/context/HeaderDesktopContext";
import Anchor from "@/src/ui/components/generic/Anchor";
import _MainMenuTitle from "@/src/ui/components/layout/desktop/HeaderDesktopNavigation/_MainMenuTitle";
import {CMS_Menu} from "@/src/core/app/domain/cms/@types/menu/CMS_Menu";

interface _Props {
  menu: CMS_Menu
}

const _MenuHeaderUser = (props: _Props): JSX.Element => {
  const context = useContextHeaderDesktopContext();
  return (
    <ul className="flex space-x-7.5 text-xl">
      {Object.values(props.menu.items).map((menuItem, index) => {
        if (index === 0) {
          return (
            <li key={index}>
              <button
                onClick={() => context?.toggleMenu()}
                className="flex space-x-2.5 items-center"
              >
                <_MainMenuTitle title={menuItem.title} />
                <img
                  className="w-2 h-2 object-contain"
                  src={context?.isVisible ? "/assets/icons/triangle-down-brand-2.svg" : "/assets/icons/triangle-down.svg"}
                  alt=""
                />
              </button>
            </li>
          )
        }
        return (
          <li key={index}>
            <Anchor className="flex space-x-2.5 items-center" href={menuItem.url}>
              <_MainMenuTitle title={menuItem.title} />
            </Anchor>
          </li>
        );
      })}
    </ul>
  );
}

export default _MenuHeaderUser;
