import InputWrapper from "@/src/ui/components/form/InputWrapper";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import cn from "classnames";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {useTranslation} from "next-i18next";

interface _Props {
  dateRange: SearchParamsDateRange
  isEditable: boolean
}

const _DateRange = (props: _Props) => {
  const { t } = useTranslation();
  const device = useDevice();
  const modalState = useModalProvider(state => state);

  const onClick = () => {
    modalState.toggleModal(ModalKey.OFFER_FORM_DATE_RANGE);
  };

  return (
    <div
      className={cn(
        'space-y-1.5',
        device.isDesktop && "w-60",
        device.isMobile && "w-full",
      )}
      onClick={props.isEditable ? onClick : undefined}
    >
      <p className="text-medium-bold">
        {props.isEditable ? t('OfferDetail._FormDateRange.dates_label') : t('OfferDetail._FormDateRange.dates_fixed_label')}:
      </p>
      <InputWrapper
        className={cn(
          "text-brand-2 flex items-center",
          props.isEditable && 'cursor-pointer',
        )}
        color={props.isEditable ? 'white' : 'disabled'}
      >
        <div className="flex-1 flex space-x-4 overflow-hidden">
          <div className="flex flex-grow items-center space-x-2 leading-none h-3.5">
            <img className="h-full w-auto" src="/assets/icons/calendar.svg" alt="" />
            <span className="text-sm">{SearchParamsFormatter.formatRange(props.dateRange)}</span>
          </div>
          <div className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">
            <img className="h-full w-auto" src="/assets/icons/moon.svg" alt="" />
            <span className="text-sm text-brand-2-40">
             {SearchParamsUtils.getDateRangeNights(props.dateRange)}
           </span>
          </div>
        </div>
      </InputWrapper>
    </div>
  );
}

export default _DateRange;
