import {T_Destination} from "@/src/core/app/domain/models/destination/T_Destination";

const DestinationUtils = {
  getDestination: (id: string, items: T_Destination[]) : T_Destination => {
    const item = items.find(i => i.id === id);
    if (!item) {
      throw new Error('Destination not found in list');
    }
    return item;
  }
}

export default DestinationUtils;
