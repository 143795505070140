import Section from "../../generic/Section/Section";
import HeaderMobileMenuNavigationLinks from "./HeaderMobileMenuNavigationLinks";
import HeaderMobileMenuTabs from "./HeaderMobileMenuTabs";
import HeaderMobileMenuTabDestinations from "./HeaderMobileMenuTabDestinations";
import HeaderMobileMenuTabServices from "./HeaderMobileMenuTabServices";
import HeaderMobileMenuTabHotels from "./HeaderMobileMenuTabHotels";
import {useHeaderMobileMenuProvider} from "@/src/ui/providers/header-mobile-menu";
import {HeaderMobileMenuSection} from "@/src/ui/view_models/header-mobile-menu.state";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";

const HeaderMobileMenu = (): JSX.Element | null => {
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();
  const headerMenuState = useHeaderMobileMenuProvider(state => state);

  if (!headerMenuState.isOpen) {
    return null;
  }

  const mainMenu = routeProps.layoutData.menuHeaderMain;
  const mainMenuFirstLink = mainMenu.items[0];

  return (
    <div className="fixed z-30 inset-0 bg-white pt-10 px-2.5 overflow-y-auto">
      <Section>
        <div className="flex justify-end">
          <button onClick={() => headerMenuState.close()}>
            <img
              className="h-6 w-6 mb-5"
              src="/assets/icons/cross-brand-2.svg"
              alt="close"
            />
          </button>
        </div>
        <nav className="text-brand-2">
          <ul className="text-lg space-y-7.5 pb-7.5">
            <li>
              <button
                onClick={() => headerMenuState.currentSection ?
                  headerMenuState.clearSection() :
                  headerMenuState.setCurrentSection(HeaderMobileMenuSection.HOTELS)
              }
                className="flex space-x-2.5 items-center"
              >
                {!!headerMenuState.currentSection && (
                  <img
                    className="transform rotate-180"
                    src="/assets/icons/chevron-right-brand-2.svg"
                    alt=""
                  />
                )}
                <span className="font-light text-xl font-calistoga">
                  {mainMenuFirstLink.title}
                </span>
                {!headerMenuState.currentSection && (
                  <img
                    src="/assets/icons/chevron-right-brand-2.svg"
                    alt=""
                  />
                )}
              </button>
            </li>
            {!headerMenuState.currentSection && (
              <>
                {mainMenu.items.slice(1).map(menuItem => (
                  <li key={menuItem.id}>
                    <Anchor href={menuItem.url}>
                      <span className="font-light text-xl font-calistoga">
                        {menuItem.title}
                      </span>
                    </Anchor>
                  </li>
                ))}
              </>
            )}
          </ul>
          <HeaderMobileMenuTabs />
          <HeaderMobileMenuTabHotels />
          <HeaderMobileMenuTabDestinations />
          <HeaderMobileMenuTabServices />
          <HeaderMobileMenuNavigationLinks />
        </nav>
      </Section>
    </div>
  );
}

export default HeaderMobileMenu;
