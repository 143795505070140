import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import _CheckoutStatusLoading from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/checkout-status-loading";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import CheckoutStepRoomsErrors from "@/src/core/app/domain/checkout/@types/FormErrorMap/checkout-step-rooms";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import NotificationMessage from "src/ui/components/generic/NotificationMessage";
import {T_Notification} from "@/src/ui/@types/T_Notification";
import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {T_HotelSectionDataRoom} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataRoom";
import _CalendarStatusOk from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/status-ok";
import {CheckoutStepCalendarStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarStatus";
import {CheckoutStepCalendarData} from "@/src/core/app/domain/models/checkout/CheckoutStepCalendarData";

const _Calendar = (props: T_ParagraphHotelRoomList) => {
  const routeData = useRouteData<T_HotelSection<T_HotelSectionDataRoom>>();
  const state = useCheckoutProvider(state => state);
  const stepData = state.currentStepData as CheckoutStepCalendarData;
  let rooms = routeData.data.ui_hotel_section.data.rooms;

  if (stepData.status === CheckoutStepCalendarStatus.LOADING) {
    return (
      <_CheckoutStatusLoading {...props} />
    );
  }

  if (stepData.status === CheckoutStepCalendarStatus.ERROR) {
    const error = FormErrorUtils.getError({
      networkError: stepData.error as NetworkResponseError,
      formErrorMap: CheckoutStepRoomsErrors
    });
    return (
      <MaxWidthWrapper>
        <NotificationMessage {...(error.notification as T_Notification)} />
      </MaxWidthWrapper>
    );
  }

  if (stepData.status === CheckoutStepCalendarStatus.OK) {
    return (
      <_CalendarStatusOk
        {...props}
        rooms={rooms}
      />
    )
  }

  return null;
};

export default _Calendar;
