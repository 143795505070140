import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockHotelRoomsPromotedDesktop from "@/src/ui/components/blocks/BlockHotelRoomsPromoted/desktop";
import BlockHotelRoomsPromotedMobile from "@/src/ui/components/blocks/BlockHotelRoomsPromoted/mobile";
import {T_ParagraphHotelRoomsPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomsPromoted";


const BlockHotelRoomsPromoted = (props: T_ParagraphHotelRoomsPromoted) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockHotelRoomsPromotedMobile : BlockHotelRoomsPromotedDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockHotelRoomsPromoted;
