import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import Anchor from "@/src/ui/components/generic/Anchor";
import {T_OfferGroupLink} from "@/src/core/app/domain/models/offer-group/T_OfferGroupLink";
import {useTranslation} from "next-i18next";

interface ModalStateData {
  links: T_OfferGroupLink[]
}

const ModalOfferFormByOfferGroup = (): JSX.Element => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const modalStateData = modalState.modalData as ModalStateData;
  const onCloseModal = () => {
    modalState.closeModal();
  };

  return (
    <ModalLayout
      title={t('ModalOfferFormByOfferGroup._Index.title')}
      className="w-80 h-95"
      onCloseModal={onCloseModal}
    >
      <div className="flex-1 overflow-auto">
        <ul>
          {modalStateData.links.map((item) => (
            <li key={item.id} className="w-full flex-none">
              <Anchor
                className="block text-brand-2 text-sm px-5 py-3 hover:bg-gray-10 cursor-pointer"
                href={item.url}
              >
                {item.title}
              </Anchor>
            </li>
          ))}
        </ul>
      </div>
    </ModalLayout>
  );
}

export default ModalOfferFormByOfferGroup;
