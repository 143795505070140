import React from "react";
import {Formik} from "formik";
import FormUtils from "@/src/ui/utils/form";
import {FormikProps} from "formik/dist/types";
import {useUserRegisterProvider} from "@/src/ui/providers/user-register.provider";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import {UserRegisterFormValues} from "@/src/core/app/domain/forms/UserRegisterFormValues";
import {UserRegisterStep} from "@/src/core/app/domain/models/user-register/user-register-step";
import UserRegisterStep1Form from "@/src/ui/components/forms/UserRegisterForm/steps/step1";
import {UserRegisterFormStep} from "@/src/ui/components/forms/UserRegisterForm/steps/step";
import UserRegisterStep2Form from "@/src/ui/components/forms/UserRegisterForm/steps/step2";
import UserRegisterStep3Form from "@/src/ui/components/forms/UserRegisterForm/steps/step3";
import {useTranslation} from "next-i18next";
import UserUtils from "@/src/core/app/utils/user";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutCommon} from "@/src/ui/@types/layout/UI_LayoutCommon";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

interface _Props {
  countries: T_Country[]
  // newsletterCategories: T_NewsletterCategory[]
  children: ((props: FormikProps<UserRegisterFormValues>) => React.ReactNode) | React.ReactNode
}

const formParamsByStep : Record<UserRegisterStep, UserRegisterFormStep> = {
  [UserRegisterStep.STEP_1]: UserRegisterStep1Form,
  [UserRegisterStep.STEP_2]: UserRegisterStep2Form,
  [UserRegisterStep.STEP_3]: UserRegisterStep3Form,
};

const UserRegisterForm = (props: _Props) => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const layoutData = useLayoutData<UI_LayoutCommon>();
  const modal = useModalProvider(state => state);
  const state = useUserRegisterProvider(state => state);
  const formParams = formParamsByStep[state.step];
  let initialValues : UserRegisterFormValues = {
    // Step 1
    email: '',
    password1: '',
    password2: '',
    termsAndConditions: false,

    // Step 2
    firstName: '',
    lastName: '',
    gender: '',
    phoneNumber: '',
    country: 'ES',
    language: 'es',

    // Step 3
    tripStyle: [],
    // newsletterCategories: [],
  };

  // initialValues = {
  //   ...initialValues,
  //   email: 'jherencia+20230822@mrmilu.com',
  //   password1: 'fidelity1234',
  //   password2: 'fidelity1234',
  //   firstName: 'Jaime',
  //   lastName: 'Herencia',
  //   phoneNumber: '615884752',
  // }

  return (
    <Formik<UserRegisterFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => FormUtils.runValidations(t, values, formParams.validations)}
      onSubmit={async (values, formikHelpers) => {
        formParams.onSubmit(
          appInfo,
          values,
          formikHelpers,
          t,
          modal,
          state,
          props.countries,
          layoutData.sitePageUrls,
        );
      }}
    >
      {props.children}
    </Formik>
  )
}

export default UserRegisterForm;
