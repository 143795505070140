import {PropsWithChildren} from "react";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";

const _PriceTagCommon = (props: PropsWithChildren) => {
  const device = useDevice();

  return (
    <>
      <div className="absolute inset-0 bg-img-blue-shade" />
      <div className={cn(
        "absolute",
        device.isDesktop && 'bottom-4 left-5 px-3.75 py-2.5 bg-gray-20',
        device.isMobile && 'bottom-2.5 px-2.5 w-full',
      )}>
        <div className={cn(
          device.isMobile && 'bg-gray-20 px-3.75 py-2.5',
        )}>
          {props.children}
        </div>
      </div>
    </>
  );
}

export default _PriceTagCommon;
