import Anchor from "@/src/ui/components/generic/Anchor";
import {T_OfferTeaser} from "@/src/core/app/domain/models/offer/T_OfferTeaser";

interface _RowLinkProps {
  item: T_OfferTeaser
}

interface _SitemapOfferMenuProps {
  title: string
  items: T_OfferTeaser[]
}

const _RowLink = (props: _RowLinkProps) => (
  <li>
    <Anchor href={props.item.path.alias}>
      {props.item.title}
    </Anchor>
  </li>
);

const _SitemapOfferMenu = (props: _SitemapOfferMenuProps) => (
  <div>
    <p>{props.title}</p>
    <ul>
      {props.items.map(item => (
        <_RowLink
          key={item.id}
          item={item}
        />
      ))}
    </ul>
  </div>
);

export default _SitemapOfferMenu;
