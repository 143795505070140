import Button from "@/src/ui/components/generic/Button";
import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Form, Formik} from "formik";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import FormRecaptcha from "@/src/ui/components/formik/FormRecaptcha";
import FormTermsAndConditions from "@/src/ui/components/formik/FormTermsAndConditions";
import FormInputFile from "@/src/ui/components/formik/FormInputFile";
import FormUtils from "@/src/ui/utils/form";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {JobRequestFormValues} from "@/src/core/app/domain/forms/JobRequestFormValues";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";
import FormFileUtils from "@/src/ui/utils/form-file";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import JobRequestUtils from "@/src/core/app/utils/job-request";
import JobRequestFormErrors from "@/src/core/app/domain/job-request/@types/FormErrorMap/JobRequestFormErrors";

interface _FormProps {
  formik: FormikProps<JobRequestFormValues>
}

const _Form = (props: _FormProps) => {
  const { t } = useTranslation();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const hotels = HotelTreeUtils.getHotels(layoutData.hotelTree);

  return (
    <TitledBox title={t('JobRequestForm._Index.title')}>
      <Form className="px-5 py-3.75 space-y-7.5">
        <div className="space-y-3 75">
          <FormInputText
            name="firstName"
            label={t('JobRequestForm._Index.first_name_label') + " *"}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            name="lastName"
            label={t('JobRequestForm._Index.last_name_label') + " *"}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            name="phoneNumber"
            label={t('JobRequestForm._Index.phone_number_label') + " *"}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputSelect
            name='hotelCode'
            label={t('JobRequestForm._Index.hotel_label') + " *"}
            emptyOption={'- ' + t('JobRequestForm._Index.hotel_empty_option') + " -"}
            options={HotelTreeUtils.getSelectOptions(hotels)}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputSelect
            name='jobType'
            label={t('JobRequestForm._Index.job_type_label') + " *"}
            emptyOption={'- ' + t('JobRequestForm._Index.job_type_empty_option') + " -"}
            options={I18nUtils.translateFormInputSelectOptions(
              JobRequestUtils.getJobTypeSelectOptions(), t
            )}
            validate={FormUtils.validateIsRequired}
          />
        </div>
        <FormInputFile
          name='cv'
          label={t('JobRequestForm._Index.file_label') + " *"}
          description={t('FormInputFile._Index.description')}
          validations={[FormUtils.validateIsRequired, FormFileUtils.validateDocExtensions, FormFileUtils.validateDocFileSize]}
          accept={FormFileUtils.getDocAccept()}
        />
        <FormTermsAndConditions name='termsAndConditions' isRequired={true} />
        <FormRecaptcha name='recaptcha' />
        <div>
          <Button
            text={t('JobRequestForm._Index.submit_button_label')}
            bold
            onClick={props.formik.handleSubmit}
            isActive={!props.formik.isSubmitting}
          />
        </div>
        <VerisignImg />
      </Form>
    </TitledBox>
  );
}

const JobRequestForm = () => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  let initialValues : JobRequestFormValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    hotelCode: '',
    jobType: '',
    cv: null,
    recaptcha: '',
    termsAndConditions: false,
  };

  return (
    <Formik<JobRequestFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        const base64 = await FormFileUtils.getBase64(values.cv);
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await JobRequestUtils.sendJobRequestForm(
          appInfo,
          values.firstName,
          values.lastName,
          values.phoneNumber,
          values.hotelCode,
          values.jobType,
          values.cv.name,
          base64
        );
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: JobRequestFormErrors,
            logFn: (reason) => FormErrorUtils.logNetworkError('Job request form error', reason, networkError),
          });
          return;
        }
        formikHelpers.setValues(initialValues);
        modalState.toggleModal(ModalKey.NOTIFICATION, {
          type: T_NotificationType.SUCCESS,
          title: 'JobRequestForm._Index.success_notification_title',
          message: 'JobRequestForm._Index.success_notification_message',
        });
      }}
    >
      {(formik: FormikProps<JobRequestFormValues>) => (
        <_Form
          formik={formik}
        />
      )}
    </Formik>
  );
}

export default JobRequestForm;
