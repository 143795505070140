import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_FieldLink} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldLink";
import {T_NodeExperienceTeaser} from "@/src/core/app/domain/models/node/T_NodeExperienceTeaser";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

export enum T_ParagraphExperiencesList__ViewMode {
  CAROUSEL = 'carousel',
  GRID = 'grid',
}


export interface T_ParagraphExperiencesList extends CMS_ParagraphEntity {
  field_title_with_tag?: CMS_FieldTextWithTag
  field_subtitle: string
  field_one_link?: CMS_FieldLink
  field_background_color: string
  field_experiences: T_NodeExperienceTeaser[]
  field_pel_view_mode: T_ParagraphExperiencesList__ViewMode
}