import {useHeaderMobileMenuProvider} from "@/src/ui/providers/header-mobile-menu";
import {HeaderMobileMenuSection} from "@/src/ui/view_models/header-mobile-menu.state";
import cn from "classnames";
import {useTranslation} from "next-i18next";

const titles = [
  {
    label: "HeaderMobileMenuTabs._Index.hotel_label",
    section: HeaderMobileMenuSection.HOTELS,
  },
  {
    label: "HeaderMobileMenuTabs._Index.destination_label",
    section: HeaderMobileMenuSection.DESTINATIONS,
  },
  {
    label: "HeaderMobileMenuTabs._Index.services_label",
    section: HeaderMobileMenuSection.SERVICES,
  },
];

const HeaderMobileMenuTabs = (): JSX.Element | null => {
  const {t} = useTranslation();
  const currentSection = useHeaderMobileMenuProvider(state => state.currentSection);
  const setCurrentSection = useHeaderMobileMenuProvider(state => state.setCurrentSection);

  if (currentSection === null) {
    return null;
  }

  return (
    <ul className="flex space-x-7.5 pt-2.5 pb-2">
      {titles.map(({ label, section }) => (
        <li key={section}>
          <button
            className={currentSection === section ? 'font-bold' : 'font-light'}
            onClick={() => setCurrentSection(section)}
          >
            <div>{t(label as any)}</div>
            <div className={
              cn("h-0.5 bg-brand-2 mx-auto transition-all", currentSection === section ? 'w-14' : 'w-0')}
            />
          </button>
        </li>
      ))}
    </ul>
  );
}

export default HeaderMobileMenuTabs;
