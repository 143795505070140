import {T_ParagraphEventForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphEventForm";
import EventForm from "@/src/ui/components/forms/EventForm";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const BlockEventForm = (props: T_ParagraphEventForm) => (
  <>
    <TitleWithTag
      className='title-4 pb-2 font-calistoga'
      field={props.field_title_with_tag}
    />
    <EventForm countries={props.countries} />
  </>
)

export default BlockEventForm;
