import {T_ParagraphContactForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphContactForm";
import ContactForm from "@/src/ui/components/forms/ContactForm";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";


const BlockContactForm = (props: T_ParagraphContactForm) => (
  <div>
    <TitleWithTag
      className="title-4 pb-7.5 font-calistoga"
      field={props.field_title_with_tag}
    />
    <ContactForm />
  </div>
);
export default BlockContactForm;
