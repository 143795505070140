import StarRating from "../../generic/StarRating";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";

interface _Props {
  checkoutHotel: T_CheckoutHotel
}

const HeaderHotelCheckoutMobile = (props: _Props): JSX.Element => (
  <section className="p-2.5 space-y-1 flex flex-col items-center bg-gray-10">
    <StarRating rating={props.checkoutHotel.field_stars} />
    <h2 className="text-big">{props.checkoutHotel.title}</h2>
    <p className="text-xs text-brand-2-90">
      {props.checkoutHotel.destination_title}
    </p>
  </section>
);

export default HeaderHotelCheckoutMobile;
