import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import _Header from "@/src/ui/components/checkout/generic/CheckoutSummary/header";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import _Room from "@/src/ui/components/checkout/generic/CheckoutSummary/room";
import _Subtotal from "@/src/ui/components/checkout/generic/CheckoutSummary/subtotal";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import _Footer from "@/src/ui/components/checkout/generic/CheckoutSummary/footer";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";

const CheckoutSummary = (): JSX.Element | null => {
  const device = useDevice();
  const routeData = useRouteData<T_CheckoutHotel>();
  const state = useCheckoutProvider(state => state);

  if (state.isInitiated === false) {
    return null;
  }

  return (
    <div className={cn({
      'px-5 pb-15': device.isMobile
    })}>
      <article className="border border-gray-40 divide-y divide-gray-40 text-sm text-brand-2">
        <_Header checkoutHotel={routeData.data} searchParams={state.searchParams as SearchParams}/>
        <ul className="divide-y divide-gray-20">
          {state.rooms.map((room, index) => (
            <li key={index} className="px-4 py-3.5">
              <_Room room={room} index={index} />
            </li>
          ))}
        </ul>
        <_Subtotal amount={CheckoutUtils.getTotalAmount(state)} />
      </article>
      <_Footer />
    </div>
  );
}

export default CheckoutSummary;
