import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodeBlogDetail} from "@/src/core/app/domain/models/node/T_NodeBlogDetail";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import _Body from "@/src/ui/components/routes/Route_BlogDetail/desktop/body";
import _Related from "@/src/ui/components/routes/Route_BlogDetail/desktop/related";
import BlogCategoryListDesktop from "@/src/ui/components/blog/BlogCategoryList/desktop";
import NodeBlogLinkListDesktop from "@/src/ui/components/blog/NodeBlogLinkList/desktop";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import {useTranslation} from "next-i18next";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_BlogDetailDesktop = (props: RouteProps<T_NodeBlogDetail>) : JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader header={props.routeData.data.page_header} />
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <div className="px-5 pt-10 pb-20">
        <MaxWidthWrapper>
          <div className="flex space-x-10">
            <div className="flex-1 divide-y divide-gray-40">
              <_Body {...props.routeData.data} />
              <_Related {...props.routeData.data} />
            </div>
            <div className="flex-none w-95">
              <BlogCategoryListDesktop
                titleWithTag={{
                  text: t('generic.blog.categories_label'),
                  tag: 'p'
                }}
                categories={props.routeData.data.categories}
              />
              <NodeBlogLinkListDesktop
                titleWithTag={{
                  text: t('generic.blog.promoted_posts_label'),
                  tag: 'p',
                }}
                items={props.routeData.data.promotedPosts}
              />
            </div>
          </div>
        </MaxWidthWrapper>
      </div>
    </>
  );
}

export default Route_BlogDetailDesktop;
