import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";

interface _Props {
  checkoutHotel: T_CheckoutHotel
  searchParams: SearchParams
}

const _Header = (props: _Props) => {
  const { t } = useTranslation();
  return (
    <div className="px-4 py-3.5 space-y-3.5 bg-gray-20">
      <h3 className="title-4 font-calistoga">
        {t('CheckoutSummary._Header.title')}
      </h3>
      <div className="space-y-1.5">
        <h4 className="font-bold text-brand-2-60">
          {t('CheckoutSummary._Header.hotel_label')}
        </h4>
        <p>{props.checkoutHotel.title}</p>
      </div>
      <div className="space-y-1.5">
        <h4 className="font-bold text-brand-2-60">{t('CheckoutSummary._Header.dates_label')}</h4>
        <p>
          {SearchParamsFormatter.formatRange(props.searchParams.dateRange)}
          {' '}
          {`(${SearchParamsFormatter.getDateRangeNightsSummary(props.searchParams.dateRange, t)})`}
        </p>
      </div>
    </div>
  );
}

export default _Header;
