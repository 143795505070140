import {SearchFormCalendarWidget} from "@/src/ui/components/search-form/SearchFormCalendarWidget";
import {useRoute} from "@/src/ui/hooks/useRoute";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {useTranslation} from "next-i18next";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import OfferSearchUtils from "@/src/core/app/utils/offer-search";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import React from "react";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";

const ModalOfferSearchDatesFilterMobile = (): JSX.Element | null => {
  const { t } = useTranslation();
  const route = useRoute();
  const device = useDevice();
  const modalState = useModalProvider(state => state);
  const dateRange = OfferSearchUtils.getRangeFromRoute(route);

  const onSubmit = (range: SearchParamsDateRange) => {
    modalState.closeModal();
    OfferSearchUtils.onSubmitDatesFilter(range);
  };

  return (
    <ModalLayoutFullscreen onCloseModal={() => modalState.closeModal()}>
      <div className='p-5 pt-15 border-b border-gray-20'>
        <h2 className="title-3-mobile text-center">
          {t('OfferSearch._Index.show_date_filter_popup_label')}:
        </h2>
      </div>
      <div className="flex-1 flex flex-col p-5">
        <SearchFormCalendarWidget
          device={device}
          onSubmit={onSubmit}
          startDate={dateRange.start}
          endDate={dateRange.end}
        />
      </div>
    </ModalLayoutFullscreen>
  );
}

export default ModalOfferSearchDatesFilterMobile;
