import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import Button from "@/src/ui/components/generic/Button";

interface _Props {
  title: string
  onClick: VoidFunction
}

const _Link = (props: _Props) => {
  const device = useDevice();
  return (
    <div
      className={cn(
        device.isDesktop && "w-60",
        device.isMobile && 'flex w-full'
      )}
      onClick={props.onClick}
    >
      <Button
        text={props.title}
        style="light"
        bold
        className={cn(device.isMobile && 'flex-1')}
      />
    </div>
  )
}

export default _Link;
