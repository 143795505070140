import Title4 from "../../generic/Title4";
import Button from "../../generic/Button";
import Card from "../../generic/Card";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Anchor from "@/src/ui/components/generic/Anchor";
import OfferGroupUtils from "@/src/core/app/utils/offer-group";
import {useTranslation} from "next-i18next";
import {T_OfferGroupTeaserDesktop} from "@/src/core/app/domain/models/offer-group/T_OfferGroupTeaserDesktop";

type Props = {
  offerGroup: T_OfferGroupTeaserDesktop
}

const OfferGroupTeaserDesktop = ({ offerGroup } : Props): JSX.Element => {
  const { t } = useTranslation();

  const cardProps = offerGroup.field_offer_group_teaser ? {
    mediaImage: offerGroup.field_offer_group_teaser,
  } : {
    imgRaw: {src: '/assets/images/dummy/desktop/offer-1.png'},
  }
  return (
    <Card {...cardProps}>
      <div className="pb-2.5 font-calistoga">
        <Title4>{offerGroup.title}</Title4>
      </div>
      {offerGroup.field_body && (
        <WrapperDiv size="medium" dangerouslySetInnerHTML={{__html: offerGroup.field_body.processed}} />
      )}
      <div className="absolute inset-x-0 bottom-10 mx-auto w-32 px-1 hover-show-child">
        <Anchor href={OfferGroupUtils.getLinkUrl(offerGroup)}>
          <Button text={t('OfferGroupTeaser._Index.button_label')} style="secondary-white"/>
        </Anchor>
      </div>
    </Card>
  );
}

export default OfferGroupTeaserDesktop;
