import _PriceTagCommon from "@/src/ui/components/offer/OfferDetail/price-tag/by-type/common";

interface _Props {
  description?: string
}

const _PriceTagNoPrice = (props: _Props) => {
  if (!props.description) {
    return null;
  }
  return (
    <_PriceTagCommon>
      <p className='text-sm text-brand-2 bold'>{props.description}</p>
    </_PriceTagCommon>
  );
}

export default _PriceTagNoPrice;
