import {TFunction} from "react-i18next";

const _commonValidation = (t: TFunction, values: any) => {
  const errors : Record<string, string> = {};

  if (values.termsAndConditions === false) {
    errors.termsAndConditions = t('CheckoutCustomerInfoForm.validation.terms_and_conditions_label')
  }
  return errors;
};

export default _commonValidation;
