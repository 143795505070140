import Section from "../../generic/Section/Section";
import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import StarRating from "../../generic/StarRating";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";

interface _Props {
  checkoutHotel: T_CheckoutHotel
}

const HeaderHotelCheckoutDesktop = (props: _Props): JSX.Element => (
  <Section className="bg-gray-10 border-t border-b border-gray-40" backgroundColor='none'>
    <MaxWidthWrapper>
      <div className="flex items-end space-x-5">
        <h1 className="title-3 leading-none">{props.checkoutHotel.title}</h1>
        <div className="pb-px">
          <StarRating size="big" rating={props.checkoutHotel.field_stars} />
        </div>
        <p className="text-lg text-brand-2-90 leading-none">
          {props.checkoutHotel.destination_title}
        </p>
      </div>
    </MaxWidthWrapper>
  </Section>
);

export default HeaderHotelCheckoutDesktop;
