import {T_ParagraphDestinationList} from "@/src/core/app/domain/models/paragraph/T_ParagraphDestinationList";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import DestinationTeaserSquared from "src/ui/components/destination/DestinationTeaserSquared";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const BlockDestinationListMobile = (props: T_ParagraphDestinationList) => (
  <Section paddingY="pt-7.5 pb-15" backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      {props.field_title_with_tag && (
        <TitleWithTag
          className="title-4 mb-5 font-calistoga"
          field={props.field_title_with_tag}
          suffix={`(${props.destinationTeasers.length})`}
        />
      )}
      <ul className="space-y-7.5">
        {props.destinationTeasers.map((item) => (
          <li className='flex' key={item.id}>
            <DestinationTeaserSquared {...item} />
          </li>
        ))}
      </ul>
    </MaxWidthWrapper>
  </Section>
);


export default BlockDestinationListMobile;