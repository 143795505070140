import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_FieldTextTextarea} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextTextarea";

export enum CMS_ParagraphAlertBlock_AlertType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface CMS_ParagraphAlertBlock extends CMS_ParagraphEntity {
  field_alert_type: CMS_ParagraphAlertBlock_AlertType
  field_body: CMS_FieldTextTextarea
}