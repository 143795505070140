import {createProvider} from "@/src/common/utils/zustand";
import {UserRegisterState} from "@/src/ui/view_models/user-register.state";
import {UserRegisterStep} from "@/src/core/app/domain/models/user-register/user-register-step";
import {UserRegisterData} from "@/src/core/app/domain/models/user-register/UserRegisterData";


export const useUserRegisterProvider = createProvider<UserRegisterState>((set, get) => ({
  step: UserRegisterStep.STEP_1,
  data: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    gender: '',
    phoneNumber: '',
    country: '',
    language: '',
    tripStyle: [],
    // newsletterCategories: [],
  },
  setStep: (step: UserRegisterStep) => set(state => {
    state.step = step;
  }),
  setData: (data: Partial<UserRegisterData>) => set(state => {
    Object.keys(data).forEach(key => {
      // @ts-ignore
      state.data[key] = data[key];
    });
  }),
  getData: () => get().data,
}));
