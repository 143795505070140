import CMS_ParagraphReference from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphReference";
import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

export enum CMS_ParagraphInfoCards_Display {
  "1col" = "1col",
  "2cols" = "2cols",
  "3cols" = "3cols",
  "4cols" = "4cols",
}

export interface CMS_ParagraphInfoCards extends CMS_ParagraphEntity {
  field_background_color?: string
  field_display: CMS_ParagraphInfoCards_Display
  field_title_with_tag?: CMS_FieldTextWithTag
  field_info_cards_items: CMS_ParagraphReference[]
}