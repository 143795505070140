import InputWrapper, { InputWrapperProps } from "../../form/InputWrapper";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {MouseEventHandler} from "react";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";

interface ButtonOccupationSummaryProps {
  className?: string
  onClick?: MouseEventHandler
  color?: InputWrapperProps["color"]
  size?: 'normal' | 'small'
}

const ButtonOccupationSummary = ({
  size = 'normal',
  ...props
}: ButtonOccupationSummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const rooms = useSearchFormProvider(state => state.rooms);

  return (
    <div className={props.className} onClick={props.onClick}>
      <InputWrapper
        color={props.color}
        className="text-brand-2 cursor-pointer flex items-center"
      >
        <ul className="flex-1 flex space-x-4 overflow-hidden">
          <li className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">
            <img className="h-full w-auto" src="/assets/icons/bed-brand-2.svg" alt="" />
            <span className="text-sm">{
              size === 'normal' ?
                SearchParamsFormatter.getRoomsSummary(rooms, t) :
                SearchParamsUtils.getRoomCount(rooms)
            }</span>
          </li>
          <li className="flex flex-shrink-0 items-center space-x-2 leading-none h-3.5">
            <img className="h-full w-auto" src="/assets/icons/person-brand-2.svg" alt="" />
            <span className="text-sm">{SearchParamsFormatter.getOccupationSummary(rooms, t)}</span>
          </li>
        </ul>
      </InputWrapper>
    </div>
  );
}

export default ButtonOccupationSummary;
