import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {FormErrorMap} from "@/src/core/app/domain/forms/@types/FormErrorMap";

const CheckoutStepRoomsErrors : FormErrorMap = {
  'date_from:error:date-from-less-than-today': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'booking_step_rooms.date_from.error.date-from-less-than-today.error_title',
      message: 'booking_step_rooms.date_from.error.date-from-less-than-today.error_message',
    }
  },
  'no-availability': {
    notification: {
      type: T_NotificationType.WARNING,
      title: 'booking_step_rooms.no-availability.error_title',
      message: 'booking_step_rooms.no-availability.error_message',
    }
  },
}

export default CheckoutStepRoomsErrors;
