import { ReactNode } from "react";
import Section from "../../generic/Section/Section";
import MaxWidthWrapper from "../../generic/MaxWidthWrapper";

interface CheckoutLayoutProps {
  rightChild: ReactNode;
  leftChild: ReactNode;
}

const CheckoutLayout = ({
  rightChild,
  leftChild,
}: CheckoutLayoutProps): JSX.Element => (
  <Section paddingY="pt-5 pb-20">
    <MaxWidthWrapper>
      <div className="flex space-x-10">
        <div className="flex-1">{leftChild}</div>
        <div className="flex-none w-95">{rightChild}</div>
      </div>
    </MaxWidthWrapper>
  </Section>
);

export default CheckoutLayout;
