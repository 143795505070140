import {FormInputCheckboxOption} from "@/src/ui/@types/form/FormInputCheckboxOption";
import {useField} from "formik";
import FormInputCheckbox from "@/src/ui/components/formik/FormInputCheckbox";
import cn from "classnames";

interface _Props {
  label?: string
  name: string
  options: FormInputCheckboxOption[]
  optionsWrapperClassName?: string
}

const FormInputCheckboxGroup = (props: _Props): JSX.Element => {
  const {
    optionsWrapperClassName = 'space-y-1.5',
  } = props;
  const [field, meta] = useField(props.name);
  return (
    <div className="flex-1 block w-full">
      {props.label && <p className="text-medium-bold pb-3.75">{props.label}</p>}
      <div className={cn("text-brand-2", optionsWrapperClassName)}>
        {props.options.map((option) => (
          <FormInputCheckbox
            name={props.name}
            key={option.value}
            color="brand-2"
            value={option.value}
            showError={false}
          >
            {option.label}
          </FormInputCheckbox>
        ))}
      </div>
      {meta.error && <p className="text-xs text-red pt-1.5">{meta.error}</p>}
    </div>
  );
};

export default FormInputCheckboxGroup;
