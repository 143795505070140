import {
  T_ParagraphIconList,
  T_ParagraphIconList_ListDisplay
} from "@/src/core/app/domain/models/paragraph/T_ParagraphIconList";
import _BlockIconListMobileUp from "@/src/ui/components/blocks/BlockIconList/mobile/by-list-display/up";
import _BlockIconListMobileInline from "@/src/ui/components/blocks/BlockIconList/mobile/by-list-display/inline";

const BlockIconListMobile = (props: T_ParagraphIconList) => {
  if (props.field_list_display === T_ParagraphIconList_ListDisplay.UP) {
    return (
      <_BlockIconListMobileUp {...props} />
    );
  }
  return (
    <_BlockIconListMobileInline {...props} />
  );
}


export default BlockIconListMobile;
