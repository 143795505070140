import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import {CheckoutRates} from "@/src/core/app/domain/models/checkout/CheckoutRates";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import CheckoutRoomListDesktop from "@/src/ui/components/checkout/desktop/CheckoutRoomList";
import {CheckoutStepRoomsStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsStatus";
import _CheckoutError from "@/src/ui/components/offer/OfferDetail/checkout/_error";
import _CheckoutEmpty from "@/src/ui/components/offer/OfferDetail/checkout/_empty";
import {useDevice} from "@/src/ui/hooks/useDevice";
import CheckoutRoomListMobile from "@/src/ui/components/checkout/mobile/CheckoutRoomList";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";

const _Checkout = (props: T_OfferDetail) => {
  const device = useDevice();
  const checkout = useCheckoutProvider(state => state);
  const stepData = checkout.currentStepData as CheckoutStepRoomsData;

  if (stepData.status === CheckoutStepRoomsStatus.ERROR) {
    return (
      <_CheckoutError />
    )
  }

  const rates = stepData.rates as CheckoutRates;
  if (rates.hotels.length === 0) {
    return (
      <_CheckoutEmpty />
    );
  }

  const _Component = device.isDesktop ? CheckoutRoomListDesktop : CheckoutRoomListMobile;

  return (
    <_Component
      rooms={props.cpt_ui_rooms}
      rates={rates}
    />
  );
}

export default _Checkout;