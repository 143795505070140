import {useDevice} from "@/src/ui/hooks/useDevice";
import {T_ParagraphUserPasswordEditForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserPasswordEditForm";
import BlockUserPasswordChangeFormDesktop from "@/src/ui/components/blocks/BlockUserPasswordChangeForm/desktop";
import BlockUserPasswordChangeFormMobile from "@/src/ui/components/blocks/BlockUserPasswordChangeForm/mobile";


const BlockUserPasswordChangeForm = (props: T_ParagraphUserPasswordEditForm) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockUserPasswordChangeFormMobile : BlockUserPasswordChangeFormDesktop;
  return (
    <_Component {...props} />
  );
}
export default BlockUserPasswordChangeForm;
