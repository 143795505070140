import {CheckoutRoom} from "@/src/core/app/domain/@types/CheckoutRoom";
import cn from "classnames";
import {useState} from "react";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import {useTranslation} from "next-i18next";

interface _Props {
  room: CheckoutRoom
  index: number
}

const _Room = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();
  const [isExpanded, setExpanded] = useState(false);

  const room = props.room;

  return (
    <div className="text-sm text-brand-2">
      <div
        onClick={() => setExpanded(!isExpanded)}
        className="w-full pb-5 flex justify-between items-center cursor-pointer"
      >
        <div className="space-y-1.5 text-left">
          <h4 className="text-medium-bold">
            {t('CheckoutSummary._Room.room_label', {
              index: props.index + 1
            })}
          </h4>
          <p>{room.roomName}</p>
        </div>
        <img
          className={cn(
            "transform transition h-3 w-auto",
            isExpanded ? '-rotate-90' : 'rotate-90'
          )}
          src="/assets/icons/chevron-right-brand-2.svg"
        />
      </div>
      <div
        className={cn(
          "space-y-3.5 transition-all overflow-hidden duration-500",
          isExpanded ? 'max-h-screen mb-5' : 'max-h-0 mb-0'
        )}
      >
        <div className="space-y-1.5">
          <h4 className="font-bold text-brand-2-60">{t('CheckoutSummary._Room.occupation_label')}</h4>
          {room.user_adults_count > 0 && (
            <p>{SearchParamsFormatter.getOccupationAdults(room.user_adults_count, t)}</p>)
          }
          {room.user_kids_count > 0 && (
            <p>{SearchParamsFormatter.getOccupationKids(room.user_kids_count, t)}</p>)
          }
        </div>
        <div className="space-y-1.5">
          <h4 className="font-bold text-brand-2-60">{t('CheckoutSummary._Room.rate_and_board_label')}</h4>
          <div className="flex justify-between">
            <p className="w-44">
              {room.rateGroupName}
              <br/>
              {room.boardTitle}
            </p>
            <p className="text-big">{UiCurrencyUtils.format(room.price, currencyConfig)}</p>
          </div>
        </div>
        {/*<div className="space-y-1.5">*/}
        {/*  <h4 className="font-bold text-brand-2-60">Tipo de cama</h4>*/}
        {/*  <p className="text-xs">{bedType}</p>*/}
        {/*</div>*/}
        {/*<div className="space-y-1.5">*/}
        {/*  <h4 className="font-bold text-brand-2-60">Extras</h4>*/}
          {/*<ul>*/}
          {/*  {extraServices.map(({name, price}) => (*/}
          {/*    <li className="flex justify-between items-center" key={name}>*/}
          {/*      <div className="flex items-center space-x-1.5">*/}
          {/*        <img*/}
          {/*          className="h-3 w-auto"*/}
          {/*          src="/assets/icons/close-small-brand-2.svg"*/}
          {/*          alt=""*/}
          {/*        />*/}
          {/*        <span>{name}</span>*/}
          {/*      </div>*/}
          {/*      <span>{price}</span>*/}
          {/*    </li>*/}
          {/*  ))}*/}
          {/*</ul>*/}
      {/*  </div>*/}
      </div>
      <div className="flex justify-between">
        <h4 className="font-bold text-brand-2-60 uppercase">
          {t('CheckoutSummary._Room.room_subtotal_label')}
        </h4>
        <p className="text-big">
          {UiCurrencyUtils.format(room.price, currencyConfig)}
        </p>
      </div>
    </div>
  );
}

export default _Room;
