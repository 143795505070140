import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import WarningBanner from "@/src/ui/components/desktop/WarningBanner";
import {SearchFormCalendarWidget} from "@/src/ui/components/search-form/SearchFormCalendarWidget";
import {useOfferFormProvider} from "@/src/ui/providers/offer-form.provider";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {OfferData} from "@/src/ui/view_models/offer-form.state";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {useState} from "react";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import {CMS_OfferEntity_NightsType} from "@/src/core/app/domain/cms/@types/offer/CMS_OfferEntityWithNights";
import {useTranslation} from "next-i18next";

const ModalOfferFormDateRangeGeneric = (): JSX.Element => {
  const { t } = useTranslation();
  const device = useDevice();
  const [isValid, setIsValid] = useState(true);
  const offerFormState = useOfferFormProvider(state => state);
  const closeModal = useModalProvider(state => state.closeModal);
  const offerData = offerFormState.offerData as OfferData;

  const onSubmit = (range: SearchParamsDateRange) => {
    if (!range.start) {
      closeModal();
      return;
    }

    const diff = SearchParamsUtils.getDateRangeNights(range);
    if (offerData.nights.nights_min && (diff < offerData.nights.nights_min)) {
      setIsValid(false);
      return;
    }

    offerFormState.setDateRange(range);
    closeModal();
  };

  const onChange = (range: SearchParamsDateRange) => {
    setIsValid(true);
  };

  const _Component = device.isDesktop ? ModalLayout : ModalLayoutFullscreen;
  const componentProps = device.isDesktop ? {
    title: t("ModalOfferFormDateRange._Index.title"),
    onCloseModal: () => closeModal(),
  } : {
    title: t("ModalOfferFormDateRange._Index.title"),
    onCloseModal: () => closeModal(),
    wrapperClassName: 'h-full overflow-hidden',
    contentClassName: 'flex flex-col'
  };

  return (
    <_Component {...componentProps}>
      {isValid && offerData.nights.nights_min && (
        <WarningBanner paddingX="px-5">
          {t('ModalOfferFormDateRange._Index.min_nights_warning', {
            count: offerData.nights.nights_min
          })}
        </WarningBanner>
      )}
      {!isValid && (
        <WarningBanner paddingX="px-5" level='error'>
          {t('ModalOfferFormDateRange._Index.min_nights_error', {
            count: offerData.nights.nights_min
          })}
        </WarningBanner>
      )}
      <SearchFormCalendarWidget
        device={device}
        onSubmit={onSubmit}
        onChange={onChange}
        startDate={offerFormState.dateRange.start}
        endDate={offerFormState.dateRange.end}
        minDate={offerFormState.minDate}
        maxDate={offerFormState.maxDate || undefined}
        nightsCount={offerData.nights.nights_type === CMS_OfferEntity_NightsType.FIXED ? offerData.nights.nights_count : undefined}
      />
    </_Component>
  );
}

export default ModalOfferFormDateRangeGeneric;
