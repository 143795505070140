import FormInputWrapper from "../../form/FormInputWrapper";
import React from "react";
import {useTranslation} from "next-i18next";
import {FormFieldProps} from "@/src/ui/@types/form/FormFieldProps";
import {useFormField} from "@/src/ui/hooks/useFormField";

interface _Props extends FormFieldProps {
  label?: string
  placeholder?: string
  className?: string
}

const FormInputTextarea = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    label,
    placeholder,
    className,
    ...fieldProps
  } = props;

  const [field, meta] = useFormField(props, t);
  return (
    <FormInputWrapper
      height={30}
      label={label}
      error={meta.error}
      className={className}
    >
      <textarea
        className={`block w-full font-sans text-brand-2-90 text-lg placeholder-current h-full resize-none`}
        placeholder={placeholder}
        {...field}
      />
    </FormInputWrapper>
  );
}

export default FormInputTextarea;
