import FormInputText from "@/src/ui/components/formik/FormInputText";
import {_FormProps} from "@/src/ui/components/forms/EventForm/types";
import CollapsedFormBox from "@/src/ui/components/desktop/CollapsedFormBox";
import EventUtils from "@/src/core/app/utils/event";
import FormInputTextarea from "@/src/ui/components/formik/FormInputTextarea";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";

const _Catering = (props: _FormProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  const groups = EventUtils.getCateringGroups();
  return (
    <CollapsedFormBox title={t('EventForm._Catering.title')}>
      <div className="px-5 py-3.75 space-y-7.5">
        <div className="space-y-3.75">
          {Object.keys(groups).map((key) => (
            <div
              key={key}
              className={cn(
                device.isDesktop && "flex",
                device.isMobile && "space-y-2.5",
              )}
            >
              <p className="flex-1 text-medium-bold text">
                {t(groups[key] as any)}:
              </p>
              <div className="flex-1 space-y-2.5">
                <div className='flex space-x-5'>
                  <FormInputText
                    label={t('EventForm._Catering.people_label')}
                    name={`cateringData.${key}.people`}
                  />
                  <FormInputText
                    label={t('EventForm._Catering.days_label')}
                    name={`cateringData.${key}.days`}
                  />
                </div>
                <div className='flex space-x-5'>
                  <FormInputText
                    label={t('EventForm._Catering.from_label')}
                    name={`cateringData.${key}.from`}
                  />
                  <FormInputText
                    label={t('EventForm._Catering.to_label')}
                    name={`cateringData.${key}.to`}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">{t('EventForm._Catering.additional_info_label')}: </p>
            <div className="flex-1">
              <FormInputTextarea name='cateringAdditionalInfo' />
            </div>
          </div>
        </div>
      </div>
    </CollapsedFormBox>
  );
}

export default _Catering;