interface _Props {
  iconKey?: string
  className?: string
  alt?: string
  title?: string
}

const AssetCmsIcon = (props: _Props) => {
  const {
    iconKey = 'no-icon'
  } = props;
  if (props.iconKey == 'no-icon' || props.iconKey === null) {
    return null;
  }
  return (
    <img
      className={props.className}
      alt={props.alt}
      title={props.title}
      src={`/assets/cms/icons/${iconKey}.svg`}
    />
  );
};

export default AssetCmsIcon;
