import {
  CMS_MenuHeaderUserItem,
  CMS_MenuHeaderUserItemVisibility
} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuHeaderUserItem";
import {UserState} from "@/src/ui/view_models/user.state";

const MenuHeaderUserUtils = {
  isMenuItemVisible: (menuItem: CMS_MenuHeaderUserItem, userState: UserState) : boolean => {
    switch (menuItem.field_menu_hu_visibility) {
      case CMS_MenuHeaderUserItemVisibility.ALL:
        return true;
      case CMS_MenuHeaderUserItemVisibility.ONLY_ANONYMOUS:
        return !userState.isLoggedIn();
      case CMS_MenuHeaderUserItemVisibility.ONLY_AUTHENTICATED:
        return userState.isLoggedIn();
    }

    throw new Error('isMenuItemVisible: Invalid visibility');
  },
}

export default MenuHeaderUserUtils;
