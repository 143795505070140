import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import HotelMapSection from "src/ui/components/hotel/HotelMapSection";
import {useRef} from "react";
import useHTMLElementHasBeenDisplayed from "@/src/ui/hooks/useHTMLElementHasBeenDisplayed";

interface _Props {
  layer?: 'mapbox' | 'leaflet'
  hotelList: T_HotelTreeHotel[]
  className?: string
}

const _Map = (props: _Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const initiated = useHTMLElementHasBeenDisplayed(ref)

  return (
    <div ref={ref}>
      <HotelMapSection
        hotelList={props.hotelList}
        initiated={initiated}
        layer='mapbox'
        className={props.className}
      />
    </div>
  );
};

export default _Map;