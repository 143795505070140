import type {RouteProps} from "@/src/ui/@types/RouteProps";
import BlockListComponent from "@/src/ui/components/generic/BlockListComponent";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import InfoBlockDesktop from "src/ui/components/desktop/InfoBlockDesktop";
import {T_NodeDestinationDetail} from "@/src/core/app/domain/models/node/T_NodeDestinationDetail";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_DestinationDetailDesktop = (props: RouteProps<T_NodeDestinationDetail>) : JSX.Element => {
  const node = props.routeData.data;
  return (
    <>
      <PageHeader header={props.routeData.data.page_header} />
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <InfoBlockDesktop
        title={node.title}
        bodyHtml={node.field_body?.processed as string}
        {...props}
      />
      <BlockListComponent blocks={props.routeData.data.field_blocks} />
    </>
  );
}

export default Route_DestinationDetailDesktop;
