import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockSimpleGalleryDesktop from "@/src/ui/components/blocks/BlockSimpleGallery/desktop";
import BlockSimpleGalleryMobile from "@/src/ui/components/blocks/BlockSimpleGallery/mobile";
import {T_ParagraphSimpleGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphSimpleGallery";


const BlockSimpleGallery = (props: T_ParagraphSimpleGallery) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockSimpleGalleryMobile : BlockSimpleGalleryDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockSimpleGallery;
