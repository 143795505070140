import {T_ParagraphInfoSquared} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoSquared";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import UiMediaUtils from "@/src/ui/utils/media";
import {useDevice} from "@/src/ui/hooks/useDevice";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const BlockInfoSquared = (props: T_ParagraphInfoSquared) => {
  const device = useDevice();
  return (
    <>
      <TitleWithTag
        className="title-4 pb-5 font-calistoga"
        field={props.field_title_with_tag}
      />
      <article className="py-3.5 px-5 text-xs text-brand-2-90 border border-gray-40 rounded-sm">
        <WrapperDiv
          className="space-y-2.5 mb-5"
          dangerouslySetInnerHTML={{__html: props.field_body.processed}}
        />
        {!device.isMobile && (
          <img
            className="block h-62.5 w-full object-cover"
            {...UiMediaUtils.getImgPropsFromMediaImage(props.field_block_image)}
          />
        )}
        {device.isMobile && (
          <img
            className="w-full"
            {...UiMediaUtils.getImgPropsFromMediaImage(props.field_block_image_mobile)}
          />
        )}

      </article>
    </>
  )
}
export default BlockInfoSquared;
