import {useDevice} from "@/src/ui/hooks/useDevice";
import _ActionsMobile from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/mobile/actions";
import _ActionsDesktop from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/desktop/actions";

const _Actions = () => {
  const device = useDevice();
  const _Component = device.isMobile ? _ActionsMobile : _ActionsDesktop;
  return (
    <_Component />
  );
};

export default _Actions;
