import {CurrencyState} from "@/src/ui/view_models/currency.state";
import {createStore, useStore} from "zustand";
import {immer} from "zustand/middleware/immer";
import BrowserUtils from "@/src/ui/utils/browser";
import CurrencyUtils from "@/src/core/app/utils/currency";

export const currencyStore = createStore<CurrencyState>()(immer((set, get) => {
  BrowserUtils.onAppInitiated(() => {
    get().init();
  });

  return {
    isInitiated: false,
    currencyCode: null,

    init: () => set(state => {
      state.isInitiated = true;
      state.currencyCode = CurrencyUtils.getCurrencyCodeFromCookie() || 'EUR';
    }),

    setCurrencyCode: (code: string) => set(state => {
      state.currencyCode = code;
    })
  }
}));

export function useCurrencyProvider(): CurrencyState;
export function useCurrencyProvider<T>(selector: (state: CurrencyState) => T, equals?: (a: T, b: T) => boolean): T;
export function useCurrencyProvider(selector?: any, equals?: any) {
  return useStore(currencyStore, selector, equals);
}
