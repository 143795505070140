import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import cn from "classnames";
import {T_ParagraphMultiGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphMultiGallery";

interface _Props {
  multiGallery: T_ParagraphMultiGallery
  currentTabIndex: number
  onTabClicked: (index: number) => void
}

const _Tabs = (props: _Props) => (
  <div className="flex-1 flex flex-col">
    <div className="border-b border-t border-gray-20 px-5 flex-none">
      <MaxWidthWrapper>
        <ul className="py-3 text-xs uppercase divide-x divide-x-gray-20 flex overflow-x-auto">
          {props.multiGallery.field_multi_gallery_items.map((item, index) => (
            <li
              key={item.id}
              className={cn(
                index === props.currentTabIndex && 'text-brand-1 font-bold',
                index !== props.currentTabIndex && 'text-brand-2 hover:font-bold hover:text-brand-1',
                index !== 0 && 'pl-7.5',
                "py-2 pr-7.5 flex flex-col whitespace-nowrap items-center cursor-pointer"
              )}
              onClick={() => props.onTabClicked(index)}
            >
              <div>{item.field_title}</div>
              <div
                className={cn(
                  "h-0.5 bg-brand-1 w-full transition-all transition-1000 transform translate-y-5",
                  index === props.currentTabIndex ? 'max-w-xs' : 'max-w-0',
                )}
              />
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </div>
  </div>
);

export default _Tabs;
