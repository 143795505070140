import {CMS_FieldLink} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldLink";
import CmsLinkButton from "@/src/ui/components/generic/CmsLinkButton";

interface _Props {
  className?: string
  links: CMS_FieldLink[]
}

const CmsLinkButtonMultiple = (props: _Props) => (
  <div className={props.className}>
    {props.links.map((item, index) => (
      <CmsLinkButton link={item} key={index} />
    ))}
  </div>
);

export default CmsLinkButtonMultiple;