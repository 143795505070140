import Section from "../Section/Section";
import cn from "classnames";
import {ReactNode} from "react";

interface _Props {
  children: ReactNode
  paddingY?: string
  fullHeight?: boolean
  className?: string
}

const SectionGray = (props: _Props): JSX.Element => {
  const {
    className,
    children,
    ...childProps
  } = props;
  return(
    <div className={cn("bg-gray-20 text-brand-2", className)}>
      <Section {...childProps} backgroundColor='none'>
        {children}
      </Section>
    </div>
  );
}

export default SectionGray;
