import ShimmerEffect from "@/src/ui/components/generic/ShimmerEffect";

const HotelRoomTeaserLoadingMobile = () => {
  return (
    <article className="space-y-2.5">
      <ShimmerEffect className='h-57.5 w-full' />
      <ShimmerEffect className="h-9 w-full" />
      <ShimmerEffect className="h-9 w-full" />
    </article>
  )
}

export default HotelRoomTeaserLoadingMobile;
