import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockLoungesListDesktop from "@/src/ui/components/blocks/BlockLoungesList/desktop";
import BlockLoungesListMobile from "@/src/ui/components/blocks/BlockLoungesList/mobile";
import {T_ParagraphLoungesList} from "@/src/core/app/domain/models/paragraph/T_ParagraphLoungesList";


const BlockLoungesList = (props: T_ParagraphLoungesList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockLoungesListMobile : BlockLoungesListDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockLoungesList;
