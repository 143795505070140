import TitledBox from "@/src/ui/components/generic/TitledBox";
import FormInputCheckboxGroup from "@/src/ui/components/formik/FormInputCheckboxGroup";
import UserUtils from "@/src/core/app/utils/user";
import Button from "@/src/ui/components/generic/Button";
import {UserEditTripStyleFormComponentProps} from "@/src/ui/components/forms/UserEditTripStyleForm/types";
import cn from "classnames";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";

const UserEditTripStyleFormComponent = (props: UserEditTripStyleFormComponentProps) => {
  const { t } = useTranslation();
  const device = useDevice();

  return (
    <TitledBox title={t('UserEditTripStyleForm._Index.title')}>
      <div className={cn([
        'space-y-5',
        device.isDesktop && "py-3.75 px-5",
        device.isMobile && "p-3.75",
      ])}>
        <FormInputCheckboxGroup
          label={t('UserEditTripStyleForm._Index.trip_style_label')}
          name="tripStyle"
          options={I18nUtils.translateFormInputSelectOptions(
            UserUtils.getTripStyleOptions(), t
          )}
        />
        <div className='space-x-3.75'>
          <Button bold text={t('UserEditTripStyleForm._Index.submit_button_label')} onClick={props.formik.handleSubmit} isActive={!props.formik.isSubmitting}/>
          <span className="cursor-pointer text-link-bold" onClick={props.closeFn}>
            {t('UserEditTripStyleForm._Index.cancel_button_label')}
          </span>
        </div>
      </div>
    </TitledBox>
  );
}

export default UserEditTripStyleFormComponent;
