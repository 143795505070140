import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {ElbaMediaGolfItemFolder} from "@/src/core/app/domain/elba-media/@types/ElbaMediaGolfItemFolder";
import { Route } from "@/src/core/app/domain/@types/Route";
import UiElbaMediaUtils from "@/src/ui/utils/elba-media";

interface _Props {
  route: Route
  item: ElbaMediaGolfItemFolder
}

const _RowFolder = (props: _Props) => {
  const {
    route,
    item,
  } = props;

  return (
    <tr>
      <td>
        <a className='Title--Folder' href={UiElbaMediaUtils.getFolderUrl(route, item.slug)}>
          {item.title}
        </a>
      </td>
      <td colSpan={3}>
        {!item.description && ''}
        {item.description && (
          <WrapperDiv
            dangerouslySetInnerHTML={{__html: item.description.value}}
          />
        )}
      </td>
    </tr>
  )
};

export default _RowFolder;
