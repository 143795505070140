import FormUtils from "@/src/ui/utils/form";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import {_FormProps} from "@/src/ui/components/forms/EventForm/types";
import CollapsedFormBox from "@/src/ui/components/desktop/CollapsedFormBox";
import FormInputTextarea from "@/src/ui/components/formik/FormInputTextarea";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";

const _Rooms = (props: _FormProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  return (
    <CollapsedFormBox title={t('EventForm._Rooms.title')}>
      <div className="px-5 py-3.75 space-y-7.5">
        <div className="space-y-3.75">
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">
              {t('EventForm._Rooms.description')}:
            </p>
            <div className={cn(
              device.isDesktop && "flex flex-1 space-x-5",
              device.isMobile && "space-y-2.5",
            )}>
              <FormInputSelect
                name='individualRooms'
                label={t('EventForm._Rooms.individual_label') + " *"}
                options={FormUtils.getNumberSelectOptions(5, 0)}
                validate={FormUtils.validateIsRequired}
              />
              <FormInputSelect
                name='doubleRooms'
                label={t('EventForm._Rooms.double_label') + " *"}
                options={FormUtils.getNumberSelectOptions(5, 0)}
                validate={FormUtils.validateIsRequired}
              />
              <FormInputSelect
                name='suiteRooms'
                label={t('EventForm._Rooms.suite_label') + " *"}
                options={FormUtils.getNumberSelectOptions(5, 0)}
                validate={FormUtils.validateIsRequired}
              />
            </div>
          </div>
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">
              {t('EventForm._Rooms.additional_info_label')}:
            </p>
            <div className="flex-1">
              <FormInputTextarea name='roomsAdditionalInfo' />
            </div>
          </div>
        </div>
      </div>
    </CollapsedFormBox>
  );
}

export default _Rooms;