import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import HotelMapSection from "@/src/ui/components/hotel/HotelMapSection";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";

interface _Props {
  hotelTeasers: T_HotelTeaser[]
}

const _Map = (props: _Props) => {
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  let hotels = HotelTreeUtils.getHotels(layoutData.hotelTree);
  const currentHotelIds = props.hotelTeasers.map(hotelTeaser => hotelTeaser.id);
  hotels = hotels.filter(item => currentHotelIds.indexOf(item.id) !== -1);

  return (
    <HotelMapSection
      hotelList={hotels}
      initiated={true}
      className='h-109.5'
      onChangeActivated={true}
    />
  );
};

export default _Map;
