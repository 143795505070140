import Button from "@/src/ui/components/generic/Button";
import {
  BlockListHotelFiltrableMode
} from "@/src/ui/components/blocks/BlockHotelListFiltrable/@types/BlockListHotelFiltrableMode";
import {useTranslation} from "next-i18next";

interface _Props {
  mode: BlockListHotelFiltrableMode
  toggleMode: VoidFunction
}

const _ModeButton = (props: _Props) => {
  const { t } = useTranslation();

  return (
    <Button
      className='select-none'
      style="ghost"
      textClassName='text-brand-2'
      text={
        props.mode === BlockListHotelFiltrableMode.LIST ?
          t('BlockHotelListFiltrable._Index.change_to_map_mode_label') :
          t('BlockHotelListFiltrable._Index.change_to_list_mode_label')
      }
      bold
      onClick={props.toggleMode}
    />
  )
};

export default _ModeButton;
