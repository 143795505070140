import { ReactNode } from "react";
import cn from "classnames";

interface Title4Props {
  children: ReactNode
  className?: string
}

const Title4 = ({ children, className }: Title4Props): JSX.Element => (
  <h4 className={cn("text-title-4 leading-tight", className)}>
    {children}
  </h4>
);

export default Title4;
