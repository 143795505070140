import {TFunction} from "react-i18next";

const FormFileUtils = {
  getBase64: (file: File) : Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    })
  },

  getDoxExtensions: () => {
    return [
      'txt',
      'pdf',
      'odf',
      'doc',
      'docx',
      'ppt',
      'pptx',
    ]
  },
  getDocAccept: () => {
    return FormFileUtils.getDoxExtensions().map(ext => '.' + ext).join(', ');
  },

  getFilenameExtension: (filename: string) : string | undefined => {
    const parts = filename.split('.');
    if (parts.length <= 1) {
      return undefined;
    }
    return parts[parts.length - 1];
  },

  validateExtension: (t: TFunction, file: File, extensions: string[]) : string | undefined => {
    const fileExtension = FormFileUtils.getFilenameExtension(file.name);
    if (fileExtension === undefined) {
      return t('form_file_utils.generic_validation.invalid_extension');
    }

    if (extensions.indexOf(fileExtension) === -1) {
      return t('form_file_utils.generic_validation.invalid_extension');
    }
  },

  validateDocExtensions: (t: TFunction, file: File) : string | undefined => {
    const extensions = FormFileUtils.getDoxExtensions();
    return FormFileUtils.validateExtension(t, file, extensions);
  },

  validateDocFileSize: (t: TFunction, file: File) : string | undefined => {
    const mazSize = 8 * 1024 * 1024;
    if (file.size > mazSize) {
      return t('form_file_utils.generic_validation.invalid_size', {max_size: '8 MB'});
    }
  },
};

export default FormFileUtils;
