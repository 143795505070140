import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import NotificationMessage from "src/ui/components/generic/NotificationMessage";
import {T_Notification} from "@/src/ui/@types/T_Notification";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import CheckoutStepRoomsErrors from "@/src/core/app/domain/checkout/@types/FormErrorMap/checkout-step-rooms";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";

const _CheckoutError = () => {
  const checkout = useCheckoutProvider(state => state);
  const stepData = checkout.currentStepData as CheckoutStepRoomsData;

  const error = FormErrorUtils.getError({
    networkError: stepData.error as NetworkResponseError,
    formErrorMap: CheckoutStepRoomsErrors
  });
  return (
    <NotificationMessage {...(error.notification as T_Notification)} />
  );
}

export default _CheckoutError;
