import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import React, {useState} from "react";
import HotelRoomMediaCarousel from "@/src/ui/components/hotel-room/generic/HotelRoomMediaCarousel";
import HotelRoomHeaderHeaderInfoMobile from "@/src/ui/components/hotel-room/mobile/HotelRoomHeaderHeaderInfo";
import cn from "classnames";
import Paragraph from "@/src/ui/components/generic/Paragraph";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelRoom: T_HotelRoomTeaser
  rightTopComponent?: JSX.Element
  bottomComponent?: JSX.Element
  footerComponent?: JSX.Element
}

const HotelRoomTeaserCommonMobile = (props: _Props) => {
  const { t } = useTranslation();
  // const [isExpanded, setExpanded] = useState(false);
  const isExpanded = false;
  const setExpanded = (value: boolean) => {};
  const modalState = useModalProvider(state => state);
  const hotelRoom = props.hotelRoom;
  const toggleRoomPopup = () => {
    modalState.toggleModal(ModalKey.HOTEL_ROOM, {
      room: hotelRoom
    })
  };

  return (
    <article className="border border-gray-20 divide-y divide-gray-20">
      <div>
        <HotelRoomMediaCarousel mediaImages={hotelRoom.field_room_media}/>
      </div>
      <div className="p-4 pb-5 text-sm text-brand-2-90">
        <div className="flex space-x-2.5 justify-between items-start pb-3">
          <h3 className="text-big-bold">{HotelRoomUtils.getTeaserTitle(hotelRoom)}</h3>
          {props.rightTopComponent}
        </div>
        <HotelRoomHeaderHeaderInfoMobile className="space-x-7.5" hotelRoom={hotelRoom}/>
        <div className={cn(
          'overflow-hidden transition-all duration-500 relative',
          isExpanded ? 'max-h-screen' : 'max-h-20'
        )}>
          <WrapperDiv
            size="medium"
            className="py-5"
            dangerouslySetInnerHTML={{__html: hotelRoom.field_body?.processed}}
          />
          {hotelRoom.field_room_details_rooms && (
            <div className="flex-1 space-y-1 mb-5">
              <h3 className="text-medium-bold">{t('HotelRoomTeaserCommon._Index.room_label')}</h3>
              <WrapperDiv
                size='small'
                className="space-y-1 text-xs text-brand-2-90 Cms-Formatted"
                dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_rooms.value}}
              />
            </div>
          )}
          {hotelRoom.field_room_details_bathroom && (
            <div className="flex-1 space-y-1 mb-5">
              <h3 className="text-medium-bold">{t('HotelRoomTeaserCommon._Index.room_label')}</h3>
              <WrapperDiv
                size='small'
                className="space-y-1 text-xs text-brand-2-90 Cms-Formatted"
                dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_bathroom.value}}
              />
            </div>
          )}
          {hotelRoom.field_room_details_equipment && (
            <div className="flex-1 space-y-1 mb-5">
              <h3 className="text-medium-bold">{t('HotelRoomTeaserCommon._Index.equipment_label')}</h3>
              <WrapperDiv
                size='small'
                className="space-y-1 text-xs text-brand-2-90 Cms-Formatted"
                dangerouslySetInnerHTML={{__html: hotelRoom.field_room_details_equipment.value}}
              />
            </div>
          )}
          {isExpanded && (
            <button
              onClick={() => setExpanded(false)}
              className="cursor-pointer text-brand-2 text-xs underline"
            >
              {t('HotelRoomTeaserCommon._Index.hide_details_button_label')}
            </button>
          )}
          {!isExpanded && (
            <div
              className="absolute inset-x-0 bottom-0 h-12 bg-gradient-to-b from-transparent via-white to-white flex items-end text-xs text-brand-2 underline">
              <button
                className="underline cursor-pointer"
                // onClick={() => setExpanded(true)}
                onClick={() => toggleRoomPopup()}
              >
                {t('HotelRoomTeaserCommon._Index.show_details_button_label')}
              </button>
            </div>
          )}
        </div>
        {props.bottomComponent && (
          <div className='pt-2.5'>
            {props.bottomComponent}
          </div>
        )}
      </div>
      {props.footerComponent}
    </article>
  )
};

export default HotelRoomTeaserCommonMobile;
