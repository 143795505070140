import {UserRegisterFormValues} from "@/src/core/app/domain/forms/UserRegisterFormValues";
import {UserRegisterState} from "@/src/ui/view_models/user-register.state";
import {UserRegisterFormStep} from "@/src/ui/components/forms/UserRegisterForm/steps/step";
import {FormikHelpers} from "formik/dist/types";
import {ModalState} from "@/src/ui/view_models/modal.state";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import {UserRegisterStep} from "@/src/core/app/domain/models/user-register/user-register-step";
import {TFunction} from "react-i18next";
import {T_SitePageUrls} from "@/src/core/app/domain/models/site/T_SitePageUrls";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

const UserRegisterStep2Form : UserRegisterFormStep = {
  validations: [],
  onSubmit: async (
    appInfo: AppInfo,
    values: UserRegisterFormValues,
    formikHelpers: FormikHelpers<UserRegisterFormValues>,
    t: TFunction,
    modal: ModalState,
    state: UserRegisterState,
    countries: T_Country[],
    sitePageUrls: T_SitePageUrls,
  ) => {
    state.setData(values);
    state.setStep(UserRegisterStep.STEP_3);
  }
}

export default UserRegisterStep2Form;
