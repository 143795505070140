import type {RouteComponent, RouteComponentSelector, RouteProps} from "@/src/ui/@types/RouteProps";

import {RouteType} from "@/src/core/app/domain/@types/Route";
import Route_PageNoHeader from "src/ui/components/routes/Route_PageNoHeader";
import Route_HotelSection from "src/ui/components/routes/Route_HotelSection";
import Route_CheckoutStepCustomerInfo from "src/ui/components/routes/Route_CheckoutStepCustomerInfo";
import Route_BookingPaymentIframe from "src/ui/components/routes/Route_BookingPaymentIframe";
import Route_BookingConfirmation from "src/ui/components/routes/Route_BookingConfirmation";
import Route_Error from "src/ui/components/routes/Route_Error";
import Route_BookingPaymentError from "src/ui/components/routes/Route_BookingPaymentError";
import Route_UserRegister from "@/src/ui/components/routes/Route_UserRegister";
import Route_Page2Cols from "@/src/ui/components/routes/Route_Page2Cols";
import Route_Page from "@/src/ui/components/routes/Route_Page";
import Route_DestinationDetail from "@/src/ui/components/routes/Route_DestinationDetail";
import Route_ExperienceDetail from "@/src/ui/components/routes/Route_ExperienceDetail";
import Route_ResidentDetail from "@/src/ui/components/routes/Route_ResidentDetail";
import Route_BasicPage from "@/src/ui/components/routes/Route_BasicPage";
import Route_BlogDetail from "@/src/ui/components/routes/Route_BlogDetail";
import Route_BlogCategory from "src/ui/components/routes/Route_BlogCategory";
import Route_JobOfferDetail from "@/src/ui/components/routes/Route_JobOfferDetail";
import Route_OfferGroupDetail from "@/src/ui/components/routes/Route_OfferGroupDetail";
import Route_OfferDetail from "@/src/ui/components/routes/Route_OfferDetail";
import Route_Sitemap from "@/src/ui/components/routes/Route_Sitemap";
import Route_QrPage from "@/src/ui/components/routes/Route_QrPage";
import Route_UserLostPassword from "src/ui/components/routes/Route_UserLostPassword";
import Route_ElbaMedia from "@/src/ui/components/routes/Route_ElbaMedia";
import Route_ElbaMediaGolf from "@/src/ui/components/routes/Route_ElbaMediaGolf";

const map : Record<string, RouteComponent> = {
  [RouteType.ROUTE_ERROR]: Route_Error,
  [RouteType.PAGE_NO_HEADER]: Route_PageNoHeader,
  [RouteType.PAGE]: Route_Page,
  [RouteType.PAGE_2COLS]: Route_Page2Cols,
  [RouteType.QR_PAGE]: Route_QrPage,
  [RouteType.DESTINATION_DETAIL]: Route_DestinationDetail,
  [RouteType.CHECKOUT_STEP_CUSTOMER_INFO]: Route_CheckoutStepCustomerInfo,
  [RouteType.BOOKING_PAYMENT_IFRAME]: Route_BookingPaymentIframe,
  [RouteType.BOOKING_PAYMENT_ERROR]: Route_BookingPaymentError,
  [RouteType.BOOKING_CONFIRMATION]: Route_BookingConfirmation,
  [RouteType.USER_REGISTER]: Route_UserRegister,
  [RouteType.USER_LOST_PASSWORD]: Route_UserLostPassword,
  [RouteType.EXPERIENCE_DETAIL]: Route_ExperienceDetail,
  [RouteType.RESIDENT_DETAIL]: Route_ResidentDetail,
  [RouteType.BASIC_PAGE]: Route_BasicPage,
  [RouteType.BLOG_DETAIL]: Route_BlogDetail,
  [RouteType.BLOG_CATEGORY]: Route_BlogCategory,
  [RouteType.JOB_OFFER_DETAIL]: Route_JobOfferDetail,
  [RouteType.OFFER_GROUP_DETAIL]: Route_OfferGroupDetail,
  [RouteType.OFFER_DETAIL]: Route_OfferDetail,
  [RouteType.SITEMAP]: Route_Sitemap,
  [RouteType.ELBA_MEDIA]: Route_ElbaMedia,
  [RouteType.ELBA_MEDIA_GOLF]: Route_ElbaMediaGolf,
}

const mapSelector : Record<string, RouteComponentSelector> = {
  [RouteType.HOTEL_SECTION]: Route_HotelSection,
};

const getRouteComponent = (props: RouteProps) : RouteComponent | null => {
  if (typeof map[props.route.type] !== 'undefined') {
    return map[props.route.type];
  }
  if (typeof mapSelector[props.route.type] !== 'undefined') {
    return mapSelector[props.route.type](props);
  }
  return null;
}

export default getRouteComponent;
