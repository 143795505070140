import {T_PageHeader} from "@/src/core/app/domain/models/page/T_PageHeader";
import {useDevice} from "@/src/ui/hooks/useDevice";
import PageHeaderDesktop from "@/src/ui/components/generic/PageHeader/desktop";
import PageHeaderMobile from "@/src/ui/components/generic/PageHeader/mobile";

interface _Props {
  header: T_PageHeader
}

const PageHeader = (props: _Props): JSX.Element => {
  const device = useDevice();
  const _Component = device.isMobile ? PageHeaderMobile : PageHeaderDesktop;
  return (
    <_Component header={props.header} />
  );
}

export default PageHeader;
