import {T_NodeJobOfferDetail} from "@/src/core/app/domain/models/node/T_NodeJobOfferDetail";
import Box from "@/src/ui/components/generic/Box";
import JobOfferUtils from "@/src/core/app/utils/job-offer";
import {useTranslation} from "next-i18next";

interface _LineProps {
  label: string
  value: string
}

const _Line = (props: _LineProps) => (
  <div className="flex space-x-2.5 text-sm text-brand-2">
    <div className="flex-none font-bold text-brand-2-90">{props.label}:</div>
    <div className="flex-1">{props.value}</div>
  </div>
);

const _Header = (props: T_NodeJobOfferDetail) => {
  const { t } = useTranslation();
  return (
    <Box padding="p-3.75 sm:px-5">
      <div className="space-y-1">
        <_Line label={t('Route_JobOfferDetail._Header.hotel_label')} value={props.cpt_hotel_title_full} />
        <_Line label={t('Route_JobOfferDetail._Header.experience_label')} value={props.field_job_offer_experience} />
        <_Line label={t('Route_JobOfferDetail._Header.schedule_label')} value={t(JobOfferUtils.getScheduleLabel(props.field_job_offer_schedule) as any)} />
        {props.field_job_offer_salary && (
          <_Line label={t('Route_JobOfferDetail._Header.salary_label')} value={props.field_job_offer_salary} />
        )}
      </div>
    </Box>
  );
}

export default _Header;
