import {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import ProcessSteps from "src/ui/components/generic/ProcessSteps";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import CheckoutSummary from "src/ui/components/checkout/generic/CheckoutSummary";
import CheckoutCustomerInfoForm from "src/ui/components/checkout/generic/CheckoutCustomerInfoForm";
import HeaderHotelCheckoutMobile from "@/src/ui/components/checkout/mobile/HeaderHotelCheckout";

const CheckoutStepCustomerInfoMobile = (props: RouteProps<T_CheckoutHotel>) => {
  const formExtrasEnabled = props.routeData.data.form_extras_enabled;
  return (
    <>
      <HeaderHotelCheckoutMobile checkoutHotel={props.routeData.data} />
      <ProcessSteps
        step={formExtrasEnabled ? 3 : 2}
        steps={CheckoutUtils.getSteps(formExtrasEnabled)}
      />
      <CheckoutCustomerInfoForm />
      <CheckoutSummary />
    </>
  );
}

export default CheckoutStepCustomerInfoMobile;
