import ReturnLink from "@/src/ui/components/generic/ReturnLink";
import HotelUtils from "@/src/core/app/utils/hotel";
import Button from "@/src/ui/components/generic/Button";
import React from "react";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {useFormikContext} from "formik";
import {useTranslation} from "next-i18next";

const _ActionsDesktop = () => {
  const { t } = useTranslation();
  const routeData = useRouteData<T_CheckoutHotel>();
  const formik = useFormikContext();

  return (
    <div className="flex justify-between items-center pt-2.5">
      <ReturnLink href={HotelUtils.getSectionUrl(routeData.data.cpt_hotel_section_rooms)}>
        <strong>{t('CheckoutCustomerInfoForm._ActionsMobile.go_back_link_label')}</strong>
      </ReturnLink>
      <Button text="Confirmar reserva" bold onClick={formik.handleSubmit} isActive={!formik.isSubmitting}/>
    </div>
  )
};

export default _ActionsDesktop;
