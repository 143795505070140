import {T_ParagraphLinksImageList} from "@/src/core/app/domain/models/paragraph/T_ParagraphLinksImageList";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Anchor from "@/src/ui/components/generic/Anchor";
import Section from "@/src/ui/components/generic/Section/Section";
import {useDevice} from "@/src/ui/hooks/useDevice";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import cn from "classnames";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";


const BlockLinksImageList = (props: T_ParagraphLinksImageList) => {
  const device = useDevice();
  return (
    <Section paddingY={device.isDesktop ? "pt-7.5 pb-15" : "py-7.5"}>
      <MaxWidthWrapper>
        <TitleWithTag
          className="title-4 pb-7.5 font-calistoga"
          field={props.field_title_with_tag}
        />
        <ul className={cn(
          "flex items flex-wrap gap-5",
          device.isMobile && 'flex-col',
        )}>
          {props.field_items.map((item, index) => (
            <li
              key={index}
              className={cn(
                device.isDesktop && 'flex-1 h-100 w-70 min-w-60',
                device.isMobile && 'w-full h-33.75',
              )}
            >
              <Anchor
                href={item.link_url}
                className={cn(
                  'relative flex-1 flex items-center justify-center',
                  device.isMobile && 'w-full h-33.75',
                  device.isDesktop && 'flex-1 h-100 w-70 min-w-60',
                )}
              >
                <MediaImageBgImg mediaImage={device.isMobile ? item.mobile_media : item.media} />
                <h3 className="relative title-4-white font-calistoga">{item.title}</h3>
              </Anchor>
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  )
}
export default BlockLinksImageList;
