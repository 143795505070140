import TagList from "../../../desktop/TagList";
import {T_HotelTeaser} from "@/src/core/app/domain/models/hotel/T_HotelTeaser";
import HotelUtils from "@/src/core/app/utils/hotel";
import Anchor from "@/src/ui/components/generic/Anchor";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import UiMediaUtils from "@/src/ui/utils/media";
import _Features from "@/src/ui/components/hotel/HotelTeaser/desktop/features";
import StarRating from "@/src/ui/components/generic/StarRating";
import LocationTag from "@/src/ui/components/generic/LocationTag";
import {CMS_ParagraphHotelList_LinkSection} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";
import UrlUtils from "@/src/core/app/utils/url";
import HotelMetaAddress from "@/src/ui/components/hotel/HotelMetaAddress";
import HotelTeaserJsonLd from "@/src/ui/components/hotel/HotelTeaser/generic/HotelTeaserJsonLd";

interface _Props {
  hotelTeaser: T_HotelTeaser
  linkSection?: CMS_ParagraphHotelList_LinkSection
}

const getTags = (item: T_HotelTeaser) => {
  return item.field_experiences.map((item) => {
    return { label: item.title, href: UrlUtils.getCmsLinkUrlFromPath(item.path) };
  });
};

const HotelTeaserDesktop = (props: _Props) => {
  const {
    hotelTeaser,
    linkSection = CMS_ParagraphHotelList_LinkSection.MAIN,
  } = props;
  const href = HotelUtils.getLinkToSection(hotelTeaser, linkSection) || undefined;
  return (
    <article
      className="flex items-stretch border border-gray-40 divide-x divide-gray-40 bg-white"
      itemScope itemType="https://schema.org/Hotel"
    >
      <Anchor href={href}>
        <img
          className="flex-none min-h-73 object-cover w-95"
          {...UiMediaUtils.getImgPropsFromMediaImage(hotelTeaser.ui_cpt_field_hotel_teaser)}
        />
      </Anchor>
      <div className="flex-1 p-7.5 pt-5">
        <div className="space-y-1">
          <StarRating rating={hotelTeaser.field_stars} />
          <Anchor href={href}>
            <h3 className="text-big-bold" itemProp="name">
              {hotelTeaser.title}
            </h3>
          </Anchor>
          <LocationTag>{hotelTeaser.locality}</LocationTag>
        </div>
        <div className='space-y-5 pt-2'>
          {/*<TagList isGray tags={getTags(hotelTeaser)} />*/}
          {hotelTeaser.field_description && (
            <WrapperDiv
              className="text-sm text-brand-2-90"
              itemProp="description"
              dangerouslySetInnerHTML={{__html:hotelTeaser.field_description.value}}
            />
          )}
          <_Features {...hotelTeaser} />
        </div>
      </div>
      {href && (
        <Anchor
          href={href}
          className="flex-none p-2 flex items-center bg-gray-10"
        >
          <img className="w-6 h-auto" src="/assets/icons/chevron-right-circle.svg" alt="" />
        </Anchor>
      )}
      <HotelMetaAddress data={props.hotelTeaser} />
      <HotelTeaserJsonLd hotelTeaser={props.hotelTeaser} />
    </article>
  );
}

export default HotelTeaserDesktop;
