import Anchor from "@/src/ui/components/generic/Anchor";
import {T_Destination} from "@/src/core/app/domain/models/destination/T_Destination";

interface _RowLinkProps {
  item: T_Destination
}

interface _SitemapDestinationMenuProps {
  title: string
  items: T_Destination[]
}

const _RowLink = (props: _RowLinkProps) => (
  <li>
    <Anchor href={props.item.url}>
      {props.item.title}
    </Anchor>
  </li>
);

const _SitemapDestinationMenu = (props: _SitemapDestinationMenuProps) => (
  <div>
    <p>{props.title}</p>
    <ul>
      {props.items.map(item => (
        <_RowLink key={item.id} item={item} />
      ))}
    </ul>
  </div>
);

export default _SitemapDestinationMenu;
