import {T_HotelSectionHotelCommonInfo} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";
import Section from "@/src/ui/components/generic/Section/Section";
import React, {ChangeEvent} from "react";
import HotelUtils from "@/src/core/app/utils/hotel";
import UrlUtils from "@/src/core/app/utils/url";
import {useTranslation} from "next-i18next";
import { Breadcrumb } from "@/src/core/app/domain/@types/Breadcrumb";
import {useRoute} from "@/src/ui/hooks/useRoute";

interface _Props {
  hotel: T_HotelSectionHotelCommonInfo
  breadcrumb: Breadcrumb
}

const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
  window.location.href = UrlUtils.buildAppUrl(event.target.value);
};

const HotelTabsMobile = (props: _Props) => {
  const { t } = useTranslation();
  const hotel = props.hotel;
  const route = useRoute();
  const {
    sections,
    currentSection
  } = HotelUtils.getSectionsAndCurrentSection(hotel.field_hotel_sections, route, props.breadcrumb);

  if (!currentSection) {
    return null;
  }

  return (
    <Section
      paddingY="pt-7.5"
      className="flex items-baseline space-x-2.5 text-xs uppercase text-brand-2-90"
    >
      <p>{t('Route_HotelSection._Tabs.title')}:</p>
      <span className="relative flex items-baseline space-x-2.5 uppercase cursor-pointer text-small-bold">
        <select
          className='w-full h-full absolute opacity-0 z-50 cursor-pointer'
          value={HotelUtils.getSectionUrl(currentSection) || ''}
          onChange={onChange}
        >
          {sections.map(section => (
            <option
              key={section.id}
              value={HotelUtils.getSectionUrl(section) || ''}
            >
              {HotelUtils.getSectionTitle(section)}
            </option>
          ))}
        </select>
        <span>{HotelUtils.getSectionTitle(currentSection)}</span>
        <img className="w-4 h-auto" src="/assets/icons/chevron-down.svg" alt="" />
      </span>
    </Section>
  );
};

export default HotelTabsMobile;
