import HorizontalCard from "../../mobile/HorizontalCard";
import Button from "../../generic/Button";
import { T_OfferGroupTeaserMobile } from "@/src/core/app/domain/models/offer-group/T_OfferGroupTeaserMobile";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Anchor from "@/src/ui/components/generic/Anchor";
import OfferGroupUtils from "@/src/core/app/utils/offer-group";
import {useTranslation} from "next-i18next";

const OfferGroupTeaserMobile = (props: T_OfferGroupTeaserMobile): JSX.Element => {
  const { t } = useTranslation();
  return (
    <HorizontalCard
      mediaImage={props.field_offer_group_teaser_mobile}
      className='space-y-2.5'
    >
      <h3 className="text-lg font-calistoga">
        {props.title}
      </h3>
      {props.field_body && (
        <WrapperDiv size="medium" className="text-center pb-1" dangerouslySetInnerHTML={{__html: props.field_body.processed}} />
      )}
      <Anchor href={OfferGroupUtils.getLinkUrl(props)}>
        <Button text={t("OfferGroupTeaser._Index.button_label")} style="secondary-white" className='block' />
      </Anchor>
    </HorizontalCard>
  );
}

export default OfferGroupTeaserMobile;
