import {CMS_NodeEntity} from "@/src/core/app/domain/cms/@types/node/CMS_NodeEntity";
import {CMS_EntityWithHeader} from "@/src/core/app/domain/cms/@types/entities/CMS_EntityWithHeader";
import {CMS_FieldTextTextarea} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextTextarea";
import {CMS_HotelReference} from "@/src/core/app/domain/cms/@types/hotel/CMS_HotelReference";
import {CMS_MediaReference} from "@/src/core/app/domain/cms/@types/media/CMS_MediaReference";
import {CMS_NodeWithBreadcrumbFields} from "@/src/core/app/domain/cms/@types/node/CMS_NodeWithBreadcrumbFields";

export enum CMS_NodeJobOffer_Schedule {
  FULL = 'full',
  HALF = 'half',
}

export interface CMS_NodeJobOffer extends CMS_NodeEntity, CMS_EntityWithHeader, CMS_NodeWithBreadcrumbFields {
  field_body?: CMS_FieldTextTextarea
  field_job_offer_experience: string
  field_hotel: CMS_HotelReference
  field_job_offer_schedule: CMS_NodeJobOffer_Schedule
  field_job_offer_teaser?: CMS_MediaReference
  field_job_offer_level?: string
  field_job_offer_count: string
  field_job_offer_personnel?: string
  field_job_offer_requirements?: CMS_FieldTextTextarea
  field_job_offer_salary?: string
  field_teaser?: CMS_FieldTextTextarea

  cpt_hotel_title: string
  cpt_hotel_title_full: string
}