import {CMS_UserTmsInfoMovement} from "@/src/core/app/domain/cms/@types/user/CMS_UserTmsInfoMovement";
import cn from "classnames";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import CalendarUtils from "@/src/ui/utils/calendar";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";

interface _Props {
  movement: CMS_UserTmsInfoMovement
}

const _Row = (props: _Props) => {
  const layoutProps = useLayoutData<UI_LayoutGeneric>();
  const currencyConfig = useCurrencyConfig();

  const {
    movement
  } = props;
  const isPositive = movement.points >= 0;
  const hotel = movement.hotel.length > 0 ?
    HotelTreeUtils.getHotelByTmsCode(movement.hotel, layoutProps.hotelTree) :
    null
  ;

  return (
    <tr className="w-full text-sm text-brand-2-90">
      <td className={cn('py-3', isPositive ? "text-green" : "text-red")}>
        {UiCurrencyUtils.formatNumber(movement.points, currencyConfig, true)}
      </td>
      <td className='p-3'>{hotel ? hotel.title : ''}</td>
      <td className='p-3'>{movement.computed_description}</td>
      <td className='p-3'>{movement.bookingReference}</td>
      <td className='py-3'>{CalendarUtils.formatDate(movement.created, 'dd/MM/yyyy')}</td>
    </tr>
  );
}

export default _Row;
