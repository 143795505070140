import {ModalLoaderData} from "@/src/ui/@types/modal/ModalLoaderData";
import PositionFixedWrapper from "@/src/ui/components/desktop/PositionFixedWrapper";
import {ModalLoaderCircleAnimated} from "@/src/ui/components/portals/generic/ModalLoader/index.styled";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useTranslation} from "next-i18next";

const ModalLoader = (): JSX.Element | null => {
  const {t} = useTranslation();
  const modalKey = useModalProvider(state => state.modalKey);
  const modalData = useModalProvider(state => state.modalData) as ModalLoaderData | null;

  if (modalKey === null) {
    return null;
  }

  const message = modalData?.message ?? t('global.generic_loader.message');
  return (
    <PositionFixedWrapper
      fixedWrapperClassName='bg-brand-2-80-transparent'
    >
      <div className='w-full flex flex-col items-center'>
        <ModalLoaderCircleAnimated className='mb-10'/>
        <p className='font-bold text-white'>{t(message as any)}</p>
      </div>
    </PositionFixedWrapper>
  );
}

export default ModalLoader;
