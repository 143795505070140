import {T_ParagraphInfoCards} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoCards";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import UiMediaUtils from "@/src/ui/utils/media";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import React from "react";
import _TextFields from "@/src/ui/components/blocks/BlockInfoCards/generic/_TextFields";

const _Display1Col = (props: T_ParagraphInfoCards) => (
  <ul className="space-y-5">
    {props.field_info_cards_items.map((item, index) => (
      <li key={item.id}>
        <article className="p-3.75 flex space-x-5 border border-gray-40">
          <div className='flex-none w-66.25'>
            <img
              {...UiMediaUtils.getImgPropsFromMediaImage(item.ui_cpt_field_media)}
              className="border border-gray-40 w-full"
            />
          </div>
          <_TextFields className="space-y-2.5" item={item} />
        </article>
      </li>
    ))}
  </ul>
);

export default _Display1Col;
