import {useUserRegisterProvider} from "@/src/ui/providers/user-register.provider";
import ProcessSteps from "@/src/ui/components/generic/ProcessSteps";
import UserRegisterUtils from "@/src/core/app/utils/user-register";
import {UserRegisterStep} from "@/src/core/app/domain/models/user-register/user-register-step";
import UserRegisterStep1 from "@/src/ui/components/routes/Route_UserRegister/steps/UserRegisterStep1";
import UserRegisterStep2 from "@/src/ui/components/routes/Route_UserRegister/steps/UserRegisterStep2";
import UserRegisterStep3 from "@/src/ui/components/routes/Route_UserRegister/steps/UserRegisterStep3";
import UserRegisterForm from "src/ui/components/forms/UserRegisterForm";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {UserRegisterRouteProps} from "@/src/core/app/domain/route/@types/UserRegisterRouteProps";
import {FormikProps} from "formik/dist/types";
import {UserRegisterFormValues} from "@/src/core/app/domain/forms/UserRegisterFormValues";
import {UserRegisterStepComponent} from "@/src/ui/components/routes/Route_UserRegister/steps/UserRegisterStep";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import UserLogin from "@/src/core/app/utils/user";
import I18nUtils from "@/src/ui/utils/i18n";
import {useTranslation} from "next-i18next";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutCommon} from "@/src/ui/@types/layout/UI_LayoutCommon";

const Step = () => {
  const {t} = useTranslation();
  const routeData = useRouteData<UserRegisterRouteProps>();
  const step = useUserRegisterProvider(state => state.step);
  let stepIndex = 0;
  let _Component : UserRegisterStepComponent | null = null;

  if (step ===  UserRegisterStep.STEP_1) {
    _Component = UserRegisterStep1;
    stepIndex = 1;
  }

  if (step ===  UserRegisterStep.STEP_2) {
    _Component = UserRegisterStep2;
    stepIndex = 2;
  }

  if (step ===  UserRegisterStep.STEP_3) {
    _Component = UserRegisterStep3;
    stepIndex = 3;
  }

  if (_Component === null) {
    return null;
  }

  return (
    <>
      <ProcessSteps
        paddingY="pt-5 sm:pt-7.5"
        step={stepIndex}
        steps={I18nUtils.translateLabels(UserRegisterUtils.getSteps(), t)}
      />
      <UserRegisterForm
        countries={routeData.data.countries}
        // newsletterCategories={routeData.data.newsletterCategories}
      >
        {(formik: FormikProps<UserRegisterFormValues>) => (
          // @ts-ignore
          <_Component formik={formik} />
        )}
      </UserRegisterForm>
    </>
  );
};

const Route_UserRegister = (): JSX.Element | null => {
  const layoutData = useLayoutData<UI_LayoutCommon>();
  const userProvider = useUserProvider(state => state);

  if (!userProvider.isInitiated) {
    return null;
  }

  if (userProvider.userData) {
    UserLogin.goToUserHome(layoutData.sitePageUrls);
    return null;
  }

  return (
    <useUserRegisterProvider.State>
      <Step />
    </useUserRegisterProvider.State>
  );
}

export default Route_UserRegister
