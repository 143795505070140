import Cookies from 'js-cookie'

import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import CalendarUtils from "@/src/ui/utils/calendar";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import {SearchParamsValidation} from "@/src/core/app/domain/@types/SearchParamsValidation";
import {SearchParamsError} from "@/src/core/app/domain/@types/SearchParamsError";
import {SearchParamsLocationType} from "@/src/core/app/domain/@types/SearchParamsLocationType";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {T_HotelTree} from "@/src/core/app/domain/models/hotel-tree/T_HotelTree";
import BrowserUtils from "@/src/ui/utils/browser";
import {CookieSearchParams} from "@/src/core/app/domain/@types/CookieSearchParams";
import {TFunction} from "react-i18next";
import {AppInitiatedEvent} from "@/src/ui/@types/event/AppInitiatedEvent";
import {SearchParamsContext} from "@/src/core/app/domain/@types/SearchParamsContext";
import {RouteType} from "@/src/core/app/domain/@types/Route";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import searchParams from "@/src/core/app/formatter/search-params";

export const KID_MAX_AGE = 13;
export const SEARCH_PARAMS_COOKIE_NAME = 'he_search_params';
export const SEARCH_PARAMS_COOKIE_DURATION = 365;
export const SEARCH_PARAMS_COOKIE_CURRENT_VERSION = '1.0.0';

const SearchParamsUtils = {
  isEmpty: (searchParams: SearchParams | null) : boolean => {
    if (!searchParams) {
      return true;
    }
    return SearchParamsUtils.isDateRangeEmpty(searchParams.dateRange);
  },
  getContext: (event: AppInitiatedEvent) : SearchParamsContext => {
    const hotelTree = event.detail.hotelTree as T_HotelTree;
    let currentHotelCode = null;

    if (event.detail.route.type == RouteType.HOTEL_SECTION) {
      currentHotelCode = (event.detail.routeData.data as T_HotelSection).ui_hotel_common_info.code;
    }
    else if (event.detail.route.type == RouteType.OFFER_DETAIL) {
      currentHotelCode = (event.detail.routeData.data as T_OfferDetail).cpt_ui_hotel_code;
    }

    return {
      hotelTree,
      currentHotelCode
    };
  },
  getDefaultValue: () : SearchParams => {
    return {
      location: null,
      rooms: [
        SearchParamsUtils.getDefaultRoomOccupation()
      ],
      dateRange: {},
      promocode: '',
    }
  },
  removeCookie: () => {
    Cookies.remove(SEARCH_PARAMS_COOKIE_NAME);
  },
  loadFromCookie: () : SearchParams | null => {
    const cookieValue = Cookies.get(SEARCH_PARAMS_COOKIE_NAME);
    if (cookieValue === undefined) {
      return null;
    }

    const cookieData = JSON.parse(cookieValue) as CookieSearchParams;
    return cookieData.data;
  },
  load: (context: SearchParamsContext) : SearchParams | null => {
    const dataFromCookie = SearchParamsUtils.loadFromCookie();

    if (dataFromCookie !== null) {
      return SearchParamsUtils.adaptByContext(
        dataFromCookie,
        context
      );
    }

    const searchParams = SearchParamsUtils.getDefaultValue();
    return SearchParamsUtils.adaptByContext(
      searchParams,
      context
    );
  },

  adaptByContext: (
    searchParams: SearchParams,
    context: SearchParamsContext
  ) : SearchParams => {
    const ret = {
      ...searchParams
    };
    if (searchParams.dateRange.start && CalendarUtils.isBeforeToday(searchParams.dateRange.start)) {
      ret.dateRange.start = undefined;
      ret.dateRange.end = undefined;
    }
    if (context.currentHotelCode) {
      ret.location = {
        type: SearchParamsLocationType.HOTEL,
        id: context.currentHotelCode,
      };
      ret.rooms = SearchParamsUtils.adaptRoomsByHotelAndHotelTree(ret.rooms, context.currentHotelCode, context.hotelTree);
    }
    return ret;
  },

  adaptRoomsByHotelAndHotelTree: (
    rooms: SearchParamsOccupationRoom[],
    hotelCode: string,
    hotelTree: T_HotelTree
  ) : SearchParamsOccupationRoom[] => {
    const hotel = HotelTreeUtils.getHotel(hotelCode, hotelTree);
    const kidsMaxAge = hotel.kids_max_age;

    return rooms.map(room => {
      // remove kids if kids_max_age == 0
      if (kidsMaxAge === 0) {
        return {
          adults: room.adults,
          kids: 0,
          kid_ages: [],
        }
      }

      // adapt ages to hotel kids_max_age
      const kid_ages : number[] = [];
      room.kid_ages.forEach((age, index) => {
        const kidAge = (age > kidsMaxAge) ? kidsMaxAge : age;
        kid_ages.push(kidAge);
      })
      return {
        adults: room.adults,
        kids: kid_ages.length,
        kid_ages,
      };
    });
  },

  validate: (searchParams: SearchParams) : SearchParamsValidation => {
    const errors : SearchParamsError[] = [];

    if (searchParams.location === null) {
      errors.push(SearchParamsError.LOCATION_EMPTY);
    }
    if (SearchParamsUtils.isDateRangeEmpty(searchParams.dateRange)) {
      errors.push(SearchParamsError.DATE_RANGE_EMPTY);
    }

    return {
      hasErrors: errors.length !== 0,
      errors,
    }
  },
  submit: (
    searchParams: SearchParams,
    context: SearchParamsContext,
    roomCode?: string
  ) : boolean => {
    SearchParamsUtils.save(searchParams);
    if (searchParams.location?.type == SearchParamsLocationType.HOTEL) {
      const hotel = HotelTreeUtils.getHotel(searchParams.location.id, context.hotelTree);
      if (!hotel.hotel_section_rooms_url) {
        return false;
      }
      const url = BrowserUtils.buildUrl(hotel.hotel_section_rooms_url);
      if (roomCode) {
        url.searchParams.set('roomCode', roomCode);
      }
      BrowserUtils.goTo(url.toString())
      return true;
    }
    if (searchParams.location?.type == SearchParamsLocationType.DESTINATION) {
      const destination = HotelTreeUtils.getDestination(searchParams.location.id, context.hotelTree);
      BrowserUtils.goTo(destination.url);
      return true;
    }
    return false;
  },
  save: (searchParams: SearchParams) => {
    const cookieData : CookieSearchParams = {
      data: {
        location: searchParams.location,
        rooms: searchParams.rooms,
        dateRange: searchParams.dateRange,
        promocode: searchParams.promocode,
      },
      version: SEARCH_PARAMS_COOKIE_CURRENT_VERSION,
    };
    Cookies.set(SEARCH_PARAMS_COOKIE_NAME, JSON.stringify(cookieData), { expires: SEARCH_PARAMS_COOKIE_DURATION });
  },
  hasError: (searchParamsValidation: SearchParamsValidation, error: SearchParamsError) : boolean => {
    return searchParamsValidation.errors.indexOf(error) !== -1;
  },
  getDefaultRoomOccupation: () : SearchParamsOccupationRoom => ({
    adults: 2,
    kids: 0,
    kid_ages: [],
  }),
  getKidAgeOptions: (t: TFunction, kidsMaxAge: number) : FormInputSelectOption[] => {
    const items : FormInputSelectOption[] = [];
    for (let i = 0; i <= kidsMaxAge; i++) {
      items.push({
        value: i.toString(),
        label: i === 1 ? t('search_params_utils.kid_age.label_singular') : t('search_params_utils.kid_age.label_plural', {
          count: i,
        }),
      })
    }
    return items;
  },
  getRoomCount: (rooms: SearchParamsOccupationRoom[]) => rooms.length,

  getTotalOccupation: (rooms: SearchParamsOccupationRoom[]) => rooms.reduce((count, room) => {
    return count + room.adults + room.kids;
  }, 0),

  getTotalAdults: (rooms: SearchParamsOccupationRoom[]) => rooms.reduce((count, room) => {
    return count + room.adults;
  }, 0),

  getTotalKids: (rooms: SearchParamsOccupationRoom[]) => rooms.reduce((count, room) => {
    return count + room.kids;
  }, 0),

  isDateRangeEmpty: (dateRange: SearchParamsDateRange) : boolean => {
    return !dateRange.start || !dateRange.end;
  },

  getDateRangeNights: (dateRange: SearchParamsDateRange) : number => {
    if (SearchParamsUtils.isDateRangeEmpty(dateRange)) {
      return -1;
    }

    return CalendarUtils.getDiffDays(dateRange.start as string, dateRange.end as string);
  },
  getDateRangeForFixedNightsCount: (dateRange: SearchParamsDateRange, start: string, nightsCount: number) : SearchParamsDateRange => {
    let end = CalendarUtils.addDays(start, nightsCount);
    if (!CalendarUtils.isDateInRange(end, dateRange)) {
      return {
        start: CalendarUtils.subDays(dateRange.end as string, nightsCount),
        end: dateRange.end,
      }
    }
    return {
      start,
      end
    };
  },
}

export default SearchParamsUtils;
