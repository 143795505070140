import {Route, RouteType} from "@/src/core/app/domain/@types/Route";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import SentryUtils from "@/src/common/utils/sentry";
import {Breadcrumb} from "@/src/core/app/domain/@types/Breadcrumb";
import {ElbaMediaGolfHierarchyItem} from "@/src/core/app/domain/elba-media/@types/ElbaMediaGolfHierarchyItem";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {TFunction} from "react-i18next";
import breadcrumb from "@/src/core/app/data/data-loader/utils/breadcrumb";
import App from "next/app";

const UiElbaMediaUtils = {
  getRedirectionUrl: (appInfo: AppInfo, route: Route, userData: UserData) : null | string => {
    if (!UiElbaMediaUtils.hasMediaExclusionRole(userData)) {
      return null;
    }

    if (!UiElbaMediaUtils.hasMediaRole(userData) && !UiElbaMediaUtils.hasMediaGolfRole(userData)) {
      SentryUtils.captureMessage('User has media_exclusion role but not media or media_golf', {
        extra: {userData}
      });
      return null;
    }

    if (UiElbaMediaUtils.hasMediaRole(userData)) {
      if (route.type !== RouteType.ELBA_MEDIA) {
        return UiElbaMediaUtils.getMediaRoot(appInfo);
      }
      return null;
    }

    if (route.type !== RouteType.ELBA_MEDIA_GOLF) {
      return UiElbaMediaUtils.getMediaGolfRoot(appInfo);
    }
    return null;
  },
  getMediaRoot: (appInfo: AppInfo) => {
    return `/${appInfo.langcode}/media`;
  },
  getMediaGolfRoot: (appInfo: AppInfo) => {
    return `/${appInfo.langcode}/media-golf`;
  },
  getLoginRedirectionUrl: (appInfo: AppInfo, userData: UserData) => {
    if (UiElbaMediaUtils.hasMediaRole(userData)) {
      return UiElbaMediaUtils.getMediaRoot(appInfo);
    }
    if (UiElbaMediaUtils.hasMediaGolfRole(userData)) {
      return UiElbaMediaUtils.getMediaGolfRoot(appInfo);
    }
    return null;
  },
  hasMediaRole: (userData: UserData) => {
    return userData.elbaMediaRoles.indexOf('media') !== -1;
  },
  hasMediaGolfRole: (userData: UserData) => {
    return userData.elbaMediaRoles.indexOf('media_golf') !== -1;
  },
  hasMediaExclusionRole: (userData: UserData) => {
    return userData.elbaMediaRoles.indexOf('media_exclusion') !== -1;
  },

  getFolderUrl: (route: Route, slug: string) => {
    return `${route.path}/${slug.trim()}`;
  },

  getIcon(type: string) {
    const map = {
      'application/zip': 'zip.png',
      'application/pdf': 'pdf.png',
    };
    if (Object.keys(map).indexOf(type) === -1) {
      SentryUtils.captureMessage('Missing icon for file type: ' + type);
      return '/assets/cms/icons/file/doc.png';
    }
    // @ts-ignore
    return '/assets/cms/icons/file/' + map[type];
  },

  getGolfBreadcrumb: (t: TFunction, route: Route, appInfo: AppInfo, hierarchy: ElbaMediaGolfHierarchyItem[]) => {
    const breadcrumb : Breadcrumb = [];

    let currentFolder = `/${appInfo.langcode}/media-golf`;

    breadcrumb.push({
      title: t('global.generic.home_label'),
      url: `/${appInfo.langcode}`,
    })

    breadcrumb.push({
      title: t('Route_ElbaMediaGolf._Index.page_title'),
      url: currentFolder,
    })

    hierarchy.forEach((item, index) => {
      currentFolder = `${currentFolder}/${item.slug}`;

      breadcrumb.push({
        title: item.title,
        url: currentFolder,
      });
    });

    if (breadcrumb.length === 0) {
      return undefined;
    }

    delete breadcrumb[breadcrumb.length - 1].url;
    return breadcrumb;
  },

  getGolfParentFolder: (breadcrumb?: Breadcrumb) => {
    if (!breadcrumb) {
      return null;
    }
    if (breadcrumb.length <= 2) {
      return null;
    }
    return breadcrumb[breadcrumb.length - 2].url;
  },
};

export default UiElbaMediaUtils;
