import Button from "@/src/ui/components/generic/Button";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import NarrowFormBox from "@/src/ui/components/generic/NarrowFormBox";
import FormInputCheckboxGroup from "src/ui/components/formik/FormInputCheckboxGroup";
import {UserRegisterStepProps} from "@/src/ui/components/routes/Route_UserRegister/steps/UserRegisterStep";
import UserUtils from "@/src/core/app/utils/user";
import _AlreadyRegistered from "@/src/ui/components/routes/Route_UserRegister/steps/_AlreadyRegistered";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";

const UserRegisterStep3 = (props: UserRegisterStepProps) => {
  const { t } = useTranslation();
  return (
    <NarrowFormBox title={t('Route_UserRegister.UserRegisterStep3.title')}>
      <FormInputCheckboxGroup
        label={t('Route_UserRegister.UserRegisterStep3.trip_style_label')}
        name="tripStyle"
        options={I18nUtils.translateFormInputSelectOptions(
          UserUtils.getTripStyleOptions(), t
        )}
      />
      {/*<FormInputCheckboxGroup*/}
      {/*  label="Intereses"*/}
      {/*  name='newsletterCategories'*/}
      {/*  options={NewsletterCategoryUtils.getSelectOptions(routeData.data.newsletterCategories)}*/}
      {/*/>*/}
      <div>
        <Button
          bold
          text={t('Route_UserRegister.UserRegisterStep3.submit_button_label')}
          onClick={props.formik.handleSubmit}
          isActive={!props.formik.isSubmitting}
        />
      </div>
      <VerisignImg />
      <_AlreadyRegistered />
    </NarrowFormBox>
  );
}

export default UserRegisterStep3;
