import ReCAPTCHA from "react-google-recaptcha";
import {useField} from "formik";
import {LegacyRef, useEffect, useRef} from "react";

interface _Props {
  name: string
}

const validate = (value: any) => {
  if (!value) {
    return 'Esta verificación es obligatoria';
  }
}

const FormRecaptcha = (props: _Props): JSX.Element => {
  const fieldProps = {
    name: props.name,
    validate,
  };

  const ref = useRef<ReCAPTCHA>();
  const [field, meta, helpers] = useField(fieldProps);

  useEffect(() => {
    if (!field.value) {
      ref.current?.reset();
    }
  }, [field.value]);

  const onChange = (value: string | null) => {
    helpers.setValue(value);
    helpers.setError(undefined);
  };

  return (
    <div className='space-y-1'>
      <ReCAPTCHA
        ref={ref as LegacyRef<ReCAPTCHA>}
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
        onChange={onChange}
      />
      {meta.error && <p className="text-xs text-red">{meta.error}</p>}
    </div>
  )
};


export default FormRecaptcha;
