import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import {useTranslation} from "next-i18next";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {SearchFormCalendarWidget} from "@/src/ui/components/search-form/SearchFormCalendarWidget";
import {useDevice} from "@/src/ui/hooks/useDevice";
import OfferSearchUtils from "@/src/core/app/utils/offer-search";
import {useRoute} from "@/src/ui/hooks/useRoute";

const ModalOfferSearchDatesFilterDesktop = (): JSX.Element => {
  const route = useRoute();
  const device = useDevice();
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const dateRange = OfferSearchUtils.getRangeFromRoute(route);

  const onSubmit = (range: SearchParamsDateRange) => {
    modalState.closeModal();
    OfferSearchUtils.onSubmitDatesFilter(range);
  };

  return (
    <ModalLayout
      title={t('OfferSearch._Index.show_date_filter_popup_label')}
      onCloseModal={() => modalState.closeModal()}
    >
      <SearchFormCalendarWidget
        device={device}
        onSubmit={onSubmit}
        startDate={dateRange.start}
        endDate={dateRange.end}
      />
    </ModalLayout>
  );
}

export default ModalOfferSearchDatesFilterDesktop;
