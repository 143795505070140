import type { ReactNode } from "react";

import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import BgImg from "@/src/ui/components/generic/BgImg";
import cn from "classnames";
import {className} from "postcss-selector-parser";

interface _Props {
  children: ReactNode
  mediaImage?: T_MediaImage
  imgRaw?: any
  className?: string
}

const Card = ({ children, mediaImage, imgRaw, className }: _Props): JSX.Element => (
  <article className="h-full w-full relative">
    {mediaImage && (<MediaImageBgImg mediaImage={mediaImage} />)}
    {imgRaw && (<BgImg imgRaw={imgRaw} />)}
    <div
      className={cn(
        "absolute inset-0 px-5 flex flex-col justify-center items-center text-white text-center hover:bg-brand-2-80 hover-show-parent transition",
        className
      )}
    >
      {children}
    </div>
  </article>
);

export default Card;
