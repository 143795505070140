import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {CmsApiService} from "@/src/core/app/data/services/cms_api_service";
import {CMS_NodeExperience} from "@/src/core/app/domain/cms/@types/node/CMS_NodeExperience";
import {T_Node} from "@/src/core/app/domain/models/node/T_Node";
import UrlUtils from "@/src/core/app/utils/url";
import DataLoaderContext from "@/src/core/app/data/data-loader/context/DataLoaderContext";

const ExperienceUtils = {
  getLinkUrl: (node: T_Node) : string => {
    if (node.path?.alias) {
      return UrlUtils.getCmsLinkUrlFromPath(node.path);
    }
    return `/${node.langcode}/node/${node.drupal_internal__nid}`;
  },

  getList: async (
    context: DataLoaderContext,
    pageSize: number,
    langcode: string
  ) : Promise<CMS_NodeExperience[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const data = await cms.getNodeList(context, 'experience', pageSize, langcode, 'title');
    return data.data as CMS_NodeExperience[]
  },
};

export default ExperienceUtils;
