import {T_ParagraphHotelsOffersPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelsOffersPromoted";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import TitleGroupDesktop from "@/src/ui/components/desktop/TitleGroup";
import Section from "@/src/ui/components/generic/Section/Section";
import OfferTeaserList from "@/src/ui/components/offer/OfferTeaserList";
import {OfferTeaserStyle} from "@/src/ui/@types/offer/OfferTeaserStyle";


const BlockHotelsOffersPromoted = (props: T_ParagraphHotelsOffersPromoted) => {
  if (props.field_offers.length === 0) {
    return null;
  }
  return (
    <Section paddingY="py-7.5" backgroundColor={props.field_background_color}>
      <MaxWidthWrapper>
        <div className="pb-7.5">
          <TitleGroupDesktop
            titleWithTag={props.field_title_with_tag}
            description={props.field_body ? props.field_body.processed : undefined}
          />
        </div>
        <OfferTeaserList
          items={props.field_offers}
          style={OfferTeaserStyle.CARD}
        />
      </MaxWidthWrapper>
    </Section>
  )
}
export default BlockHotelsOffersPromoted;
