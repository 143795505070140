import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import HotelRoomTeaserLoadingMobile from "@/src/ui/components/hotel-room/mobile/HotelRoomTeaserLoading";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const _CheckoutStatusLoading = (props: T_ParagraphHotelRoomList) => {
  const rooms = Array(3).fill(0);
  return (
    <Section>
      <MaxWidthWrapper>
        <TitleWithTag
          field={props.field_title_with_tag}
          className="title-2-mobile pt-2.5 pb-7.5"
        />
        <ul className="rooms space-y-10">
          {rooms.map((room, index) => (
            <li key={index}>
              <HotelRoomTeaserLoadingMobile />
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  )
}

export default _CheckoutStatusLoading;
