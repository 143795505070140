import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";

const UserTmsAssignUtils = {
  assign: async (clientId: string) => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    if (authHeaders === null) {
      throw new Error('You cannot assign tms if you are not logged in');
    }

    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.userTmsAssign(clientId, authHeaders);
  }
};

export default UserTmsAssignUtils;
