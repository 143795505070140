import React from "react";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import HotelRoomFeaturesSummary from "@/src/ui/components/hotel-room/generic/HotelRoomFeaturesSummary";
import _Features from "@/src/ui/components/portals/desktop/ModalHotelRoom/_Features";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

const _Summary = (props: T_HotelRoomBasic): JSX.Element => (
  <div className="px-5 flex-none">
    <MaxWidthWrapper>
      <div className="pb-6 space-y-5">
        <h2 className="title-4 font-calistoga">{props.title}</h2>
        <HotelRoomFeaturesSummary
          room={props}
          className='text-sm space-x-7.5'
          itemClassName='space-x-3'
        />
        <WrapperDiv
          size="small"
          className="pt-1 text-brand-2-90 max-w-screen-md"
          dangerouslySetInnerHTML={{__html: props.field_body.processed}}
        />
        <_Features hotelRoom={props} />
      </div>
    </MaxWidthWrapper>
  </div>
);

export default _Summary;
