import UserTmsAssignForm from "@/src/ui/components/forms/UserTmsAssignForm";
import {T_ParagraphUserTmsAssignForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserTmsAssignForm";


const BlockUserTmsAssignForm = (props: T_ParagraphUserTmsAssignForm) => {
  return (
    <UserTmsAssignForm paragraph={props} />
  );
}
export default BlockUserTmsAssignForm;
