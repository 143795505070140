import Button from "@/src/ui/components/generic/Button";
import {useSearchFormOccupationProvider} from "@/src/ui/providers/search-form-occupation.provider";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useTranslation} from "next-i18next";

interface _FooterProps {
  onSubmit: (rooms: SearchParamsOccupationRoom[]) => void
}

export const _Footer = (props: _FooterProps) => {
  const { t } = useTranslation();
  const searchFormOccupation = useSearchFormOccupationProvider(state => state);
  const onSubmit = () => {
    props.onSubmit(searchFormOccupation.rooms);
  };

  return (
    <>
      <div className="flex-none py-2.5 px-5 border-t bg-gray-10 text-brand-2 text-sm">
        <p>{SearchParamsFormatter.getOccupationDetailedSummary(searchFormOccupation.rooms, t)}</p>
      </div>
      <div className="flex-none flex justify-between items-center p-5 border-t border-gray-40">
        <button
          className="text-brand-2 text-sm underline font-bold hover:text-brand-2-40"
          onClick={() => searchFormOccupation.clearRooms()}
        >
          {t('ModalSearchFormStepOccupation._Popup.clear_button_label')}
        </button>
        <Button text={t('ModalSearchFormStepOccupation._Popup.submit_button_label')} bold style="secondary" onClick={onSubmit} />
      </div>
    </>
  )
}