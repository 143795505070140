import {T_NodeBlogTeaser} from "@/src/core/app/domain/models/node/T_NodeBlogTeaser";
import NodeBlogTeaserDesktop, {NodeBlogTeaser_Style} from "@/src/ui/components/blog/NodeBlogTeaser/desktop";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

interface _Props {
  titleWithTag?: CMS_FieldTextWithTag
  items: T_NodeBlogTeaser[]
  style?: NodeBlogTeaser_Style
}

const NodeBlogTeaserListDesktop = (props: _Props) => (
  <div>
    <TitleWithTag
      className="title-4 font-calistoga"
      field={props.titleWithTag}
    />
    <ul className="pt-10 grid grid-cols-3 gap-x-5 gap-y-10">
      {props.items.map((item) => (
        <li className="flex items-stretch" key={item.id}>
          <NodeBlogTeaserDesktop
            blogPost={item}
            style={props.style}
          />
        </li>
      ))}
    </ul>
  </div>
);

export default NodeBlogTeaserListDesktop;
