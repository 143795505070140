import { ReactNode } from "react";
import cn from "classnames";

interface BoxProps {
  children: ReactNode;
  padding?: string;
}

const Box = ({ children, padding = "p-3.75" }: BoxProps): JSX.Element => (
  <article className={cn("border border-gray-40 rounded-sm", padding)}>
    {children}
  </article>
);

export default Box;
