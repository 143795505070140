import { CheckoutRatesHotelRoomRateGroupConfiguration_Style } from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupConfiguration_Style";
import { CheckoutRateStyleConfig } from "@/src/core/app/domain/models/checkout/CheckoutRateStyleConfig";



export const CheckoutRateStyleConfigMap : Record<CheckoutRatesHotelRoomRateGroupConfiguration_Style, CheckoutRateStyleConfig> = {
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.BLACK_FRIDAY]: {
    icon: 'black-friday.svg',
    textColor: '#ffffff',
    backgroundColor: '#000000',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.CYBER_MONDAY]: {
    icon: 'cyber-monday.svg',
    textColor: '#ffffff',
    backgroundColor: '#12A9E2',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.BLUE_MONDAY]: {
    icon: 'blue-monday.svg',
    textColor: '#ffffff',
    backgroundColor: '#12A9E2',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.LOVE]: {
    icon: 'love.svg',
    textColor: '#ffffff',
    backgroundColor: '#E64C3C',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.CHRISTMAS]: {
    icon: 'christmas.svg',
    textColor: '#ffffff',
    backgroundColor: '#E64C3C',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.NEW_YEARS_EVE]: {
    icon: 'new-years-eve.svg',
    textColor: '#ffffff',
    backgroundColor: '#D4AF37',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.RELAX]: {
    icon: 'relax.svg',
    textColor: '#ffffff',
    backgroundColor: '#04CAB2',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.HALLOWEEN]: {
    icon: 'halloween.svg',
    textColor: '#ffffff',
    backgroundColor: '#F1901E',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.GOLF]: {
    icon: 'golf.svg',
    textColor: '#ffffff',
    backgroundColor: '#8ED15A',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.GETAWAY]: {
    icon: 'getaway.svg',
    textColor: '#ffffff',
    backgroundColor: '#12A9E2',
  },
  [CheckoutRatesHotelRoomRateGroupConfiguration_Style.SUMMER]: {
    icon: 'summer.svg',
    textColor: '#ffffff',
    backgroundColor: '#12A9E2',
  },
};
