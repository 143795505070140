import type { ReactNode } from "react";
import cn from "classnames";

export interface FormInputWrapperProps {
  label?: string
  className?: string
  error?: string | boolean
  height?: number
  children: ReactNode
  relativeChildren?: ReactNode
  footer?: ReactNode
}

const FormInputWrapper = (props: FormInputWrapperProps): JSX.Element => {
  const {
    error,
    label,
    height = 10,
    children,
    relativeChildren,
    footer,
    className,
  } = props;
  return (
    <div className={className || "flex-1"}>
      <div className="space-y-1.5">
        {label && <p className="text-medium-bold">{label}</p>}
        <div className='relative'>
          {relativeChildren}
          <div
            className={cn(
              `h-${height} flex items-center overflow-hidden border rounded-3px`,
              error === true || error === undefined ? "border-gray-40" : "border-red",
              "px-2.5"
            )}
          >
            {children}
          </div>
        </div>
        {footer}
        {error && <p className="text-xs text-red">{error}</p>}
      </div>
    </div>
  );
}

export default FormInputWrapper;
