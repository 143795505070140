import {ChangeEvent} from "react";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import {useTranslation} from "next-i18next";

interface _Props {
  currentOption: FormInputSelectOption | null
  options: FormInputSelectOption[]
  onChange: (option: FormInputSelectOption | null) => void
  placeholder: string
}

const _Filter = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === '') {
      props.onChange(null);
      return;
    }

    const option = props.options.find(item => item.value === value) as FormInputSelectOption;
    props.onChange(option);
  };

  return (

    <div className="flex items-baseline">
      <strong>{t('BlockHotelListFiltrable._Filter.title')}&nbsp;</strong>
      <div className="relative flex space-x-1 items-center hover:underline">
        <select
          className='w-full h-full absolute opacity-0 z-50 cursor-pointer'
          value={props.currentOption ? props.currentOption.value : ''}
          onChange={onChange}
        >
          <option value=''>
            {props.placeholder}
          </option>
          {props.options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <div className="cursor-pointer flex items-center space-x-1">
          <button>{props.currentOption ? props.currentOption.label : props.placeholder}</button>
          <img src="/assets/icons/triangle-down-brand-2.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default _Filter;
