import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import TitleGroupDesktop from "src/ui/components/desktop/TitleGroup";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_ParagraphBlogList} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogList";
import {T_NodeBlogTeaser} from "@/src/core/app/domain/models/node/T_NodeBlogTeaser";
import NodeBlogTeaserDesktop, {NodeBlogTeaser_Style} from "@/src/ui/components/blog/NodeBlogTeaser/desktop";

const renderList = (posts: T_NodeBlogTeaser[]) => (
  <div className='pb-5'>
    <ul className="flex space-x-5">
      {posts.map((blogPost, index) => (
        <li key={index} className='flex flex-1'>
          <NodeBlogTeaserDesktop
            blogPost={blogPost}
            style={NodeBlogTeaser_Style.HOME}
          />
        </li>
      ))}
    </ul>
  </div>
)

const _Last = (props: T_ParagraphBlogList) => (
  <Section paddingY="py-7.5" backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      <div className="pb-7.5">
        <TitleGroupDesktop
          titleWithTag={props.field_title_with_tag}
          description={props.field_body?.processed}
        />
      </div>
      {renderList(props.blogPosts)}
      <div className="pt-1.5">
        { props.field_one_link && (
          <ArrowLink href={props.field_one_link.url}>{props.field_one_link.title}</ArrowLink>
        )}
      </div>
    </MaxWidthWrapper>
  </Section>
)

export default _Last;