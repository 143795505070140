import {useContextHeaderDesktopContext} from "@/src/ui/context/HeaderDesktopContext";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParamsError} from "@/src/core/app/domain/@types/SearchParamsError";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalDesktopKey from "@/src/ui/@types/modal/ModalDesktopKey";
import Anchor from "@/src/ui/components/generic/Anchor";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import ButtonLocationSummary from "@/src/ui/components/search-form/ButtonLocationSummary";
import ButtonDateRangeSummary from "@/src/ui/components/search-form/ButtonDateRangeSummary";
import ButtonOccupationSummary from "@/src/ui/components/search-form/ButtonOccupationSummary";
import InputText from "@/src/ui/components/form/InputText";
import Button from "@/src/ui/components/generic/Button";
import {useTranslation} from "next-i18next";
import SearchFormUtils from "@/src/core/app/utils/search-form";

const HeaderDesktopForm = (): JSX.Element | null => {
  const { t } = useTranslation();
  const searchForm = useSearchFormProvider(state => state);
  const modalState = useModalProvider(state => state);
  const promocode = useSearchFormProvider(state => state.promocode);
  const context = useContextHeaderDesktopContext();
  const layoutData = useLayoutData<UI_LayoutGeneric>();

  if (!context?.isVisible) {
    return null;
  }
  return (
    <div
      className="relative h-14 pt-2.5 pb-1.5 bg-white border-t border-b border-gray-30"
    >
      <Section paddingY={""}>
        <MaxWidthWrapper>
          <div className="flex items-stretch justify-between">
            <ButtonLocationSummary
              className="w-55 flex items-stretch cursor-pointer"
              color="gray"
              hasError={SearchParamsUtils.hasError(searchForm.validation, SearchParamsError.LOCATION_EMPTY)}
              onClick={() => modalState.toggleModal(ModalDesktopKey.SEARCH_FORM_STEP_LOCATION)}
            />
            <ButtonDateRangeSummary
              color="gray"
              size="small"
              className="w-60 flex items-stretch cursor-pointer"
              hasError={SearchParamsUtils.hasError(searchForm.validation, SearchParamsError.DATE_RANGE_EMPTY)}
              onClick={() => modalState.toggleModal(ModalDesktopKey.SEARCH_FORM_STEP_DATES)}
            />
            <ButtonOccupationSummary
              className="w-50 flex items-stretch cursor-pointer"
              size="small"
              color="gray"
              onClick={() => modalState.toggleModal(ModalDesktopKey.SEARCH_FORM_STEP_OCCUPATION)}
            />
            <div className="w-44 flex items-stretch">
              <div className="w-full">
                <InputText
                  writable
                  color="gray"
                  icon="/assets/icons/ticket.svg"
                  placeholder={t('SearchForm._Index.promocode_placeholder')}
                  value={promocode}
                  onChange={(value) => searchForm.setPromocode(value)}
                />
              </div>
            </div>
            <Button
              text={t('SearchForm._Index.submit_button_label')}
              className="w-25 h-9"
              customPaddingClass='py-2'
              onClick={() => SearchFormUtils.submit(searchForm, modalState)}
            />
            <div className="flex flex-col text-brand-1 text-sm">
              {layoutData.menuSearchForm.items.map(item => (
                <Anchor className="text-left" href={item.url} key={item.id}>
                  + {item.title}
                </Anchor>
              ))}
            </div>
          </div>
        </MaxWidthWrapper>
      </Section>
    </div>
  );
}

export default HeaderDesktopForm;
