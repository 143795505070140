import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_HotelReference} from "@/src/core/app/domain/cms/@types/hotel/CMS_HotelReference";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

export enum CMS_ParagraphHotelList_Display {
  LIST = 'list',
  GRID = 'grid',
}
export enum CMS_ParagraphHotelList_LinkSection {
  MAIN = 'main',
  ROOM = 'room',
  OFFER = 'offer',
  EVENT = 'event',
}

export interface CMS_ParagraphHotelList extends CMS_ParagraphEntity {
  field_background_color?: string
  field_title_with_tag?: CMS_FieldTextWithTag
  field_hotels: CMS_HotelReference[]
  field_hotel_list_display: CMS_ParagraphHotelList_Display
  field_hotel_list_link_section: CMS_ParagraphHotelList_LinkSection
}