import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodeResidentDetail} from "@/src/core/app/domain/models/node/T_NodeResidentDetail";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import BlockListComponent from "@/src/ui/components/generic/BlockListComponent";

const Route_ResidentDetail = (props: RouteProps<T_NodeResidentDetail>) : JSX.Element => (
  <>
    <PageHeader header={props.routeData.data.page_header} />
    <BlockListComponent blocks={props.routeData.data.field_blocks} />
  </>
)

export default Route_ResidentDetail;
