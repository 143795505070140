import {
  ContactGeneralInfoItem,
} from "@/src/core/app/domain/models/paragraph/T_ParagraphContactGeneralInfo";

interface _Props {
  item: ContactGeneralInfoItem
}

const _Item = (props: _Props) => (
  <div className="py-2.5 px-3.75 space-y-3.5">
    <h3 className="text-medium-bold">{props.item.name}</h3>

    <div className="space-y-2.5">
      <p className="flex items-center space-x-2.5">
        <img className="w-4.5 h-auto" src="/assets/icons/phone.svg" alt="Teléfono" />
        <a
          href={"tel:" + props.item.phone}
          className="text-sm text-brand-3-90 underline hover:text-brand-3"
        >
          {props.item.phone}
        </a>
      </p>

      <p className="flex items-center space-x-2.5">
        <img className="w-4.5 h-auto" src="/assets/icons/email.svg" alt="E-mail" />
        <a
          href={"mailto:" + props.item.email}
          className="text-sm text-brand-3-90 underline hover:text-brand-3"
        >
          {props.item.email}
        </a>
      </p>
    </div>
  </div>
);
export default _Item;
