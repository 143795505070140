import OfferGroupTeaserDesktop from "../OfferGroupTeaser/desktop";
import {T_OfferGroupTeaser} from "@/src/core/app/domain/models/offer-group/T_OfferGroupTeaser";
import {T_OfferGroupTeaserDesktop} from "@/src/core/app/domain/models/offer-group/T_OfferGroupTeaserDesktop";

interface Props {
  offers: T_OfferGroupTeaser[]
}

const OfferTeaserListDesktop = ({ offers }: Props): JSX.Element => (
  <div className="relative h-100">
    <ul className="absolute h-full px-5 -inset-x-5 overflow-auto flex items-stretch space-x-5">
      {offers.map((offerGroup, index) => (
        <li key={index} className="w-70 flex-shrink-0">
          <OfferGroupTeaserDesktop offerGroup={offerGroup as T_OfferGroupTeaserDesktop} />
        </li>
      ))}
    </ul>
  </div>
);

export default OfferTeaserListDesktop;
