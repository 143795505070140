import {RouteProps} from "@/src/ui/@types/RouteProps";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import HeaderHotelCheckoutDesktop from "@/src/ui/components/checkout/desktop/HeaderHotelCheckout";
import CheckoutLayout from "@/src/ui/components/checkout/desktop/CheckoutLayout";
import BookingConfirmationSummary from "@/src/ui/components/checkout/desktop/BookingConfirmationSummary";
import BookingConfirmationActions from "@/src/ui/components/checkout/generic/BookingConfirmationActions";

const BookingConfirmationDesktop = (props: RouteProps<BookingConfirmationRouteProps>) => {
  return (
    <>
      <HeaderHotelCheckoutDesktop
        checkoutHotel={props.routeData.data.hotel}
      />
      <div className="pt-5">
        <CheckoutLayout
          leftChild={<BookingConfirmationSummary />}
          rightChild={<BookingConfirmationActions />}
        />
      </div>
    </>
  );
};

export default BookingConfirmationDesktop;
