import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import _Summary from "@/src/ui/components/portals/mobile/ModalLounge/_Summary";
import React from "react";
import FullWidthGalleryMobile from "src/ui/components/mobile/FullwidthGalleryMobile";
import {T_Lounge} from "@/src/core/app/domain/models/lounge/T_Lounge";

const ModalLoungeMobile = () => {
  const modalState = useModalProvider(state => state);
  const lounge = modalState.modalData.lounge as T_Lounge;

  return (
    <ModalLayoutFullscreen onCloseModal={() => modalState.closeModal()}>
      <div className='p-5 pt-15 border-b border-gray-20'>
        <h2 className="title-3-mobile text-center">{lounge.field_title}</h2>
      </div>
      <div className="flex-1 overflow-y-auto">
        <_Summary {...lounge} />
        <FullWidthGalleryMobile items={lounge.ui_cpt_field_medias_mobile} />
      </div>
    </ModalLayoutFullscreen>
  )
};

export default ModalLoungeMobile;