import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import {ModalMultiGalleryData} from "@/src/ui/@types/modal/ModalMultiGalleryData";
import _Header from "@/src/ui/components/portals/desktop/ModalMultiGallery/_Header";
import {useState} from "react";
import _Tabs from "@/src/ui/components/portals/desktop/ModalMultiGallery/_Tabs";
import _Gallery from "@/src/ui/components/portals/desktop/ModalMultiGallery/_Gallery";

const ModalMultiGalleryDesktop = () => {
  const modalState = useModalProvider(state => state);
  const data = modalState.modalData as ModalMultiGalleryData;
  const [currentTabIndex, setCurrentTabIndex] = useState(data.index);

  return (
    <ModalLayoutFullscreen onCloseModal={() => modalState.closeModal()}>
      <_Header titleWithTag={data.multiGallery.field_title_with_tag} />
      <_Tabs
        multiGallery={data.multiGallery}
        currentTabIndex={currentTabIndex}
        onTabClicked={(index: number) => setCurrentTabIndex(index)}
      />
      <_Gallery multiGalleryItem={data.multiGallery.field_multi_gallery_items[currentTabIndex]} />
    </ModalLayoutFullscreen>
  )
};

export default ModalMultiGalleryDesktop;
