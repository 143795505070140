import { ReactNode } from "react";
import cn from "classnames";

interface _Props {
  children: ReactNode
  paddingX?: "px-5" | "px-2.5"
  iconPosition?: "top" | "center"
  level?: 'warning' | 'error'
}

const WarningBanner = ({
  children,
  paddingX = "px-5",
  iconPosition = "center",
  level = "warning",
}: _Props): JSX.Element => (
  <article
    className={cn(
      "flex space-x-2.5 py-2.5 px-5",
      level === 'warning' && 'bg-yellow-notification',
      level === 'error' && 'bg-red-notification',
      paddingX,
      iconPosition === "center" && "items-center"
    )}
  >
    {level === 'warning' && <img className="w-5 flex-none h-auto" src="/assets/icons/info.svg" alt="" />}
    {level === 'error' && <img className="w-5 flex-none h-auto" src="/assets/icons/error.svg" alt="" />}
    <div className="flex-1 text-sm text-brand-2 leading-snug">{children}</div>
  </article>
);

export default WarningBanner;
