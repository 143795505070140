import React from "react";
import {T_Lounge} from "@/src/core/app/domain/models/lounge/T_Lounge";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import LegendBox from "@/src/ui/components/generic/LegendBox";
import Section from "@/src/ui/components/generic/Section/Section";
import AssetCmsIcon from "@/src/ui/components/generic/AssetCmsIcon";
import {useTranslation} from "next-i18next";

interface _Props {
  lounge: T_Lounge
}

const _Features = (props: _Props) => {
  const { t } = useTranslation();
  const lounge = props.lounge;

  if (!lounge.field_disposition_items.length && !lounge.field_information_items.length) {
    return null;
  }

  return (
    <Section paddingY="pb-5 px-0">
      <MaxWidthWrapper>
        <div className="flex space-x-5">
          <div className="flex-none w-95">
            <LegendBox title={t('ModalLounge._Features.info_title')}>
              <div className="grid grid-cols-2 gap-2.5">
                {lounge.field_information_items.map((item, index) => (
                  <div className="flex" key={index}>
                    <span className="font-bold">{item.text_1}:&nbsp;</span>
                    <span>{item.text_2}</span>
                  </div>
                ))}
              </div>
            </LegendBox>
          </div>
          <div className="flex-1">
            <LegendBox title={t('ModalLounge._Features.disposition_title')}>
              <div className="grid grid-cols-2 gap-2.5">
                {lounge.field_disposition_items.map((item, index) => (
                  <div className="flex items-center" key={index}>
                    <AssetCmsIcon
                      className="mr-4 h-4.5"
                      iconKey={item.icon_class}
                    />
                    <span className="font-bold">{item.text_1}:&nbsp;</span>
                    <span>{item.text_2}</span>
                  </div>
                ))}
              </div>
            </LegendBox>
          </div>
        </div>
      </MaxWidthWrapper>
    </Section>
  );
}

export default _Features;
