import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import HotelRoomFeaturesSummary from "@/src/ui/components/hotel-room/generic/HotelRoomFeaturesSummary";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import _Features from "@/src/ui/components/portals/mobile/ModalHotelRoom/_Features";

const _Summary = (props: T_HotelRoomBasic): JSX.Element => (
  <div className="py-8 px-5 space-y-5 text-brand-2">
    <HotelRoomFeaturesSummary
      room={props}
      iconColor='brand-2'
      className="text-sm space-x-7.5"
      itemClassName='space-x-3'
    />
    <WrapperDiv
      size="small"
      className="text-xs text-brand-2-90"
      dangerouslySetInnerHTML={{__html: props.field_body.processed}}
    />
    <_Features hotelRoom={props} />
  </div>
);

export default _Summary;
