import FormErrorUtils from "@/src/core/app/utils/form-error";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {FormErrorHandleFnProps} from "@/src/core/app/domain/forms/@types/FormErrorHandleFnProps";
import EmailUtils from "@/src/core/app/utils/email";
import {arrayFromOneToN} from "@/src/common/utils/arrayFromOneToN";
import {TFunction} from "react-i18next";
import {FormValidationFn} from "@/src/ui/@types/form/FormValidationFn";
import {FormFieldValidationFn} from "@/src/ui/@types/form/FormFieldValidationFn";

const FormUtils = {
  runValidations: (t: TFunction, values: any, fns: FormValidationFn[]) => {
    let errors = {};
    fns.forEach(fn => {
      errors = {
        ...errors,
        ...fn(t, values),
      }
    });
    return errors;
  },
  multipleValidator: (t: TFunction, value: string, fieldValidators: FormFieldValidationFn[]) : string | undefined => {
    for (const fieldValidator of fieldValidators) {
      const errorMessage = fieldValidator(t, value);
      if (errorMessage) {
        return errorMessage;
      }
    }
  },
  validateIsRequired: (t: TFunction, value: any) : string | undefined => {
    if (!value) {
      return t('form_utils.generic_validation.required_label');
    }
  },
  validateIsEmail: (t: TFunction, value: any) : string | undefined => {
    if (!value) {
      return;
    }
    if (!EmailUtils.isValid(value)) {
      return t('form_utils.generic_validation.invalid_email_label');
    }
  },
  handleNetworkError: <T = any>(props: FormErrorHandleFnProps<T>) => {
    let formError = FormErrorUtils.getError<T>(props);
    if (formError.notification) {
      props.modalState.showModal(ModalKey.NOTIFICATION, formError.notification);
    }
    if (formError.fields) {
      let errors : Record<string, string> = {};
      for (const key of Object.keys(formError.fields)) {
        errors[key] = props.t(formError.fields[key] as any);
      }
      props.formikHelpers.setErrors(errors);
    }
    if (formError.action) {
      formError.action(props);
    }
  },
  getNumberSelectOptions: (count: number, start: number = 1) => {
    return arrayFromOneToN({
      count,
      start,
    }).map(value => ({
      value: value.toString(),
      label: `${value}`,
    }))
  }
};

export default FormUtils;
