import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import {UI_LayoutConversion} from "@/src/ui/@types/layout/UI_LayoutConversion";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {useEffect} from "react";
import cn from "classnames";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "src/ui/components/portals/generic/ModalLayout";
import {useTranslation} from "next-i18next";
import ModalKey from "@/src/ui/@types/modal/ModalKey";

const MODAL_KEY = ModalKey.CHECKOUT_RATE_POPUP;

const ModalCheckoutRatePopupGeneric = (): JSX.Element | null => {
  const { t } = useTranslation();
  const state = useModalProvider(state => state);
  const checkout = useCheckoutProvider(state => state);
  const routeProps = useRouteProps<T_CheckoutHotel, UI_LayoutConversion>();

  useEffect(() => {
    if (checkout.isInitiated === false) {
      return;
    }

    if (CheckoutUtils.hasAnyRatePopupToDisplay(checkout, routeProps.routeData.data.rate_configuration_popups)) {
      state.toggleModal(MODAL_KEY);
    }
  }, [checkout.isInitiated]);

  if (
    !checkout.isInitiated ||
    state.modalKey !== MODAL_KEY
  ) {
    return null;
  }

  const ratePopupsToDisplay = CheckoutUtils.getRatePopupsToDisplay(
    checkout,
    routeProps.routeData.data.rate_configuration_popups
  );

  return (
    <ModalLayout
      title={t('ModalCheckoutRatePopup._Index.title')}
      className="w-171.5"
      onCloseModal={() => state.toggleModal(MODAL_KEY)}
    >
      <div className="p-3.75 sm:p-5 space-y-3.75 sm:space-y-5">
        {ratePopupsToDisplay.map(ratePopupToDisplay => (
          <div key={ratePopupToDisplay[0]}>
            <p className='font-bold mb-2'>
              {t('ModalCheckoutRatePopup._Index.room_label', {
                index: ratePopupToDisplay[0] + 1
              })}
            </p>
            <article
              className={cn(
            "flex space-x-2.5 py-2.5 px-5 bg-yellow-15",
                  "items-center"
              )}
            >
              <img className="w-5 mr-2 flex-none h-auto" src="/assets/icons/info.svg" alt="" />
              <WrapperDiv
                className='Cms-Formatted'
                dangerouslySetInnerHTML={{__html: routeProps.routeData.data.rate_configuration_popups[0].field_body.value}}
                size='medium'
              />
            </article>
          </div>
        ))}
      </div>
    </ModalLayout>
  );
}

export default ModalCheckoutRatePopupGeneric;
