import UrlUtils from "@/src/core/app/utils/url";
import {T_TaxonomyTermLink} from "@/src/core/app/domain/models/taxonomy/T_TaxonomyTermLink";

const UiTaxonomyTermUtils = {
  getUrl: (entity: T_TaxonomyTermLink) => {
    if (entity.path.alias) {
      return UrlUtils.buildAppUrlWithLangPrefix(entity.path.langcode, entity.path.alias);
    }
    return UrlUtils.buildAppUrlWithLangPrefix(entity.langcode, `/taxonomy/term/${entity.drupal_internal__tid}`);
  },
}

export default UiTaxonomyTermUtils;
