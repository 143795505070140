import {T_HotelRoomPromoted} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomPromoted";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import {useTranslation} from "next-i18next";


const _Features = (props: T_HotelRoomPromoted): JSX.Element => {
  const { t } = useTranslation();
  const surface = HotelRoomUtils.getSurface(props);
  return (
    <div className="flex flex-col items-center space-y-1">
      <div className="flex space-x-5">
        <div className="flex items-center space-x-3">
          <img
            className="h-4.5 w-4.5 object-contain"
            src={"/assets/icons/person-white.svg"}
            alt=""
          />
          <span className="sr-only">{t('BlockHotelRoomsPromoted._Index.view_all')}:</span>
          <span>{HotelRoomUtils.getCapacity(props)}</span>
        </div>
        {props.field_room_beds && (
          <div className="flex items-center space-x-3">
            <img
              className="h-4.5 w-4.5 object-contain"
              src={"/assets/icons/bed-white.svg"}
              alt=""
            />
            <span className="sr-only">{t('BlockHotelRoomsPromoted._Features.beds')}:</span>
            <span>{props.field_room_beds}</span>
          </div>
        )}
      </div>
      {surface && (
        <div className="flex space-x-3">
          <img
            className="h-4.5 w-4.5 object-contain"
            src={"/assets/icons/width-white.svg"}
            alt=""
          />
          <span className="sr-only">
          {t('BlockHotelRoomsPromoted._Features.surface')}:
        </span>
          <span className="whitespace-nowrap"
                dangerouslySetInnerHTML={{__html: surface}}
          />
        </div>
      )}
    </div>
  );
}

export default _Features;
