import { immer } from "zustand/middleware/immer";
import {createStore, useStore} from "zustand";
import {UserState} from "@/src/ui/view_models/user.state";
import UserUtils from "@/src/core/app/utils/user";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import { Route } from "@/src/core/app/domain/@types/Route";
import UiElbaMediaUtils from "@/src/ui/utils/elba-media";
import BrowserUtils from "@/src/ui/utils/browser";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

export const userProviderStore = createStore<UserState>()(immer((set, get) => ({
  isInitiated: false,
  userData: null,
  init: async (appInfo: AppInfo, route: Route) => {
    // console.time('userProviderStore.init');
    const userData = await UserUtils.loadUser();

    if (userData === null) {
      set(state => {
        state.isInitiated = true;
        state.userData = null;
      });
      return;
    }

    const redirectionURl = UiElbaMediaUtils.getRedirectionUrl(appInfo, route, userData);
    if (redirectionURl) {
      BrowserUtils.goTo(redirectionURl);
      return;
    }

    const userSession = await UserUtils.regenerateSession();

    if (userSession === null) {
      set(state => {
        state.isInitiated = true;
        state.userData = null;
      });
      return;
    }

    set(state => {
      state.isInitiated = true;
      state.userData = userData;
    })
    // console.timeEnd('userProviderStore.init');
  },
  setUserData(userData: UserData) {
    set(state => {
      state.userData = userData;
    });
  },
  isLoggedIn: () => {
    const state = get();
    if (!state.isInitiated) {
      return false;
    }
    return state.userData !== null;
  },
  isAnonymousGuaranteed: () => {
    const state = get();
    if (!state.isInitiated) {
      return false;
    }
    return state.userData === null;
  }
})));

export function useUserProvider(): UserState;
export function useUserProvider<T>(selector: (state: UserState) => T, equals?: (a: T, b: T) => boolean): T;
export function useUserProvider(selector?: any, equals?: any) {
  return useStore(userProviderStore, selector, equals);
}
