import type {RouteProps} from "@/src/ui/@types/RouteProps";
import UserLostPasswordForm from "src/ui/components/forms/UserLostPasswordForm";
import {useRoute} from "@/src/ui/hooks/useRoute";
import RouteUtils from "@/src/core/app/utils/route";
import UserLostPasswordConfirmationForm from "@/src/ui/components/forms/UserLostPasswordConfirmationForm";

const Route_UserLostPassword = (props: RouteProps) : JSX.Element => {
  const route = useRoute();
  const username = RouteUtils.getQueryParamString(route, 'name');
  const tempPassword = RouteUtils.getQueryParamString(route, 'pwd');

  if (username && tempPassword) {
    return (
      <UserLostPasswordConfirmationForm
        username={username}
        tempPassword={tempPassword}
      />
    );
  }
  return (
    <UserLostPasswordForm />
  );
}

export default Route_UserLostPassword;
