import WarningBanner from "@/src/ui/components/desktop/WarningBanner";
import {useTranslation} from "next-i18next";

const _CheckoutEmpty = () => {
  const { t } = useTranslation();
  return (
    <div className='space-y-5'>
      <WarningBanner level='error'>
        <>
          {t('OfferDetail._CheckoutEmpty.warning')}
        </>
      </WarningBanner>
    </div>
  )
}

export default _CheckoutEmpty;
