import {T_ParagraphInfoBlock} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoBlock";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import AssetCmsIcon from "src/ui/components/generic/AssetCmsIcon";

interface _Props {
  paragraph: T_ParagraphInfoBlock
}

const _Advantages = (props: _Props) => {
  if (props.paragraph.field_bullets?.length === 0) {
    return null;
  }

  return (
    <ul className="p-5 pt-9 mb-2.5 border border-gray-40 space-y-2.5">
      {props.paragraph.field_bullets?.map((bullet, index) => (
        <li key={index} className="flex items-center space-x-3.5">
          <AssetCmsIcon className="w-6 h-auto" iconKey={bullet.icon_class} />
          {bullet.text_2 && (
            <div>
              <dt className="font-bold">{bullet.text_1}</dt>
              <dd className="text-xs">{bullet.text_2}</dd>
            </div>
          )}
          {!bullet.text_2 && (
            <span>{bullet.text_1}</span>
          )}
        </li>
      ))}
      {props.paragraph.field_bullets_footer && (
        <WrapperDiv
          className="pt-2.5 text-xs"
          dangerouslySetInnerHTML={{__html: props.paragraph.field_bullets_footer.processed}}
        />
      )}
    </ul>
  );
}

const _Box = (props: _Props) => (
  <article className="text-sm text-brand-2-90">
    <div className="h-6 mb-0.5 relative">
      <div className={`absolute inset-x-0 flex justify-center pt-3`}>
        <h3 className={`text-center bg-white text-lg font-bold px-2.5`}>
          {props.paragraph.field_bullets_title}
        </h3>
      </div>
    </div>
    <_Advantages {...props} />
  </article>
);

export default _Box;