import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockDestinationListDesktop from "@/src/ui/components/blocks/BlockDestinationList/desktop";
import BlockDestinationListMobile from "@/src/ui/components/blocks/BlockDestinationList/mobile";
import {T_ParagraphDestinationList} from "@/src/core/app/domain/models/paragraph/T_ParagraphDestinationList";


const BlockDestinationList = (props: T_ParagraphDestinationList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockDestinationListMobile : BlockDestinationListDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockDestinationList;
