import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import cn from "classnames";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelRoom: T_HotelRoomTeaser
  className?: string
}

const HotelRoomHeaderHeaderInfoMobile = (props: _Props) => {
  const { t } = useTranslation();
  const hotelRoom = props.hotelRoom;
  const surface = HotelRoomUtils.getSurface(hotelRoom);
  return (
    <ul className={cn("flex text-sm", props.className)}>
      <li className="flex space-x-3">
        <img src="/assets/icons/person-brand-2.svg" alt="" />
        <span className="sr-only">{t('HotelRoomHeaderHeaderInfo._Index.capacity_label')}:</span>
        <span>{HotelRoomUtils.getCapacity(hotelRoom)}</span>
      </li>
      {surface && (
        <li className="flex space-x-3">
          <img src="/assets/icons/surface-circle-brand-2.svg" alt="" />
          <span className="sr-only">{t('HotelRoomHeaderHeaderInfo._Index.surface_label')}:</span>
          <span dangerouslySetInnerHTML={{__html: surface}} />
        </li>
      )}
    </ul>
  )
};

export default HotelRoomHeaderHeaderInfoMobile;
