import FormInputText from "@/src/ui/components/formik/FormInputText";
import Button from "@/src/ui/components/generic/Button";
import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Formik} from "formik";
import FormUtils from "@/src/ui/utils/form";
import Anchor from "@/src/ui/components/generic/Anchor";
import {BookingManageFormValues} from "@/src/core/app/domain/forms/BookingManageFormValues";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import BookingManageErrors from "@/src/core/app/domain/booking/@types/FormErrorMap/booking-manage";
import {useBookingManageProvider} from "@/src/ui/providers/booking-manage.provider";
import SsrUtils from "@/src/core/app/utils/ssr";
import {T_ReservationManage} from "@/src/core/app/domain/models/reservation/T_ReservationManage";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

export interface _FormProps {
  formik: FormikProps<BookingManageFormValues>
}

const _Form = (props: _FormProps) => {
  const { t } = useTranslation();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const hotels = HotelTreeUtils.getHotels(layoutData.hotelTree);

  return (
    <TitledBox title={t('BookingManageForm._Index.title')}>
      <div className="px-5 py-3.75 space-y-5 sm:space-y-7.5">
        <div>
          <div className="pb-3.75">
            <FormInputText
              label={t('BookingManageForm._Index.reference_label') + " *"}
              name='bookingReference'
              type='text'
              validate={FormUtils.validateIsRequired}
            />
            <p className="text-xs text-brand-2 pt-1.5">
              {t('BookingManageForm._Index.reference_help')}
            </p>
          </div>
          <FormInputSelect
            name='hotelCode'
            label={t('BookingManageForm._Index.hotel_label') + " *"}
            emptyOption={'- ' + t('BookingManageForm._Index.hotel_empty_option')}
            options={HotelTreeUtils.getSelectOptions(hotels)}
            validate={FormUtils.validateIsRequired}
          />
        </div>
        <div>
          <Button text={t('BookingManageForm._Index.submit_button_label')} bold onClick={props.formik.handleSubmit} isActive={!props.formik.isSubmitting} />
        </div>
        <p className="text-sm text-brand-2">
          {t('BookingManageForm._Index.contact_text_1')}{" "}
          <Anchor
            className="underline"
            href={layoutData.sitePageUrls.contact_page_url}
          >
            {t('BookingManageForm._Index.contact_text_2')}
          </Anchor>
        </p>
      </div>
    </TitledBox>
  );
}

const BookingManageForm = () => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const bookingManageState = useBookingManageProvider(state => state)
  const modalState = useModalProvider(state => state);
  let initialValues : BookingManageFormValues = {
    bookingReference: '',
    hotelCode: '',
  };

  return (
    <Formik<BookingManageFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await SsrUtils.bookingManage(appInfo, values.bookingReference, values.hotelCode);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: BookingManageErrors,
            logFn: (reason) => FormErrorUtils.logNetworkError('Manage booking form error', reason, networkError),
          });
          return;
        }

        modalState.closeModal();
        bookingManageState.setData(response.data as T_ReservationManage);
      }}
    >
      {(formik: FormikProps<BookingManageFormValues>) => (
        <_Form formik={formik} />
      )}
    </Formik>
  );
}

export default BookingManageForm;
