import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {locator} from "@/src/core/app/ioc";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {TYPES} from "@/src/core/app/ioc/types";
import {CMS_AuthHeadersType} from "@/src/core/app/domain/cms/@types/auth/CMS_AuthHeadersType";

const UserLoginUtils = {
  login: async (username: string, password: string) : Promise<NetworkResponse> => {
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.userLogin(username, password);
  },
  loginWithCmsCookie: async () : Promise<NetworkResponse> => {
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    const r1 = await api.userSessionToken();
    if (!r1.isOk) {
      return r1;
    }

    return await api.userSessionRegenerate({
      type: CMS_AuthHeadersType.BROWSER,
      csrfToken: r1.data as string,
    });
  },
};

export default UserLoginUtils;
