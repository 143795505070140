import {T_HotelMetaAddressData} from "@/src/core/app/domain/models/hotel/T_HotelMetaAddressData";

interface _Props {
  data: T_HotelMetaAddressData
}

export const HotelMetaAddress = (props: _Props) => (
  <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
    <meta itemProp="streetAddress" content={props.data.address} />
    <meta itemProp="postalCode" content={props.data.postal_code} />
    <meta itemProp="addressLocality" content={props.data.locality} />
    <meta itemProp="addressRegion" content={props.data.region} />
    <meta itemProp="addressCountry" content={props.data.country} />
    <meta itemProp="priceRange" content={props.data.price_range} />
    <meta itemProp="telephone" content={props.data.phone} />
  </div>
);

export default HotelMetaAddress;
