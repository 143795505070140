import {SearchFormOccupationState} from "@/src/ui/view_models/search-form-occupation.state";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {createProvider} from "@/src/common/utils/zustand";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";

const ROOMS_MAX = 5;
const ADULTS_MAX = 5;
const ADULTS_MIN = 0;
const KIDS_MAX = 5;
const KIDS_MIN = 0;

export const useSearchFormOccupationProvider = createProvider<SearchFormOccupationState>((set, get) => {
  const rooms = useSearchFormProvider(state => state.rooms);
  return {
    rooms,
    clearRooms: () => set(state => {
      state.rooms = [SearchParamsUtils.getDefaultRoomOccupation()];
    }),
    roomAdd: () => {
      if (get().rooms.length === ROOMS_MAX) {
        return;
      }
      set(state => {
        state.rooms.push(SearchParamsUtils.getDefaultRoomOccupation());
      })
    },
    roomAddAdult: (roomIndex) => {
      if (get().rooms[roomIndex].adults === ADULTS_MAX) {
        return;
      }
      set(state => {
        state.rooms[roomIndex].adults++;
      });
    },
    roomSubAdult: (roomIndex) => {
      if (get().rooms[roomIndex].adults === ADULTS_MIN) {
        return;
      }
      set(state => {
        state.rooms[roomIndex].adults--;
      });
    },
    roomAddKid: (roomIndex, kidsMaxAge: number) => {
      if (get().rooms[roomIndex].kids === KIDS_MAX) {
        return;
      }
      set(state => {
        state.rooms[roomIndex].kids++;
        state.rooms[roomIndex].kid_ages.push(kidsMaxAge);
      });
    },
    roomSubKid: (roomIndex) => {
      if (get().rooms[roomIndex].kids === KIDS_MIN) {
        return;
      }
      set(state => {
        state.rooms[roomIndex].kids--;
        state.rooms[roomIndex].kid_ages = state.rooms[roomIndex].kid_ages.splice(0, state.rooms[roomIndex].kids);
      });
    },
    roomChangeKidAge: (roomIndex, kidIndex, kidAge) => {
      set(state => {
        state.rooms[roomIndex].kid_ages[kidIndex] = kidAge;
      });
    }
  }
});
