import PaymentUtils from "@/src/ui/utils/payment";

const _paymentDataValidation = (values: any) => {
  const errors : Record<string, string> = {};

  if (values.ccNumber) {
    if (!/^\d+$/.test(values.ccNumber)) {
      errors.ccNumber = 'Invalid credit card number, must be numeric.';
    }
    else {
      if (values.ccType === 'amex' && values.ccNumber.length != 15) {
        errors.ccNumber = 'Invalid credit card number, must be 15 characters long.';
      }
      else if (values.ccType !== 'amex' && values.ccNumber.length != 16) {
        errors.ccNumber = 'Invalid credit card number, must be 16 characters long.';
      }
      else if (!PaymentUtils.isValid(values.ccNumber)) {
        errors.ccNumber = 'Invalid credit card number.';
      }
    }
  }
  if (values.ccCVV) {
    if (!/^\d+$/.test(values.ccCVV)) {
      errors.ccCVV = 'Invalid credit card security code, must be numeric.';
    }
    else {
      if (values.ccType === 'amex' && values.ccCVV.length != 4) {
        errors.ccCVV = 'Invalid credit card security code length, must be 4 characters long.';
      }
      if (values.ccType !== 'amex' && values.ccCVV.length != 3) {
        errors.ccCVV = 'Invalid credit card security code length, must be 3 characters long.';
      }
    }
  }
  if (values.ccMonth && values.ccYear) {
    if (!PaymentUtils.isExpirationDateValid(values.ccMonth, values.ccYear)) {
      errors.ccMonth = 'Credit card expired.';
    }
  }
  return errors;
};

export default _paymentDataValidation;