import DatePicker, { registerLocale } from "react-datepicker";

import {DeviceType} from "@/src/ui/@types/Device";
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {useState} from "react";
import CalendarUtils from "@/src/ui/utils/calendar";
import cn from "classnames";
import {_CalendarHeader} from "@/src/ui/components/search-form/SearchFormCalendar/header";
import {_Footer} from "@/src/ui/components/search-form/SearchFormCalendar/footer";
import es from "date-fns/locale/es";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import dateRange from "@/src/ui/components/offer/OfferDetail/form/date-range";

interface _CalendarProps {
  device: DeviceType
  startDate?: string
  endDate?: string
  onSubmit: (range: SearchParamsDateRange) => void
  minDate?: string
  maxDate?: string
  onChange?: (range: SearchParamsDateRange) => void
  nightsCount?: number
}

registerLocale('es', es)

export const SearchFormCalendarWidget = (props: _CalendarProps) => {
  const [clearClicked, setClearClicked] = useState(false);
  const [startDate, setStartDate] = useState(
    props.startDate ? CalendarUtils.parseDay(props.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    props.endDate ? CalendarUtils.parseDay(props.endDate) : null
  );

  const range : SearchParamsDateRange = {
    start: (startDate && endDate) ? CalendarUtils.dayToString(startDate) : undefined,
    end: endDate ? CalendarUtils.dayToString(endDate) : undefined,
  };

  const onChange = (dates: any) => {
    let [start, end] = dates;

    const _start = CalendarUtils.dayToString(start);
    let _end = end ? CalendarUtils.dayToString(end) : null;

    if (props.nightsCount) {
      const dateRange = SearchParamsUtils.getDateRangeForFixedNightsCount({
        start: props.minDate,
        end: props.maxDate,
      }, _start, props.nightsCount);

      start = CalendarUtils.parseDay(dateRange.start as string);
      end = CalendarUtils.parseDay(dateRange.end as string);
    }

    if (_start === _end) {
      setStartDate(null);
      setEndDate(null);
    }
    else {
      setStartDate(start);
      setEndDate(end);
    }

    if (props.onChange) {
      props.onChange({
        start,
        end,
      })
    }
  };

  const clearDates = () => {
    setClearClicked(true);
    setStartDate(null);
    setEndDate(null);

    if (props.onChange) {
      props.onChange({
        start: undefined,
        end: undefined,
      })
    }
  };

  const canBeSubmitted = !SearchParamsUtils.isDateRangeEmpty(range) ||  clearClicked;
  const onSubmit = () => {
    if (!canBeSubmitted) {
      return;
    }
    props.onSubmit(range);
  };

  return (
    <div className={cn(
      'divide-y-gray-40 text-brand-2',
      !props.device.isMobile && 'w-171.5',
      props.device.isMobile && 'flex flex-col flex-1 overflow-hidden'
    )}>
      <div className='overflow-y-auto'>
        <DatePicker
          renderCustomHeader={_CalendarHeader}
          locale={'es'}
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          minDate={props.minDate ? CalendarUtils.parseDay(props.minDate) : new Date()}
          maxDate={props.maxDate ? CalendarUtils.parseDay(props.maxDate) : undefined}
          monthsShown={2}
          selectsRange
          // @ts-ignore
          selectsDisabledDaysInRange
          inline
        />
      </div>
      <_Footer
        device={props.device}
        range={range}
        onSubmit={onSubmit}
        clearDates={() => clearDates()}
        canBeSubmitted={canBeSubmitted}
      />
    </div>
  );
}