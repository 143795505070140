import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Section from "@/src/ui/components/generic/Section/Section";
import {ReactNode, useState} from "react";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

interface _Props {
  subtitle?: string
  title?: string
  titleWithTag?: CMS_FieldTextWithTag
  bodyHtml?: string
  bodyExpandedHtml?: string
  bodyItemProp?: string
  infoBox?: ReactNode
}

const InfoBlockMobile = (props: _Props) => {
  const { t } = useTranslation();
  const isExpandable = props.bodyExpandedHtml !== undefined;
  const [expanded, setExpanded] = useState(false);

  return (
    <Section className="text-sm text-brand-2">
      <div className="space-y-2.5 pb-2.5">
        {props.subtitle && (<p className="title-5">{props.subtitle}</p>)}
        {props.title && (
          <h2 className="title-2-mobile font-calistoga">
            {props.title}
          </h2>
        )}
        <TitleWithTag
          field={props.titleWithTag}
          className='text-2xl font-calistoga'
        />
      </div>
      {props.bodyHtml && (
        <WrapperDiv
          className='Cms-Formatted'
          itemProp={props.bodyItemProp}
          dangerouslySetInnerHTML={{__html: expanded ? [props.bodyHtml, props.bodyExpandedHtml].join('') : props.bodyHtml}}
        />
      )}
      {isExpandable && (
        <span className="block py-5" onClick={() => setExpanded(!expanded)}>
          {expanded ? t('global.generic_link.read_less') : t('global.generic_link.read_more')}
        </span>
      )}
      {props.infoBox && (
        <>
          {props.infoBox}
        </>
      )}
    </Section>
  );
}

export default InfoBlockMobile;
