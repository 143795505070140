import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";
import UrlUtils from "@/src/core/app/utils/url";

const MenuUtils = {
  getMenuItemHref: (menuItem: CMS_MenuItem) : string => {
    if (menuItem.uri == 'internal:/user/logout') {
      return '/user/logout';
    }

    return UrlUtils.getCmsLinkUrl(menuItem.url);
  }
};

export default MenuUtils;
