import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";

const BookingConfirmationSummaryHeader = () => {
  const { t } = useTranslation();
  const routeData = useRouteData<BookingConfirmationRouteProps>();
  return (
    <TitledBox
      title={
        <span className="title-4 font-normal">
          {t('BookingConfirmationSummaryHeader._Index.header', {
            name: routeData.data.reservation.first_name
          })}
        </span>
      }
    >
      <div className="py-4 px-5 space-y-7.5 text-xs text-brand-2">
        <p dangerouslySetInnerHTML={{__html: t('BookingConfirmationSummaryHeader._Index.confirmation', {
          email: routeData.data.reservation.email
        })}} />
        <div className="space-y-1">
          <h3 className="text-sm text-brand-2-60 font-bold">{t('BookingConfirmationSummaryHeader._Index.booking_reference_label')}</h3>
          <p className="text-title-5">
            #{routeData.data.reservation.id}
          </p>
        </div>
        <p>
          {t('BookingConfirmationSummaryHeader._Index.footer')}
        </p>
      </div>
    </TitledBox>
  )
};

export default BookingConfirmationSummaryHeader;