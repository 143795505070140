import React from "react";
import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import {useCarouselBasic} from "@/src/ui/hooks/useCarouselBasic";
import MediaImage from "@/src/ui/components/generic/MediaImage";

interface _Props {
  medias: T_MediaImage[]
  className?: string
}

const Carousel = (props: _Props) => {
  const {
    emblaRef,
    scrollPrev,
    scrollNext,
  } = useCarouselBasic(props.medias);

  if (props.medias.length === 0) {
    return null;
  }

  if (props.medias.length === 1) {
    return (
      <div className={props.className}>
        <MediaImage mediaImage={props.medias[0]} />
      </div>
    );
  }

  return (
    <div className={props.className}>
      <div className='relative'>
        <div className="absolute inset-0 max-w-screen-xl mx-auto p-5 flex items-center justify-between">
          <button
            className="embla__prev relative z-10 transition-all transform hover:scale-110"
            onClick={scrollPrev}
          >
            <img src="/assets/icons/arrow-left-circle-transparent.svg" alt="" />
          </button>
          <button
            className="embla__next relative z-10 transition-all transform hover:scale-110"
            onClick={scrollNext}
          >
            <img src="/assets/icons/arrow-right-circle-transparent.svg" alt="" />
          </button>
        </div>
        <div
          ref={emblaRef}
          className="embla overflow-hidden"
        >
          <div className="embla__container flex">
            {props.medias.map((mediaImage) => (
              <div
                key={mediaImage.id}
                className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0"
                style={{ flexBasis: "100%" }}
              >
                <MediaImage mediaImage={mediaImage} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
