import {useModalProvider} from "@/src/ui/providers/modal.provider";
import React from "react";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import OfferSearchMenu from "@/src/ui/components/offer/OfferSearchMenu";
import {T_OfferGroupLink} from "@/src/core/app/domain/models/offer-group/T_OfferGroupLink";
import {T_NodeExperienceLink} from "@/src/core/app/domain/models/node/T_NodeExperienceLink";
import {useTranslation} from "next-i18next";

interface ModalStateData {
  offerGroupLinks: T_OfferGroupLink[]
  experienceLinks: T_NodeExperienceLink[]
}

const ModalOfferSearchMenuMobile = (): JSX.Element => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const modalStateData = modalState.modalData as ModalStateData;

  return (
    <ModalLayoutFullscreen onCloseModal={() => modalState.closeModal()}>
      <div className='p-5 pt-15 border-b border-gray-20'>
        <h2 className="title-3-mobile text-center">
          {t('ModalOfferSearchMenu._Index.title')}:
        </h2>
      </div>
      <div className="flex-1 flex flex-col p-5">
        <OfferSearchMenu
          offerGroupLinks={modalStateData.offerGroupLinks}
          experienceLinks={modalStateData.experienceLinks}
        />
      </div>
    </ModalLayoutFullscreen>
  );
}

export default ModalOfferSearchMenuMobile;
