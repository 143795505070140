import { ReactNode } from "react";
import Section from "../Section/Section";
import FormBox from "../../desktop/FormBox";

interface NarrowFormBoxProps {
  children: ReactNode;
  title: string;
}

const NarrowFormBox = ({
  children,
  title,
}: NarrowFormBoxProps): JSX.Element => (
  <Section paddingY="py-7.5 sm:py-20">
    <div className="w-95 max-w-full mx-auto">
      <FormBox title={title}>
        <div className="p-3.75 sm:px-5 space-y-5 sm:space-y-7.5">
          {children}
        </div>
      </FormBox>
    </div>
  </Section>
);

export default NarrowFormBox;
