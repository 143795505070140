import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";
import BrowserUtils from "@/src/ui/utils/browser";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import _Table from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/table";
import _Header from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/header";

interface _Props extends T_ParagraphHotelRoomList {
  rooms: T_HotelRoomTeaser[]
}

const _CalendarStatusOk = (props: _Props) => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);

  const onClick = () => {
    SearchFormUtils.showLoader(modalState);
    BrowserUtils.reload();
  };

  return (
    <Section paddingY="py-15">
      <MaxWidthWrapper>
        <div className="flex justify-between items-end pb-10">
          <TitleWithTag
            field={props.field_title_with_tag}
            className="title-4 font-calistoga"
          />
          <div className="flex space-x-7.5 text-sm text-brand-2">
            <span className="underline cursor-pointer" onClick={onClick}>
              {t('BlockHotelRoomListDesktop._CalendarStatusOk.see_room_prices_label')}
            </span>
          </div>
        </div>
        <_Header />
        <_Table />
      </MaxWidthWrapper>
    </Section>
  );
}

export default _CalendarStatusOk;
