import {T_NodeBlogDetail} from "@/src/core/app/domain/models/node/T_NodeBlogDetail";
import NodeBlogTeaserDesktop from "@/src/ui/components/blog/NodeBlogTeaser/desktop";

const _Related = (props: T_NodeBlogDetail) => {
  if (props.relatedPosts.length === 0) {
    return null;
  }

  return (
    <section className="pb-20">
      <h2 className="py-7.5 title-4">Posts relacionados</h2>
      <ul className="grid grid-cols-3 gap-5">
        {props.relatedPosts.map((item) => (
          <li className="flex items-stretch" key={item.id}>
            <NodeBlogTeaserDesktop  blogPost={item} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default _Related;