import {T_Pager} from "@/src/core/app/domain/models/pager/T_Pager";
import Pagination from "@/src/ui/components/generic/Pagination";

interface _Props {
  pager: T_Pager
  className?: string
}

const PaginationWrapper = (props: _Props) => {
  if (props.pager.pagesCount <= 1) {
    return null;
  }
  return (
    <div className={props.className}>
      <Pagination {...props.pager} />
    </div>
  );
}

export default PaginationWrapper;