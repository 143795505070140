import {PropsWithChildren} from "react";
import cn from "classnames";

type _Props = PropsWithChildren &  {
  fixedWrapperClassName?: string
  className?: string
}

const PositionFixedWrapper = (props: _Props) => (
  <section className={cn(
    "fixed z-40 inset-0 flex justify-center items-center bg-brand-2-40-transparent px-2.5 py-7.5",
      props.fixedWrapperClassName,
    )}>
    <div
      className={cn(
        "max-w-full max-h-full flex flex-col items-stretch overflow-hidden",
        props.className
      )}
    >
      {props.children}
    </div>
  </section>
)

export default PositionFixedWrapper;