import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import OfferSearchMenu from "@/src/ui/components/offer/OfferSearchMenu";
import Section from "@/src/ui/components/generic/Section/Section";
import OfferDetail from "@/src/ui/components/offer/OfferDetail";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";
import _JsonLd from "@/src/ui/components/routes/Route_OfferDetail/generic/_JsonLd";

const Route_OfferDetailDesktop = (props: RouteProps<T_OfferDetail>) : JSX.Element => {
  const item = props.routeData.data;
  return (
    <>
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <Section paddingY="py-7.5">
        <MaxWidthWrapper>
          <div className="pb-7.5 pl-75 flex justify-between items-center">
            <h2 className="title-3">
              {item.title}
            </h2>
          </div>
          <div className="flex pb-5">
            <div className="flex-none w-75 pr-20">
              <OfferSearchMenu
                offerGroupLinks={item.cpt_ui_offer_group_links}
                experienceLinks={item.cpt_ui_experience_links}
              />
            </div>
            <div className="flex-1">
              <OfferDetail item={item} />
            </div>
          </div>
          <_JsonLd offerDetail={props.routeData.data} />
        </MaxWidthWrapper>
      </Section>
    </>
  );
}

export default Route_OfferDetailDesktop;
