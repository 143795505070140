import HotelMapSection from "src/ui/components/hotel/HotelMapSection";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";

interface _Props {
  initiated: boolean
}

const _Map = (props: _Props) => {
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const hotels = HotelTreeUtils.getHotels(layoutData.hotelTree);
  return (
    <HotelMapSection
      hotelList={hotels}
      initiated={props.initiated}
      layer='leaflet'
      className='h-109.5'
    />
  );
}

export default _Map;