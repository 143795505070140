import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";
import {CheckoutStepRoomsStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsStatus";
import _CheckoutStatusLoading from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/checkout-status-loading";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import CheckoutStepRoomsErrors from "@/src/core/app/domain/checkout/@types/FormErrorMap/checkout-step-rooms";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import NotificationMessage from "src/ui/components/generic/NotificationMessage";
import {T_Notification} from "@/src/ui/@types/T_Notification";
import _RoomsStatusOk from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/rooms/status-ok";
import {CheckoutRates} from "@/src/core/app/domain/models/checkout/CheckoutRates";
import {T_ParagraphHotelRoomList} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomList";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {T_HotelSectionDataRoom} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataRoom";

const _Rooms = (props: T_ParagraphHotelRoomList) => {
  const routeData = useRouteData<T_HotelSection<T_HotelSectionDataRoom>>();
  const state = useCheckoutProvider(state => state);
  const stepData = state.currentStepData as CheckoutStepRoomsData;
  let rooms = routeData.data.ui_hotel_section.data.rooms;

  if (stepData.status === CheckoutStepRoomsStatus.LOADING) {
    return (
      <_CheckoutStatusLoading {...props} />
    );
  }

  if (stepData.status === CheckoutStepRoomsStatus.ERROR) {
    const error = FormErrorUtils.getError({
      networkError: stepData.error as NetworkResponseError,
      formErrorMap: CheckoutStepRoomsErrors
    });
    return (
      <MaxWidthWrapper>
        <NotificationMessage {...(error.notification as T_Notification)} />
      </MaxWidthWrapper>
    );
  }

  if (stepData.status === CheckoutStepRoomsStatus.OK) {
    return (
      <_RoomsStatusOk
        {...props}
        rooms={rooms}
        rates={stepData.rates as CheckoutRates}
      />
    )
  }

  return null;
};

export default _Rooms;
