import { ReactNode } from "react";
import cn from "classnames";

export interface InputWrapperProps {
  children: ReactNode
  className?: string
  color?: "white" | "gray" | "disabled"
  hasError?: boolean
}

/**
 * There are many input components but they all share a common design and layout.
 * This component lets us reuse the design so that we don't have to write duplicate code.
 */
const InputWrapper = ({
  children,
  className,
  color = "white",
  hasError = false,
}: InputWrapperProps): JSX.Element => (
  <div
    className={cn(
      "flex-1 flex rounded py-2 px-2.5 h-9 border",
      color === "white" && "bg-white",
      color === "gray" && "bg-gray-20",
      color === "disabled" && "bg-gray-10",
      color === "white" && !hasError && "border border-brand-2-40",
      color === "gray" && !hasError && "border border-gray-20",
      color === "disabled" && !hasError && "border border-brand-2-40",
      hasError && "border border-red",
      className
    )}
  >
    {children}
  </div>
);

export default InputWrapper;
