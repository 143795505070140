import cn from "classnames";
import {SmallDropdownProps} from "@/src/ui/components/generic/SmallDropdown/types";
import {ChangeEvent, useEffect} from "react";

const SmallDropdown = (props: SmallDropdownProps): JSX.Element => {
  const {
    options,
    spaceBetween = false,
    color = "white",
    isBold = true,
  } = props;

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    props.onChange(value);
  };

  const currentOption = options.find(item => item.value === props.currentOption);

  return (
    <div className='relative'>
      <div className={cn("flex", spaceBetween && "space-x-2")}>
        <span
          className={cn(
            " text-xs",
            isBold && "font-bold"
          )}
        >
          {currentOption?.label}
        </span>
        {color === "white" && <img src="/assets/icons/triangle-down.svg" alt="" />}
        {color === "brand-2" && (
          <img src="/assets/icons/triangle-down-brand-2.svg" alt="" />
        )}
      </div>
      <select
        className='cursor-pointer absolute top-0 left-0 text-transparent border-none text-xs bg-transparent w-full'
        value={props.currentOption ? props.currentOption : undefined}
        onChange={onChange}
      >
        {options.map((item) => (
          <option
            key={item.id || item.value}
            value={item.value}
            className='text-black'
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SmallDropdown;
