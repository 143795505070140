import TitledBox from "@/src/ui/components/generic/TitledBox";
import {CMS_UserTmsInfo} from "@/src/core/app/domain/cms/@types/user/CMS_UserTmsInfo";
import _Row from "@/src/ui/components/blocks/BlockUserTmsInfo/generic/row";
import {useTranslation} from "next-i18next";

interface _Props {
  userTmsInfo: CMS_UserTmsInfo
}

const _Table = (props: _Props) => {
  const { t } = useTranslation();
  return (
    <TitledBox title={t('BlockUserTmsInfoDesktop._Table.header_label')}>
      <div>
        <div className="overflow-x-auto py-3 px-5">
          <table className="w-full min-w-140">
            <thead className="py-3.75">
            <tr className="border-b border-brand-2 text-left text-medium-bold">
              <th className='py-3'>{t('BlockUserTmsInfoDesktop._Table.points_label')}</th>
              <th className='p-3'>{t('BlockUserTmsInfoDesktop._Table.hotel_label')}</th>
              <th className='p-3'>{t('BlockUserTmsInfoDesktop._Table.description_label')}</th>
              <th className='p-3'>{t('BlockUserTmsInfoDesktop._Table.booking_reference_label')}</th>
              <th className='py-3'>{t('BlockUserTmsInfoDesktop._Table.date_label')}</th>
            </tr>
            </thead>
            <tbody className="px-3.75 sm:px-5 divide-y divide-gray-20">
            {props.userTmsInfo.movements.map((movement, index) => (
              <_Row key={index} movement={movement} />
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </TitledBox>
  );
}

export default _Table;
