import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import {useState} from "react";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import CheckoutRateGroupHeader from "@/src/ui/components/checkout/generic/CheckoutRateGroupHeader";
import CheckoutRateGroupBoardDesktop from "@/src/ui/components/checkout/desktop/CheckoutRateGroupBoard";
import CheckoutRateGroupFooterDesktop from "@/src/ui/components/checkout/desktop/CheckoutRateGroupFooter";

interface _Props {
  rateGroup: CheckoutRatesHotelRoomRateGroup
  onClick: (
    rateGroup: CheckoutRatesHotelRoomRateGroup,
    board: CheckoutRatesHotelRoomRateGroupBoard
  ) => void
}

const CheckoutRateGroupDesktop = (props: _Props): JSX.Element => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => setExpanded(!isExpanded)}
      >
        <CheckoutRateGroupHeader rateGroup={props.rateGroup} />
      </div>
      {isExpanded && (
        <div className="space-y-2.5">
          <ul className="flex border border-brand-40 divide-x divide-brand-40">
            {props.rateGroup.boards.map(board => (
              <li key={board.code} className="flex-1">
                <CheckoutRateGroupBoardDesktop
                  board={board}
                  onClick={(board: CheckoutRatesHotelRoomRateGroupBoard) => props.onClick(props.rateGroup, board)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      {isExpanded && (
        <CheckoutRateGroupFooterDesktop rateGroup={props.rateGroup}/>
      )}
    </div>
  )
};

export default CheckoutRateGroupDesktop;
