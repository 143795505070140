import Anchor from "@/src/ui/components/generic/Anchor";
import {useState} from "react";
import cn from "classnames";

export interface _GroupLink {
  label: string
  href: string | null
  isActive: boolean
}

interface _Props {
  title: string
  links: _GroupLink[]
}


const anyActive = (items: _GroupLink[]) : boolean => !!items.find(item => item.isActive);

const _Group = (props: _Props) => {
  const [isExpanded, setExpanded] = useState(anyActive(props.links));
  return (
    <div>
      <div
        className="w-full p-2.5 flex justify-between items-center bg-gray-10 text-sm text-brand-2 font-bold cursor-pointer select-none"
        onClick={() => setExpanded(!isExpanded)}
      >
        <span>{props.title}</span>
        <img
          className={cn(
            "w-3 h-auto",
            isExpanded && 'transform rotate-180',
          )}
          src="/assets/icons/chevron-small-brand-2.svg"
          alt=""
        />
      </div>
      <ul
        className={cn(
          "overflow-hidden transition-all mb-7.5 px-2.5 text-xs text-brand-2-90",
          isExpanded && 'max-h-screen',
          !isExpanded && 'max-h-0',
        )}
      >
        {props.links.map((item, index) => (
          <li key={index} className="mt-5 hover:underline">
            <Anchor
              href={item.href || undefined}
              className={cn(item.isActive && 'font-bold text-brand-2')}
            >{item.label}</Anchor>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default _Group;