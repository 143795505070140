import {T_Meta} from "@/src/core/app/domain/models/meta/T_Meta";
import {
  CMS_FieldMetatagRecord,
  CMS_FieldMetatagRecordAttributesLink,
  CMS_FieldMetatagRecordAttributesMeta,
  CMS_FieldMetatagRecordType, CMS_Metatag
} from "@/src/core/app/domain/cms/@types/metatag/CMS_Metatag";
import SentryUtils from "@/src/common/utils/sentry";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import { Route } from '@/src/core/app/domain/@types/Route';

interface HreflangValue {
  hreflang: string
  href: string
}

const MetaUtils = {
  getMeta: (metatag: CMS_Metatag | null) : T_Meta | null => {
    if (!metatag) {
      return null;
    }

    const meta : T_Meta = {
      title: '',
      description: '',
      hreflang: {},
    };

    metatag.forEach(item => {
      let [type, value] = MetaUtils.getMetatagRecordTypeAndValue(item);
      if (type === CMS_FieldMetatagRecordType.UNKNOWN) {
        SentryUtils.captureMessage(`Unhandled metatag type ${item.tag}`);
        return;
      }

      switch (type) {
        case CMS_FieldMetatagRecordType.TITLE:
          meta.title = value;
          break;
        case CMS_FieldMetatagRecordType.DESCRIPTION:
          meta.description = value;
          break;
        case CMS_FieldMetatagRecordType.KEYWORDS:
          meta.keywords = value;
          break;
        case CMS_FieldMetatagRecordType.CANONICAL:
          meta.canonical = value;
          break;
        case CMS_FieldMetatagRecordType.HREFLANG:
          value = value as HreflangValue;
          meta.hreflang[value.hreflang] = value.href;
          break;
      }
    });

    return meta;
  },

  getMetatagRecordTypeAndValue: (item: CMS_FieldMetatagRecord) : [CMS_FieldMetatagRecordType, any] => {
    let attributes = null;

    switch (item.tag) {
      case 'meta':
        attributes = item.attributes as CMS_FieldMetatagRecordAttributesMeta;
        if (attributes.name === 'title') {
          return [CMS_FieldMetatagRecordType.TITLE, attributes.content];
        }
        if (attributes.name === 'description') {
          return [CMS_FieldMetatagRecordType.DESCRIPTION, attributes.content];
        }
        if (attributes.name === 'keywords') {
          return [CMS_FieldMetatagRecordType.KEYWORDS, attributes.content];
        }
        break;
      case 'link':
        attributes = item.attributes as CMS_FieldMetatagRecordAttributesLink;
        if (attributes.rel === 'canonical') {
          return [CMS_FieldMetatagRecordType.CANONICAL, attributes.href];
        }
        if (attributes.hreflang) {
          return [CMS_FieldMetatagRecordType.HREFLANG, {
            hreflang: attributes.hreflang,
            href: attributes.href,
          } as HreflangValue];
        }
    }

    return [CMS_FieldMetatagRecordType.UNKNOWN, undefined];
  },

  getMetaForRoute: (
    meta: T_Meta | undefined | null,
    route: Route,
    layoutData: UI_LayoutBase
  ) : T_Meta | undefined  => {
    if (!meta) {
      return undefined;
    }

    if (!route.isHomePath) {
      return meta;
    }

    const hreflang : Record<string, any> = {};
    layoutData.languages.forEach(language => {
      hreflang[language.code] = `/${language.code}`;
    });

    return {
      ...meta,
      hreflang,
    }
  },
}

export default MetaUtils;
