import useEmblaCarousel from "embla-carousel-react";
import React, {useCallback, useState} from "react";

import Section from "../../generic/Section/Section";
import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import HeroHomeSearchForm from "../../desktop/HeroHomeSearchForm";
import MediaImageBgImg from "@/src/ui/components/generic/MediaImageBgImg";
import {T_ParagraphBannerSliderSlideDesktop} from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSliderSlideDesktop";
import {T_ParagraphBannerSlider} from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSlider";
import Anchor from "@/src/ui/components/generic/Anchor";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {
  T_ParagraphBannerSliderSlide_FooterFontSize
} from "@/src/core/app/domain/models/paragraph/T_ParagraphBannerSliderSlide";
import cn from "classnames";
import {useTranslation} from "next-i18next";

const _Footer = (item: T_ParagraphBannerSliderSlideDesktop) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);

  if (!item.field_footer || !item.field_footer_font_size) {
    return null;
  }

  const sizes = {
    [T_ParagraphBannerSliderSlide_FooterFontSize.SMALL]: 'text-xs',
    [T_ParagraphBannerSliderSlide_FooterFontSize.MEDIUM]: 'text-sm',
    [T_ParagraphBannerSliderSlide_FooterFontSize.BIG]: 'text-base',
  }
  return (
    <div className='pt-5 border-t-1px border-white'>
      <p className='mb-0.5 font-bold text-xs cursor-pointer flex space-x-1'
         onClick={() => setExpanded(!isExpanded)}
      >
        <span>{t('BlockBannerSlider._Index.conditions_label')}</span>
        <img
          className={cn({'rotate-180': isExpanded})}
          src='/assets/icons/banner-slider-footer-arrow-down.svg'
        />
      </p>
      {isExpanded && (
        <p className={sizes[item.field_footer_font_size]}>
          {item.field_footer}
        </p>
      )}
    </div>
  );
};

const renderItem = (item: T_ParagraphBannerSliderSlideDesktop, index: number) : JSX.Element => (
  <div key={index} className="embla__slide relative w-full h-full flex-grow-0 flex-shrink-0">
    <MediaImageBgImg mediaImage={item.field_media} top />
    <div
      className="relative flex h-full flex-col justify-center space-y-5"
      style={{background: '#18234129', backgroundBlendMode: 'multiply'}}
    >
      <Section backgroundColor={'unset'} fullHeight>
        <MaxWidthWrapper>
          <div className="relative flex h-full flex-col justify-center space-y-5">
            <TitleWithTag
              field={item.field_title_with_tag}
              className="font-cognito text-title-1-display leading-none h-25"
            />
            <p className="text-big-white" dangerouslySetInnerHTML={{__html: item.field_body.processed}} />
            {item.field_one_link && (
              <Anchor
                href={item.field_one_link.url}
                className="text-big-bold-white"
              >
                {item.field_one_link.title}
              </Anchor>
            )}
            <_Footer {...item} />
          </div>
        </MaxWidthWrapper>
      </Section>
    </div>
  </div>
)

const BlockBannerSliderDesktop = (props: T_ParagraphBannerSlider): JSX.Element => {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi]);

  return (
    <div className="relative h-175 text-white">
      <div className="embla h-175 overflow-hidden" ref={emblaRef}>
        <div className="embla__container flex h-full">
          {(props.field_slides as T_ParagraphBannerSliderSlideDesktop[]).map((item, index) => renderItem(item, index))}
        </div>
        <img
          className="absolute right-0 bottom-0"
          src="/assets/images/desktop/ribbon-slider.png"
        />
        <div className="absolute inset-0 max-w-screen-xl mx-auto p-5 flex items-center justify-between pointer-events-none">
          <HeroHomeSearchForm/>
          <button
            className="embla__prev absolute left-0 z-10 transition-all transform hover:scale-110 pointer-events-auto"
            onClick={scrollPrev}
          >
            <img src="/assets/icons/arrow-left-circle-transparent.svg" alt=""/>
          </button>
          <button
            className="embla__next absolute right-0 z-10 transition-all transform hover:scale-110 pointer-events-auto"
            onClick={scrollNext}
          >
            <img src="/assets/icons/arrow-right-circle-transparent.svg" alt=""/>
          </button>
        </div>
      </div>
    </div>
  )
};

export default BlockBannerSliderDesktop;
