import cn from "classnames";
import {useRoute} from "@/src/ui/hooks/useRoute";
import Anchor from "@/src/ui/components/generic/Anchor";
import UrlUtils from "@/src/core/app/utils/url";
import {T_TaxonomyTermLink} from "@/src/core/app/domain/models/taxonomy/T_TaxonomyTermLink";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import UiTaxonomyTermUtils from "@/src/ui/utils/taxonomy-term";

interface _Props {
  titleWithTag?: CMS_FieldTextWithTag
  categories: T_TaxonomyTermLink[]
}

const BlogCategoryListDesktop = (props: _Props) => {
  const route = useRoute();

  const categories = props.categories.map(item => ({
    ...item,
    isActive: UrlUtils.getCmsLinkUrlFromPath(item.path) === route.path,
  }));

  return (
    <section className="space-y-7.5 pb-16">
      <TitleWithTag
        className="title-4 font-calistoga"
        field={props.titleWithTag}
      />
      <nav>
        <ul className="space-y-2.5 text-brand-2 text-xs uppercase">
          {categories.map((item, index) => (
            <li
              key={item.id}
              className={cn(
                "hover:underline",
                item.isActive && "font-bold text-brand-1"
              )}
            >
              <Anchor href={UiTaxonomyTermUtils.getUrl(item)}>
                {item.title}
              </Anchor>
            </li>
          ))}
        </ul>
      </nav>
      {/*<Button text="Ver todos los posts" style="secondary" bold />*/}
    </section>
  )
}

export default BlogCategoryListDesktop;
