import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {KID_MAX_AGE} from "@/src/core/app/utils/search-params";
import { SearchParamsContext } from "@/src/core/app/domain/@types/SearchParamsContext";

export const useSearchFormKidsMaxAge = () : number => {
  const searchForm = useSearchFormProvider(state => state);

  if (!searchForm.isInitiated) {
    return KID_MAX_AGE;
  }

  if (!searchForm.location) {
    return KID_MAX_AGE;
  }

  const hotelCode = searchForm.location.id;
  const hotelTree = (searchForm.context as SearchParamsContext).hotelTree;
  const hotel = HotelTreeUtils.getHotel(hotelCode, hotelTree);
  return hotel.kids_max_age;
}
