import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_TaxonomyTermBlogCategoryDetail} from "@/src/core/app/domain/models/taxonomy/T_TaxonomyTermBlogCategoryDetail";
import BlogCategoryListMobile from "@/src/ui/components/blog/BlogCategoryList/mobile";
import _Body from "@/src/ui/components/routes/Route_BlogDetail/mobile/body";
import _Related from "@/src/ui/components/routes/Route_BlogDetail/mobile/related";
import Section from "@/src/ui/components/generic/Section/Section";
import NodeBlogTeaserListMobile from "@/src/ui/components/blog/NodeBlogTeaserList/mobile";
import Pagination from "@/src/ui/components/generic/Pagination";
import PageHeader from "@/src/ui/components/generic/PageHeader";
import PaginationWrapper from "@/src/ui/components/generic/PaginationWrapper";
import React from "react";
import BreadcrumbWrapper from "@/src/ui/components/generic/BreadcrumbWrapper";

const Route_BlogCategoryMobile = (props: RouteProps<T_TaxonomyTermBlogCategoryDetail>) : JSX.Element => {
  const currentTaxonomy = props.routeData.data.categories.find(item => item.id === props.routeData.data.id) || null;
  return (
    <>
      <PageHeader header={props.routeData.data.page_header} />
      <BreadcrumbWrapper breadcrumb={props.routeData.data.breadcrumb} />
      <BlogCategoryListMobile
        categories={props.routeData.data.categories}
        currentTaxonomy={currentTaxonomy}
      />
      <Section paddingY="pt-10 pb-7.5">
        <NodeBlogTeaserListMobile
          titleWithTag={{
            text: props.routeData.data.name,
            tag: 'p'
          }}
          items={props.routeData.data.posts}
        />
        <PaginationWrapper
          className="flex justify-center"
          pager={props.routeData.data.pager}
        />
      </Section>
    </>
  );
}

export default Route_BlogCategoryMobile;
