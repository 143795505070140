import {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_NodePage2Cols} from "@/src/core/app/domain/models/node/T_NodePage2Cols";
import {useBookingManageProvider} from "@/src/ui/providers/booking-manage.provider";
import BookingManage from "src/ui/components/booking-manage/BookingManage";
import BlockListComponent from "@/src/ui/components/generic/BlockListComponent";

const _Content = (props: RouteProps<T_NodePage2Cols>) => {
  const state = useBookingManageProvider(state => state);

  return state.reservationManageData ? (
    <BookingManage
      reservationManageData={state.reservationManageData}
      onCancel={(reservationId: string) => state.onCancel()}
    />
  ): (
    <BlockListComponent blocks={props.routeData.data.field_blocks} />
  );
};

export default _Content;
