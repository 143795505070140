import cn from "classnames";
import { ReactNode } from "react";

export interface FormBoxProps {
  children: ReactNode
  title: string | ReactNode
  /**
   * Tailwind class that specifies the background color of the header of the box.
   */
  bgColor?: string
}

const FormBox = ({
  children,
  title,
  bgColor = "bg-gray-20",
}: FormBoxProps): JSX.Element => (
  <article className="border border-gray-40 rounded-sm divide-y divide-gray-40">
    <h3 className={cn("px-4 py-3.5 text-big-bold", bgColor)}>{title}</h3>
    {children}
  </article>
);

export default FormBox;
