import {_FormProps} from "@/src/ui/components/forms/EventForm/types";
import CollapsedFormBox from "@/src/ui/components/desktop/CollapsedFormBox";
import FormInputTextarea from "@/src/ui/components/formik/FormInputTextarea";
import FormInputCheckboxGroup from "@/src/ui/components/formik/FormInputCheckboxGroup";
import EventUtils from "@/src/core/app/utils/event";
import cn from "classnames";
import {useDevice} from "@/src/ui/hooks/useDevice";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";

const _Services = (props: _FormProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  return (
    <CollapsedFormBox title={t('EventForm._Services.title')}>
      <div className="px-5 py-3.75 space-y-7.5">
        <div className="space-y-3.75">
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">
              {t('EventForm._Services.multimedia_label')}:</p>
            <div className="flex-1 space-y-2.5">
              <FormInputCheckboxGroup
                name='servicesMultimedia'
                optionsWrapperClassName={'grid grid-cols-2 gap-1.5'}
                options={I18nUtils.translateFormInputSelectOptions(
                  EventUtils.getMultimediaServicesSelectOptions(), t
                )}
              />
            </div>
          </div>
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">
              {t('EventForm._Services.other_label')}:
            </p>
            <div className="flex-1 space-y-2.5">
              <FormInputCheckboxGroup
                name='servicesOther'
                optionsWrapperClassName={'grid grid-cols-2 gap-1.5'}
                options={I18nUtils.translateFormInputSelectOptions(
                  EventUtils.getOtherServicesSelectOptions(), t
                )}
              />
            </div>
          </div>
          <div className={cn(
            device.isDesktop && "flex",
            device.isMobile && "space-y-2.5",
          )}>
            <p className="flex-1 text-medium-bold text">
              {t('EventForm._Services.additional_info_label')}: </p>
            <div className="flex-1">
              <FormInputTextarea name='servicesAdditionalInfo' />
            </div>
          </div>
        </div>
      </div>
    </CollapsedFormBox>
  );
}

export default _Services;
