interface _MainMenuTitleProps {
  title: string
}

const _MainMenuTitle = ({title}: _MainMenuTitleProps) => (
  <span className="font-calistoga">
    {title}
  </span>
);

export default _MainMenuTitle;
