import {T_ParagraphHotelListByTag} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelListByTag";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import HotelTeaserListMobile from "@/src/ui/components/hotel/HotelTeaserList/mobile";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const BlockHotelListByTagMobile = (props: T_ParagraphHotelListByTag) => (
  <Section paddingY="pt-7.5 pb-15" backgroundColor={props.field_background_color}>
    <MaxWidthWrapper>
      {props.field_title_with_tag && (
        <TitleWithTag
          className="title-4 pb-5 font-calistoga"
          field={props.field_title_with_tag}
          suffix={`(${props.hotelTeasers.length})`}
        />
      )}
      <HotelTeaserListMobile hotelTeasers={props.hotelTeasers} />
    </MaxWidthWrapper>
  </Section>
);


export default BlockHotelListByTagMobile;