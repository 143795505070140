import {T_ParagraphResidentsList} from "@/src/core/app/domain/models/paragraph/T_ParagraphResidentsList";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockResidentsListDesktop from "@/src/ui/components/blocks/BlockResidentsList/desktop";
import BlockResidentsListMobile from "@/src/ui/components/blocks/BlockResidentsList/mobile";

const BlockResidentsList = (props: T_ParagraphResidentsList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockResidentsListMobile : BlockResidentsListDesktop;
  return (
    <_Component {...props} />
  );
};

export default BlockResidentsList;
