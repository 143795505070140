import {
  T_ParagraphHotelList
} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelList";
import _List from "@/src/ui/components/blocks/BlockHotelList/desktop/by-display/list";
import _Grid from "@/src/ui/components/blocks/BlockHotelList/desktop/by-display/grid";
import {CMS_ParagraphHotelList_Display} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphHotelList";

const BlockHotelListDesktop = (props: T_ParagraphHotelList) => {
  const _Component = props.field_hotel_list_display === CMS_ParagraphHotelList_Display.LIST ? _List : _Grid;
  return (
    <_Component {...props} />
  );
}

export default BlockHotelListDesktop;
