import TitledBox from "@/src/ui/components/generic/TitledBox";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import CountryUtils from "@/src/core/app/utils/country";
import FormUtils from "@/src/ui/utils/form";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import {_FormProps} from "@/src/ui/components/forms/EventForm/types";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";

const _Person = (props: _FormProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  return (
    <TitledBox title={t('EventForm._Person.title')}>
      <div className="px-5 py-3.75 space-y-7.5">
        <div className="space-y-3.75">
          <div className={cn(
            device.isDesktop && "flex space-x-5",
            device.isMobile && "space-y-2.5",
          )}>
            <FormInputText
              name='firstName'
              label={t('EventForm._Person.first_name_label') + " *"}
              validate={FormUtils.validateIsRequired}
            />
            <FormInputText
              name='lastName'
              label={t('EventForm._Person.last_name_label') + " *"}
              validate={FormUtils.validateIsRequired}
            />
          </div>
          <div className={cn(
            device.isDesktop && "flex space-x-5",
            device.isMobile && "space-y-2.5",
          )}>
            <FormInputText
              name='email'
              label={t('EventForm._Person.email_label') + " *"}
              type='email'
              validate={FormUtils.validateIsRequired}
            />
            <FormInputText
              name='phoneNumber'
              label={t('EventForm._Person.phone_number_label') + " *"}
              validate={FormUtils.validateIsRequired}
            />
          </div>
          <div className={cn(
            device.isDesktop && "flex space-x-5",
            device.isMobile && "space-y-2.5",
          )}>
            <FormInputText
              name='iat'
              label={t('EventForm._Person.iat_label')}
            />
            <FormInputSelect
              name='country'
              label={t('EventForm._Person.country_label') + " *"}
              emptyOption={'- ' + t('EventForm._Person.country_empty_option')}
              options={CountryUtils.getSelectOptions(props.countries)}
              validate={FormUtils.validateIsRequired}
            />
          </div>
        </div>
      </div>
    </TitledBox>
  );
}

export default _Person;