const EmailUtils = {
  obfuscate: (value: string) => {
    const parts = value.split("@");
    return [
      parts[0].substring(0, 2),
      Array(parts[0].length - 2).join("*"),
      '@',
      parts[1],
    ].join('');
  },
  isValid: (value: string) : boolean => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (value.match(regex)) {
      return true;
    }
    return false;
  },
};

export default EmailUtils;
