import {T_MediaImage} from "@/src/core/app/domain/models/media/T_MediaImage";
import UiMediaUtils from "@/src/ui/utils/media";

interface _Props {
  mediaImage: T_MediaImage | null
  className?: string
}

const MediaImage = (props: _Props) => {
  if (!props.mediaImage) {
    return null;
  }

  return (
    <img
      className={props.className}
      {...UiMediaUtils.getImgPropsFromMediaImage(props.mediaImage)}
    />
  );
};

export default MediaImage;