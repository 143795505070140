import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockIconListDesktop from "@/src/ui/components/blocks/BlockIconList/desktop";
import BlockIconListMobile from "@/src/ui/components/blocks/BlockIconList/mobile";
import {T_ParagraphIconList} from "@/src/core/app/domain/models/paragraph/T_ParagraphIconList";


const BlockIconList = (props: T_ParagraphIconList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockIconListMobile : BlockIconListDesktop;
  return (
    <_Component {...props}/>
  );
}
export default BlockIconList;
