import _Display1Col from "@/src/ui/components/blocks/BlockLoungesList/desktop/by-display/1col";
import _Display2Cols from "@/src/ui/components/blocks/BlockLoungesList/desktop/by-display/2cols";
import _Display3Cols from "@/src/ui/components/blocks/BlockLoungesList/desktop/by-display/3cols";
import _Display4Cols from "@/src/ui/components/blocks/BlockLoungesList/desktop/by-display/4cols";
import Section from "@/src/ui/components/generic/Section/Section";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {T_ParagraphLoungesList} from "@/src/core/app/domain/models/paragraph/T_ParagraphLoungesList";
import {CMS_ParagraphLoungesList_Display} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphLoungesList";

const components = {
  [CMS_ParagraphLoungesList_Display["1col"]]: _Display1Col,
  [CMS_ParagraphLoungesList_Display["2cols"]]: _Display2Cols,
  [CMS_ParagraphLoungesList_Display["3cols"]]: _Display3Cols,
  [CMS_ParagraphLoungesList_Display["4cols"]]: _Display4Cols,
}

const BlockLoungesListDesktop = (props: T_ParagraphLoungesList) => {
  const _Component = components[props.field_lounges_display];
  return (
    <Section paddingY="py-5" backgroundColor={props.field_background_color}>
      <MaxWidthWrapper>
        <div className="space-y-5">
          <_Component {...props} />
        </div>
      </MaxWidthWrapper>
    </Section>
  );
}

export default BlockLoungesListDesktop;