export enum CMS_OfferEntity_PriceType {
  NO_PRICE = 'no-price',
  FIXED = 'fixed',
  RANGE = 'range',
  DISCOUNT = 'discount',
}

export interface CMS_OfferEntityWithPrice {
  price_type: CMS_OfferEntity_PriceType
  price_from: number
  price_to?: number
  price_discount?: number
  field_price_conditions?: string
}
