import {T_ParagraphExperiencesList} from "@/src/core/app/domain/models/paragraph/T_ParagraphExperiencesList";
import BlockExperiencesListDesktop from "@/src/ui/components/blocks/BlockExperiencesList/desktop";
import {useDevice} from "@/src/ui/hooks/useDevice";
import BlockExperiencesListMobile from "@/src/ui/components/blocks/BlockExperiencesList/mobile";

const BlockExperiencesList = (props: T_ParagraphExperiencesList) => {
  const device = useDevice();
  const _Component = device.isMobile ? BlockExperiencesListMobile : BlockExperiencesListDesktop;
  return (
    <_Component {...props} />
  );
};

export default BlockExperiencesList;
