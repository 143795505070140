import Card from "../../generic/Card";
import Title4 from "../../generic/Title4";
import Paragraph from "../../generic/Paragraph";
import Button from "../../generic/Button";
import {T_NodeExperienceTeaser} from "@/src/core/app/domain/models/node/T_NodeExperienceTeaser";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Anchor from "@/src/ui/components/generic/Anchor";
import ExperienceUtils from "@/src/core/app/utils/experience";
import {useTranslation} from "next-i18next";

type _Props = {
  experience: T_NodeExperienceTeaser
}

const NodeExperienceTeaserDesktop = ({experience}: _Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Card
      mediaImage={experience.field_experience_teaser}
      className='bg-img-blue-shade-card'
    >
      <div className="pb-2.5 font-calistoga">
        <Title4>{experience.title}</Title4>
      </div>
      <Paragraph
        size="medium"
        className="text-white pb-2.5 hover-show-child-hidden"
      >
        {t('NodeExperienceTeaser._Index.subtitle', {
          count: experience.hotelCount,
        })}
      </Paragraph>
      <Paragraph
        size="medium"
        className="text-brand-3-40 pb-2.5 hover-show-child"
      >
        {t('NodeExperienceTeaser._Index.subtitle', {
          count: experience.hotelCount,
        })}
      </Paragraph>
      {experience.field_teaser && (
        <WrapperDiv
          size="medium"
          className="pb-5 hover-show-child"
          dangerouslySetInnerHTML={{__html: experience.field_teaser.processed}}
        />
      )}
      <div className="w-32 px-1 hover-show-child">
        <Anchor href={ExperienceUtils.getLinkUrl(experience)}>
          <Button text={t('NodeExperienceTeaser._Index.detail_link_label')} style="secondary-white"/>
        </Anchor>
      </div>
    </Card>
  );
}

export default NodeExperienceTeaserDesktop;
