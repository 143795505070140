import ShimmerEffect from "@/src/ui/components/generic/ShimmerEffect";

const HotelRoomTeaserLoadingDesktop = () => {
  return (
    <article className="space-y-2.5">
      <div className="flex space-x-5">
        <ShimmerEffect className='h-57.5 w-95' />
        <div className="relative flex-1 overflow-hidden space-y-3.5">
          <div className="flex justify-between items-center">
            <ShimmerEffect className="h-5 w-80" />
            <ShimmerEffect className="h-9 w-40 rounded-full" />
          </div>
          <ShimmerEffect className="h-5 w-32" />
          <ShimmerEffect className="h-15 w-full" />
          <ShimmerEffect className="h-3.5 w-15" />
        </div>
      </div>
      <ShimmerEffect className="h-9 w-full" />
      <ShimmerEffect className="h-9 w-full" />
    </article>
  )
}

export default HotelRoomTeaserLoadingDesktop;
