import {T_Notification} from "@/src/ui/@types/T_Notification";
import NotificationMessage from "src/ui/components/generic/NotificationMessage";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import {useTranslation} from "next-i18next";


const ModalNotification = (): JSX.Element | null => {
  const { t } = useTranslation();
  const modal = useModalProvider(state => state);
  const modalData = modal.modalData as T_Notification;

  const onCloseModal = () => {
    if (modalData.onClose) {
      modalData.onClose();
      return;
    }
    modal.closeModal();
  };

  return (
    <ModalLayout title={t(modalData.title as any)} className="w-171.5" onCloseModal={onCloseModal}>
      <div className='px-3.75 sm:px-5'>
        <NotificationMessage
          type={modalData.type}
          message={t(modalData.message as any)}
        />
      </div>
    </ModalLayout>
  );
}

export default ModalNotification;
