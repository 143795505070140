import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import React from "react";
import {T_Lounge} from "@/src/core/app/domain/models/lounge/T_Lounge";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";

interface _Props {
  item: T_Lounge
  className?: string
}

const _TextFields = (props: _Props) => {
  const modalState = useModalProvider(state => state);
  return (
    <div className={props.className}>
      {props.item.field_title && (
        <h3 className="text-big-bold">{props.item.field_title}</h3>
      )}
      {props.item.field_body_teaser && (
        <WrapperDiv
          className='text-sm text-brand-2-90 space-y-4 has-styled-li Dingus-Formatted'
          dangerouslySetInnerHTML={{__html: props.item.field_body_teaser.processed}}
        />
      )}
      <div className="pt-2.5">
        <div
          className="items-center text-sm text-brand-2-90 hover:underline flex cursor-pointer"
          onClick={() => modalState.toggleModal(ModalKey.LOUNGE, {
            lounge: props.item,
          })}
        >
          <span>Ver salón</span>
          <img className="inline ml-2" src="/assets/icons/arrow.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default _TextFields;
