import {PropsWithChildren} from "react";
import {RouteContextProvider, RouteContextProviderProps} from "@/src/ui/context/RouteContext";
import {useAppInitiatedEvent} from "@/src/ui/hooks/useAppInitiatedEvent";
import {RouteAppWrapper} from "@/src/ui/@types/RouteProps";
import {LayoutType} from "@/src/ui/@types/layout/Layout";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import LayoutUtils from "@/src/ui/utils/layout";

type _Props = PropsWithChildren<RouteContextProviderProps> & {
  routeAppWrapper?: RouteAppWrapper
}

const DefaultRouteAppWrapper = (props: PropsWithChildren) => (
  <>
    {props.children}
  </>
);

const App = (props: _Props): JSX.Element | null => {
  const hotelTree = LayoutUtils.isLayoutWithSearchForm(props.routeProps.layout.type)
    ? (props.routeProps.layoutData as UI_LayoutGeneric).hotelTree
    : null
  ;
  useAppInitiatedEvent(
    props.routeProps.route,
    props.routeProps.routeData,
    props.routeProps.appInfo,
    hotelTree
  );

  const RouteAppWrapper = props.routeAppWrapper ?? DefaultRouteAppWrapper;

  return (
    <RouteContextProvider routeProps={props.routeProps}>
      <RouteAppWrapper {...props.routeProps}>
        {props.children}
      </RouteAppWrapper>
    </RouteContextProvider>
  );
}

export default App;
