import {UserRegisterFormValues} from "@/src/core/app/domain/forms/UserRegisterFormValues";
import {UserRegisterState} from "@/src/ui/view_models/user-register.state";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import UserRegisterUtils from "@/src/core/app/utils/user-register";
import {FormikHelpers} from "formik/dist/types";
import {ModalState} from "@/src/ui/view_models/modal.state";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {UserRegisterError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserRegisterError";
import UserUtils from "@/src/core/app/utils/user";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import SentryUtils from "@/src/common/utils/sentry";
import UserLoginUtils from "@/src/core/app/utils/user-login";
import {TFunction} from "react-i18next";
import {T_SitePageUrls} from "@/src/core/app/domain/models/site/T_SitePageUrls";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

const UserRegisterStep3Form = {
  validations: [],
  onSubmit: async (
    appInfo: AppInfo,
    values: UserRegisterFormValues,
    formikHelpers: FormikHelpers<UserRegisterFormValues>,
    t: TFunction,
    modal: ModalState,
    state: UserRegisterState,
    countries: T_Country[],
    sitePageUrls: T_SitePageUrls,
  ) => {
    formikHelpers.setSubmitting(true);
    modal.toggleModal(ModalKey.LOADER, {
      message: 'global.generic_loader.message'
    });
    state.setData(values);
    // const input = UserRegisterUtils.buildUserRegisterInput(state.getData(), props.countries, props.newsletterCategories);
    const input = UserRegisterUtils.buildUserRegisterInput(state.getData(), countries);

    const userRegisterResponse = await UserRegisterUtils.submit(appInfo, input);
    if (!userRegisterResponse.isOk) {
      UserRegisterUtils.handleError(userRegisterResponse.error as NetworkResponseError<UserRegisterError>, values, formikHelpers, t, modal, state);
      return;
    }

    // login and navigation
    const userLoginResponse = await UserLoginUtils.login(input.email, input.password);
    if (!userLoginResponse.isOk) {
      SentryUtils.captureMessage('User login KO after registration OK');
      modal.toggleModal(ModalKey.NOTIFICATION, FormErrorUtils.getUnexpectedError().notification);
      return;
    }

    UserUtils.goToUserHome(sitePageUrls);
  }
}

export default UserRegisterStep3Form;
