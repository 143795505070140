import {T_ParagraphInfoCards} from "@/src/core/app/domain/models/paragraph/T_ParagraphInfoCards";
import Section from "@/src/ui/components/generic/Section/Section";
import _TextFields from "@/src/ui/components/blocks/BlockInfoCards/generic/_TextFields";
import UiMediaUtils from "@/src/ui/utils/media";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import React from "react";

const BlockInfoCardsMobile = (props: T_ParagraphInfoCards) => (
  <Section paddingY="my-7.5">
    <TitleWithTag
      className="title-4 pb-5 font-calistoga"
      field={props.field_title_with_tag}
    />
    <ul className="space-y-5">
      {props.field_info_cards_items.map(item => (
        <li className="flex-1 p-3.75 border border-gray-40" key={item.id}>
          <article className='space-y-5'>
            <img
              className="w-full border border-gray-40"
              {...UiMediaUtils.getImgPropsFromMediaImage(item.ui_cpt_field_media)}
            />
            <_TextFields
              className='space-y-2.5'
              item={item}
            />
          </article>
        </li>
      ))}
    </ul>
  </Section>
);


export default BlockInfoCardsMobile;