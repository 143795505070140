import {Formik} from "formik";
import {PropsWithChildren} from "react";
import FormUtils from "@/src/ui/utils/form";
import _paymentDataValidation from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/validation/payment-data";
import _userRegisterValidation from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/validation/user-register";
import _commonValidation from "@/src/ui/components/checkout/generic/CheckoutCustomerInfoForm/validation/common";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import {CheckoutCustomerInfoFormValues} from "@/src/core/app/domain/forms/CheckoutCustomerInfoFormValues";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {MakeBookingOutput,} from "@/src/core/app/domain/booking/@types/Output/MakeBookingOutput";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import CheckoutStepCustomerInfoErrors from "@/src/core/app/domain/checkout/@types/FormErrorMap/checkout-step-customer-info";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import CountryUtils from "@/src/core/app/utils/country";
import SentryUtils from "@/src/common/utils/sentry";
import UserUtils from "@/src/core/app/utils/user";
import {FormValidationFn} from "@/src/ui/@types/form/FormValidationFn";
import {useTranslation} from "next-i18next";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

interface _Props {
  countries: T_Country[]
  isPaymentDataRequired: boolean
  isRegistrationRequired: boolean
}

const _Formik = (props: PropsWithChildren<_Props>) => {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const userState = useUserProvider(state => state);
  const modalState = useModalProvider(state => state);
  const checkout = useCheckoutProvider(state => state);

  if (!userState.isInitiated) {
    return null;
  }

  let validations : FormValidationFn[] = [_commonValidation];
  const initialValues : CheckoutCustomerInfoFormValues = {
    firstName: userState.userData?.firstName || '',
    lastName: userState.userData?.lastName || '',
    email: userState.userData?.email || '',
    phoneNumber: userState.userData?.phoneNumber || '',
    country: userState.userData ? CountryUtils.findByUuid(props.countries, userState.userData.country).iso2 : 'ES',
    termsAndConditions: false,
  };

  if (props.isPaymentDataRequired) {
    initialValues.ccFirstName = '';
    initialValues.ccLastName = '';
    initialValues.ccType = 'visa';
    initialValues.ccNumber = '';
    initialValues.ccCVV = '';
    initialValues.ccMonth = '';
    initialValues.ccYear = '';

    validations.push(_paymentDataValidation);
  }

  if (props.isRegistrationRequired) {
    initialValues.userPassword1 = '';
    initialValues.userPassword2 = '';
    validations.push(_userRegisterValidation);
  }

  return (
    <Formik<CheckoutCustomerInfoFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => FormUtils.runValidations(t, values, validations)}
      onSubmit={async (values, formikHelpers) => {
        const input = CheckoutUtils.buildMakeBookingInput(
          checkout,
          values,
          props.isRegistrationRequired,
          props.isPaymentDataRequired
        );
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'Solo será un momento...'
        });
        const response = await CheckoutUtils.makeBooking(appInfo.langcode, input);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: CheckoutStepCustomerInfoErrors,
            logFn: (reason) => FormErrorUtils.logNetworkError('Checkout form error', reason, networkError),
          });
          return;
        }

        if (props.isRegistrationRequired) {
          const userData = await UserUtils.loadUser();
          if (!userData) {
            SentryUtils.captureMessage('Checkout form - Load user failed');
            return CheckoutUtils.onMakeBookingOk(appInfo.langcode, response.data as MakeBookingOutput);
          }

          userState.setUserData(userData);
        }

        return CheckoutUtils.onMakeBookingOk(appInfo.langcode, response.data as MakeBookingOutput);
      }}
    >
      {props.children}
    </Formik>
  )
}

export default _Formik;
