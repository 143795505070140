import {T_ParagraphHotelRoomsPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelRoomsPromoted";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import HotelUtils from "@/src/core/app/utils/hotel";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import RouteData from "@/src/core/app/domain/@types/RouteData";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import _Room from "@/src/ui/components/blocks/BlockHotelRoomsPromoted/mobile/_Room";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

const BlockHotelRoomsPromotedMobile = (props: T_ParagraphHotelRoomsPromoted) => {
  const { t } = useTranslation();
  const routeData = useRouteData() as RouteData<T_HotelSection>;
  const href = HotelUtils.getSectionUrl(routeData.data.ui_hotel_common_info.cpt_hotel_section_rooms);
  return (
    <div className="py-5">
      <hgroup className="px-5 text-brand-2 space-y-2.5">
        <p className="title-5">{props.field_section_name}</p>
        <TitleWithTag
          field={props.field_title_with_tag}
          className="title-4 font-calistoga"
        />
      </hgroup>
      <ul className="p-5 flex overflow-x-auto space-x-2.5">
        {props.field_rooms.map((room, index) => (
          <li
            key={index}
            className="flex-none h-50 w-50 cursor-pointer"
          >
            <_Room {...room}/>
          </li>
        ))}
      </ul>
      {href && (
        <div className="px-5">
          <ArrowLink href={href}>
            {t('BlockHotelRoomsPromoted._Index.view_all')}
          </ArrowLink>
        </div>
      )}
    </div>
  );
}


export default BlockHotelRoomsPromotedMobile;