import MaxWidthWrapper from "../MaxWidthWrapper";
import Section from "../Section/Section";
import cn from "classnames";

interface StepsProps {
  step: number;
  steps: string[];
  paddingY?: string;
}

const ProcessSteps = ({
  steps,
  step,
  paddingY = "pt-5 pb-7.5 sm:pt-7.5",
}: StepsProps): JSX.Element => (
  <Section paddingY={paddingY}>
    <MaxWidthWrapper>
      <ul className="flex justify-between sm:justify-start space-x-7.5">
        {steps.map((text, index) => (
          <li
            key={text}
            className="flex-1 sm:flex-none flex flex-col sm:flex-row items-center space-y-1 sm:space-y-0 sm:space-x-2.5 text-brand-2 text-xs sm:text-sm text-center"
          >
            <img
              className={cn("h-7.5 w-auto", index + 1 > step && "opacity-40")}
              src={`/assets/icons/${index + 1}.svg`}
            />
            <span
              className={cn(
                index + 1 === step && "font-bold",
                index + 1 > step && "text-brand-2-40"
              )}
            >
              {text}
            </span>
          </li>
        ))}
      </ul>
    </MaxWidthWrapper>
  </Section>
);

export default ProcessSteps;
