import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import {useTranslation} from "next-i18next";

interface _Props {
  hotelRoom: T_HotelRoomTeaser
}

const HotelRoomHeaderHeaderInfoDesktop = ({
  hotelRoom,
}: _Props): JSX.Element => {
  const { t } = useTranslation();
  const surface = HotelRoomUtils.getSurface(hotelRoom);
  return (
    <ul className="flex items-center space-x-7.5 text-sm text-brand-2">
      <li className="flex items-center">
        <h4 className="sr-only">{t('HotelRoomHeaderHeaderInfo._Index.capacity_label')}</h4>
        <img className="h-4.5 w-auto mr-2.5" src="/assets/icons/person-brand-2.svg" alt="" />
        <span>{HotelRoomUtils.getCapacity(hotelRoom)}</span>
      </li>
      {surface && (
        <li className="flex items-center">
          <h4 className="sr-only">{t('HotelRoomHeaderHeaderInfo._Index.surface_label')}</h4>
          <img
            className="h-4.5 w-auto mr-2.5"
            src="/assets/icons/surface-circle-brand-2.svg"
            alt=""
          />
          <span dangerouslySetInnerHTML={{__html: surface}} />
        </li>
      )}
    </ul>
  );
}

export default HotelRoomHeaderHeaderInfoDesktop;
