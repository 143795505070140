export enum CMS_OfferEntity_OccupationType {
  OPEN = 'open',
  FIXED = 'fixed',
}

export interface CMS_OfferEntityWithOccupation {
  occupation_type: CMS_OfferEntity_OccupationType
  occupation_adults: number
  occupation_children: number
}
