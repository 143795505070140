import {CMS_ParagraphType} from "@/src/core/app/domain/cms/@types/entities/CMS_ParagraphType";

import BlockBannerSlider from "@/src/ui/components/blocks/BlockBannerSlider";
import BlockElbaFidelity from "@/src/ui/components/blocks/BlockElbaFidelity";
import BlockOffersCategoryPromoted from "@/src/ui/components/blocks/BlockOffersCategoryPromoted";
import BlockResidentsList from "@/src/ui/components/blocks/BlockResidentsList";
import BlockBlogList from "@/src/ui/components/blocks/BlockBlogList";
import BlockExperiencesList from "@/src/ui/components/blocks/BlockExperiencesList";
import BlockMapInfoSlider from "@/src/ui/components/blocks/BlockMapInfoSlider";
import BlockHotelRoomList from "@/src/ui/components/blocks/BlockHotelRoomList";
import BlockInfoSquared from "@/src/ui/components/blocks/BlockInfoSquared";
import BlockLoginForm from "@/src/ui/components/blocks/BlockLoginForm";
import BlockManageBookingForm from "@/src/ui/components/blocks/BlockManageBookingForm";
import BlockMenuAccount from "@/src/ui/components/blocks/BlockMenuAccount";
import BlockMyBookingList from "@/src/ui/components/blocks/BlockMyBookingList";
import BlockInvalid from "@/src/ui/components/blocks/BlockInvalid";
import BlockUserEditForm from "@/src/ui/components/blocks/BlockUserEditForm";
import BlockUserPasswordChangeForm from "@/src/ui/components/blocks/BlockUserPasswordChangeForm";
import BlockUserTmsInfo from "@/src/ui/components/blocks/BlockUserTmsInfo";
import BlockUserTmsAssignForm from "@/src/ui/components/blocks/BlockUserTmsAssignForm";
import BlockInfoBlock from "@/src/ui/components/blocks/BlockInfoBlock";
import BlockIconList from "@/src/ui/components/blocks/BlockIconList";
import BlockHotelContact from "@/src/ui/components/blocks/BlockHotelContact";
import BlockHotelListFiltrable from "@/src/ui/components/blocks/BlockHotelListFiltrable";
import BlockHotelsMap from "@/src/ui/components/blocks/BlockHotelsMap";
import BlockHotelRoomsPromoted from "@/src/ui/components/blocks/BlockHotelRoomsPromoted";
import BlockHotelListByDestination from "@/src/ui/components/blocks/BlockHotelListByDestination";
import BlockMultiGallery from "src/ui/components/blocks/BlockMultiGallery";
import BlockSimpleGallery from "@/src/ui/components/blocks/BlockSimpleGallery";
import BlockInfoCards from "@/src/ui/components/blocks/BlockInfoCards";
import BlockAlertBlock from "@/src/ui/components/blocks/BlockAlertBlock";
import BlockLinksImageList from "@/src/ui/components/blocks/BlockLinksImageList";
import BlockInfoTextImage from "@/src/ui/components/blocks/BlockInfoTextImage";
import BlockLinksBlock from "@/src/ui/components/blocks/BlockLinksBlock";
import BlockLoungesList from "@/src/ui/components/blocks/BlockLoungesList";
import BlockHotelListByExperience from "@/src/ui/components/blocks/BlockHotelListByExperience";
import BlockHotelListByTag from "@/src/ui/components/blocks/BlockHotelListByTag";
import BlockDestinationList from "@/src/ui/components/blocks/BlockDestinationList";
import BlockHotelListByResident from "@/src/ui/components/blocks/BlockHotelListByResident";
import BlockDestinationListByResident from "@/src/ui/components/blocks/BlockDestinationListByResident";
import BlockBlogSection from "@/src/ui/components/blocks/BlockBlogSection";
import BlockBlogPromoted from "@/src/ui/components/blocks/BlockBlogPromoted";
import BlockHotelList from "@/src/ui/components/blocks/BlockHotelList";
import BlockJobOfferInfo from "@/src/ui/components/blocks/BlockJobOfferInfo";
import BlockJobOfferForm from "@/src/ui/components/blocks/BlockJobOfferForm";
import BlockEventForm from "@/src/ui/components/blocks/BlockEventForm";
import BlockContactGeneralInfo from "@/src/ui/components/blocks/BlockContactGeneralInfo";
import BlockContactForm from "@/src/ui/components/blocks/BlockContactForm";
import BlockHotelsOffersPromoted from "@/src/ui/components/blocks/BlockHotelsOffersPromoted";
import BlockHotelOffersSearchForm from "@/src/ui/components/blocks/BlockHotelOffersSearchForm";
import BlockHotelOffersResult from "@/src/ui/components/blocks/BlockHotelOffersResult";
import BlockHotelOffers from "@/src/ui/components/blocks/BlockHotelOffers";
import BlockHtmlBlock from "@/src/ui/components/blocks/BlockHtmlBlock";


export type BlockElement = (props: any) => JSX.Element | null;

const BlockComponents : Record<CMS_ParagraphType, BlockElement> = {
  [CMS_ParagraphType.BANNER_SLIDER]: BlockBannerSlider,
  [CMS_ParagraphType.BANNER_SLIDER_SLIDE]: BlockInvalid,
  [CMS_ParagraphType.ELBA_FIDELITY]: BlockElbaFidelity,
  [CMS_ParagraphType.OFFERS_CATEGORY_PROMOTED]: BlockOffersCategoryPromoted,
  [CMS_ParagraphType.RESIDENTS_LIST]: BlockResidentsList,
  [CMS_ParagraphType.BLOG_LIST]: BlockBlogList,
  [CMS_ParagraphType.EXPERIENCES_LIST]: BlockExperiencesList,
  [CMS_ParagraphType.MAP_INFO_SLIDER]: BlockMapInfoSlider,
  [CMS_ParagraphType.MAP_INFO_SLIDER_ITEM]: BlockInvalid,
  [CMS_ParagraphType.HOTEL_ROOM_LIST]: BlockHotelRoomList,
  [CMS_ParagraphType.LOGIN_FORM]: BlockLoginForm,
  [CMS_ParagraphType.INFO_SQUARED]: BlockInfoSquared,
  [CMS_ParagraphType.MANAGE_BOOKING_FORM]: BlockManageBookingForm,
  [CMS_ParagraphType.MENU_ACCOUNT]: BlockMenuAccount,
  [CMS_ParagraphType.MY_BOOKING_LIST]: BlockMyBookingList,
  [CMS_ParagraphType.USER_EDIT_FORM]: BlockUserEditForm,
  [CMS_ParagraphType.USER_PASSWORD_CHANGE_FORM]: BlockUserPasswordChangeForm,
  [CMS_ParagraphType.USER_TMS_INFO]: BlockUserTmsInfo,
  [CMS_ParagraphType.USER_TMS_ASSIGN_FORM]: BlockUserTmsAssignForm,
  [CMS_ParagraphType.INFO_BLOCK]: BlockInfoBlock,
  [CMS_ParagraphType.ICON_LIST]: BlockIconList,
  [CMS_ParagraphType.HOTEL_CONTACT]: BlockHotelContact,
  [CMS_ParagraphType.HOTEL_LIST_FILTRABLE]: BlockHotelListFiltrable,
  [CMS_ParagraphType.HOTELS_MAP]: BlockHotelsMap,
  [CMS_ParagraphType.HOTEL_ROOMS_PROMOTED]: BlockHotelRoomsPromoted,
  [CMS_ParagraphType.HOTEL_LIST]: BlockHotelList,
  [CMS_ParagraphType.HOTEL_LIST_BY_DESTINATION]: BlockHotelListByDestination,
  [CMS_ParagraphType.HOTEL_LIST_BY_EXPERIENCE]: BlockHotelListByExperience,
  [CMS_ParagraphType.HOTEL_LIST_BY_TAG]: BlockHotelListByTag,
  [CMS_ParagraphType.MULTI_GALLERY]: BlockMultiGallery,
  [CMS_ParagraphType.SIMPLE_GALLERY]: BlockSimpleGallery,
  [CMS_ParagraphType.INFO_CARDS]: BlockInfoCards,
  [CMS_ParagraphType.ALERT_BLOCK]: BlockAlertBlock,
  [CMS_ParagraphType.LINKS_IMAGE_LIST]: BlockLinksImageList,
  [CMS_ParagraphType.INFO_TEXT_IMAGE]: BlockInfoTextImage,
  [CMS_ParagraphType.HTML_BLOCK]: BlockHtmlBlock,
  [CMS_ParagraphType.LINKS_BLOCK]: BlockLinksBlock,
  [CMS_ParagraphType.LOUNGES_LIST]: BlockLoungesList,
  [CMS_ParagraphType.DESTINATION_LIST]: BlockDestinationList,
  [CMS_ParagraphType.HOTELS_BY_RESIDENT]: BlockHotelListByResident,
  [CMS_ParagraphType.DESTINATION_BY_RESIDENT]: BlockDestinationListByResident,
  [CMS_ParagraphType.BLOG_SECTION]: BlockBlogSection,
  [CMS_ParagraphType.BLOG_PROMOTED]: BlockBlogPromoted,
  [CMS_ParagraphType.JOB_OFFER_INFO]: BlockJobOfferInfo,
  [CMS_ParagraphType.JOB_OFFER_FORM]: BlockJobOfferForm,
  [CMS_ParagraphType.EVENT_FORM]: BlockEventForm,
  [CMS_ParagraphType.CONTACT_GENERAL_INFO]: BlockContactGeneralInfo,
  [CMS_ParagraphType.CONTACT_FORM]: BlockContactForm,
  [CMS_ParagraphType.HOTELS_OFFERS_PROMOTED]: BlockHotelsOffersPromoted,
  [CMS_ParagraphType.HOTEL_OFFERS_SEARCH_FORM]: BlockHotelOffersSearchForm,
  [CMS_ParagraphType.HOTEL_OFFERS_RESULT]: BlockHotelOffersResult,
  [CMS_ParagraphType.HOTEL_OFFERS]: BlockHotelOffers,
};

const getBlockComponent = (blockType: CMS_ParagraphType) : BlockElement | null => {
  if (typeof BlockComponents[blockType] === 'undefined') {
    return null;
  }
  return BlockComponents[blockType];
}

export default getBlockComponent;
