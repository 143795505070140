let cardValid = require("card-validator");

const PaymentUtils = {
  isValid: (ccNumber: string) => {
    return cardValid.number(ccNumber).isValid;
  },
  isExpirationDateValid: (month: string, year: string) => {
    return cardValid.expirationDate(`${month}/${year}`);
  },
}

export default PaymentUtils;
