import {PropsWithChildren} from "react";
import cn from "classnames";

export enum ThStyle {
  TH_ROOM_DESCRIPTION,
  TH_TOTAL_PRICE,
  DAY_NORMAL,
  DAY_SELECTED,
  DAY_UNAVAILABLE,
}

interface _Props {
  style?: ThStyle
  onClick?: VoidFunction
  className?: string
}

const _Th = (props: PropsWithChildren<_Props>) => (
  <th
    className={cn(
      'h-10 border border-brand-2-40 font-bold text-xs',
      props.style === ThStyle.TH_ROOM_DESCRIPTION && 'bg-gray-40 text-left pl-2.5',
      props.style === ThStyle.TH_TOTAL_PRICE && 'text-center bg-brand-2-90 text-white w-38',
      props.style === ThStyle.DAY_NORMAL && 'text-center bg-gray-40 select-none',
      props.style === ThStyle.DAY_UNAVAILABLE && 'text-center bg-gray-40',
      props.style === ThStyle.DAY_SELECTED && 'text-center bg-brand-2-90 text-white select-none',
      props.style === ThStyle.DAY_NORMAL && 'cursor-pointer hover:bg-brand-1-60 hover:font-bold',
      props.style === ThStyle.DAY_SELECTED && 'cursor-pointer hover:bg-brand-1-60',
      props.className,
    )}
    onClick={props.onClick}
  >
    {props.children}
  </th>
);

export default _Th;
