import UiMediaUtils from "@/src/ui/utils/media";
import {T_HotelSectionHotelCommonInfo} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";

interface _Props {
  hotel: T_HotelSectionHotelCommonInfo
}

const _Hero = (props: _Props): JSX.Element => (
  <img
    className="w-full h-60 object-cover"
    {...UiMediaUtils.getImgPropsFromMediaImage(props.hotel.field_hotel_header)}
  />
);

export default _Hero;
