import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

interface _Props {
  titleWithTag: CMS_FieldTextWithTag
}

const _Header = (props: _Props) => (
  <div className="pt-10 px-5 flex-none">
    <MaxWidthWrapper>
      <TitleWithTag
        field={props.titleWithTag}
        className="title-5 pb-5"
      />
    </MaxWidthWrapper>
  </div>
);

export default _Header;
