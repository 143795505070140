import MaxWidthWrapper from "../../../generic/MaxWidthWrapper";
import Section from "../../../generic/Section/Section";
import Paragraph from "../../../generic/Paragraph";
import TriangleSeparator from "../../../generic/TriangleSeparator";
import _SocialNetworks from "../generic/_SocialNetworks";
import TagList from "../../../desktop/TagList";
import {
  T_HotelSectionHotelCommonInfo
} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionHotelCommonInfo";
import UrlUtils from "@/src/core/app/utils/url";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import UiHotelSectionUtils from "@/src/ui/utils/hotel-section";

interface _Props {
  hotelSection: T_HotelSection
}

const _Summary = (props: _Props): JSX.Element => {
  const hotel = props.hotelSection.ui_hotel_common_info;
  const experienceLinks = hotel.field_experiences.map(node => ({
    label: node.title,
    href: UrlUtils.getCmsLinkUrlFromPath(node.path),
  }));

  const isMainSection = UiHotelSectionUtils.isMainSection(props.hotelSection);

  return (
    <div className="bg-gray-20">
      <TriangleSeparator />
      <Section backgroundColor={'E2EEFB'}>
        <MaxWidthWrapper>
          <div className="space-y-5 text-brand-2">
            {isMainSection && (
              <h1 className="text-title-1 leading-tight" itemProp={isMainSection ? 'name' : undefined}>
                {hotel.title}
              </h1>
            )}
            {!isMainSection && (
              <p className="text-title-1 leading-tight" itemProp={isMainSection ? 'name' : undefined}>
                {hotel.title}
              </p>
            )}
            <div className="flex items-center justify-between space-x-5">
              <div className="flex items-center space-x-5">
                <div className="flex items-center space-x-2.5 flex-shrink-0">
                  <img className="h-4 w-auto" src="/assets/icons/place.svg"/>
                  <Paragraph size="big">{hotel.locality}, {hotel.region} ({hotel.country})</Paragraph>
                </div>
                {/*<TagList tags={experienceLinks} />*/}
              </div>
              <_SocialNetworks hotel={hotel}/>
            </div>
          </div>
        </MaxWidthWrapper>
      </Section>
    </div>
  );
}

export default _Summary;
