import {CMS_ParagraphEntity} from "@/src/core/app/domain/cms/@types/paragraph/CMS_ParagraphEntity";
import {CMS_FieldTextTextarea} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextTextarea";
import {CMS_FieldLink} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldLink";
import {CMS_MediaReference} from "@/src/core/app/domain/cms/@types/media/CMS_MediaReference";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";

export enum CMS_ParagraphInfoTextImage_Align {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface CMS_ParagraphInfoTextImage extends CMS_ParagraphEntity {
  field_align: CMS_ParagraphInfoTextImage_Align
  field_background_color?: string
  field_body: CMS_FieldTextTextarea
  field_title_with_tag?: CMS_FieldTextWithTag
  field_medias: CMS_MediaReference[]
  field_one_link?: CMS_FieldLink
  field_link?: CMS_FieldLink[]
}