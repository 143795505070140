import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {UserRegisterData} from "@/src/core/app/domain/models/user-register/UserRegisterData";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {UserRegisterInput} from "@/src/core/app/domain/user/@types/Input/UserRegisterInput";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import CountryUtils from "@/src/core/app/utils/country";
import {UserRegisterFormValues} from "@/src/core/app/domain/forms/UserRegisterFormValues";
import {FormikHelpers} from "formik/dist/types";
import {ModalState} from "@/src/ui/view_models/modal.state";
import {UserRegisterState} from "@/src/ui/view_models/user-register.state";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {UserRegisterError} from "@/src/core/app/domain/user/@types/ErrorEnum/UserRegisterError";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import FormUtils from "@/src/ui/utils/form";
import UserRegisterErrors from "@/src/core/app/domain/user/@types/FormErrorMap/user-register";
import NetworkUtils from "@/src/common/network/NetworkUtils";
import {TFunction} from "react-i18next";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

const UserRegisterUtils = {
  getSteps: () => {
    return [
      "user_register_utils.step.label_1",
      "user_register_utils.step.label_2",
      "user_register_utils.step.label_3",
    ];
  },
  buildUserRegisterInput: (
    data: UserRegisterData,
    countries: T_Country[],
    // newsletterCategories: T_NewsletterCategory[],
  ) => {
    return {
      email: data.email,
      password: data.password,
      langcode: data.language,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      phoneNumber: data.phoneNumber,
      country: CountryUtils.findByIso2(countries, data.country).drupal_internal__id,
      // newsletter: NewsletterCategoryUtils.findByKeyMultiple(newsletterCategories, data.newsletterCategories).map(
      //   item => item.drupal_internal__id
      // ),
      tripStyle: data.tripStyle,
    } as UserRegisterInput;
  },
  submit: async (appInfo: AppInfo, input: UserRegisterInput) => {
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    return await api.userRegister(appInfo, input);
  },
  handleError: (
    networkError: NetworkResponseError<UserRegisterError>,
    values: UserRegisterFormValues,
    formikHelpers: FormikHelpers<UserRegisterFormValues>,
    t: TFunction,
    modalState: ModalState,
    userRegisterState: UserRegisterState,
  ) => {
    FormUtils.handleNetworkError<UserRegisterState>({
      networkError,
      modalState,
      formikHelpers,
      t,
      formErrorMap: UserRegisterErrors,
      formErrorMapKeyGetter: NetworkUtils.NetworkErrorKeyGetter__DataIsErrorMapKey,
      logFn: (reason) => FormErrorUtils.logNetworkError('User register form error', reason, networkError),
      extra: userRegisterState,
    });
  },
};

export default UserRegisterUtils;
