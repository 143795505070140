import TitledBox from "@/src/ui/components/generic/TitledBox";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormUtils from "@/src/ui/utils/form";
import FormInputRadioGroup from "@/src/ui/components/formik/FormInputRadioGroup";
import UserUtils from "@/src/core/app/utils/user";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import CountryUtils from "@/src/core/app/utils/country";
import LanguageUtils from "@/src/ui/utils/language";
import Button from "@/src/ui/components/generic/Button";
import {UserEditFormComponentProps} from "@/src/ui/components/forms/UserEditForm/types";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";

const UserEditFormDesktopComponent = (props: UserEditFormComponentProps) => {
  const { t } = useTranslation();
  return (
    <TitledBox title={t('UserEditForm._Index.title')}>
      <div className="space-y-7.5 py-3.75 px-5">
        <div className="space-y-3.75">
          <div className="flex space-x-5">
            <FormInputText
              name='firstName'
              label={t('UserEditForm._Index.first_name_label') + " *"}
              validate={FormUtils.validateIsRequired}
            />
            <FormInputText
              name='lastName'
              label={t('UserEditForm._Index.last_name_label') + " *"}
              validate={FormUtils.validateIsRequired}
            />
          </div>
          <div className="flex space-x-5">
            <FormInputText
              name='phoneNumber'
              label={t('UserEditForm._Index.phone_number_label') + " *"}
              validate={FormUtils.validateIsRequired}
            />
            <FormInputRadioGroup
              name="gender"
              label={t('UserEditForm._Index.gender_label') + " *"}
              validate={FormUtils.validateIsRequired}
              options={I18nUtils.translateFormInputSelectOptions(
                UserUtils.getGenderOptions(), t
              )}
            />
          </div>
          <div className="flex space-x-5">
            <div className="flex-1 flex space-x-3">
              <FormInputSelect
                name='country'
                label={t('UserEditForm._Index.country_label') + " *"}
                emptyOption={'- ' + t('UserEditForm._Index.country_empty_option')}
                options={CountryUtils.getSelectOptions(props.countries)}
                validate={FormUtils.validateIsRequired}
              />
              <FormInputSelect
                name='langcode'
                label={t('UserEditForm._Index.language_label') + " *"}
                emptyOption={'- ' + t('UserEditForm._Index.language_empty_option')}
                options={LanguageUtils.getSelectOptions(props.languages)}
                validate={FormUtils.validateIsRequired}
              />
            </div>
          </div>
        </div>
        <div className='space-x-3.75'>
          <Button bold text={t('UserEditForm._Index.submit_button_label')} onClick={props.formik.handleSubmit} isActive={!props.formik.isSubmitting}/>
          <span className="cursor-pointer text-link-bold" onClick={props.closeFn}>
          {t('UserEditForm._Index.cancel_button_label')}
        </span>
        </div>
      </div>
    </TitledBox>
  );
}

export default UserEditFormDesktopComponent;
