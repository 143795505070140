import Section from "@/src/ui/components/generic/Section/Section";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import {ChangeEvent} from "react";

interface _Props {
  title: string
  currentOption: FormInputSelectOption | null
  options: FormInputSelectOption[]
  onChange: (option: FormInputSelectOption | null) => void
  placeholder: string
  placeholderOptionLabel?: string
  showPlaceholderOption: boolean

  paddingY?: string
  paddingX?: string
}

const PageMenuMobile = (props: _Props): JSX.Element => {
  const {
    showPlaceholderOption = true,
    paddingY = 'pt-7.5',
    paddingX,
  } = props;

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === '') {
      props.onChange(null);
      return;
    }

    const option = props.options.find(item => item.value === value) as FormInputSelectOption;
    props.onChange(option);
  };
  return (
    <Section
      paddingX={paddingX}
      paddingY={paddingY}
      className="flex items-baseline space-x-2.5 text-xs uppercase text-brand-2-90"
    >
      <strong>{props.title}:</strong>
      <div className="relative flex items-baseline space-x-2.5 uppercase cursor-pointer text-small-bold">
        <select
          className='w-full h-full absolute opacity-0 z-50 cursor-pointer'
          value={props.currentOption ? props.currentOption.value : ''}
          onChange={onChange}
        >
          {showPlaceholderOption && (
            <option value=''>
              {props.placeholderOptionLabel || props.placeholder}
            </option>
          )}
          {props.options.map((item) => (
            <option key={item.id || item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <div className="cursor-pointer flex items-center space-x-1">
          <span>{props.currentOption ? props.currentOption.label : props.placeholder}</span>
          <img className="w-4 h-auto" src="/assets/icons/chevron-down.svg" alt="" />
        </div>
      </div>
    </Section>
  );
}

export default PageMenuMobile;
