import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormUtils from "@/src/ui/utils/form";
import Button from "@/src/ui/components/generic/Button";
import {UserPasswordChangeFormComponentProps} from "@/src/ui/components/forms/UserPasswordChangeForm/types";
import Box from "@/src/ui/components/generic/Box";
import {useTranslation} from "next-i18next";

const UserPasswordChangeFormMobileComponent = (props: UserPasswordChangeFormComponentProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <div className="space-y-3.75 pb-5">
        <div className="space-y-1.5">
          <FormInputText
            name='currentPassword'
            type='password'
            label={t('UserPasswordChangeForm._Index.current_password_label') + " *"}
            validate={FormUtils.validateIsRequired}
          />
        </div>
        <FormInputText
          name='newPassword1'
          type='password'
          label={t('UserPasswordChangeForm._Index.new_password1_label') + " *"}
          validate={FormUtils.validateIsRequired}
        />
        <FormInputText
          name='newPassword2'
          type='password'
          label={t('UserPasswordChangeForm._Index.new_password2_label') + " *"}
          validate={FormUtils.validateIsRequired}
        />
      </div>
      <div className='space-x-3.75'>
        <Button bold text={t('UserPasswordChangeForm._Index.submit_button_label')} onClick={props.formik.handleSubmit} isActive={!props.formik.isSubmitting} style="secondary"/>
      </div>
    </Box>
  );
}

export default UserPasswordChangeFormMobileComponent;
