import {T_ParagraphBlogPromoted} from "@/src/core/app/domain/models/paragraph/T_ParagraphBlogPromoted";
import NodeBlogLinkListDesktop from "@/src/ui/components/blog/NodeBlogLinkList/desktop";

const BlockBlogPromotedDesktop = (props: T_ParagraphBlogPromoted) => (
  <NodeBlogLinkListDesktop
    titleWithTag={props.field_title_with_tag}
    items={props.promotedPosts}
  />
);

export default BlockBlogPromotedDesktop;