import {useHeaderMobileMenuProvider} from "@/src/ui/providers/header-mobile-menu";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {HeaderMobileMenuSection} from "@/src/ui/view_models/header-mobile-menu.state";
import React from "react";
import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";
import Anchor from "@/src/ui/components/generic/Anchor";

const _ArrowLink = ({menuItem}: {menuItem: CMS_MenuItem}) => (
  <Anchor className="text-sm font-bold text-brand-2 flex items-center space-x-2.5" href={menuItem.url}>
    <span>{menuItem.title}</span>
    <img
      className="h-3.5 w-auto"
      src="/assets/icons/chevron-right-brand-2.svg"
      alt=""
    />
  </Anchor>
);

const HeaderMobileMenuTabServices = (): JSX.Element | null => {
  const currentSection = useHeaderMobileMenuProvider(state => state.currentSection);
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();
  const menuPopup = routeProps.layoutData.menuPopup;

  if (currentSection !== HeaderMobileMenuSection.SERVICES) {
    return null;
  }
  return (
    <div className="pt-7.5">
      {menuPopup.items.map((item, index) => (
        <div key={index}>
          <_ArrowLink menuItem={item} />
          {item.below.length > 0 && (
            <ul className="ul pt-5 space-y-5 leading-4 text-sm">
              {item.below.map((item, index) => (
                <li className="hover:underline" key={item.id}>
                  <Anchor href={item.url}>{item.title}</Anchor>
                </li>
              ))}
            </ul>
          )}
          {(index + 1) != menuPopup.items.length && (
            <div className="h-7 5" />
          )}
        </div>
      ))}
    </div>
  );
}

export default HeaderMobileMenuTabServices;
