import React from "react";
import {T_ParagraphElbaFidelity} from "@/src/core/app/domain/models/paragraph/T_ParagraphElbaFidelity";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import UiMediaUtils from "@/src/ui/utils/media";
import Anchor from "@/src/ui/components/generic/Anchor";
import {T_ElbaFidelity} from "@/src/core/app/domain/models/elba_fidelity/T_ElbaFidelity";
import Section from "@/src/ui/components/generic/Section/Section";
import Button from "@/src/ui/components/generic/Button";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import TriangleSeparator from "@/src/ui/components/generic/TriangleSeparator";

const renderFidelityItems = (items: T_ElbaFidelity[]) : JSX.Element => (
  <ul className="flex justify-center items-stretch space-x-2.5">
    {items.filter(item => item.field_fidelity_mobile_visible).map((item, index) => (
      <li
        key={index}
        className="w-26.5 h-24 flex-initial flex flex-col items-center"
      >
        <img
          className="h-11 w-auto flex-none"
          {...UiMediaUtils.getImgPropsFromMediaImage(item.field_fidelity_media)}
        />
        <div className="flex-1 flex items-center text-center text-sm">
          <span>
            {item.title}
          </span>
        </div>
      </li>
    ))}
  </ul>
)

const BlockElbaFidelityMobile = (props: T_ParagraphElbaFidelity): JSX.Element => {
  const routeProps = useRouteProps();
  return (
    <div>
      {routeProps.route.isHomePath && (
        <TriangleSeparator />
      )}
      <Section>
        <img
          className="h-10 w-auto mx-auto mb-1.5"
          src="/assets/images/generic/elba-fidelity-logo.svg"
        />
        <WrapperDiv
          size="medium"
          className="text-center text-brand-3 pb-5"
          dangerouslySetInnerHTML={{__html: props.field_body.processed}}
        />
        {renderFidelityItems(props.fidelity_items)}
        {props.field_one_link && (
          <div className="flex justify-center pt-5">
            <Anchor href={props.field_one_link.url}>
              <Button text={props.field_one_link.title} style="secondary" bold />
            </Anchor>
          </div>
        )}
      </Section>
    </div>
  );
}

export default BlockElbaFidelityMobile;
