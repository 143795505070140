import Anchor from "@/src/ui/components/generic/Anchor";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import {T_NodeBlogLink} from "@/src/core/app/domain/models/node/T_NodeBlogLink";
import {CMS_FieldTextWithTag} from "@/src/core/app/domain/cms/@types/fields/CMS_FieldTextWithTag";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

interface _Props {
  titleWithTag?: CMS_FieldTextWithTag
  items: T_NodeBlogLink[]
}

const NodeBlogLinkListDesktop = (props: _Props) => {
  if (props.items.length === 0) {
    return null;
  }

  return (
    <section className="pt-1.5 space-y-7.5">
      <TitleWithTag
        className="title-4 font-calistoga"
        field={props.titleWithTag}
      />
      <ul className="border border-gray-40 px-3.75 divide-y divide-gray-40">
        {props.items.map((item, index) => (
          <li key={item.id}>
            <Anchor
              className="block py-2.5 space-y-1.5"
              path={item.path}
            >
              <h3 className="text-medium-bold">
                {item.title}
              </h3>
              {item.field_teaser && (
                <WrapperDiv
                  className="text-xs text-brand-2-90"
                  dangerouslySetInnerHTML={{__html: item.field_teaser.processed}}
                />
              )}
            </Anchor>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default NodeBlogLinkListDesktop;