import {T_OfferDetail} from "@/src/core/app/domain/models/offer/T_OfferDetail";
import Anchor from "@/src/ui/components/generic/Anchor";
import StarRating from "@/src/ui/components/generic/StarRating";

const _HeaderDesktop = (props: T_OfferDetail) => (
  <div className='flex items-center space-x-2'>
    <p className="text-brand-2">
      <Anchor href={props.cpt_ui_hotel_url || undefined}>
        {props.cpt_ui_hotel_name}
      </Anchor>
    </p>
    <StarRating rating={props.cpt_ui_hotel_stars} />
    <p className="text-brand-2-40">{props.cpt_ui_hotel_location}</p>
  </div>
);

export default _HeaderDesktop;