import {useDevice} from "@/src/ui/hooks/useDevice";
import {T_ParagraphUserEditForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserEditForm";
import BlockUserEditFormDesktop from "@/src/ui/components/blocks/BlockUserEditForm/desktop";
import BlockUserEditFormMobile from "@/src/ui/components/blocks/BlockUserEditForm/mobile";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";

const BlockUserEditForm = (props: T_ParagraphUserEditForm) => {
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const device = useDevice();
  const _Component = device.isMobile ? BlockUserEditFormMobile : BlockUserEditFormDesktop;
  return (
    <_Component {...props} languages={layoutData.languages} />
  );
}
export default BlockUserEditForm;
