import {T_ParagraphHotelListByDestination} from "@/src/core/app/domain/models/paragraph/T_ParagraphHotelListByDestination";
import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import HotelTeaserListDesktop from "@/src/ui/components/hotel/HotelTeaserList/desktop";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";

const BlockHotelListByDestinationDesktop = (props: T_ParagraphHotelListByDestination) => (
  <Section paddingY="pt-7.5 pb-15" backgroundColor={props.field_background_color} >
    <MaxWidthWrapper>
      {props.field_title_with_tag && (
        <TitleWithTag
          className="title-4 pb-7.5 font-calistoga"
          field={props.field_title_with_tag}
          suffix={`(${props.hotelTeasers.length})`}
        />
      )}
      <HotelTeaserListDesktop hotelTeasers={props.hotelTeasers} />
    </MaxWidthWrapper>
  </Section>
);

export default BlockHotelListByDestinationDesktop;
