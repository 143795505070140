import {T_ParagraphSimpleGallery} from "@/src/core/app/domain/models/paragraph/T_ParagraphSimpleGallery";
import FullWidthGalleryMobile from "@/src/ui/components/mobile/FullwidthGalleryMobile";
import UiGalleryUtils from "@/src/ui/utils/gallery";

const BlockSimpleGalleryMobile = (props: T_ParagraphSimpleGallery) => (
  <FullWidthGalleryMobile
    items={props.ui_cpt_field_medias_gallery_mobile}
    legendBuilder={(selectedIndex) => {
      return UiGalleryUtils.getSingleGalleryLegend(props, selectedIndex, props.ui_cpt_field_medias_gallery_mobile.length)
    }}
  />
);


export default BlockSimpleGalleryMobile;