import Paragraph from "../../generic/Paragraph";
import {useHeaderMobileMenuProvider} from "@/src/ui/providers/header-mobile-menu";
import Anchor from "@/src/ui/components/generic/Anchor";
import LanguageDropdown from "@/src/ui/components/generic/LanguageDropdown";
import CurrencyDropdown from "@/src/ui/components/generic/CurrencyDropdown";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {useUserProvider} from "@/src/ui/providers/user.provider";
import MenuHeaderUserUtils from "@/src/core/app/utils/menu-header-user";
import {CMS_MenuHeaderUserItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuHeaderUserItem";

interface _ItemProps {
  menuItem: CMS_MenuHeaderUserItem
}

const _MenuItem = (props: _ItemProps) => {
  const menuItem = props.menuItem;
  const userState = useUserProvider(state => state);

  if (!MenuHeaderUserUtils.isMenuItemVisible(menuItem, userState)) {
    return null;
  }

  return (
    <Paragraph size="big">
      <Anchor href={menuItem.url}>{menuItem.title}</Anchor>
    </Paragraph>
  )
};

const HeaderMobileMenuNavigationLinks = (): JSX.Element | null => {
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();
  const state = useHeaderMobileMenuProvider(state => state);

  if (state.currentSection) {
    return null;
  }

  return (
    <div>
      <div className="h-px bg-gray-40" />
      <div className="py-7.5 space-y-7.5">
        {routeProps.layoutData.menuHeaderUser.items.map(menuItem => (
          <_MenuItem key={menuItem.id} menuItem={menuItem} />
        ))}
      </div>
      <div className="h-px bg-gray-40" />
      <div className="pt-7.5 flex space-x-7.5">
        <CurrencyDropdown color="brand-2" spaceBetween />
        <LanguageDropdown color="brand-2" spaceBetween />
      </div>
    </div>
  );
}

export default HeaderMobileMenuNavigationLinks;
