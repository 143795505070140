import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import {useEffect} from "react";
import MapServiceFactory from "@/src/ui/components/hotel/HotelMapSection/services/factory";

interface _Props {
  initiated: boolean
  layer?: 'mapbox' | 'leaflet'
  hotelList: T_HotelTreeHotel[]
  className?: string
  onChangeActivated?: boolean
}

const getNodesAndInit = (props: _Props) => {
  const nodes = props.hotelList.map(hotel => ({
    title: hotel.title,
    latitude: parseFloat(hotel.latitude),
    longitude: parseFloat(hotel.longitude),
    link: `<a href="${hotel.href}">${hotel.title}</a>`,
  }))
  const service = MapServiceFactory.create(props.layer || 'mapbox');
  service.init(nodes);
};

const HotelMapSection = (props: _Props): JSX.Element => {
  const onChangeActivated = props.onChangeActivated || false;
  useEffect(() => {
    if (!props.initiated) {
      return;
    }
    getNodesAndInit(props);
  }, [props.initiated]);

  useEffect(() => {
    if (!onChangeActivated) {
      return;
    }
    getNodesAndInit(props);
  }, [props.hotelList]);

  return (
    <section>
      <div id="mapId" className={props.className} />
    </section>
  );
}

export default HotelMapSection;
