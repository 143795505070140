import {ReactNode} from "react";
import Section from "@/src/ui/components/generic/Section/Section";

interface _Props {
  content: ReactNode
  sidebar: ReactNode
}

const Section2ColsMobile = (props: _Props): JSX.Element => {
  return (
    <>
      <Section className="space-y-5">
        {props.content}
      </Section>
      <Section paddingY="pt-2.5 pb-7.5 text-brand-2-90">
        {props.sidebar}
      </Section>
    </>
  );
}

export default Section2ColsMobile;
