import MaxWidthWrapper from "@/src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import {T_ParagraphResidentsList} from "@/src/core/app/domain/models/paragraph/T_ParagraphResidentsList";
import ArrowLink from "@/src/ui/components/generic/ArrowLink";
import UiMediaUtils from "@/src/ui/utils/media";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Anchor from "@/src/ui/components/generic/Anchor";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useTranslation} from "next-i18next";

const _Row = (props: T_ParagraphResidentsList) => {
  const { t } = useTranslation();
  return (
    <Section backgroundColor={props.field_background_color}>
      <MaxWidthWrapper>
        <div className="pb-7 5">
          <TitleWithTag
            className="text-2xl"
            field={props.field_title_with_tag}
          />
        </div>
        <ul>
          {props.residents.map((item, index) => (
            <li
              key={item.id}
              className="flex-1 p-3.75 border border-gray-40 bg-white mb-5 ml-0"
            >
              <article className="flex">
                <Anchor path={item.path}>
                  <img
                    className="w-80	h-45 object-cover border border-gray-40 mr-5"
                    {...UiMediaUtils.getImgPropsFromMediaImage(item.ui_cpt_field_resident_teaser)}
                  />
                </Anchor>
                <div>
                  <p className="pt-3 pb-2.5 text-brand-2-60 text-xs strong uppercase">{item.ui_cpi_field_region_title}</p>
                  <Anchor path={item.path}>
                    <h2 className='text-2xl'>{item.title}</h2>
                  </Anchor>
                  {item.field_teaser && (
                    <WrapperDiv
                      className="py-3 text-sm text-brand-2-90 Cms-Formatted"
                      dangerouslySetInnerHTML={{__html: item.field_teaser.processed}} />
                  )}
                  <ArrowLink path={item.path}>
                    <p>{t('BlockResidentsList._Row.link_label')}</p>
                  </ArrowLink>
                </div>
              </article>
            </li>
          ))}
        </ul>
      </MaxWidthWrapper>
    </Section>
  );
}

export default _Row;
