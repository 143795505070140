import ModalPlaceSearchTabsDesktop from "../../desktop/ModalPlaceSearchTabs";
import {useSearchFormMobileProvider} from "@/src/ui/providers/search-form-mobile-provider";
import {SearchFormMobileStep} from "@/src/ui/view_models/search-form-mobile.state";
import {useState} from "react";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {T_Destination} from "@/src/core/app/domain/models/destination/T_Destination";
import {useSearchFormProvider} from "@/src/ui/providers/search-form.provider";
import {T_HotelTreeHotel} from "@/src/core/app/domain/models/hotel-tree/T_HotelTreeHotel";
import HotelLinkList from "src/ui/components/hotel/HotelLinkList";
import {SearchParamsLocationType} from "@/src/core/app/domain/@types/SearchParamsLocationType";

export enum ModalSearchFormStepLocationTab {
  HOTELS,
  DESTINATIONS,
}

interface ModalSearchFormStepLocationState {
  tab: ModalSearchFormStepLocationTab,
}


const ModalSearchFormStepLocationMobile = (): JSX.Element | null => {
  const searchForm = useSearchFormProvider(state => state);
  const searchFormMobileState = useSearchFormMobileProvider(state => state);
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();
  const [state, setState] = useState<ModalSearchFormStepLocationState>({
    tab: ModalSearchFormStepLocationTab.HOTELS,
  });

  if (searchFormMobileState.currentStep !== SearchFormMobileStep.LOCATION) {
    return null;
  }

  const goToForm = () => searchFormMobileState.setCurrentStep(SearchFormMobileStep.FORM);

  const onDestinationSelected = (item: T_Destination) => {
    searchForm.setLocation({
      type: SearchParamsLocationType.DESTINATION,
      id: item.id,
    });
    goToForm();
  };

  const onHotelSelected = (item: T_HotelTreeHotel) => {
    searchForm.setLocation({
      type: SearchParamsLocationType.HOTEL,
      id: item.code,
    });
    goToForm();
  };

  const setTab = (tab: ModalSearchFormStepLocationTab) => {
    setState({
      ...state,
      tab,
    })
  };

  return (
    <div className='flex-1 overflow-y-auto'>
      <ModalPlaceSearchTabsDesktop
        tab={state.tab}
        setTab={setTab}
      />
      <div className="p-7.5 pt-2.5">
        {state.tab === ModalSearchFormStepLocationTab.HOTELS && (
          <div className="divide-y divide-gray-40">
            {routeProps.layoutData.hotelTree.regions.map((item) => (
              <div key={item.id} className='pt-5'>
                <HotelLinkList region={item} onHotelSelected={onHotelSelected} />
              </div>
            ))}
          </div>
        )}
        {state.tab === ModalSearchFormStepLocationTab.DESTINATIONS && (
          <ul className="divide-y divide-gray-40">
            {routeProps.layoutData.destinations.map(item => (
              <li
                key={item.id}
                className="hover:underline text-brand-2 text-sm flex items-center space-x-2.5 py-5"
                onClick={() => onDestinationSelected(item)}
              >
                <span className='flex-1'>
                  {item.destinationName} ({item.hotelCount})
                </span>
                <img
                  src="/assets/icons/chevron-right-brand-2.svg"
                  className="h-3.5 w-auto"
                  alt=""
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default ModalSearchFormStepLocationMobile;
