import {ReactNode} from "react";
import cn from "classnames";

interface _Props {
  children: ReactNode
  wrapperClassName?: string
  contentClassName?: string
  onCloseModal?: VoidFunction
  title?: string | ReactNode
}

const ModalLayoutFullscreen = (props: _Props): JSX.Element | null => (
  <section className={cn(
    "fixed z-40 inset-0 bg-white flex",
    props.wrapperClassName,
  )}>
    <div
      className={cn(
        "flex-1 relative overflow-auto",
        props.contentClassName
      )}
    >
      {props.onCloseModal && (
        <button
          className='absolute right-0 top-0 p-5'
          onClick={props.onCloseModal}>
          <img
            className='w-6 h-6'
            src="/assets/icons/cross-brand-2.svg"
          />
        </button>
      )}
      {props.title && (
        <div className='p-5 pt-15 border-b border-gray-20'>
          <h2 className="title-3-mobile text-center">{props.title}</h2>
        </div>
      )}
      {props.children}
    </div>
  </section>
);

export default ModalLayoutFullscreen;
