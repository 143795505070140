import NotificationMessage from "@/src/ui/components/generic/NotificationMessage";
import {Route_Error_ComponentProps} from "@/src/ui/components/routes/Route_Error/Route_Error_ComponentProps";
import {T_RouteErrorDataMessage} from "@/src/core/app/domain/route/@types/T_RouteErrorDataMessage";

const _Message = (props: Route_Error_ComponentProps) => {
  const data = props.routeError.data as T_RouteErrorDataMessage;
  return (
    <NotificationMessage
      title={data.title}
      message={data.message}
      size='medium' />
  );
}

export default _Message;
