import FormInputWrapper from "../../form/FormInputWrapper";
import React from "react";
import {useField} from "formik";
import {FieldValidator} from "formik/dist/types";
import FormUtils from "@/src/ui/utils/form";
import {useTranslation} from "next-i18next";
import {FormFieldProps} from "@/src/ui/@types/form/FormFieldProps";

interface _Props extends FormFieldProps {
  label?: string
  placeholder?: string
  type?: string
  className?: string
  maxLength?: number
}

interface _FieldProps {
  name: string
  validate?: FieldValidator
}

const FormInputText = (props: _Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    label,
    type,
    placeholder,
    className,
    maxLength,
    validate,
    validations,
  } = props;

  const fieldProps : _FieldProps = {
    name: props.name,
    validate: undefined,
  };

  if (validate) {
    fieldProps.validate = (value: any) => validate(t, value);
  }

  if (validations) {
    fieldProps['validate'] = (value: any) => FormUtils.multipleValidator(t, value, validations);
  }

  const [field, meta] = useField(fieldProps);

  return (
    <FormInputWrapper
      label={label}
      error={meta.error}
      className={className}
    >
      <input
        className={`block w-full font-sans text-brand-2-90 text-lg placeholder-current`}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        {...field}
      />
    </FormInputWrapper>
  )
};

export default FormInputText;
