import WarningBanner from "@/src/ui/components/desktop/WarningBanner";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormUtils from "@/src/ui/utils/form";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";

const _UserRegistrationDesktop = () => {
  const { t } = useTranslation();
  return (
    <TitledBox bgColor="bg-yellow" title={t('CheckoutCustomerInfoForm._UserRegistration.title')}>
      <WarningBanner>
        <WrapperDiv
          dangerouslySetInnerHTML={{__html: t('CheckoutCustomerInfoForm._UserRegistration.warning')}}
        />
      </WarningBanner>
      <div className="px-5 py-4 space-y-3.5">
        <div className="flex space-x-5">
          <FormInputText
            label={t('CheckoutCustomerInfoForm._UserRegistration.password1_label') + " *"}
            name='userPassword1'
            type='password'
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            label={t('CheckoutCustomerInfoForm._UserRegistration.password2_label') + " *"}
            name='userPassword2'
            type='password'
            validate={FormUtils.validateIsRequired}
          />
        </div>
        <p className="pt-3.5 text-brand-2 text-xs leading-relaxed">
          {t('CheckoutCustomerInfoForm._UserRegistration.footer')}
        </p>
      </div>
    </TitledBox>
  );
}

export default _UserRegistrationDesktop;
