import _Td from "@/src/ui/components/blocks/BlockHotelRoomList/desktop/calendar/td";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import {T_HotelSectionDataRoom} from "@/src/core/app/domain/models/hotel-section/T_HotelSectionDataRoom";
import MediaImage from "@/src/ui/components/generic/MediaImage";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {useTranslation} from "next-i18next";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";

interface _Props {
  roomCode: string
}

const _RoomInfoCell = (props: _Props) => {
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  const routeData = useRouteData<T_HotelSection<T_HotelSectionDataRoom>>();
  let rooms = routeData.data.ui_hotel_section.data.rooms;
  const roomTeaser = rooms.find(item => item.code === props.roomCode);

  if (!roomTeaser) {
    return (
      <_Td>
        {props.roomCode}
      </_Td>
    );
  }

  const toggleRoomPopup = () => {
    modalState.toggleModal(ModalKey.HOTEL_ROOM, {
      room: roomTeaser
    })
  };

  return (
    <_Td className='w-60'>
      <div className="flex space-x-2.5">
        <div className='cursor-pointer' onClick={toggleRoomPopup}>
          <MediaImage
            className='w-auto h-15'
            mediaImage={roomTeaser.field_room_media_square}
          />
        </div>
        <div className='flex flex-col space-y-1.5 justify-center items-start'>
          <p className='text-xs font-bold text-brand-2 text-left'>
            {HotelRoomUtils.getTeaserTitle(roomTeaser)}
          </p>
          <p className='text-xs text-brand-2 underline cursor-pointer' onClick={toggleRoomPopup}>
            <span>{t('BlockHotelRoomListDesktop._CalendarStatusOk.room_info_see_details_label')}</span>
          </p>
        </div>
      </div>
    </_Td>
  );
}

export default _RoomInfoCell;
