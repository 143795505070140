import React from "react";
import type {LayoutProps} from "@/src/ui/@types/layout/LayoutProps";
import LayoutDesktop from "@/src/ui/components/layout/desktop/LayoutDesktop";
import LayoutMobile from "@/src/ui/components/layout/mobile/LayoutMobile";
import {LayoutType} from "@/src/ui/@types/layout/Layout";
import LayoutIframe from "@/src/ui/components/layout/iframe";
import LayoutError from "@/src/ui/components/layout/error";

const getComponent = (props: LayoutProps) => {
  if (props.routeProps.layout.type === LayoutType.ERROR) {
    return LayoutError;
  }
  if (props.routeProps.layout.type === LayoutType.IFRAME) {
    return LayoutIframe;
  }
  return props.routeProps.device.isDesktop ? LayoutDesktop : LayoutMobile
}

const Layout = (props: LayoutProps) => {
  const Component = getComponent(props);
  return <Component
    routeProps={props.routeProps}
    routePortals={props.routePortals}
  >
    {props.children}
  </Component>;
};

export default Layout;
