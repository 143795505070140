import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {locator} from "@/src/core/app/ioc";
import {UserApiService} from "@/src/core/app/data/services/user_api_service";
import {TYPES} from "@/src/core/app/ioc/types";
import {UserEditFormValues} from "@/src/core/app/domain/forms/UserEditFormValues";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import CountryUtils from "@/src/core/app/utils/country";
import {UserUpdateInput} from "@/src/core/app/domain/user/@types/Input/UserUpdateInput";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import {UserState} from "@/src/ui/view_models/user.state";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import {UserEditTripStyleFormValues} from "@/src/core/app/domain/forms/UserEditTripStyleFormValues";

const UserEditUtils = {
  buildUserUpdateInput: (
    data: UserEditFormValues,
    userData: UserData,
    countries: T_Country[],
    // newsletterCategories: T_NewsletterCategory[],
  ) => {
    return {
      email: data.email,
      password: data.password,
      langcode: data.langcode,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      phoneNumber: data.phoneNumber,
      country: CountryUtils.findByIso2(countries, data.country).drupal_internal__id,
      // newsletter: NewsletterCategoryUtils.findByKeyMultiple(newsletterCategories, data.newsletterCategories).map(
      //   item => item.drupal_internal__id
      // ),
      tripStyle: userData.tripStyle,
    } as UserUpdateInput;
  },
  save: async (
    data: UserEditFormValues,
    userState: UserState,
    countries: T_Country[],
    // newsletterCategories: T_NewsletterCategory[],
  ) : Promise<NetworkResponse> => {
    const userData = userState.userData as UserData;
    const userUpdateInput = UserEditUtils.buildUserUpdateInput(data, userData, countries);
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    let response = await api.userUpdate(userData.id, userUpdateInput, CMS_AuthUtils.getAuthHeaders());
    if (!response.isOk) {
      return response;
    }

    userState.setUserData(response.data as UserData);
    return response;
  },

  saveTripStyleForm: async (
    data: UserEditTripStyleFormValues,
    userState: UserState,
    countries: T_Country[],
  ) : Promise<NetworkResponse> => {
    const userData = userState.userData as UserData;
    const userUpdateInput = {
      ...userData,
      country: CountryUtils.findByUuid(countries, userData.country).drupal_internal__id,
      tripStyle: data.tripStyle,
    };
    const api = locator.get<UserApiService>(TYPES.UserApiService);
    let response = await api.userUpdate(userData.id, userUpdateInput, CMS_AuthUtils.getAuthHeaders());
    if (!response.isOk) {
      return response;
    }

    userState.setUserData(response.data as UserData);
    return response;
  },
};

export default UserEditUtils;
