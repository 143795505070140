import BrowserUtils from "@/src/ui/utils/browser";

import {createProvider} from "@/src/common/utils/zustand";
import {AppInitiatedEvent} from "@/src/ui/@types/event/AppInitiatedEvent";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import UiElbaMediaUtils from "@/src/ui/utils/elba-media";
import ElbaMediaUtils from "@/src/core/app/utils/elba-media";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import RouteUtils from "@/src/core/app/utils/route";
import { Route } from "@/src/core/app/domain/@types/Route";
import {ElbaMediaGolfState} from "@/src/ui/view_models/elba-media-golf.state";

export const useElbaMediaGolfProvider = createProvider<ElbaMediaGolfState>((set, get) => {
  BrowserUtils.onAppInitiated<AppInitiatedEvent>((event) => {
    get().init(
      event.detail.route,
      event.detail.appInfo,
      event.detail.userData
    );
  });

  return {
    isLoading: true,
    isUserAllowed: true,
    init: async (route: Route, appInfo: AppInfo, userData: UserData | null) => {
      if (userData === null) {
        set(state => {
          state.isLoading = false;
          state.isUserAllowed = false;
        });
        return;
      }

      if (!UiElbaMediaUtils.hasMediaGolfRole(userData)) {
        set(state => {
          state.isLoading = false;
          state.isUserAllowed = false;
        });
        return;
      }

      const path = route.params.subpath ? `/${route.params.subpath.join('/')}` : undefined;
      const page = RouteUtils.getQueryParamString(route, 'page');
      const response = await ElbaMediaUtils.getGolfData(appInfo, page, path);
      if (!response.isOk) {
        set(state => {
          state.isLoading = false;
          state.isUserAllowed = true;
          state.error = response.error;
        });
        return;
      }

      set(state => {
        state.isLoading = false;
        state.isUserAllowed = true;
        state.data = response.data;
      });
    },
  };
});
