import {PropsWithChildren, useState} from "react";

type _Props = PropsWithChildren<{
  title: string
}>

const CollapsedFormBox = (props: _Props): JSX.Element => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <article className="border border-gray-40 rounded-sm divide-y divide-gray-40">
      <h3
        className="flex justify-between items-center px-4 py-3.5 text-big-bold bg-gray-20 cursor-pointer select-none"
        onClick={() => setExpanded(!isExpanded)}
      >
        {props.title}
        <img className="block h-4 w-auto" src="/assets/icons/plus.svg" alt="" />
      </h3>
      {isExpanded && props.children}
    </article>
  );
}

export default CollapsedFormBox;
