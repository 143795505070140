import FormInputText from "@/src/ui/components/formik/FormInputText";
import FormInputTextarea from "src/ui/components/formik/FormInputTextarea";
import {useCheckoutProvider} from "@/src/ui/providers/checkout.provider";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {useTranslation} from "next-i18next";

const _SpecialRequests = () => {
  const { t } = useTranslation();
  const device = useDevice();
  const checkout = useCheckoutProvider(state => state);

  if (checkout.isInitiated === false) {
    return null;
  }

  return (
    <div className={cn({
      'px-5': device.isMobile,
    })}>
      <TitledBox title={t('CheckoutCustomerInfoForm._SpecialRequests.title')}>
        <div className="px-5 py-4 space-y-3.5">
          <FormInputText
            label={t('CheckoutCustomerInfoForm._SpecialRequests.arrival_time_label')}
            name="arrivalTime"
            maxLength={80}
          />
          {checkout.rooms.map((room , index) => (
            <FormInputTextarea
              key={index}
              label={t('CheckoutCustomerInfoForm._SpecialRequests.room_label', {
                roomIndex: index + 1,
                roomName: room.roomName,
                roomOccupation: SearchParamsFormatter.formatRoomOccupation(room.user_adults_count, room.user_kids_count, t),
              })}
              name={`specialRequestsRoom_${index}`}
            />
          ))}
          <p className="text-brand-2 text-xs leading-relaxed">
            {t('CheckoutCustomerInfoForm._SpecialRequests.footer')}
          </p>
        </div>
      </TitledBox>
    </div>
  );
}

export default _SpecialRequests;
