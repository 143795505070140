import {RouteProps} from "@/src/ui/@types/RouteProps";
import {BookingPaymentErrorRouteProps} from "@/src/core/app/domain/route/@types/BookingPaymentErrorRouteProps";
import React from "react";
import MaxWidthWrapper from "src/ui/components/generic/MaxWidthWrapper";
import Section from "@/src/ui/components/generic/Section/Section";
import {CMS_Reservation} from "@/src/core/app/domain/cms/@types/reservation/CMS_Reservation";
import {CMS_ReservationKoCode} from "@/src/core/app/domain/cms/@types/reservation/CMS_ReservationKoCode";
import HeroError from "@/src/ui/components/generic/HeroError";
import {useTranslation} from "next-i18next";
import {TFunction} from "react-i18next";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import NotificationWrapper from "@/src/ui/components/generic/NotificationWrapper";

const getTitle = (reservation: CMS_Reservation, t: TFunction) : string => {
  if (reservation.ko_code === CMS_ReservationKoCode.PAYMENT) {
    return t('Route_BookingPaymentError._Index.payment_error_title');
  }
  if ([
    CMS_ReservationKoCode.DINGUS,
    CMS_ReservationKoCode.DINGUS_NOT_ALLOTMENT,
    CMS_ReservationKoCode.DINGUS_STOP_SALES,
  ].indexOf(reservation.ko_code) !== -1) {
    return t('Route_BookingPaymentError._Index.dingus_error_title');
  }
  return t('Route_BookingPaymentError._Index.unknown_error_title');
}

const getMessage = (reservation: CMS_Reservation, t: TFunction) : string => {
  if (reservation.ko_code === CMS_ReservationKoCode.PAYMENT) {
    const message = reservation.ko_message || '';
    return t('Route_BookingPaymentError._Index.payment_error_message', {message});
  }
  if ([
    CMS_ReservationKoCode.DINGUS,
    CMS_ReservationKoCode.DINGUS_NOT_ALLOTMENT,
    CMS_ReservationKoCode.DINGUS_STOP_SALES,
  ].indexOf(reservation.ko_code) !== -1) {
    const message = reservation.ko_message || '';
    return t('Route_BookingPaymentError._Index.dingus_error_message', {message});
  }
  const message = reservation.ko_message || '';
  return t('Route_BookingPaymentError._Index.unknown_error_message', {message});
}

const Route_BookingPaymentError = (props: RouteProps<BookingPaymentErrorRouteProps>) => {
  const { t } = useTranslation();
  const title = getTitle(props.routeData.data.reservation, t);
  const message = getMessage(props.routeData.data.reservation, t);
  return (
    <>
      <HeroError />
      <Section paddingY="pt-5 pb-20 min-h-73">
        <MaxWidthWrapper>
          <NotificationWrapper size='medium' title={title}>
            <WrapperDiv
              className='BookingErrorMessage'
              dangerouslySetInnerHTML={{__html: message}}
            />
          </NotificationWrapper>
        </MaxWidthWrapper>
      </Section>
    </>
  );
};

export default Route_BookingPaymentError;
