import {T_ParagraphLoungesList} from "@/src/core/app/domain/models/paragraph/T_ParagraphLoungesList";
import Section from "@/src/ui/components/generic/Section/Section";
import UiMediaUtils from "@/src/ui/utils/media";
import _TextFields from "@/src/ui/components/blocks/BlockLoungesList/generic/_TextFields";

const BlockLoungesListMobile = (props: T_ParagraphLoungesList) => (
  <Section paddingY="my-7.5">
    <ul className="space-y-5">
      {props.field_lounge_items.map((item, index) => (
        <li className="flex-1 p-3.75 border border-gray-40" key={index}>
          <article className='space-y-5'>
            <img
              className="w-full border border-gray-40"
              {...UiMediaUtils.getImgPropsFromMediaImage(item.ui_cpt_field_media_teaser)}
            />
            <_TextFields
              className='space-y-2.5'
              item={item}
            />
          </article>
        </li>
      ))}
    </ul>
  </Section>
);


export default BlockLoungesListMobile;