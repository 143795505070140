import ModalLayout from "@/src/ui/components/portals/generic/ModalLayout";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {T_ReservationManage} from "@/src/core/app/domain/models/reservation/T_ReservationManage";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Button from "@/src/ui/components/generic/Button";
import ModalFooter from "src/ui/components/portals/generic/ModalFooter";
import DropdownParagraph from "@/src/ui/components/generic/DropdownParagraph";
import BookingManageUtils from "@/src/core/app/utils/booking-manage";
import {ModalState} from "@/src/ui/view_models/modal.state";
import {ModalReservationCancelData} from "@/src/ui/@types/modal/ModalReservationCancelData";
import {useTranslation} from "next-i18next";

const ModalReservationCancel = (): JSX.Element => {
  const { t } = useTranslation();
  const routeProps = useRouteProps();
  const modalState = useModalProvider<ModalState<ModalReservationCancelData>>(state => state);
  const reservationManage = (modalState.modalData?.reservationManageData) as T_ReservationManage;

  const hotel = HotelTreeUtils.getHotel(reservationManage.hotel.code, routeProps.layoutData.hotelTree);
  const closeModal = () => {
    modalState.closeModal();
  };

  const onSubmit = async ()=> {
    const bookingCancelled = await BookingManageUtils.bookingCancel(
      routeProps.appInfo.langcode,
      reservationManage.id,
      reservationManage.hotel.code,
      modalState
    );
    if (bookingCancelled) {
      modalState.modalData?.onCancel(reservationManage.id);
    }
  };

  return (
    <ModalLayout title={t('ModalReservationCancel._Index.title')} className="max-w-171.5">
      <div className="flex-1 overflow-y-auto p-5 space-y-5">
        <div className="space-y-2.5 text-sm text-brand-2-90">
          <h3 className="text-medium-bold">
            {t('ModalReservationCancel._Index.header', {
              hotel: hotel.title
            })}
          </h3>
        </div>
        <DropdownParagraph title={t('ModalReservationCancel._Index.terms_link_label')}>
          <WrapperDiv
            className="space-y-2.5 text-sm text-brand-2-90 ModalReservationCancel__CancellationPolicies"
            dangerouslySetInnerHTML={{__html: reservationManage.cancellation_popup_text}}
          />
        </DropdownParagraph>
      </div>
      <div className="flex-none">
        <ModalFooter>
          <span className="cursor-pointer text-link-bold" onClick={closeModal}>
            {t('ModalReservationCancel._Index.back_link_label')}
          </span>
          <Button text={t('ModalReservationCancel._Index.submit_button_label')} bold onClick={onSubmit} />
        </ModalFooter>
      </div>
    </ModalLayout>
  );
}

export default ModalReservationCancel;
